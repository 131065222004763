const claimOptions = {
    appointment_types: [
        {
            name: 'Air Transport',
            value: 1,
            key: 'air_transport'
        },
        {
            name: 'discharge',
            value: 2,
            key: 'discharge'
        },
        {
            name: 'Doctor',
            value: 3,
            key: 'doctor'
        },
        {
            name: 'Dr. Evaluation',
            value: 4,
            key: 'doctor_evaluation'
        },
        {
            name: 'Imaging',
            value: 5,
            key: 'imaging'
        },
        {
            name: 'IME',
            value: 6,
            key: 'ime'
        },
        {
            name: 'Lab',
            value: 7,
            key: 'lab'
        },
        {
            name: 'Legal',
            value: 8,
            key: 'legal'
        },
        {
            name: 'Pharmacy',
            value: 9,
            key: 'pharmacy'
        },
        {
            name: 'Other',
            value: 10,
            key: 'other'
        },
        {
            name: 'Physical Therapy',
            value: 11,
            key: 'physical_therapy'
        },
        {
            name: 'Pre-Op',
            value: 12,
            key: 'pre_op'
        },
        {
            name: 'Surgery',
            value: 13,
            key: 'surgery'
        },
        {
            name: 'Work',
            value: 14,
            key: 'work'
        }
    ],
    appointment_types_default: 10,
    leg_pickup_destination_types: [
        {
            name: 'Home',
            value: 1
        },
        {
            name: 'Doctor',
            value: 2
        },
        {
            name: 'Physical Therapy',
            value: 3
        },
        {
            name: 'X ray',
            value: 4
        },
        {
            name: 'Pharmacy',
            value: 5
        },
        {
            name: 'Lab',
            value: 6
        },
        {
            name: 'Legal Appt',
            value: 7
        },
        {
            name: 'Work',
            value: 8
        },
        {
            name: 'Occupational Therapy',
            value: 9
        },
        {
            name: 'Chiropractor',
            value: 10
        },
        {
            name: 'Dentist',
            value: 11
        },
        {
            name: 'Hospital',
            value: 12
        },
        {
            name: 'Evaluation & Fittings',
            value: 13
        },
        {
            name: 'Transport',
            value: 14
        },
        {
            name: 'Pain Management',
            value: 15
        },
        {
            name: 'Other-Medical',
            value: 16
        },
        {
            name: 'IME-Legal',
            value: 17
        },
        {
            name: 'IME-Medical',
            value: 18
        },
        {
            name: 'Telephonic Translation',
            value: 19
        },
        {
            name: 'Other',
            value: 20
        }
    ],
    leg_pickup_destination_types_default: 20
};

Object.freeze(claimOptions);
export default claimOptions;