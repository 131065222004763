angular.module('relayHealth').controller('notesCtrl', [
  '$uibModalInstance',
  'data',
  function notesCtrl($uibModalInstance, { cta = '', heading = '', confirmationLine = '' }) {
    Object.assign(this, {
      cta, heading, confirmationLine,
    });
    this.saveNotes = (notes) => {
      $uibModalInstance.close(notes);
    };
    this.closePopup = () => {
      $uibModalInstance.dismiss();
    };
  },
]);
