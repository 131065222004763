angular.module('relayHealth').controller('loginCtrl', [
  '$scope',
  '$rootScope',
  '$http',
  'API_BASE_URL',
  'PASSWORD_PUBLIC_KEY',
  'toaster',
  '$interval',
  '$location',
  'redirectUserHomeIfLoggedInService',
  'permissionsManager',
  'deviceDetector',
  'SessionTimeoutHandler',
  function loginCtrl(
    $scope,
    $rootScope,
    $http,
    API_BASE_URL,
    PASSWORD_PUBLIC_KEY,
    toaster,
    $interval,
    $location,
    redirectUserHomeIfLoggedInService,
    permissionsManager,
    deviceDetector,
    SessionTimeoutHandler,
  ) {
    $scope.hideChromeRecommendMsg = deviceDetector.browser === 'chrome';
    $scope.permissionsConstants = relaylib.permissions.permissionsConstants;
    $scope.$on('emitLoginEvent', () => {
      $scope.$emit('login');
    });
    redirectUserHomeIfLoggedInService();

    function byPassIosLogin() {
      if ($location.search().verify_login) {
        const encodedValue = $location.search().verify_login;
        const encryptedValue = decodeURIComponent(encodedValue);
        const res = $http.post(`${API_BASE_URL}verify-login`, { encryptedValue });
        res.then(({ data }) => {
          if (data.success) {
            const userData = data.user_data;
            const orgIds = [];
            if (userData.organisations) {
              angular.forEach(userData.organisations, (value) => {
                orgIds.push(value.id);
              });
            }
            userData.orgIds = orgIds;
            localStorage.setItem('userDetails', JSON.stringify(userData));
            localStorage.setItem('uuid', userData.uuid);
            Object.assign($rootScope, {
              userName: `${userData.first_name} ${userData.last_name}`,
            });
            redirectUserHomeIfLoggedInService();
            return;
          }
          if (data.uuid) {
            if (!localStorage.getItem('uuid')) {
              toaster.pop({
                type: 'error',
                title: data.message,
                showCloseButton: true,
                timeout: 10000,
              });
              localStorage.setItem('uuid', data.uuid);
            }
          } else {
            toaster.pop({
              type: 'error',
              title: data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        });

        res.catch(({ data }) => {
          if (data.uuid) {
            localStorage.setItem('uuid', data.uuid);
          }
        });
      }
      return false;
    }
    byPassIosLogin();
    $scope.rememberMe = false;

    $scope.login = (user) => {
      const usernew = _.cloneDeep(user);
      // Using jsencrypt module here for encrypting password before sendng using public key
      const crypt = new JSEncrypt();
      crypt.setPublicKey(PASSWORD_PUBLIC_KEY);

      $scope.value = 10;
      const stop = $interval(() => {
        if ($scope.value < 90) $scope.value += 5;
      }, 100);
      $scope.formSubmited = true;
      usernew.password = crypt.encrypt(user.password, 'base64');
      if (!!navigator.platform && /iPad|iPhone/.test(navigator.platform)) {
        // if the device type is ipad, then try to get the uuid for the user's device
        const uuid = localStorage.getItem('uuid') || '';
        if (uuid) {
          // add the uuid value to the user data
          usernew.uuid = uuid;
          usernew.os_type = 'ipad';
        }
      }
      // check remember me
      if ($scope.rememberMe) {
        usernew.remember_me = true;
      } else {
        usernew.remember_me = false;
      }
      usernew.date_current = moment
        .tz()
        .local()
        .format('YYYY_MM_DD');
      // check remember me  ends here

      const res = $http.post(`${API_BASE_URL}user/login`, usernew);
      res.then(({ data }) => {
        $scope.formSubmited = false;
        $interval.cancel(stop);
        if (data.success) {
          const userData = data.user_data;
          userData.date_current = usernew.date_current;
          userData.hasPreviousInvoices = !_.isEmpty(userData.organisation.rides_monthly_invoices);
          const orgIds = [];
          if (userData.organisations) {
            angular.forEach(userData.organisations, (value) => {
              orgIds.push(value.id);
            });
          }
          userData.orgIds = orgIds;
          const isInviteRiderSettingActive = userData.organisation.settings.find(setting => setting.setting_name === 'invited_riders' && setting.setting_value.toLowerCase() === 'true');
          if (isInviteRiderSettingActive) {
            userData.isInviteRiderSettingActive = true;
          }
          localStorage.setItem('userDetails', JSON.stringify(userData));
          $rootScope.$emit('userLogin', userData);
          Object.assign($rootScope, {
            userName: `${userData.first_name} ${userData.last_name}`,
          });
          permissionsManager.init(userData.permissions);
          SessionTimeoutHandler.setExpiryTime(data.session_expiry);
          redirectUserHomeIfLoggedInService();
          return;
        }
        toaster.pop({
          type: 'error',
          title: data.message,
          showCloseButton: true,
          timeout: 10000,
        });
      });
      res.catch(({ data }) => {
        Object.assign(user, { password: '' });
        $scope.formSubmited = false;
        $interval.cancel(stop);
        toaster.pop({
          type: 'error',
          title: data && data.message ? data.message : 'Not able to login',
          showCloseButton: true,
          timeout: 10000,
        });
      });
    };

    $scope.validateEmail = /^[a-zA-Z0-9_+]+([\.-]?[a-zA-Z0-9_+]+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  },
]);
