const vendorCaseStatus = {
    unknown: {
      action: 'Unknown',
      id: '0',
    },
    sticky: {
      action: 'Sticky',
      id: '1',
    },
    auto_assign: {
      action: 'AutoAssign',
      id: '2',
    },
    confirmation_email_sent: {
      action: 'Confirmation Email Sent',
      id: '3',
    },
    confirmation_email_accept: {
      action: 'Confirmation Email: Accept',
      id: '4',
    },
    confirmation_email_reject: {
      action: 'Confirmation Email: Reject',
      id: '5',
    },
    confirmation_email_expired: {
      action: 'Confirmation Email: Expired',
      id: '6',
    },
    vendor_portal_accept: {
      action: 'Vendor Portal: Accept',
      id: '7',
    },
    vendor_portal_24hr_confirm: {
      action: 'Vendor Portal: 24 Hr. Confirm',
      id: '8',
    },
    vendor_portal_final_confirm: {
      action: 'Vendor Portal: Final Confirm',
      id: '9',
    },
    vendor_portal_reject: {
      action: 'Vendor Portal: Reject',
      id: '10',
    },
  };
  Object.freeze(vendorCaseStatus);
  module.exports = vendorCaseStatus;
  