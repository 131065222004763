window.jQuery = require('jquery');

window.$ = window.jQuery;

const { JSEncrypt } = require('jsencrypt');
window.JSEncrypt = JSEncrypt;

window.moment = require('moment');


window._ = require('lodash');

window.PubNub = require('pubnub');

window.relaylib = require('@relayonecall/relay-lib');

window.Hammer = require('hammerjs');

window.tv4 = require('tv4');

window.ObjectPath = require('objectpath');

window.saveAs = require('./unorganized-js/filesaver').saveAs;

window.libPhoneNumber = require('libphonenumber-js');

require('intl-tel-input/build/js/intlTelInput.min');

require('jasny-bootstrap/js/fileinput');

require('jquery-ui');

require('bootstrap');
require('bootstrap-daterangepicker');

require('daterangepicker');
require('metismenu/dist/metisMenu');

require('intl-tel-input/build/css/intlTelInput.css');
require('intl-tel-input/build/js/utils');
require('intl-tel-input/build/js/intlTelInput-jquery');



require('bootstrap-duallistbox/dist/jquery.bootstrap-duallistbox.min');

require('./unorganized-js/theme/inspinia');

require('moment-timezone');

require('sweetalert');

window.tableContainerScrollLeft = (obj) => {
  window
    .$(obj)
    .parent('.table-responsive-container')
    .find('.table-responsive')
    .animate(
      {
        scrollLeft: '-=200',
      },
      'slow',
    );
};

window.tableContainerScrollRight = (obj) => {
  window
    .$(obj)
    .parent('.table-responsive-container')
    .find('.table-responsive')
    .animate(
      {
        scrollLeft: '+=200',
      },
      'slow',
    );
};

window.toggleAccordian = (obj) => {
  window
    .$(obj)
    .find('.dropdown a')
    .triggerHandler('click');
};
