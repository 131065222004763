const directiveDef = [
  function paginateDirective() {
    return {
      restrict: 'E',
      template: () => import('./paginate.html'),
      scope: {
        itemsPerPage: '=?',
        maxSize: '=?',
        reloadCurrent: '=?',
        reload: '=?',
        executor: '=',
        onExecutionComplete: '&',
      },
      controller: [
        '$scope',
        '$timeout',
        function paginateCtrl($scope, $timeout) {
          const defaults = {
            itemsPerPage: 10,
            maxSize: 10,
          };

          const watcherRemovers = [];
          let inited = false;

          /**
           * Invoked to send execution complete callback
           * @param resp Response data to be sent back
           * @param page Page number
           */
          function sendExecCompleteCB(resp, page) {
            $scope.totalCount = resp ? resp.totalCount : 0;
            $scope.onExecutionComplete({
              data: resp && resp.data,
              totalCount: $scope.totalCount,
              page,
            });
          }

          /**
           * Invoked to change page programmatically
           * @param newPage New page number
           */
          function changePage(newPage) {
            $scope.currentPage = newPage;
            $scope.pageChanged(newPage, true).then((resp) => {
              if (resp && resp.totalCount > 0 && !(resp.data && resp.data.length > 0)) {
                // Page not available. Now look for last possible page
                $scope.currentPage = Math.ceil(resp.totalCount / $scope.itemsPerPage);
                $scope.pageChanged($scope.currentPage);
              } else {
                sendExecCompleteCB(resp, newPage);
              }
            });
          }

          /**
           * Initialize default values
           */
          function init() {
            $scope.maxSize = $scope.maxSize || defaults.maxSize;
            $scope.itemsPerPage = $scope.itemsPerPage || defaults.itemsPerPage;
            $scope.totalCount = 0;
            changePage(1);
            inited = true;
          }

          /**
           * Invoked when page is updated
           * @param newPage   New page number
           * @param noCompleteCB  True, if callback need not to be sent
           */
          $scope.pageChanged = (newPage, noCompleteCB) => {
            if ($scope.executor && angular.isFunction($scope.executor)) {
              const params = {
                offset: (newPage - 1) * $scope.itemsPerPage,
                limit: $scope.itemsPerPage,
              };
              return $scope.executor(params).then((resp) => {
                if (!noCompleteCB) {
                  sendExecCompleteCB(resp, newPage);
                }
                return resp;
              });
            }
            return Promise.resolve();
          };

          watcherRemovers.push(
            $scope.$watch('itemsPerPage', (n, o) => {
              if (n !== o && inited) {
                changePage($scope.currentPage);
              }
            }),
          );

          watcherRemovers.push(
            $scope.$watch('reloadCurrent', (n, o) => {
              if (n !== o && inited && n) {
                changePage($scope.currentPage);
                const timer = $timeout(() => {
                  $scope.reloadCurrent = false;
                });
                timer.then(() => {
                  $timeout.cancel(timer);
                });
              }
            }),
          );

          watcherRemovers.push(
            $scope.$watch('reload', (n, o) => {
              if (n !== o && inited && n) {
                changePage(1);
                const timer = $timeout(() => {
                  $scope.reload = false;
                });
                timer.then(() => {
                  $timeout.cancel(timer);
                });
              }
            }),
          );

          $scope.$on('$destroy', () => {
            angular.forEach(watcherRemovers, (w) => {
              w();
            });
          });

          init();
        },
      ],
    };
  },
];
export default directiveDef;
