angular.module('relayHealth').controller('orgLanguageReportingCtrl', [
  '$scope',
  '$http',
  '$q',
  'API_BASE_URL',
  function orgLanguageReportingCtrl($scope, $http, $q, API_BASE_URL) {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    $scope.metrics = {};
    $scope.loading = true;

    $scope.time = {
      selected: 'monthToDate',
      options: {
        today: {
          name: 'Today',
          startDateTime: moment().startOf('day'),
          endDateTime: moment().endOf('day'),
        },
        thisWeek: {
          name: 'This week',
          startDateTime: moment().startOf('week'),
          endDateTime: moment().endOf('week'),
        },
        thisMonth: {
          name: 'This month',
          startDateTime: moment().startOf('month'),
          endDateTime: moment().endOf('month'),
        },
        thisYear: {
          name: 'This year',
          startDateTime: moment().startOf('year'),
          endDateTime: moment().endOf('year'),
        },
        theDaySoFar: {
          name: 'The day so far',
          startDateTime: moment().startOf('day'),
          endDateTime: moment(),
        },
        weekToDate: {
          name: 'Week to date',
          startDateTime: moment().startOf('week'),
          endDateTime: moment(),
        },
        monthToDate: {
          name: 'Month to date',
          startDateTime: moment().startOf('month'),
          endDateTime: moment(),
        },
        yearToDate: {
          name: 'Year to date',
          startDateTime: moment().startOf('year'),
          endDateTime: moment().endOf('year'),
        },
        yesterday: {
          name: 'Yesterday',
          startDateTime: moment()
            .add(-1, 'days')
            .startOf('day'),
          endDateTime: moment()
            .add(-1, 'days')
            .endOf('day'),
        },
        dayBeforeYesterday: {
          name: 'Day before yesterday',
          startDateTime: moment()
            .add(-2, 'days')
            .startOf('day'),
          endDateTime: moment()
            .add(-2, 'days')
            .endOf('day'),
        },
        thisDayLastWeek: {
          name: 'This day last week',
          startDateTime: moment()
            .add(-1, 'week')
            .startOf('day'),
          endDateTime: moment()
            .add(-1, 'week')
            .endOf('day'),
        },
        previousWeek: {
          name: 'Previous week',
          startDateTime: moment()
            .add(-1, 'weeks')
            .startOf('week'),
          endDateTime: moment()
            .add(-1, 'weeks')
            .endOf('week'),
        },
        previousMonth: {
          name: 'Previous month',
          startDateTime: moment()
            .add(-1, 'months')
            .startOf('month'),
          endDateTime: moment()
            .add(-1, 'months')
            .endOf('month'),
        },
        previousYear: {
          name: 'Previous year',
          startDateTime: moment()
            .add(-1, 'years')
            .startOf('year'),
          endDateTime: moment()
            .add(-1, 'years')
            .endOf('year'),
        },
        lastFifteenMinutes: {
          name: 'Last 15 minutes',
          startDateTime: moment().add(-15, 'minutes'),
          endDateTime: moment(),
        },
        lastThirtyMinutes: {
          name: 'Last 30 minutes',
          startDateTime: moment().add(-30, 'minutes'),
          endDateTime: moment(),
        },
        lastHour: {
          name: 'Last 1 hour',
          startDateTime: moment().add(-1, 'hours'),
          endDateTime: moment(),
        },
        lastFourHours: {
          name: 'Last 4 hours',
          startDateTime: moment().add(-4, 'hours'),
          endDateTime: moment(),
        },
        lastTwelveHours: {
          name: 'Last 12 hours',
          startDateTime: moment().add(-12, 'hours'),
          endDateTime: moment(),
        },
        lastTwentyFourHours: {
          name: 'Last 24 hours',
          startDateTime: moment().add(-24, 'hours'),
          endDateTime: moment(),
        },
        lastSevenDays: {
          name: 'Last 7 days',
          startDateTime: moment().add(-7, 'days'),
          endDateTime: moment(),
        },
        lastThirtyDays: {
          name: 'Last 30 days',
          startDateTime: moment().add(-30, 'days'),
          endDateTime: moment(),
        },
        lastSixtyDays: {
          name: 'Last 60 days',
          startDateTime: moment().add(-60, 'days'),
          endDateTime: moment(),
        },
        lastNinetyDays: {
          name: 'Last 90 days',
          startDateTime: moment().add(-90, 'days'),
          endDateTime: moment(),
        },
        lastSixMonths: {
          name: 'Last 6 months',
          startDateTime: moment().add(-6, 'months'),
          endDateTime: moment(),
        },
        lastOneYear: {
          name: 'Last 1 year',
          startDateTime: moment().add(-1, 'years'),
          endDateTime: moment(),
        },
        lastTwoYears: {
          name: 'Last 2 years',
          startDateTime: moment().add(-2, 'years'),
          endDateTime: moment(),
        },
        lastFiveYears: {
          name: 'Last 5 years',
          startDateTime: moment().add(-5, 'years'),
          endDateTime: moment(),
        },
      },
    };

    function getOrgMetricData(metricFunctionName) {
      const params = {};
      const selectedOption = $scope.time.options[$scope.time.selected];
      if (selectedOption) {
        params.time = {
          startDateTime: selectedOption.startDateTime,
          endDateTime: selectedOption.endDateTime,
        };
      }
      params.orgId = userDetails.organisation.id;

      return $http({
        url: `${API_BASE_URL}language/getOrganisationMetricData/${metricFunctionName}`,
        params,
        method: 'GET',
      })
        .then((result) => {
          $scope.metrics[metricFunctionName] = result.data.result;
        })
        .catch(() => {
          $scope.metrics[metricFunctionName] = 'Error';
        });
    }

    $scope.timeChange = () => {
      $scope.loading = true;
      $q.all([getOrgMetricData('VIThisPeriod'), getOrgMetricData('averageLengthPerVI'), getOrgMetricData('averageRideCostForVI')]).finally(() => {
        $scope.loading = false;
      });
    };

    $scope.timeChange();
  },
]);
