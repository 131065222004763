angular.module('relayHealth').controller('CreateBroadcastCtrl', [
  '$scope',
  '$uibModalInstance',
  'editInfo',
  'tmplInfo',
  '$http',
  'API_BASE_URL',
  'toaster',
  '$timeout',
  function CreateBroadcastCtrl($scope, $uibModalInstance, editInfo, tmplInfo, $http, API_BASE_URL, toaster, $timeout) {
    $scope.permissionsConstants = relaylib.permissions.permissionsConstants;
    function convertFromDM(inDM, isTemplate) {
      const data = {
        category: inDM.category || null,
        title: inDM.title,
        content: inDM.message,
        duration: inDM.duration,
        isAuthenticated: inDM.isAuthenticated,
      };
      if (!isTemplate) {
        data.id = inDM.id;
        data.type = relaylib.broadcasts.broadcastTypes.NON_OBTRUSIVE;
        data.roles = ['all'];
      }
      data.targets = [];
      if (inDM.isTargetWeb) {
        data.targets.push(relaylib.broadcasts.broadcastTargets.WEB);
      }
      if (inDM.isTargetAndroid) {
        data.targets.push(relaylib.broadcasts.broadcastTargets.ANDROID);
      }
      if (inDM.isTargetIos) {
        data.targets.push(relaylib.broadcasts.broadcastTargets.IOS);
      }
      return data;
    }

    function init() {
      if (tmplInfo) {
        $scope.dm = Object.assign({}, tmplInfo);
      } else if (editInfo) {
        $scope.dm = Object.assign({}, editInfo);
      } else {
        $scope.dm = {
          title: '',
          message: '',
          category: '',
          isTargetWeb: false,
          isTargetAndroid: false,
          isTargetIos: false,
          duration: 0,
          isAuthenticated: true,
        };
      }
      $scope.isTmplSaving = false;
      $scope.isSaving = false;
    }

    function reset() {
      $scope.dm = {
        title: '',
        message: '',
        category: '',
        isTargetWeb: false,
        isTargetAndroid: false,
        isTargetIos: false,
        duration: 0,
        isAuthenticated: true,
      };
      $scope.isTmplSaving = false;
      $scope.isSaving = false;
      if ($scope.addBroadcastFrm) {
        $scope.addBroadcastFrm.$setPristine();
      }
    }

    function isFormValid() {
      return $scope.addBroadcastFrm.$valid && ($scope.dm.isTargetWeb || $scope.dm.isTargetAndroid || $scope.dm.isTargetIos);
    }

    $scope.submitMessage = () => {
      if (isFormValid() && !editInfo) {
        // Create case
        $scope.isSaving = true;
        const data = convertFromDM($scope.dm);
        $http
          .post(`${API_BASE_URL}broadcasts`, data)
          .then((resp) => {
            $uibModalInstance.close(resp);
          })
          .finally(() => {
            $scope.isSaving = false;
          });
      } else if (isFormValid()) {
        // Edit case
        $scope.isSaving = true;
        const data = convertFromDM($scope.dm);
        $http
          .put(`${API_BASE_URL}broadcasts/${data.id}`, data)
          .then((resp) => {
            $uibModalInstance.close(resp);
          })
          .finally(() => {
            $scope.isSaving = false;
          });
      } else {
        // Form data invalid. Do nothing
      }
    };

    $scope.saveAsTmpl = () => {
      $scope.isTmplSaving = true;
      if (!!$scope.dm.title && $scope.dm.duration >= 0) {
        // Create case
        const data = convertFromDM($scope.dm, true);
        $http
          .post(`${API_BASE_URL}broadcast_templates`, data)
          .then(() => {
            toaster.pop({
              type: 'success',
              title: 'Saved successfully',
              timeout: 2000,
              showCloseButton: true,
            });
          })
          .finally(() => {
            $scope.isTmplSaving = false;
          });
      } else {
        const timer = $timeout(() => {
          $scope.isTmplSaving = false;
        }, 10000);
        timer.then(() => {
          $timeout.cancel(timer);
        });
      }
    };

    $scope.cancel = () => {
      reset();
      $uibModalInstance.close();
    };

    init();
  },
]);
