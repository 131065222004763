export default class Command {
    constructor(){
        // nothing
    }
    execute(){
        // nothing
    }
}


