import userRoles from './userRoles';

const userRolesDisplayTextMapping = {};
userRolesDisplayTextMapping[userRoles.MASTER_SUPER_ADMIN] = "Master Super Admin";
userRolesDisplayTextMapping[userRoles.SUPER_ADMIN] = "Super Admin";
userRolesDisplayTextMapping[userRoles.SCHEDULING_ADMIN] = "Scheduling Admin";
userRolesDisplayTextMapping[userRoles.NETWORK_OPERATION] = "Scheduling Manager";
userRolesDisplayTextMapping[userRoles.PRODUCTION_OPERATION] = "Product Operations";
userRolesDisplayTextMapping[userRoles.PROVIDER_MANAGER] = "Provider Manager";
userRolesDisplayTextMapping[userRoles.ORG_ADMIN] = "User";
userRolesDisplayTextMapping[userRoles.RIDER_USER] = "User";
userRolesDisplayTextMapping[userRoles.RIDE_MANAGER] = "Ride Manager Admin";
userRolesDisplayTextMapping[userRoles.ORG_SUPERADMIN] = "Admin";
userRolesDisplayTextMapping[userRoles.RIDER_ADMIN] = "Admin";
userRolesDisplayTextMapping[userRoles.TEMP_RIDER] = "Admin";
userRolesDisplayTextMapping[userRoles.TEMP_BUSINESS] = "Admin";
userRolesDisplayTextMapping[userRoles.OC_REPORTING]="One Call Reporting";
userRolesDisplayTextMapping[userRoles.ORG_REPORTING]="Reporting";
Object.freeze(userRolesDisplayTextMapping);
export default userRolesDisplayTextMapping;