angular.module('relayHealth').controller('fuseAccessLogsCtrl', [
  '$scope',
  'API_BASE_URL',
  '$http',
  'loadingScreenFactory',
  '$uibModal',
  '$state',
  '$timeout',
  'storageManager',
  'toaster',
  function fuseAccessLogsCtrl($scope,
    API_BASE_URL,
    $http,
    loadingScreenFactory,
    $uibModal,
    $state,
    $timeout,
    storageManager,
    toaster) {
    const stateParmas = $state.params;
    const { storage } = storageManager;

    function initSearchForm() {
      $scope.searchFormQuery = {
        claimanthomephone: '',
        claimant: '',
        filenumber: '',
        dateRange: { startDate: null, endDate: null },
        status: 'Select Status',
      };
      $scope.fuseLogQuery = {
        claimanthomephone: '',
        claimant: '',
        filenumber: '',
        dateRange: { startDate: null, endDate: null },
        status: 'Select Status',
      };
    }

    $scope.moment = moment;
    $scope.sortBy = 'createdAt';

    let firstTimeLoad = true;
    const statuses = ['accepted', 'rejected', 'email_parsed', 'pending_for_parsing']; // adding possible statuses to select box
    $scope.statusList = ['Select Status'].concat(statuses);
    $scope.dateRangeOptions = {
      parentEl: '#current-app #page-wrapper',
      eventHandlers: {
        'apply.daterangepicker': function applyDaterangepicker() {
          const dateRangeFilter = document.getElementsByName('dateRange')[0].value;

          if (dateRangeFilter) {
            const dateRangeFilterArr = dateRangeFilter.split(' - ');
            $scope.searchFormQuery.dateRange.startDate = `${dateRangeFilterArr[0]} 00:00:00`;
            $scope.searchFormQuery.dateRange.endDate = `${dateRangeFilterArr[1]} 23:59:59`;
          }
        },
      },
    };

    function initPagination() {
      $scope.pagination = {
        totalItems: 0,
        currentPage: 1,
        lastItem: 0,
        itemsPerPage: 10,
      };
    }

    function adjustPagination() {
      $scope.pagination.lastItem = ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage + $scope.pagination.itemsPerPage;
      if ($scope.pagination.lastItem > $scope.pagination.totalItems) {
        $scope.pagination.lastItem = $scope.pagination.totalItems;
      }
    }

    function getSearchString() {
      const searchFormQuery = angular.copy($scope.searchFormQuery);

      const searchStr = [];

      Object.keys(searchFormQuery).forEach((key) => {
        if (searchFormQuery[key] && key === 'claimanthomephone') {
          searchFormQuery.claimanthomephone = searchFormQuery.claimanthomephone
            .replace(/-/g, '')
            .replace(' ', '')
            .replace(/_/g, '')
            .replace('(', '')
            .replace(')', '');
        }
        if (searchFormQuery[key] && key !== 'currentPage' && key !== 'dateRange') {
          if (searchFormQuery[key] && key === 'status' && searchFormQuery[key] === 'Select Status') {
            // to handle status filter
            return;
          }
          searchStr.push(`${key}=${searchFormQuery[key]}`);
        }
        if (searchFormQuery[key] && key === 'dateRange' && searchFormQuery.dateRange.startDate && searchFormQuery.dateRange.endDate) {
          // to handle date range filter
          searchStr.push(`startDate=${searchFormQuery.dateRange.startDate.format('YYYY-MM-DD')} 00:00:00`);
          searchStr.push(`endDate=${searchFormQuery.dateRange.endDate.format('YYYY-MM-DD')} 23:59:59`);
        }
      });
      // var order = ($scope.sortOrder ? 'DESC' : 'ASC');
      searchStr.push(`order="${$scope.sortBy}" DESC`);
      let temp = searchStr.join('&');
      if (searchStr.length > 0) temp = `&${temp}`;

      return temp;
    }

    // for getting audit logs from api
    function getFuseLogs(offset, limit, searchStr) {
      const request = $http.get(`${API_BASE_URL}fuse_logs?offset=${offset}&limit=${limit}${searchStr}`);
      loadingScreenFactory.showLoadingScreen();
      request.then(
        (response) => {
          loadingScreenFactory.hideLoadingScreen();
          if (response.data.success) {
            const result = response.data.fuse_logs;
            Object.keys(result).forEach((index) => {
              const parsedJson = result[index].json;
              result[index].parsedJson = angular.copy(parsedJson);
            });
            $scope.fuseLogs = result;
            $scope.pagination.totalItems = response.data.totalCount;
            firstTimeLoad = false;
            adjustPagination();
          }
        },
      ).catch((error) => {
        toaster.pop({
          type: 'error',
          title: error.data.message || 'Error fetching fuse details',
          showCloseButton: true,
          timeout: 10000,
        });
        loadingScreenFactory.hideLoadingScreen();
      });
    }

    function writeSearchFilter() {
      const prevSearchValues = storage.getData('filterDataFuseAccessLogs');
      const prevForm = prevSearchValues;
      if (!prevSearchValues) {
        return;
      }
      $scope.fuseLogQuery.claimant = prevForm.claimant;
      $scope.fuseLogQuery.claimanthomephone = prevForm.claimanthomephone;
      $scope.fuseLogQuery.filenumber = prevForm.filenumber;
    }

    function init() {
      initPagination();
      initSearchForm();

      function initFormAndLogs() {
        $timeout(() => {
          // setting params into form
          angular.extend($scope.searchFormQuery, stateParmas);
          $scope.fuseLogQuery = angular.copy($scope.searchFormQuery);
          writeSearchFilter();
          $scope.pagination.currentPage = angular.isDefined(stateParmas.currentPage) ? parseInt(stateParmas.currentPage, 10) + 1 : 1;
          getFuseLogs($scope.pagination.currentPage - 1, 10, getSearchString());
        }, 0);
      }
      initFormAndLogs();
    }
    init();

    $scope.pageChanged = () => {
      if (!firstTimeLoad) {
        $state.go($state.current, { currentPage: $scope.pagination.currentPage - 1 });
        getFuseLogs($scope.pagination.currentPage - 1, 10, getSearchString());
      }
    };

    function getStateParams() {
      const stateParams = angular.copy($scope.searchFormQuery);
      if (stateParams.level === 'Select Level') {
        delete stateParams.level;
      }
      return stateParams;
    }

    $scope.filterFuseLogs = () => {
      $scope.searchFormQuery = angular.copy($scope.fuseLogQuery);
      const stateParams = getStateParams();
      stateParams.currentPage = 0;
      $state.go($state.current, stateParams);
      storage.setData('filterDataFuseAccessLogs', stateParams);
      getFuseLogs(0, 10, getSearchString());
    };

    $scope.clearFilters = () => {
      storage.removeData('filterDataFuseAccessLogs');
      const stateParams = {};
      initSearchForm();
      getFuseLogs(0, 10, getSearchString());

      angular.extend(stateParams, $scope.searchFormQuery);
      stateParams.level = '';
      stateParams.currentPage = 0;
      $scope.pagination.currentPage = 1;
      $state.go($state.current, stateParams);
    };

    $scope.enterOnFormSearch = (event) => {
      if (event.keyCode === 13) {
        $scope.pagination.currentPage = 1;
        $scope.searchFormQuery = angular.copy($scope.fuseLogQuery);

        const stateParams = getStateParams();
        storage.setData('filterDataFuseAccessLogs', stateParams);
        stateParams.currentPage = 0;
        $state.go($state.current, stateParams);

        getFuseLogs(0, 10, getSearchString());
      }
    };

    $scope.getLogDetails = (log) => {
      if (angular.isDefined(log.logDetails)) {
        Object.assign(log, {
          logDetails: undefined,
        });
        return;
      }
      loadingScreenFactory.showLoadingScreen();
      $http.get(`${API_BASE_URL}logs/getLogDetails/fuse/${log.id}`)
        .then(
          (response) => {
            loadingScreenFactory.hideLoadingScreen();
            if (response.data.success) {
              Object.assign(log, {
                logDetails: response.data.logDetails,
              });
            }
          },
          () => {
            loadingScreenFactory.hideLoadingScreen();
          },
        );
    };

    $scope.showMeta = ({ _source: { meta: sourceMeta } }) => {
      const modalInstance = $uibModal.open({
        animation: true,
        template: require('../sub-modules/logs/log-details.html'),
        size: 'lg',
        backdrop: 'static',
        resolve: {
          meta() {
            return angular.copy(sourceMeta);
          },
        },
        controllerAs: 'logDetailsCtrl',
        controller: [
          'meta',
          '$uibModalInstance',
          function logDetails(meta, $uibModalInstance) {
            Object.assign(this, {
              meta,
              closePopup() {
                $uibModalInstance.close();
              },
            });
          },
        ],
      });
      $scope.$on('$destroy', () => {
        modalInstance.close();
      });
    };
  },
]);
