const providerNames = {
    LYFT: 'lyft',
    UBER: 'uber',
    TRADITIONAL: 'traditional',
};

const tagNames = {
    OCCM: 'occm',
    MAS: 'mas',
    GH: 'gh'
}

const orgProviders = {
    supportedProviderNames: [providerNames.LYFT, providerNames.UBER],
    constants: {
        LYFT: {
            PROVIDER_ID: 2,
            NAME: providerNames.LYFT,
            TAGS: [tagNames.OCCM, tagNames.MAS, tagNames.GH],
            DEFAULT_TAG: tagNames.OCCM
        },
        UBER: {
            PROVIDER_ID: 1,
            NAME: providerNames.UBER,
            TAGS: [tagNames.OCCM, tagNames.MAS, tagNames.GH],
            DEFAULT_TAG: tagNames.OCCM
        },
        TRADITIONAL: {
            PROVIDER_ID: 4,
            NAME: providerNames.TRADITIONAL,
            TAGS: [tagNames.GH],
            DEFAULT_TAG: tagNames.GH
        }
    },
    tagConsts: tagNames
}

Object.freeze(orgProviders);
export default orgProviders;