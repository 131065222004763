angular.module('relayHealth').controller('billingCtrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  '$state',
  '$timeout',
  'toaster',
  function billingCtrl($scope, $http, API_BASE_URL, $state, $timeout, toaster) {
    $scope.searchFormQuery = {};

    $scope.pagination = {
      totalItems: 0,
      currentPage: 1,
      lastItem: 0,
      itemsPerPage: 10,
    };

    let sort;
    function getOrganzationCompletedApps() {
      let getOrganzationCompletedAppsCall;
      if (sort) {
        getOrganzationCompletedAppsCall = $http.get(
          `${API_BASE_URL}appointment/getdata/${$state.params.orgId}/completed/${($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage}/${
            $scope.pagination.itemsPerPage
          }/${sort}`,
        );
      } else {
        getOrganzationCompletedAppsCall = $http.get(
          `${API_BASE_URL}appointment/getdata/${$state.params.orgId}/completed/${($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage}/${
            $scope.pagination.itemsPerPage
          }`,
        );
      }
      getOrganzationCompletedAppsCall.then(
        (data) => {
          if (data.data.success && data.data.result) {
            if (data.data.success && data.data.result.data.length > 0) {
              $scope.completedAppointments = data.data.result.data;
              $scope.pagination.totalItems = data.data.result.totalCount;
              $scope.pagination.lastItem = ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage + 10;
              if ($scope.pagination.lastItem > $scope.pagination.totalItems) {
                $scope.pagination.lastItem = $scope.pagination.totalItems;
              }
            } else {
              $scope.completedAppointments = [];
              $scope.pagination.totalItems = 0;
            }
          }
        },
        () => {
        },
      );
    }

    $scope.pageChanged = () => {
      if (angular.equals({}, $scope.searchFormQuery)) {
        getOrganzationCompletedApps();
      } else {
        $scope.filterCompletedAppts();
      }
    };

    $scope.clearFilters = () => {
      $scope.searchFormQuery = {};
    };


    getOrganzationCompletedApps();

    $scope.filterCompletedAppts = () => {
      const matchData = {
        invoiceId: $scope.searchFormQuery.invoiceId,
        customerName: $scope.searchFormQuery.customer ? $scope.searchFormQuery.customer.trim() : undefined,
        appointmentDate: $scope.searchFormQuery.appointmentDate ? $scope.searchFormQuery.appointmentDate.unix() : undefined,
        invoiceStatus: $scope.searchFormQuery.invoiceStatus,
      };

      let url;
      if (sort) {
        url = `${API_BASE_URL}appointments/filterCompleted/${$state.params.orgId}/${($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage}/${
          $scope.pagination.itemsPerPage
        }/${sort}`;
      } else {
        url = `${API_BASE_URL}appointments/filterCompleted/${$state.params.orgId}/${($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage}/${
          $scope.pagination.itemsPerPage
        }`;
      }
      const getFilteredCompletedAppointmentsCall = $http({
        url,
        method: 'GET',
        params: matchData,
      });
      getFilteredCompletedAppointmentsCall.then(
        (result) => {
          if (result.data.success && result.data.result) {
            $scope.completedAppointments = result.data.result.data;
            $scope.pagination.totalItems = result.data.result.totalCount;
            $scope.pagination.lastItem = ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage + 10;
            if ($scope.pagination.lastItem > $scope.pagination.totalItems) {
              $scope.pagination.lastItem = $scope.pagination.totalItems;
            }
          } else if (!result.data.success) {
            toaster.pop({
              type: 'error',
              title: result.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          } else {
            $scope.completedAppointments = [];
            $scope.pagination.totalItems = 0;
          }
        },
        () => {
        },
      );
    };

    $scope.selectAppointment = (app) => {
      $scope.selectedAppointment = app;
    };

    $scope.openDatePicker = (e) => {
      $timeout(() => {
        $($(e.currentTarget).siblings()[0])[0].focus();
      }, 500);
    };

    $scope.sortColumn = undefined;
    $scope.sortType = undefined;
    $scope.sortData = (sortColumn) => {
      $scope.sortColumn = sortColumn;
      if (sort) {
        const sortCol = sort.split(' ')[0];
        let sortColType = sort.split(' ')[1];
        if (sortCol === sortColumn) {
          if (sortColType === 'ASC') {
            sortColType = 'DESC';
          } else {
            sortColType = 'ASC';
          }
          $scope.sortType = sortColType;
          sort = `${sortColumn} ${sortColType}`;
        } else {
          $scope.sortType = 'ASC';
          sort = `${sortColumn} ASC`;
        }
      } else {
        $scope.sortType = 'ASC';
        sort = `${sortColumn} ASC`;
      }
      if (angular.equals({}, $scope.searchFormQuery)) {
        getOrganzationCompletedApps();
      } else {
        $scope.filterCompletedAppts();
      }
    };
  },
]);
