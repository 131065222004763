angular.module('relayHealth').service('dosOverlap', function dosOverlap() {
  const daysToHours = (value, unit) => {
    if (unit === 'days') {
      return value * 24;
    }
    return Number(value);
  };
  const evaluate = (value1, operations, value2) => {
    switch (operations) {
      case '<':
        return Number(value1) < Number(value2);
      case '>':
        return Number(value1) > Number(value2);
      case '=':
        return Number(value1) === Number(value2);
      case '<=':
        return Number(value1) <= Number(value2);
      case '>=':
        return Number(value1) >= Number(value2);
      default:
        return true;
    }
  };
  const getRange = (value, operations) => {
    const max = 9999;
    const min = 0;
    const range = 0.999;
    switch (operations) {
      case '<':
        return [min, Number(value) - 0.01];
      case '>':
        return [Number(value) + range, max];
      case '=':
        return [Number(value), Number(value) + range];
      case '<=':
        return [min, Number(value) + range];
      case '>=':
        return [Number(value), max];
      case 'between': {
        const newRange = value.split('-');

        return [newRange[0], Number(newRange[1]) + range];
      }
      default:
        return null;
    }
  };
  this.checkValidity = function checkValidity(dosArray, dosValue) {
    if (Object.keys(dosValue).length === 0
    ) {
      return false;
    }
    for (let i = 0; i < dosArray.length; i += 1) {
      if (
        dosValue.check === dosArray[i].check
        && dosValue.dos === dosArray[i].dos
      ) {
        return true;
      }

      const addDosValues = getRange(dosValue.dos, dosValue.check);
      const compareValues = getRange(dosArray[i].dos, dosArray[i].check);
      // if max(a2, b2) - min(a1, b1) < (a2 - a1) + (b2 - b1) {
      //  they overlap!
      // }

      let check = '<=';
      if (['>', '<'].indexOf(dosValue.check) !== -1 || ['>', '<'].indexOf(dosArray[i].check) !== -1) {
        check = '<';
      }
      const maxMinDiff = Math.max(daysToHours(compareValues[1], dosArray[i].dosUnit), daysToHours(addDosValues[1], dosValue.dosUnit)) - Math.min(daysToHours(compareValues[0], dosArray[i].dosUnit), daysToHours(addDosValues[0], dosValue.dosUnit));
      const diffEndpoints = daysToHours(compareValues[1], dosArray[i].dosUnit) - daysToHours(compareValues[0], dosArray[i].dosUnit) + daysToHours(addDosValues[1], dosValue.dosUnit) - daysToHours(addDosValues[0], dosValue.dosUnit);
      if (evaluate(maxMinDiff, check, diffEndpoints)) {
        return true;
      }
    }
    return false;
  };
});
