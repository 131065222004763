const { alert } = window;

angular.module('relayHealth').controller('manageQuestionnaireCtrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  'toaster',
  'loadingScreenFactory',
  '$stateParams',
  '$state',
  'SweetAlert',
  function manageQuestionnaireCtrl(
    $scope,
    $http,
    API_BASE_URL,
    toaster,
    loadingScreenFactory,
    $stateParams,
    $state,
    SweetAlert,
  ) {
    function getOrgDetailsById() {
      if ($stateParams.orgId) {
        const getOrgDetailsByIdCall = $http.get(`${API_BASE_URL}organisation/getOrgDetailsById/${$stateParams.orgId}`);
        getOrgDetailsByIdCall.then(
          (result) => {
            if (result.data.success) {
              $scope.orgName = result.data.orgDetails.name;
            } else {
              toaster.pop({
                type: 'error',
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
            }
          },
          (error) => {
            toaster.pop({
              type: 'error',
              title: error.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          },
        );
      }
    }
    getOrgDetailsById();
    $scope.nextButtonText = 'Next';

    $scope.questionnaireId = $stateParams.questionnaireId;

    function init() {
      $scope.schema = {
        type: 'object',
        properties: {},
      };
      $scope.form = [];
    }
    init();

    const question = {
      text: '',
      type: 'text',
      isVisible: true,
      options: [],
    };

    $scope.questions = [];
    $scope.questions.push(angular.copy(question));
    $scope.questionTypes = ['text', 'select', 'radio', 'checkbox', 'date'];


    function validateQuestionData(index) {
      if (!$scope.questions[index].text) {
        return true;
      }
      if (!($scope.questions[index].type === 'text' || $scope.questions[index].type === 'date') && !$scope.questions[index].options.length) {
        return true;
      }
      if (!($scope.questions[index].type === 'text' || $scope.questions[index].type === 'date') && $scope.questions[index].options.length > 0) {
        return Object.keys($scope.questions[index].options).some(optionIndex => !$scope.questions[index].options[optionIndex].text);
      }
      return false;
    }

    function createSchemaAndForm() {
      init();
      Object.keys($scope.questions).forEach((index) => {
        const isInvalidData = validateQuestionData(index);
        if (!isInvalidData) {
          $scope.schema.properties[`question${index}`] = {
            type: 'string',
          };

          const formComponent = {
            key: `question${index}`,
            title: `${parseInt(index, 10) + 1}. ${$scope.questions[index].text}`,
          };

          if ($scope.questions[index].type !== 'text') {
            if ($scope.questions[index].type === 'select') {
              formComponent.type = 'select';
            } else if ($scope.questions[index].type === 'radio') {
              formComponent.type = 'radios';
            } else if ($scope.questions[index].type === 'date') {
              formComponent.type = 'date';
              $scope.schema.properties[`question${index}`].type = 'date';
            } else {
              formComponent.type = 'checkboxes';
              $scope.schema.properties[`question${index}`] = {};
              $scope.schema.properties[`question${index}`].title = $scope.questions[index].text;
              $scope.schema.properties[`question${index}`].type = 'array';
              $scope.schema.properties[`question${index}`].items = {
                type: 'string',
                enum: [],
              };
            }

            const titleMap = [];
            const titleMapRecord = {};

            Object.keys($scope.questions[index].options).forEach((optionIndex) => {
              if ($scope.questions[index].options[optionIndex].text) {
                titleMapRecord.value = $scope.questions[index].options[optionIndex].text;
                titleMapRecord.name = $scope.questions[index].options[optionIndex].text;
                titleMap.push(angular.copy(titleMapRecord));

                // handle checkbox's options in array
                if ($scope.questions[index].type === 'checkbox') {
                  $scope.schema.properties[`question${index}`].items.enum.push($scope.questions[index].options[optionIndex].text);
                }
              }
            });

            formComponent.titleMap = angular.copy(titleMap);
          }

          $scope.form.push(angular.copy(formComponent));
        }
      });
      $scope.$broadcast('schemaFormRedraw');
    }


    $scope.addQuestionToForm = (index) => {
      $scope.questions[index].isVisible = false;
      createSchemaAndForm();
      $scope.questions.push(angular.copy(question));
    };

    $scope.updateQuestionnaire = () => {
      createSchemaAndForm();
    };

    $scope.setDisabled = (index) => {
      let disabled = false;
      if (!$scope.questions[index].text) {
        disabled = true;
      } else if (!($scope.questions[index].type === 'text' || $scope.questions[index].type === 'date') && !$scope.questions[index].options.length) {
        disabled = true;
      } else if ($scope.questions[index].type !== 'text' && $scope.questions[index].options.length > 0) {
        disabled = Object.keys($scope.questions[index].options).forEach((optionIndex) => {
          if (!$scope.questions[index].options[optionIndex].text) {
            disabled = true;
          }
        });
      }

      if (index + 1 < $scope.questions.length) {
        disabled = true;
      }

      return disabled;
    };

    $scope.deleteQuestion = (index) => {
      if ($scope.questions[index - 1]) {
        $scope.questions[index - 1].isVisible = true;
      } else if ($scope.questions[index + 1]) {
        $scope.questions[index + 1].isVisible = true;
      }
      $scope.questions.splice(index, 1);
      createSchemaAndForm();
      if (!$scope.questions.length) {
        $scope.questions.push(angular.copy(question));
      }
    };

    $scope.next = (index) => {
      $scope.questions[index + 1].isVisible = true;
      $scope.questions[index].isVisible = false;
      createSchemaAndForm();
    };

    $scope.previous = (index) => {
      $scope.questions[index - 1].isVisible = true;
      $scope.questions[index].isVisible = false;
      createSchemaAndForm();
    };
    $scope.$on('sf-render-finished', (event, element) => {
      element.find('input[name="text"]:last').focus();
    });

    function prepareQuestions(questionsFromApi) {
      const questions = Object.keys(questionsFromApi).map(index => ({
        text: questionsFromApi[index].question_text,
        type: questionsFromApi[index].question_type,
        isVisible: false,
        options: questionsFromApi[index].optionsStr
          ? JSON.parse(questionsFromApi[index].optionsStr)
          : [],
      }));
      questions[questions.length - 1].isVisible = true;
      $scope.questions = angular.copy(questions);
    }

    function getQuestionnareFieldsById() {
      const getQuestionnareFieldsByIdCall = $http({
        url: `${API_BASE_URL}organisation/getQuestionnareFieldsById/${$scope.questionnaireId}`,
        method: 'GET',
      });
      loadingScreenFactory.showLoadingScreen();
      getQuestionnareFieldsByIdCall.then(
        (result) => {
          if (result.data.success && result.data.questions && result.data.questions.length) {
            prepareQuestions(result.data.questions);
            createSchemaAndForm();
          }
        },
      );
    }
    if ($scope.questionnaireId) {
      getQuestionnareFieldsById();
      $scope.nextButtonText = 'Update';
    }

    $scope.saveQuestionnaire = () => {
      const questions = [];
      if ($scope.form.length) {
        Object.keys($scope.form).forEach((index) => {
          const isInvalidData = validateQuestionData(index);
          if (isInvalidData) {
            toaster.pop({
              type: 'error',
              title: `Question ${parseInt(index, 10) + 1} data not complete`,
              showCloseButton: true,
              timeout: 10000,
            });
            return;
          }
          const questionData = {
            question_text: $scope.questions[index].text,
            question_type: $scope.questions[index].type,
            question_index: index,
          };
          if ($scope.questionnaireId) {
            questionData.questionnaire_id = $scope.questionnaireId;
          }
          if ($scope.questions[index].type !== 'text') {
            questionData.optionsStr = JSON.stringify($scope.questions[index].options);
          }
          questions.push(angular.copy(questionData));
        });

        const serverData = {
          questions,
          orgId: $stateParams.orgId,
        };
        const saveQuestionnaireCall = $http.post(
          `${API_BASE_URL}organisation/saveQuestionnaire${$scope.questionnaireId ? `/${$scope.questionnaireId}` : ''}`,
          serverData,
        );
        saveQuestionnaireCall.then(
          (result) => {
            if (result.data.success) {
              toaster.pop({
                type: 'info',
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
              $scope.closeFormBuilder(false);
            }
          },
          (error) => {
            toaster.pop({
              type: 'error',
              title: error.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          },
        );
      } else {
        // eslint-disable-next-line no-alert
        alert('no questions');
      }
    };

    $scope.closeFormBuilder = (displayConfirmAlert) => {
      if (displayConfirmAlert) {
        SweetAlert.swal(
          {
            title: 'Are you sure?',
            text: 'Do you want to close the form builder?',
            type: 'success',
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes',
            showCancelButton: true,
          },
          (isConfirm) => {
            if (isConfirm) {
              $state.go('main.userMgmt.questionnaireList', { orgId: $stateParams.orgId });
            }
          },
        );
      } else {
        $state.go('main.userMgmt.questionnaireList', { orgId: $stateParams.orgId });
      }
    };
  },
]);
