import Ladda from 'ladda';

import createHtmlTemplate from './pdf/createRelayBillingMethodHtmlTemplate';

const { Blob, saveAs } = window;

function invoiceDetailsCtrl(
  $scope,
  $http,
  API_BASE_URL,
  toaster,
  SweetAlert,
  $filter,
  loadingScreenFactory,
  WORKERS_COMPENSATION_KEY,
  MASS_MARKET_KEY,
  HEALTHCARE_MASS_MARKET_KEY,
  PAYMENT_METHODS,
  $stateParams,
  INVOICE_TYPES,
) {
  const {
    year: invoiceyear,
    month: invoicemonth,
    org: orgId,
    type: invoiceType,
  } = $stateParams;
  Object.assign($scope, {
    moment,
    invoiceReceipt: {},
    accountHolderTypes: [
      {
        name: 'individual',
        value: 'Individual',
      },
      {
        name: 'company',
        value: 'Company',
      },
    ],
    accordions: [],
    isClientOrg: true,
    micro_deposits: {},
    buttonClicked: false,
    opiMonthlyInvoice: {},
    vrMonthlyInvoice: {},
    dateOptions: {
      'year-format': "'yy'",
      'starting-day': 1,
      'datepicker-mode': "'month'",
      'min-mode': 'month',
      showDates: false,
    },
    isTransportationTab: INVOICE_TYPES.RELAY === invoiceType,
    transportationMonthlyInvoice: '',
    translationMonthlyInvoice: '',
    monthlyInvoicePaymentMethod: '',
    onPayInvoicePage: false,
    viewReceiptPage: false,
    displayInvoiceRelay: false,
    newAccountOptions: {},
    newAccountFields: {
      country: 'US',
      currency: 'usd',
      routing_number: '',
      account_number: '',
      account_holder_name: '',
      account_holder_type: '',
    },
  });
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  $scope.userDetails = userDetails;
  if ($filter('isMasterOrg')(userDetails.organisation.org_level)) {
    $scope.isClientOrg = false;
  }
  function getPaymentProfiles(id) {
    $scope.accordions = [];
    const data = {
      params: {
        owner_id: id,
        owner_type: 'org',
        invoice_profile: true,
      },
    };
    const getPaymentProfilesCall = $http.get(`${API_BASE_URL}paymentProfiles`, data);
    loadingScreenFactory.showLoadingScreen();
    getPaymentProfilesCall.then(
      (result) => {
        loadingScreenFactory.hideLoadingScreen();
        if (result.data.success) {
          const resultData = result.data.data;
          if (resultData.length > 0) {
            for (let i = 0; i < resultData.length; i += 1) {
              if (resultData[i].payment_method === PAYMENT_METHODS.BANK_ACCOUNT) {
                $scope.accordions.unshift({
                  type: resultData[i].stripe_profile.object === 'bank_account' ? 'existing-account' : '',
                  id: resultData[i].id,
                  accountNumber: resultData[i].stripe_profile.last4,
                  open: false,
                  status: resultData[i].stripe_profile.status,
                });
              }
            }
          }
          $scope.accordions = _.orderBy($scope.accordions, ['type', 'status'], ['desc', 'desc']);
          const monthlyInvoiceMethodInInvoicePaymentMethods = _.find($scope.accordions, {
            id: ($scope.monthly_invoice_method && $scope.monthly_invoice_method.id) || 0,
          });
          if (monthlyInvoiceMethodInInvoicePaymentMethods) {
            monthlyInvoiceMethodInInvoicePaymentMethods.open = true;
            const monthlyInvoiceMethodIndex = _.findIndex($scope.accordions, { id: $scope.monthly_invoice_method.id });
            $scope.accordions.splice(0, 0, $scope.accordions.splice(monthlyInvoiceMethodIndex, 1)[0]);
          }
          $scope.accordions.push({
            type: 'new-account',
            id: 'AddNewAccount',
            open: false,
          });
        }
      },
      (err) => {
        toaster.pop({
          type: 'error',
          title: err,
          showCloseButton: true,
          timeout: 10000,
        });
      },
    );
  }

  $scope.goBack = function goBack() {
    window.history.back();
  };

  function payMonthlyInvoice(paymentProfileId, InvoiceId) {
    const data = {
      payment_profile_id: paymentProfileId,
    };

    const payMonthlyInvoiceCall = $http.post(`${API_BASE_URL}invoice/paymonthlyinvoice/${InvoiceId}`, data);
    loadingScreenFactory.showLoadingScreen();
    payMonthlyInvoiceCall.then(
      (result) => {
        if (result.data.success) {
          Object.assign($scope, {
            onPayInvoicePage: true,
            viewReceiptPage: true,
            newAccountFields: {
              country: 'US',
              currency: 'usd',
              routing_number: '',
              account_number: '',
              account_holder_name: '',
              account_holder_type: '',
            },
          });
          $scope.invoiceReceipt = result.data.data;
          $scope.invoiceReceipt.charged_datetime = moment($scope.invoiceReceipt.charged_datetime).format('MM/DD/YY');
          $scope.invoiceReceipt.confirmationEmail = _.get($scope.invoiceReceipt, 'user.username', userDetails.username);
          $scope.invoiceReceipt.paymentMethod = '';
          if (_.get($scope.invoiceReceipt, 'payment_profile.stripe_profile.object') === 'bank_account') {
            $scope.invoice_pending = true;
            $scope.invoiceReceipt.paymentMethod = `${_.get($scope.invoiceReceipt, 'payment_profile.stripe_profile.bank_name')} ending in ${_.get(
              $scope.invoiceReceipt,
              'payment_profile.stripe_profile.last4',
            )}`;
          }
        } else {
          toaster.pop({
            type: 'error',
            title: result.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        }
        loadingScreenFactory.hideLoadingScreen();
      },
      (errorResult) => {
        loadingScreenFactory.hideLoadingScreen();
        toaster.pop({
          type: 'error',
          title: _.get(errorResult, 'data.message'),
          showCloseButton: true,
          timeout: 10000,
        });
      },
    );
  }

  function createObjForPrint(filename) {
    const templateObj = {
      invoicePaid: $scope.invoice_paid,
      invoiceNo: $scope.invoice_no,
      orgName: $scope.org_name,
      orgAddress: $scope.org_address,
      orgAddress1: $scope.org_address1,
      orgAddress2: $scope.org_address2,
      dueStatus: $scope.dueStatus,
      totalAdministrativeCost: parseFloat($scope.total_administrative_cost, 10),
      totalCostOfCancellationFees: parseFloat($scope.total_cost_of_cancellation_fees, 10),
      totalCostOfCompltRides: parseFloat($scope.total_cost_of_complt_rides, 10),
      isLanguageInvoice: !$scope.isTransportationTab,
      totalApptCountFinal: parseInt($scope.total_appt_count_final, 10) || 0,
      totalRidesCountFinal: parseInt($scope.total_rides_count_final, 10) || 0,
      totalRidesCancelCountFinal: parseInt($scope.total_rides_cancel_count_final, 10) || 0,
      fileName: filename,
      dateProperties: {
        invoiceDate: moment($scope.showLastDate).format('MMMM DD, YYYY'),
        showDate: $scope.showDate,
      },
      totalVideoAppointments: 0,
      totalVideoAppointmentsCost: 0,
      totalOpiAppointments: 0,
      totalOpiAppointmentsCost: 0,
    };
    if ($scope.vrMonthlyInvoice) {
      templateObj.totalVideoAppointments = parseInt($scope.vrMonthlyInvoice.total_appt_count, 10) || 0;
      templateObj.totalVideoAppointmentsCost = parseFloat($scope.vrMonthlyInvoice.grand_total, 10) || 0;
    }
    if ($scope.opiMonthlyInvoice) {
      templateObj.totalOpiAppointments = parseInt($scope.opiMonthlyInvoice.total_appt_count, 10) || 0;
      templateObj.totalOpiAppointmentsCost = parseFloat($scope.opiMonthlyInvoice.grand_total, 10) || 0;
    }
    return templateObj;
  }

  $scope.printInvoice = (filename) => {
    /**
     * In case of relay, dynamically generate the pdf on ui
     */
    if (filename) {
      const popupWinindow = window.open('', '_blank', 'width=800,height=900,scrollbars=yes,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
      popupWinindow.document.open();
      popupWinindow.document.write(createHtmlTemplate(createObjForPrint(filename)));
      popupWinindow.document.close();
    } else {
      toaster.pop({
        type: 'error',
        title: 'File name required',
        showCloseButton: true,
        timeout: 10000,
      });
    }
  };

  $scope.downloadInvoice = (filename) => {
    if (filename) {
      const downloadPdfFile = $http({
        url: `${API_BASE_URL}download/monthlyInvoicePdf/${filename}`,
        method: 'GET',
        data: { filename },
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/pdf',
        },
        responseType: 'arraybuffer',
      });

      downloadPdfFile.then((result) => {
        const blob = new Blob([result.data], {
          type: 'application/pdf',
        });
        if (blob.size < 70) {
          toaster.pop({
            type: 'error',
            title: 'No record found!',
            showCloseButton: true,
            timeout: 10000,
          });
        } else {
          saveAs(blob, filename);
        }
      });
    } else {
      toaster.pop({
        type: 'error',
        title: 'File name required',
        showCloseButton: true,
        timeout: 10000,
      });
    }
  };

  $scope.downloadXlsxFile = (filename) => {
    if (filename.length <= 0) {
      toaster.pop({
        type: 'error',
        title: 'Missing file to download.',
        showCloseButton: true,
        timeout: 10000,
      });
    } else {
      const loaderObj = Ladda.create(document.querySelector('.export_ride_details'));
      loaderObj.start();

      const downloadXlsxFileCall = $http({
        url: `${API_BASE_URL}export/downloadXlsxFile/${filename}`,
        method: 'GET',
        data: {
          filename,
        },
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'arraybuffer',
      });

      downloadXlsxFileCall.then((result) => {
        const blob = new Blob([result.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        if (blob.size < 70) {
          toaster.pop({
            type: 'error',
            title: 'No record found! Please try changing date range!',
            showCloseButton: true,
            timeout: 10000,
          });
        } else {
          saveAs(blob, filename);
        }
        loaderObj.stop();
      });
    }
  };

  const getInvoiceButtonClicked = () => {
    Object.assign($scope, {
      onPayInvoicePage: false,
      viewReceiptPage: false,
    });
    if (invoiceType && invoiceyear && invoicemonth && orgId) {
      loadingScreenFactory.showLoadingScreen();
      const url = `${API_BASE_URL}monthlyInvoices`;
      const payload = {
        org_id: orgId,
        month: invoicemonth,
        year: invoiceyear,
        invoice_type: invoiceType,
      };
      const invoiceDateRangeCall = $http({
        url,
        method: 'PUT',
        data: payload,
        headers: {
          'Content-type': 'application/json',
        },
      });

      invoiceDateRangeCall.then(
        (result) => {
          const d = new Date(invoiceyear, invoicemonth, 0);
          Object.assign($scope, {
            buttonClicked: true,
            showDate: `${
              moment.months()[parseInt(invoicemonth, 10) - 1]
            } 1 - ${
              moment.months()[parseInt(invoicemonth, 10) - 1]
            } ${
              d.getDate()
            }, ${
              invoiceyear
            }`,
            dueDate: `${moment.months()[parseInt(invoicemonth, 10) - 1]} ${d.getDate()}, ${invoiceyear}`,
          });

          if (result.data.success && result.data.data.length > 0) {
            $scope.recordsFound = true;
            $scope.invoiceRecords = result.data.data;
            if ($scope.isTransportationTab) {
              $scope.transportationMonthlyInvoice = !_.isEmpty($scope.invoiceRecords) && $scope.invoiceRecords;
            } else {
              $scope.translationMonthlyInvoice = !_.isEmpty($scope.invoiceRecords) && $scope.invoiceRecords;
            }
            Object.assign($scope, {
              org_id: '',
              invoice_no: '',
              org_address: '',
              org_address1: '',
              org_address2: '',
              client_id: '',
              parent_org_id: '',
              total_cost_of_complt_rides: 0,
              total_cost_of_cancellation_fees: 0,
              cancellation_count: 0,
              total_administrative_cost: 0,
              total_appt_count_final: 0,
              total_rides_count_final: 0,
              total_rides_cancel_count_final: 0,
              pdfUrl: '',
              totalDues: 0,
              showLastDate: '',
              org_name: '',
              xlsx_file_name: '',
              showExportRideDetailBtn: false,
              org_payment_profile: '',
              monthly_invoice_method: '',
              isWorkerMarketSegment: true,
            });

            let totalRidesCancelCountFinal = 0;
            let totalApptCountFinal = 0;
            let totalRidesCountFinal = 0;

            const records = result.data.data;
            const organisationPayInvoiceSetting = !!_.find(
              records[0].organisation.settings,
              setting => setting.setting_name === 'pay_invoice' && setting.setting_value.toLowerCase() === 'true',
              null,
            );
            const marketSegmentPayInvoiceSetting = !!_.find(
              _.get(records[0], 'organisation.market_segment.settings'),
              setting => setting.setting_name === 'pay_invoice' && setting.setting_value.toLowerCase() === 'true',
              null,
            );
            $scope.vrMonthlyInvoice = _.find(
              records,
              {
                appointment_type: 'VR',
              },
              {},
            );
            $scope.opiMonthlyInvoice = _.find(
              records,
              {
                appointment_type: 'OPI',
              },
              {},
            );
            for (let i = 0; i < records.length; i += 1) {
              if (i === 0) {
                $scope.org_payment_profile = _.get(records[0], 'organisation.payment_profile');
                $scope.monthly_invoice_method = _.get(records[0], 'organisation.monthly_invoice_payment_profile');
                $scope.showLastDate = records[0].createdAt;
                if (records[0].invoice_pdf_url && records[0].invoice_pdf_url !== '') {
                  $scope.pdfUrl = records[0].invoice_pdf_url;
                  const pieces = $scope.pdfUrl.split('/');
                  if (pieces) {
                    $scope.file_name = pieces[pieces.length - 1];
                  }
                }
                if (records[0].invoice_xlsx_url && records[0].invoice_xlsx_url !== '') {
                  $scope.xlsxUrl = records[0].invoice_xlsx_url;
                  const pieces = $scope.xlsxUrl.split('/');
                  if (pieces) {
                    $scope.xlsx_file_name = pieces[pieces.length - 1];
                    $scope.showExportRideDetailBtn = true;
                  }
                }
                $scope.isWorkerMarketSegment = !!(
                  records[0].organisation
                && records[0].organisation.market_segment
                && records[0].organisation.market_segment.key === WORKERS_COMPENSATION_KEY
                );
                if (records[0].organisation && records[0].organisation.market_segment && records[0].organisation.market_segment.billing_method === 'relay') {
                  $scope.displayInvoiceRelay = true;
                } else {
                  $scope.displayInvoiceRelay = false;
                }
                $scope.showPayInvoiceButtons = organisationPayInvoiceSetting
                && [WORKERS_COMPENSATION_KEY, MASS_MARKET_KEY, HEALTHCARE_MASS_MARKET_KEY].includes(_.get(records[0], 'organisation.market_segment.key'))
                && marketSegmentPayInvoiceSetting
                && userDetails.role === 'org_superadmin'
                && (($scope.isTransportationTab) || !$scope.isTransportationTab);

                $scope.dueStatus = (records[0].invoice_status === 'Disabled') ? 'Disabled due to Non-Payment' : records[0].invoice_status;
                $scope.org_id = records[0].org_id;
                $scope.invoice_no = records[0].invoice_no;
                $scope.invoice_paid = records[0].invoice_paid ? records[0].invoice_paid : false;
                $scope.invoice_pending = _.get(records[0], 'monthly_invoice_payment.status') === 'pending';
                $scope.charge_failed = _.get(records[0], 'monthly_invoice_payment.status') === 'charge failed';
                $scope.invoiceReceipt = records[0].monthly_invoice_payment || {};
                $scope.monthlyInvoicePaymentMethod = _.get('invoiceReceipt', 'payment_profile.payment_method');
                $scope.charge_failed_account_last4 = _.get($scope.invoiceReceipt, 'payment_profile.stripe_profile.last4');
                if (records[0].invoice_paid || $scope.invoice_pending) {
                  $scope.invoiceReceipt.charged_datetime = moment($scope.invoiceReceipt.charged_datetime).format('MM/DD/YY');
                  $scope.invoiceReceipt.confirmationEmail = _.get($scope.invoiceReceipt, 'user.username', userDetails.username);
                  $scope.invoiceReceipt.paymentMethod = '';
                  if (_.get($scope.invoiceReceipt, 'payment_profile.stripe_profile.object') === 'bank_account') {
                    $scope.invoiceReceipt.paymentMethod = `${_.get($scope.invoiceReceipt, 'payment_profile.stripe_profile.bank_name')} ending in ${_.get(
                      $scope.invoiceReceipt,
                      'payment_profile.stripe_profile.last4',
                    )}`;
                  }
                }
                $scope.invoice_id = records[0].id;
                if (records[0].organisation) {
                  if (records[0].organisation.address) {
                    const orgDetails = records[0].organisation.address;
                    $scope.org_address = orgDetails.address1 ? orgDetails.address1 : '';
                    $scope.org_address1 = orgDetails.city ? orgDetails.city : '';
                    $scope.org_address1 += orgDetails.state ? `, ${orgDetails.state.state_name}` : '';
                    $scope.org_address2 = orgDetails.country ? orgDetails.country : '';
                    $scope.org_address2 += orgDetails.zip ? `, ${orgDetails.zip}` : '';
                  }
                  $scope.org_name = records[0].organisation.name;
                  $scope.client_id = records[0].organisation.client_id;
                  $scope.parent_org_id = records[0].organisation.parent_org_id;
                }
              }

              $scope.total_cost_of_complt_rides = (records[i].completed_rides_cost ? records[i].completed_rides_cost : 0) + $scope.total_cost_of_complt_rides;

              if (records[i].driver_canceled_rides_cost) {
                $scope.total_cost_of_cancellation_fees = records[i].driver_canceled_rides_cost + $scope.total_cost_of_cancellation_fees;
                $scope.cancellation_count += 1;
              }

              $scope.total_administrative_cost = (records[i].total_administrative_cost || 0) + $scope.total_administrative_cost;

              totalApptCountFinal += records[i].total_appt_count;
              totalRidesCountFinal += records[i].total_rides_count;
              totalRidesCancelCountFinal += records[i].total_rides_cancel_count;
            }
            Object.assign($scope, {
              total_rides_count_final: totalRidesCountFinal,
              total_rides_cancel_count_final: totalRidesCancelCountFinal,
              total_appt_count_final: totalApptCountFinal,
            });
            $scope.total_cost_of_complt_rides = $scope.round($scope.total_cost_of_complt_rides, 2).toFixed(2);
            $scope.total_cost_of_cancellation_fees = $scope.round($scope.total_cost_of_cancellation_fees, 2).toFixed(2);
            $scope.total_administrative_cost = $scope.round($scope.total_administrative_cost, 2).toFixed(2);
            $scope.totalDues = (
              parseFloat($scope.total_cost_of_complt_rides)
                + parseFloat($scope.total_cost_of_cancellation_fees)
                + parseFloat($scope.total_administrative_cost)
            ).toFixed(2);

            loadingScreenFactory.hideLoadingScreen();
          } else {
            if ($scope.isTransportationTab) {
              $scope.transportationMonthlyInvoice = '';
            } else {
              $scope.translationMonthlyInvoice = '';
            }
            loadingScreenFactory.hideLoadingScreen();
            toaster.pop({
              type: 'error',
              title: 'No record found for selected month.',
              showCloseButton: true,
              timeout: 10000,
            });
            $scope.recordsFound = false;
            $scope.displayInvoiceRelay = false;
          }
        },
        (errorResult) => {
          loadingScreenFactory.hideLoadingScreen();
          toaster.pop({
            type: 'error',
            title: _.get(errorResult, 'data.message'),
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    }
  };
  getInvoiceButtonClicked();

  $scope.payInvoiceClicked = () => {
    $scope.onPayInvoicePage = true;
    getPaymentProfiles($scope.org_id);
  };

  $scope.viewReceiptClicked = () => {
    Object.assign($scope, {
      onPayInvoicePage: true,
      viewReceiptPage: true,
    });
  };

  $scope.backPayInvoicePage = () => {
    Object.assign($scope, {
      onPayInvoicePage: false,
      viewReceiptPage: false,
      newAccountFields: {
        country: 'US',
        currency: 'usd',
        routing_number: '',
        account_number: '',
        account_holder_name: '',
        account_holder_type: '',
      },
    });
    getInvoiceButtonClicked();
  };

  $scope.existingAccountPaymentSubmit = (account) => {
    payMonthlyInvoice(account.id, $scope.invoice_no);
  };

  $scope.newAccountPaymentSubmit = () => {
    $scope.newAccountOptions.getToken($scope.newAccountFields).then(
      (result) => {
        const body = {
          ownerType: 'org',
          ownerId: $scope.org_id,
          token: result.token.id,
          invoiceProfile: true,
        };
        loadingScreenFactory.showLoadingScreen();
        $http
          .post(`${API_BASE_URL}paymentProfiles/bankaccount`, body)
          .then(({
            data: {
              data: paymentProfile,
              success,
              message,
            },
          }) => {
            loadingScreenFactory.hideLoadingScreen();
            if (!success) {
              toaster.pop({
                type: 'error',
                title: message,
                showCloseButton: true,
                timeout: 10000,
              });
              return;
            }
            $scope.accordions.splice($scope.accordions.length - 2, 0, {
              type: 'existing-account',
              id: paymentProfile.payment_profile_id,
              accountNumber: paymentProfile.last4,
              open: true,
              status: paymentProfile.status,
            });
            $scope.newAccountFields = {
              country: 'US',
              currency: 'usd',
              routing_number: '',
              account_number: '',
              account_holder_name: '',
              account_holder_type: '',
            };
            SweetAlert.swal({
              title: 'Bank Account Information Received',
              text: 'In 2-3 business days, you will receive two small(normally less than one dollar) deposits to this account from us.'
                  + ' Once you receive them, enter those amounts under "Micro Deposits" to complete setup.',
              type: 'success',
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'OK',
            });
          })
          .catch((err) => {
            loadingScreenFactory.hideLoadingScreen();
            toaster.pop({
              type: 'error',
              title: err.message || (err.data && err.data.message) || '',
              showCloseButton: true,
              timeout: 10000,
            });
          });
      },
      () => {
        loadingScreenFactory.hideLoadingScreen();
      },
    );
  };

  $scope.verifyAccount = (accountDetails) => {
    const body = {
      amounts: [$scope.micro_deposits.amount_one, $scope.micro_deposits.amount_two],
    };
    loadingScreenFactory.showLoadingScreen();
    $http
      .post(`${API_BASE_URL}paymentProfiles/${accountDetails.id}/bankaccount/verify`, body)
      .then((response) => {
        if (response.data.success) {
          toaster.pop({
            type: 'success',
            title: response.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
          const selectedAccount = _.find(
            $scope.accordions,
            {
              id: accountDetails.id,
            },
            null,
          );
          if (selectedAccount) {
            selectedAccount.status = 'verified';
          }
          $scope.micro_deposits = {};
        } else {
          $scope.micro_deposits = {};
          toaster.pop({
            type: 'error',
            title: response.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        }
        loadingScreenFactory.hideLoadingScreen();
      })
      .catch((errorResult) => {
        $scope.micro_deposits = {};
        toaster.pop({
          type: 'error',
          title: errorResult.message || (errorResult.data && errorResult.data.message) || '',
          showCloseButton: true,
          timeout: 10000,
        });
        loadingScreenFactory.hideLoadingScreen();
      });
  };

  $scope.deletePaymentProfile = (event, accordion) => {
    event.preventDefault();
    event.stopPropagation();
    SweetAlert.swal(
      {
        title: 'Delete Payment Method',
        text: 'Are you sure you want to delete this payment method?',
        type: 'warning',
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes! Delete it',
        closeOnConfirm: true,
        showCancelButton: true,
        closeOnCancel: true,
      },
      (isConfirm) => {
        if (isConfirm) {
          loadingScreenFactory.showLoadingScreen();
          $http
            .delete(`${API_BASE_URL}paymentProfiles/${accordion.id}`)
            .then((response) => {
              if (response.data.success) {
                toaster.pop({
                  type: 'success',
                  title: response.data.message,
                  showCloseButton: true,
                  timeout: 10000,
                });
                getPaymentProfiles($scope.org_id);
              } else {
                toaster.pop({
                  type: 'error',
                  title: response.data.message,
                  showCloseButton: true,
                  timeout: 10000,
                });
              }
              loadingScreenFactory.hideLoadingScreen();
            })
            .catch((errorResult) => {
              toaster.pop({
                type: 'error',
                title: errorResult.message || (errorResult.data && errorResult.data.message) || '',
                showCloseButton: true,
                timeout: 10000,
              });
              loadingScreenFactory.hideLoadingScreen();
            });
        }
      },
    );
  };
}

angular
  .module('relayHealth')
  .controller('invoiceDetailsCtrl', [
    '$scope',
    '$http',
    'API_BASE_URL',
    'toaster',
    'SweetAlert',
    '$filter',
    'loadingScreenFactory',
    'WORKERS_COMPENSATION_KEY',
    'MASS_MARKET_KEY',
    'HEALTHCARE_MASS_MARKET_KEY',
    'PAYMENT_METHODS',
    '$stateParams',
    'INVOICE_TYPES',
    invoiceDetailsCtrl,
  ])
  .filter(
    'invoiceDateFilter',
    () => function invoiceDateFilter(input, moment) {
      return moment(input).format('MMMM DD, YYYY');
    },
  );
