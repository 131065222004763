angular.module('relayHealth').controller('addUsersCtrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  '$state',
  '$timeout',
  'signupStateService',
  'signupAuthCheckService',
  'signupInitialStateService',
  '$stateParams',
  'signupNavigationValidationService',
  'updateSignupStep',
  'loadingScreenFactory',
  '$transitions',
  function addUsersCtrl(
    $scope,
    $http,
    API_BASE_URL,
    $state,
    $timeout,
    signupStateService,
    signupAuthCheckService,
    signupInitialStateService,
    $stateParams,
    signupNavigationValidationService,
    updateSignupStep,
    loadingScreenFactory,
    $transitions,
  ) {
    $scope.usersFormTitle = 'Add User';
    $scope.userTypes = [];
    $scope.showAddUserButton = true;
    $scope.user = {};
    $scope.state = {};
    $scope.addUsersResponseError = '';
    $scope.addUsersResponseSuccess = '';
    $scope.stageNumber = 5;
    $scope.tempUser = {
      first_name: '',
      last_name: '',
      email: '',
      role: '',
    };
    $scope.error = {
      first_name: '',
      last_name: '',
      email: '',
    };

    $scope.pagination = {
      totalItems: 0,
      currentPage: 1,
      lastItem: 0,
      itemsPerPage: 20,
    };

    function adjustPagination() {
      $scope.pagination.lastItem = ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage + 20;
      if ($scope.pagination.lastItem > $scope.pagination.totalItems) {
        $scope.pagination.lastItem = $scope.pagination.totalItems;
      }
    }

    function init() {
      $scope.state = signupInitialStateService.initialState();
      const currentPage = parseInt($scope.state.currentPage, 10);
      let hasErrors = false;
      if ($scope.state.currentPage < 5) {
        if (currentPage < 4) {
          signupNavigationValidationService.termsOfServiceValidation((errors) => {
            if (Object.keys(errors).length > 0) {
              hasErrors = true;
              $state.go($scope.state.signupType === 'business' ? 'main.business_signup_terms_of_service' : 'main.rider_signup_terms_of_service', {
                errors,
              });
            }
          });
        }

        if (currentPage < 5) {
          if ($scope.state.signupType === 'business') {
            signupNavigationValidationService.organizationValidation((errors) => {
              if (Object.keys(errors).length > 0) {
                hasErrors = true;
                $state.go('main.business_signup_add_organization', { errors });
              }
            });
          } else {
            signupNavigationValidationService.accountInfoValidation((errors) => {
              if (Object.keys(errors).length > 0) {
                hasErrors = true;
                $state.go('main.rider_signup_account_information', { errors });
              }
            });
          }
        }
      }

      if ($stateParams.errors) {
        const { errors } = $stateParams.errors;
        Object.keys(errors).forEach((err) => {
          $scope.error[err] = errors[err];
        });

        $timeout(() => {
          $scope.error = {
            first_name: '',
            last_name: '',
            email: '',
          };
        }, 10000);
      }
      if (!hasErrors) {
        if ($scope.state.signupType === 'business') {
          $scope.userTypes = [{ name: 'User', id: 1, role: 'orgadmin' }, { name: 'Admin', id: 2, role: 'org_superadmin' },
            { role: 'org_reporting', id: 3, name: 'Reporting' }];
        }

        if ($scope.stageNumber > $scope.state.latestStage) {
          $scope.state.latestStage = 5;
          signupStateService.setState('signupState', $scope.state);
        }

        $scope.state.currentPage = 5;
        signupStateService.setState('signupState', $scope.state);

        signupAuthCheckService.addUsersAllowed();

        const tempUser = signupStateService.getState('tempUser');
        if (tempUser && Object.keys(tempUser).length > 2) {
          $scope.showAddUserButton = tempUser.showAddUserButton;
          if (!tempUser.showCancelButton) {
            $timeout(() => {
              $scope.addUsersForm.$setPristine();
            }, 0);
          } else {
            $timeout(() => {
              $scope.addUsersForm.$setDirty();
            }, 0);
          }
          delete tempUser.showAddUserButton;
          $scope.user = tempUser;
        }
        const headers = {
          username: $scope.state.email,
          usertoken: $scope.state.usertoken,
          'Content-Type': 'application/json',
        };

        $http
          .get(`${API_BASE_URL}rider/members`, headers)
          .then(({ data }) => {
            if (data.success) {
              const users = data.data || [];
              for (let i = 0; i < users.length; i += 1) {
                users[i].type = $scope.userTypes[users[i].role === 'orgadmin' ? 0 : 1];
              }
              $scope.state.users = users;
              $scope.pagination.totalItems = data.data.length;
              adjustPagination();
              signupStateService.setState('signupState', $scope.state);
            } else {
              $scope.pagination.totalItems = 0;
            }
          })
          .catch(() => {
            $scope.pagination.totalItems = 0;
            $scope.state.users = [];
            signupStateService.setState('signupState', $scope.state);
          });
        updateSignupStep(4);
      }
    }

    init();

    $scope.addUser = () => {
      loadingScreenFactory.showLoadingScreen();
      const state = signupStateService.getState('signupState');
      const body = angular.copy($scope.user);
      body.signupType = $scope.state.signupType;
      body.email = body.email ? body.email.toLowerCase() : '';
      body.step = 5;
      body.orgId = $scope.state.signupType === 'business' ? $scope.state.businessInfo.orgId : $scope.state.accountInfo.orgId;
      body.role = $scope.state.signupType === 'business' ? body.type.role : 'riderUser';
      delete body.type;

      const headers = {
        username: state.email,
        usertoken: state.usertoken,
        'Content-Type': 'application/json',
      };

      $http
        .put(`${API_BASE_URL}rider/update`, [body], headers)
        .then(({ data }) => {
          if (data.success) {
            loadingScreenFactory.hideLoadingScreen();
            $scope.addUsersResponseSuccess = data ? data.message : 'User has been added';
            $scope.user.id = data.tempMemberId;
            $scope.user.role = $scope.state.signupType === 'business' ? $scope.user.type.role : 'riderUser';
            $scope.state.users.push($scope.user);
            const signupState = signupStateService.getState('signupState');
            signupState.users.push($scope.user);
            signupStateService.setState('signupState', signupState);
            $scope.user = {};
            $scope.addUsersForm.$setPristine();
            $scope.pagination.totalItems = $scope.state.users.length;
            adjustPagination();
            signupStateService.clearState('tempUser');
            $('#current-app').animate({ scrollTop: $('#current-app #wrapper').height() }, 'slow');
            updateSignupStep(4);
            $timeout(() => {
              $scope.addUsersResponseSuccess = '';
            }, 5000);
          } else {
            const errors = data.errorMessages;
            if (typeof errors === 'string') {
              $scope.addUsersResponseError = errors;
            } else {
              Object.keys(errors).forEach((err) => {
                $scope.error[err] = errors[err];
              });
            }
            $timeout(() => {
              $scope.addUsersResponseError = '';
              $scope.errors = {
                first_name: '',
                last_name: '',
                email: '',
              };
            }, 10000);
          }
        })
        .catch(({ data }) => {
          const errors = data.errorMessages;
          if (typeof errors === 'string') {
            $scope.addUsersResponseError = errors;
          } else {
            Object.keys(errors).forEach((err) => {
              $scope.error[err] = errors[err];
            });
          }
          $timeout(() => {
            $scope.addUsersResponseError = '';
            $scope.errors = {
              first_name: '',
              last_name: '',
              email: '',
            };
          }, 10000);
        });
    };

    $scope.nextPage = () => {
      // check if the form is valid, if yes then allow redirection
      if ($scope.state.signupType === 'business' && !$scope.state.created_by_user) {
        $state.go('main.business_signup_payment');
      } else if ($scope.state.signupType === 'rider') {
        $state.go('main.rider_signup_payment');
      } else {
        // the data is unwanted or corrupted, logout the user after showing a message
        $state.go('main.business_signup_summary');
      }
    };

    $scope.cancelUpdateUser = () => {
      $scope.addUsersForm.$setPristine();
      $scope.user = {};
      $scope.usersFormTitle = 'Add User';
      $scope.showAddUserButton = true;
    };

    $scope.updateUser = () => {
      const state = signupStateService.getState('signupState');
      const body = angular.copy($scope.user);
      body.signupType = $scope.state.signupType;
      body.email = body.email ? body.email.toLowerCase() : '';
      body.step = 5;
      body.orgId = $scope.state.signupType === 'business' ? $scope.state.businessInfo.orgId : $scope.state.accountInfo.orgId;
      body.role = $scope.state.signupType === 'business' ? body.type.role : 'riderUser';
      const userRole = body.role;
      delete body.type;

      const { users } = $scope.state;
      const userToCheck = $scope.user;
      let index = -1;

      for (let i = 0; i < users.length; i += 1) {
        if (userToCheck.id === users[i].id) {
          index = i;
        }
      }

      $http
        .put(`${API_BASE_URL}rider/update`, [body])
        .then(({ data }) => {
          if (data.success) {
            $scope.addUsersResponseSuccess = data ? data.message : 'User has been updated';
            userToCheck.role = userRole;
            $scope.state.users[index] = userToCheck;
            state.users[index] = userToCheck;
            signupStateService.setState('signupState', state);
            $scope.addUsersForm.$setPristine();
            $scope.user = {};
            $scope.usersFormTitle = 'Add User';
            $scope.showAddUserButton = true;
            signupStateService.clearState('tempUser');
            $timeout(() => {
              $scope.addUsersResponseSuccess = '';
            }, 5000);
          } else {
            const errors = data.errorMessages;
            if (typeof errors === 'string') {
              $scope.addUsersResponseError = errors || 'Could not update user';
            } else {
              Object.keys(errors).forEach((err) => {
                $scope.error[err] = errors[err];
              });
            }
            $timeout(() => {
              $scope.addUsersResponseError = '';
              $scope.errors = {
                first_name: '',
                last_name: '',
                email: '',
              };
            }, 10000);
          }
        })
        .catch(({ data }) => {
          const errors = data.errorMessages;
          if (typeof errors === 'string') {
            $scope.addUsersResponseError = errors || 'Could not update user';
          } else {
            Object.keys(errors).forEach((err) => {
              $scope.error[err] = errors[err];
            });
          }
          $timeout(() => {
            $scope.addUsersResponseError = '';
            $scope.errors = {
              first_name: '',
              last_name: '',
              email: '',
            };
          }, 10000);
        });
    };

    $scope.removeUser = (index) => {
      const state = signupStateService.getState('signupState');
      const body = angular.copy($scope.state.users[index]);
      body.orgId = $scope.state.signupType === 'business' ? $scope.state.businessInfo.orgId : $scope.state.accountInfo.orgId;
      body.signupType = $scope.state.signupType;
      body.step = 5;

      $http
        .delete(`${API_BASE_URL}rider/member/${body.id}`, body)
        .then(({ data }) => {
          if (data.success) {
            $scope.state.users.splice(index, 1);
            if (state) {
              state.users.splice(index, 1);
              signupStateService.setState('signupState', state);
            }
            $scope.pagination.totalItems = $scope.state.users.length;
            adjustPagination();
            $scope.cancelUpdateUser();
          } else {
            const errors = data.errorMessages;
            if (typeof errors === 'string') {
              $scope.addUsersResponseError = errors || 'Could not delete user';
            } else {
              Object.keys(errors).forEach((err) => {
                $scope.error[err] = errors[err];
              });
            }
            $timeout(() => {
              $scope.addUsersResponseError = '';
              $scope.errors = {
                first_name: '',
                last_name: '',
                email: '',
              };
            }, 10000);
          }
        })
        .catch(({ data }) => {
          const errors = data.errorMessages;
          if (typeof errors === 'string') {
            $scope.addUsersResponseError = errors || 'Could not delete user';
          } else {
            Object.keys(errors).forEach((err) => {
              $scope.error[err] = errors[err];
            });
          }
          $timeout(() => {
            $scope.addUsersResponseError = '';
            $scope.errors = {
              first_name: '',
              last_name: '',
              email: '',
            };
          }, 10000);
        });
    };

    $scope.previousPage = () => {
      if ($scope.state.signupType === 'rider') {
        $state.go('main.rider_signup_account_information');
      } else if ($scope.state.signupType === 'business') {
        $state.go('main.business_signup_add_organization');
      } else {
        $state.go('main.dashboard.superAdminDashboard');
      }
    };

    $scope.updateUserHtml = (index) => {
      $scope.usersFormTitle = 'Update User';
      // hide the add user button and show the update user user button
      $scope.showAddUserButton = false;
      angular.copy($scope.state.users[index], $scope.user);
      $('#current-app').animate({ scrollTop: 0 }, 'slow');
    };

    const onStageChangeStart = $transitions.onStart({}, () => {
      $scope.user.showAddUserButton = $scope.showAddUserButton;
      $scope.user.showCancelButton = $scope.addUsersForm.$dirty;
      signupStateService.setState('tempUser', $scope.user);
    });

    $scope.$on('$destroy', () => {
      // destroy transition watcher
      onStageChangeStart();
    });
  },
]);
