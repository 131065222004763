angular.module('relayHealth').controller('checkAvailabilityCntrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  'toaster',
  'loadingScreenFactory',
  function checkAvailabilityCntrl($scope, $http, API_BASE_URL, toaster, loadingScreenFactory) {
    $scope.providerTypes = [
      {
        id: 0,
        name: 'All Vendors',
      },
      {
        id: 2,
        name: 'Lyft',
      },
      {
        id: 1,
        name: 'Uber',
      },
    ];

    $scope.selectedProviderType = 0;

    function getLatLongFromZip(zip) {
      const geocoder = new google.maps.Geocoder();
      return new Promise((resolve) => {
        geocoder.geocode(
          {
            componentRestrictions: {
              postalCode: zip,
              country: 'US',
            },
          },
          (results, status) => {
            if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
              const location = {
                lat: results[0].geometry.location.lat(),
                long: results[0].geometry.location.lng(),
              };
              resolve(location);
            } else {
              loadingScreenFactory.hideLoadingScreen();
              toaster.pop({
                type: 'error',
                title: 'Enter a valid zip',
                showCloseButton: true,
                timeout: 10000,
              });
            }
          },
        );
      });
    }

    $scope.checkAvailibility = () => {
      $scope.products = {};
      if (!$scope.zip) {
        toaster.error('Please input ZIP code');
        return;
      }

      const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test($scope.zip);
      if (!isValidZip) {
        toaster.error('Please input valid ZIP code');
        return;
      }

      const promise = getLatLongFromZip($scope.zip);
      loadingScreenFactory.showLoadingScreen();
      promise.then((location) => {
        let url = `${API_BASE_URL}getProductsAtZip/${location.lat}/${location.long}`;
        if ($scope.selectedProviderType) {
          url = `${url}/${$scope.selectedProviderType}`;
        }
        const getProductsAtZipCall = $http.get(url);
        getProductsAtZipCall.then(
          (result) => {
            loadingScreenFactory.hideLoadingScreen();
            if (result.data.success) {
              $scope.products = result.data.data.products;
            } else {
              $scope.products = [];
              toaster.error(result.data.message);
            }
          },
          (error) => {
            loadingScreenFactory.hideLoadingScreen();
            toaster.error(error.message);
          },
        );
      });
    };
    $scope.clearResult = () => {
      $scope.zip = '';
      $scope.products = {};
      $scope.selectedProviderType = 0;
    };
  },
]);
