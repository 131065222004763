export const marketSegment = Object.freeze({
    WORKERS_COMPENSATION: {
    	ID: 1
    },
    MASS_MARKET: {
    	ID: 2
    },
    GROUP_HEALTH: {
    	ID: 3
    }
});