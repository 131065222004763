function trackingLogCtrl(
  $scope,
  API_BASE_URL,
  $http,
  loadingScreenFactory,
  $uibModal,
  $rootScope,
  $state,
  $timeout,
) {
  const initSearchForm = () => {
    $scope.searchFormQuery = {
      username: '',
      level: 'Select Level',
      dateRange: '',
      phone: '',
    };
  };
  $scope.dateRangeOptions = {
    parentEl: '#current-app #page-wrapper',
    eventHandlers: {
      'apply.daterangepicker': function applyDaterangepicker() {
        const dateRangeFilter = document.getElementsByName('dateRange')[0].value;
        if (dateRangeFilter) {
          const dateRangeFilterArr = dateRangeFilter.split(' - ');
          if (!$scope.searchFormQuery.dateRange) {
            $scope.searchFormQuery.dateRange = {};
          }
          $scope.searchFormQuery.dateRange.startDate = moment.utc(`${dateRangeFilterArr[0]} 00:00:00`);
          $scope.searchFormQuery.dateRange.endDate = moment.utc(`${dateRangeFilterArr[1]} 23:59:59`);
        }
      },
    },
  };
  $scope.moment = moment;

  let firstTimeLoad = true;


  function adjustPagination() {
    $scope.pagination.lastItem = ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage + $scope.pagination.itemsPerPage;
    if ($scope.pagination.lastItem > $scope.pagination.totalItems) {
      $scope.pagination.lastItem = $scope.pagination.totalItems;
    }
  }

  // for getting audit logs from api
  function getAccessLogs(offset, limit, searchStr) {
    const request = $http.get(`${API_BASE_URL}tracking_logs?offset=${offset}&limit=${limit}${searchStr}`);
    loadingScreenFactory.showLoadingScreen();
    request.then(({ data }) => {
      loadingScreenFactory.hideLoadingScreen();
      if (data.success) {
        $scope.access_logs = data.access_logs;
        $scope.pagination.totalItems = data.total_count;
        firstTimeLoad = false;
        adjustPagination();
      }
    }, () => {
      loadingScreenFactory.hideLoadingScreen();
    });
  }

  function getSearchString() {
    const searchFormQuery = angular.copy($scope.searchFormQuery);

    if (
      typeof searchFormQuery.dateRange.startDate !== 'undefined'
      && typeof searchFormQuery.dateRange.endDate !== 'undefined'
    ) {
      searchFormQuery.startDate = parseInt($scope.searchFormQuery.dateRange.startDate, 10) / 1000;
      searchFormQuery.endDate = parseInt($scope.searchFormQuery.dateRange.endDate, 10) / 1000;
      delete searchFormQuery.dateRange;
    }

    if (searchFormQuery.level === 'Select Level') { // remove if from
      delete searchFormQuery.level;
    }
    const searchStr = [];

    Object.keys(searchFormQuery).forEach((key) => {
      if (searchFormQuery[key] && key !== 'currentPage') searchStr.push(`${key}=${searchFormQuery[key]}`);
    });

    const order = ($scope.sortOrder ? 'DESC' : 'ASC');
    searchStr.push(`order="${$scope.sortBy}" ${order}`);
    let temp = searchStr.join('&');
    if (searchStr.length > 0) temp = `&${temp}`;

    return temp;
  }

  $scope.sortBy = 'createdAt';
  $scope.sortOrder = 1;
  $scope.sort = (sortBy) => {
    if ($scope.sortBy === sortBy) {
      $scope.sortOrder = !$scope.sortOrder;
    }
    $scope.sortBy = sortBy;
    getAccessLogs($scope.pagination.currentPage - 1, 10, getSearchString());
  };

  function initPagination() {
    $scope.pagination = {
      totalItems: 0,
      currentPage: 1,
      lastItem: 0,
      itemsPerPage: 10,
    };
  }


  $scope.rideStatus = ['Select Level', 'info', 'error'];


  function init() {
    initPagination();
    initSearchForm();

    function initFormAndLogs() {
      $timeout(() => {
        const stateParmas = $state.params;
        // setting params into form
        angular.extend($scope.searchFormQuery, stateParmas);
        if (angular.isDefined(stateParmas.startDate) && angular.isDefined(stateParmas.endDate)) {
          $scope.searchFormQuery.dateRange = {
            startDate: stateParmas.startDate * 1000,
            endDate: stateParmas.endDate * 1000,
          };
        }
        if (angular.isUndefined(stateParmas.level)) {
          $scope.searchFormQuery.level = 'Select Level';
        }
        $scope.pagination.currentPage = angular.isDefined(stateParmas.currentPage)
          ? parseInt(stateParmas.currentPage, 10) + 1
          : 1;
        getAccessLogs($scope.pagination.currentPage - 1, 10, getSearchString());
      }, 0);
    }
    initFormAndLogs();
  }
  init();

  $scope.pageChanged = () => {
    if (!firstTimeLoad) {
      $state.go($state.current, { currentPage: $scope.pagination.currentPage - 1 });
      getAccessLogs($scope.pagination.currentPage - 1, 10, getSearchString());
    }
  };

  $scope.showUserData = (accessLog) => {
    const accessLogMeta = { accessLog };
    const modalInstance = $uibModal.open({
      animation: true,
      template: require('../../sub-modules/logs/log-details.html'),
      size: 'lg',
      backdrop: 'static',
      resolve: {
        meta() {
          accessLogMeta.user = {};
          Object.assign(accessLogMeta.user, accessLog.user, accessLog.user_on_phone);
          return angular.copy(accessLogMeta);
        },
      },
      controllerAs: 'logDetailsCtrl',
      controller: [
        'meta',
        '$uibModalInstance',
        function logDetailsCtrl(meta, $uibModalInstance) {
          Object.assign(this, {
            meta,
            closePopup() {
              $uibModalInstance.close();
            },
          });
        },
      ],
    });
    $scope.$on('$destroy', () => {
      modalInstance.close();
    });
  };

  function getStateParams() {
    const stateParams = angular.copy($scope.searchFormQuery);
    if (stateParams.level === 'Select Level') {
      delete stateParams.level;
    }
    stateParams.startDate = $scope.searchFormQuery.dateRange === '' ? null : (parseInt($scope.searchFormQuery.dateRange.startDate, 10) / 1000);
    stateParams.endDate = $scope.searchFormQuery.dateRange === '' ? null : (parseInt($scope.searchFormQuery.dateRange.endDate, 10) / 1000);
    return stateParams;
  }

  $scope.filterAuditLogs = () => {
    const stateParams = getStateParams();
    stateParams.currentPage = 0;
    $state.go($state.current, stateParams);
    getAccessLogs(0, 10, getSearchString());
  };

  $scope.clearFilters = () => {
    initSearchForm();
    getAccessLogs(0, 10, getSearchString());
    const stateParams = {};
    angular.extend(stateParams, $scope.searchFormQuery);
    stateParams.level = '';
    stateParams.currentPage = 0;
    $scope.pagination.currentPage = 1;
    $state.go($state.current, stateParams);
  };

  $scope.enterOnFormSearch = (event) => {
    if (event.keyCode === 13) {
      $scope.pagination.currentPage = 1;
      const stateParams = getStateParams();
      stateParams.currentPage = 0;
      $state.go($state.current, stateParams);
      getAccessLogs(0, 10, getSearchString());
    }
  };
}

angular.module('relayHealth')
  .controller('trackingLogCtrl', [
    '$scope',
    'API_BASE_URL',
    '$http',
    'loadingScreenFactory',
    '$uibModal',
    '$rootScope',
    '$state',
    '$timeout',
    trackingLogCtrl,
  ]);
