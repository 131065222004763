import {
  start, navigateToUrl,
} from 'single-spa';
import './current';

if (window.location.pathname.indexOf(`/${process.env.PUBLIC_URL_PREFIX}current/`) !== 0) {
  navigateToUrl(`/${process.env.PUBLIC_URL_PREFIX}current/`);
}

start();
