import Ladda from 'ladda';

angular
  .module('relayHealth')
  .controller('rideOrderCtrl', [
    '$scope',
    '$state',
    'API_BASE_URL',
    '$http',
    'toaster',
    'loadingScreenFactory',
    '$stateParams',
    'SweetAlert',
    '$timeout',
    'Pubnub',
    'pubnubNotificationFactory',
    function rideOrderCtrl(
      $scope,
      $state,
      API_BASE_URL,
      $http,
      toaster,
      loadingScreenFactory,
      $stateParams,
      SweetAlert,
      $timeout,
      Pubnub,
      pubnubNotificationFactory,
    ) {
      // todo Move to appropriate file
      // Calculation Helpers
      /**
       * Returns updated cost based on data. And uses previousCost to increase it
       * @param {Object} data
       * @param {Number} data.vendor_rate_per_mile
       * @param {Number} data.vendor_load_charge
       * @param {Boolean|String} data.offer_sent
       * @param {Number} data.flat_fee
       * @param {Number} previousCost
       */
      function costCalculate(data, previousCost) {
        // todo remove arbitrary data object
        const vendorRatePerMile      = data.vendor_rate_per_mile || 0;
        const rateForCostCalculation = Number(data.vendor_load_charge) || Number(data.vendor_base_rate) || 0;

        if ([true, 'true'].includes(data.offer_sent) && data.flat_fee) {
          return previousCost + Number(parseFloat(data.flat_fee).toFixed(2));
        }
        const totalCost          = Number($scope.rideDetails.tripInfo.total_distance * vendorRatePerMile);
        const costWithLoadCharge = totalCost + Number($scope.rideDetails.tripInfo.legs.length * rateForCostCalculation);
        return Number(Number(costWithLoadCharge)
          .toFixed(2));
      }

      // watch removers for all listeners;
      const watcherRemovers = [];
      const pageRideId = parseInt($stateParams.rideId, 10);
      $scope.moment = moment;
      $scope.forms = {
        order_info: {},
        ride_info: {},
        trip_info: {},
      };
      $scope.pageHeading = 'Ride Order ';
      $scope.offeredVendorCount = 0;
      // data from stateParams
      let rideId = Number($stateParams.rideId);

      const elgParamConv = relaylib.ride.eligibilityParameterConverter;

      $scope.diffLoaded = false;
      $scope.isHR68 = false;
      $scope.newSteerage = false;

      const loggedInUserDetails = JSON.parse(
        localStorage.getItem('userDetails'),
      );
      const { transportationTypeConsts } = relaylib.vendorPortal;
      $scope.permissionsConstants = relaylib.permissions.permissionsConstants;
      const statusConsts = relaylib.ride.status;
      const afterActiveCategories = [statusConsts.PATIENT_ENROUTE.type, statusConsts.FAILED.type, statusConsts.APPOINTMENT_COMPLETED.type];
      const maxRadiusCategories = relaylib.vendors.maxRadiusVendorCategories;
      const providerIdConstants = relaylib.common.constants.providerIdMapping;
      /**
       * below rideDetails object is being used in child controllers also,
       * so don't delete anything before confirmation
       * */
      $scope.dropdowns = {
        organisations: [],
        vendorPreferenceList: ['Yes', 'No'],
        radiusList: [15, 20, 25, 30],
      };

      const offeredStatusCode = [0, 3, 4, 7, 8, 9];
      $scope.rideDetails = null;
      const resetRideDetails = function resetRideDetails() {
        $scope.rideDetails = {
          ride_id: rideId,
          org_id: null,
          managed_by: null,
          distance: 0,
          rideInfo: {
            disable: true,
            patient: {
              phone: null,
              first_name: null,
              last_name: null,
            },
            file_no: null,
          },
          tripInfo: {
            disable: true,
            appointmentTime: {
              time: 'FUTURE_APPOINTMENT',
            },
            leg_type: 'single',
            legs: [],
            source_address: '',
            dest_address: '',
          },
          orderInfo: {
            disable: true,
            orderId: '',
            preferredVendorNotes: '',
            prefferedVendor: '',
            orderGeneratedAt: null,
            driverNotes: '',
            orderInternalNotes: '',
          },
          availableVendors: {
            radius: 15,
            error: '',
            vendorList: [],
            rideShareSelectedCount: 0,
            oneCallSelectedCount: 0,
          },
          previousEligibilityLists: [],
          orderUpdateDiff: {
            patient_first_name: { changed: false, value: null },
            patient_last_name: { changed: false, value: null },
            patient_phone_number: { changed: false, value: null },
            leg_type: { changed: false, value: null },
            health_plan_name: { changed: false, value: null },
            provider_preference_notes: { changed: false, value: null },
            has_provider_preference: { changed: false, value: null },
            order_create_date: { changed: false, value: null },
            note_to_driver: { changed: false, value: null },
            changedLegs: {},
            removed_legs: [],
          },
        };
      };

      const popErrors = function popErrors(error, message) {
        toaster.pop({
          type: 'error',
          title: error.message || message || 'Error occured !',
          timeout: 3000,
        });
      };

      $scope.tabSelected = (event) => {
        $timeout(() => {
          $scope.$broadcast(event);
        }, 0);
      };

      const setupTabs = function setupTabs() {
        $scope.tabs = {
          riderInfo: {
            heading: 'Rider Information',
            active: false,
            tabSelectedEvent: 'riderInfoSelected',
          },
          tripInfo: {
            heading: 'Trip Information',
            active: false,
            tabSelectedEvent: 'tripInfoSelected',
          },
          orderInfo: {
            heading: 'Order Information',
            active: true,
            tabSelectedEvent: 'orderInfoSelected',
          },
        };
      };

      const getStatusString = function getStatusString(vendor) {
        let status = '';
        if (vendor) {
          if (
            [providerIdConstants.LYFT, providerIdConstants.UBER].includes(
              vendor.vendor_type,
            )
          ) {
            if (vendor.is_accepted) {
              status = 'Accepted';
            }
            if (vendor.status === '101') {
              status = 'Rejected';
            }
          } else if (vendor.status) {
            const code = Number(vendor.status);
            status = 'Unknown';
            const statusConst = relaylib.vendorPortal.statusConsts;
            if (statusConst[code]) {
              status = statusConst[code];
            }
          }
        }
        return status;
      };


      $scope.onlineAdmins = [];
      $scope.subscribedLegs = [];

      let checkAndUpdateCurrentlyViewingAdminsRunning = false;
      function checkAndUpdateCurrentlyViewingAdmins() {
        if (checkAndUpdateCurrentlyViewingAdminsRunning) {
          return; // Check to prevent parallel calling attempts to this function
        }

        checkAndUpdateCurrentlyViewingAdminsRunning = true;

        const presenceChannels = $scope.subscribedLegs.map(subscribedRideId => `presence_${subscribedRideId}`);
        pubnubNotificationFactory.getHereNowOfChannels(presenceChannels, (status, response) => {
          const onlineAdmins = [];
          const onlineAdminsIDs = [];

          if (response && response.channels) {
            presenceChannels.forEach((channel) => {
              if (response.channels[channel]) {
                const { occupants } = response.channels[channel];
                // eslint-disable-next-line no-unused-expressions
                Array.isArray(occupants) && occupants.forEach((o) => {
                  if (o.state && !onlineAdminsIDs.includes(o.state.id)) {
                    onlineAdminsIDs.push(o.state.id);
                    onlineAdmins.push(o.state);
                  }
                });
              }
            });
          }
          checkAndUpdateCurrentlyViewingAdminsRunning = false; // Reset flag....so that next attempt to check active users can run successfully.

          $scope.$apply(() => {
            $scope.onlineAdmins = onlineAdmins;
          });
        });
      }

      const presenceListeners = {
        presence(p) {
          const presenceChannels = $scope.subscribedLegs.map(subscribedRideId => `presence_${subscribedRideId}`);
          if (!(presenceChannels.includes(p.channel) && ['state-change', 'leave', 'timeout'].includes(p.action))) {
            return;
          }
          setTimeout(() => {
            checkAndUpdateCurrentlyViewingAdmins();
          }, 1000); // Put some delay before fetching here now details...
        },
        message(p) {
          const presenceChannels = $scope.subscribedLegs.map(subscribedRideId => `presence_${subscribedRideId}`);
          if (!(presenceChannels.includes(p.channel))) {
            return;
          }

          const payload = p.message;
          toaster.pop({
            type: 'info',
            title: payload.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      };

      Pubnub.addListener(presenceListeners);

      function subscribePresenceChannelForAllLegs() {
        if ($scope.rideDetails.tripInfo.legs.length) {
          $scope.rideDetails.tripInfo.legs.forEach((leg) => {
            if (!$scope.subscribedLegs.includes(leg.ride_id)) {
              $scope.subscribedLegs.push(leg.ride_id);
              pubnubNotificationFactory.subscribePresenceChannel(`presence_${leg.ride_id}`, {
                name: `${loggedInUserDetails.first_name} ${loggedInUserDetails.last_name}`,
                id: loggedInUserDetails.user_id,
              });
            }
          });
        }
      }

      $scope.selectAllVendorsChange = function selectAllVendorsChange(category, code) {
        $scope.rideDetails.availableVendors.vendorList.forEach((vendor) => {
          if (vendor.tabCategory === code && !vendor.checkDisabled && vendor.softwareCategory === category) {
            if ($scope.providerTabs.allSelected[category][code]) {
              if (!vendor.selected) {
                Object.assign(vendor, { selected: true });
                if (vendor.isRideShareVendor) {
                  $scope.rideDetails.availableVendors.rideShareSelectedCount += 1;
                } else {
                  $scope.rideDetails.availableVendors.oneCallSelectedCount += 1;
                }
              }
            } else if (vendor.selected) {
              Object.assign(vendor, { selected: false });
              if (vendor.isRideShareVendor) {
                $scope.rideDetails.availableVendors.rideShareSelectedCount -= 1;
              } else {
                $scope.rideDetails.availableVendors.oneCallSelectedCount -= 1;
              }
            }
          }
        });
      };

      $scope.selectVendorChange = function selectVendorChange({ selected, isRideShareVendor }, category, code) {
        if (selected) {
          if (isRideShareVendor) {
            $scope.rideDetails.availableVendors.rideShareSelectedCount += 1;
          } else {
            $scope.rideDetails.availableVendors.oneCallSelectedCount += 1;
          }
          const availableVendorList = $scope.rideDetails.availableVendors.vendorList;
          for (
            let i = 0;
            i < availableVendorList.length;
            i += 1
          ) {
            if (
              availableVendorList[i].tabCategory
                === code
              && availableVendorList[i]
                .softwareCategory === category
              && !availableVendorList[i].selected
            ) {
              if (isRideShareVendor) {
                $scope.rideDetails.availableVendors.vendorList[i].checkDisabled = true;
              }
              $scope.providerTabs.allSelected[category][code] = false;
              return;
            }
          }
          $scope.providerTabs.allSelected[category][code] = true;
        } else {
          if (isRideShareVendor) {
            $scope.rideDetails.availableVendors.rideShareSelectedCount -= 1;
            ($scope.rideDetails.availableVendors.vendorList || []).forEach(
              (vendor) => {
                if (
                  vendor.tabCategory === code
                  && vendor.softwareCategory === category
                ) {
                  Object.assign(vendor, { checkDisabled: false });
                }
              },
            );
          } else {
            $scope.rideDetails.availableVendors.oneCallSelectedCount -= 1;
          }
          $scope.providerTabs.allSelected[category][code] = false;
        }
      };

      const getAllOrganizations = function getAllOrganizations() {
        const requestUrl = `${API_BASE_URL}organisation/getOrganizations/active`;
        return $http
          .get(requestUrl, {
            params: {
              orgId: loggedInUserDetails.organisation.id,
              removeParentOrganisations: true,
              marketSegment: true,
            },
          })
          .then((response) => {
            $scope.dropdowns.organisations = response.data.org_details;
          })
          .catch((error) => {
            popErrors(error, 'Error while getting list of organisations');
          });
      };

      const resetAvailableVendors = function resetAvailableVendors() {
        $scope.rideDetails.availableVendors.error = '';
        $scope.traditionalOff = false;
        $scope.rideDetails.availableVendors.vendorList = [];
        $scope.offeredVendorCount = 0;
        $scope.providerTabs.allSelected = {
          withSoftwareId: {
            ep: false,
            me: false,
            ne: false,
            sra: false,
          },
          withoutSoftwareId: {
            ep: false,
            me: false,
            ne: false,
            sra: false,
          },
          rideShareProviders: {
            ep: false,
            me: false,
            ne: false,
            sra: false,
          },
        };

        $scope.providerTabs.counts = {
          total: {
            withSoftwareId: {
              ep: 0,
              me: 0,
              ne: 0,
              sra: 0,
            },
            withoutSoftwareId: {
              ep: 0,
              me: 0,
              ne: 0,
              sra: 0,
            },
            rideShareProviders: {
              ep: 0,
              me: 0,
              ne: 0,
              sra: 0,
            },
          },
        };
      };

      const applyTimezoneOffset = function applyTimezoneOffset(utcMoment, pickupOffset) {
        let timeMoment;
        if (pickupOffset) {
          timeMoment = utcMoment.utcOffset(pickupOffset);
        } else if ($scope.rideDetails.orderInfo.appointment_timezone_offset) {
          timeMoment = utcMoment.utcOffset(
            $scope.rideDetails.orderInfo.appointment_timezone_offset,
          );
        } else if ($scope.rideDetails.orderInfo.appt_timezone) {
          timeMoment = utcMoment.tz($scope.rideDetails.orderInfo.appt_timezone);
        } else {
          timeMoment = utcMoment.utcOffset(0);
        }

        return timeMoment;
      };
      const unixTimeConversion = (dateString, offsetString) => {
        let time = null;
        let afterOffsetDate = null;
        if (dateString) {
          const tempDate = moment.unix(dateString);
          if (tempDate.isValid()) {
            afterOffsetDate = applyTimezoneOffset(tempDate, offsetString);
            if (afterOffsetDate.isValid()) {
              time = new Date(
                1970,
                1,
                1,
                afterOffsetDate.hours(),
                afterOffsetDate.minutes(),
              );
            }
          }
        }
        return { time, afterOffsetDate };
      };

      const addRideShareVendor = ({
        rideShareProvider,
        rideShareConfirmed,
        disableRelayAction,
      }) => {
        let statusString;
        if (rideShareProvider.is_rescinded === 'true') {
          statusString = 'Rescinded';
        } else if (rideShareProvider.is_accepted) {
          statusString = 'Confirmed';
        }
        if (rideShareProvider.status === '101') {
          statusString = 'Rejected';
        }
        $scope.rideDetails.availableVendors.vendorList.push({
          providerId: rideShareProvider.vendor_code,
          fuseId: rideShareProvider.fuse_id,
          name: rideShareProvider.name,
          softwareCategory: 'rideShareProviders',
          tabCategory: 'ep',
          selected: rideShareProvider.is_accepted && (rideShareProvider.is_rescinded !== 'true' && rideShareProvider.status !== '101'),
          checkDisabled: (rideShareProvider.is_accepted || rideShareProvider.is_rescinded === 'true') || $scope.providerTabs.isOffered,
          status: (rideShareProvider.is_accepted && 1) || undefined,
          isRideShareVendor: true,
          isRescinded: rideShareProvider.is_rescinded === 'true',
          statusString,
          disableRelayAction,
        });
        $scope.providerTabs.counts.total.rideShareProviders.ep += 1;
        $scope.providerTabs.categorySetting.rideShareProviders.show = true;

        if (rideShareConfirmed) {
          $scope.providerTabs.isOffered = true;
          $scope.providerTabs.isRideShareSelected = true;
          $scope.rideDetails.availableVendors.rideShareSelectedCount += 1;
        }
      };

      const fetchVendorDetailsCall = async (rideid, vendorId) => {
        let url;
        if (vendorId) {
          url = `${API_BASE_URL}vendors/${rideid}/${vendorId}`;
        } else {
          url = `${API_BASE_URL}vendors/${rideid}`;
        }
        return $http.get(url);
      };
      $scope.generatedVia = null;
      const fetchAvailableVendorDetails = async (rideIdToFetchVendorDetails, rideShareInfo = {}) => {
        try {
          const result = await fetchVendorDetailsCall(rideIdToFetchVendorDetails);
          if (result.data.success) {
            $scope.generatedVia = result.data.generatedVia || null;
            $scope.rideDetails.availableVendors.radius = result.data.radius || 15;
            $scope.mode = result.data.mode;
            $scope.pickupRegion = result.data.pickupRegion;
            if ($scope.rideDetails.availableVendors.radius === 100) {
              $scope.providerTabs.hasMaxRadiusProviders = true;
              $scope.dropdowns.radiusList.push(100);
            }
            if (
              !result.data.traditionalProviders.length
              && !result.data.traditionalSoftwareProviders.length
              && !result.data.rideShareProviders.length
            ) {
              $scope.rideDetails.availableVendors.error = `It appears that there are no eligible providers within the current defined radius.
                Please expand the search radius.`;
              return;
            }


            if (result.data.rideShareProviders.length) {
              result.data.rideShareProviders.forEach(rideShareProvider => addRideShareVendor({ rideShareProvider, ...rideShareInfo }));
            }
            if (result.data.traditionalProviders.length || result.data.traditionalSoftwareProviders.length) {
              const traditionalVendors = [...result.data.traditionalProviders, ...result.data.traditionalSoftwareProviders];
              const allVendors = [];
              for (let i = 0; i < traditionalVendors.length; i += 1) {
                const vendor = { cost: 0 };
                vendor.isRideShareVendor = false;
                vendor.is_accepted = traditionalVendors[i].is_accepted;
                vendor.vendor_type = traditionalVendors[i].vendor_type;
                vendor.providerId = traditionalVendors[i].vendor_code;
                vendor.fuseId = traditionalVendors[i].fuse_id;
                vendor.name = traditionalVendors[i].name;
                vendor.region_status = traditionalVendors[i].region_status;
                vendor.distance = traditionalVendors[i].distance;
                vendor.region = traditionalVendors[i].region;
                if (traditionalVendors[i].non_eligibility_reason) {
                  let nonEligibilityReason = '';
                  for (const [key, value] of Object.entries(traditionalVendors[i].non_eligibility_reason)) {
                    nonEligibilityReason += key;
                    if ((value && String(value).trim()) || value===false || value ===null) {
                      nonEligibilityReason += `: ${value}`;
                    }
                    nonEligibilityReason += '<br/> ';
                  }
                  vendor.non_eligibility_reason = nonEligibilityReason;
                }

                vendor.hasSoftwareId = Boolean(
                  traditionalVendors[i].software_provider_id,
                );
                vendor.softwareCategory = (vendor.hasSoftwareId && 'withSoftwareId')
                    || 'withoutSoftwareId';
                vendor.vendor_rate_per_mile = traditionalVendors[i].vendor_rate_per_mile || 0;
                vendor.rate_for_cost_calculation = Number(traditionalVendors[i].vendor_load_charge || 0)
                    || Number(traditionalVendors[i].vendor_base_rate || 0);
                vendor.max_radius_provider = traditionalVendors[i].max_radius_provider;
                vendor.max_radius_category = traditionalVendors[i].tab_category;
                if (
                  traditionalVendors[i].transportation_type
                    === String(transportationTypeConsts.sp)
                ) {
                  vendor.phone = traditionalVendors[i].cell_phone;
                } else {
                  vendor.phone = traditionalVendors[i].phone;
                }
                vendor.previousCount = traditionalVendors[i].previousCount;

                // Assigning cost to vendor object
                vendor.cost = costCalculate(traditionalVendors[i], vendor.cost);

                const expiryTimeString = traditionalVendors[i].offer_expiration_utc_time;
                vendor.expiryTimeString = expiryTimeString;
                if (typeof traditionalVendors[i].is_rescinded === 'string') {
                  if (
                    traditionalVendors[i].is_rescinded.toLowerCase()
                      === 'true'
                  ) {
                    vendor.isRescinded = true;
                  }
                  if (
                    traditionalVendors[i].is_rescinded.toLowerCase()
                      === 'false'
                  ) {
                    vendor.isRescinded = false;
                  }
                } else {
                  vendor.isRescinded = traditionalVendors[i].is_rescinded;
                }
                const expiryTimeUTCMoment = moment.utc(expiryTimeString);
                if (expiryTimeUTCMoment.isValid()) {
                  const expiryTimeMoment = applyTimezoneOffset(
                    expiryTimeUTCMoment,
                    $scope.rideDetails.rideInfo.pickupOffset,
                  );
                  vendor.offerExpiryTime = expiryTimeMoment.format(
                    'MM/DD/YYYY h:mm A',
                  );
                  const now = applyTimezoneOffset(
                    moment.utc(),
                    $scope.rideDetails.rideInfo.pickupOffset,
                  );
                  if (expiryTimeMoment.isAfter(now, 'minutes')) {
                    const days = expiryTimeMoment.diff(now, 'days');
                    const hours = expiryTimeMoment
                      .subtract(days, 'days')
                      .diff(now, 'hours');
                    const minutes = expiryTimeMoment
                      .subtract(hours, 'hours')
                      .diff(now, 'minutes');
                    vendor.timeToExpire = `${days} day(s), ${hours} hour(s), ${minutes} minute(s)`;
                  } else {
                    vendor.timeToExpire = '(Expired) 0 day(s), 0 hour(s), 0 minute(s)';
                  }
                } else {
                  vendor.offerExpiryTime = '';
                  vendor.timeToExpire = '';
                }

                if (vendor.max_radius_provider) {
                  if (vendor.max_radius_category === maxRadiusCategories.MORE_ELIGIBLE) {
                    vendor.tabCategory = 'me';
                  } else if (vendor.max_radius_category === maxRadiusCategories.NON_ELIGIBLE) {
                    vendor.tabCategory = 'ne';
                  } else if (vendor.max_radius_category === maxRadiusCategories.SRA) {
                    vendor.tabCategory = 'sra';
                  } else {
                    vendor.tabCategory = 'ep';
                  }
                } else {
                  vendor.tabCategory = 'ep';
                }

                vendor.status = traditionalVendors[i].status;
                vendor.statusString = getStatusString(vendor);
                const statusCode = vendor.status ? Number(vendor.status) : null;
                if (statusCode === 0 || statusCode > 0) {
                  vendor.selected = true;
                  vendor.checkDisabled = true;
                  if (!$scope.providerTabs.hasOneSelected) {
                    $scope.providerTabs.hasOneSelected = true;
                  }
                  if (
                    !vendor.isRescinded
                      && offeredStatusCode.includes(statusCode)
                  ) {
                    $scope.providerTabs.isOffered = true;
                    $scope.rideDetails.availableVendors.oneCallSelectedCount += 1;
                    $scope.offeredVendorCount += 1;
                  }
                }
                if (statusCode === 7 || statusCode === 4) {
                  vendor.timeToExpire = '(Accepted)';
                }

                $scope.providerTabs.counts.total[vendor.softwareCategory][
                  vendor.tabCategory
                ] += 1;

                vendor.email = traditionalVendors[i].email;
                vendor.vendorScores = parseFloat(traditionalVendors[i].vendor_scores) || 0.00;
                vendor.softwareProvider = traditionalVendors[i].software_provider;
                vendor.exceptionReason = traditionalVendors[i].exception_reason;
                allVendors.push(vendor);
              }
              if (!$scope.isHR68 && !$scope.newSteerage) {
                allVendors.sort((a, b) => {
                  if (a.vendorScores === b.vendorScores) {
                    if (a.cost === 0) return 1; // Return 1 so that b goes first
                    if (b.cost === 0) return -1; // Return -1 so that a goes first
                    return a.cost - b.cost;
                  }
                  return b.vendorScores - a.vendorScores;
                });
              }
              $scope.rideDetails.availableVendors.vendorList.push(
                ...allVendors,
              );
            }
          } else {
            $scope.rideDetails.availableVendors.error = `It appears that there are no eligible providers within the current defined radius.
            Please expand the search radius.`;
          }
        } catch {
          $scope.rideDetails.availableVendors.error = `It appears that there are no eligible providers within the current defined radius.
          Please expand the search radius.`;
        }
      };

      const fetchPreviousVendorDetails = async (rideid, listRef) => {
        if (!listRef.pending) {
          return;
        }
        // used to display previous eligible vendor list
        const result = await fetchVendorDetailsCall(rideid, listRef.id);
        if (!result.data.success) {
          return;
        }
        Object.assign(listRef, { pending: false });
        if (Array.isArray(result.data.vendors)) {
          const { vendors } = result.data;
          const allVendors = [];
          for (let i = 0; i < vendors.length; i += 1) {
            const vendor = {
              id: vendors[i].id,
              vendor_id: vendors[i].vendor_code,
              vendor_scores: parseFloat(vendors[i].vendor_scores) || 0.00,
              vendor_name: vendors[i].name,
              status: vendors[i].status,
              statusString: getStatusString(vendors[i]),
              cost: 0,
              vendor_type: vendors[i].vendor_type,
              region_status: vendors[i].region_status,
              distance: vendors[i].distance,
            };
            if (vendors[i].non_eligibility_reason) {
              let nonEligibilityReason = '';
              for (const [key, value] of Object.entries(vendors[i].non_eligibility_reason)) {
                nonEligibilityReason += key;
                if ((value && String(value).trim()) || value===false || value ===null) {
                  nonEligibilityReason += `: ${value}`;
                }
                nonEligibilityReason += '<br/> ';
              }
              vendor.non_eligibility_reason = nonEligibilityReason;
            }
            // Assigning cost to vendor object
            vendor.cost = costCalculate(vendors[i], vendor.cost);
            allVendors.push(vendor);
          }
          if (!$scope.isHR68 && !$scope.newSteerage) {
            allVendors.sort((a, b) => {
              if (a.vendor_scores === b.vendor_scores) {
                if (a.cost === 0) return 1; // Return 1 so that b goes first
                if (b.cost === 0) return -1; // Return -1 so that a goes first
                return a.cost - b.cost;
              }
              return b.vendor_scores - a.vendor_scores;
            });
          }
          // sort by vendor type
          allVendors.sort((a, b) => a.vendor_type - b.vendor_type);
          Object.assign(listRef, { pending: false, vendors: allVendors });
        }
      };

      const fetchVendorList = async (rideid, rideShareInfo) => {
        // this api returns all vendor lists associated with the ride and used to show list 1, list 2 ...
        const result = await $http.get(`${API_BASE_URL}vendors/list/${rideid}`);
        const vendorListPromises = [];
        if (result.data.success && result.data.data && result.data.data.length) {
          $scope.rideDetails.previousEligibilityLists = [];
          const vendorLists = _.sortBy(result.data.data, 'id', 'asc');
          vendorLists.forEach((list) => {
            if (list.status === 'deactive') {
              $scope.rideDetails.previousEligibilityLists.push({
                id: list.id,
                generatedVia: list.generated_via,
                title: `List ${$scope.rideDetails.previousEligibilityLists
                  .length + 1}`,
                radius: list.radius,
                pending: true,
                vendors: [],
              });
            } else if (list.status === 'active') {
              $scope.rideDetails.tripInfo.latestFlatRate = list.latest_flat_rate;
            }
          });
          if ($scope.rideDetails.previousEligibilityLists.length) {
            [$scope.rideDetails.selectedPreviousEligibilityList] = $scope.rideDetails.previousEligibilityLists;
            vendorListPromises.push(fetchPreviousVendorDetails(
              rideid,
              $scope.rideDetails.selectedPreviousEligibilityList,
            ));
          }
        }
        vendorListPromises.push(fetchAvailableVendorDetails(rideid, rideShareInfo));
        await Promise.all(vendorListPromises);
      };

      const setFieldDiff = function setFieldDiff(diff, updateFieldName, diffFieldName) {
        if (_.has(diff, diffFieldName)) {
          $scope.rideDetails.orderUpdateDiff[updateFieldName].changed = true;
          $scope.rideDetails.orderUpdateDiff[updateFieldName].value = _.get(
            diff,
            diffFieldName,
          );
        }
      };

      const setPickupTimeOfInDiff = function setPickupTimeOfInDiff(leg, key) {
        Object.assign(leg.changes, { [`${key}_date_time`]: true });
        const dateString = leg[`${key}_date_time`];
        const offsetString = Number(leg[`${key}_timezone_offset`] || 0);
        let finalDate;
        if (dateString) {
          const tempDate = moment.utc(dateString);
          if (tempDate.isValid()) {
            const afterOffsetDate = tempDate.utcOffset(offsetString);
            if (afterOffsetDate.isValid()) {
              finalDate = afterOffsetDate;
            }
          }
        }
        if (finalDate) {
          Object.assign(leg, { [`${key}_date_time_new`]: finalDate.format('MM/DD/YYYY h:mm A') });
        }
      };

      const mapOrderUpdateDiffToFields = function mapOrderUpdateDiffToFields(diffData) {
        const diff = _.get(diffData, '_source.meta.orderUpdateDiff');
        if (diff) {
          setFieldDiff(diff, 'patient_first_name', 'patient.first_name');
          setFieldDiff(diff, 'patient_last_name', 'patient.last_name');
          setFieldDiff(diff, 'patient_phone_number', 'patient.phone');
          setFieldDiff(diff, 'leg_type', 'leg_type');
          setFieldDiff(diff, 'health_plan_name', 'health_plan');
          if (_.has(diff, 'order_create_date')) {
            $scope.rideDetails.orderUpdateDiff.order_create_date.changed = true;

            const orderDateString = diff.order_create_date;
            const orderOffsetString = Number(
              diff.order_create_timezone_offset || 0,
            );
            let orderFinalDate;
            if (orderDateString) {
              const orderTempDate = moment.utc(orderDateString);
              if (orderTempDate.isValid()) {
                const orderAfterOffsetDate = orderTempDate.utcOffset(
                  orderOffsetString,
                );
                if (orderAfterOffsetDate.isValid()) {
                  orderFinalDate = orderAfterOffsetDate.format(
                    'MM/DD/YYYY h:mm A',
                  );
                }
              }
            }
            if (orderFinalDate) {
              $scope.rideDetails.orderUpdateDiff.order_create_date.value = orderFinalDate;
            }
          }
          Object.keys(diff.changed_legs).forEach((key) => {
            if (diff.changed_legs[key] && !diff.changed_legs[key].legIsAdded) {
              diff.changed_legs[key].changes = {};
              if (_.has(diff.changed_legs[key], 'has_provider_preference')) {
                diff.changed_legs[key].changes.has_provider_preference = true;
              }
              if (_.has(diff.changed_legs[key], 'provider_preference_notes')) {
                diff.changed_legs[key].changes.provider_preference_notes = true;
              }
              if (_.has(diff.changed_legs[key], 'note_to_driver')) {
                diff.changed_legs[key].changes.note_to_driver = true;
              }
              if (_.has(diff.changed_legs[key], 'pickup_date_time')) {
                setPickupTimeOfInDiff(diff.changed_legs[key], 'pickup');
              }
              if (_.has(diff.changed_legs[key], 'appointment_date_time')) {
                setPickupTimeOfInDiff(diff.changed_legs[key], 'appointment');
              }
              if (_.has(diff.changed_legs[key], 'source_address')) {
                diff.changed_legs[key].changes.source_address = true;
              }
              if (_.has(diff.changed_legs[key], 'dest_address')) {
                diff.changed_legs[key].changes.dest_address = true;
              }
            }
          });
          $scope.rideDetails.orderUpdateDiff.changedLegs = diff.changed_legs;
          const externalLegId = String($scope.rideDetails.external_leg_id);
          const { changedLegs } = $scope.rideDetails.orderUpdateDiff;
          if (_.has(changedLegs, externalLegId)) {
            const changedLeg = changedLegs[externalLegId];
            if (!changedLeg.legIsAdded) {
              if (changedLeg.changes.has_provider_preference) {
                $scope.rideDetails.orderUpdateDiff.has_provider_preference.changed = true;
                $scope.rideDetails.orderUpdateDiff.has_provider_preference.value = (changedLeg.has_provider_preference && 'Yes') || 'No';
              }
              if (changedLeg.changes.provider_preference_notes) {
                $scope.rideDetails.orderUpdateDiff.provider_preference_notes.changed = true;
                $scope.rideDetails.orderUpdateDiff.provider_preference_notes.value = changedLeg.provider_preference_notes;
              }
              if (changedLeg.changes.note_to_driver) {
                $scope.rideDetails.orderUpdateDiff.note_to_driver.changed = true;
                $scope.rideDetails.orderUpdateDiff.note_to_driver.value = changedLeg.note_to_driver;
              }
            }
          }
          diff.removed_legs.forEach((leg) => {
            const legInfo = {};
            legInfo.source_address = leg.source_address;
            legInfo.source_lat_lng = leg.source_lat_lng;
            legInfo.dest_address = leg.dest_address;
            legInfo.dest_lat_lng = leg.dest_lat_lng;
            legInfo.external_leg_id = leg.external_leg_id;

            const dateString = leg.pickup_date_time;
            const offsetString = Number(leg.pickup_timezone_offset || 0);
            const apptDateTime = _.get(leg, 'appointment_date_time', null);
            ({ time: legInfo.appt_time, afterOffsetDate: legInfo.appt_date } = unixTimeConversion(apptDateTime, leg.appointment_date_time_offset || 0));
            const legDetails = unixTimeConversion(dateString, offsetString);
            legInfo.time = legDetails.time;
            legInfo.date = legDetails.afterOffsetDate;
            $scope.rideDetails.orderUpdateDiff.removed_legs.push(legInfo);
          });
        } else {
          toaster.pop({
            type: 'error',
            title: 'No Changes are found in Order Update.',
            timeout: 3000,
          });
        }
      };

      const fetchOrderUpdateDiff = function fetchOrderUpdateDiff() {
        $http
          .get(`${API_BASE_URL}ride_order/diff`, {
            params: {
              external_order_id: $scope.rideDetails.orderInfo.orderId,
            },
          })
          .then((result) => {
            watcherRemovers.push(
              $timeout(() => {
                $scope.diffLoaded = true;
              }, 2000),
            );
            if (result.data.success) {
              mapOrderUpdateDiffToFields(result.data.data);
            } else {
              toaster.pop({
                type: 'error',
                title: 'No Updates are found for this Ride Order.',
                timeout: 3000,
              });
            }
          })
          .catch(() => {
            $scope.diffLoaded = true;
            toaster.pop({
              type: 'error',
              title: 'No Updates are found for this Ride Order.',
              timeout: 3000,
            });
          });
      };

      const addRideDetailsToScope = async (rideDetails) => {
        const rideStatuses = relaylib.ride.status;
        try {
          $scope.rideDetails.isVendorWithSoftwareProviderId = rideDetails.isVendorWithSoftwareProviderId;
          $scope.rideDetails.externalReferenceId = rideDetails.appointment.appt_provider_request_id;
          let legOneChanged = false;
          let legOneId;
          // ride details
          $scope.rideDetails.distance = rideDetails.distance;
          $scope.rideDetails.provider_id =  rideDetails.provider_id;
          // timezone details
          $scope.rideDetails.orderInfo.appt_timezone_offset = rideDetails.appointment.appointment_timezone_offset;
          $scope.rideDetails.orderInfo.appt_timezone = rideDetails.appointment.appt_timezone;
          // ride info
          $scope.rideDetails.rideInfo.patient = rideDetails.patient;
          $scope.rideDetails.rideInfo.health_plan_name = rideDetails.appointment.health_plan;
          $scope.rideDetails.org_id = rideDetails.org_id;
          $scope.rideDetails.external_leg_id = rideDetails.external_leg_id;
          $scope.rideDetails.orderInfo.status = rideDetails.ride_status;
          $scope.rideDetails.rideInfo.pickupOffset = (rideDetails.ride_additional_detail
              && rideDetails.ride_additional_detail.pickup_timezone_offset)
            || 0;
          $scope.rideDetails.orderInfo.appt_id = rideDetails.appt_id;
          // trip info
          $scope.rideDetails.tripInfo.leg_type = rideDetails.leg_type;
          if (rideDetails.book_now) {
            $scope.rideDetails.tripInfo.appointmentTime.time = 'BOOK_NOW';
          } else {
            $scope.rideDetails.tripInfo.appointmentTime.time = 'FUTURE_APPOINTMENT';
          }
          $scope.rideDetails.tripInfo.total_distance = 0;

          const { appointment: { organisation: { settings = [] } = {} } = {} } = rideDetails;
          const { setting_value: { traditional = null } = {} } = settings.find(setting => setting.setting_name === 'org_providers');
          // find hr68 setting
          const hr68Setting = settings.find(
            setting => setting.setting_name === 'hr68',
          );
          if (hr68Setting && hr68Setting.setting_value) {
            $scope.isHR68 = hr68Setting.setting_value === 'true';
          }
          if (!$scope.isHR68) {
            // find new_steerage
            const newSteerageSetting = settings.find(
              setting => setting.setting_name === 'new_steerage',
            );
            if (newSteerageSetting && newSteerageSetting.setting_value) {
              $scope.newSteerage = newSteerageSetting.setting_value === 'true';
            }
          }
          const disableRelayAction = false;

          // if (!traditional) {
          //   disableRelayAction = true;
          // }


          const externalLegProviderRequestIdArr = [];
          if (rideDetails.legs && rideDetails.legs.length) {
            rideDetails.legs.forEach((leg, index) => {
              externalLegProviderRequestIdArr.push(`Leg ${index + 1}: ${leg.provider_request_id}`);
              const legInfo = {};
              const jsonSourceAddress = _.get(leg, 'ride_additional_detail.s_json');
              const jsonDestinationAddress = _.get(leg, 'ride_additional_detail.d_json');
              legInfo.source_address = (jsonSourceAddress
                 && `${jsonSourceAddress.address}, ${jsonSourceAddress.city}, ${jsonSourceAddress.state}, ${jsonSourceAddress.zip}`)
                 || '';
              legInfo.source_lat_lng = leg.source_lat_lng;
              legInfo.dest_address = (jsonDestinationAddress
                && `${jsonDestinationAddress.address}, ${jsonDestinationAddress.city}, ${jsonDestinationAddress.state}, ${jsonDestinationAddress.zip}`)
                || '';
              legInfo.dest_lat_lng = leg.dest_lat_lng;
              legInfo.external_leg_id = leg.external_leg_id;
              legInfo.ride_id = leg.id;
              const dateString = leg.pickup_date_time;
              const offsetString = (leg.ride_additional_detail
                  && leg.ride_additional_detail.p_offset)
                || 0;
              const apptDetails = unixTimeConversion(leg.ride_additional_detail.appointment_date, leg.ride_additional_detail.appointment_timezone_offset);
              legInfo.appt_date = apptDetails.afterOffsetDate;
              legInfo.appt_time = apptDetails.time;
              const legDetails = unixTimeConversion(dateString, offsetString);
              legInfo.date = legDetails.afterOffsetDate;
              legInfo.time = legDetails.time;
              if (leg.leg_no === 1) {
                $scope.rideDetails.tripInfo.firstLegPickupDate = legDetails.afterOffsetDate;
                $scope.rideDetails.tripInfo.firstLegOffset = offsetString;
                $scope.rideDetails.firstLegStatus = leg.ride_status;
                if (leg.id !== rideId) {
                  legOneChanged = true;
                  legOneId = leg.id;
                }
              }

              if (leg.managed_by) {
                $scope.rideDetails.managed_by = leg.managed_by;
              }

              // calculating distance
              $scope.rideDetails.tripInfo.total_distance += Number(
                leg.distance,
              );

              if (pageRideId === leg.id && leg.ride_status === rideStatuses.POTENTIAL_UNAVAILABILITY.status && leg.provider_id) {
                $scope.rideDetails.isPotentialUnavailablity = true;
              }
              // consider ride order as confirmed if it was scheduled once and now in relay statuses.
              legInfo.ride_category = leg.ride_category;
              legInfo.ride_status = leg.ride_status;
              if (
                !$scope.providerTabs.isConfirmed
                && (legInfo.ride_category !== statusConsts.OFFER_CREATED.type
                  && legInfo.ride_category !== statusConsts.OFFERED.type)
              ) {
                $scope.providerTabs.isConfirmed = true;
              }
              if (
                !$scope.providerTabs.isConfirmed
                && (legInfo.ride_status === statusConsts.TWENTY_FOUR_HOUR_CONFIRMATION_MISSING.status)
              ) {
                $scope.providerTabs.isConfirmed = true;
              }

              if (
                !$scope.providerTabs.isActive
                && afterActiveCategories.includes(legInfo.ride_category)
                && legInfo.ride_status !== statusConsts.POTENTIAL_UNAVAILABILITY.status
              ) {
                $scope.providerTabs.isActive = true;
              }
              $scope.rideDetails.tripInfo.legs.push(legInfo);
            });
          }

          $scope.rideDetails.externalLegProviderRequestId = externalLegProviderRequestIdArr.join(', ');
          $scope.rideDetails.tripInfo.total_distance = Math.ceil($scope.rideDetails.tripInfo.total_distance);

          if (legOneChanged) {
            rideId = legOneId;
            $scope.updateFullOrder();
            return;
          }

          $scope.rideDetails.tripInfo.source_address = rideDetails.source_address;
          $scope.rideDetails.tripInfo.source_lat_lng = rideDetails.source_lat_long;
          $scope.rideDetails.tripInfo.dest_address = rideDetails.dest_address;
          $scope.rideDetails.tripInfo.dest_lat_lng = rideDetails.dest_lat_long;

          // mapping ride order details
          $scope.rideDetails.orderInfo.orderId = rideDetails.appointment.external_order_id;
          const orderDateString = rideDetails.appointment.order_create_date;
          const orderOffsetString = rideDetails.appointment.order_create_timezone_offset || 0;
          let orderTempDate; let orderAfterOffsetDate; let
            orderFinalDate;
          if (orderDateString) {
            orderTempDate = moment.unix(orderDateString);
            if (orderTempDate.isValid()) {
              orderAfterOffsetDate = orderTempDate.utcOffset(orderOffsetString);
              if (orderAfterOffsetDate.isValid()) {
                orderFinalDate = orderAfterOffsetDate.format(
                  'MM/DD/YYYY h:mm A',
                );
              }
            }
          }

          if (orderFinalDate) {
            $scope.rideDetails.orderInfo.orderGeneratedAt = orderFinalDate;
          } else {
            $scope.rideDetails.orderInfo.orderGeneratedAt = 'N/A';
          }

          $scope.rideDetails.orderInfo.prefferedVendor = (rideDetails.ride_additional_detail.has_provider_preference
              && 'Yes')
            || 'No';
          $scope.rideDetails.orderInfo.preferredVendorNotes = (rideDetails.ride_additional_detail.has_provider_preference
              && rideDetails.provider_preference_notes)
            || '';

          $scope.rideDetails.orderInfo.uatpNotes = rideDetails.appointment.uatp_notes;
          $scope.rideDetails.orderInfo.orderInternalNotes = rideDetails.appointment.order_internal_notes;
          $scope.rideDetails.orderInfo.driverNotes = rideDetails.note_to_driver;
          $scope.rideDetails.orderInfo.rideNotes = rideDetails.ride_notes || null;
          /* Working on when I open a ride order, if any of the following fields are TRUE or contain a value,
          I need to display them in the Driver Notes section of Relay Ride Order page */
          const driverNoteStr = [];
          if (rideDetails.note_to_driver) {
            driverNoteStr.push(rideDetails.note_to_driver);
          }
          if (rideDetails.ride_eligibility_parameters
            && elgParamConv.convertEligibilityObjToString(rideDetails.ride_eligibility_parameters, 'ride_order_page')) {
            driverNoteStr.push(elgParamConv.convertEligibilityObjToString(rideDetails.ride_eligibility_parameters,
              'ride_order_page'));
          }

          $scope.rideDetails.orderInfo.driverNotes = driverNoteStr.join(',');

          if ($scope.rideDetails.orderInfo.status === 'UATP') {
            $scope.providerTabs.isUATP = true;
          } else if ($scope.rideDetails.orderInfo.status === 'Order Updated') {
            $scope.providerTabs.isOrderUpdate = true;
            fetchOrderUpdateDiff();
          } else {
            const orderLegs = _.get($scope.rideDetails, 'tripInfo.legs', []);
            const updatedLeg = orderLegs.find(leg => leg.ride_status === 'Order Updated');
            if (updatedLeg) {
              $scope.rideDetails.legToUpdate = updatedLeg;
              $scope.providerTabs.isDispositionUpdate = true;
              fetchOrderUpdateDiff();
            }
          }
          // now fetch vendor lists and details also
          subscribePresenceChannelForAllLegs();

          const rideShareConfirmed = (
            [providerIdConstants.LYFT, providerIdConstants.UBER].includes(rideDetails.provider_id) && $scope.providerTabs.isConfirmed);

          if (rideShareConfirmed && $scope.rideDetails.firstLegStatus === statusConsts.CONFIRMED.status) {
            $scope.providerTabs.isConfirmed = false;
          }

          await fetchVendorList(rideDetails.id, {
            rideShareConfirmed,
            disableRelayAction,
          });
          $scope.isSendOffer = false;
          if ($scope.rideDetails.firstLegStatus === rideStatuses.POTENTIAL_UNAVAILABILITY.status && !rideShareConfirmed) {
            $scope.isSendOffer = true;
            $scope.providerTabs.isConfirmed = false;
          }
          if (!traditional) {
            $scope.traditionalOff = true;
            $scope.rideDetails.availableVendors.error = 'Traditional Providers Excluded';
          }

          // broadcast that ride details are fetched successfully
          $scope.$broadcast('rideOrderFetched');
        } catch (error) {
          toaster.pop({
            type: 'error',
            title: error.message,
            timeout: 3000,
          });
        }
      };
      const tabStyle = {
        active: {
          color: '#1ab394',
        },
        inactive: {
          color: 'grey',
        },
      };

      function resetProviderTabs() {
        $scope.providerTabs = {
          isUATP: false,
          hasMaxRadiusProviders: false,
          isOffered: false,
          hasOneSelected: false,
          isConfirmed: false,
          isRideShareSelected: false,
          isOrderUpdate: false,
          isActive: false,
          activeIndex: 'ep',
          getTabStyle(tab) {
            if (tab.activeCode === $scope.providerTabs.activeIndex) {
              return tabStyle.active;
            }
            return tabStyle.inactive;
          },
          categorySetting: {
            rideShareProviders: {
              code: 'rideShareProviders',
              isRideShareCategory: true,
              title: 'Ride Share Providers',
              visibleOn: ['ep'],
              show: false, // This is false by default and only if lyft is eligible then show it.
            },
            withoutSoftwareId: {
              code: 'withoutSoftwareId',
              isRideShareCategory: false,
              title: 'One Call Providers',
              visibleOn: ['ep', 'me', 'ne', 'sra'],
              show: true,
            },
            withSoftwareId: {
              code: 'withSoftwareId',
              isRideShareCategory: false,
              title: 'One Call Providers having Software ID',
              visibleOn: ['ep', 'me', 'ne', 'sra'],
              show: true,
            },
          },
          tabs: [
            {
              heading: 'Available Vendors',
              activeCode: 'ep',
              show: true,
            },
            {
              heading: 'More Eligible',
              activeCode: 'me',
              show: false,
            },
            {
              heading: 'Non Eligible',
              activeCode: 'ne',
              show: false,
            },
            {
              heading: 'SRA Vendors',
              activeCode: 'sra',
              show: false,
            },
          ],
          allSelected: {},
          counts: {},
        };

        $scope.providerTabs.categories = [
          $scope.providerTabs.categorySetting.rideShareProviders,
          $scope.providerTabs.categorySetting.withoutSoftwareId,
          $scope.providerTabs.categorySetting.withSoftwareId,
        ];
      }

      const fetchRideDetails = async (rideid) => {
        try {
          const result = await $http.get(
            `${API_BASE_URL}ride/getRideDetailsById/${rideid}`,
            {
              params: {
                org_id: loggedInUserDetails.organisation.id,
                fetchOrgProvidersSetting: true,
                fetchOrgHR68Setting: true,
                fetchOrgNewSteerageSetting: true,
              },
            },
          );

          if (result.data.success) {
            resetProviderTabs();
            resetRideDetails();
            resetAvailableVendors();
            setupTabs();
            await addRideDetailsToScope(result.data.rideDetails);
          } else {
            toaster.pop({
              type: 'error',
              title: 'Error while fetching ride details.',
              timeout: 3000,
            });
          }
        } catch (error) {
          toaster.pop({
            type: 'error',
            title: error.message || 'Error while fetching ride details',
            timeout: 3000,
          });
        }
      };
      $scope.generatedViaClass = function generatedViaClass(generatedVia) {
        let generatedViaClassCss = null;
        switch (generatedVia) {
          case 'Auto-Assignment':
            generatedViaClassCss = 'generated-via generated-via-aa';

            break;
          case 'Sticky':
            generatedViaClassCss = 'generated-via generated-via-sticky';
            break;
          case 'Rideshare-Auto':
            generatedViaClassCss = 'generated-via generated-via-ra';
            break;
          case 'Manual':
            generatedViaClassCss = 'generated-via generated-via-manual';
            break;
          default:
        }
        return generatedViaClassCss;
      };
      $scope.updateVendorList = function updateVendorList() {
        resetAvailableVendors();
        fetchVendorList(rideId);
      };

      $scope.updateFullOrder = function updateFullOrder() {
        loadingScreenFactory.showLoadingScreen();
        fetchRideDetails(rideId).finally(() => {
          loadingScreenFactory.hideLoadingScreen();
          $scope.$apply();
        });
      };

      $scope.onListChange = function onListChange() {
        loadingScreenFactory.showLoadingScreen();
        fetchPreviousVendorDetails(
          $scope.rideDetails.ride_id,
          $scope.rideDetails.selectedPreviousEligibilityList,
        ).finally(() => {
          loadingScreenFactory.hideLoadingScreen();
          $scope.$apply();
        });
      };

      $scope.providerTabs = {};

      $scope.regenerateVendorList = function regenerateVendorList(rideid, radius) {
        const la = Ladda.create(
          document.querySelector('.generate-list-button'),
        );
        la.start();
        $http
          .put(`${API_BASE_URL}vendors/${rideid}/${radius}/1`)
          .then((result) => {
            if (result.data.success) {
              $scope.updateFullOrder();
            } else {
              toaster.pop({
                type: 'error',
                title: 'Cannot generate new Providers list!',
                body: `Reason: ${result.data.message || 'Unknown Error'}`,
                timeout: 3000,
              });
            }
          })
          .catch((error) => {
            toaster.pop({
              type: 'error',
              title: 'Cannot generate new Providers list!',
              body: `Reason: ${error.data.message || 'Unknown Error'}`,
              timeout: 3000,
            });
          }).finally(() => {
            la.stop();
          });
      };


      $scope.showGenerateListDisabledMessage = function showGenerateListDisabledMessage() {
        if ($scope.providerTabs.isOffered) {
          toaster.pop({
            type: 'info',
            title:
              'There are some outstanding offers, so you cannot generate new list.',
            timeout: 3000,
          });
        } else {
          // do nothing
        }
      };

      $scope.markRideOutOfNetwork = (apptId) => {
        SweetAlert.swal({
          title: 'Are you sure?',
          text: 'Are you sure you want to mark this ride Out Of Network?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          closeOnConfirm: true,
          confirmButtonColor: '#DD6B55',
          cancelButtonText: 'No',
        }, async (isConfirm) => {
          if (!isConfirm) {
            return;
          }
          loadingScreenFactory.showLoadingScreen();
          try {
            await $http.put(`${API_BASE_URL}vendors/appt/${apptId}/mark-as-OON`);
            window.history.back();
          } catch (errorResponse) {
            toaster.pop({
              type: 'error',
              title: `Error while Updating Ride Status: ${_.get(errorResponse, 'data.message')}`,
              timeout: 3000,
            });
          } finally {
            loadingScreenFactory.hideLoadingScreen();
            $scope.$apply();
          }
        });
      };

      (async () => {
        getAllOrganizations();
        resetProviderTabs();
        resetRideDetails();
        resetAvailableVendors();
        loadingScreenFactory.showLoadingScreen();
        fetchRideDetails(rideId).finally(() => {
          loadingScreenFactory.hideLoadingScreen();
          $scope.$apply();
        });
      })();

      $scope.$on('$destroy', () => {
        angular.forEach(watcherRemovers, (w) => {
          if (typeof w === 'function')w();
        });
        Pubnub.removeListener(presenceListeners);
        const presenceChannels = $scope.subscribedLegs.map(subRideId => `presence_${subRideId}`);
        presenceChannels.forEach((channel) => {
          pubnubNotificationFactory.unsubscribeChannel(channel);
        });
      });
    },
  ]);
