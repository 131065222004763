angular
  .module('relayHealth')
  .service('providerService',
    function providerService() {
      const providerMapping = relaylib.common.constants.providerIdMapping;

      this.getProviderNameById = function getProviderNameById(providerId) {
        switch (providerId) {
          case providerMapping.UBER:
            return 'Uber';
          case providerMapping.LYFT:
            return 'Lyft';
          case providerMapping.EXTERNAL:
            return 'One Call Network';
          default:
            return '';
        }
      };
    });
