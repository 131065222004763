import './unorganized-imports';
import angular from 'angular';
import angularAnimate from 'angular-animate';
import uiRouter from '@uirouter/angularjs';
import angularToaster from 'angularjs-toaster/index';
import 'angular-sweetalert/SweetAlert';
import './unorganized-js/ui-bootstrap-tpls-1.1.2.min';
import 'angular-translate';
import ngIdle from 'ng-idle';
import angularSanitize from 'angular-sanitize';
import './unorganized-js/angularjs-google-maps';
import './unorganized-js/icheck/icheck.min';
import 'pubnub-angular';
import 'angular-schema-form';
import 'angular-schema-form-bootstrap';
import 'angular-fcsa-number';
import 'ng-mask';
import ngMap from 'ngmap';
import 'checklist-model';
import ngDialog from 'ng-dialog';
import uiSelect from 'ui-select';
import 'angular-datepicker';
import 'ng-tags-input';
import 'angular-daterangepicker/js/angular-daterangepicker';
import 'jsonformatter';
import './unorganized-js/angular-rating/rating';
import 'angular-hammer';
import 'angular-bootstrap-duallistbox/dist/angular-bootstrap-duallistbox';
import ngQuill from 'ng-quill';
import config from './constants/relay-config';
import relayComps from './components';

const app = angular
  .module('relayHealth', [
    angularAnimate,
    uiRouter,
    angularToaster,
    'oitozero.ngSweetAlert',
    'ui.bootstrap',
    'pascalprecht.translate',
    ngIdle,
    angularSanitize,
    'gm',
    'pubnub.angular.service',
    'schemaForm',
    'fcsa-number',
    'ngMask',
    'checklist-model',
    ngDialog,
    config,
    relayComps,
    uiSelect,
    ngMap,
    ngQuill,
    'datePicker',
    'stars',
    'daterangepicker',
    'jsonFormatter',
    'ngTagsInput',
    'frapontillo.bootstrap-duallistbox',
  ])
  .run([
    '$rootScope',
    'urlFactory',
    'filePathFactory',
    '$window',
    '$location',
    'ShowBroadcastService',
    'pubnubNotificationFactory',
    '$state',
    'SITE_NAME',
    'editableOptions',
    '$transitions',
    '$uibModalStack',
    'ngDialog',
    function initialRun(
      $rootScope,
      urlFactory,
      filePathFactory,
      $window,
      $location,
      ShowBroadcastService,
      pubnubNotificationFactory,
      $state,
      SITE_NAME = '',
      editableOptions,
      $transitions,
      $uibModalStack,
      dialog,
    ) {
      $rootScope.$on('$locationChangeStart', (event, current) => {
        const navValue = urlFactory.getParameterByName('nav', current);
        Object.assign($rootScope, { navValue });
      });

      if (localStorage.getItem('userDetails')) { // only initiate broadcast service when user is logged in
        ShowBroadcastService.init();
      }

      $transitions.onSuccess({}, (transition) => {
        if ($window.ga) {
          $window.ga('send', 'pageview', $location.path());
        }
        if (transition.to().redirectTo) {
          $state.go(transition.to().redirectTo, transition.to().params, { location: 'replace' });
        } else {
          document.title = `${(transition.to().data && transition.to().data.pageTitle) || '-'} | ${SITE_NAME}`;
        }

        // Dismiss all modals from pervious states
        $uibModalStack.dismissAll();

        // Dismiss all dialogs from pervious states
        dialog.closeAll();
      });

      // Unsubscribe all channels and groups
      $($window).on('beforeunload', () => {
        pubnubNotificationFactory.unsubscribeAllChannel();
      });

      Object.assign($rootScope, { objFilePath: filePathFactory.getImagePathObjects() });

      Object.assign(editableOptions, { theme: 'bs3' }); // x-editable-plugin configuration
    },
  ])
  .config([
    '$controllerProvider',
    '$compileProvider',
    '$filterProvider',
    '$provide',
    ($controllerProvider, $compileProvider, $filterProvider, $provide) => {
      app.lazy = {
        controller: $controllerProvider.register,
        directive: $compileProvider.directive,
        filter: $filterProvider.register,
        factory: $provide.factory,
        service: $provide.service,
      };
    },
  ])
  .config([
    'dateRangePickerConfig',
    'dateTimeConfig',
    (dateRangePickerConfig, dateTimeConfig) => {
      Object.assign(dateRangePickerConfig.locale, { format: 'MM/DD/YYYY' });
      Object.assign(dateTimeConfig, { format: 'MM/DD/YYYY' });
    },
  ]);

export default app;
