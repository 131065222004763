import StorageProvider from '../storageProvider';
import Command from '../../common/command';

export default class GetItem extends Command {
  constructor() {
    super();
  }
  execute(key){
    const strorageType = StorageProvider.instance.storageType;
    const storageObj = new strorageType();
    return storageObj.getItem(key);
  }
}
