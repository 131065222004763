angular.module('relayHealth').controller('bwbOutboundReportCtrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  'toaster',
  'loadingScreenFactory',
  '$location',
  'storageManager',
  function bwbOutboundReportCtrl(
    $scope,
    $http,
    API_BASE_URL,
    toaster,
    loadingScreenFactory,
    $location,
    storageManager,
  ) {
    const { storage } = storageManager;

    $scope.moment = moment;
    $scope.readStatuses = {
      viewed: 1,
      archived: 2,
    };

    // Main data source, responsible for all the content visible on the frontend. Make sure to update this using API
    $scope.tabs = [
      {
        // Tab heading
        heading: 'Active',
        show: true, // Flag to enable/disable this tab
        active: true, // Flag to make this tab active by default
        // Array of items that will be shown under this tab
        data: [],
        // Pagination stuff... need to keep updated total items, current page, items per page, last item, etc.
        pagination: {
          totalItems: 4,
          currentPage: 1,
          itemsPerPage: 10,
          lastItem: 4,
        },
        filter: {
          dateRange: {
            startDate: null,
            endDate: null,
          },
          status: '',
          readStatus: '',
        },
      },
      {
        // Tab heading
        heading: 'Archived',
        show: true, // Flag to enable/disable this tab
        active: false, // Flag to make this tab active by default
        // Array of items that will be shown under this tab
        data: [],
        // Pagination stuff... need to keep updated total items, current page, items per page, last item, etc.
        pagination: {
          totalItems: 4,
          currentPage: 1,
          itemsPerPage: 10,
          lastItem: 4,
        },
        filter: {
          dateRange: {
            startDate: null,
            endDate: null,
          },
          status: '',
          readStatus: '',
        },
      },
    ];


    function setSearchFilters(tabKey) {
      let matchData = {};

      let dateRange = '';

      if ($scope.tabs[tabKey].filter.dateRange && $scope.tabs[tabKey].filter.dateRange.startDate && $scope.tabs[tabKey].filter.dateRange.endDate) {
        dateRange = {
          startDate: typeof $scope.tabs[tabKey].filter.dateRange.startDate === 'object'
            ? `${$scope.tabs[tabKey].filter.dateRange.startDate.format('YYYY-MM-DD')} 00:00:00`
            : $scope.tabs[tabKey].filter.dateRange.startDate,
          endDate: typeof $scope.tabs[tabKey].filter.dateRange.endDate === 'object'
            ? `${$scope.tabs[tabKey].filter.dateRange.endDate.format('YYYY-MM-DD')} 23:59:59`
            : $scope.tabs[tabKey].filter.dateRange.endDate,
        };
      }
      matchData = {
        status: $scope.tabs[tabKey].filter.status ? $scope.tabs[tabKey].filter.status : undefined,
        readStatus: $scope.tabs[tabKey].filter.readStatus || tabKey === 0 ? 'active' : 'archived',
      };
      if (dateRange) {
        matchData.dateRange = dateRange;
      }

      if (tabKey === 0 && matchData.readStatus === 'archived') {
        delete matchData.readStatus;
      }
      if (tabKey === 1 && (matchData.readStatus === 'viewed' || matchData.readStatus === 'active')) {
        matchData.readStatus = 'archived';
      }

      return angular.copy(matchData);
    }

    function setUrlFilters(tabKey, filters) {
      if ($scope.tabs[tabKey].active) {
        $location.search('activeTab', tabKey);
        Object.keys(filters).forEach((key) => {
          if (key === 'dateRange') {
            $location.search('startDate', filters.dateRange.startDate);
            $location.search('endDate', filters.dateRange.endDate);
          } else {
            $location.search(key, filters[key]);
          }
        });
        $location.search('currentPage', $scope.tabs[tabKey].pagination.currentPage);
      }
      return filters;
    }

    function getReports(tabKey, filters) {
      loadingScreenFactory.showLoadingScreen();
      const getReportsCall = $http({
        // TODO: update path to reports API
        url: `${API_BASE_URL}fuseReports/bwbReports/${($scope.tabs[tabKey].pagination.currentPage - 1) * $scope.tabs[tabKey].pagination.itemsPerPage}/${
          $scope.tabs[tabKey].pagination.itemsPerPage
        }`,
        method: 'GET',
        params: filters,
      });

      getReportsCall.then(
        (result) => {
          if (result.data.success) {
            loadingScreenFactory.hideLoadingScreen();
            $scope.tabs[tabKey].data = result.data.data;
            $scope.tabs[tabKey].firstTimeLoad = false;
            $scope.tabs[tabKey].pagination.totalItems = result.data.totalCount;
            const lastItem = ($scope.tabs[tabKey].pagination.currentPage - 1) * $scope.tabs[tabKey].pagination.itemsPerPage
              + (result.data.data.length ? result.data.data.length : 1);
            $scope.tabs[tabKey].pagination.lastItem = lastItem > result.data.totalCount ? result.data.totalCount : lastItem;
          }
        },
        (err) => {
          loadingScreenFactory.hideLoadingScreen();
          $scope.tabs[tabKey].data = [];
          $scope.tabs[tabKey].pagination.totalItems = 0;
          $scope.tabs[tabKey].pagination.lastItem = 0;

          toaster.pop({
            type: 'info',
            title: err.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    }

    function filterReports(tabKey, isSearch = false) {
      if ($scope.tabs[tabKey].active) {
        $location.url($location.path());
      }

      const matchData = setSearchFilters(tabKey);
      if (isSearch) {
        storage.setData('filterDatabwb', { tab: tabKey, data: matchData });
      }
      setUrlFilters(tabKey, matchData);

      getReports(tabKey, matchData);
    }

    function init() {
      Object.keys($scope.tabs).forEach((tabKey) => {
        filterReports(tabKey);
      });
    }

    init();


    $scope.dateRangeOptions = {
      parentEl: '#current-app #page-wrapper',
      eventHandlers: {
        'apply.daterangepicker': function applyDaterangepicker() {
          const activeTab = Object.keys($scope.tabs).find(tabKey => $scope.tabs[tabKey].active);
          const dateRangeFilter = document.getElementsByName('dateRange')[0].value;

          if (dateRangeFilter) {
            const dateRangeFilterArr = dateRangeFilter.split(' - ');
            $scope.tabs[activeTab].filter.dateRange.startDate = `${dateRangeFilterArr[0]} 00:00:00`;
            $scope.tabs[activeTab].filter.dateRange.endDate = `${dateRangeFilterArr[1]} 23:59:59`;
          }
        },
      },
    };

    $scope.tabClicked = (tabKey) => {
      $location.url($location.path());
      const matchData = setSearchFilters(tabKey);
      setUrlFilters(tabKey, matchData);
      $location.search('activeTab', tabKey);
    };

    function writeSearchFilter() {
      const prevSearchValues = storage.getData('filterDatabwb');
      if (!prevSearchValues) {
        return;
      }
      const prevTab = prevSearchValues.tab;
      const prevForm = prevSearchValues.data;
      $scope.tabs[prevTab].filter.dateRange = prevForm.dateRange;
      $scope.tabs[prevTab].filter.status = prevForm.status;
    }

    writeSearchFilter();
    $scope.clearFilters = (tabKey) => {
      const prevSearchValues = storage.getData('filterDatabwb');
      if (prevSearchValues && prevSearchValues.tab === tabKey) {
        storage.removeData('filterDatabwb');
      }

      $scope.tabs[tabKey].filter = {
        dateRange: {
          startDate: null,
          endDate: null,
        },
        status: '',
        readStatus: '',
      };
      $scope.tabs[tabKey].pagination.currentPage = 1;
      $location.url($location.path());
      filterReports(tabKey);
    };

    $scope.searchButtonClicked = (tabKey) => {
      $scope.tabs[tabKey].pagination.currentPage = 1;
      filterReports(tabKey, true);
    };

    getReports(0, {
      readStatus: 'active',
    });

    function updateArchiveTabItems() {
      getReports(1, {
        readStatus: 'archived',
      });
    }

    function updateActiveTabItems() {
      getReports(0, {
        readStatus: 'active',
      });
    }

    $scope.handleReadStatusChange = (item) => {
      if (item.read_status && item.read_status !== 'active') {
        const putReportCall = $http({
          url: `${API_BASE_URL}fuseReports/editReport/${item.id}`,
          method: 'PUT',
          data: {
            read_status: item.read_status,
          },
        });
        putReportCall.then(() => {
          updateActiveTabItems();
          updateArchiveTabItems();
        });
      }
    };


    $scope.pageChanged = (tabKey) => {
      if (!$scope.tabs[tabKey].firstTimeLoad) {
        filterReports(tabKey);
      }
    };


    function updateTabItems() {
      updateActiveTabItems();
      updateArchiveTabItems();
    }

    updateTabItems();
  },
]);
