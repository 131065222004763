angular.module('relayHealth').controller('userProfileCtrl', [
  '$scope',
  '$rootScope',
  '$http',
  'API_BASE_URL',
  'PASSWORD_PUBLIC_KEY',
  '$state',
  'toaster',
  'SweetAlert',
  'loadingScreenFactory',
  'pubnubNotificationFactory',
  '$document',
  'SITE_NAME',
  function userProfileCtrl(
    $scope,
    $rootScope,
    $http,
    API_BASE_URL,
    PASSWORD_PUBLIC_KEY,
    $state,
    toaster,
    SweetAlert,
    loadingScreenFactory,
    pubnubNotificationFactory,
    $document,
    SITE_NAME,
  ) {
    const { userRoles } = relaylib.common.constants;
    $scope.permissionsConstants = relaylib.permissions.permissionsConstants;
    $scope.user = JSON.parse(localStorage.getItem('userDetails'));
    $scope.confirmPassword = '';
    $scope.oldPassword = '';
    $scope.newPassword = '';
    $scope.promptPasswordChange = false;
    if ($scope.user.password_expired) {
      $scope.promptPasswordChange = true;
    }
    $scope.error_msg = '';
    $scope.error_msg_confirm = '';
    $scope.hasError = true;
    $scope.validatePassword = () => {
      $scope.error_msg = '';
      $scope.error_msg_confirm = '';
      const ascii = /^[\x20-\x7E]+$/;
      const password = $scope.newPassword;
      if (!password) {
        return;
      }
      if (password.length < 8) {
        $scope.error_msg = 'Password length should be atleast 8';
        return;
      }
      if (password.match(/\s/g)) {
        $scope.error_msg = 'Password should not contain spaces';
        return;
      }
      if (password.search(/\d/) === -1) {
        $scope.error_msg = 'Password should contain a digit';
        return;
      }
      if (password.search(/[a-z]/) === -1) {
        $scope.error_msg = 'Password should contain a lower case letter';
        return;
      }
      if (password.search(/[A-Z]/) === -1) {
        $scope.error_msg = 'Password should contain an upper case letter';
        return;
      }
      if (password.search(/[!@#$%^&*()_+.,;:]/) === -1) {
        $scope.error_msg = 'Password should contain a special symbol';
        return;
      }
      if (ascii.test(password) === false) {
        $scope.error_msg = 'Password should not contain control character or non printable character';
        return;
      }
      if (password.match(/[\\]/g)) {
        $scope.error_msg = 'Password should not contain backslash';
        return;
      }
      for (let i = 0; i + 5 <= $scope.user.first_name.length; i += 1) {
        if (password.toLowerCase().indexOf($scope.user.first_name.toLowerCase().substring(i, i + 5)) !== -1) {
          $scope.error_msg = "Password can not contain First name's significant portions";
          return;
        }
      }
      for (let i = 0; i + 5 <= $scope.user.last_name.length; i += 1) {
        if (password.toLowerCase().indexOf($scope.user.last_name.toLowerCase().substring(i, i + 5)) !== -1) {
          $scope.error_msg = "Password can not contain Last name's significant portions";
          return;
        }
      }
      for (let i = 0; i + 5 <= $scope.user.username.length; i += 1) {
        if (password.toLowerCase().indexOf($scope.user.username.toLowerCase().substring(i, i + 5)) !== -1) {
          $scope.error_msg = "Password can not contain username's significant portions";
          return;
        }
      }

      if ($scope.confirmPassword !== $scope.newPassword) {
        if ($scope.confirmPassword && !$scope.error_msg) {
          $scope.error_msg_confirm = 'Password and confirm password do not match';
        }
        $scope.hasError = true;
      } else {
        $scope.passwordMatch = true;
        $scope.error_msg = '';
        $scope.error_msg_confirm = '';
        $scope.hasError = false;
      }
    };
    $scope.checkConfirmPassword = () => {
      if ($scope.newPassword === $scope.confirmPassword) {
        $scope.error_msg = '';
        $scope.error_msg_confirm = '';
        $scope.hasError = false;
        $scope.validatePassword();
      } else if (!$scope.err_msg_confirm) {
        $scope.error_msg_confirm = 'Password and confirm password do not match';
      }
    };
    const redirectToHomepage = () => {
      if ($scope.user.role === userRoles.PROVIDER_MANAGER) {
        $state.go('main.userMgmt.providerList', { redirect: true });
        return;
      }
      $state.go('main.dashboard.superAdminDashboard', { redirect: true });
    };
    $scope.changePassword = () => {
      $scope.loading = true;
      // Encrypting passwords before sending to server
      const crypt = new JSEncrypt();
      crypt.setPublicKey(PASSWORD_PUBLIC_KEY);

      const oldPassword = crypt.encrypt($scope.oldPassword, 'base64');
      const password = crypt.encrypt($scope.newPassword, 'base64');

      const serverData = {
        old_password: oldPassword,
        new_password: password,
      };
      const headers = {
        username: $scope.user.username,
        usertoken: $scope.user.user_token,
      };
      const res = $http.put(`${API_BASE_URL}changePassword/user/${$scope.user.user_id}`, serverData, headers);
      res.then(({ data }) => {
        if (data.success) {
          toaster.success({
            type: 'success',
            title: data.message,
            showCloseButton: true,
            timeout: 10000,
          });
          $scope.confirmPassword = '';
          $scope.oldPassword = '';
          $scope.newPassword = '';
          $scope.user.password_expired = false;
          localStorage.setItem('userDetails', JSON.stringify($scope.user));
          $scope.user = JSON.parse(localStorage.getItem('userDetails'));
          redirectToHomepage();
        } else {
          $scope.confirmPassword = '';
          $scope.oldPassword = '';
          $scope.newPassword = '';
          toaster.error({
            type: 'error',
            title: data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        }
        $scope.loading = false;
      });
      res.catch(({ data }) => {
        $scope.loading = false;
        $scope.confirmPassword = '';
        $scope.oldPassword = '';
        $scope.newPassword = '';
        $scope.formSubmited = false;
        toaster.error({
          type: 'error',
          title: data.message,
          showCloseButton: true,
          timeout: 10000,
        });
      });
    };
    $scope.changePasswordCancel = () => {
      redirectToHomepage();
    };
    const getUserSettings = function getUserSettings() {
      let userDetails = localStorage.getItem('userDetails');
      userDetails = JSON.parse(userDetails);
      const headers = {
        username: userDetails.username,
        usertoken: userDetails.user_token,
        'Content-Type': 'application/json',
      };
      const request = $http.get(`${API_BASE_URL}user/settings`, { headers });

      request.then(({ data: objSuccess }) => {
        $scope.userSettings = objSuccess.data.settings;
      });
    };

    const init = function init() {
      getUserSettings();
    };

    init();
    $scope.updateUserSettings = function updateUserSettings(key, value) {
      const obj = { settings: {} };
      obj.settings[key] = value;
      const request = $http.put(`${API_BASE_URL}user/settings`, obj);
      loadingScreenFactory.showLoadingScreen();
      request.then(() => {
        getUserSettings();
        SweetAlert.swal('Success', 'Settings updated successfully', 'success');
        loadingScreenFactory.hideLoadingScreen();

        if (key === 'admin_status') {
          const [doc] = $document;
          doc.title = `${SITE_NAME} | Login`;
          $rootScope.$broadcast('removeClickHandler');
          pubnubNotificationFactory.unsubscribeAllChannel();
          const res = $http.put(`${API_BASE_URL}user/logout`, { status: value });
          res.then(({ data }) => {
            if (data.success) {
              localStorage.clear();
              $state.go('login');
            } else {
              toaster.pop({
                type: 'error',
                title: data.message,
                showCloseButton: true,
                timeout: 10000,
              });
            }
          });
          res.catch(() => {
            toaster.pop({
              type: 'error',
              title: 'Not able to logout',
              showCloseButton: true,
              timeout: 10000,
            });
          });
        }
      });
    };
  },
]);
