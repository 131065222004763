angular.module('relayHealth').controller('signupCtrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  '$state',
  function signupCtrl($scope, $http, API_BASE_URL, $state) {
    $scope.state = {};

    function getLocalStorage(stateName) {
      if (!stateName) return false;
      const businessState = localStorage.getItem(stateName);
      return JSON.parse(businessState);
    }

    function setLocalStorage(stateName, state) {
      if (!stateName || !state) return;
      localStorage.setItem(stateName, JSON.stringify(state));
    }

    function pageAccessCheck() {
      const userDetails = getLocalStorage('userDetails');
      if (userDetails.phone_verified === true) {
        $state.go('main.dashboard.superAdminDashboard');
        return;
      }

      if (userDetails.role !== 'tempBusiness' && userDetails.role !== 'orgadmin' && userDetails.role !== 'tempRider' && userDetails.role !== 'riderUser') {
        $state.go('main.dashboard.superAdminDashboard');
      }
    }

    function init() {
      // check if the user has already verified the phone number, if yes then redirect to the dashboard page
      // check if the user's role is allowed to access the page.
      pageAccessCheck();
      const userDetails = getLocalStorage('userDetails');
      const stateStructure = {
        signupType: userDetails.organisation.org_class,
        role: userDetails.role,
        userType: userDetails.role,
        email: userDetails.username,
        firstName: userDetails.first_name,
        lastName: userDetails.last_name,
        phone: userDetails.phone ? userDetails.phone.slice(-10) : '',
        userId: userDetails.user_id,
        accountInfo: {
          orgId: '',
          state: '',
          city: '',
          address: '',
          zipcode: '',
        },
        latestStage: 1,
        acceptTc: false,
        businessInfo: {
          orgId: '',
          interest: '',
          orgName: '',
          streetAddress: '',
          city: '',
          state: '',
          zipCode: '',
          TIN: '',
        },
        users: [],
        monthlyInvoice: false,
        stripeCardAdded: false,
        expMonth: '',
        expYear: '',
        billingAddress: {
          streetAddress: '',
          city: '',
          state: '',
          zipCode: '',
        },
      };
      const state = getLocalStorage('signupState') || setLocalStorage('signupState', stateStructure);
      if (state) {
        $scope.state = state;
      } else {
        $scope.state = stateStructure;
      }
    }

    init();

    $scope.resendOtp = () => {
      const strippedPhone = $scope.state.phone.replace(/[- )(]/g, '');
      const phone = `+1${strippedPhone}`;
      const data = { phone, userId: $scope.state.userId };

      $http
        .post(`${API_BASE_URL}resend/otp`, data)
        .then(({ data: { success } }) => {
          if (success) {
            // otp sent succesfully
          }
        })
        .catch(() => {
          // could not send otp
        });
    };

    $scope.verifyOtp = () => {
      const strippedPhone = $scope.state.phone.replace(/[- )(]/g, '');
      const phone = `+1${strippedPhone}`;
      const data = { phone, userId: $scope.state.userId };

      $http
        .post(`${API_BASE_URL}verify/otp`, data)
        .then(({ data: { success } }) => {
          if (success) {
            // otp sent succesfully
          }
        })
        .catch(() => {
          // could not send otp
        });
    };

    $scope.populateErrorFields = (fieldName, message) => !(!fieldName || !message);
  },
]);
