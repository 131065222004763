angular.module('relayHealth')
  .controller('masRideInfoCtrl', ['$scope', '$timeout', 'setMapPoints',
    function rideInfoCtrl($scope, $timeout, setMapPoints) {
      $timeout(() => {
        if ($scope.rideDetails.rideInfo.patient.phone) {
          $scope.patientPhone = $scope.rideDetails.rideInfo.patient.phone;
        }
        if ($scope.rideDetails.rideInfo.patient.contact_phone) {
          $scope.contactPhone = $scope.rideDetails.rideInfo.patient.contact_phone;
        }
      });
      $timeout(() => {
        angular.element('#patient_phone_number').trigger('phone-number-changed', $scope.patientPhone);
        angular.element('#ride_contact_number').trigger('phone-number-changed', $scope.ontactPhone);
      });

      let map;
      const setMap = function setMap() {
        if (map) {
          return;
        }
        map = new google.maps.Map(document.getElementById('rideInfoMap'), {
          zoom: 12,
        });
        const markers = [];
        const mapPoints = {
          source: {
            latitude: $scope.rideDetails.tripInfo.source_lat_lng[0],
            longitude: $scope.rideDetails.tripInfo.source_lat_lng[1],
          },
          destination: {
            latitude: $scope.rideDetails.tripInfo.dest_lat_lng[0],
            longitude: $scope.rideDetails.tripInfo.dest_lat_lng[1],
          },
        };
        setMapPoints(map, markers, mapPoints);
      };

      $scope.$on('riderInfoSelected', () => {
        setMap();
      });
    }]);
