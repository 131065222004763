const userRoles = {
    MASTER_SUPER_ADMIN: 'mastersuperadmin',
    SUPER_ADMIN: 'superadmin',
    SCHEDULING_ADMIN: 'Scheduling admin',
    RIDE_MANAGER: 'admin',
    ORG_ADMIN: 'orgadmin',
    ORG_SUPERADMIN: 'org_superadmin',
    RIDER_ADMIN: 'riderAdmin',
    RIDER_USER: 'riderUser',
    PATIENT: 'patient',
    TEMP_RIDER: 'tempRider',
    TEMP_BUSINESS: 'tempBusiness',
    API_USER: 'apiuser',
    PRODUCTION_OPERATION: 'productionOperation',
    NETWORK_OPERATION: 'networkOperation',
    MICRO_SERVICE: 'micro_service',
    PROVIDER_MANAGER: 'providerManager',
    INVITED_PATIENT:'invitedPatient',
    OC_REPORTING:'oc_reporting',
    ORG_REPORTING:'org_reporting'
};
Object.freeze(userRoles);
export default userRoles;