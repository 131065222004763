angular.module('relayHealth').directive('tripIdentifier', [
  'TRIP_IDENTIFIERS', function tripIdentifier(TRIP_IDENTIFIERS) {
    return {
      restrict: 'E',
      require: 'ngModel',
      scope: {
        fileNo: '=',
        orderId: '=',
        masInvoiceNumber: '=',
        tripIdentifierType: '=',
        tripIdentifierChanged: '&',
        enterOnFormSearch: '&',
      },
      controller: ['$scope', '$attrs', function controller($scope, $attrs) {
        const attrsToTripIdentifierTypeMapping = {
          fileNo: 'file_number',
          orderId: 'ride_order_id',
          masInvoiceNumber: 'mas',
        };
        const tripIdentifiers = [];

        Object.keys(attrsToTripIdentifierTypeMapping).forEach((attr) => {
          if ($attrs[attr]) {
            const tripIdentifierType = attrsToTripIdentifierTypeMapping[attr];
            const mappedTripIdentifier = TRIP_IDENTIFIERS.find(tripIdentifierObj => (tripIdentifierObj.type === tripIdentifierType));
            if (mappedTripIdentifier) {
              tripIdentifiers.push(mappedTripIdentifier);
            }
          }
        });

        $scope.tripIdentifierList = tripIdentifiers;
      }],
      template: require('./template.html'),
    };
  },
]);
