angular.module('relayHealth').controller('SelectBroadcastTmplCtrl', [
  '$scope',
  '$uibModalInstance',
  '$http',
  'API_BASE_URL',
  function SelectBroadcastTmplCtrl($scope, $uibModalInstance, $http, API_BASE_URL) {
    $scope.permissionsConstants = relaylib.permissions.permissionsConstants;
    function convertToDM(inData) {
      return {
        id: inData.id,
        title: inData.title,
        message: inData.content,
        category: inData.category,
        targets: inData.targets,
        isTargetWeb: inData.targets && inData.targets.indexOf($scope.broadcastTargets.WEB) >= 0,
        isTargetAndroid: inData.targets && inData.targets.indexOf($scope.broadcastTargets.ANDROID) >= 0,
        isTargetIos: inData.targets && inData.targets.indexOf($scope.broadcastTargets.IOS) >= 0,
        duration: inData.duration,
      };
    }

    function init() {
      $scope.broadcastTargets = relaylib.broadcasts.broadcastTargets;
      $scope.broadcastCats = relaylib.broadcasts.broadcastCategories;
      $scope.dataRefresh = { value: false };
      $scope.dataRefetch = false;
      $scope.isLoading = false;
      $scope.searchTxt = '';
    }

    $scope.fetchData = (paginationParams) => {
      $scope.isLoading = true;

      const params = Object.assign({}, paginationParams);
      if ($scope.searchTxt) {
        params.search = $scope.searchTxt;
      }

      return $http
        .get(`${API_BASE_URL}broadcast_templates`, {
          params,
        })
        .then((resp) => {
          if (resp.data) {
            $scope.broadcastTmpls = _.map(resp.data.data, d => convertToDM(d));
          } else {
            $scope.broadcastTmpls = [];
          }
          return resp.data;
        })
        .finally(() => {
          $scope.isLoading = false;
        });
    };

    $scope.refreshData = () => {
      $scope.dataRefresh.value = true;
    };

    $scope.searchData = (e) => {
      if (!e || e.keyCode === 13) {
        // search data iff, either button pressed or enter pressed
        $scope.dataRefetch = true;
      }
    };

    $scope.removeItem = (id) => {
      if (id) {
        $scope.isLoading = true;
        return $http
          .delete(`${API_BASE_URL}broadcast_templates/${id}`)
          .then((resp) => {
            if (resp.data) {
              $scope.refreshData();
            } else {
              // Do nothing on error
            }
            return resp.data;
          })
          .finally(() => {
            $scope.isLoading = false;
          });
      }
      return false;
    };

    $scope.select = (tmpl) => {
      const tmplSelected = Object.assign({}, tmpl);
      delete tmplSelected.id;
      $uibModalInstance.close({
        tmpl: tmplSelected,
        action: 'select',
      });
    };

    $scope.edit = (tmpl) => {
      const tmplSelected = Object.assign({}, tmpl);
      $uibModalInstance.close({
        tmpl: tmplSelected,
        action: 'edit',
      });
    };

    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };

    init();
  },
]);
