angular
  .module('relayHealth')
  .controller('adjusterListCntrl', [
    '$scope',
    '$rootScope',
    '$http',
    'API_BASE_URL',
    '$state',
    'toaster',
    'DTOptionsBuilder',
    '$uibModal',
    'SweetAlert',
    function adjusterListCntrl($scope, $rootScope, $http, API_BASE_URL, $state, toaster, DTOptionsBuilder, $uibModal, SweetAlert) {
      $scope.dtOptions = DTOptionsBuilder.newOptions()
        .withDOM('<"html5buttons"B>lTfgitp')
        .withButtons([]);

      function getstaffMembers() {
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        const res = $http.get(`${API_BASE_URL}staffmember/${userDetails.division[0].div_id}/all`);
        res.then(({ data }) => {
          if (data.success) {
            $scope.staff_members = data.data;
          } else {
            toaster.pop({
              type: 'error',
              title: data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        });
        res.catch(() => {
          toaster.pop({
            type: 'error',
            title: 'Not able to get staffmember data',
            showCloseButton: true,
            timeout: 10000,
          });
        });
      }
      getstaffMembers();

      $scope.$on('staffAdded', () => {
        // when the staff member added successfully
        getstaffMembers();
      });

      // add the staff member here

      $scope.show_staff_member_form = () => {
        // show the adjuster add form
        const modalInstance = $uibModal.open({
          animation: true,
          template: require('../views/adjuster-add.html'),
          controller: 'adjusterAddCtrl',
          size: 'lg',
          backdrop: 'static',
          resolve: {
            staff_member_detail() {
              return '';
            },
          },
        });
        Object.assign($rootScope, { modal: modalInstance });
      };

      $scope.editStaff_member = (memberId) => {
        // edit the staff member
        // get the data for the particular member
        const res = $http.get(`${API_BASE_URL}staffmember/${memberId}`);
        res.then(({ data }) => {
          if (data.success) {
            const staffMembers = data.data;

            const modalInstance = $uibModal.open({
              animation: true,
              template: require('../views/adjuster-add.html'),
              controller: 'adjusterAddCtrl',
              size: 'lg',
              backdrop: 'static',
              resolve: {
                staff_member_detail() {
                  return angular.copy(staffMembers);
                },
              },
            });
            Object.assign($rootScope, { modal: modalInstance });
          } else {
            toaster.pop({
              type: 'error',
              title: data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        });
        res.catch(() => {
          toaster.pop({
            type: 'error',
            title: 'Not able to update staffmember data',
            showCloseButton: true,
            timeout: 10000,
          });
        });
      };

      function deleteStaffMember(staffMemberId) {
        // delete the staff member
        const res = $http.delete(`${API_BASE_URL}staffmember/delete/${staffMemberId}`);
        res.then(({ data }) => {
          if (data.success) {
            getstaffMembers();
            toaster.pop({
              type: 'success',
              title: data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          } else {
            toaster.pop({
              type: 'error',
              title: data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        });
        res.catch(() => {
          toaster.pop({
            type: 'error',
            title: 'Not able to delete adjuster',
            showCloseButton: true,
            timeout: 10000,
          });
        });
      }

      $scope.delete_staff_member = (id) => {
        // delete the staff member
        SweetAlert.swal(
          {
            title: 'Are you sure you want to delete?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, delete it!',
            closeOnConfirm: true,
            closeOnCancel: true,
          },
          (isConfirm) => {
            if (isConfirm) deleteStaffMember(id);
          },
        );
      };

      $scope.getPatients = (staffMember) => {
        Object.assign($rootScope, { adjuster: staffMember });
        $state.go('main.adjusterPatients', {
          divisionId: staffMember.division_id,
          adjusterId: staffMember.user_id,
          staffMember,
        });
      };
    },
  ])
  .controller('staff_member_patients', [
    '$scope',
    '$rootScope',
    'DTOptionsBuilder',
    '$uibModal',
    '$uibModalInstance',
    'staff_member_patients',
    function staffMemberPatientsCtrl(
      $scope,
      $rootScope,
      DTOptionsBuilder,
      $uibModal,
      $uibModalInstance,
      staffMemberPatients,
    ) {
      $scope.dtOptions = DTOptionsBuilder.newOptions()
        .withDOM('<"html5buttons"B>lTfgitp')
        .withButtons([]);
      $scope.patients = staffMemberPatients;
      $scope.addAppointment = (patient) => {
        $scope.cancel();
        const modalInstance = $uibModal.open({
          animation: true,
          template: require('../views/add_appointment.html'),
          controller: 'addAppointmentCtrl',
          size: 'lg',
          backdrop: 'static',
          resolve: {
            patient() {
              return patient;
            },
          },
        });
        Object.assigm($rootScope, { modal: modalInstance });
      };
      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };
    },
  ]);
