const { permissionsConstants } = relaylib.permissions;
angular.module('relayHealth').controller('globalSettingCtrl', [
  '$scope',
  'SweetAlert',
  '$http',
  'API_BASE_URL',
  'toaster',
  'loadingScreenFactory',
  'AUTO_ASSIGN_SETTINGS',
  'dosOverlap',
  function globalSettingCtrl(
    $scope,
    SweetAlert,
    $http,
    API_BASE_URL,
    toaster,
    loadingScreenFactory,
    AUTO_ASSIGN_SETTINGS,
    dosOverlap,
  ) {
    $scope.validateDos = false;
    $scope.emptyDosValues = false;
    $scope.dosPattern = /^[0-9]\d*$/;
    $scope.addDosExpiration = {};
    let prevDosValue;
    $scope.globalSettings = {};
    $scope.permissionsConstants = permissionsConstants;
    let globalSettingsData;
    const getGlobalSettingsData = async () => {
      try {
        loadingScreenFactory.showLoadingScreen();
        const autoAssignSettingsName = AUTO_ASSIGN_SETTINGS.ALL_AUTO_ASSIGN_SETTINGS;
        const params = { setting_name: autoAssignSettingsName.map(setting => setting.settingName).join(',') };
        globalSettingsData = await $http({
          url: `${API_BASE_URL}setting/default`,
          method: 'GET',
          params,
        });
        $scope.globalSettings = { ...globalSettingsData.data.settings };
        if (!$scope.globalSettings.dos_offer_expiry) {
          $scope.globalSettings.dos_offer_expiry = [];
        } else {
          $scope.globalSettings.dos_offer_expiry = JSON.parse($scope.globalSettings.dos_offer_expiry);
        }
      } catch (err) {
        const message = err.data && err.data.message ? err.data.message : err.message;
        toaster.pop({
          type: 'error',
          title: message,
          showCloseButton: true,
          timeout: 10000,
        });
      } finally {
        loadingScreenFactory.hideLoadingScreen();
        $scope.$apply();
      }
    };

    getGlobalSettingsData();
    $scope.setValidity = (form) => {
      form.$setValidity('valid', true);
      if ($scope.globalSettings.dos_offer_expiry.length === 0 && $scope.globalSettings.dos_expiry_combination) {
        form.$setValidity('valid', false);
      }
    };
    $scope.editDos = (index, form) => {
      if ($scope.disableForm) {
        return;
      }
      if ($scope.addDosExpiration.$$hashKey && prevDosValue) {
        $scope.globalSettings.dos_offer_expiry.push({ ...prevDosValue });
      }
      $scope.addDosExpiration = { ...$scope.globalSettings.dos_offer_expiry[index] };
      prevDosValue = { ...$scope.globalSettings.dos_offer_expiry[index] };
      $scope.onDosConditionChange();
      $scope.globalSettings.dos_offer_expiry.splice(index, 1);
      $scope.setValidity(form);
    };
    $scope.clearDos = (form) => {
      if ($scope.disableForm) {
        return;
      }
      if ($scope.addDosExpiration.$$hashKey) {
        $scope.globalSettings.dos_offer_expiry.push({ ...prevDosValue });
      }
      $scope.addDosExpiration = {};
      $scope.addDosExpiration.expiry = '';
      $scope.addDosExpiration.dos = '';
      $scope.setValidity(form);
      form.$setUntouched();
      form.$setPristine();
      $scope.onDosConditionChange();
    };
    $scope.deleteDos = (index) => {
      if ($scope.disableForm) {
        return;
      }
      if ($scope.globalSettings.dos_offer_expiry.length > 1) {
        $scope.globalSettings.dos_offer_expiry.splice(index, 1);
      }
      $scope.onDosConditionChange();
    };
    $scope.onDosConditionChange = () => {
      $scope.dosPlaceholder = 'e.g. 5';
      $scope.validateDos = false;
      $scope.emptyDosValues = false;
      $scope.dosPattern = /^[0-9]\d*$/;
      if ($scope.addDosExpiration && $scope.addDosExpiration.check === 'between') {
        $scope.dosPlaceholder = 'e.g. 5-10';
        $scope.dosPattern = /^[0-9]+-[0-9]+$/;
      }
    };
    $scope.addDosAndExpiry = (form) => {
      $scope.validateDos = dosOverlap.checkValidity($scope.globalSettings.dos_offer_expiry, $scope.addDosExpiration);
      if ($scope.disableForm || $scope.validateDos) {
        return;
      }

      if (
        !($scope.addDosExpiration.check
        && $scope.addDosExpiration.dos
        && $scope.addDosExpiration.dosUnit
        && $scope.addDosExpiration.expiry
        && $scope.addDosExpiration.expiryUnit
        && $scope.addDosExpiration.topProvidersValue
        )
      ) {
        $scope.emptyDosValues = true;
        return;
      }
      if ($scope.addDosExpiration.check === 'between') {
        const values = $scope.addDosExpiration.dos.split('-');
        if (Number(values[0]) > Number(values[1])) {
          $scope.wrongValues = true;
          return;
        }
      }
      if ($scope.addDosExpiration.$$hashKey) {
        let found;
        $scope.globalSettings.dos_offer_expiry.forEach((dosSetting, index) => {
          if (dosSetting.$$hashKey === $scope.addDosExpiration.$$hashKey) {
            found = true;
            $scope.globalSettings.dos_offer_expiry[index] = { ...$scope.addDosExpiration };
            $scope.addDosExpiration = {};
          }
        });
        if (!found) {
          $scope.globalSettings.dos_offer_expiry.push({ ...$scope.addDosExpiration });
          $scope.addDosExpiration = {};
        }
      } else {
        $scope.globalSettings.dos_offer_expiry.push({ ...$scope.addDosExpiration });
        $scope.addDosExpiration = {};
      }
      $scope.setValidity(form);
      $scope.onDosConditionChange();
    };
    $scope.saveChangesClicked = async () => {
      try {
        loadingScreenFactory.showLoadingScreen();
        Object.keys($scope.globalSettings)
          .forEach((key) => {
            if (!$scope.globalSettings[key] && $scope.globalSettings[key] !== 0 && $scope.globalSettings[key] !== false) {
              $scope.globalSettings[key] = null;
            }
          });
        const saveGlobalSettings = await $http
          .put(`${API_BASE_URL}setting/default`, $scope.globalSettings);
        if (saveGlobalSettings.data.success) {
          SweetAlert.swal(
            {
              title: 'Changes Saved',
              text: 'Global Settings edited successfully',
              type: 'success',
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'OK',
              closeOnConfirm: true,
            },
            (isConfirm) => {
              if (isConfirm) { getGlobalSettingsData(); }
            },
          );
        }
      } catch (err) {
        const message = err.data && err.data.message ? err.data.message : err.message;
        $scope.globalSettings = angular.copy(globalSettingsData.data.settings);
        toaster.pop({
          type: 'error',
          title: message,
          showCloseButton: true,
          timeout: 10000,
        });
      } finally {
        loadingScreenFactory.hideLoadingScreen();
        $scope.$apply();
      }
    };
  }]);
