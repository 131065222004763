angular.module('relayHealth').constant('GOOGLE_PLACES_ATTRIBUTES', [
  'address_component',
  'adr_address',
  'alt_id',
  'formatted_address',
  'geometry',
  'icon',
  'id',
  'name',
  'permanently_closed',
  'photo',
  'place_id',
  'plus_code',
  'scope',
  'type',
  'url',
  'utc_offset_minutes',
  'vicinity',
]);
