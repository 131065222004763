const {
  permissions: { permissionsConstants },
} = relaylib;

angular
  .module('relayHealth')
  .service('oldDashboardCheck',
    [
      'permissionFilter',
      function oldDashboardCheck(permissionFilter) {
        this.check = function check() {
          const userDetails = JSON.parse(localStorage.getItem('userDetails'));
          let settingEnabled = false;
          let showChildData = false;

          if (userDetails && userDetails.organisation && userDetails.organisation.settings && userDetails.organisation.settings.length > 0) {
            const settingIndexInArray = _.find(userDetails.organisation.settings, { setting_name: 'old_dashboard' });
            if (settingIndexInArray && settingIndexInArray.setting_value === 'true') {
              settingEnabled = true;
            }

            // client org feature flag basic parent org redirect to new dashborad
            const findShowChildData = _.find(userDetails.organisation.settings, { setting_name: 'show_child_data' });

            if (findShowChildData && (findShowChildData.setting_value).toLowerCase() === 'true') {
              showChildData = true;
            }
          }


          if (
            permissionFilter(permissionsConstants.CLIENT_DASHBOARD_ACCESS)
                && !settingEnabled
                && userDetails.organisation
          && (userDetails.organisation.childrenCount === 0 || showChildData)
          ) {
            return false;
          }
          return true;
        };
      },
    ]);
