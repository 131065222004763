angular.module('relayHealth').controller('signupTermsConditionsCtrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  '$state',
  '$timeout',
  'signupAuthCheckService',
  'signupInitialStateService',
  'signupStateService',
  function signupTermsConditionsCtrl($scope, $http, API_BASE_URL, $state, $timeout, signupAuthCheckService, signupInitialStateService, signupStateService) {
    $scope.acceptTc = false;
    $scope.acceptTOSResponseError = '';

    $scope.acceptTermsAndConditions = () => {
      const endPoint = 'user/tAndCCheck';
      let userDetails = localStorage.getItem('userDetails');
      userDetails = JSON.parse(userDetails);
      const data = { userId: userDetails.user_id, tAndCCheck: $scope.acceptTc };
      const claimSetting = _.find(userDetails.organisation.settings, { setting_name: 'enabled_claims_management' });
      const headers = {
        username: userDetails.username,
        usertoken: userDetails.usertoken,
        'Content-Type': 'application/json',
      };

      $http
        .put(API_BASE_URL + endPoint, data, headers)
        .then(({ data: responsedata }) => {
          if (responsedata.success) {
            userDetails.t_and_c_check = true;
            localStorage.setItem('userDetails', JSON.stringify(userDetails));
            // if(userDetails.role === 'org_superadmin'
            // && userDetails.organisation.main_contact_id !== userDetails.user_id
            // && userDetails.organisation.billing_method === 'invoice'){
            //     $state.go('main.dashboard.superAdminDashboard', {}, { reload: true });
            // } else if(userDetails.role === 'orgadmin' && userDetails.organisation.billing_method === 'credit card'){
            //     $state.go('main.signup_add_card');
            // } else {
            //     $state.go('main.signup_add_card');
            // }
            const { role } = userDetails;
            const { organisation } = userDetails;
            if (claimSetting && claimSetting.setting_value && claimSetting.setting_value.toLowerCase() === 'true') {
              $state.go('main.dashboard.createAppointment');
            } else if (role === 'orgadmin' && organisation.billing_method === 'credit card') {
              $state.go('main.signup_add_card');
            } else if (role === 'org_superadmin' && organisation.main_contact_id !== userDetails.user_id && organisation.billing_method === 'credit card') {
              $state.go('main.signup_add_card');
            } else if (role === 'tempBusiness' && !organisation.stripe_customer_id) {
              $state.go('main.signup_add_card');
            } else if (role === 'riderAdmin' && organisation.main_contact_id !== userDetails.user_id) {
              $state.go('main.signup_add_card');
            } else {
              $state.go('main.dashboard.superAdminDashboard', {}, { reload: true });
            }
          } else {
            $scope.acceptTOSResponseError = typeof data.errorMessages === 'string' ? data.errorMessages : 'Could not update Terms and Conditions';
            $timeout(() => {
              $scope.acceptTOSResponseError = '';
            }, 5000);
          }
        })
        .catch(({ data: errdata }) => {
          $scope.acceptTOSResponseError = typeof errdata.errorMessages === 'string' ? errdata.errorMessages : 'Could not update Terms and Conditions';
          $timeout(() => {
            $scope.acceptTOSResponseError = '';
          }, 5000);
        });
    };

    function init() {
      $scope.state = signupInitialStateService.initialState();
      signupAuthCheckService.termsConditionsAllowed();
      $scope.$on('$destroy', () => {
        signupStateService.clearState('signupState');
      });
    }

    $scope.checkboxChanged = () => {
      $scope.state.acceptTc = $scope.acceptTc;
      signupStateService.setState('signupState', $scope.state);
    };

    init();
  },
]);
