const { Blob, saveAs } = window;

function addressAuditReportCtrl(
  $scope,
  $http,
  API_BASE_URL,
  toaster,
) {
  $scope.addressReportList = [];
  $scope.pagination = {
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 10,
  };

  async function getLatestProcessedExportRecords() {
    try {
      const url = `${API_BASE_URL}export/getLatestProcessedExportRecords/Address Audit Report?limit=${
        $scope.pagination.itemsPerPage
      }&offset=${
        ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage
      }`;
      const { data: { data: { count, rows }  } } = await $http.get(url);
      $scope.addressReportList = rows;
      $scope.pagination.totalItems = count;
    } catch (err) {
      toaster.pop({
        type: 'error',
        title: err,
        showCloseButton: true,
        timeout: 10000,
      });
    } finally {
      $scope.$apply();
    }
  }

  $scope.getReports = function getReports() {
    getLatestProcessedExportRecords();
  };

  function init() {
    getLatestProcessedExportRecords();
  }
  init();

  $scope.downloadXlsxFile = async (filename) => {
    try {
      const result = await $http({
        url: `${API_BASE_URL}downloadFile/csv/export/address_audit_report/${filename}`,
        method: 'GET',
        data: { filename },
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'arraybuffer',
      });
      if (result && result.data) {
        const blob = new Blob([result.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        if (blob.size < 70) {
          toaster.pop({
            type: 'error',
            title: 'No record found! Please try changing date range!',
            showCloseButton: true,
            timeout: 10000,
          });
        } else {
          saveAs(blob, filename);
        }
      }
    } catch (err) {
      toaster.pop({
        type: 'error',
        title: err,
        showCloseButton: true,
        timeout: 10000,
      });
    } finally {
      $scope.$apply();
    }
  };
}

angular.module('relayHealth').controller('addressAuditReportCtrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  'toaster',
  addressAuditReportCtrl,
]);
