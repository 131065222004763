angular.module('relayHealth').service('signupAuthCheckService', [
  'signupStateService',
  '$state',
  'logoutService',
  'redirectUserHomeIfLoggedInService',
  function signupAuthCheckService(signupStateService, $state, logoutService, redirectUserHomeIfLoggedInService) {
    const { userRoles } = relaylib.common.constants;
    this.verifyOtpAllowed = function verifyOtpAllowed() {
      this.logout();
      const userDetails = signupStateService.getState('userDetails');
      const { role } = userDetails;
      const orgClass = userDetails.organisation.org_class;
      if (userDetails.phone_verified) {
        if (role === 'tempRider') {
          $state.go('main.rider_signup_terms_of_service');
          return false;
        }
        if (role === 'tempBusiness') {
          $state.go('main.business_signup_terms_of_service');
          return false;
        }
        $state.go('main.dashboard.superAdminDashboard');
        return false;
      }

      if ((role === 'orgadmin' || role === 'riderUser') && $state.current.name === 'main.signup_phone_verification') {
        return true;
      }
      if (role === 'tempRider' && orgClass === 'rider' && $state.current.name === 'main.rider_signup_verify_otp') {
        return true;
      }
      if (role === 'tempBusiness' && orgClass === 'business' && $state.current.name === 'main.business_signup_verify_otp') {
        return true;
      }

      if (role === 'orgadmin' && !userDetails.t_and_c_check && $state.current.name === 'main.signup_phone_verification') {
        return true;
      }
      if (role === 'riderUser' && orgClass === 'rider' && $state.current.name === 'main.signup_phone_verification') {
        return true;
      }
      if (
        role === 'org_superadmin'
        && userDetails.organisation.main_contact_id !== userDetails.user_id
        && orgClass === 'business'
        && $state.current.name === 'main.signup_phone_verification'
      ) {
        return true;
      }
      if (
        role === 'tempBusiness'
        && userDetails.organisation.main_contact_id === userDetails.user_id
        && orgClass === 'business'
        && $state.current.name === 'main.business_signup_verify_otp'
      ) {
        return true;
      }
      if (role === 'tempRider' && orgClass === 'rider' && $state.current.name === 'main.rider_signup_verify_otp') {
        return true;
      }
      if (
        role === 'riderAdmin'
        && orgClass === 'rider'
        && $state.current.name === 'main.signup_phone_verification'
        && userDetails.organisation.main_contact_id !== userDetails.user_id
      ) {
        return true;
      }
      if (role === userRoles.PROVIDER_MANAGER) {
        return $state.go('main.userMgmt.providerList');
      }
      return redirectUserHomeIfLoggedInService();
    };

    this.termsConditionsAllowed = function termsConditionsAllowed() {
      this.logout();
      const userDetails = signupStateService.getState('userDetails');
      const { role } = userDetails;
      const orgClass = userDetails.organisation.org_class;
      if ((role === 'orgadmin' || role === 'riderUser') && !userDetails.t_and_c_check && $state.current.name === 'main.signup_terms_of_service') {
        return true;
      }
      if (role === 'tempRider' && orgClass === 'rider' && $state.current.name === 'main.rider_signup_terms_of_service') {
        return true;
      }
      if (role === 'tempBusiness' && orgClass === 'business' && $state.current.name === 'main.business_signup_terms_of_service') {
        return true;
      }
      if (
        role === 'org_superadmin'
        && orgClass === 'business'
        && userDetails.organisation.main_contact_id !== userDetails.user_id
        && !userDetails.t_and_c_check
      ) {
        return true;
      }
      if (
        role === 'riderAdmin'
        && orgClass === 'rider'
        && $state.current.name === 'main.signup_terms_of_service'
        && userDetails.organisation.main_contact_id !== userDetails.user_id
      ) {
        return true;
      }
      $state.go('main.dashboard.superAdminDashboard');
      return false;
    };

    this.accountInfoAllowed = function accountInfoAllowed() {
      this.logout();
      const userDetails = signupStateService.getState('userDetails');
      const { role } = userDetails;
      const orgClass = userDetails.organisation.org_class;
      if (role === 'tempRider' && orgClass === 'rider' && $state.current.name === 'main.rider_signup_account_information') {
        return true;
      }
      $state.go('main.dashboard.superAdminDashboard');
      return false;
    };

    this.organizationDetailsAllowed = function organizationDetailsAllowed() {
      this.logout();
      const userDetails = signupStateService.getState('userDetails');
      const { role } = userDetails;
      const orgClass = userDetails.organisation.org_class;
      if (role === 'tempBusiness' && orgClass === 'business' && $state.current.name === 'main.business_signup_add_organization') {
        return true;
      }
      $state.go('main.dashboard.superAdminDashboard');
      return false;
    };

    this.addUsersAllowed = function addUsersAllowed() {
      this.logout();
      const userDetails = signupStateService.getState('userDetails');
      const { role } = userDetails;
      const orgClass = userDetails.organisation.org_class;
      if (role === 'tempBusiness' && orgClass === 'business' && $state.current.name === 'main.business_signup_add_users') {
        return true;
      }
      if (role === 'tempRider' && orgClass === 'rider' && $state.current.name === 'main.rider_signup_add_users') {
        return true;
      }
      $state.go('main.dashboard.superAdminDashboard');
      return false;
    };

    this.addPaymentAllowed = function addPaymentAllowed() {
      this.logout();
      const userDetails = signupStateService.getState('userDetails');
      const { role } = userDetails;
      const orgClass = userDetails.organisation.org_class;
      if (role === 'riderUser' && (!userDetails.stripe_customer_id && !userDetails.skip_payment) && $state.current.name === 'main.signup_add_card') {
        return true;
        // TODO add check to see if the monthlyInvoice is not true for the orgadmin
      }
      if (role === 'orgadmin' && (!userDetails.monthlyInvoice || !userDetails.stripe_customer_id) && $state.current.name === 'main.signup_add_card') {
        return true;
      }
      if (role === 'tempRider' && $state.current.name === 'main.rider_signup_payment') {
        return true;
      }
      if (role === 'tempBusiness' && $state.current.name === 'main.business_signup_payment') {
        return true;
      }
      if (role === 'tempBusiness' && $state.current.name === 'main.signup_add_card') {
        return true;
      }
      if (
        role === 'org_superadmin'
        && $state.current.name === 'main.signup_add_card'
        && !userDetails.stripe_customer_id
        && userDetails.organisation.main_contact_id !== userDetails.user_id
      ) {
        return true;
      }
      if (
        role === 'riderAdmin'
        && orgClass === 'rider'
        && $state.current.name === 'main.signup_add_card'
        && userDetails.organisation.main_contact_id !== userDetails.user_id
      ) {
        return true;
      }
      $state.go('main.dashboard.superAdminDashboard');
      return false;
    };

    this.summaryAllowed = function summaryAllowed() {
      this.logout();
      const userDetails = signupStateService.getState('userDetails');
      const { role } = userDetails;
      const orgClass = userDetails.organisation.org_class;
      if (role === 'tempBusiness' && orgClass === 'business' && $state.current.name === 'main.business_signup_summary') {
        return true;
      }
      if (role === 'tempRider' && orgClass === 'rider' && $state.current.name === 'main.rider_signup_summary') {
        return true;
      }
      $state.go('main.dashboard.superAdminDashboard');
      return false;
    };

    this.thankYouAllowed = function thankYouAllowed() {
      const userDetails = signupStateService.getState('userDetails');
      const { role } = userDetails;
      const orgClass = userDetails.organisation.org_class;
      if (role === 'org_superadmin' && orgClass === 'business' && $state.current.name === 'main.business_signup_summary') {
        return true;
      }
      if (role === 'riderAdmin' && orgClass === 'rider' && $state.current.name === 'main.rider_signup_summary') {
        return true;
      }
      $state.go('main.dashboard.superAdminDashboard');
      return false;
    };

    this.logout = function logout() {
      const signupState = signupStateService.getState('signupState');
      if (parseInt(signupState.latestStage, 10) > 7) {
        logoutService();
      }
    };
  },
]);
