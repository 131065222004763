import _ from 'lodash';

export default class NotificationsDataConverter {
    constructor() {
        // Do nothing
    }

    static convertAppointmentDataToSend(appointment) {
        let apptToReturn = null;
        if (appointment) {
            apptToReturn = _.pick(appointment, [
                "id",
                "recurrence_description",
                "appointment_start_date_time",
                "appt_timezone",
                "organisation.customizable_file_number",
                "organisation.file_number_label",
                "organisation.market_segment",
                "organisation.org_class",
                "organisation.name",
                "organisation.show_poc",
                "organisation.org_type"
            ]);
            apptToReturn.ride_details = _.map(appointment.ride_details,
                data => _.pick(data, [
                    "id",
                    "source_address",
                    "ride_status",
                    "ride_category",
                    "dest_address",
                    "leg_type"
                ])
            );
        }
        return apptToReturn;
    }

    static convertProvidersDataToSend(providersUser) {
        return providersUser && _.pick(providersUser, ["id", "is_bandwidth", "provider_id"]);
    }

    static convertRideRequesterDataToSend(rideReq) {
        return rideReq && _.pick(rideReq, ["id", "first_name", "last_name", "phone"]);
    }

    static convertRideDataToSend(rideData) {
        let rideDataToReturn = null;
        rideDataToReturn = _.pick(rideData, [
            "id",
            "ride_status",
            "driver_eta",
            "ride_category",
            "source_lat_long",
            "dest_lat_long",
            "file_no_suffix",
            "leg_type",
            "display_status",
            "patient",
            "file_no",
            "ride_start_date_time",
            "ride_end_date_time",
            "googleMapImageIosWebTile",
            "provider_id",
            "book_now",
            "trip_time_type",
            "main_contact_phone",
            "text",
            "route_lat_long",
            "managed_by",
            "org_id",
            "comments",
            "variance_type",
            "higher_price_confirmation_url",
            "late_arrival_flag",
            "auto_rebook_tries",
            "leg_no",
            "driver_phone",
            "cta",
            "car_color_code",
            "book_cab_time",
            "actual_dest",
            "estimations",
            "driver_name",
            "provider_driver_rating",
            "provider_car_make",
            "provider_car_model",
            "licence_plate",
            "car_color",
            "ride_cost",
            "appt_date_time",
            "appt_timezone",
            "user",
            "appointment_type",
            "facility_name",
            "is_read",
            "special_users_first_name",
            "special_users_last_name",
            "special_users_phone",
            "is_pr_ride"
        ]);
        rideDataToReturn.appointment = this.convertAppointmentDataToSend(rideData.appointment);
        rideDataToReturn.providers_user = this.convertProvidersDataToSend(rideData.providers_user);
        rideDataToReturn.ride_requester = this.convertRideRequesterDataToSend(rideData.ride_requester);
        return rideDataToReturn;
    }
}
