angular.module('relayHealth').controller('specialRideCtrl', [
  '$scope',
  '$rootScope',
  '$http',
  'API_BASE_URL',
  'rideDetails',
  '$uibModalInstance',
  'pubnubNotificationFactory',
  'SweetAlert',
  'toaster',
  'loadingScreenFactory',
  function specialRideCtrl(
    $scope,
    $rootScope,
    $http,
    API_BASE_URL,
    rideDetails,
    $uibModalInstance,
    pubnubNotificationFactory,
    SweetAlert,
    toaster,
    loadingScreenFactory,
  ) {
    loadingScreenFactory.hideLoadingScreen();

    $scope.moment = moment;

    $scope.rideDetails = rideDetails;

    $scope.closePopup = () => {
      pubnubNotificationFactory.unsubscribeChannel(`appt${$scope.rideDetails.appointment.id}`);
      pubnubNotificationFactory.unsubscribeChannel(`appt_track${$scope.rideDetails.appointment.id}`);
      $uibModalInstance.dismiss('cancel');
    };

    $rootScope.$on('$destroy', () => {
      $scope.closePopup();
    });

    function refreshActiveRideTiles() {
      $rootScope.$broadcast('refreshActiveRideTiles');
    }

    function refreshIssuesTab() {
      $rootScope.$broadcast('refreshIssuesTab');
    }

    $scope.clearAlert = () => {
      SweetAlert.swal(
        {
          title: 'Are you sure?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes',
          closeOnConfirm: false,
        },
        (isConfirm) => {
          if (isConfirm) {
            const ctaUrl = `${API_BASE_URL}ride/updateSpecialRide/${$scope.rideDetails.id}`;
            const params = {
              ride_category: 'special_ride_completed',
              manually_resolved: true,
              action: 'Alert Cleared',
            };
            const clearAlertCall = $http.put(ctaUrl, params);
            clearAlertCall.then(
              (result) => {
                if (result.data.success) {
                  SweetAlert.swal({
                    title: 'Issue Addressed!',
                    text: 'Issue Addressed Successfully',
                    type: 'success',
                  });
                  refreshIssuesTab();
                  refreshActiveRideTiles();
                  $scope.closePopup();
                }
              },
              (err) => {
                toaster.pop({
                  type: 'error',
                  title: err.data.message,
                  showCloseButton: true,
                  timeout: 10000,
                });
              },
            );
          }
        },
      );
    };
  },
]);
