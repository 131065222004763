import moment from 'moment';
import Ladda from 'ladda';
import app from '../../app';
import claimLoaderTemplate from './claim-loader.html';

app
  .controller('createAppointmentCtrl', [
    '$scope',
    '$http',
    'Pubnub',
    'pubnubNotificationFactory',
    'API_BASE_URL',
    'toaster',
    'SweetAlert',
    '$stateParams',
    '$timeout',
    'DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID',
    'loadingScreenFactory',
    '$state',
    '$filter',
    'ngDialog',
    '$rootScope',
    'OCCM_LAT_LONG',
    '$uibModal',
    'googleService',
    'RECURRENCE_WEEKLY_MAX_INTERVALS',
    'RECURRENCE_MONTHLY_MAX_INTERVALS',
    'RECURRENCE_LIMIT',
    'oldDashboardCheck',
    'SHOW_RECURRENCE',
    'GOOGLE_PLACES_ATTRIBUTES',
    'orgHierarchyFactory',
    function createAppointmentCtrl(
      $scope,
      $http,
      Pubnub,
      pubnubNotificationFactory,
      API_BASE_URL,
      toaster,
      SweetAlert,
      $stateParams,
      $timeout,
      DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID,
      loadingScreenFactory,
      $state,
      $filter,
      ngDialog,
      $rootScope,
      OCCM_LAT_LONG,
      $uibModal,
      googleService,
      RECURRENCE_WEEKLY_MAX_INTERVALS,
      RECURRENCE_MONTHLY_MAX_INTERVALS,
      RECURRENCE_LIMIT,
      oldDashboardCheck,
      SHOW_RECURRENCE,
      GOOGLE_PLACES_ATTRIBUTES,
      orgHierarchyFactory,
    ) {
      window.apptScope = $scope; // for debugging
      $scope.moment = moment;
      $scope.permissionsConstants = relaylib.permissions.permissionsConstants;
      $scope.tripTypeConstants = relaylib.common.constants.tripTypeConstants;
      const { ride: { status: rideStatuses }, common: { constants: { userRoles } } } = relaylib;
      $scope.googlePlacesAttributes = GOOGLE_PLACES_ATTRIBUTES;
      $scope.isScheduleTypeActive = false;
      $scope.isBookNowTypeActive = false;
      $scope.lookingForClaim = false;
      $scope.disableUpdateTrip = false;
      $scope.isPayerSettingActive = false;
      $scope.areMultipleBillingOptions = false;
      $scope.claimNumberHasError = false;
      $scope.isClaimNumberChanged = false;
      $scope.allowRecurrence = false;
      $scope.mask = '(999) 999-9999';
      $scope.pickupAddfocus = [];
      $scope.destAddfocus = [];
      $scope.destAddNameFocus = [];
      // Billing option default values
      $scope.payerNameDropdown = false;
      $scope.billingDetails = {
        payerName: '',
        selectedBillingOption: 'account_lookup',
        tooltipMessage: '',
      };
      $scope.monthlyWeeks = [
        { value: 1, name: 'First' },
        { value: 2, name: 'Second' },
        { value: 3, name: 'Third' },
        { value: 4, name: 'Fourth' },
        { value: 5, name: 'Fifth' },
      ];
      $scope.showClaimDetailsOf = {
        future_lookup: false,
        claim_lookup: false,
      };

      $scope.billingOptionsDefault = {
        account_lookup: {
          display_name: 'My Account',
          value: 'account_lookup',
          tooltip_message: 'My Account- Select this method to bill the ride to your Organization.',
          payerNameDropdown: false,
          fieldsToBeValidatedOnRiderForm: [
            'appointmentType',
            'phoneNo',
            'firstName',
            'lastName',
            'refrenceNoClientOrg',
            'refrenceNo',
            'suffix',
          ],
          fieldsToBeValidatedOnTripForm: [
            'radioTripTimeType',
            'sourceAddress',
            'destinationAddress',
          ],
        },
        claim_lookup: {
          display_name: 'Claim',
          value: 'claim_lookup',
          tooltip_message:
            'Claim- Select this method to bill the ride to another Organization.'
            + ' Claim Number and Payer Name are required at the time of ride request to select this option.',
          payerNameDropdown: true,
          fieldsToBeValidatedOnRiderForm: [
            'appointmentType',
            'payerName',
            'claimNumber',
            'phoneNo',
            'firstName',
            'lastName',
            'adjusterHomeAddress',
            'refrenceNoClientOrg',
            'refrenceNo',
            'suffix',
          ],
          fieldsToBeValidatedOnTripForm: [
            'radioTripTimeType',
            'sourceAddress',
            'destinationAddress',
            'destinationAddressName',
          ],
        },
        future_lookup: {
          display_name: 'Future Claim',
          value: 'future_lookup',
          tooltip_message:
            'Future Claim- Select this method to bill the ride to another Organization.'
            + ' Date of birth and date of injury are required at time of ride request to select this option.',
          payerNameDropdown: true,
          fieldsToBeValidatedOnRiderForm: [
            'appointmentType',
            'payerName',
            'date-of-injury',
            'date-of-birth',
            'phoneNo',
            'firstName',
            'lastName',
            'adjusterHomeAddress',
            'refrenceNoClientOrg',
            'refrenceNo',
            'suffix',
          ],
          fieldsToBeValidatedOnTripForm: [
            'radioTripTimeType',
            'sourceAddress',
            'destinationAddress',
            'destinationAddressName',
          ],
        },
      };
      $scope.activatedBillingOptions = [];
      const ignoreWarningType = {
        address: false,
        mileage: false,
      };
      this.now = moment();


      const datePickerToggleState = {
        '#date-of-injury': false,
        '#date-of-birth': false,
      };

      this.dateOptions = {
        singleDatePicker: true,
        parentEl: '#current-app #page-wrapper',
        locale: {
          format: 'MM-DD-YYYY',
        },
        showDropdowns: true,
        eventHandlers: {
          'showCalendar.daterangepicker': ({ picker: { element: [{ id }] } }) => {
            datePickerToggleState[`#${id}`] = true;
          },
          'hideCalendar.daterangepicker': ({ picker: { element: [{ id }] } }) => {
            datePickerToggleState[`#${id}`] = false;
          },
        },
      };

      this.toggleDatePicker = (elementName) => {
        if ($(elementName).hasClass('error-border')) {
          return;
        }
        if (datePickerToggleState[elementName]) {
          datePickerToggleState[elementName] = false;
          $timeout(() => {
            $(elementName).data('daterangepicker').hide();
          });
        } else {
          datePickerToggleState[elementName] = true;
          $timeout(() => {
            $(elementName).data('daterangepicker').show();
          });
        }
      };

      const billingOptionConstants = {
        accountLookup: 'account_lookup',
        claimLookup: 'claim_lookup',
        futureLookup: 'future_lookup',
      };

      const allowedInvitedRiderEntitiesAndFields = {
        patient: {
          [billingOptionConstants.accountLookup]: ['first_name', 'last_name', 'phone'],
          [billingOptionConstants.claimLookup]: ['first_name', 'last_name', 'phone'],
          [billingOptionConstants.futureLookup]: ['first_name', 'last_name', 'phone'],
        },
        claimInformation: {
          [billingOptionConstants.claimLookup]: [
            'date_of_injury',
            'adjuster_first_name',
            'adjuster_last_name',
            'adjuster_email_address',
            'claim_state',
            'adjuster_employer_name',
            'injury_state',
            'adjuster_home_address',
            'claim_number',
          ],
          [billingOptionConstants.futureLookup]: [
            'date_of_injury',
            'date_of_birth',
          ],
        },
        billingDetails: {
          [billingOptionConstants.accountLookup]: ['selectedBillingOption'],
          [billingOptionConstants.claimLookup]: ['selectedPayerName', 'selectedBillingOption'],
          [billingOptionConstants.futureLookup]: ['selectedPayerName', 'selectedBillingOption'],
        },
      };

      $scope.backButtonClicked = () => {
        $scope.showRiderDetails = true;
      };

      let map;
      let selectedProduct;
      const pickupData = {
        type: 'source',
      };
      const destinationData = {
        type: 'destination',
      };
      if (oldDashboardCheck.check()) {
        $scope.oldDashboard = true;
      } else {
        $scope.oldDashboard = false;
      }
      let currentPosition;
      let focusedLeg;
      let focusedBlockIndex;
      let initialRideDetails;
      let initialPayerName;
      $scope.isClaimNumberDetails = false;
      const WatcherRemovers = [];

      const TimerRemovers = [];

      if ($stateParams.recurrenceAction) {
        $scope.recurrenceAction = $stateParams.recurrenceAction;
      }

      $scope.showClaimInformation = false;
      $scope.claimInformation = {
        date_of_injury: null,
        leg_pick_up_type: [],
        leg_destination_type: [],
        destination_address_name: [],
      };
      $scope.claimInfoFlags = {
        claimNotFoundByClaimUid: false,
        editMode: false,
      };

      $scope.claimOptions = relaylib.claimLookup.claimOptions;
      $scope.claimInformation.claim_appointment_type = $scope.claimOptions.appointment_types_default;
      $scope.override_cost_token = false;
      $scope.selectedDate = { date: '' };

      pubnubNotificationFactory.setUUID();
      pubnubNotificationFactory.subscribeUserIdChannel();

      $scope.overlaySetting = {
        showIfSave: false,
        showTagForm: false,
        tagName: '',
      };
      $scope.enableFields = {
        tripTimeType: true,
        time: true,
        removeLeg: true,
        legTypeSingle: true,
        legTypeRound: true,
        legTypeMulti: true,
        common: true,
      };
      let currentDayRideEditableFields = [];
      const prepareMonthsData = function prepareMonthsData() {
        const months = [
          { month: 'January' },
          { month: 'February' },
          { month: 'March' },
          { month: 'April' },
          { month: 'May' },
          { month: 'June' },
          { month: 'July' },
          { month: 'August' },
          { month: 'September' },
          { month: 'October' },
          { month: 'November' },
          { month: 'December' },
        ];
        let currentYear = moment().format('YYYY');
        const currentMonth = moment().format('MMMM');
        const indexOfCurrentMonth = months.findIndex(element => element.month === currentMonth);
        const returnData = [];
        let value = 0;
        if (indexOfCurrentMonth > -1) {
          for (let i = indexOfCurrentMonth; i < months.length; i += 1) {
            value += 1;
            returnData.push({
              month: months[i].month,
              year: currentYear,
              status: 'active',
              value,
            });
          }
          currentYear = (parseInt(currentYear, 10) + 1).toString();
          for (let i = 0; i < indexOfCurrentMonth; i += 1) {
            value += 1;
            returnData.push({
              month: months[i].month,
              year: currentYear,
              status: 'active',
              value,
            });
          }
          return returnData;
        }
        return [];
      };

      const getMonthIndex = monthName => $scope.months.findIndex(element => element.month === monthName);

      function getMonthlyWeekday(weekNumber, day, month, year) {
        let targetDay; let curDay = 0; let date = 1; let
          seekDay;
        switch (day) {
          case 'Sunday':
            seekDay = 0;
            break;
          case 'Monday':
            seekDay = 1;
            break;
          case 'Tuesday':
            seekDay = 2;
            break;
          case 'Wednesday':
            seekDay = 3;
            break;
          case 'Thursday':
            seekDay = 4;
            break;
          case 'Friday':
            seekDay = 5;
            break;
          case 'Saturday':
            seekDay = 6;
            break;
          default:
        }
        while (curDay < weekNumber && date <= 31) {
          date += 1;
          targetDay = moment().date(date).month(month).year(year);
          if (targetDay.day() === seekDay) {
            curDay += 1;
          }
        }
        if (curDay === weekNumber) {
          targetDay = moment(targetDay);
          return targetDay;
        }
        return false;
      }

      const monthlyWeekDayInRange = function monthlyWeekDayInRange(start, end, weekNumber, day) {
        const startMonth = start.clone().month();
        const endMonth = end.clone().month() + ((end.clone().year() - start.clone().year()) * 12);
        let dayInRange = false;
        for (let i = startMonth; i <= endMonth; i += 1) {
          const currentMonth = moment().month(i).format('MMMM');
          const currentYear = moment().year();
          const result = getMonthlyWeekday(weekNumber, day, currentMonth, currentYear);
          if (result && result.isBetween(start, end)) {
            dayInRange = true;
            break;
          }
        }
        return dayInRange;
      };

      const checkMonthlyweekDateInRange = function checkMonthlyweekDateInRange() {
        if ($scope.isMonthlyDate.value === 'true') {
          $scope.monthlyDaysInRange = true;
        } else {
          const { rideDetails: { recurringData: { endBy, startBy, endMonth } } } = $scope;
          const dateInRangeCheck = $scope.selectedMonthlyDay && $scope.selectedMonthlyDay.name && endMonth && endMonth.month
            && startBy && endBy && $scope.monthlyDayOccurenceNumber && $scope.monthlyDayOccurenceNumber.value;
          if (dateInRangeCheck) {
            $scope.monthlyDaysInRange = monthlyWeekDayInRange(startBy, endBy, $scope.monthlyDayOccurenceNumber.value, $scope.selectedMonthlyDay.name);
          } else {
            $scope.monthlyDaysInRange = true;
          }
        }
      };

      $scope.months = prepareMonthsData();

      $scope.endMonthChanged = function endMonthChanged(selectedDate) {
        if ($scope.rideDetails.recurringData.pattern !== 'monthly') {
          return;
        }
        const { endMonth } = $scope.rideDetails.recurringData;
        if (_.isEmpty(endMonth)) {
          $scope.rideDetails.recurringData.endBy = '';
          return;
        }
        if ($scope.isMonthlyDate.value === 'true') {
          if (selectedDate) {
            $scope.rideDetails.recurringData.endBy = moment(
              `${endMonth.year}-${
                endMonth.month
              }-${selectedDate}`,
              'YYYY-MMMM-DD',
            );
          }
        } else {
          $scope.rideDetails.recurringData.endBy = moment().year(endMonth.year).month(endMonth.month).endOf('month');
        }
        $scope.monthlyIntervalChanged();
        checkMonthlyweekDateInRange();
      };

      const getMonthlyInterval = function getMonthlyInterval() {
        if ($scope.isMonthlyDate.value === 'true') {
          return $scope.monthlyDateInterval.value;
        }
        return $scope.monthlyDayInterval.value;
      };

      const setMonthlyInterval = function setMonthlyInterval(interval) {
        if ($scope.isMonthlyDate.value === 'true') {
          $scope.monthlyDateInterval = Object.assign($scope.monthlyDateInterval, { value: interval });
        } else {
          $scope.monthlyDayInterval = Object.assign($scope.monthlyDayInterval, { value: interval });
        }
        $scope.rideDetails.recurringData.monthlyInterval = interval;
      };

      const setMaxMonthlyInterval = function setMaxMonthlyInterval(interval) {
        if ($scope.isMonthlyDate.value === 'true') {
          $scope.maxMonthlyDateInterval = interval;
        } else {
          $scope.maxMonthlyDayInterval = interval;
        }
      };

      const checkmonthlyWeekDaysValidity = function checkmonthlyWeekDaysValidity() {
        const isInValid = $scope.rideDetails.recurringData.pattern === 'monthly' && $scope.isMonthlyDate && $scope.isMonthlyDate.value === 'false'
          && _.isEmpty($scope.selectedMonthlyDay);
        if (isInValid) {
          $scope.appointmentForm.week_days.$setValidity('required', false);
        } else {
          $scope.appointmentForm.week_days.$setValidity('required', true);
        }
      };

      const checkMonthlyweekValidity = function checkMonthlyweekValidity() {
        const isInValid = $scope.rideDetails.recurringData.pattern === 'monthly' && $scope.isMonthlyDate && $scope.isMonthlyDate.value === 'false'
          && _.isEmpty($scope.monthlyDayOccurenceNumber);
        if (isInValid) {
          $scope.appointmentForm.days_occurence_number.$setValidity('required', false);
        } else {
          $scope.appointmentForm.days_occurence_number.$setValidity('required', true);
        }
      };

      $scope.monthlyIntervalChanged = function monthlyIntervalChanged() {
        const interval = getMonthlyInterval();
        const startDate = moment($scope.rideDetails.recurringData.startBy);
        const endDate = moment($scope.rideDetails.recurringData.endBy);
        const startYear = startDate.year();
        const endYear = endDate.year();
        const startMonth = startDate.month();
        const endMonth = endDate.month();
        let monthsDifference = 0;
        if (startYear === endYear) {
          monthsDifference = endMonth - startMonth;
        } else if (endYear === startYear + 1) {
          monthsDifference = (endMonth + 12) - startMonth;
        }
        if (!interval) {
          setMonthlyInterval(1);
          setMaxMonthlyInterval(monthsDifference);
          return;
        }
        if (monthsDifference >= 1 && monthsDifference < interval) {
          setMonthlyInterval(monthsDifference);
        } else if (monthsDifference >= 1) {
          setMonthlyInterval(interval);
        }
        if (!monthsDifference || monthsDifference <= 0) {
          setMonthlyInterval(1);
          setMaxMonthlyInterval(1);
          return;
        }
        setMaxMonthlyInterval(monthsDifference);
      };

      $scope.updateMonthlyType = function updateMonthlyType(isMonthlyDate) {
        $scope.isMonthlyDate = { value: isMonthlyDate };
        if (isMonthlyDate === 'true') {
          $scope.monthlyDateChanged($scope.selectedDate.date);
        } else {
          $scope.appointmentForm.month_date.$setValidity('max', true);
          $scope.appointmentForm.month_date.$setValidity('min', true);
          $scope.monthlyDateChanged(moment().date());
        }
        checkmonthlyWeekDaysValidity();
        checkMonthlyweekValidity();
        $scope.rideDetails.recurringData.days = (_.isEmpty($scope.selectedMonthlyDay)) ? [] : [$scope.selectedMonthlyDay.name];
        $scope.rideDetails.recurringData.daysOccurenceNumber = $scope.monthlyDayOccurenceNumber.value;
        checkMonthlyweekDateInRange();
      };

      $scope.monthlyDayOccurenceNumberChanged = function monthlyDayOccurenceNumberChanged(number) {
        if (!number) {
          $scope.monthlyDayOccurenceNumber = {};
          checkMonthlyweekValidity();
          return;
        }
        $scope.appointmentForm.days_occurence_number.$setValidity('required', true);
        if ($scope.monthlyWeeks[number - 1]) {
          $scope.monthlyDayOccurenceNumber = { value: number, name: $scope.monthlyWeeks[number - 1].name };
        } else {
          $scope.monthlyDayOccurenceNumber = { value: 1, name: 'First' };
        }
        $scope.rideDetails.recurringData.daysOccurenceNumber = number;
        checkMonthlyweekDateInRange();
      };

      function arrayObjectIndexOf(myArray, searchTerm, property) {
        for (let i = 0, len = myArray.length; i < len; i += 1) {
          if (myArray[i][property] === searchTerm) {
            return i;
          }
        }
        return -1;
      }

      let getEstimatesButtonHit = false;

      // Default value to prevent no valid payment method error
      $scope.paymentMethods = null;

      const tripTimeTypeLabelAndComments = {
        arrival: {
          value: 'arrival',
          label: 'Arrival Time',
          comment: 'This time tells us when you want to arrive to your destination.'
            + ' Relay will calculate the route and dispatch a driver to ensure you arrive at the selected time.',
        },
        pickup: {
          value: 'pickup',
          label: 'Pickup Time',
          comment:
            'This is the time the Relay driver will come and pick you up at your starting location.',
        },
      };

      $scope.availableTripTimeType = [];

      let centerMarker;
      let rideId;
      const leg = {
        short_source: '',
        short_dest: '',
        source_address: '',
        dest_address: '',
        source_lat_long: [],
        dest_lat_long: [],
        note_to_driver: '',
      };
      const loggedInUserDetails = JSON.parse(localStorage.getItem('userDetails'));
      $scope.loggedInUserOrgId = loggedInUserDetails.organisation.id;
      $scope.loggedInUserOrgLevel = loggedInUserDetails.organisation.org_level;
      $scope.userDetails = loggedInUserDetails;
      $scope.occm_id = DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID;
      let firstTimeLoad = true;
      $scope.ifFirstLeg = true;
      $scope.showGetEstimates = true;
      $scope.pageTitle = angular.isDefined($stateParams.caller)
        && $stateParams.caller === 'Book now'
        ? 'Request A Ride'
        : 'Schedule A Trip';
      $scope.activeTab = [false, true];
      $scope.language = {};
      $scope.patientInvoiceHistoryBtn = 'Save';

      // Variables to manage active users parallely viewing this ride
      $scope.activeUsersOnThisAppointment = {};
      $scope.countOfActiveUsersOnThisAppointment = 0;

      let savedAnswer;

      $scope.days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ];
      $scope.monthlyDays = [
        { name: 'Sunday', value: 'Sunday' },
        { name: 'Monday', value: 'Monday' },
        { name: 'Tuesday', value: 'Tuesday' },
        { name: 'Wednesday', value: 'Wednesday' },
        { name: 'Thursday', value: 'Thursday' },
        { name: 'Friday', value: 'Friday' },
        { name: 'Saturday', value: 'Saturday' },
      ];
      $scope.recurrenceWaitDialog = null;
      $scope.recurrenceWaitDialogEdit = null;
      $scope.recurrencePollingProcess = null;
      $scope.recurrencePollingProcessEdit = null;
      $scope.recurrenceWaitID = null;
      $scope.recurrenceWaitIDEdit = null;
      $scope.maxWeeklyInterval = RECURRENCE_WEEKLY_MAX_INTERVALS;
      $scope.maxMonthlyDateInterval = RECURRENCE_MONTHLY_MAX_INTERVALS;
      $scope.maxMonthlyDayInterval = RECURRENCE_MONTHLY_MAX_INTERVALS;
      $scope.recurrenceRangeError = {
        startBy: {
          status: false,
          message: null,
        },
        endBy: {
          status: false,
          message: null,
        },
      };

      $scope.rangeAltered = false;
      $scope.startByDisabled = false;
      $scope.daysInRange = true;

      $scope.monthsWeekDayChanged = function monthsWeekDayChanged(day) {
        if (day) {
          $scope.rideDetails.recurringData.days = [day];
          $scope.selectedMonthlyDay = { name: day, value: day };
          checkMonthlyweekDateInRange();
        } else {
          $scope.rideDetails.recurringData.days = [];
          $scope.selectedMonthlyDay = {};
        }
        checkmonthlyWeekDaysValidity();
      };

      const makeAllMonthsActive = function makeAllMonthsActive() {
        for (let i = 0; i < $scope.months.length; i += 1) {
          $scope.months[i].status = 'active';
        }
      };

      const leapYearCheck = year => (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

      const makeMonthsInactive = function makeMonthsInactive(selectedDate, endMonth) {
        const febIndex = getMonthIndex('February');
        const febDate = $scope.months[febIndex];
        if (selectedDate === 29) {
          if (leapYearCheck(febDate.year)) {
            febDate.status = 'active';
          } else {
            febDate.status = 'inactive';
            if (endMonth.month === 'February') {
              $scope.rideDetails.recurringData.endMonth = {};
              $scope.rideDetails.recurringData.endBy = '';
            }
          }
        } else if (selectedDate === 30) {
          febDate.status = 'inactive';
          if (endMonth.month === 'February') {
            $scope.rideDetails.recurringData.endMonth = {};
            $scope.rideDetails.recurringData.endBy = '';
          }
        } else if (selectedDate === 31) {
          const inactiveMonths = [
            'February',
            'April',
            'June',
            'September',
            'November',
          ];
          if (inactiveMonths.includes(endMonth.month)) {
            $scope.rideDetails.recurringData.endMonth = {};
            $scope.rideDetails.recurringData.endBy = '';
          }
          for (let i = 0; i < $scope.months.length; i += 1) {
            if (inactiveMonths.includes($scope.months[i].month)) {
              $scope.months[i].status = 'inactive';
            }
          }
        } else {
          //
        }
      };

      $scope.monthlyDateChanged = function monthlyDateChanged(selectedDate) {
        if ($scope.rideDetails.recurringData.pattern !== 'monthly') {
          return;
        }
        if (!selectedDate) {
          $scope.rideDetails.recurringData.startBy = '';
          $scope.rideDetails.recurringData.endBy = '';
          return;
        }
        const { rideDetails: { recurringData: { endMonth } } } = $scope;
        const currentMonthIndex = getMonthIndex(moment().format('MMMM'));
        if (selectedDate < moment().date()) {
          if (selectedDate > 28 && moment().add(1, 'month').format('MMMM') === 'February' && !leapYearCheck(moment().year())) {
            $scope.rideDetails.recurringData.startBy = moment().add(2, 'month').date(selectedDate);
          } else {
            $scope.rideDetails.recurringData.startBy = moment().add(1, 'month').date(selectedDate);
          }
          $scope.months[currentMonthIndex].status = 'inactive';
        } else {
          if (moment().daysInMonth() < selectedDate) {
            $scope.rideDetails.recurringData.startBy = moment().add(1, 'month').date(selectedDate);
          } else {
            $scope.rideDetails.recurringData.startBy = moment().date(selectedDate);
          }
          if ($scope.months[currentMonthIndex].status === 'inactive') {
            $scope.months[currentMonthIndex].status = 'active';
          }
        }
        if (!_.isEmpty(endMonth) && _.isEmpty($scope.rideDetails.recurringData.endBy)) {
          $scope.endMonthChanged(selectedDate);
        } else if (_.isEmpty(endMonth) || _.isEmpty($scope.rideDetails.recurringData.endBy)) {
          makeAllMonthsActive();
          makeMonthsInactive(selectedDate, {});
          return;
        }
        const endDate = angular.copy($scope.rideDetails.recurringData.endBy);
        const startDate = angular.copy($scope.rideDetails.recurringData.startBy);
        let endingMonth = endDate.month();
        const startMonth = startDate.month();
        const endYear = endDate.year();
        const startYear = startDate.year();
        if (startYear < endYear) {
          endingMonth += 12;
        }
        if (endingMonth <= startMonth) {
          const endMonthIndex = getMonthIndex(endDate.format('MMMM'));
          $scope.rideDetails.recurringData.endMonth = $scope.months[endMonthIndex];
          $scope.rideDetails.recurringData.endBy = $scope.rideDetails.recurringData.startBy;
        }
        makeAllMonthsActive();
        makeMonthsInactive(selectedDate, endMonth);
        $scope.monthlyIntervalChanged();
        $scope.endMonthChanged(selectedDate);
      };

      $scope.daysInRangeCall = function daysInRangeCall() {
        const {
          recurringData: {
            days, pattern, startBy, endBy,
          } = {
            days: [],
            pattern: '',
            endBy: new Date(),
          },
        } = $scope.rideDetails;
        const ifRecurringDataDays = days && days.length > 0;
        const ifRecurringDataPatternWeekly = pattern === 'weekly';
        if (ifRecurringDataDays && ifRecurringDataPatternWeekly && startBy && endBy) {
          const m = moment(
            $scope.startByDisabled
              ? new Date().getTime()
              : startBy,
          );
          let dayCount = 1;
          while (
            m.diff(endBy, 'days') <= 0
            && dayCount <= 7
          ) {
            if (
              days.includes(m.format('dddd'))
            ) {
              $scope.daysInRange = true;
              return;
            }
            m.add(1, 'days');

            dayCount += 1;
          }
          $scope.daysInRange = false;
        } else {
          $scope.daysInRange = true;
        }
      };

      $rootScope.$on(
        Pubnub.getMessageEventNameFor($scope.userDetails.user_id),
        (event, payload) => {
          const { message } = payload;

          // use $scope.recurrenceWaitDialog here
          if (message.type === 'recurrence-rides') {
            const parsedMessage = JSON.parse(message.message);
            if (
              parsedMessage.action === 'add'
              && parsedMessage.recurrence_id === $scope.recurrenceWaitID
            ) {
              if ($scope.recurrenceWaitDialog && $scope.recurrenceWaitDialog.id) {
                $scope.recurrenceWaitDialog.close(parsedMessage);
              }
            } else if (
              parsedMessage.action === 'update'
              && parsedMessage.recurrence_id === $scope.recurrenceWaitIDEdit
            ) {
              if (
                $scope.recurrenceWaitDialogEdit
                && $scope.recurrenceWaitDialogEdit.id
              ) {
                $scope.recurrenceWaitDialogEdit.close(parsedMessage);
              }
            } else {
              // nothing
            }
          }
        },
      );

      // Helper function to check and update list/count of users parallely active on a ride
      let checkAndUpdateCurrentlyViewingAdminsRunning = false;
      const checkAndUpdateCurrentlyViewingAdmins = function checkAndUpdateCurrentlyViewingAdmins() {
        if (checkAndUpdateCurrentlyViewingAdminsRunning) {
          // Check to prevent parallel calling attempts to this function
          return;
        }
        checkAndUpdateCurrentlyViewingAdminsRunning = true;
        pubnubNotificationFactory.getHereNowOfChannel(
          `presence_${$scope.rideId}`,
          (status, response) => {
            const activeUsers = {};
            let activeUsersCount = 0;

            if (
              response
              && response.channels
              && response.channels[`presence_${$scope.rideId}`]
            ) {
              const occupants = response.channels[`presence_${$scope.rideId}`].occupants || [];
              occupants.forEach((o) => {
                if (o.state && typeof activeUsers[o.state.id] === 'undefined') {
                  activeUsers[o.state.id] = o.state;
                  activeUsersCount += 1;
                }
              });
            }
            // Reset flag....so that next attempt to check active users can run successfully.}
            checkAndUpdateCurrentlyViewingAdminsRunning = false;

            $scope.$apply(() => {
              $scope.activeUsersOnThisAppointment = activeUsers;
              $scope.countOfActiveUsersOnThisAppointment = activeUsersCount;
            });
          },
        );
      };

      // Presence Listener for handling presence events coming directly from Pubnub service
      const presenceListeners = {
        presence(p) {
          if (
            !(
              p.channel === `presence_${$scope.rideId}`
              && ['state-change', 'leave', 'timeout'].indexOf(p.action) >= 0
            )
          ) {
            // If presence event is not for currently active ride or it's not for one of the mentioned states, ignore it.
            return;
          }
          // Put some delay before fetching here now details...
          setTimeout(() => {
            checkAndUpdateCurrentlyViewingAdmins();
          }, 1000);
        },
        message(p) {
          if (p.channel !== `presence_${$scope.rideId}`) {
            // Skip all messages who do not belongs to our presence channel
            return;
          }

          const payload = p.message;

          toaster.pop({
            type: 'info',
            title: payload.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      };

      // UI helper function for showing/hiding active users list
      $scope.toggleActiveUsers = function toggleActiveUsers() {
        $scope.showActiveUsers = !$scope.showActiveUsers;
      };

      // setting the page title and breadcrumb models.
      if ($stateParams.caller === 'AR') {
        $scope.pageTitle = 'Request A Ride';
        $scope.breadcrumbForActiveRide = 1;
      }
      if ($stateParams.caller === 'dashboard') {
        $scope.pageTitle = 'Request A Ride';
      }

      const initializeTaxiProducts = function initializeTaxiProducts() {
        $scope.taxiProducts = {
          basic: [],
          premium: [],
        };
      };

      const initializePatient = function initializePatient() {
        $scope.patient = {
          phone: '',
          first_name: '',
          last_name: '',
        };
      };

      const initializeAppointment = function initializeAppointment() {
        $scope.rideDetails = {
          patient_id: '',
          org_id: loggedInUserDetails.organisation.id,
          org_level: loggedInUserDetails.organisation.org_level,
          created_by_patient: 0,
          appt_date_time: 0,
          provider_prod_id: '',
          provider_id: 1,
          date: moment(),
          time: new Date(1970, 1, 1, 0, 0),
          recurringTime: new Date(1970, 1, 1, 0, 0),
          file_no: '',
          leg_type: 'single',
          legs: [
            {
              short_source: '',
              short_dest: '',
              source_address: '',
              dest_address: '',
              source_lat_long: [],
              dest_lat_long: [],
              note_to_driver: '',
              notes_to_patient: '',
            },
          ],
          recurringData: {
            description: '',
            time: 0,
            pattern: 'daily',
            days: angular.copy($scope.days),
            interval: null,
            startBy: moment(),
            endBy: '',
            daysOccurenceNumber: 0,
          },
        };

        if ($scope.availableTripTimeType.length < 2) {
          $scope.rideDetails.trip_time_type = 'arrival';
        }
        if ($scope.rideDetails.leg_type === 'round') {
          $scope.rideDetails.legs.push({
            short_source: '',
            short_dest: '',
            source_address: '',
            dest_address: '',
            source_lat_long: [],
            dest_lat_long: [],
            note_to_driver: '',
          });
          $scope.rideDetails.legs.length = 2;
        }
      };

      const getOrgSettings = function getOrgSettings(orgId, settings = []) {
        let requestUrl = `${API_BASE_URL}organisation/${orgId}/settings`;
        if (settings.length) {
          requestUrl += `/${settings.join(',')}`;
        }
        return $http
          .get(requestUrl, {})
          .then(response => response.data.settings)
          .catch(() => ({}));
      };

      const hideResetBillingDependencies = function hideResetBillingDependencies() {
        // Hide and Reset Payer Drop Down
        $scope.payerNameDropdown = false;
        $scope.isPayerSettingActive = false;
        $scope.billingDetails.selectedBillingOption = 'account_lookup';

        $scope.billingDetails.selectedPayerName = '';

        // Hide claim information - will hide container of future and claim lookup.
        $scope.showClaimInformation = false;

        // Hidde claim information for specific lookup values as well
        $scope.showClaimDetailsOf = {
          future_lookup: false,
          claim_lookup: false,
        };
        $scope.claimNumberHasError = false;
        $scope.isClaimNumberDetails = false;
      };

      let fetchingOrgTripTimeTypeSettingsFor = '';

      const setCurrentDayEditRideFields = (fields) => {
        if (!fields.includes('trip_time_type')) {
          $scope.enableFields.tripTimeType = false;
        }
        if (!fields.includes('time')) {
          $scope.enableFields.time = false;
        }
        if (fields.includes('leg_type')) {
          if ($scope.rideDetails.leg_type !== 'round') {
            $scope.enableFields.legTypeRound = false;
            $scope.enableFields.legTypeSingle = false;
          }
          $scope.enableFields.legTypeMulti = false;
        }
        if (!fields.includes('remove_leg')) {
          $scope.enableFields.removeLeg = false;
        }
        if (!fields.includes('common')) {
          $scope.enableFields.common = false;
        }
      };
      $scope.fileNumberRequired = false;
      const getOrgTripTimeTypeSettings = function getOrgTripTimeTypeSettings(
        orgId = $scope.rideDetails.org_id, fetchSetting = [],
      ) {
        if (fetchingOrgTripTimeTypeSettingsFor === orgId) {
          return;
        }

        fetchingOrgTripTimeTypeSettingsFor = orgId;
        $scope.availableTripTimeType.splice(0);
        let orgSettingList = ['trip_time_type', 'trip_type', 'account_lookup', 'claim_lookup', 'future_lookup',
          'payer', 'allow_recurrence', 'file_number_required', 'file_number_format', 'invited_riders'];
        if (fetchSetting.length) {
          orgSettingList = orgSettingList.concat(fetchSetting);
        }
        getOrgSettings(
          orgId,
          orgSettingList,
        ).then((settings) => {
          hideResetBillingDependencies(); // resetting billing dependencies
          const tripTimeType = settings.trip_time_type
            ? JSON.parse(settings.trip_time_type)
            : [];

          const tripType = settings.trip_type !== undefined
            ? Number(settings.trip_type)
            : $scope.tripTypeConstants.BOTH_ACTIVE;

          if (settings.current_day_ride_editable_fields) {
            currentDayRideEditableFields = JSON.parse(settings.current_day_ride_editable_fields);
          }

          if (currentDayRideEditableFields.length) {
            setCurrentDayEditRideFields(currentDayRideEditableFields);
          }
          // duplicate value assign for this scope variable empty
          if (tripTimeType.length) {
            $scope.availableTripTimeType.splice(0);
          }
          tripTimeType.forEach((t) => {
            if (tripTimeTypeLabelAndComments[t]) {
              $scope.availableTripTimeType.push(tripTimeTypeLabelAndComments[t]);
            }
          });

          $scope.activatedBillingOptions = [];
          $scope.billingDetails.tooltipMessage = '';

          $scope.allowRecurrence = SHOW_RECURRENCE && settings.allow_recurrence === 'true';
          if ($scope.allowRecurrence && $scope.rideId) {
            if (!$scope.recurrenceAction || $scope.recurrenceAction === 'single') {
              $scope.allowRecurrence = false;
            }
          }

          Object.values($scope.billingOptionsDefault).forEach((v) => {
            if (settings[v.value] && settings[v.value] === 'true') {
              $scope.activatedBillingOptions.push(v);
              $scope.billingDetails.tooltipMessage += `\n \n${$scope.billingOptionsDefault[v.value].tooltip_message}`;
            }
          });

          if (
            !$scope.activatedBillingOptions.length
            || ($scope.activatedBillingOptions.length === 1
              && $scope.activatedBillingOptions[0].value === 'account_lookup'
            )
          ) {
            // if only account lookup is selected then no need to show billing drop down
            $scope.areMultipleBillingOptions = false;
          } else {
            if ($scope.activatedBillingOptions.length === 1) {
              // if there is only one option activate show it as selected.
              $scope.billingDetails.selectedBillingOption = $scope.activatedBillingOptions[0].value;
            }
            $scope.areMultipleBillingOptions = true;
          }

          if (!$scope.billingDetails.selectedBillingOption || $scope.billingDetails.selectedBillingOption === '' || settings.account_lookup === 'true') {
            $scope.billingDetails.selectedBillingOption = 'account_lookup';
          }

          if ((settings.future_lookup || settings.claim_lookup) && settings.account_lookup !== 'true') {
            $scope.billingDetails.selectedBillingOption = settings.claim_lookup === 'true' ? 'claim_lookup' : 'future_lookup';
          }

          if (settings.payer === 'true' && settings.payer_id) {
            $scope.billingDetails.selectedPayerName = settings.payer_id;
            initialPayerName = angular.copy(settings.payer_id);

            $scope.isPayerSettingActive = settings.payer;
            if ($scope.billingDetails.selectedBillingOption !== 'account_lookup') {
              $scope.payerNameSelected();
            }
          }

          fetchingOrgTripTimeTypeSettingsFor = '';

          if ($scope.availableTripTimeType.length === 1) {
            $scope.rideDetails.trip_time_type = $scope.availableTripTimeType[0].value;
          } else if (!$stateParams.rideId && !$stateParams.bookAnotherId) {
            $scope.rideDetails.trip_time_type = '';
          }
          $scope.rideDetails.trip_type = tripType;
          $scope.tripTypeManager();

          const haveAppointmentData = !!$scope.rideDetails.appointment;
          if (haveAppointmentData && $scope.rideDetails.appointment.billing_type) {
            $scope.billingDetails.selectedBillingOption = $scope.rideDetails.appointment.billing_type;
          }

          if (haveAppointmentData && $scope.rideDetails.appointment.dynamite_claim && $scope.rideDetails.appointment.dynamite_claim.payer_id) {
            $scope.billingDetails.selectedPayerName = $scope.rideDetails.appointment.dynamite_claim.payer_id;
            initialPayerName = angular.copy($scope.rideDetails.appointment.dynamite_claim.payer_id);
            $scope.payerNameSelected(); // if payer name is off but we are editing a scheduled ride, and payer id is already exist.
            $scope.isClaimNumberDetails = !!$scope.rideDetails.appointment.dynamite_claim.claim_number;
          }

          $scope.payerHandler($scope.billingDetails);

          $scope.fileNumberRequired = false;
          if (settings.file_number_required && (settings.file_number_required).toLowerCase() === 'true') {
            $scope.fileNumberRequired = true;
          }
          if (settings.file_number_format && (settings.file_number_format).toLowerCase() === 'true') { // especially in case of USPS organisation
            $scope.fileNumberFormat = true;
          }

          if (settings.invited_riders
            && (settings.invited_riders).toLowerCase() === 'true'
            && ![userRoles.RIDE_MANAGER, userRoles.SCHEDULING_ADMIN, userRoles.NETWORK_OPERATION].includes(loggedInUserDetails.role)
          ) {
            $scope.showInviteOnlyOption = true;
          } else {
            $scope.showInviteOnlyOption = false;
          }
        });
      };

      const getAllOrganizations = function getAllOrganizations() {
        const requestUrl = `${API_BASE_URL}organisation/getOrganizations/active`;
        const params = {
          orgId: loggedInUserDetails.organisation.id,
          removeParentOrganisations: true,
          marketSegment: true,
          displayOrgChildren: $scope.displayOrgChildren,
        };
        if (loggedInUserDetails.organisation.is_third_party && [userRoles.ORG_SUPERADMIN, userRoles.ORG_ADMIN].includes(loggedInUserDetails.role)) {
          $scope.showDelegatingOrgs = true;
          params.getDelegatingOrgs = true;
        }
        return $http
          .get(requestUrl, {
            params,
          })
          .then((response) => {
            $scope.organisations = response.data.org_details;
            if (
              arrayObjectIndexOf(
                $scope.organisations,
                loggedInUserDetails.organisation.id,
                'id',
              ) === -1
            ) {
              $scope.rideDetails.org_id = $scope.organisations[0].id;
              getOrgTripTimeTypeSettings($scope.rideDetails.org_id);
            }
            return true;
          })
          .catch(() => {
            // log error here
          });
      };

      const getAllPaymentMethods = function getAllPaymentMethods(orgId) {
        const requestUrl = `${API_BASE_URL}getValidPaymentMethods`;
        return $http
          .get(requestUrl, {
            params: {
              orgId: orgId || loggedInUserDetails.organisation.id,
              bookAnotherId: $stateParams.bookAnotherId,
            },
          })
          .then((response) => {
            $scope.paymentMethods = response.data.data;
          })
          .catch(() => {
            // log error
          });
      };

      $scope.appointmentTimeChange = function appointmentTimeChange() {
        initializeTaxiProducts();
        if ($scope.appointmentTime.time === 'BOOK_NOW') {
          $scope.rideDetails.time = new Date(1970, 1, 1, 0, 0);
        } else if ($scope.rideDetails.legs[0].appt_date_time) {
          const objMoment = moment(parseInt($scope.rideDetails.legs[0].appt_date_time, 10) * 1000)
            .tz($scope.rideDetails.appt_timezone);
          $scope.rideDetails.time = new Date(
            1970,
            1,
            1,
            objMoment.hours(),
            objMoment.minutes(),
          );
        }
      };

      const initQuestionnaireForm = function initQuestionnaireForm() {
        $scope.schema = {
          type: 'object',
          properties: {},
          required: [],
        };
        $scope.form = [];
        $scope.model = {};
        $scope.options = {};
      };

      const prepareQuestions = function prepareQuestions(questionsFromApi) {
        const questions = [];
        const dateFieldQuestion = [];
        for (let index = 0; index < questionsFromApi.length; index += 1) {
          const question = {
            id: questionsFromApi[index].id,
            text: questionsFromApi[index].question_text,
            type: questionsFromApi[index].question_type,
            options: [],
            answerId: questionsFromApi[index].answerId,
          };
          if (questionsFromApi[index].optionsStr) {
            question.options = JSON.parse(questionsFromApi[index].optionsStr);
          }
          if (questionsFromApi[index].question_type !== 'date') {
            questions.push(question);
          } else {
            dateFieldQuestion.push(question);
          }
        }
        $scope.totalQuestion = questions.length;
        $scope.questions = [...questions, ...dateFieldQuestion];
      };

      const createSchemaAndForm = function createSchemaAndForm() {
        $scope.dateFieldData = [];
        $scope.showQuestion = false;
        for (let index = 0; index < $scope.questions.length; index += 1) {
          if ($scope.questions[index].type !== 'date') {
            $scope.showQuestion = true;
            $scope.schema.properties[`question${index}`] = {
              type: 'string',
            };

            $scope.schema.required.push(`question${index}`);
            const formComponent = {
              key: `question${index}`,
              title: `${parseInt(index, 10) + 1}. ${$scope.questions[index].text}`,
            };

            if ($scope.questions[index].type !== 'text') {
              if ($scope.questions[index].type === 'select') {
                formComponent.type = 'select';
              } else if ($scope.questions[index].type === 'radio') {
                formComponent.type = 'radios';
              } else {
                formComponent.type = 'checkboxes';
                $scope.schema.properties[`question${index}`] = {};
                $scope.schema.properties[`question${index}`].title = $scope.questions[index].text;
                $scope.schema.properties[`question${index}`].type = 'array';
                $scope.schema.properties[`question${index}`].items = {
                  type: 'string',
                  enum: [],
                };
              }

              const titleMap = [];
              const titleMapRecord = {};

              for (
                let optionIndex = 0;
                optionIndex < $scope.questions[index].options.length;
                optionIndex += 1
              ) {
                if ($scope.questions[index].options[optionIndex].text) {
                  titleMapRecord.value = $scope.questions[index].options[optionIndex].text;
                  titleMapRecord.name = $scope.questions[index].options[optionIndex].text;
                  titleMap.push(angular.copy(titleMapRecord));
                  // handle checkbox's options in array
                  if ($scope.questions[index].type === 'checkbox') {
                    $scope.schema.properties[`question${index}`].items.enum.push(
                      $scope.questions[index].options[optionIndex].text,
                    );
                  }
                }
              }
              formComponent.titleMap = angular.copy(titleMap);
            }

            $scope.form.push(angular.copy(formComponent));
          } else {
            $scope.dateFieldData.push({ name: $scope.questions[index].text });
          }
        }
        $scope.$broadcast('schemaFormRedraw');
      };

      const getAnswersWithQuestionsForAppt = function getAnswersWithQuestionsForAppt(
        apptId,
      ) {
        const getAnswersWithQuestionsForApptCall = $http.get(
          `${API_BASE_URL}organisation/getAnswersWithQuestions/${apptId}`,
        );
        getAnswersWithQuestionsForApptCall.then((result) => {
          if (result.data.success && result.data.answers.length) {
            if ($stateParams.bookAnotherId) {
              $scope.activeTab[0] = false;
              $scope.activeTab[1] = true;
            } else {
              $scope.activeTab[0] = true;
              $scope.activeTab[1] = false;
            }
            initQuestionnaireForm();
            const questionsFromApi = [];
            $scope.activeQuestionnaire = {
              id: result.data.answers[0].questionnaire_id,
              version: result.data.answers[0].question.questionnaire.version,
            };
            savedAnswer = result.data.answers;

            for (let index = 0; index < result.data.answers.length; index += 1) {
              Object.assign(result.data.answers[index].question, { answerId: result.data.answers[index].id });
              questionsFromApi.push(result.data.answers[index].question);

              if (
                result.data.answers[index].question.question_type === 'checkbox'
              ) {
                $scope.model[`question${index}`] = JSON.parse(result.data.answers[
                  index
                ].answer);
              } else if (result.data.answers[index].question.question_type === 'date' && result.data.answers[index].answer) {
                $scope.model[`question${index}`] = moment(result.data.answers[index].answer, 'MM-DD-YYYY');
              } else {
                $scope.model[`question${index}`] = result.data.answers[index].answer;
              }
            }
            prepareQuestions(questionsFromApi);
            createSchemaAndForm();
          }
        });
      };

      function processAnswer(answers) {
        initQuestionnaireForm();
        const questionsFromApi = [];
        if (answers && answers.length) {
          $scope.activeQuestionnaire = {
            id: answers[0].questionnaire_id,
            version: answers[0].question.questionnaire.version,
          };

          for (let index = 0; index < answers.length; index += 1) {
            Object.assign(answers[index].question, { answerId: answers[index].id });
            questionsFromApi.push(answers[index].question);
            if (answers[index].question.question_type === 'checkbox') {
              $scope.model[`question${index}`] = JSON.parse(answers[index].answer);
            } else {
              $scope.model[`question${index}`] = answers[index].answer;
            }
          }
          prepareQuestions(questionsFromApi);
          createSchemaAndForm();
        }
      }

      const setBounds = function setBounds() {
        const bounds = new google.maps.LatLngBounds();
        if (
          pickupData.marker
          && typeof pickupData.marker.setMap === 'function'
          && destinationData.marker
          && typeof destinationData.marker.setMap === 'function'
        ) {
          bounds.extend(pickupData.latLng);
          bounds.extend(destinationData.latLng);
          map.fitBounds(bounds);
          map.panToBounds(bounds);
          return;
        }

        if (
          pickupData.marker
          && typeof pickupData.marker.setMap === 'function'
          && (!destinationData.marker
            || typeof destinationData.marker.setMap !== 'function')
        ) {
          map.setCenter(pickupData.latLng);
          map.setZoom(16);
          return;
        }

        if (
          (!pickupData.marker
            || typeof pickupData.marker.setMap !== 'function')
          && (destinationData.marker
            && typeof destinationData.marker.setMap === 'function')
        ) {
          map.setCenter(destinationData.latLng);
          map.setZoom(16);
        }
      };

      const setMarker = function setMarker(
        latitude,
        longitude,
        placeName,
        obj,
        index,
      ) {
        Object.assign(obj, { latLng: new google.maps.LatLng(latitude, longitude) });
        if (
          obj.marker
          && typeof obj.marker.setMap === 'function'
          && !obj.viaPin
        ) {
          obj.marker.setMap(null);
        }

        let contentString;
        let icon;
        let label;
        if (obj.type === 'source') {
          contentString = `
          <div>
            <h4>Pickup Location</h4>
            ${placeName}
          <div>`;
          icon = 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png';
          label = index ? `${parseInt(index, 10) + 1}` : '';
        } else {
          contentString = `
            <div>
              <h4>Destination Location</h4>
              ${placeName}
            </div>`;
          icon = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';
          label = index ? `${parseInt(index, 10) + 1}` : '';
        }

        const infowindow = new google.maps.InfoWindow({
          content: contentString,
        });

        if (!obj.viaPin) {
          Object.assign(obj, {
            marker: new google.maps.Marker({
              position: obj.latLng,
              map,
              label,
              icon,
              animation: google.maps.Animation.DROP,
              draggable: true,
            }),
          });
        }

        if (obj.clickListener) {
          google.maps.event.removeListener(obj.clickListener);
          Object.assign(obj, { clickListener: undefined });
        }
        if (obj.dragEndListener) {
          google.maps.event.removeListener(obj.dragEndListener);
          Object.assign(obj, { dragEndListener: undefined });
        }
        Object.assign(obj, {
          clickListener: obj.marker.addListener('click', () => {
            infowindow.open(map, obj.marker);
          }),
        });
        Object.assign(obj, {
          dragEndListener: obj.marker.addListener('dragend', () => {
            Object.assign(obj, { viaPin: true });// obj.viaPin = true;
            Object.assign(obj, { viaTag: false });// obj.viaTag = false;
            const position = [
              obj.marker.getPosition().lat(),
              obj.marker.getPosition().lng(),
            ];
            $scope.setLocationOnMap(
              obj.type,
              index,
              position,
              obj.viaPin,
              obj.viaTag,
            );
          }),
        });
        setBounds();
      };

      // API call to get feature settings
      function getUserOrgSettings(orgId, settingName) {
        let requestUrl = `${API_BASE_URL}setting/feature-settings/${orgId}`;
        if (settingName) {
          requestUrl += `/${settingName}`;
        }
        return $http
          .get(requestUrl, {})
          .then(response => response.data.setting);
      }

      // function to get feature setting of DISABLE_FUTURE_RIDE_UPDATE value
      // If true compare the ride date with current date
      // If ride date is of future for mas org rides than disable the update and cancel button
      async function checkIfFutureRideUpdateDisable(response) {
        if (response.rideDetails) {
          const settings = await getUserOrgSettings(response.rideDetails.org_id, 'disable_future_ride_update');
          if (settings && settings.disable_future_ride_update) {
            const rideDate = moment(response.rideDetails.datetime_in_tz).format('YYYY-MM-DD 00:00:00');
            let currentDate = moment().tz(response.rideDetails.appt_timezone).format('YYYY-MM-DD 00:00:00');
            currentDate = moment(currentDate);
            if (moment(rideDate).diff(currentDate, 'days') > 0) {
              $scope.disableUpdateTrip = true;
              toaster.pop({
                type: 'error',
                title: 'Alert',
                body: 'You cannot edit future ride for MAS org.',
                showCloseButton: true,
                bodyOutputType: 'trustedHtml',
              });
            }
          }
        }
      }

      const showDataBasedOnSelectedBillingOption = function showDataBasedOnSelectedBillingOption() {
        const billingOption = $scope.billingDetails.selectedBillingOption;
        // Hidde claim information for specific lookup values as well
        const showClaimDetailsOf = {
          future_lookup: false,
          claim_lookup: false,
        };

        // checking if billing option is future or cliam lookup
        if (showClaimDetailsOf[billingOption] === false) {
          showClaimDetailsOf[billingOption] = true;
          $scope.showClaimInformation = true;
          // activate payer dropdown
        }
        $scope.showClaimDetailsOf = showClaimDetailsOf;
        $scope.payerNameDropdown = $scope.billingOptionsDefault[billingOption].payerNameDropdown;
      };

      const getRideDetailsByRideId = function getRideDetailsByRideId() {
        let url;
        if ($stateParams.rideId) {
          ({ rideId } = $stateParams);
          url = `${API_BASE_URL}ride/getRideDetailsById/${rideId}`;
        } else if ($stateParams.bookAnotherId) {
          rideId = $stateParams.bookAnotherId;
          url = `${API_BASE_URL}ride/getRideDetailsForBookAnother/${rideId}`;
        }
        const request = $http.get(url, {
          params: {
            org_id: loggedInUserDetails.organisation.id,
          },
        });
        loadingScreenFactory.showLoadingScreen();
        request.then(({ data: response }) => {
          // function invoked to check the future date rides for mas org
          checkIfFutureRideUpdateDisable(response);
          if ($stateParams.bookAnotherId && response.rideDetails.leg_no !== 1) {
            $scope.ifFirstLeg = false;
          }
          loadingScreenFactory.hideLoadingScreen();
          const { rideDetails } = response;
          if ($stateParams.isFromDashboard) {
            $scope.rideTabDisabled = true;
          }
          if (!rideDetails.appointment.sent_by_fuse) {
            getAnswersWithQuestionsForAppt(rideDetails.appt_id);
          }
          if (rideDetails.appointment.organisation.customizable_file_number) {
            $scope.fileNumberLabel = rideDetails.appointment.organisation.file_number_label;
          }

          $scope.ride_invoice_history = rideDetails.appointment.organisation.ride_invoice_history;

          selectedProduct = {
            provider_id: rideDetails.provider_id,
            product_name: rideDetails.product_name,
          };

          if ($stateParams.bookAnotherId) {
            rideDetails.previousRideId = rideDetails.id;
            rideDetails.created_by_patient = 0;
          }
          // We need rideId in scope for constructing presence channel name
          $scope.rideId = rideDetails.id;
          delete rideDetails.id;
          delete rideDetails.createdAt;
          delete rideDetails.updatedAt;

          const ifPatientLanguage = rideDetails
            && rideDetails.appointment
            && rideDetails.appointment.patient
            && rideDetails.appointment.patient.language;

          if (ifPatientLanguage && $scope.language.languages) {
            angular.forEach(
              $scope.language.languages,
              (languageNumber, languageText) => {
                if (languageNumber === rideDetails.appointment.patient.language) {
                  $scope.language.languageSelected = languageText;
                }
              },
            );
          }

          for (let index = 0; index < rideDetails.legs.length; index += 1) {
            const jsonSourceAddress = _.get(rideDetails.legs[index], 'ride_additional_detail.s_json');
            const jsonDestinationAddress = _.get(rideDetails.legs[index], 'ride_additional_detail.d_json');
            if (jsonSourceAddress && jsonSourceAddress.zip) {
              Object.assign(rideDetails.legs[index], {
                sourceAddressPincode: jsonSourceAddress.zip,
              });
            }
            if (jsonDestinationAddress && jsonDestinationAddress.zip) {
              Object.assign(rideDetails.legs[index], {
                destAddressPincode: jsonDestinationAddress.zip,
              });
            }
          }

          $scope.rideDetails = rideDetails;
          const legsExistForTrip = $scope.rideDetails && $scope.rideDetails.legs && $scope.rideDetails.legs.length;
          $scope.tripConditions = {
            scheduledTrip: legsExistForTrip && $scope.rideDetails.legs[0].ride_category === rideStatuses.CONFIRMED.type,
            firstLegIsPotentiallyUnavailable: legsExistForTrip && $scope.rideDetails.legs[0].ride_status === rideStatuses.POTENTIAL_UNAVAILABILITY.status,
          };
          const rideDate = moment(rideDetails.datetime_in_tz).format('YYYY-MM-DD 00:00:00');
          let currentDate = moment().tz(rideDetails.appt_timezone).format('YYYY-MM-DD 00:00:00');
          currentDate = moment(currentDate);
          const fetchSettings = [];
          if (moment(rideDate).diff(currentDate, 'days') === 0) {
            fetchSettings.push('current_day_ride_editable_fields');
          }
          getOrgTripTimeTypeSettings(rideDetails.org_id, fetchSettings);

          if (firstTimeLoad) {
            initialRideDetails = rideDetails;
          }

          // Adding pubnub presence listeners
          Pubnub.removeListener(presenceListeners);
          Pubnub.addListener(presenceListeners);

          // Subscribing to presence channel for current ride
          pubnubNotificationFactory.subscribePresenceChannel(
            `presence_${$scope.rideId}`,
            {
              name: `${$scope.userDetails.first_name} ${
                $scope.userDetails.last_name
              }`,
              id: $scope.userDetails.user_id,
            },
          );

          // Manually fetching details of active users for the first time... later on it will be handled via pubnub notifications
          // Put some delay before fetching here now details...
          setTimeout(() => {
            checkAndUpdateCurrentlyViewingAdmins();
          }, 1000);

          if (!$stateParams.bookAnotherId) {
            if ($scope.rideDetails.legs[0].book_now) {
              $scope.appointmentTime.time = 'BOOK_NOW';
            } else if (
              $scope.rideDetails.appointment
              && $scope.rideDetails.appointment.recurrence_id
              && $scope.recurrenceAction === 'series'
            ) {
              $scope.appointmentTime.time = 'RECURRENCE_TRIP';
            } else {
              $scope.appointmentTime.time = 'FUTURE_APPOINTMENT';
            }
          }

          if ($stateParams.rideId && rideDetails.provider_product) {
            // Copy the provider product object so that the original copy is retained
            const providerProduct = angular.copy(rideDetails.provider_product);

            if (rideDetails.ride_category === 'active') {
              // If we are editing an active ride, display this leg as an active ride in estimation table and the breakdown details.
              providerProduct.display_name += ' (Active Ride)';
              providerProduct.breakdown[0].display_name = 'Active Ride';
            } else {
              // If we are editing any ride which is not yet active (might be in issue category),
              // display this leg as a selected ride in estimation table and show the leg number in breakdown table.
              providerProduct.display_name += ' (Selected Ride)';
            }

            // Using timeout because watch enabled on org_id otherwise reset the taxiProducts variable
            TimerRemovers.push(
              $timeout(() => {
                const taxiProducts = {
                  basic: [],
                  premium: [],
                };
                // push the updated provider product object as the default estimation for the currently opened ride leg
                taxiProducts.basic.push(providerProduct);
                $scope.taxiProducts = taxiProducts;

                const timerRemover = $timeout(() => {
                  $('#current-app').animate(
                    {
                      scrollTop: $('#current-app #wrapper').height(),
                    },
                    1000,
                  );
                }, 800);
                timerRemover.then(() => {
                  $timeout.cancel(timerRemover);
                });
              }, 800),
            );
          }
          if ($stateParams.bookAnotherId) {
            $scope.rideDetails.provider_prod_id = '';
          }

          $scope.patient = rideDetails.patient;

          $scope.getPatientInvoiceHistoryWithSettings();

          if ($stateParams.variance_type) {
            $scope.rideDetails.variance_type = $stateParams.variance_type;
          }

          const objMoment = moment(parseInt($scope.rideDetails.legs[0].appt_date_time, 10) * 1000)
            .tz($scope.rideDetails.appt_timezone);
          $scope.rideDetails.date = moment(objMoment);
          $scope.rideDetails.time = new Date(
            1970,
            1,
            1,
            objMoment.hours(),
            objMoment.minutes(),
          );
          $scope.bookButtonText = 'Update Trip';
          $scope.cancelButtonText = 'Cancel Trip';
          if ($scope.rideDetails.legs.length) {
            $scope.claimInformation.leg_pick_up_type = [];
            $scope.claimInformation.leg_destination_type = [];
            $scope.claimInformation.destination_address_name = [];
          }
          for (
            let legIndex = 0;
            legIndex < $scope.rideDetails.legs.length;
            legIndex += 1
          ) {
            if (
              $scope.rideDetails.legs[legIndex].ride_status
              === 'Potential Unavailability'
            ) {
              $scope.rideDetails.legs[legIndex].showUnavailableMessage = true;
              $scope.bookButtonText = 'Keep Trip';
            } else if (
              $scope.rideDetails.legs[legIndex].ride_status === 'Failed Fuse'
            ) {
              $scope.bookButtonText = 'Rebook';
              $scope.cancelButtonText = 'Clear Alert ';
            } else {
              // nothing
            }

            $scope.claimInformation.leg_pick_up_type.push(
              $scope.rideDetails.legs[legIndex].leg_pick_up_type,
            );
            $scope.claimInformation.leg_destination_type.push(
              $scope.rideDetails.legs[legIndex].leg_destination_type,
            );
            $scope.claimInformation.destination_address_name.push(
              $scope.rideDetails.legs[legIndex].destination_address_name,
            );
          }

          if (
            $scope.rideDetails.appointment
            && $scope.rideDetails.appointment.recurrence_id
            && $scope.recurrenceAction === 'series'
          ) {
            if (
              $scope.rideDetails.appointment.recurrence_ride
              && $scope.rideDetails.appointment.recurrence_ride.time
              && typeof $scope.rideDetails.appointment.recurrence_ride.time
              === 'string'
            ) {
              const timeSplit = $scope.rideDetails.appointment.recurrence_ride.time.split(
                ':',
              );
              $scope.rideDetails.recurringTime = new Date(
                1970,
                1,
                1,
                parseInt(timeSplit[0], 10),
                parseInt(timeSplit[1], 10),
              );
            } else {
              $scope.rideDetails.recurringTime = new Date(1970, 1, 1, 0, 0);
            }
            const { recurrence_ride: recurrenceRide } = $scope.rideDetails.appointment;
            if (recurrenceRide) {
              $scope.rideDetails.recurringData = {
                description: recurrenceRide.description || '',
                time: 0,
                pattern: recurrenceRide.pattern || 'daily',
                days: angular.copy(recurrenceRide.days || []).map(day => day.charAt(0).toUpperCase() + day.slice(1)),
                interval: recurrenceRide.pattern === 'weekly' ? recurrenceRide.interval : 1,
                monthlyInterval: recurrenceRide.pattern === 'monthly' ? recurrenceRide.interval : 1,
                startBy: moment.utc(recurrenceRide.startBy),
                endBy: moment.utc(recurrenceRide.endBy),
                daysOccurenceNumber: recurrenceRide.days_occurence_number || 0,
              };

              if (recurrenceRide.pattern === 'monthly') {
                if (!recurrenceRide.days.length) {
                  $scope.isMonthlyDate = { value: 'true' };
                  $scope.monthlyDateInterval = { value: $scope.rideDetails.recurringData.monthlyInterval };
                  $scope.monthlyDayInterval = { value: 1 };
                } else {
                  const day = recurrenceRide.days.map(d => d.charAt(0).toUpperCase() + d.slice(1))[0];
                  $scope.isMonthlyDate = { value: 'false' };
                  $scope.selectedMonthlyDay = { name: day, value: day };// recurrenceRide.days.map(day => day.charAt(0).toUpperCase() + day.slice(1));
                  $scope.monthlyDayInterval = { value: $scope.rideDetails.recurringData.monthlyInterval };
                  $scope.monthlyDateInterval = { value: 1 };
                }
                $scope.monthlyDayOccurenceNumber = (() => {
                  if (recurrenceRide.days_occurence_number) {
                    return { value: recurrenceRide.days_occurence_number, name: $scope.monthlyWeeks[recurrenceRide.days_occurence_number - 1].name };
                  }
                  return { value: 1, name: 'First' };
                })();
              }
              const endMonthIndex = getMonthIndex($scope.rideDetails.recurringData.endBy.clone().format('MMMM'));
              $scope.rideDetails.recurringData.endMonth = $scope.months[endMonthIndex];
              $scope.selectedDate.date = +moment(
                $scope.rideDetails.appointment.recurrence_ride.startBy,
              ).format('D');
              $scope.monthlyDateChanged($scope.selectedDate.date);
            }

            if (recurrenceRide.pattern === 'daily') {
              $scope.rideDetails.recurringData.interval = null;
              $scope.rideDetails.recurringData.days = angular.copy($scope.days);
            }

            if (
              $scope.rideDetails.recurringData.startBy.isBefore(
                moment(),
                'days',
              )
            ) {
              $scope.startByDisabled = true;
            }

            $scope.rangeAltered = true;
          }

          focusedBlockIndex = $scope.rideDetails.leg_no - 1;

          setMarker(
            $scope.rideDetails.source_lat_long[0],
            $scope.rideDetails.source_lat_long[1],
            $scope.rideDetails.source_address,
            pickupData,
            focusedBlockIndex,
          );

          setMarker(
            $scope.rideDetails.dest_lat_long[0],
            $scope.rideDetails.dest_lat_long[1],
            $scope.rideDetails.dest_address,
            destinationData,
            focusedBlockIndex,
          );

          TimerRemovers.push(
            $timeout(() => {
              firstTimeLoad = false;
            }, 1000),
          );

          // map claim information details - start
          if (
            $scope.rideDetails.appointment
            && $scope.rideDetails.appointment.dynamite_claim
            && $scope.rideDetails.appointment.dynamite_claim.id
          ) {
            const dynamiteInfo = $scope.rideDetails.appointment.dynamite_claim;
            if (dynamiteInfo.dynamite_claims_adjuster) {
              $scope.claimInformation.adjuster_first_name = dynamiteInfo.dynamite_claims_adjuster.first_name;
              $scope.claimInformation.adjuster_last_name = dynamiteInfo.dynamite_claims_adjuster.last_name;
              $scope.claimInformation.adjuster_email_address = dynamiteInfo.dynamite_claims_adjuster.email;
            }

            $scope.claimInformation.date_of_injury = moment.utc(
              dynamiteInfo.date_of_injury,
            );
            $scope.claimInformation.date_of_birth = moment.utc(dynamiteInfo.date_of_birth);
            $scope.billingDetails.selectedPayerName = dynamiteInfo.payer_id;

            $scope.claimInformation.claim_state = dynamiteInfo.claim_state;
            $scope.claimInformation.adjuster_employer_name = dynamiteInfo.employer;
            $scope.claimInformation.injury_state = dynamiteInfo.injury_state;
            $scope.claimInformation.adjuster_home_address = dynamiteInfo.claimant_home_address;

            $scope.claimInformation.claim_number = dynamiteInfo.claim_number;
            $scope.claimInformation.vendor_code = dynamiteInfo.vendor_code;

            $scope.claimInformation.claims_adjuster_id = dynamiteInfo.claims_adjuster_id;
            $scope.claimInformation.dynamite_claims_id = dynamiteInfo.id;
            $scope.claimInformation.claim_appointment_type = $scope.rideDetails.appointment.claim_appointment_type;
          }
          // map claim information details - end
          showDataBasedOnSelectedBillingOption();
        });

        request.catch(({ data: response }) => {
          loadingScreenFactory.hideLoadingScreen();
          firstTimeLoad = false;
          toaster.pop({
            type: 'error',
            title: response.message,
            showCloseButton: true,
            timeout: 10000,
          });
        });
        return request;
      };

      function createMap(pos) {
        if (pos && !$stateParams.rideId && !$stateParams.bookAnotherId) {
          const currentLatlng = new google.maps.LatLng(
            pos.latitude,
            pos.longitude,
          );
          centerMarker = new google.maps.Marker({
            position: currentLatlng,
            map,
            label: '',
            icon: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
            animation: google.maps.Animation.DROP,
          });
          const bounds = new google.maps.LatLngBounds();
          bounds.extend(currentLatlng);
          map.setZoom(16);
          // map.fitBounds(bounds);
          map.panToBounds(bounds);
          firstTimeLoad = false;
          $scope.rideTabDisabled = true;
          // if (loggedInUserDetails.organisation.questionnaire_active) {
          //     $scope.rideTabDisabled = true;
          //     getActiveQuestionnaireForOrg();
          // }
        } else if ($stateParams.rideId || $stateParams.bookAnotherId) {
          // getRideDetailsByRideId();
        } else {
          firstTimeLoad = false;
          $scope.rideTabDisabled = true;
          // if (loggedInUserDetails.organisation.questionnaire_active) {
          //     $scope.rideTabDisabled = true;
          //     getActiveQuestionnaireForOrg();
          // }
        }
      }

      const setCurrentPositionOnMap = function setCurrentPositionOnMap() {
        map = new google.maps.Map(document.getElementById('appointmentMap'), {
          zoom: 2,
          disableDefaultUI: true,
          scrollwheel: false,
          disableDoubleClickZoom: false,
          streetViewControl: false,
          zoomControl: true,
        });
        map.setCenter({
          lat: -34.397,
          lng: 150.644,
        });

        const options = {
          enableHighAccuracy: true,
          // //maximumAge: 600000,
          // timeout: 10000
        };

        if (navigator.geolocation) {
          const locationTimeout = setTimeout(() => {
            createMap();
          }, 10000);

          navigator.geolocation.getCurrentPosition(
            (position) => {
              clearTimeout(locationTimeout);
              currentPosition = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              };
              map.setCenter({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
              createMap(currentPosition);
            },
            () => {
              currentPosition = OCCM_LAT_LONG;
              clearTimeout(locationTimeout);
              createMap();
            },
            options,
          );
        } else {
          // Fallback for no geolocation
          createMap();
        }
      };
      const getActiveQuestionnaireForOrg = function getActiveQuestionnaireForOrg(
        orgId,
      ) {
        let orgIdForQuestionnaire;
        if (orgId) {
          orgIdForQuestionnaire = orgId;
        } else {
          orgIdForQuestionnaire = loggedInUserDetails.organisation.id;
        }
        loadingScreenFactory.showLoadingScreen();
        $http
          .get(
            `${API_BASE_URL}organisation/getActiveQuestionnaireForOrg/${orgIdForQuestionnaire}`,
          )
          .then((result) => {
            if (result.data.success) {
              if (result.data.activeQuestionnaire) {
                $scope.activeQuestionnaire = result.data.activeQuestionnaire;
                $scope.activeTab[0] = true;
                const getQuestionnareFieldsByIdCall = $http({
                  url: `${API_BASE_URL}organisation/getQuestionnareFieldsById/${
                    result.data.activeQuestionnaire.id
                  }`,
                  method: 'GET',
                });
                getQuestionnareFieldsByIdCall.then(
                  (resultData) => {
                    loadingScreenFactory.hideLoadingScreen();
                    if (
                      resultData.data.success
                      && resultData.data.questions
                      && resultData.data.questions.length
                    ) {
                      initQuestionnaireForm();
                      prepareQuestions(resultData.data.questions);
                      createSchemaAndForm();
                    }
                  },
                  (error) => {
                    loadingScreenFactory.hideLoadingScreen();
                    toaster.pop({
                      type: 'error',
                      title: error.data.message,
                      showCloseButton: true,
                      timeout: 10000,
                    });
                  },
                );
              } else {
                loadingScreenFactory.hideLoadingScreen();
                $scope.activeQuestionnaire = null;
                $scope.activeTab[0] = false;
                $scope.activeTab[1] = true;
                // toaster.pop({
                //     type: 'error',
                //     title: 'Please activate a questionnaire',
                //     showCloseButton: true,
                //     timeout: 10000
                // });
                // $state.go("main.dashboard.superAdminDashboard")
              }
            }
          }).catch((error) => {
            loadingScreenFactory.hideLoadingScreen();
            toaster.pop({
              type: 'error',
              title: error.data ? error.data.message : '',
              showCloseButton: true,
              timeout: 10000,
            });
          });
      };

      const updateClaimUIBasedOnSettings = function updateClaimUIBasedOnSettings(isLoginUserDetails, organizationSettings) {
        let orgSettings = organizationSettings;
        if (isLoginUserDetails) {
          orgSettings = organizationSettings.organisation;
          orgSettings.claim_uid = organizationSettings.claim_uid;
        }

        let claimSettingCount = 0;
        // initialize claim ui start
        _.filter(orgSettings.settings, (setting) => {
          if (setting.setting_name === 'account_lookup') {
            claimSettingCount += 1;
            return true;
          }
          if ((setting.setting_name === 'claim_lookup' || setting.setting_name === 'future_lookup') && setting.setting_value.toLowerCase() === 'true') {
            claimSettingCount += 1;
            return true;
          }
          return false;
        });

        if (claimSettingCount > 0) {
          if ($stateParams.bookAnotherId || $stateParams.rideId) {
            $scope.claimInfoFlags.editMode = true;
          }
          if (!$scope.claimInfoFlags.editMode) {
            if (orgSettings.claim_uid) {
              $scope.claimLookupFunction(orgSettings.claim_uid);
            } else {
              $scope.claimInfoFlags.claimNotFoundByClaimUid = true;
            }
          }
        }
      };

      const init = function init() {
        $scope.loading = true;
        selectedProduct = {};
        initializeTaxiProducts();
        initializePatient();
        initializeAppointment();
        $scope.appointmentId = 0;
        $scope.stateParams = $stateParams;
        $scope.rideDetails.time = new Date(1970, 1, 1, 9, 0);
        $scope.premiumReverse = false;
        $scope.basicReverse = false;
        setCurrentPositionOnMap();

        // TODO JASPREET see below logic again need to add recurrence_type when case
        // like if recurrence_id is there make time : 'RECURRENCE_TYPE
        $scope.appointmentTime = {
          time: 'FUTURE_APPOINTMENT',
        };
        // for by passing validation,we set value for appointment time drop donw
        if (
          $scope.stateParams.caller === 'dashboard'
          || $scope.stateParams.caller === 'Book now'
          || $scope.stateParams.caller === 'AR'
        ) {
          $scope.rideDetails.time = new Date(1970, 1, 1, 0, 0);
          $scope.appointmentTime.time = 'BOOK_NOW';
        }

        // This is necessary for claim-uid

        updateClaimUIBasedOnSettings(true, loggedInUserDetails);
        $scope.showRiderDetails = true;
        return getAllOrganizations();
      };

      const recurrenceRangeChange = function recurrenceRangeChange() {
        $scope.daysInRangeCall();
        $scope.recurrenceRangeError.startBy.message = '';
        if (
          $scope.rideDetails
          && $scope.rideDetails.recurringData
          && $scope.rideDetails.recurringData.startBy
          && $scope.rideDetails.recurringData.endBy
        ) {
          if (
            !$scope.startByDisabled
            && $scope.rideDetails.recurringData.startBy.isBefore(
              moment(),
              'days',
            )
          ) {
            $scope.recurrenceRangeError.startBy.status = true;
            $scope.recurrenceRangeError.startBy.message = 'Start date cannot be before today';
          } else if (
            (!$scope.startByDisabled
              && $scope.rideDetails.recurringData.startBy.isAfter(
                moment().add(
                  RECURRENCE_LIMIT - 1,
                  'days',
                ),
                'days',
              ))
            || $scope.rideDetails.recurringData.endBy.isAfter(
              moment().add(
                RECURRENCE_LIMIT - 1,
                'days',
              ),
              'days',
            )
          ) {
            $scope.recurrenceRangeError.startBy.status = true;
            $scope.recurrenceRangeError.startBy.message = 'Trips cannot be created for the selected recurrence pattern : Exceeded EndBy validation of 1 year.';
          } else if (
            $scope.rideDetails.recurringData.endBy.isBefore(
              moment(),
              'days',
            )
          ) {
            $scope.recurrenceRangeError.startBy.status = true;
            $scope.recurrenceRangeError.startBy.message = 'End date cannot be before today';
          } else if (
            $scope.rideDetails.recurringData.endBy.isBefore(
              $scope.rideDetails.recurringData.startBy,
              'days',
            )
          ) {
            $scope.recurrenceRangeError.startBy.status = true;
            $scope.recurrenceRangeError.startBy.message = 'End date cannot be before start date';
          } else {
            $scope.recurrenceRangeError.startBy.status = false;
            if ($scope.recurrenceRangeError.startBy.message) {
              $scope.recurrenceRangeError.startBy.message = '';
            }
          }
          const startWeek = moment().week();
          const WEEKSINYEAR = 52;
          const endByDate = $scope.rideDetails.recurringData.endBy;
          const startYear = $scope.rideDetails.recurringData.startBy.year();
          const endWeek = endByDate.weekYear() === startYear
            ? endByDate.week()
            : endByDate.week() + WEEKSINYEAR;
          const endWeekCheck = moment().add(RECURRENCE_LIMIT - 1, 'days');
          $scope.maxWeeklyInterval = (endWeek - startWeek) > RECURRENCE_WEEKLY_MAX_INTERVALS ? RECURRENCE_WEEKLY_MAX_INTERVALS : endWeek - startWeek;
          if ($scope.maxWeeklyInterval < 1) {
            $scope.maxWeeklyInterval = 1;
          } else if (
            $scope.rideDetails.recurringData.pattern === 'weekly'
            && $scope.rideDetails.recurringData.endBy.isAfter(endWeekCheck)
          ) {
            $scope.recurrenceRangeError.startBy.status = true;
            $scope.maxWeeklyInterval = RECURRENCE_WEEKLY_MAX_INTERVALS;
            $scope.recurrenceRangeError.startBy.message = 'Trips cannot be created for the selected recurrence pattern : Exceeded EndBy validation of 1 year.';
          } else {
            //
          }
        } else if (
          $scope.rideDetails.recurringData
          && $scope.rideDetails.recurringData.pattern === 'monthly'
        ) {
          $scope.recurrenceRangeError.startBy.status = false;
          $scope.recurrenceRangeError.startBy.message = '';
        } else if (
          $scope.rideDetails
          && $scope.rideDetails.recurringData
          && !$scope.rideDetails.recurringData.startBy
        ) {
          $scope.recurrenceRangeError.startBy.status = true;
          $scope.recurrenceRangeError.startBy.message = 'Please select a Start date';
        } else {
          $scope.recurrenceRangeError.startBy.status = true;
          $scope.recurrenceRangeError.startBy.message = 'Please select an End By date';
        }
      };

      $scope.hideTaxiDetails = function hideTaxiDetails() {
        if (!($stateParams.bookAnotherId || $stateParams.rideId)) {
          initializeTaxiProducts();
        }
      };
      $scope.claimNumberChanged = function claimNumberChanged() {
        $scope.isClaimNumberChanged = true;
        $scope.appointmentForm.claimNumber.$setValidity('isClaimNumberValid', false);
        $scope.hideTaxiDetails();
      };
      const registerWatches = function registerWatches() {
        WatcherRemovers.push(
          $scope.$watch('rideDetails.org_id', () => {
            // If org changed, we need to get products again
            initializeTaxiProducts();
            getOrgTripTimeTypeSettings();
          }),
        );

        WatcherRemovers.push(
          $scope.$watch('rideDetails.date', () => {
            initializeTaxiProducts();
          }),
        );

        WatcherRemovers.push(
          $scope.$watch('rideDetails.time', () => {
            initializeTaxiProducts();
          }),
        );

        WatcherRemovers.push(
          $scope.$watch('rideDetails.legs[0].source_address', () => {
            if (
              $scope.rideDetails.leg_type === 'round'
              && $scope.rideDetails.legs.length === 2
              && firstTimeLoad === false
            ) {
              $scope.rideDetails.legs[1].dest_address = $scope.rideDetails.legs[0].source_address;
            }
          }),
        );

        WatcherRemovers.push(
          $scope.$watch('rideDetails.legs[0].dest_address', () => {
            if (
              $scope.rideDetails.leg_type === 'round'
              && $scope.rideDetails.legs.length === 2
              && firstTimeLoad === false
            ) {
              $scope.rideDetails.legs[1].source_address = $scope.rideDetails.legs[0].dest_address;
            }
          }),
        );

        WatcherRemovers.push(
          $scope.$watch('rideDetails.legs[0].sourceAddressPincode', () => {
            if (
              $scope.rideDetails.leg_type === 'round'
              && $scope.rideDetails.legs.length === 2
              && firstTimeLoad === false
            ) {
              $scope.rideDetails.legs[1].destAddressPincode = $scope.rideDetails.legs[0].sourceAddressPincode;
            }
          }),
        );

        WatcherRemovers.push(
          $scope.$watch('rideDetails.legs[0].destAddressPincode', () => {
            if (
              $scope.rideDetails.leg_type === 'round'
              && $scope.rideDetails.legs.length === 2
              && firstTimeLoad === false
            ) {
              $scope.rideDetails.legs[1].sourceAddressPincode = $scope.rideDetails.legs[0].destAddressPincode;
            }
          }),
        );

        WatcherRemovers.push(
          $scope.$watch(
            'rideDetails.recurringData.startBy',
            recurrenceRangeChange,
          ),
        );

        WatcherRemovers.push(
          $scope.$watch('rideDetails.recurringData.endBy', recurrenceRangeChange),
        );

        WatcherRemovers.push(
          $scope.$watch(
            'rideDetails.recurringData.days.length',
            $scope.recurrenceDaysChange,
          ),
        );
      };

      const getEnabledLanguages = function getEnabledLanguages(languages) {
        const enabledLanguages = {};
        const { enabledLanguageIds } = relaylib.languages;
        const keys = Object.keys(languages);
        keys.forEach((key) => {
          const value = languages[key];
          if (enabledLanguageIds.includes(value)) {
            enabledLanguages[key] = value;
          }
        });
        return enabledLanguages;
      };

      const getUserDetailsById = function getUserDetailsById() {
        $scope.loading = true;
        return $http
          .get(
            `${API_BASE_URL}users/getUserDetailsById/${
              loggedInUserDetails.user_id
            }`,
          )
          .then((result) => {
            if (result.data.success) {
              loggedInUserDetails.organisation.questionnaire_active = result.data.userDetails.organisation.questionnaire_active;
              if (result.data.userDetails.organisation.customizable_file_number) {
                $scope.fileNumberLabel = result.data.userDetails.organisation.file_number_label;
              }
            } else {
              toaster.pop({
                type: 'error',
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
            }
            registerWatches();
            return init();
          })
          .then(() => {
            const headers = {
              username: loggedInUserDetails.username,
              usertoken: loggedInUserDetails.user_token,
              'Content-Type': 'application/json',
            };

            $http.get(`${API_BASE_URL}languages`, { headers }).then((languages) => {
              $scope.language.languages = getEnabledLanguages(languages.data);
              if (!$scope.language.languageSelected) {
                $scope.language.languageSelected = 'english';
              }
            });
          })
          .catch((error) => {
            toaster.pop({
              type: 'error',
              title: error.data ? error.data.message : '',
              showCloseButton: true,
              timeout: 10000,
            });
          })
          .finally(() => {
            $scope.loading = false;
          });
      };

      const getAddressComponents = function getAddressComponents(
        place,
        setterPincodeProperty,
        rideLegObj,
      ) {
        let pincode;
        place.address_components.forEach((obj) => {
          if (obj.types.indexOf('postal_code') !== -1) {
            pincode = obj.short_name;
          }
        });

        if (typeof pincode !== 'undefined') {
          Object.assign(rideLegObj, {
            [setterPincodeProperty]: pincode,
          });
          return;
        }

        const geocoder = new google.maps.Geocoder();
        const latlng = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        geocoder.geocode(
          {
            location: latlng,
          },
          (results, status) => {
            if (
              status === 'OK'
              && angular.isDefined(results)
              && Array.isArray(results)
            ) {
              for (let i = 0; i < results.length; i += 1) {
                for (let j = 0; j < results[i].address_components.length; j += 1) {
                  const obj = results[i].address_components[j];
                  if (obj.types.indexOf('postal_code') !== -1) {
                    pincode = obj.short_name;
                    Object.assign(rideLegObj, {
                      [setterPincodeProperty]: pincode,
                    });
                    // break inner loop
                    break;
                  }
                } // end inner loop

                if (typeof pincode !== 'undefined') {
                  // break outer loop
                  break;
                }
              }
            }
          },
        );
      };

      $scope.hideZipCode = function hideZipCode(
        addressProperty,
        pincodeProperty,
        index,
      ) {
        if (!$scope.rideDetails.legs[index][addressProperty]) {
          $scope.rideDetails.legs[index][pincodeProperty] = undefined;
        }
      };

      const getAddressComponentsByAddress = function getAddressComponentsByAddress(
        address,
      ) {
        const geocoder = new google.maps.Geocoder();
        return new Promise((resolve) => {
          geocoder.geocode(
            {
              address,
            },
            (results, status) => {
              if (status === google.maps.GeocoderStatus.OK) {
                resolve(results);
              }
            },
          );
        });
      };

      $scope.isSelectedCountryUS = true;
      (function registerListenerForCountryChange() {
        $timeout(() => {
          $('.iti__country-list .iti__country').click(() => {
            $timeout(() => {
              if (angular.element('#txtPhoneNo').intlTelInput('getSelectedCountryData').iso2 === 'us') {
                $scope.mask = '(999) 999-9999';
                $scope.isSelectedCountryUS = true;
              } else {
                $scope.mask = '(999) 999-99999?9?9?9?9?';
                $scope.isSelectedCountryUS = false;
              }
            });
          });
        });
      }());

      WatcherRemovers.push(
        $scope.$on('$viewContentLoaded', () => {
          getUserDetailsById().then(() => {
            if ($stateParams.bookAnotherId || $stateParams.rideId) {
              return getRideDetailsByRideId().then(() => $scope.checkSpecialRide());
            }
            return $scope.checkSpecialRide();
          });
        }),
      );

      const setSourceAddressDetails = function setSourceAddressDetails(
        pickupPlace,
        index,
      ) {
        $scope.rideDetails.legs[index].short_source = pickupPlace.name;

        if (!pickupData.viaPin && !pickupData.viaTag) {
          $scope.rideDetails.legs[index].source_lat_long = [
            pickupPlace.geometry.location.lat(),
            pickupPlace.geometry.location.lng(),
          ];
        }

        if (
          $scope.rideDetails.legs.length > 1
          && index === 0
          && $scope.rideDetails.leg_type === 'round'
        ) {
          $scope.rideDetails.legs[1].short_dest = pickupPlace.name;
          $scope.rideDetails.legs[1].dest_lat_long = angular.copy(
            $scope.rideDetails.legs[index].source_lat_long,
          );
        }

        // Moved to top, because on adding or making changes in any leg (source) , products will be initialized
        initializeTaxiProducts();

        if (centerMarker && typeof centerMarker.setMap === 'function') {
          centerMarker.setMap(null);
        }

        setMarker(
          $scope.rideDetails.legs[index].source_lat_long[0],
          $scope.rideDetails.legs[index].source_lat_long[1],
          pickupPlace.name,
          pickupData,
          index,
        );

        $scope.overlaySetting = {
          showIfSave: true,
          showTagForm: false,
          tagName: '',
        };
        focusedLeg = { index, type: 'source' };
      };

      let triggeredViaAddress = false;
      $scope.pickupAddressChanged = function pickupAddressChanged(error, index) {
        pickupData.viaPin = false;
        pickupData.viaTag = false;
        let pickupPlace = this.getPlace();
        triggeredViaAddress = true;
        if (
          typeof pickupPlace.address_components === 'undefined'
          && typeof pickupPlace.geometry === 'undefined'
        ) {
          const promise = getAddressComponentsByAddress(pickupPlace.name);
          promise.then((result) => {
            [pickupPlace] = result;
            [pickupPlace.name] = (pickupPlace.formatted_address && pickupPlace.formatted_address.split(',')) || [''];
            getAddressComponents(
              pickupPlace,
              'sourceAddressPincode',
              $scope.rideDetails.legs[index],
            );
            setSourceAddressDetails(pickupPlace, index);
            $scope.appointmentForm[`sourceAddress${index}`].$setValidity(
              `sourceAddress${index}`,
              true,
            );
          });
        } else {
          getAddressComponents(
            pickupPlace,
            'sourceAddressPincode',
            $scope.rideDetails.legs[index],
          );
          setSourceAddressDetails(pickupPlace, index);
          $scope.appointmentForm[`sourceAddress${index}`].$setValidity(
            `sourceAddress${index}`,
            true,
          );
        }
      };

      // Check if special ride option enebaled for selected organization
      $scope.checkSpecialRide = async function checkSpecialRide() {
        $scope.loading = true;
        const orgId = $scope.rideDetails ? $scope.rideDetails.org_id : 0;
        const selectedOrgDetails = $scope.organisations.find(organisation => organisation.id === orgId);
        if (selectedOrgDetails) {
          $scope.seletedOrgDetails = selectedOrgDetails;
          $scope.isGhtOrg = selectedOrgDetails.market_segment.key === 'GHT';
          if (selectedOrgDetails.customizable_file_number) {
            $scope.fileNumberLabel = selectedOrgDetails.file_number_label;
          } else {
            try {
              const { data: { orgsWithInheritedSettings: [{ file_number_label: ancestorfileNumberLabel } = {}] = [] } } = await orgHierarchyFactory.getOrgsWithInheritedSettings(selectedOrgDetails.id,
                [
                  'file_number_label',
                ]);
              $scope.fileNumberLabel = 'File Number';
              if (ancestorfileNumberLabel) {
                $scope.fileNumberLabel = ancestorfileNumberLabel;
              }
            } catch (error) {
              toaster.pop({
                type: 'error',
                title: (error.data || {}).message ? error.data.message : error.message,
                showCloseButton: true,
                timeout: 10000,
              });
            }
          }

          if (selectedOrgDetails.special_ride) {
            $scope.showSpecialRide = true;
          } else {
            $scope.showSpecialRide = false;
          }

          if (selectedOrgDetails.org_level) {
            $scope.rideDetails.org_level = selectedOrgDetails.org_level;
          }

          if (selectedOrgDetails.ride_invoice_history) {
            $scope.ride_invoice_history = selectedOrgDetails.ride_invoice_history;
          } else {
            $scope.ride_invoice_history = false;
          }
        }

        if (!$stateParams.rideId && !$stateParams.bookAnotherId) {
          if (selectedOrgDetails && !selectedOrgDetails.questionnaire_active) {
            $scope.activeQuestionnaire = null;
            $scope.activeTab[0] = false;
            $scope.activeTab[1] = true;
          } else {
            getActiveQuestionnaireForOrg(orgId);
          }
        } else if (
          initialRideDetails
          && orgId === initialRideDetails.appointment.org_id
        ) {
          processAnswer(savedAnswer);
        } else if (!firstTimeLoad) {
          getActiveQuestionnaireForOrg(orgId);
        }

        return getAllPaymentMethods(
          orgId || loggedInUserDetails.organisation.id,
        ).then(() => {
          $scope.loading = false;
        });
      };
      // Book Special Ride
      $('#specialFacility').iCheck('check');
      $scope.bookSpecialRide = function bookSpecialRide() {
        if ($('#specialFacility').iCheck('update')[0].checked) {
          const rideDetails = {};
          loadingScreenFactory.showLoadingScreen();
          const request = $http.post(
            `${API_BASE_URL}ride/addSpecialRide/${$scope.rideDetails.org_id}`,
            rideDetails,
          );
          request.then(({ data: response }) => {
            if (response.success) {
              loadingScreenFactory.hideLoadingScreen();
              SweetAlert.swal(
                {
                  title: 'Thank you for your Special Needs Ride request.',
                  text:
                    'A One Call Representative will be in contact with you shortly',
                  type: 'success',
                  confirmButtonColor: '#DD6B55',
                  confirmButtonText: 'OK',
                },
                () => {
                  $state.go('main.dashboard.superAdminDashboard');
                },
              );
            }
          });
        } else {
          SweetAlert.swal('Please select special ride option', '', 'warning');
        }
      };

      $scope.setInvalid = function setInvalid(key, index, type) {
        $timeout(() => {
          let ngChangeTriggered = true;
          if (triggeredViaAddress) {
            ngChangeTriggered = false;
            triggeredViaAddress = false;
          }
          if (ngChangeTriggered === true) {
            if (type === 'source') {
              $scope.rideDetails.legs[index].source_lat_long.length = 0;
            } else {
              $scope.rideDetails.legs[index].dest_lat_long.length = 0;
            }
            if (
              $scope.rideDetails.legs[index].source_lat_long.length === 0
              && !$scope.appointmentForm[key].$error.required
            ) {
              if (
                $scope.rideDetails.legs.length > 1
                && index === 0
                && $scope.rideDetails.leg_type === 'round'
              ) {
                $scope.rideDetails.legs[1].dest_lat_long.length = 0;
              }
            } else if (
              $scope.rideDetails.legs[index].dest_lat_long.length === 0
              && !$scope.appointmentForm[key].$error.required
            ) {
              if (
                $scope.rideDetails.legs.length > 1
                && index === 0
                && $scope.rideDetails.leg_type === 'round'
              ) {
                $scope.rideDetails.legs[1].source_lat_long.length = 0;
              }
            }
            $scope.appointmentForm[key].$setValidity(key, false);
          }
        }, 1000);
      };

      const setDestAddressDetails = function setDestAddressDetails(
        destinationPlace,
        index,
      ) {
        $scope.rideDetails.legs[index].short_dest = destinationPlace.name;
        if (!destinationData.viaPin && !destinationData.viaTag) {
          $scope.rideDetails.legs[index].dest_lat_long = [
            destinationPlace.geometry.location.lat(),
            destinationPlace.geometry.location.lng(),
          ];
        }

        if (
          $scope.rideDetails.legs.length > 1
          && index === 0
          && $scope.rideDetails.leg_type === 'round'
        ) {
          $scope.rideDetails.legs[1].short_source = destinationPlace.name;
          $scope.rideDetails.legs[1].source_lat_long = angular.copy(
            $scope.rideDetails.legs[index].dest_lat_long,
          );
        }
        // Moved to top, because on adding or making changes in any leg (destination) , products will be initialized
        initializeTaxiProducts();

        setMarker(
          $scope.rideDetails.legs[index].dest_lat_long[0],
          $scope.rideDetails.legs[index].dest_lat_long[1],
          destinationPlace.name,
          destinationData,
          index,
        );
        $scope.overlaySetting = {
          showIfSave: true,
          showTagForm: false,
          tagName: '',
        };
        focusedLeg = { index, type: 'dest' };
      };

      $scope.destinationAddressChanged = function destinationAddressChanged(
        error,
        index,
      ) {
        destinationData.viaPin = false;
        destinationData.viaTag = false;
        let destinationPlace = this.getPlace();
        triggeredViaAddress = true;
        if (
          typeof destinationPlace.address_components === 'undefined'
          && typeof destinationPlace.geometry === 'undefined'
        ) {
          const promise = getAddressComponentsByAddress(destinationPlace.name);
          promise.then((result) => {
            [destinationPlace] = result;
            [destinationPlace.name] = (destinationPlace.formatted_address && destinationPlace.formatted_address.split(',')) || [''];
            getAddressComponents(
              destinationPlace,
              'destAddressPincode',
              $scope.rideDetails.legs[index],
            );
            setDestAddressDetails(destinationPlace, index);
            $scope.appointmentForm[`destinationAddress${index}`].$setValidity(
              `destinationAddress${index}`,
              true,
            );
          });
        } else {
          getAddressComponents(
            destinationPlace,
            'destAddressPincode',
            $scope.rideDetails.legs[index],
          );
          setDestAddressDetails(destinationPlace, index);
          $scope.appointmentForm[`destinationAddress${index}`].$setValidity(
            `destinationAddress${index}`,
            true,
          );
        }
      };

      $scope.getUnmatchedPattern = (str, nameType) => {
        if (!str) {
          delete $scope.unmatchedPattern;
          $scope.appointmentForm[nameType].$setValidity('pattern', true);
          return;
        }
        const nameRegex = /^[ A-Za-z\s-',.]*$/;
        const strChars = Array.from(str);
        if (!strChars.length) {
          delete $scope.unmatchedPattern;
          $scope.appointmentForm[nameType].$setValidity('pattern', true);
          return;
        }
        const invalidCharacters = strChars.filter(char => !nameRegex.test(char));
        if (!invalidCharacters.length) {
          delete $scope.unmatchedPattern;
          $scope.appointmentForm[nameType].$setValidity('pattern', true);
          return;
        }
        const uniqueInvalidCharactersSet = new Set(invalidCharacters);
        const uniqueInvalidCharacters = Array.from(uniqueInvalidCharactersSet);
        $scope.unmatchedPattern =  uniqueInvalidCharacters.join();
        $scope.appointmentForm[nameType].$setValidity('pattern', false);
      };

      $scope.getProducts = function getProducts() {
        const tripType = $scope.appointmentTime.time;
        if (!tripType.length) {
          toaster.pop({
            type: 'error',
            title: 'Cannot get estimates',
            body: 'Invalid Trip Type',
            showCloseButton: true,
            bodyOutputType: 'trustedHtml',
          });
          return;
        }
        if (tripType !== 'RECURRENCE_TRIP') {
          const fieldsToBeValidatedOnTripForm = ['radioTripTimeType'];
          const fieldsToBeValidatedOnTripFormForEachLeg = [...$scope.billingOptionsDefault[$scope.billingDetails.selectedBillingOption].fieldsToBeValidatedOnTripForm];
          $scope.rideDetails.legs.forEach((ride, index) => {
            fieldsToBeValidatedOnTripFormForEachLeg.forEach((field) => {
              if (field !== 'radioTripTimeType') {
                fieldsToBeValidatedOnTripForm.push(`${field}${index}`);
              }
            });
          });
          if ($scope.appointmentForm.radioTripTimeType) {
            if (!$scope.rideDetails.trip_time_type) {
              $scope.appointmentForm.radioTripTimeType.$setValidity('tripTimeTypeNotSelected', false);
            } else {
              $scope.appointmentForm.radioTripTimeType.$setValidity('tripTimeTypeNotSelected', true);
            }
          }
          const invalidTripTabFields = fieldsToBeValidatedOnTripForm.filter(fieldToBeValidated => (($scope.appointmentForm[fieldToBeValidated] || {}).$invalid === true));
          if (invalidTripTabFields.length) {
            const firstInvalidTripTabField = $(`[name = ${invalidTripTabFields[0]}]`)[0];
            if (firstInvalidTripTabField) {
              firstInvalidTripTabField.focus();
            }
            $scope.isAnyTripTabFieldInvalid = true;
            return;
          }
          $scope.isAnyTripTabFieldInvalid = false;
        }
        let productsURL = API_BASE_URL;
        if ($scope.appointmentTime.time === 'BOOK_NOW') {
          if (
            $scope.rideDetails.appointment
            && $scope.rideDetails.appointment.sent_by_fuse
          ) {
            // If appt is scheduled by fuse, send client id for finding preferred transport options
            productsURL += `provider/estimates/book-now/${
              $scope.rideDetails.appointment.client_id
            }?fuse_ride=1`;
          } else {
            // If appt is scheduled by relay, send org id for finding preferred transport options
            productsURL += `provider/estimates/book-now/${$scope.rideDetails.org_id}?fuse_ride=0`;
          }
        } else if (
          $scope.rideDetails.appointment
          && $scope.rideDetails.appointment.sent_by_fuse
        ) {
          // If appt is scheduled by fuse, send client id for finding preferred transport options
          productsURL += `provider/estimates/schedule/${
            $scope.rideDetails.appointment.client_id
          }?fuse_ride=1`;
        } else {
          // If appt is scheduled by relay, send org id for finding preferred transport options
          productsURL += `provider/estimates/schedule/${$scope.rideDetails.org_id}?fuse_ride=0`;
        }

        const legs = [];
        let filteredIndex = -1;
        const validStartLegStatuses = [
          'Confirmed',
          'Potential Unavailability',
          'Failed Fuse',
          'Ready to Process',
        ];
        let legNo = 1;
        for (
          let realIndex = 0;
          realIndex < $scope.rideDetails.legs.length;
          realIndex += 1
        ) {
          if (
            filteredIndex < 0
            && (!$scope.rideDetails.legs[realIndex].ride_status
              || ($scope.rideDetails.legs[realIndex].ride_status
                && validStartLegStatuses.indexOf(
                  $scope.rideDetails.legs[realIndex].ride_status,
                ) >= 0))
          ) {
            filteredIndex = realIndex;
            if (realIndex > 0) {
              legNo = $scope.rideDetails.legs[realIndex - 1].leg_no + 1;
            }
          }

          if (filteredIndex >= 0) {
            const { time, date } = $scope.rideDetails;
            $scope.rideDetails.legs[realIndex].leg_no = legNo;
            legNo += 1;
            legs.push({
              source_lat_long: $scope.rideDetails.legs[realIndex].source_lat_long,
              dest_lat_long: $scope.rideDetails.legs[realIndex].dest_lat_long,
              zip_code: $scope.rideDetails.legs[realIndex].sourceAddressPincode,
              time: `${time.getHours()}:${time.getMinutes()}`,
              date: `${date.year()}/${date.month()}/${date.date()}`,
            });
          }
        }

        if (!legs.length) {
          toaster.pop({
            type: 'error',
            title: 'Cannot get estimates',
            body: 'No valid legs added',
            showCloseButton: true,
            bodyOutputType: 'trustedHtml',
            closeHtml: '<button>X</button>',
          });
          return;
        }

        // Remove currently selected provider product from ride details,
        // as once the product list is refreshed, user must select a new product from the refreshed product list
        if ($scope.rideDetails && $scope.rideDetails.provider_prod_id) {
          $scope.rideDetails.provider_prod_id = '';
        }

        const getProductsCall = $http.post(productsURL, {
          legs,
          org_id: $scope.rideDetails.org_id,
          appt_id: $scope.rideDetails.appt_id || null,
          trip_time_type: $scope.rideDetails.trip_time_type || 'pickup',
        });
        getProductsCall.then(({ data: response }) => {
          getEstimatesButtonHit = true;
          let legAvailableFlag = true;
          let anotherLegNo = '';
          if (response.legs_available && !response.isZipcodeAvailabilityEnabled) {
            angular.forEach(response.legs_available, (legAvailable, index) => {
              if (!legAvailable) {
                $scope.rideDetails.legs[index + filteredIndex].errorMessage = "This leg is outside the vendor's service area.";
                legAvailableFlag = false;
                if (anotherLegNo) {
                  anotherLegNo += ', ';
                }
                anotherLegNo += `Leg ${index + filteredIndex + 1}`;
              } else {
                $scope.rideDetails.legs[index + filteredIndex].errorMessage = '';
              }
            });
          }
          // Customized message according to number of legs not available
          let toasterMessage = legAvailableFlag
            // Case when all legs available but book now is not available
            // Api return will be success false
            ? '<br/><b><ul><li>No product available at this time. </li></ul></b>'
            : `<br/><b><ul><li>${anotherLegNo} outside of vendor's service area. </li></ul>Please remove these legs and try again.</b>`;
          if (response.success) {
            // Take provider_product for existing rides and change display name to Active ....
            // So that no two providers are shown on same page.
            const providerProduct = angular.copy(
              $scope.rideDetails.provider_product || {},
            );
            providerProduct.display_name = 'Active Ride';

            const taxiProducts = {
              basic: [],
              premium: [],
            };

            if (!legAvailableFlag) {
              if (response.isZipcodeAvailabilityEnabled) {
                toasterMessage = response.message;
              }
              // toaster.error("Some addresses are outside the vendor's service area.");
              toaster.pop({
                type: 'error',
                title: 'Trip cannot be created.',
                body: toasterMessage,
                showCloseButton: true,
                bodyOutputType: 'trustedHtml',
              });
            }
            response.data.products.forEach((item) => {
              if (item) {
                // Commenting out different categories and using all products as basic only
                // if (['UberX', 'uberX', 'Lyft', 'lyft'].indexOf(item.display_name) != -1)
                if (
                  $scope.rideDetails.provider_product
                  && $scope.rideDetails.ride_category === 'active'
                ) {
                  // If we are getting estimates for some existing appointment,
                  // it will have a provider_product object for active ride... add this to the fetched estimates and recalculate the total estimate.
                  item.breakdown.unshift(providerProduct);
                  const tempTotalEstimateRaw = angular.copy(item.totalEstimateRaw);
                  for (let i = 0; i < 2; i += 1) {
                    tempTotalEstimateRaw[i] += (providerProduct.totalEstimateRaw && providerProduct.totalEstimateRaw[i]) || 0;
                  }
                  Object.assign(item.totalEstimateRaw, { 0: tempTotalEstimateRaw[0] });
                  Object.assign(item.totalEstimateRaw, { 1: tempTotalEstimateRaw[1] });
                  const matches = new RegExp(
                    '([^0-9]+)\\s*([0-9]+)\\s*-\\s*([0-9]+)',
                  ).exec(item.totalEstimate);
                  Object.assign(item, {
                    totalEstimate: `${(matches ? matches[1] : '$') + Math.floor(item.totalEstimateRaw[0])}-${Math.ceil(item.totalEstimateRaw[1])}`,
                  });
                }

                taxiProducts.basic.push(item);
                // else
                // taxiProducts.premium.push(item)
              }
            });

            if ($scope.appointmentTime.time === 'BOOK_NOW') {
              $scope.cost_token_redis_key = response.cost_token_redis_key || undefined;
              $scope.override_cost_token = false;
            }

            $scope.taxiProducts = taxiProducts;
            TimerRemovers.push(
              $timeout(() => {
                $('#current-app').animate(
                  {
                    scrollTop: $('#current-app #wrapper').height(),
                  },
                  1000,
                );
              }, 500),
            );
          } else {
            // toaster.error(response.message); // this message was for first leg from backend
            if (response.isZipcodeAvailabilityEnabled) {
              toasterMessage = response.message;
            }
            toaster.pop({
              type: 'error',
              title: 'Trip cannot be created.',
              body: toasterMessage,
              showCloseButton: true,
              bodyOutputType: 'trustedHtml',
            });
          }
        });

        getProductsCall.catch(() => {
        });
      };

      const getAppointmentDateAndTime = function getAppointmentDateAndTime(
        appointmentDate,
        appointmentTime,
      ) {
        const tempDate = moment(appointmentDate);
        return {
          date: tempDate.format('YYYY-MM-DD'),
          time: `${$filter('date')(appointmentTime, 'HH:mm')}`,
        };
      };

      const getRecurrenceAppointmentDateAndTime = function getRecurrenceAppointmentDateAndTime(
        appointmentStartDate,
        appointmentEndDate,
        appointmentTime,
      ) {
        const tempStartDate = moment(appointmentStartDate);
        const tempEndDate = moment(appointmentEndDate);
        return {
          startBy: tempStartDate.format('YYYY-MM-DD'),
          endBy: tempEndDate.format('YYYY-MM-DD'),
          time: moment(appointmentTime).format('HH:mm'),
        };
      };

      const prepareAnswers = function prepareAnswers() {
        const answers = [];
        for (let index = 0; index < $scope.questions.length; index += 1) {
          if ($scope.questions[index].type === 'date' && $scope.model[`question${index}`]) {
            $scope.model[`question${index}`] = moment($scope.model[`question${index}`]).format('MM-DD-YYYY');
          }
          const answer = {
            questionnaire_id: $scope.activeQuestionnaire.id,
            question_id: $scope.questions[index].id,
            answer: $scope.model[`question${index}`],
            org_id: $scope.rideDetails.org_id,
            user_id: loggedInUserDetails.user_id,
            question: $scope.questions[index].text,
            questionnaire_version: $scope.activeQuestionnaire.version,
          };
          if ($scope.questions[index].answerId) {
            answer.id = $scope.questions[index].answerId;
          }
          answers.push(angular.copy(answer));
        }
        return answers;
      };

      const deleteRouteLatLong = function deleteRouteLatLong(rideDetails) {
        if (rideDetails.route_lat_long) {
          Object.assign(rideDetails, { route_lat_long: undefined });
        }
        if (rideDetails.legs && rideDetails.legs.length) {
          rideDetails.legs.forEach((singleLeg) => {
            if (singleLeg.route_lat_long) {
              Object.assign(singleLeg, { route_lat_long: undefined });
            }
          });
        }
      };

      function getSourceAndDestAddress(
        sourceAddress,
        destAddress,
        sourceAddressPincode,
        destAddressPincode,
      ) {
        let sAddress = sourceAddress;
        let dAddress = destAddress;
        // in case user types same address again in case of reschedule appointment,pincode goes twice in database
        if (
          angular.isDefined(sourceAddressPincode)
          && sourceAddress.indexOf(sourceAddressPincode) === -1
        ) {
          sAddress = `${sourceAddress}, ${sourceAddressPincode}`;
        }

        if (
          angular.isDefined(destAddressPincode)
          && destAddress.indexOf(destAddressPincode) === -1
        ) {
          dAddress = `${destAddress}, ${destAddressPincode}`;
        }
        return {
          dest_address: dAddress,
          source_address: sAddress,
        };
      }
      const scrollToTop = function scrollToTop() {
        TimerRemovers.push(
          $timeout(() => {
            $('#current-app').animate(
              {
                scrollTop: 0,
              },
              1000,
            );
          }, 500),
        );
      };
      const rescheduleAppointment = function rescheduleAppointment(l, ignoreWarnings) {
        const rideDetails = angular.copy($scope.rideDetails);
        if (rideDetails.patient) {
          rideDetails.patient.phone = angular.element('#txtPhoneNo').intlTelInput(
            'getNumber',
            window.intlTelInputUtils.numberFormat.E164,
          );
        }
        rideDetails.ignoreWarning = !ignoreWarnings ? false : ignoreWarnings.mileage;
        rideDetails.ignoreAddressWarning = !ignoreWarnings ? false : ignoreWarnings.address;

        if ($scope.activeQuestionnaire) {
          rideDetails.answers = prepareAnswers();
        }

        const dateTime = getAppointmentDateAndTime(
          rideDetails.date,
          rideDetails.time,
        );
        rideDetails.date = dateTime.date;
        rideDetails.time = dateTime.time;

        for (let index = 0; index < rideDetails.legs.length; index += 1) {
          if (
            rideDetails.legs[index].source_lat_long.length === 0
            || rideDetails.legs[index].dest_lat_long.length === 0
          ) {
            toaster.error(
              'Invalid Source/Destination address. Please reset source and destination addresses',
            );
            l.stop();
            return;
          }
          const objAddress = getSourceAndDestAddress(
            rideDetails.legs[index].source_address,
            rideDetails.legs[index].dest_address,
            rideDetails.legs[index].sourceAddressPincode,
            rideDetails.legs[index].destAddressPincode,
          );
          rideDetails.legs[index].source_address = objAddress.source_address;
          if (!rideDetails.legs[index].source_address_json) {
            (rideDetails.legs[index].source_address_json = {});
          }
          rideDetails.legs[index].source_address_json.zip = rideDetails.legs[index].sourceAddressPincode;
          rideDetails.legs[index].dest_address = objAddress.dest_address;
          if (!rideDetails.legs[index].destination_address_json) {
            (rideDetails.legs[index].destination_address_json = {});
          }
          rideDetails.legs[index].destination_address_json.zip = rideDetails.legs[index].destAddressPincode;
        }

        if (
          $scope.language.languageSelected
          && rideDetails.appointment
          && rideDetails.appointment.patient
        ) {
          rideDetails.language = $scope.language.languageSelected;
          rideDetails.appointment.patient.language = $scope.language.languages[$scope.language.languageSelected];
        }

        rideDetails.provider_id = selectedProduct.provider_id;
        rideDetails.product_name = selectedProduct.display_name || selectedProduct.product_name;
        rideDetails.check_potential = $scope.appointmentForm.$dirty;

        const recurrenceDataTemp = angular.copy(rideDetails.recurringData);
        delete rideDetails.recurringData;

        if ($scope.recurrenceAction === 'single') {
          rideDetails.recurrence_edit_type = 'single';
        } else if ($scope.recurrenceAction === 'series') {
          rideDetails.recurrence_edit_type = 'series';
          rideDetails.recurrence_id = rideDetails.appointment.recurrence_id;
          delete rideDetails.book_cab_time;
          const recurrenceDateTime = getRecurrenceAppointmentDateAndTime(
            recurrenceDataTemp.startBy,
            recurrenceDataTemp.endBy,
            rideDetails.recurringTime,
          );
          if (recurrenceDataTemp.pattern === 'monthly') {
            recurrenceDataTemp.interval = recurrenceDataTemp.monthlyInterval;
            if ($scope.isMonthlyDate.value === 'true') {
              recurrenceDataTemp.days = [];
              recurrenceDataTemp.daysOccurenceNumber = 0;
            }
          }
          rideDetails.recurringData = {
            description: recurrenceDataTemp.description,
            time: recurrenceDateTime.time,
            pattern: recurrenceDataTemp.pattern,
            days: recurrenceDataTemp.days,
            interval: recurrenceDataTemp.interval,
            startBy: recurrenceDateTime.startBy,
            endBy: recurrenceDateTime.endBy,
            daysOccurenceNumber: recurrenceDataTemp.daysOccurenceNumber,
          };
        } else {
          // do nothing
        }

        delete rideDetails.recurringTime;
        if ($scope.bookButtonText === 'Keep Trip') {
          rideDetails.keep_trip = true;
        }

        if ($scope.showClaimInformation) {
          if ($scope.billingDetails.selectedBillingOption === 'future_lookup') {
            rideDetails.claimInformation = {
              date_of_injury: moment(
                $scope.claimInformation.date_of_injury,
              ).format('MM-DD-YYYY'),
              date_of_birth: moment(
                $scope.claimInformation.date_of_birth,
              ).format('MM-DD-YYYY'),
              payer_id: $scope.billingDetails.selectedPayerName,
              claimant_home_address: $scope.claimInformation.adjuster_home_address,
              claim_appointment_type:
                $scope.claimInformation.claim_appointment_type,
            };
          } else {
            rideDetails.claimInformation = {
              first_name: $scope.claimInformation.adjuster_first_name,
              last_name: $scope.claimInformation.adjuster_last_name,
              email: $scope.claimInformation.adjuster_email_address,
              claim_number: $scope.claimInformation.claim_number,
              vendor_code: $scope.claimInformation.vendor_code,
              date_of_injury: moment(
                $scope.claimInformation.date_of_injury,
              ).format('MM-DD-YYYY'),
              payer_id: $scope.billingDetails.selectedPayerName,
              claim_state: $scope.claimInformation.claim_state,
              injury_state: $scope.claimInformation.injury_state,
              claimant_home_address: $scope.claimInformation.adjuster_home_address,
              employer: $scope.claimInformation.adjuster_employer_name,
              claim_appointment_type: $scope.claimInformation.claim_appointment_type,
              dynamite_claims_id: $scope.claimInformation.dynamite_claims_id,
            };
          }

          rideDetails.claimInformation.legs = [];
          for (let index = 0; index < rideDetails.legs.length; index += 1) {
            rideDetails.claimInformation.legs.push({
              leg_pick_up_type: $scope.claimInformation.leg_pick_up_type[index],
              leg_destination_type:
                $scope.claimInformation.leg_destination_type[index],
              destination_address_name:
                $scope.claimInformation.destination_address_name[index],
            });
          }
        }

        rideDetails.cost_token = selectedProduct
          ? selectedProduct.cost_token
          : '';
        deleteRouteLatLong(rideDetails);

        const request = $http.put(
          `${API_BASE_URL}ride/edit/${rideId}`,
          rideDetails,
        );
        loadingScreenFactory.showLoadingScreen();
        request.then(({ data: response }) => {
          if (response.success) {
            loadingScreenFactory.hideLoadingScreen();
            l.stop();
            if ($scope.recurrenceAction === 'single') {
              rideDetails.recurrence_edit_type = 'single';
            }

            if (
              response.recurrence_id > 0
              && $scope.recurrenceAction === 'series'
            ) {
              $scope.recurrenceWaitDialogEdit = ngDialog.open({
                template: 'ngDialogTemplateEdit',
                className: 'ngdialog-theme-default',
                scope: $scope,
                showClose: false,
                closeByEscape: false,
                closeByNavigation: false,
                closeByDocument: false,
                name: 'recurrenceWaitDialogEdit',
              });

              $scope.recurrenceWaitIDEdit = response.recurrence_id;

              $scope.recurrencePollingProcessEdit = setTimeout(() => {
                $scope.recurrencePollingNowEdit = setInterval(() => {
                  const pollingRequest = $http.get(
                    `${API_BASE_URL}recurring-rides/${
                      response.recurrence_id
                    }?fields=all`,
                  );
                  pollingRequest.then(({ data: result }) => {
                    if (result.success) {
                      if (
                        result.recurrence
                        && result.recurrence.status === 'success'
                      ) {
                        $scope.recurrenceWaitDialogEdit.close({
                          status: 'success',
                        });
                      } else if (
                        result.recurrence
                        && result.recurrence.status === 'edit-failed'
                      ) {
                        $scope.recurrenceWaitDialogEdit.close({
                          status: 'edit-failed',
                          message: result.recurrence.failedMessage
                            ? result.recurrence.failedMessage
                            : 'Recurrence trip update failed',
                        });
                      } else {
                        //
                      }
                    }
                  });

                  pollingRequest.catch(() => {
                    toaster.info(
                      'Waiting for the response. This may take a while.',
                    );
                  });
                }, 5000);
              }, 15000);

              $scope.recurrenceWaitDialogEdit.closePromise.then((data) => {
                if ($scope.recurrencePollingProcessEdit) {
                  clearTimeout($scope.recurrencePollingProcessEdit);
                }
                if ($scope.recurrencePollingNowEdit) {
                  clearInterval($scope.recurrencePollingNowEdit);
                }

                $scope.recurrenceWaitIDEdit = null;
                if (data.value.status && data.value.status === 'success') {
                  SweetAlert.swal(
                    {
                      title: 'Recurrence trip updated',
                      text: 'Recurrence trip updated successfully',
                      type: 'success',
                      confirmButtonColor: '#DD6B55',
                      confirmButtonText: 'OK',
                    },
                    (isConfirm) => {
                      if (isConfirm) {
                        // if ($stateParams.caller == 'AR') {
                        //     $state.go("main.dashboard.active")
                        // } else if ($stateParams.caller == 'Book now' || $stateParams.caller == 'dashboard') {
                        //     $state.go("main.dashboard.superAdminDashboard")
                        // } else {
                        $state.go('main.dashboard.scheduled');
                        // }
                      }
                    },
                  );
                } else if (
                  data.value.status
                  && data.value.status === 'edit-failed'
                ) {
                  SweetAlert.swal(
                    {
                      title: 'Recurrence trip update failed',
                      html: true,
                      text: `<pre>${
                        data.value.message
                          ? data.value.message
                          : 'Recurrence trip update failed'
                      }</pre>`,
                      type: 'error',
                      confirmButtonColor: '#DD6B55',
                      confirmButtonText: 'OK',
                    },
                    (isConfirm) => {
                      if (isConfirm) {
                        // if ($stateParams.caller == 'AR') {
                        //     $state.go("main.dashboard.active")
                        // } else if ($stateParams.caller == 'Book now' || $stateParams.caller == 'dashboard') {
                        //     $state.go("main.dashboard.superAdminDashboard")
                        // } else {
                        // $state.go("main.dashboard.scheduled")
                        // }
                      }
                    },
                  );
                } else {
                  SweetAlert.swal(
                    {
                      title: 'Error in getting status',
                      text: data.value.message
                        ? data.value.message
                        : 'Not able to fetch recurrence status',
                      type: 'error',
                      confirmButtonColor: '#DD6B55',
                      confirmButtonText: 'OK',
                    },
                    (isConfirm) => {
                      if (isConfirm) {
                        // if ($stateParams.caller == 'AR') {
                        //     $state.go("main.dashboard.active")
                        // } else if ($stateParams.caller == 'Book now' || $stateParams.caller == 'dashboard') {
                        //     $state.go("main.dashboard.superAdminDashboard")
                        // } else {
                        // $state.go("main.dashboard.scheduled")
                        // }
                      }
                    },
                  );
                }
              });

              // here creates interval after which it check for status // TODO JASPREET
            } else {
              SweetAlert.swal(
                {
                  title:
                    $scope.recurrenceAction === 'single'
                      ? 'Trip Updated'
                      : 'Updated',
                  text: 'Trip updated successfully',
                  type: 'success',
                  confirmButtonColor: '#DD6B55',
                  confirmButtonText: 'OK',
                },
                () => {
                  if ($stateParams.caller === 'AR') {
                    $state.go('main.dashboard.active');
                  } else if (
                    $stateParams.caller === 'Book now'
                    || $stateParams.caller === 'dashboard'
                  ) {
                    $state.go('main.dashboard.superAdminDashboard');
                  } else {
                    $state.go('main.dashboard.scheduled');
                  }
                },
              );
            }
          } else {
            toaster.error(response.message);
            loadingScreenFactory.hideLoadingScreen();
            l.stop();
          }
        });

        request.catch(({ data: response }) => {
          if (response && response.type === 'warning') {
            SweetAlert.swal(
              {
                title: 'Warning',
                text: response.message,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                closeOnConfirm: true,
                confirmButtonColor: '#DD6B55',
                cancelButtonText: 'No',
              },
              (isConfirm) => {
                if (isConfirm) {
                  l.stop();
                  if (response.validationType === 'ORG_ADDRESS')  ignoreWarningType.address = true;
                  if (response.validationType === 'MILEAGE_LIMIT') ignoreWarningType.mileage = true;
                  rescheduleAppointment(l, ignoreWarningType);
                } else {
                  l.stop();
                  scrollToTop();
                }
              },
            );
          } else if (response && response.type === 'error') {
            SweetAlert.swal(
              {
                title: 'Error',
                text: response.message,
                type: 'error',
                showCancelButton: response.validationType === 'MILEAGE_LIMIT',
                confirmButtonText: response.validationType === 'MILEAGE_LIMIT' ? 'Edit' : 'OK',
                closeOnConfirm: true,
                confirmButtonColor: '#DD6B55',
                cancelButtonText: 'Cancel',
              },
              (isConfirm) => {
                if (isConfirm) {
                  l.stop();
                  scrollToTop();
                } else {
                  $state.go('main.dashboard.createAppointment', { rideId }, { reload: true });
                }
              },
            );
          } else if (response) {
            SweetAlert.swal(
              {
                title: 'Error',
                text: response.message,
                type: 'error',
                confirmButtonText: 'Edit',
                closeOnConfirm: true,
                confirmButtonColor: '#DD6B55',
              },
              (isConfirm) => {
                if (isConfirm) {
                  scrollToTop();
                }
              },
            );
          }
          loadingScreenFactory.hideLoadingScreen();
          toaster.clear();
          l.stop();
        });
      };
      $scope.checkNonNumeric = function checkNonNumeric() {
        const fileNo = $('#file_no').val();
        if (!/^[\d-]+$/.test(fileNo) && fileNo.length) {
          $scope.containsNonNumericChars = true;
        } else {
          $scope.containsNonNumericChars = false;
        }
      };

      $scope.recurrenceTripUpdate = function recurrenceTripUpdate() {
        if ($scope.recurrenceAction === 'single') {
          SweetAlert.swal(
            {
              title: 'Info',
              text:
                'Only the changes made for this trip will be updated. All other recurrences in this series will remain as currently scheduled.',
              type: 'info',
              showCancelButton: true,
              confirmButtonText: 'OK',
              closeOnConfirm: true,
              confirmButtonColor: '#DD6B55',
              cancelButtonText: 'Cancel',
            },
            (isConfirm) => {
              if (isConfirm) {
                $scope.checkParentOrgSettings();
              }
            },
          );
        } else if ($scope.recurrenceAction === 'series') {
          SweetAlert.swal(
            {
              title: 'Info',
              text: 'All trips will be updated',
              type: 'info',
              showCancelButton: true,
              confirmButtonText: 'OK',
              closeOnConfirm: true,
              confirmButtonColor: '#DD6B55',
              cancelButtonText: 'Cancel',
            },
            (isConfirm) => {
              if (isConfirm) {
                $scope.checkParentOrgSettings();
              }
            },
          );
        } else {
          $scope.checkParentOrgSettings();
        }
      };

      const openNotesPopup = function openNotesPopup(rideDetails) {
        const notesModal = $uibModal.open({
          animation: true,
          template: require('../../views/notes.html'),
          controller: 'notesCtrl',
          controllerAs: '$notesCtrl',
          size: 'md',
          backdrop: 'static',
          resolve: {
            data() {
              return {
                cta: 'Book Another Ride',
                heading: 'Book Another Ride',
                confirmationLine: 'Are you sure you want to Book Another Ride ?',
              };
            },
          },
        });
        return new Promise((resolve, reject) => {
          notesModal.result.then((notes) => {
            if (notes) {
              Object.assign(rideDetails, { comments: notes });
              resolve();
            }
          }, () => { reject(); });
        });
      };

      const confirmAppointment = async function confirmAppointment(l, ignoreWarnings) {
        if (
          $scope.paymentMethods
          && !$scope.paymentMethods.organisation.creditcard
          && !$scope.paymentMethods.organisation.invoicesEnabled
          && !$stateParams.bookAnotherId
        ) {
          const url = $state.href('main.userMgmt.manageOrg', {
            orgId: $scope.rideDetails.org_id,
            caller: 'PM',
          });
          let bodyHtml;
          if (
            loggedInUserDetails.org_id
            && loggedInUserDetails.org_id === $scope.rideDetails.org_id
          ) {
            bodyHtml = `<a style='margin-top: 5px;width: 100 %;text-align:center; float: left;' href='${url}'>Click here to Add Payment method</a>`;
          }

          toaster.pop({
            type: 'error',
            title:
              'No valid payment method found for organization. Please add payment method before creating a ride.',
            body: bodyHtml,
            bodyOutputType: 'trustedHtml',
            showCloseButton: true,
            tapToDismiss: false,
            timeout: 10000,
          });
          l.stop();
          return;
        }
        if ($scope.rideDetails.appointment
          && $scope.rideDetails.appointment.organisation.org_type === 'mas'
          && $stateParams.bookAnotherId
          && $scope.rideDetails.ride_status === rideStatuses.VARIANCE_ISSUE.status) {
          try {
            await openNotesPopup($scope.rideDetails);
          } catch {
            l.stop();
            return;
          }
        }
        const rideDetails = angular.copy($scope.rideDetails);
        rideDetails.ignoreWarning =  !ignoreWarnings ? false : ignoreWarnings.mileage;
        rideDetails.ignoreAddressWarning = !ignoreWarnings ? false : ignoreWarnings.address;
        if ($scope.activeQuestionnaire) {
          rideDetails.answers = prepareAnswers();
        }
        rideDetails.patient = angular.copy($scope.patient);
        rideDetails.patient.first_name = (rideDetails.patient.first_name || '').replace(/'/g, "''");
        rideDetails.patient.last_name = (rideDetails.patient.last_name || '').replace(/'/g, "''");
        if (
          $scope.ride_invoice_history
          && ($scope.patientInvoiceHistory.allocatedAmount
            || $scope.patientInvoiceHistory.allocatedAmount === 0)
        ) {
          if (parseFloat($scope.patientInvoiceHistory.allocatedAmount) < 0) {
            toaster.pop({
              type: 'error',
              title: 'Only numbers are allowed in Relay Ride Allocation',
              showCloseButton: true,
              timeout: 10000,
            });
            l.stop();
            return;
          }
          rideDetails.patient.allocatedAmount = $scope.patientInvoiceHistory.allocatedAmount;
        }

        rideDetails.provider_id = selectedProduct.provider_id;
        rideDetails.product_name = selectedProduct.display_name || selectedProduct.product_name;
        rideDetails.cost_token = selectedProduct
          ? selectedProduct.cost_token
          : '';
        if ($scope.language.languageSelected) {
          rideDetails.language = $scope.language.languageSelected;
        }

        const dateTime = getAppointmentDateAndTime(
          rideDetails.date,
          rideDetails.time,
        );
        rideDetails.date = dateTime.date;
        rideDetails.time = dateTime.time;

        if (
          $scope.paymentMethods
          && $scope.paymentMethods.organisation.invoicesEnabled
        ) {
          rideDetails.paymentMethod = 'invoice';
        } else if (
          $scope.paymentMethods
          && $scope.paymentMethods.organisation.creditcard
        ) {
          rideDetails.paymentMethod = $scope.paymentMethods.organisation.creditcard;
        } else if (
          $scope.paymentMethods
          && $scope.paymentMethods.user
          && $scope.paymentMethods.user.creditcard
        ) {
          rideDetails.paymentMethod = $scope.paymentMethods.user.creditcard;
        } else {
          toaster.error(
            'No valid payment methods on file, please add a credit card',
          );
        }

        // adding RecurrenceData if trip type is recurrence - start
        const recurrenceDataTemp = angular.copy(rideDetails.recurringData);
        delete rideDetails.recurringData;

        if ($scope.appointmentTime.time === 'RECURRENCE_TRIP') {
          // validations of recurrenceDataTemp here - TODO JASPREET
          const recurrenceDateTime = getRecurrenceAppointmentDateAndTime(
            recurrenceDataTemp.startBy,
            recurrenceDataTemp.endBy,
            rideDetails.recurringTime,
          );
          if (recurrenceDataTemp.pattern === 'monthly') {
            recurrenceDataTemp.interval = recurrenceDataTemp.monthlyInterval;
            if ($scope.isMonthlyDate.value === 'true') {
              recurrenceDataTemp.days = [];
              recurrenceDataTemp.daysOccurenceNumber = 0;
            }
          }
          rideDetails.recurringData = {
            description: recurrenceDataTemp.description,
            time: recurrenceDateTime.time,
            pattern: recurrenceDataTemp.pattern,
            days: recurrenceDataTemp.days,
            interval: recurrenceDataTemp.interval,
            startBy: recurrenceDateTime.startBy,
            endBy: recurrenceDateTime.endBy,
            daysOccurenceNumber: recurrenceDataTemp.daysOccurenceNumber,
          };
        }

        delete rideDetails.recurringTime;
        // adding RecurrenceData if trip type is recurrence - end
        rideDetails.role = $scope.userDetails.role;
        rideDetails.legs.forEach((value, index) => {
          if (
            rideDetails.legs[index].source_lat_long.length === 0
            || rideDetails.legs[index].dest_lat_long.length === 0
          ) {
            toaster.error(
              'Invalid Source/Destination address. Please reset source and destination addresses',
            );
            // Re-enable confirm/book appointment button
            l.stop();
            return;
          }
          const objAddress = getSourceAndDestAddress(
            rideDetails.legs[index].source_address,
            rideDetails.legs[index].dest_address,
            rideDetails.legs[index].sourceAddressPincode,
            rideDetails.legs[index].destAddressPincode,
          );
          rideDetails.legs[index].source_address = objAddress.source_address;
          rideDetails.legs[index].dest_address = objAddress.dest_address;
        });

        if (
          angular.isDefined(rideDetails.patient)
          && !rideDetails.patient_id
        ) {
          rideDetails.patient.phone = $('#txtPhoneNo').intlTelInput(
            'getNumber',
            window.intlTelInputUtils.numberFormat.E164,
          );
        }

        if ($scope.language.languageSelected && rideDetails.patient) {
          rideDetails.language = $scope.language.languageSelected;
          rideDetails.patient.language = $scope.language.languages[$scope.language.languageSelected];
        }

        if ($scope.appointmentTime.time === 'BOOK_NOW') {
          rideDetails.cost_token_redis_key = $scope.cost_token_redis_key;
          rideDetails.override_cost_token = $scope.override_cost_token;
        }
        deleteRouteLatLong(rideDetails);
        // Claim Information gathering here - start
        rideDetails.billing_type = $scope.billingDetails.selectedBillingOption;
        if ($scope.showClaimInformation) {
          if ($scope.billingDetails.selectedBillingOption === 'future_lookup') {
            rideDetails.claimInformation = {
              date_of_injury: moment($scope.claimInformation.date_of_injury).format('MM-DD-YYYY'),
              date_of_birth: moment($scope.claimInformation.date_of_birth).format('MM-DD-YYYY'),
              payer_id: $scope.billingDetails.selectedPayerName,
              claimant_home_address: $scope.claimInformation.adjuster_home_address,
              claim_appointment_type: $scope.claimInformation.claim_appointment_type,
            };
          } else {
            rideDetails.claimInformation = {
              first_name: $scope.claimInformation.adjuster_first_name,
              last_name: $scope.claimInformation.adjuster_last_name,
              email: $scope.claimInformation.adjuster_email_address,
              claim_number: $scope.claimInformation.claim_number,
              vendor_code: $scope.claimInformation.vendor_code,
              date_of_injury: moment($scope.claimInformation.date_of_injury).format('MM-DD-YYYY'),
              payer_id: $scope.billingDetails.selectedPayerName,
              claim_state: $scope.claimInformation.claim_state,
              injury_state: $scope.claimInformation.injury_state,
              claimant_home_address: $scope.claimInformation.adjuster_home_address,
              employer: $scope.claimInformation.adjuster_employer_name,
              claim_appointment_type: $scope.claimInformation.claim_appointment_type,
            };
          }

          rideDetails.claimInformation.legs = [];
          for (let index = 0; index < rideDetails.legs.length; index += 1) {
            rideDetails.claimInformation.legs.push({
              leg_pick_up_type: $scope.claimInformation.leg_pick_up_type[index],
              leg_destination_type:
                $scope.claimInformation.leg_destination_type[index],
              destination_address_name:
                $scope.claimInformation.destination_address_name[index],
            });
          }
        }
        // Claim Information gathering here - end

        loadingScreenFactory.showLoadingScreen();
        let addurl = `${API_BASE_URL}ride/add/${$scope.rideDetails.org_id}`;
        if (
          $scope.rideDetails.sent_by_fuse
          && $scope.rideDetails.appointment
          && $scope.rideDetails.appointment.client_id
        ) {
          addurl += `/${$scope.rideDetails.appointment.client_id}`;
        }
        const request = $http.post(addurl, rideDetails, {
          params: {
            bookAnotherId: $stateParams.bookAnotherId,
          },
        });
        request.then(({ data: response }) => {
          if (response.success) {
            loadingScreenFactory.hideLoadingScreen();
            // Re-enable confirm/book appointment button
            l.stop();

            if (response.recurrence_id > 0) {
              $scope.recurrenceWaitDialog = ngDialog.open({
                template: 'ngDialogTemplate',
                className: 'ngdialog-theme-default',
                scope: $scope,
                showClose: false,
                closeByEscape: false,
                closeByNavigation: false,
                closeByDocument: false,
                name: 'recurrenceWaitDialog',
              });

              $scope.recurrenceWaitID = response.recurrence_id;

              $scope.recurrencePollingProcess = setTimeout(() => {
                $scope.recurrencePollingNow = setInterval(() => {
                  const pollingRequest = $http.get(
                    `${API_BASE_URL}recurring-rides/${
                      response.recurrence_id
                    }?fields=all`,
                  );
                  pollingRequest.then(({ data: result }) => {
                    if (result.success) {
                      if (
                        result.recurrence
                        && result.recurrence.status === 'success'
                      ) {
                        $scope.recurrenceWaitDialog.close({
                          status: 'success',
                        });
                      } else if (
                        result.recurrence
                        && result.recurrence.status === 'failed'
                      ) {
                        $scope.recurrenceWaitDialog.close({
                          status: 'failed',
                          message: result.recurrence.failedMessage
                            ? result.recurrence.failedMessage
                            : 'Recurrence trip booking failed',
                          type: result.recurrence.failedType,
                          validationType: result.recurrence.validationType,
                        });
                      }
                    }
                  });

                  pollingRequest.catch(() => {
                    toaster.info(
                      'Waiting for the response. This may take a while.',
                    );
                  });
                }, 5000);
              }, 15000);

              $scope.recurrenceWaitDialog.closePromise.then((data) => {
                if ($scope.recurrencePollingProcess) {
                  clearTimeout($scope.recurrencePollingProcess);
                }
                if ($scope.recurrencePollingNow) {
                  clearInterval($scope.recurrencePollingNow);
                }

                $scope.recurrenceWaitID = null;

                if (data.value.status === 'success') {
                  SweetAlert.swal(
                    {
                      title: 'Recurrence trip booked',
                      text: 'Recurrence trip booked successfully',
                      type: 'success',
                      confirmButtonColor: '#DD6B55',
                      confirmButtonText: 'OK',
                    },
                    (isConfirm) => {
                      if (isConfirm) {
                        // if ($stateParams.caller === 'AR') {
                        //     $state.go("main.dashboard.active")
                        // } else if ($stateParams.caller === 'Book now' || $stateParams.caller === 'dashboard') {
                        //     $state.go("main.dashboard.superAdminDashboard")
                        // } else {
                        $state.go('main.dashboard.scheduled');
                        // }
                      }
                    },
                  );
                } else if (data.value.status === 'failed') {
                  if (data.value.type === 'warning') {
                    SweetAlert.swal(
                      {
                        title: 'Warning',
                        text: data.value.message,
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        closeOnConfirm: true,
                        confirmButtonColor: '#DD6B55',
                        cancelButtonText: 'No',
                      },
                      (isConfirm) => {
                        if (isConfirm) {
                          if (data.value.validationType === 'ORG_ADDRESS') ignoreWarningType.address = true;
                          if (data.value.validationType === 'MILEAGE_LIMIT') ignoreWarningType.mileage = true;
                          confirmAppointment(l, ignoreWarningType);
                        } else {
                          l.stop();
                          scrollToTop();
                        }
                      },
                    );
                  } else if (data.value.type === 'error') {
                    SweetAlert.swal(
                      {
                        title: 'Error',
                        text: data.value.message,
                        type: 'error',
                        showCancelButton: data.value.validationType === 'MILEAGE_LIMIT',
                        confirmButtonText: data.value.validationType === 'ORG_ADDRESS' ? 'OK' : 'Edit',
                        closeOnConfirm: true,
                        confirmButtonColor: '#DD6B55',
                        cancelButtonText: 'Cancel',
                      },
                      (isConfirm) => {
                        if (isConfirm) {
                          l.stop();
                        } else {
                          $scope.resetForm();
                        }
                        scrollToTop();
                      },
                    );
                  } else {
                    SweetAlert.swal(
                      {
                        title: 'Recurrence trip booking failed',
                        text: data.value.message
                          ? data.value.message
                          : 'Recurrence trip booking failed',
                        type: 'error',
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: 'OK',
                      },
                      (isConfirm) => {
                        if (isConfirm) {
                          // if ($stateParams.caller === 'AR') {
                          //     $state.go("main.dashboard.active")
                          // } else if ($stateParams.caller === 'Book now' || $stateParams.caller === 'dashboard') {
                          //     $state.go("main.dashboard.superAdminDashboard")
                          // } else {
                          // $state.go("main.dashboard.scheduled")
                          // }
                        }
                      },
                    );
                  }
                } else {
                  SweetAlert.swal(
                    {
                      title: 'Error in getting status',
                      text: data.value.message
                        ? data.value.message
                        : 'Not able to fetch recurrence status',
                      type: 'error',
                      confirmButtonColor: '#DD6B55',
                      confirmButtonText: 'OK',
                    },
                    (isConfirm) => {
                      if (isConfirm) {
                        // if ($stateParams.caller === 'AR') {
                        //     $state.go("main.dashboard.active")
                        // } else if ($stateParams.caller === 'Book now' || $stateParams.caller === 'dashboard') {
                        //     $state.go("main.dashboard.superAdminDashboard")
                        // } else {
                        // $state.go("main.dashboard.scheduled")
                        // }
                      }
                    },
                  );
                }
              });

              // here creates interval after which it check for status // TODO JASPREET
            } else {
              SweetAlert.swal(
                {
                  title:
                    $scope.appointmentTime.time === 'BOOK_NOW'
                      ? 'Ride booked'
                      : 'Trip booked',
                  text:
                    $scope.appointmentTime.time === 'BOOK_NOW'
                      ? 'Ride booked successfully'
                      : 'Trip booked successfully',
                  type: 'success',
                  confirmButtonColor: '#DD6B55',
                  confirmButtonText: 'OK',
                },
                (isConfirm) => {
                  if (isConfirm) {
                    if ($stateParams.caller === 'AR') {
                      $state.go('main.dashboard.active');
                    } else if (
                      $stateParams.caller === 'Book now'
                      || $stateParams.caller === 'dashboard'
                    ) {
                      $state.go('main.dashboard.superAdminDashboard');
                    } else {
                      $state.go('main.dashboard.scheduled');
                    }
                  }
                },
              );
            }
          }
        });

        request.catch(({ data: response }) => {
          loadingScreenFactory.hideLoadingScreen();
          if (response && response.type === 'warning') {
            SweetAlert.swal(
              {
                title: 'Warning',
                text: response.message,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                closeOnConfirm: true,
                confirmButtonColor: '#DD6B55',
                cancelButtonText: 'No',
              },
              (isConfirm) => {
                if (isConfirm) {
                  if (response.validationType === 'ORG_ADDRESS') ignoreWarningType.address = true;
                  if (response.validationType === 'MILEAGE_LIMIT') ignoreWarningType.mileage = true;
                  confirmAppointment(l, ignoreWarningType);
                } else {
                  l.stop();
                  scrollToTop();
                }
              },
            );
          } else if (response && response.type === 'error') {
            SweetAlert.swal(
              {
                title: 'Error',
                text: response.message,
                type: 'error',
                showCancelButton: response.validationType === 'MILEAGE_LIMIT',
                confirmButtonText: response.validationType === 'MILEAGE_LIMIT' ? 'Edit' : 'OK',
                closeOnConfirm: true,
                confirmButtonColor: '#DD6B55',
                cancelButtonText: 'Cancel',
              },
              (isConfirm) => {
                if (isConfirm) {
                  l.stop();
                } else if ($stateParams.bookAnotherId) {
                  $state.go('main.dashboard.createAppointment', { bookAnotherId: $stateParams.bookAnotherId }, { reload: true });
                } else {
                  $scope.showRiderDetails = true;
                  $scope.resetForm();
                }
                scrollToTop();
              },
            );
          } else if (response && response.message && !response.get_estimates_again) {
            toaster.error(response.message);
          } else {
            SweetAlert.swal(
              {
                title: 'Ride Cost Changed',
                text: response.message,
                type: 'info',
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes',
                showCancelButton: true,
                cancelButtonText: 'Get new cost',
              },
              (isConfirm) => {
                if (isConfirm) {
                  const la = Ladda.create(
                    document.querySelector('.confirm-appointment-button'),
                  );
                  la.start();
                  $scope.override_cost_token = true;
                  confirmAppointment(la);
                } else {
                  $scope.getProducts();
                }
              },
            );
          }
          // Re-enable confirm/book appointment button
          l.stop();
        });
      };

      // function to check parent settings and then showing popup in case distance exceeds 25 miles for any leg
      $scope.checkParentOrgSettings = function checkParentOrgSettings() {
        // Create new instance for Ladda (loading buttons)
        const l = Ladda.create(
          document.querySelector('.confirm-appointment-button'),
        );

        // Disable confirm/book appointpent button and show loading icon
        l.start();

        // if no product is selected
        if (!$scope.rideDetails.provider_prod_id) {
          toaster.error('Please select product for ride');
          l.stop();
          return;
        }

        // to check time is selected while scheduling ride
        if ($scope.appointmentTime.time === 'FUTURE_APPOINTMENT') {
          if (!$scope.rideDetails.time || !$scope.rideDetails.trip_time_type) {
            toaster.error('Please select time for scheduling the ride');
            l.stop();
            return;
          }
          $scope.rideDetails.book_now = undefined;
        } else if ($scope.appointmentTime.time === 'RECURRENCE_TRIP') {
          if (
            !$scope.rideDetails.recurringTime
            || !$scope.rideDetails.trip_time_type
          ) {
            toaster.error('Please select time for recurrence trips');
            l.stop();
            return;
          }

          if (
            $scope.rideDetails.recurringData
            && $scope.rideDetails.recurringData.pattern === 'weekly'
          ) {
            if (!$scope.rideDetails.recurringData.interval) {
              toaster.error('Please select valid interval');
              l.stop();
              return;
            }
          }

          $scope.rideDetails.book_now = undefined;
        } else if ($scope.appointmentTime.time === 'BOOK_NOW') {
          $scope.rideDetails.book_now = 1;
        }
        // to check if future claim then home address is required
        if ($scope.showClaimDetailsOf.future_lookup && !$scope.claimInformation.adjuster_home_address) {
          toaster.error('Please select home address');
          l.stop();
          return;
        }
        // to check if billing option is claim_lookup then claim number is there.
        if ($scope.billingDetails.selectedBillingOption === 'claim_lookup') {
          if (!$scope.claimInformation.claim_number || $scope.claimNumberHasError) {
            toaster.error('Claim Number is required.');
            l.stop();
            return;
          }
        }
        const getHierarchyWithAncestorsSettingsCall = $http.get(
          `${API_BASE_URL}organisation/getOrgDetailsById/${
            $scope.rideDetails.org_id
          }`,
        );
        getHierarchyWithAncestorsSettingsCall.then((result) => {
          if (result.data.success) {
            let showDistancePopup = false;
            // if selected org has ancestors
            if (
              result.data.orgDetails.ancestors
              && result.data.orgDetails.ancestors.length
            ) {
              for (
                let ancestorIndex = 0;
                ancestorIndex < result.data.orgDetails.ancestors.length;
                ancestorIndex += 1
              ) {
                const ancestor = result.data.orgDetails.ancestors[ancestorIndex];
                // check only top level parent's settings
                if (
                  ancestor.hierarchy_level === 1
                  && ancestor.settings
                  && ancestor.settings.length
                ) {
                  for (
                    let settingIndex = 0;
                    settingIndex < ancestor.settings.length;
                    settingIndex += 1
                  ) {
                    const orgSetting = ancestor.settings[settingIndex];
                    if (orgSetting.setting_name === 'distanceWarning') {
                      let message = '';
                      for (
                        let legIndex = 0;
                        legIndex < $scope.taxiProducts.basic[0].breakdown.length;
                        legIndex += 1
                      ) {
                        // adding check for active and completed legs, not to be shown in distance popup
                        if (
                          $scope.taxiProducts.basic[0].breakdown[legIndex]
                            .distance > parseInt(orgSetting.setting_value, 10)
                          && $scope.taxiProducts.basic[0].breakdown[legIndex].leg_no
                        ) {
                          message = `${message
                            + $scope.taxiProducts.basic[0].breakdown[legIndex]
                              .display_name}, `;
                        }
                      }
                      // if any ride exceeded set distance and get estame button has been hit
                      if (message && getEstimatesButtonHit) {
                        showDistancePopup = true;
                        message = `Rides : ${message.substr(
                          0,
                          message.length - 2,
                        )} may exceed ${
                          orgSetting.setting_value
                        } miles, would you like to continue?`;
                        SweetAlert.swal(
                          {
                            title: 'Info',
                            text: message,
                            type: 'info',
                            showCancelButton: true,
                            confirmButtonText: 'Yes',
                            closeOnConfirm: true,
                            confirmButtonColor: '#DD6B55',
                            cancelButtonText: 'No',
                          },
                          (isConfirm) => {
                            if (isConfirm) {
                              if ($stateParams.rideId) {
                                rescheduleAppointment(l);
                              } else {
                                confirmAppointment(l);
                              }
                            } else {
                              l.stop();
                            }
                          },
                        );
                      }
                    }
                  }
                }
              }
            }
            if (showDistancePopup === false) {
              if ($stateParams.rideId) {
                rescheduleAppointment(l);
              } else {
                confirmAppointment(l);
              }
            }
          } else {
            toaster.error('Unable to fetch full organisation details');
          }
        });
      };

      $scope.selectProduct = function selectProduct(product) {
        const check = product.selected;
        angular.forEach($scope.taxiProducts, (pt) => {
          Object.assign(pt, { selected: false });
        });
        Object.assign(product, { selected: check });
      };

      $scope.setProduct = function setProduct(product) {
        selectedProduct = product;
      };

      $scope.confirmAppointmentByAdmin = function confirmAppointmentByAdmin() {
        if (
          $scope.rideDetails.provider_prod_id === ''
          || !$scope.rideDetails.provider_prod_id
        ) {
          toaster.error('Please select product for ride');
          return;
        }

        if ($scope.appointmentTime.time === 'FUTURE_APPOINTMENT') {
          if (!$scope.rideDetails.time || !$scope.rideDetails.trip_time_type) {
            toaster.error('Please select time for scheduling the ride');
            return;
          }
          $scope.rideDetails.book_now = undefined;
        } else if ($scope.appointmentTime.time === 'BOOK_NOW') {
          $scope.rideDetails.book_now = 1;
        }

        $scope.rideDetails.provider_id = selectedProduct.provider_id;
        $scope.rideDetails.product_name = selectedProduct.display_name || selectedProduct.product_name;
        const rideDetails = angular.copy($scope.rideDetails);

        if (
          $scope.language.languageSelected
          && rideDetails.appointment
          && rideDetails.appointment.patient
        ) {
          rideDetails.language = $scope.language.languageSelected;
          rideDetails.appointment.patient.language = $scope.language.languages[$scope.language.languageSelected];
        }

        if ($scope.activeQuestionnaire) {
          rideDetails.answers = prepareAnswers();
        }
        const dateTime = getAppointmentDateAndTime(
          rideDetails.date,
          rideDetails.time,
        );
        rideDetails.date = dateTime.date;
        rideDetails.time = dateTime.time;
        rideDetails.cost_token = selectedProduct
          ? selectedProduct.cost_token
          : '';
        rideDetails.legs.forEach((value, index) => {
          if (
            rideDetails.legs[index].source_lat_long.length === 0
            || rideDetails.legs[index].dest_lat_long.length === 0
          ) {
            toaster.error(
              'Invalid Source/Destination address. Please reset source and destination addresses',
            );
            return;
          }
          const objAddress = getSourceAndDestAddress(
            rideDetails.legs[index].source_address,
            rideDetails.legs[index].dest_address,
            rideDetails.legs[index].sourceAddressPincode,
            rideDetails.legs[index].destAddressPincode,
          );
          rideDetails.legs[index].source_address = objAddress.source_address;
          if (!rideDetails.legs[index].source_address_json) {
            (rideDetails.legs[index].source_address_json = {});
          }
          rideDetails.legs[index].source_address_json.zip = rideDetails.legs[index].sourceAddressPincode;
          rideDetails.legs[index].dest_address = objAddress.dest_address;
          if (!rideDetails.legs[index].destination_address_json) {
            (rideDetails.legs[index].destination_address_json = {});
          }
          rideDetails.legs[index].destination_address_json.zip = rideDetails.legs[index].destAddressPincode;
        });

        deleteRouteLatLong(rideDetails);

        const request = $http.put(
          `${API_BASE_URL}ride/edit/${rideId}`,
          rideDetails,
        );
        loadingScreenFactory.showLoadingScreen();
        request.then(({ data: response }) => {
          if (response.success) {
            loadingScreenFactory.hideLoadingScreen();
            SweetAlert.swal(
              {
                title: 'Trip confirmed',
                text: 'Trip confirmed successfully',
                type: 'success',
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'OK',
              },
              (isConfirm) => {
                if (isConfirm) {
                  $state.go('main.dashboard.superAdminDashboard');
                }
              },
            );
          }
        });

        request.catch(({ data: response }) => {
          if (response && response.type === 'warning') {
            SweetAlert.swal(
              {
                title: 'Warning',
                text: response.message,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                closeOnConfirm: true,
                confirmButtonColor: '#DD6B55',
                cancelButtonText: 'No',
              },
              (isConfirm) => {
                if (isConfirm) {
                  const la = Ladda.create(
                    document.querySelector('.confirm-appointment-button'),
                  );
                  la.start();
                  confirmAppointmentByAdmin();
                } else {
                  scrollToTop();
                }
              },
            );
          } else if (response && response.type === 'error') {
            SweetAlert.swal(
              {
                title: 'Error',
                text: response.message,
                type: 'error',
                showCancelButton: true,
                confirmButtonText: 'Edit',
                closeOnConfirm: true,
                confirmButtonColor: '#DD6B55',
                cancelButtonText: 'Cancel',
              },
              (isConfirm) => {
                if (isConfirm) {
                  scrollToTop();
                } else {
                  $scope.resetForm();
                }
              },
            );
          } else if (response) {
            SweetAlert.swal(
              {
                title: 'Error',
                text: response.message,
                type: 'error',
                confirmButtonText: 'Edit',
                closeOnConfirm: true,
                confirmButtonColor: '#DD6B55',
              },
              (isConfirm) => {
                if (isConfirm) {
                  scrollToTop();
                }
              },
            );
          }
          loadingScreenFactory.hideLoadingScreen();
        });
      };

      $scope.resetForm = function resetForm() {
        initializeAppointment();
        initializeTaxiProducts();
        initializePatient();
        $scope.appointmentForm.$setPristine();
        $scope.appointmentForm.$setUntouched();

        if (
          destinationData.marker
          && typeof destinationData.marker.setMap === 'function'
        ) {
          destinationData.marker.setMap(null);
        }

        if (pickupData.marker && typeof pickupData.marker.setMap === 'function') {
          pickupData.marker.setMap(null);
        }
      };

      $scope.setForm = function setForm(form) {
        $scope.appointmentForm = form;
      };

      $scope.getPageName = function getPageName(val) {
        $scope.displayOrgChildren = false;
        if (val === 'create-appointment' && $scope.showChildData()) {
          $scope.displayOrgChildren = true;
        }
      };

      $scope.openCalender = function openCalender(event) {
        event.stopPropagation();
        if (
          $stateParams.bookAnotherId
          || $scope.rideDetails.legs[0].ride_category === 'active'
          || ($scope.rideDetails.legs[0].ride_category === 'issue'
            && $scope.rideDetails.legs[0].ride_status
            !== 'Potential Unavailability')
          || $scope.rideDetails.legs[0].ride_category === 'completed'
        ) {
          return;
        }
        TimerRemovers.push(
          $timeout(() => {
            angular.element('#txtDate').triggerHandler('focus');
          }, 100),
        );
      };

      $scope.openStartCalender = (event) => {
        event.stopPropagation();
        if ($scope.rideDetails.recurringData.pattern === 'monthly') {
          return;
        }
        if (!$scope.rangeAltered) $scope.rangeAltered = true;
        if (
          $scope.startByDisabled
          || $stateParams.bookAnotherId
          || $scope.rideDetails.legs[0].ride_category === 'active'
          || ($scope.rideDetails.legs[0].ride_category === 'issue'
            && $scope.rideDetails.legs[0].ride_status
            !== 'Potential Unavailability')
          || $scope.rideDetails.legs[0].ride_category === 'completed'
        ) {
          return;
        }
        this.toggleDatePicker('#start-date');
      };

      $scope.openEndCalender = (event) => {
        event.stopPropagation();
        if ($scope.rideDetails.recurringData.pattern === 'monthly') {
          return;
        }
        if (!$scope.rangeAltered) $scope.rangeAltered = true;
        if (
          $stateParams.bookAnotherId
          || $scope.rideDetails.legs[0].ride_category === 'active'
          || ($scope.rideDetails.legs[0].ride_category === 'issue'
            && $scope.rideDetails.legs[0].ride_status
            !== 'Potential Unavailability')
          || $scope.rideDetails.legs[0].ride_category === 'completed'
        ) {
          return;
        }
        this.toggleDatePicker('#end-date');
      };

      $scope.closeCalender = function closeCalender() {
        TimerRemovers.push(
          $timeout(() => {
            angular.element('#txtDate').triggerHandler('blur');
            angular.element('#startDate').triggerHandler('blur');
            angular.element('#endDate').triggerHandler('blur');
          }, 100),
        );
      };

      $scope.closeStartCalender = function closeStartCalender(event) {
        event.stopPropagation();
        TimerRemovers.push(
          $timeout(() => {
            angular.element('#startDate').triggerHandler('blur');
          }, 100),
        );
      };
      $scope.closeEndCalender = function closeEndCalender(event) {
        event.stopPropagation();
        TimerRemovers.push(
          $timeout(() => {
            angular.element('#endDate').triggerHandler('blur');
          }, 100),
        );
      };
      $scope.apptTypeChanged = function apptTypeChanged() {
        initializeTaxiProducts();
        if (
          $scope.rideDetails.leg_type === 'round'
          && $scope.rideDetails.legs.length > 2
        ) {
          $scope.rideDetails.legs.length = 2;
        } else if (
          $scope.rideDetails.leg_type === 'round'
          && $scope.rideDetails.legs.length < 2
        ) {
          $scope.rideDetails.legs.push(angular.copy(leg));
        } else if ($scope.rideDetails.leg_type === 'single') {
          $scope.rideDetails.legs.length = 1;
        }
      };

      $scope.cancelAppointment = function cancelAppointment() {
        const params = {
          ride_category: 'completed',
          ride_status: 'Ride Canceled',
          dontupdatedate: true,
        };

        SweetAlert.swal(
          {
            title: 'Cancel?',
            text:
              $scope.recurrenceAction === 'single'
                ? 'Only this trip will be cancelled.'
                : 'Are you sure you want to cancel this trip?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonClass: 'btn-danger',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            closeOnConfirm: false,
            closeOnCancel: true,
          },
          (isConfirm) => {
            if (isConfirm) {
              const request = $http.put(
                `${API_BASE_URL}ride/update/${$scope.rideDetails.legs[0].id}`,
                params,
              );

              loadingScreenFactory.showLoadingScreen();
              request.then(({ data: response }) => {
                if (response.success) {
                  loadingScreenFactory.hideLoadingScreen();
                  SweetAlert.swal(
                    'Cancelled!',
                    'Your trip has been cancelled.',
                    'success',
                  );
                  $state.go('main.dashboard.scheduled');
                }
              });

              request.catch(({ data: response }) => {
                loadingScreenFactory.hideLoadingScreen();
                toaster.error(response.message);
              });
            }
          },
        );
      };

      $scope.togglePremiumReverse = function togglePremiumReverse() {
        $scope.premiumReverse = !$scope.premiumReverse;
      };

      $scope.toggleBasicReverse = function toggleBasicReverse() {
        $scope.basicReverse = !$scope.basicReverse;
      };

      $scope.addLeg = function addLeg() {
        $scope.rideDetails.legs.push(angular.copy(leg));
      };

      $scope.removeLeg = function removeLeg(index) {
        $scope.rideDetails.legs.splice(index, 1);
      };

      $scope.closePage = function closePage() {
        if ($stateParams.caller === 'AR') {
          $state.go('main.dashboard.active');
        } else if (
          $stateParams.caller === 'Book now'
          || $stateParams.caller === 'dashboard'
        ) {
          $state.go('main.dashboard.superAdminDashboard');
        } else {
          $state.go('main.dashboard.scheduled');
        }
      };

      $scope.validatePhoneNumber = () => {
        const phoneNo = $('#txtPhoneNo').intlTelInput('getNumber', window.intlTelInputUtils.numberFormat.E164);
        const isValidPhoneNumber = window.libPhoneNumber.isValidPhoneNumber(phoneNo);
        if (!$scope.appointmentForm) {
          return false;
        }
        if (!phoneNo) {
          if (!$scope.appointmentForm.phoneNo.$error.required) {
            $scope.appointmentForm.phoneNo.$setValidity('invalidPhone', true);
          }
          return false;
        }
        if (isValidPhoneNumber) {
          $scope.appointmentForm.phoneNo.$setValidity('invalidPhone', true);
        } else {
          $scope.appointmentForm.phoneNo.$setValidity('invalidPhone', false);
        }
        return isValidPhoneNumber;
      };

      $scope.validateQuestionnaire = function validateQuestionnaire(
        questionnaireformController,
      ) {
        $scope.submitted = true;
        let isValidForm = true;
        const answers = Object.values($scope.model);
        answers.forEach((value) => { if (!value || !answers.length) { isValidForm = false; } });
        if ($scope.showQuestion) {
          $scope.$broadcast('schemaFormValidate');
          if (questionnaireformController.$valid && isValidForm && $scope.questions.length === answers.length) {
            $scope.activeTab[1] = true;
          }
        } else if (isValidForm && $scope.questions.length === answers.length) {
          $scope.activeTab[1] = true;
        }
      };
      $scope.getPatientInvoiceHistoryWithSettings = function getPatientInvoiceHistoryWithSettings() {
        $scope.patientInvoiceHistory = {
          totalInvoiceAmount: 0,
          allocatedAmount: 0,
        };
        let phoneNo;
        if ($scope.patient.phone && !$scope.rideId && !$scope.bookAnotherId) {
          phoneNo = $('#txtPhoneNo').intlTelInput(
            'getNumber',
            window.intlTelInputUtils.numberFormat.E164,
          );
        } else if ($scope.rideId || $scope.bookAnotherId) {
          phoneNo = $scope.patient.phone;
        }
        if (phoneNo) {
          $timeout(() => {
            $('#txtPhoneNo').trigger('phone-number-changed', $scope.patient.phone);
          });
          const getPatientInvoiceHistoryCall = $http.get(
            `${API_BASE_URL}user/patientInvoiceHistoryWithSettings/${phoneNo}/${parseInt(new Date().getMonth() + 1, 10)}/${new Date().getFullYear()}`,
          );
          getPatientInvoiceHistoryCall.then(
            (result) => {
              if (result.data.success) {
                $scope.patientInvoiceHistory.totalInvoiceAmount = result.data
                  .totalInvoiceAmount
                  ? $scope.round(result.data.totalInvoiceAmount, 2)
                  : result.data.totalInvoiceAmount;
                $scope.patientInvoiceHistory.allocatedAmount = result.data.allocatedAmount;
                $scope.patient.settings = result.data.userSettings;
                if (
                  result.data.allocatedAmount
                  || result.data.allocatedAmount === 0
                ) {
                  $scope.patientInvoiceHistoryBtn = 'Change';
                }
              }
            },
          ).catch((error) => {
            toaster.pop({
              type: 'error',
              title: error.message || 'Error fetching invoice history with settings',
              showCloseButton: true,
              timeout: 10000,
            });
          });
        }
      };

      $scope.updatePatientAllocatedAmount = function updatePatientAllocatedAmount() {
        const phoneNo = $('#txtPhoneNo').intlTelInput(
          'getNumber',
          window.intlTelInputUtils.numberFormat.E164,
        );
        const serverData = {
          allocatedAmount: $scope.patientInvoiceHistory.allocatedAmount,
          phone: phoneNo,
        };
        if (parseFloat($scope.patientInvoiceHistory.allocatedAmount) < 0) {
          toaster.pop({
            type: 'error',
            title: 'Only numbers are allowed',
            showCloseButton: true,
            timeout: 10000,
          });
          return;
        }
        const updatePatientAllocatedAmountCall = $http.put(
          `${API_BASE_URL}user/updatePatientAllocatedAmount`,
          serverData,
        );
        updatePatientAllocatedAmountCall.then(
          (result) => {
            if (result.data.success) {
              toaster.pop({
                type: 'info',
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
            } else {
              toaster.pop({
                type: 'error',
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
            }
          },
          (error) => {
            toaster.pop({
              type: 'error',
              title: error.data ? error.data.message : '',
              showCloseButton: true,
              timeout: 10000,
            });
          },
        );
      };

      $scope.recurringPatternChanged = function recurringPatternChanged(value) {
        checkmonthlyWeekDaysValidity();
        checkMonthlyweekValidity();
        if (value === 'daily') {
          $scope.rideDetails.recurringData.days = angular.copy($scope.days);
          $scope.rideDetails.recurringData.interval = null;
          $scope.recurrenceDaysChange();
        } else if (value === 'weekly') {
          $scope.rideDetails.recurringData.days = [];
          $scope.rideDetails.recurringData.interval = 1;
          $scope.recurrenceDaysChange();
        } else if (value === 'monthly') {
          $scope.monthlyDateInterval = { value: 1 };
          $scope.monthlyDayInterval = { value: 1 };
          $scope.maxMonthlyDayInterval = 1;
          $scope.maxMonthlyDateInterval = 1;
          $scope.recurrenceRangeError.startBy.message = '';
          $scope.selectedDate.date = $scope.selectedDate.date || +moment().format('D');
          $scope.selectedMonthlyDay = ($scope.selectedMonthlyDay && $scope.selectedMonthlyDay.name) ? $scope.selectedMonthlyDay : {};
          $scope.rideDetails.recurringData.days = _.isEmpty($scope.selectedMonthlyDay) ? [] : [$scope.selectedMonthlyDay.name];
          $scope.isMonthlyDate = (_.isEmpty($scope.isMonthlyDate) || $scope.isMonthlyDate.value === 'true') ? { value: 'true' } : { value: 'false' };
          setMonthlyInterval(1);
          $scope.monthlyDayOccurenceNumber = $scope.monthlyDayOccurenceNumber ? $scope.monthlyDayOccurenceNumber : { value: 1, name: 'First' };
          $scope.monthlyDateChanged($scope.selectedDate.date);
        } else {
          toaster.clear();
          toaster.pop({
            type: 'error',
            title: 'Invalid Recurrence Pattern Selected',
            showCloseButton: true,
            timeout: 10000,
          });
        }
        recurrenceRangeChange();
      };

      // this function opens tag list popup
      $scope.savedAddressClicked = function savedAddressClicked(type, index) {
        const getSelectedTagData = function getSelectedTagData(tagData) {
          if (type === 'source') {
            if (
              $scope.rideDetails.leg_type === 'round'
              && $scope.rideDetails.legs.length === 2
              && firstTimeLoad === false
            ) {
              $scope.claimInformation.destination_address_name[1] = tagData.tag;
            }
            $scope.rideDetails.legs[index].source_address = tagData.address;
            $scope.rideDetails.legs[index].source_lat_long = [
              tagData.latitude,
              tagData.longitude,
            ];
          } else {
            $scope.claimInformation.destination_address_name[index] = tagData.tag;
            $scope.rideDetails.legs[index].dest_address = tagData.address;
            $scope.rideDetails.legs[index].dest_lat_long = [
              tagData.latitude,
              tagData.longitude,
            ];
          }
          $scope.setLocationOnMap(
            type,
            index,
            [parseFloat(tagData.latitude), parseFloat(tagData.longitude)],
            false,
            true,
          );
        };

        const openTagListPopup = function openTagListPopup(userId, orgId) {
          $scope.disabledOnPopUp = true;
          loadingScreenFactory.showLoadingScreen();
          const modalInstance = $uibModal.open({
            animation: true,
            template: require('../../views/tagsList.html'),
            controller: 'tagsListCtrl',
            size: 'md',
            backdrop: 'static',
            resolve: {
              user_id() {
                return userId;
              },
              parentFunctionCall() {
                return getSelectedTagData;
              },
              for_patient() {
                return false;
              },
              patient_details() {
                if ($scope.showClaimInformation) {
                  if ($scope.rideDetails.patient_id) {
                    return {
                      patient_id: $scope.rideDetails.patient_id,
                    };
                  }
                  if (angular.isDefined($scope.patient)) {
                    const phone = $('#txtPhoneNo').intlTelInput(
                      'getNumber',
                      window.intlTelInputUtils.numberFormat.E164,
                    );
                    if (
                      phone
                      && $scope.patient.first_name
                      && $scope.patient.last_name
                    ) {
                      return {
                        phone,
                        first_name: $scope.patient.first_name,
                        last_name: $scope.patient.last_name,
                      };
                    }
                  }
                }
                return null;
              },
              orgId() {
                return orgId;
              },
            },
          });
          modalInstance.result.then((result) => {
            $scope.result = result;
          }, () => {
            $scope.disabledOnPopUp = false;
          });
          Object.assign($rootScope, { modal: modalInstance });
        };
        openTagListPopup(loggedInUserDetails.user_id, $scope.rideDetails.org_id);
      };

      const checkForRequiredFields = function checkForRequiredFields(
        requiredFields,
        tagData,
      ) {
        let msg = '';
        requiredFields.forEach((value, index) => {
          if (!tagData[requiredFields[index]]) {
            if (!msg) {
              msg = requiredFields[index];
            } else {
              msg = `${msg}, ${requiredFields[index]}`;
            }
          }
        });
        if (msg) {
          msg = `Missing Data: ${msg}`;
        }
        return msg;
      };
      // to save address as a tag
      $scope.saveAddress = function saveAddress() {
        const { index } = focusedLeg;
        let tagInfo;
        if (focusedLeg.type === 'source') {
          tagInfo = {
            address: $scope.rideDetails.legs[index].source_address,
            short_address: $scope.rideDetails.legs[index].short_source,
            latitude: $scope.rideDetails.legs[index].source_lat_long[0],
            longitude: $scope.rideDetails.legs[index].source_lat_long[1],
            tag: $scope.overlaySetting.tagName,
            user_id: loggedInUserDetails.user_id,
          };
        } else {
          tagInfo = {
            address: $scope.rideDetails.legs[index].dest_address,
            short_address: $scope.rideDetails.legs[index].short_dest,
            latitude: $scope.rideDetails.legs[index].dest_lat_long[0],
            longitude: $scope.rideDetails.legs[index].dest_lat_long[1],
            tag: $scope.overlaySetting.tagName,
            user_id: loggedInUserDetails.user_id,
          };
        }
        const error = checkForRequiredFields(
          ['tag', 'latitude', 'longitude', 'address'],
          tagInfo,
        );
        if (error) {
          toaster.pop({
            type: 'error',
            title: error,
            showCloseButton: true,
            timeout: 10000,
          });
          return;
        }

        const saveAddressRequest = $http.post(
          `${API_BASE_URL}useraddress`,
          tagInfo,
        );
        saveAddressRequest
          .then(
            (result) => {
              let type;
              if (result.data.success) {
                type = 'info';
              } else {
                type = 'error';
              }
              toaster.pop({
                type,
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
              $scope.overlaySetting = {
                showIfSave: false,
                showTagForm: false,
                tagName: '',
              };
            },
            (err) => {
              toaster.pop({
                type: 'error',
                title: err.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
            },
          )
          .catch((errObj) => {
            toaster.pop({
              type: 'error',
              title: errObj.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          });
      };

      // to set location on map via pin or tag(saved address)
      $scope.setLocationOnMap = function setLocationOnMap(
        type,
        index,
        selectedLocation,
        viaPin,
        viaTag,
      ) {
        let location;
        if (selectedLocation && selectedLocation.length) {
          location = {
            latitude: selectedLocation[0],
            longitude: selectedLocation[1],
          };
        } else {
          location = currentPosition;
        }
        googleService
          .geocode(location)
          .then((results) => {
            $scope.setLegMarkers(index);
            if (viaPin) {
              Object.assign(results[0], { name: results[0].formatted_address });
            }
            if (type === 'source') {
              pickupData.viaPin = viaPin;
              pickupData.viaTag = viaTag;
              if (viaPin) {
                $scope.rideDetails.legs[index].source_address = results[0].formatted_address;
                $scope.rideDetails.legs[index].source_lat_long = [
                  location.latitude,
                  location.longitude,
                ];
              }
              getAddressComponents(
                results[0],
                'sourceAddressPincode',
                $scope.rideDetails.legs[index],
              );
              setSourceAddressDetails(results[0], index);
            } else {
              destinationData.viaPin = viaPin;
              destinationData.viaTag = viaTag;
              if (viaPin) {
                $scope.rideDetails.legs[index].dest_address = results[0].formatted_address;
                $scope.rideDetails.legs[index].dest_lat_long = [
                  location.latitude,
                  location.longitude,
                ];
              }
              getAddressComponents(
                results[0],
                'destAddressPincode',
                $scope.rideDetails.legs[index],
              );
              setDestAddressDetails(results[0], index);
            }
          })
          .catch((error) => {
            toaster.pop({
              type: 'error',
              title: error,
              showCloseButton: true,
              timeout: 10000,
            });
          });
      };

      // to clear markers on map
      const clearMarkers = function clearMarkers() {
        if (pickupData.marker) {
          pickupData.marker.setMap(null);
          pickupData.viaPin = false;
          pickupData.viaTag = false;
          delete pickupData.clickListener;
          delete pickupData.dragEndListener;
        }
        if (destinationData.marker) {
          destinationData.marker.setMap(null);
          destinationData.viaPin = false;
          destinationData.viaTag = false;
          delete destinationData.clickListener;
          delete destinationData.dragEndListener;
        }
      };

      // to set focused leg/block marker on map
      $scope.setLegMarkers = function setLegMarkers(index) {
        if (index !== focusedBlockIndex) {
          clearMarkers();
          if (
            $scope.rideDetails.legs[index].source_lat_long
            && $scope.rideDetails.legs[index].source_lat_long.length > 0
          ) {
            setMarker(
              $scope.rideDetails.legs[index].source_lat_long[0],
              $scope.rideDetails.legs[index].source_lat_long[1],
              $scope.rideDetails.legs[index].short_source,
              pickupData,
              index,
            );
          }
          if (
            $scope.rideDetails.legs[index].dest_lat_long
            && $scope.rideDetails.legs[index].dest_lat_long.length > 0
          ) {
            setMarker(
              $scope.rideDetails.legs[index].dest_lat_long[0],
              $scope.rideDetails.legs[index].dest_lat_long[1],
              $scope.rideDetails.legs[index].short_dest,
              destinationData,
              index,
            );
          }
        }
        focusedBlockIndex = index;
      };


      $scope.tripTypeManager = function tripTypeManager() {
        if ($scope.rideDetails.trip_type === $scope.tripTypeConstants.BOTH_ACTIVE) {
          $scope.isScheduleTypeActive = true;
          $scope.isBookNowTypeActive = true;
          if ($stateParams.bookAnotherId) {
            $scope.appointmentTime.time = 'BOOK_NOW';
          }
        } else if ($scope.rideDetails.trip_type === $scope.tripTypeConstants.SCHEDULE_ACTIVE) {
          $scope.isScheduleTypeActive = true;
          if ($stateParams.bookAnotherId) {
            $scope.appointmentTime.time = 'BOOK_NOW';
            $scope.isBookNowTypeActive = true;
          } else {
            $scope.appointmentTime.time = 'FUTURE_APPOINTMENT';
            $scope.isBookNowTypeActive = false;
          }
        } else if ($scope.rideDetails.trip_type === $scope.tripTypeConstants.ON_DEMAND_ACTIVE) {
          $scope.isScheduleTypeActive = false;
          $scope.isBookNowTypeActive = true;
          $scope.appointmentTime.time = 'BOOK_NOW';
        } else {
          $scope.isScheduleTypeActive = false;
          $scope.isBookNowTypeActive = false;
          $scope.appointmentTime.time = '';
        }
      };

      $scope.recurrenceDaysChange = function recurrenceDaysChange() {
        $scope.daysInRangeCall();
        if ($scope.rangeAltered || $scope.startByDisabled) {
          return;
        }
        if ($scope.rideDetails && $scope.rideDetails.recurringData) {
          if (
            $scope.rideDetails.recurringData.days
            && $scope.rideDetails.recurringData.days.length > 0
            && $scope.rideDetails.recurringData.pattern !== 'monthly'
          ) {
            const day = moment(); // initially select today and then check next day and then next

            for (let i = 0; i < 7; i += 1) {
              if (
                $scope.rideDetails.recurringData.days.includes(day.format('dddd'))
              ) {
                $scope.rideDetails.recurringData.startBy = moment(day);
                break;
              } else {
                day.add(1, 'days');
              }
            }
          } else if ($scope.rideDetails.recurringData.pattern === 'monthly') {
            return;
          } else {
            $scope.rideDetails.recurringData.startBy = moment(
              new Date().getTime(),
            );
          }

          if ($scope.rideDetails.recurringData.pattern === 'weekly') {
            recurrenceRangeChange();
          }
        }
      };

      $scope.claimLookupFunction = async function claimLookupFunction(claimUid) {
        const paramsObject = {};
        let processingWaitDialog = null;
        $scope.isClaimNumberChanged = false;

        if ($scope.rideDetails.org_id) {
          paramsObject.org_id = $scope.rideDetails.org_id;
        }

        if (claimUid) {
          paramsObject.claim_uid = claimUid;
          processingWaitDialog = ngDialog.open({
            template: claimLoaderTemplate,
            plain: true,
            className: 'ngdialog-theme-default',
            scope: $scope,
            showClose: false,
            closeByEscape: false,
            closeByNavigation: false,
            closeByDocument: false,
            name: 'processingWaitDialog',
          });
        } else if ($scope.claimInformation.claim_number) {
          paramsObject.claim_number = $scope.claimInformation.claim_number;
          if ($scope.claimInformation.vendor_code) {
            paramsObject.vendor_code = $scope.claimInformation.vendor_code;
          }

          processingWaitDialog = ngDialog.open({
            template: claimLoaderTemplate,
            plain: true,
            className: 'ngdialog-theme-default',
            scope: $scope,
            showClose: false,
            closeByEscape: false,
            closeByNavigation: false,
            closeByDocument: false,
            name: 'processingWaitDialog',
          });
        } else {
          toaster.pop({
            type: 'error',
            title: 'Enter Claim Number',
            showCloseButton: true,
            timeout: 10000,
          });
          return;
        }

        try {
          const {
            data: {
              success,
              claimInformation,
            },
          } = await $http
            .get(`${API_BASE_URL}claims`, {
              params: paramsObject,
            });

          if (
            success
            && claimInformation
            && claimInformation.count
            && claimInformation.count.toString() === '1'
          ) {
            const dateOfInjury = moment.utc(claimInformation.date_of_injury);

            $scope.claimNumberHasError = false;
            $scope.isClaimNumberDetails = true;
            $scope.appointmentForm.claimNumber.$setValidity('isClaimNumberValid', true);

            Object.assign($scope.claimInformation, {
              adjuster_first_name: claimInformation.adjuster_first_name,
              adjuster_last_name: claimInformation.adjuster_last_name,
              adjuster_email_address: claimInformation.email,
              date_of_injury: dateOfInjury.isValid() ? dateOfInjury : null,
              claim_state: claimInformation.claim_state,
              adjuster_employer_name: claimInformation.employer,
              injury_state: claimInformation.injury_state,
              adjuster_home_address: claimInformation.claimant_home_address,
              claim_number: claimInformation.claim_number,
              vendor_code: claimInformation.vendor_code,
            });

            Object.assign($scope.patient, {
              first_name: claimInformation.claimant_first_name,
              last_name: claimInformation.claimant_last_name,
            });

            $scope.$apply();
          } else if ($scope.claimInformation.claim_number) {
            if (
              $scope.claimInformation.vendor_code
              || (claimInformation.count
                && claimInformation.count.toString() === '0')
            ) {
              $scope.claimNumberHasError = true;
              $scope.isClaimNumberDetails = false;
              // cannot find claim fill data manually
              SweetAlert.swal(
                'Claim not found. Please select a different billing method option or try a different claim number.',
                '',
                'warning',
              );
            } else {
              // please enter vendor code, as we got multiple data
              SweetAlert.swal(
                'More than one claim found for this claim number, please enter vendor code and try again',
                '',
                'warning',
              );
            }

            $scope.resetClaimInformation();
          } else if (claimUid) {
            $scope.claimInfoFlags.claimNotFoundByClaimUid = true;
            SweetAlert.swal(
              'Claim not found. Please select a different billing method option or try a different claim number.',
              '',
              'warning',
            );
          } else {
            $scope.resetClaimInformation();
          }
        } catch (error) {
          $scope.claimNumberHasError = true;
          $scope.isClaimNumberDetails = false;

          const errorMessage = (!error.data.success && error.data.message) ? error.data.message : 'Unable to retrieve claim details at this time. Please try again in a few minutes.';
          SweetAlert.swal(
            errorMessage,
            '',
            'error',
          );
          $scope.resetClaimInformation();
        } finally {
          if (processingWaitDialog) {
            processingWaitDialog.close();
          }
        }
      };

      $scope.resetClaimInformation = function resetClaimInformation() {
        Object.assign($scope.claimInformation, {
          date_of_injury: moment(),
          adjuster_first_name: '',
          adjuster_last_name: '',
          adjuster_email_address: '',
          claim_state: '',
          adjuster_employer_name: '',
          injury_state: '',
          adjuster_home_address: '',
        });

        Object.assign($scope.patient, {
          first_name: '',
          last_name: '',
        });
      };

      $scope.payerNameSelected = function payerNameSelected() {
        $scope.showClaimInformation = true;
      };

      /**
     * Creator: Bhupinder Garg
     * Date: 14 dec 2018
     * Reason: To get payers if billing option drop down value is changed.
     */

      const getPayersList = function getPayersList() {
        $scope.payersList = [$scope.seletedOrgDetails.org_payer] || [];
      };

      $scope.payerHandler = function payerHandler(billingDetails) {
        $scope.hideTaxiDetails();

        if (!$stateParams.rideId && !$stateParams.bookAnotherId) {
          $scope.isClaimNumberDetails = false;
          Object.assign($scope.claimInformation, {
            claim_number: '',
            date_of_injury: '',
            date_of_birth: '',
            adjuster_home_address: '',
          });
          $scope.claimInformation.destination_address_name.forEach((value, index) => {
            $scope.claimInformation.destination_address_name[index] = '';
          });

          Object.assign($scope.patient, {
            first_name: '',
            last_name: '',
            phone: '',
          });

          $scope.rideDetails.legs.forEach((legObj) => {
            Object.assign(legObj, {
              dest_address: '',
              source_address: '',
            });
          });

          if ($scope.rideDetails.file_no) {
            $scope.rideDetails.file_no = '';
          }
        }

        const { selectedBillingOption } = billingDetails;
        if (selectedBillingOption !== 'account_lookup') {
          const checkOrgClaimSearchAvailability = $http.get(`${API_BASE_URL}organisation/checkClaimSearch/${$scope.rideDetails.org_id}/${selectedBillingOption}`);
          checkOrgClaimSearchAvailability.then(
            (result) => {
              if (!result.data.success) {
                toaster.pop({
                  type: 'error',
                  title: result.data.message,
                  showCloseButton: true,
                  timeout: 10000,
                });
                hideResetBillingDependencies();
              }
            },
            (error) => {
              toaster.pop({
                type: 'error',
                title: error.data ? error.data.message : '',
                showCloseButton: true,
                timeout: 10000,
              });
            },
          );

          if (initialPayerName && !$scope.billingDetails.selectedPayerName) {
            $scope.billingDetails.selectedPayerName = initialPayerName;
            $scope.showClaimInformation = true;
            $scope.isPayerSettingActive = true;
          }

          $scope.payerNameDropdown = $scope.billingOptionsDefault[selectedBillingOption].payerNameDropdown;

          $scope.showClaimDetailsOf = {
            future_lookup: false,
            claim_lookup: false,
          };

          $scope.showClaimDetailsOf[selectedBillingOption] = true;

          getPayersList();

          if ($scope.isPayerSettingActive) {
            $scope.payerNameSelected();
          }
        } else {
          hideResetBillingDependencies();
        }
      };

      function prepareInviteRiderData() {
        const preparedInviteRiderData = {};
        Object.keys(allowedInvitedRiderEntitiesAndFields).forEach((allowedInvitedRiderEntity) => {
          if (allowedInvitedRiderEntitiesAndFields[allowedInvitedRiderEntity][$scope.billingDetails.selectedBillingOption]) {
            preparedInviteRiderData[allowedInvitedRiderEntity] = {};
          } else {
            return;
          }
          const allowedInvitedRiderFields = allowedInvitedRiderEntitiesAndFields[allowedInvitedRiderEntity][$scope.billingDetails.selectedBillingOption];
          allowedInvitedRiderFields.forEach((allowedInvitedRiderField) => {
            if ($scope[allowedInvitedRiderEntity] && $scope[allowedInvitedRiderEntity][allowedInvitedRiderField]) {
              if (allowedInvitedRiderField === 'phone') {
                preparedInviteRiderData[allowedInvitedRiderEntity][allowedInvitedRiderField] = angular.element('#txtPhoneNo').intlTelInput(
                  'getNumber',
                  window.intlTelInputUtils.numberFormat.E164,
                );
              } else {
                preparedInviteRiderData[allowedInvitedRiderEntity][allowedInvitedRiderField] = $scope[allowedInvitedRiderEntity][allowedInvitedRiderField];
              }
            }
          });
        });
        return preparedInviteRiderData;
      }

      $scope.riderDetailsSubmitted = () => {
        const invalidRiderTabFields = $scope.billingOptionsDefault[$scope.billingDetails.selectedBillingOption].fieldsToBeValidatedOnRiderForm
          .filter(fieldToBeValidated => (($scope.appointmentForm[fieldToBeValidated] || {}).$invalid === true));
        if (invalidRiderTabFields.length) {
          const firstInvalidRiderTabField = $(`[name = ${invalidRiderTabFields[0]}]`)[0];
          if (firstInvalidRiderTabField) {
            if (invalidRiderTabFields[0] === 'payerName') {
              $scope.$broadcast('focusPayerName');
            } else {
              firstInvalidRiderTabField.focus();
            }
          }
          $scope.isAnyRiderTabFieldInvalid = true;
        } else {
          $scope.isAnyRiderTabFieldInvalid = false;
          if ($scope.appointmentTime.time === 'INVITE_ONLY') {
            const inviteRiderData = prepareInviteRiderData();
            sessionStorage.setItem('inviteRiderData', JSON.stringify(inviteRiderData));
            $state.go('main.invitations', {
              org_id: $scope.seletedOrgDetails.id,
              org_name: $scope.seletedOrgDetails.name,
            });
            return;
          }
          $scope.showRiderDetails = false;
        }
      };

      $scope.payerOpenClose = (isOpen) => {
        if (!isOpen) {
          $scope.$broadcast('focusPayerName');
        }
      };

      $scope.$on('$destroy', () => {
        clearMarkers();
        angular.forEach(WatcherRemovers, (w) => {
          w();
        });
        angular.forEach(TimerRemovers, (t) => {
          $timeout.cancel(t);
        });
      });
    },
  ]);
