const { rideStatusMapping } = relaylib.common.constants;

angular
  .module('relayHealth')
  .service('superAdminDashboardService',
    function superAdminDashboardService() {
      this.rideAlertStatuses = [{
        displayMessage: 'Potential Unavailability',
        status: 'Potential Unavailability',
      },
      {
        displayMessage: 'Failed-Other',
        status: 'Failed-Other',
      },
      {
        displayMessage: 'No Driver Available',
        status: 'No Driver Available',
      },
      {
        displayMessage: 'Late Arrival',
        status: 'Arrival Time Exceeded',
      }, {
        displayMessage: 'Ride En Route-Late Arrival',
        status: 'Late Arrival',
      },
      {
        displayMessage: 'Driver Canceled',
        status: 'Driver Canceled',
      },
      {
        displayMessage: 'Variance Issue',
        status: 'Variance Issue',
      },
      {
        displayMessage: 'Request Overdue',
        status: 'Request Overdue',
      },
      {
        displayMessage: 'Failed Fuse',
        status: 'Failed Fuse',
      },
      {
        displayMessage: 'Confirm Member Pickup',
        status: 'Confirm Member Pickup',
      }, {
        displayMessage: 'Confirm Member Dropoff',
        status: 'Confirm Member Dropoff',
      },
      {
        displayMessage: 'Special Ride',
        status: 'Special Ride',
      },
      {
        displayMessage: '3rd Driver Alert',
        status: 'Driver Confirmed,Driver Arrived,Patient Enroute',
      },
      {
        displayMessage: 'Rider No show',
        status: 'Patient No Show',
        type: 'danger',
      },
      ];
      this.issueStatuses = [{
        displayMessage: 'Driver Arrived',
        status: 'Driver Arrived',
        type: 'warn',
      }, {
        displayMessage: 'Ride Canceled',
        status: 'Ride Canceled',
        type: 'plain',
      }, {
        displayMessage: 'Surge Approval Needed',
        status: 'Surge Approval Needed',
        type: 'warn',
      }, {
        displayMessage: 'Driver Canceled Ride',
        status: 'Driver Canceled',
        type: 'danger',
      }, {
        displayMessage: 'Patient No show',
        status: 'Patient No Show',
        type: 'danger',
      }, {
        displayMessage: 'Patient Late Arrival ',
        status: 'Late Arrival',
        type: 'warn',
      }, {
        displayMessage: 'Missed Surge Approval',
        status: 'Missed Surge Approval',
        type: 'danger',
      }, {
        displayMessage: 'No <provider> Drivers Available',
        status: 'No Driver Available',
        type: 'danger',
      }, {
        displayMessage: 'Failed CC Auth',
        status: 'Failed CC Auth',
        type: 'warn',
      }, {
        displayMessage: 'Failed CC Payment',
        status: 'Failed CC Payment',
        type: 'danger',
      }, {
        displayMessage: 'Failed - Other',
        status: 'Failed-Other',
        type: 'danger',
      }, {
        displayMessage: 'Potential Unavailability',
        status: 'Potential Unavailability',
        type: 'warn',
      }, {
        displayMessage: 'Failed Fuse',
        status: 'Failed Fuse',
        type: 'warn',
      }, {
        displayMessage: 'Variance Under',
        status: 'Variance Under',
        type: 'warn',
      }, {
        displayMessage: 'Variance Over',
        status: 'Variance Over',
        type: 'warn',
      }, {
        displayMessage: 'Variance Issue',
        status: 'Variance Issue',
        type: 'warn',
      }, {
        displayMessage: 'Request Overdue',
        status: 'Request Overdue',
        type: 'danger',
      }, {
        displayMessage: 'Departure Overdue',
        status: 'Departure Overdue',
        type: 'warn',
      }, {
        displayMessage: 'Special Ride',
        status: 'Special Ride',
        type: 'special',
      }, {
        displayMessage: 'Arrival Time Exceeded',
        status: 'Arrival Time Exceeded',
        type: 'warn',
      }, {
        displayMessage: 'Confirm Member Pick-up',
        status: 'Confirm Member Pickup',
        type: 'danger',
      }, {
        displayMessage: 'Confirm Member Drop-Off',
        status: 'Confirm Member Dropoff',
        type: 'danger',
      }];

      this.lateArrivalStatuses = ['Arrival Time Exceeded', 'Driver Arrived', 'Patient Enroute'];

      this.driverAlertStatuses = [{
        displayMessage: '3rd Driver Alert',
        status: 'Driver Confirmed',
        type: 'danger',
      }, {
        displayMessage: '3rd Driver Alert',
        status: 'Driver Arrived',
        type: 'danger',
      }, {
        displayMessage: '3rd Driver Alert',
        status: 'Patient Enroute',
        type: 'danger',
      }];

      this.alertTypes = [
        {
          displayMessage: rideStatusMapping['Order Updated'],
          status: 'Order Updated',
          type: 'warn',
        },
        {
          displayMessage: rideStatusMapping.NDA,
          status: 'NDA',
          type: 'danger',
        },
        {
          displayMessage: rideStatusMapping.NEPF,
          status: 'NEPF',
          type: 'danger',
        },
        {
          displayMessage: rideStatusMapping.UATP,
          status: 'UATP',
          type: 'danger',
        },
        {
          displayMessage: rideStatusMapping['Offer Created'],
          status: ['Offer Created', 'Pending - Re-Offer'],
          type: 'warn',
        },
        {
          displayMessage: rideStatusMapping['24 Hour Confirmation Missing'],
          status: '24 Hour Confirmation Missing',
          type: 'danger',
        }, {
          displayMessage: 'Validation Failed',
          status: 'Validation Failed',
          type: 'warn',
        },
        {
          displayMessage: `${rideStatusMapping.NDA} (Yellow)`,
          status: 'Offered NDA',
          type: 'warn',
        }];

      this.retroTypes = [
        {
          displayMessage: 'Retro Queued',
          status: 'Retro Queued',
          type: 'plain',
        },
        {
          displayMessage: 'Retro Queued',
          status: 'Ride Completed',
          type: 'plain',
        },
        {
          displayMessage: 'Retro Queued',
          status: 'Patient No Show',
          type: 'plain',
        },
        {
          displayMessage: 'Retro Queued',
          status: 'Provider No Show',
          type: 'plain',
        },
      ];

      this.statusColor = {
        danger: '#ed5565',
        plain: '#e7eaec',
        warn: '#F9A825',
        special: '#3E98C5',
      };

      this.issuesTab = {
        heading: 'Ride Alerts',
        tableColumns: [{
          displayText: 'Rider Name',
          columnKey: 'name',
        },
        {
          displayText: 'Trip Identifier',
          columnKey: 'tripIdentifier',
        },
        {
          displayText: 'Trip/Leg Date & Time',
          columnKey: 'tripDateTime',
          isSortable: true,
          sortKey: 'trip_date_time',
          sortType: null,
        }, {
          displayText: 'Organization',
          columnKey: 'organization',
          isSortable: true,
          sortType: null,
          sortKey: 'org.name',
        }, {
          displayText: 'Managed By',
          columnKey: 'managedBy',
          isSortable: true,
          sortKey: 'users.first_name',
          sortType: null,
          isFilterable: true,
          filterKey: 'manager',
          filterValue: null,
          showFilter: false,
        }, {
          displayText: 'Alert Type',
          columnKey: 'alertType',
        }],
        url: 'rides/getRidesListByRideCategory/issues/ride_sorting, ride."updatedAt" DESC/',
        data: [],
        pagination: {
          totalItems: 0,
          currentPage: 1,
          lastItem: 0,
          itemsPerPage: 10,
        },
        show: false,
        active: false,
        showFilterForm: true,
        filterForm: {
          angularForm: {},
          data: {
            organizations: null,
            market_segment_id: null,
            alertType: [],
          },
        },
      };

      this.mileageTrackerTab = {
        heading: 'Mileage Tracker',
        tableColumns: [{
          displayText: 'Rider Name',
          columnKey: 'name',
        }, {
          displayText: 'Trip Date & Time',
          columnKey: 'tripDateTime',
        }, {
          displayText: 'Trip Type',
          key: 'tripType',
        }, {
          displayText: 'Destination',
          key: 'destination',
        }],
        url: 'rides/getRidesListByRideCategory/pr/ride."updatedAt" DESC/',
        data: [],
        pagination: {
          totalItems: 0,
          currentPage: 1,
          lastItem: 0,
          itemsPerPage: 10,
        },
        show: true,
        active: true,
        showFilterForm: true,
        filterForm: {
          angularForm: {},
          data: {
            organizations: null,
          },
        },
      };

      this.varianceTab = {
        heading: 'RelayRIDE Variances',
        tableColumns: [
          { displayText: 'Rider Name', columnKey: 'name' },
          { displayText: 'Trip Date & Time', columnKey: 'tripDateTime' },
          {
            displayText: 'Market Segment',
            columnKey: 'marketSegment',
            isSortable: true,
            isFilterable: true,
            filterKey: 'market_segment_id',
            filterValue: null,
            showFilter: false,
            sortType: null,
            sortKey: 'market_segment.name',
          },
          { displayText: 'Variance Type', columnKey: 'varianceType' },
        ],
        url: 'rides/getRidesListByRideCategory/variance/ride."updatedAt" DESC/',
        data: [],
        pagination: {
          totalItems: 0,
          currentPage: 1,
          lastItem: 0,
          itemsPerPage: 10,
        },
        show: false,
        active: false,
      };

      this.recurrenceTripsTab = {
        heading: 'Recurring Trips',
        tableColumns: [
          { displayText: 'Rider Name', columnKey: 'name' },
          { displayText: 'Rider Phone Number', columnKey: 'riderPhoneNo' },
          {
            displayText: 'Organization',
            columnKey: 'organization',
            isSortable: true,
            sortType: null,
            sortKey: 'organisation.name',
          },
          { displayText: 'Trip Series (start date-end date)', columnKey: 'tripSeries' },
          { displayText: 'Extend Button', columnKey: 'extendButton' }],
        url: 'recurring-rides?fields=all',
        data: [],
        pagination: {
          totalItems: 0,
          currentPage: 1,
          lastItem: 0,
          itemsPerPage: 10,
        },
        show: false,
        active: false,
        showFilterForm: true,
        filterForm: {
          angularForm: {},
          data: {
            organizations: null,
          },
        },
      };

      this.paymentIssuesTab = {
        heading: 'Payment Issues',
        tableColumns: [
          { displayText: 'Rider Name', columnKey: 'name' },
          { displayText: 'Trip Date & Time', columnKey: 'tripDateTime' },
          {
            displayText: 'Market Segment',
            columnKey: 'marketSegment',
            isSortable: true,
            isFilterable: true,
            filterKey: 'market_segment_id',
            filterValue: null,
            showFilter: false,
            sortType: null,
            sortKey: 'market_segment.name',
          },
          {
            displayText: 'Organization',
            columnKey: 'organization',
            isSortable: true,
            sortType: null,
            sortKey: 'org.name',
          },
          { displayText: 'Alert Type', columnKey: 'alertType' }],
        url: 'rides/getRidesListByRideCategory/issues/ride_sorting, ride."updatedAt" DESC/',
        data: [],
        pagination: {
          totalItems: 0,
          currentPage: 1,
          lastItem: 0,
          itemsPerPage: 10,
        },
        show: false,
        active: false,
        showFilterForm: true,
        filterForm: {
          angularForm: {},
          data: {
            organizations: null,
            market_segment_id: null,
          },
        },
      };

      this.schedulingAlertsTab = {
        heading: 'Scheduling Alerts',
        tableColumns: [
          { displayText: 'Name', columnKey: 'name' },
          { displayText: 'Ride Order ID', columnKey: 'rideOrderId' },
          { displayText: 'Mode', columnKey: 'mode' },
          {
            displayText: 'Organization',
            columnKey: 'organization',
            isSortable: true,
            sortType: null,
            sortKey: 'org.name',
          },
          {
            displayText: 'First Leg Pick-up Date/Time',
            columnKey: 'firstLegPickupDateTime',
            isSortable: true,
            sortKey: 'ride.pickup_date_time',
            sortType: null,
          },
          {
            displayText: 'Managed By',
            columnKey: 'managedBy',
            isSortable: true,
            isFilterable: true,
            filterKey: 'manager',
            filterValue: null,
            showFilter: false,
            sortKey: 'users.first_name',
            sortType: null,
          },
          {
            displayText: 'Alert Type',
            columnKey: 'alertType',
            isFilterable: true,
            filterKey: 'alertType',
            filterValue: null,
            showFilter: false,
          },
          { displayText: 'Hours until pickup', columnKey: 'hoursUntilPickup' },
          { displayText: 'Actions', columnKey: 'actions' },
        ],
        url: 'rides/getRidesListByRideCategory/schedulingAlerts/ride.pickup_date_time ASC/',
        data: [],
        pagination: {
          totalItems: 0,
          currentPage: 1,
          lastItem: 0,
          itemsPerPage: 10,
        },
        show: true,
        showFilterForm: true,
        filterForm: {
          angularForm: {},
          data: {
            mode: null,
            orderId: null,
            masInvoiceNumber: null,
            organizations: null,
            tripIdentifierType: null,
          },
        },
        active: false,
      };


      this.retroQueueTab = {
        heading: 'Retro Queue',
        tableColumns: [
          {
            displayText: 'Member Name',
            columnKey: 'name',
            isSortable: true,
            sortKey: 'patient.full_name',
            sortType: null,
          },
          {
            displayText: 'Ride Order ID',
            columnKey: 'rideOrderId',
            isSortable: true,
            sortKey: 'external_order_id',
            sortType: null,
          },
          {
            displayText: 'Mode',
            columnKey: 'mode',
          },
          {
            displayText: 'Organization',
            columnKey: 'organization',
            isSortable: true,
            sortType: null,
            sortKey: 'org.name',
          },
          {
            displayText: 'First Leg Pick-up Date/Time',
            columnKey: 'firstLegPickupDateTime',
            isSortable: true,
            sortKey: 'ride.pickup_date_time',
            sortType: null,
          },
          {
            displayText: 'Alert Type',
            columnKey: 'alertType',
          },
          {
            displayText: 'Actions',
            columnKey: 'actions',
          },
        ],
        url: 'rides/getRidesListByRideCategory/retroQueueRides/ride.pickup_date_time ASC/',
        data: [],
        pagination: {
          totalItems: 0,
          currentPage: 1,
          lastItem: 0,
          itemsPerPage: 10,
        },
        show: true,
        showFilterForm: true,
        filterForm: {
          angularForm: {},
          data: {
            name: null,
            orderId: null,
            dateRange: null,
            pickUpDateRange: null,
            organizations: null,
          },
        },
        active: false,
      };
    });
