angular.module('relayHealth').controller('adjusterAddCtrl', [
  '$scope',
  '$rootScope',
  '$http',
  'API_BASE_URL',
  'toaster',
  'countryStateFactory',
  '$uibModalInstance',
  'staff_member_detail',
  'SweetAlert',
  function adjusterAddCtrl(
    $scope,
    $rootScope,
    $http,
    API_BASE_URL,
    toaster,
    countryStateFactory,
    $uibModalInstance,
    staffMemberDetail,
    SweetAlert,
  ) {
    $scope.countryArray = countryStateFactory.countryList();
    $scope.operation = staffMemberDetail ? 'Edit' : 'Add';

    $scope.getStates = (country, scopeAdjuster, state, stateArray) => {
      // eslint-disable-next-line no-param-reassign
      if (angular.isDefined(scopeAdjuster[state])) delete scopeAdjuster[state];
      const index = $scope.countryArray.indexOf(country) + 1;
      if (index > 0) $scope[stateArray] = countryStateFactory.stateList(index);
    };

    $scope.staff_member_detail = staffMemberDetail || {
      dob: moment(),
    };
    $scope.staff_member_detail.gender = staffMemberDetail.gender ? staffMemberDetail.gender : 'Male';
    let userDetails = JSON.parse(localStorage.getItem('userDetails'));
    $scope.company = userDetails.division[0].org_name;
    $scope.staff_member_detail.role = 'adjuster';
    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.staff_member_add = (
      staffMember, // to save the staff member info
    ) => {
      $scope.formSubmited = true;
      const res = $http.post(`${API_BASE_URL}staffmember/addstaffmember`, Object.assign(staffMember, { role: 'adjuster' }));
      res.then(({ data }) => {
        $scope.formSubmited = false;
        if (data.success) {
          $uibModalInstance.close();
          SweetAlert.swal({
            title: 'Added!',
            text: 'Adjuster Added Successfully',
            type: 'success',
          });
          $rootScope.$broadcast('staffAdded');
        } else {
          toaster.pop({
            type: 'error',
            title: data.error_messages,
            showCloseButton: true,
            timeout: 10000,
          });
        }
      });
      res.catch(({ data }) => {
        $scope.formSubmited = false;
        toaster.pop({
          type: 'error',
          title: data.message,
          showCloseButton: true,
          timeout: 10000,
        });
      });
    };

    $scope.staff_member_edit = (
      staffMemberObj, // to save the patient info
    ) => {
      const staffMember = Object.assign({}, staffMemberObj);
      staffMember.role = 'adjuster';
      userDetails = JSON.parse(localStorage.getItem('userDetails'));
      staffMember.division_id = userDetails.division[0].div_id;
      const id = angular.copy(staffMember.id);
      delete staffMember.id;
      $scope.formSubmited = true;
      const res = $http.put(`${API_BASE_URL}staffmember/edit/${id}`, staffMember);
      res.then(({ data }) => {
        $scope.formSubmited = false;
        if (data.success) {
          SweetAlert.swal({
            title: 'Updated!',
            text: data.message || 'Adjuster Updated Successfully',
            type: 'success',
          });
          $uibModalInstance.close();
          $rootScope.$broadcast('staffAdded');
        } else {
          toaster.error({
            type: 'error',
            title: data.error_messages,
            showCloseButton: true,
            timeout: 10000,
          });
        }
      });
      res.catch(() => {
        $scope.formSubmited = false;
        toaster.error({
          type: 'error',
          title: 'Not able to edit the Staffmember',
          showCloseButton: true,
          timeout: 10000,
        });
      });
    };
    $scope.$watch('addressCheck', (newValue) => {
      if (newValue) {
        $scope.staff_member_detail.bill_address1 = $scope.staff_member_detail.address1;
        $scope.staff_member_detail.bill_address2 = $scope.staff_member_detail.address2;
        $scope.staff_member_detail.bill_country = $scope.staff_member_detail.country;
        $scope.staff_member_detail.bill_state = $scope.staff_member_detail.state;
        $scope.staff_member_detail.bill_city = $scope.staff_member_detail.city;
        $scope.staff_member_detail.bill_zip = $scope.staff_member_detail.zip;
      }
    });
  },
]);
