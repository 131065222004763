const { FormData } = window;

angular.module('relayHealth').controller('videoLibraryCntrl', [
  '$scope',
  '$rootScope',
  '$http',
  'API_BASE_URL',
  'toaster',
  '$uibModal',
  '$timeout',
  'loadingScreenFactory',
  'SweetAlert',
  function videoLibraryCntrl($scope, $rootScope, $http, API_BASE_URL, toaster, $uibModal, $timeout, loadingScreenFactory, SweetAlert) {
    $scope.searchFormQuery = {};
    $scope.searchFormQuery.status = 'Select Status';
    $scope.activeRide = {};
    $scope.activeRide.status = 'Select Status';
    $scope.description = '';
    $scope.pagination = {
      totalItems: 0,
      currentPage: 1,
      lastItem: 0,
      itemsPerPage: 17,
    };

    $scope.videoList = [];

    $scope.permissionsConstants = relaylib.permissions.permissionsConstants;
    $scope.videoUploadMaxSize = relaylib.common.constants.unitsConstant.HELP_VIDEO_UPLOAD_SIZE;
    $scope.maxThumbnailSize = relaylib.common.constants.unitsConstant.HELP_THUMBNAIL_UPLOAD_SIZE;

    $scope.adjustPagination = () => {
      $scope.pagination.lastItem = (($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage + 17);
      if ($scope.pagination.lastItem > $scope.pagination.totalItems) {
        $scope.pagination.lastItem = $scope.pagination.totalItems;
      }
    };

    $scope.getAllVideo = () => {
      loadingScreenFactory.showLoadingScreen();
      const getAllVideo = $http({
        url: `${API_BASE_URL}video_library/${($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage}/${$scope.pagination.itemsPerPage}`,
        method: 'GET',
      });

      getAllVideo.then(
        (result) => {
          if (result.data.success) {
            $scope.videoList = [];
            loadingScreenFactory.hideLoadingScreen();
            $scope.videoList = result.data.data;
            $scope.pagination.totalItems = result.data.total;
            $scope.adjustPagination();
          } else {
            toaster.pop({
              type: 'info',
              title: 'No video found',
              showCloseButton: true,
              timeout: 10000,
            });
          }
        },
        (err) => {
          loadingScreenFactory.hideLoadingScreen();
          $scope.activeRides.length = 1;
          $scope.pagination.totalItems = 0;
          $scope.adjustPagination();
          toaster.pop({
            type: 'info',
            title: err.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    };

    $scope.cancelClicked = () => {
      document.getElementById('description').value = '';
      $('.fileinput').fileinput('clear');
    };

    $scope.uploadHelpVideo = () => {
      const fm = new FormData();
      const description = document.getElementById('description').value;
      const thumbnail = document.getElementById('import_thumbnail').files;
      const video = document.getElementById('import_file').files;
      if (video.length <= 0) {
        toaster.pop({
          type: 'error',
          title: 'Please select a video to upload.',
          showCloseButton: true,
          timeout: 10000,
        });
      } else if (video[0].size > $scope.videoUploadMaxSize) {
        toaster.pop({
          type: 'error',
          title: `Video size is greater than ${($scope.videoUploadMaxSize / 1024 / 1024) || 100}MB. Please upload Video below ${($scope.videoUploadMaxSize / 1024 / 1024) || 100}MB.`,
          showCloseButton: true,
          timeout: 10000,
        });
      } else if (thumbnail.length <= 0) {
        toaster.pop({
          type: 'error',
          title: 'Please select a thumbnail to upload.',
          showCloseButton: true,
          timeout: 10000,
        });
      } else if (thumbnail[0].size > $scope.maxThumbnailSize) {
        toaster.pop({
          type: 'error',
          title: 'Thumbnail size is greater than 2MB. Please upload thumbnail below 2MB',
          showCloseButton: true,
          timeout: 10000,
        });
      } else if (description.trim() === '') {
        toaster.pop({
          type: 'error',
          title: 'Please enter description',
          showCloseButton: true,
          timeout: 10000,
        });
      } else {
        loadingScreenFactory.showLoadingScreen();
        fm.append('file', video[0]);
        fm.append('thumbnail', thumbnail[0]);
        const importVideo = $http({
          url: `${API_BASE_URL}video_library/uploadHelpVideo/${description}`,
          method: 'POST',
          data: fm,
          headers: {
            'Content-Type': undefined,
          },
        });
        importVideo.then((result) => {
          loadingScreenFactory.hideLoadingScreen();
          if (result.data.success) {
            toaster.pop({
              type: 'info',
              title: 'File queued to upload',
              showCloseButton: true,
              timeout: 10000,
            });
            $scope.cancelClicked();
          } else {
            toaster.pop({
              type: 'error',
              title: result.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        }, (error) => {
          loadingScreenFactory.hideLoadingScreen();
          toaster.pop({
            type: 'error',
            title: error.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        }).catch((error) => {
          loadingScreenFactory.hideLoadingScreen();
          toaster.pop({
            type: 'error',
            title: error.message,
            showCloseButton: true,
            timeout: 10000,
          });
        });
      }
    };
    $scope.deleteVideo = (videoId) => {
      SweetAlert.swal({
        title: 'Are you sure you want to delete?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, delete it!',
        closeOnConfirm: true,
        closeOnCancel: true,
      },
      (isConfirm) => {
        if (isConfirm) {
          loadingScreenFactory.showLoadingScreen();
          const deleteVideo = $http({
            url: `${API_BASE_URL}video_library/${videoId}`,
            method: 'DELETE',
          });
          deleteVideo.then((result) => {
            if (result.data.success) {
              $scope.getAllVideo();
              loadingScreenFactory.hideLoadingScreen();
              toaster.pop({
                type: 'info',
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
            } else {
              loadingScreenFactory.hideLoadingScreen();
              toaster.pop({
                type: 'error',
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
            }
          }, (err) => {
            loadingScreenFactory.hideLoadingScreen();
            toaster.pop({
              type: 'error',
              title: err.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          });
        }
      });
    };

    $scope.getAllVideo();
    $scope.pageChanged = () => {
      $scope.getAllVideo();
    };
  },
]);
