const costType = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
}
const billingFeeType = {
  COST_PLUS_FLAT_FEE: 'cost_plus_flat_fee',
  COST_PLUS_PERCENTAGE: 'cost_plus_percentage',
  COST_PER_MILE: 'cost_per_mile',
  PMPM: 'pmpm',
}

const modeType = {
  ALL_MODE: 'all_mode',
  PER_MODE: 'per_mode',
}
const billingFeeList = [
  {
    key: billingFeeType.COST_PLUS_FLAT_FEE,
    value: billingFeeType.COST_PLUS_FLAT_FEE,
    display: 'Cost Plus Flat Fee',
    calculation_type: 'monitoring_cost',
    short_name: 'cpff',
    ui_visible: true,
    sort_order: 1,
    mode_type_enable: true,
  },
  {
    key: billingFeeType.COST_PLUS_PERCENTAGE,
    value: billingFeeType.COST_PLUS_PERCENTAGE,
    display: 'Cost Plus Percentage %',
    calculation_type: 'monitoring_cost',
    short_name: 'cpp',
    ui_visible: true,
    sort_order: 2,
    mode_type_enable: true,
  },
  {
    key: billingFeeType.COST_PER_MILE,
    value: billingFeeType.COST_PER_MILE,
    display: 'Cost Per Mile',
    calculation_type: 'total_cost',
    short_name: 'cpm',
    ui_visible: true,
    sort_order: 3,
    mode_type_enable: true,
  }
]

const pmmFeeConfig = {
  key: billingFeeType.PMPM,
  value: billingFeeType.PMPM,
  display: 'PMPM',
  calculation_type: null,
  short_name: 'pmpm',
  ui_visible: true,
  sort_order: 4,
  mode_type_enable: false,
}

const billingFeeTypeList = {
  [costType.PRIMARY]: {
    key: costType.PRIMARY,
    value: costType.PRIMARY,
    display: 'Primary',
    sort_order: 1,
    ui_visible: true,
    billingFeeList: [...billingFeeList, pmmFeeConfig]
  },
  [costType.SECONDARY]: {
    key: costType.SECONDARY,
    value: costType.SECONDARY,
    display: 'Secondary',
    sort_order: 2,
    ui_visible: true,
    billingFeeList
  }
}
const billingFeeService = {
  billingFeeType,
  billingFeeTypeList,
  costType,
  modeType
}
Object.freeze(billingFeeService)
export default billingFeeService
