angular.module('relayHealth').controller('passordSettingsCntrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  'PASSWORD_PUBLIC_KEY',
  '$state',
  'toaster',
  'SweetAlert',
  '$stateParams',
  'loadingScreenFactory',
  function passwordSettingsCntrl($scope, $http, API_BASE_URL, PASSWORD_PUBLIC_KEY, $state, toaster, SweetAlert, $stateParams, loadingScreenFactory) {
    let user = JSON.parse(localStorage.getItem('userDetails'));
    $scope.confirmPassword = '';
    $scope.password = '';
    $scope.error_msg = '';
    $scope.error_msg_confirm = '';
    $scope.hasError = true;
    $scope.userDetails = {};

    const getUserDetailsCall = $http.get(`${API_BASE_URL}user/profile/${$stateParams.userId}`);
    getUserDetailsCall.then((result) => {
      if (result.data.success) {
        $scope.userDetails = result.data.data;
      }
    });

    $scope.validatePassword = () => {
      $scope.error_msg = '';
      $scope.error_msg_confirm = '';
      const ascii = /^[\x20-\x7E]+$/;
      const { password } = $scope;
      if (password.length < 8) {
        $scope.error_msg = 'Password length should be atleast 8';
        return;
      }
      if (password.match(/\s/g)) {
        $scope.error_msg = 'Password should not contain spaces';
        return;
      }
      if (password.search(/\d/) === -1) {
        $scope.error_msg = 'Password should contain a digit';
        return;
      }
      if (password.search(/[a-z]/) === -1) {
        $scope.error_msg = 'Password should contain a lower case letter';
        return;
      }
      if (password.search(/[A-Z]/) === -1) {
        $scope.error_msg = 'Password should contain an upper case letter';
        return;
      }
      if (password.search(/[!@#$%^&*()_+.,;:]/) === -1) {
        $scope.error_msg = 'Password should contain a special symbol';
        return;
      }
      if (!ascii.test(password)) {
        $scope.error_msg = 'Password should not contain control character or non printable character';
        return;
      }
      if (password.match(/[\\]/g)) {
        $scope.error_msg = 'Password should not contain backslash';
        return;
      }
      for (let i = 0; i + 5 <= $scope.userDetails.first_name.length; i += 1) {
        if (password.toLowerCase().indexOf($scope.userDetails.first_name.substring(i, i + 5).toLowerCase()) !== -1) {
          $scope.error_msg = "Password can not contain First name's significant portions";
          return;
        }
      }
      for (let i = 0; i + 5 <= $scope.userDetails.last_name.length; i += 1) {
        if (password.toLowerCase().indexOf($scope.userDetails.last_name.substring(i, i + 5).toLowerCase()) !== -1) {
          $scope.error_msg = "Password can not contain Last name's significant portions";
          return;
        }
      }
      for (let i = 0; i + 5 <= $scope.userDetails.username.length; i += 1) {
        if (password.toLowerCase().indexOf($scope.userDetails.username.substring(i, i + 5).toLowerCase()) !== -1) {
          $scope.error_msg = "Password can not contain username's significant portions";
          return;
        }
      }

      if ($scope.confirmPassword !== $scope.password) {
        if ($scope.confirmPassword && !$scope.error_msg) {
          $scope.error_msg_confirm = 'Password and confirm password do not match';
        }
        // if(!$scope.error_msg)
        //     $scope.error_msg = 'password and confirm password do not match';
        // return ;
        $scope.hasError = true;
      } else {
        $scope.error_msg = '';
        $scope.error_msg_confirm = '';
        $scope.hasError = false;
      }
    };

    $scope.checkConfirmPassword = () => {
      if ($scope.password === $scope.confirmPassword) {
        $scope.error_msg = '';
        $scope.error_msg_confirm = '';
        $scope.hasError = false;
        $scope.validatePassword();
      } else if (!$scope.err_msg_confirm) $scope.error_msg_confirm = 'Password and confirm password do not match';
    };

    $scope.changePassword = () => {
      // Encrypting passwords before sending to server
      const crypt = new JSEncrypt();
      crypt.setPublicKey(PASSWORD_PUBLIC_KEY);

      const newpassword = crypt.encrypt($scope.password, 'base64');

      const data = {
        password: newpassword,
      };
      const headers = {
        username: user.username,
        usertoken: user.user_token,
      };
      const res = $http.put(`${API_BASE_URL}user/setUserPassword/${$scope.userDetails.id}`, data, headers);
      loadingScreenFactory.showLoadingScreen();
      res.then(({ data: { success, message } }) => {
        loadingScreenFactory.hideLoadingScreen();
        if (!success) {
          $scope.confirmPassword = '';
          $scope.password = '';
          toaster.error({
            type: 'error',
            title: message,
            showCloseButton: true,
            timeout: 10000,
          });
          return;
        }
        toaster.success({
          type: 'success',
          title: message,
          showCloseButton: true,
          timeout: 10000,
        });
        $scope.confirmPassword = '';
        $scope.password = '';
        user.password_expired = false;
        localStorage.setItem('userDetails', JSON.stringify(user));
        user = JSON.parse(localStorage.getItem('userDetails'));
        $state.go('main.userMgmt.manageUser', { userId: $scope.userDetails.id });
      });
      res.catch(({ data: { message } }) => {
        loadingScreenFactory.hideLoadingScreen();
        $scope.confirmPassword = '';
        $scope.password = '';
        $scope.formSubmited = false;
        toaster.error({
          type: 'error',
          title: message,
          showCloseButton: true,
          timeout: 10000,
        });
      });
    };

    $scope.changePasswordCancel = () => {
      $state.go('main.userMgmt.manageUser', { userId: $scope.userDetails.id });
    };

    $scope.resetViaEmailClicked = () => {
      const http = $http.post(`${API_BASE_URL}user/forgotPassword`, { username: $scope.userDetails.username });
      loadingScreenFactory.showLoadingScreen();

      http.then(
        ({ data: { success } }) => {
          loadingScreenFactory.hideLoadingScreen();
          if (success) {
            SweetAlert.swal({
              title: 'Mail Sent',
              text: `Reset password mail sent successfully to ${$scope.userDetails.username}`,
              type: 'success',
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'OK',
              closeOnConfirm: true,
            });
            $state.go('main.userMgmt.manageUser', { userId: $scope.userDetails.id });
          }
        },
        () => loadingScreenFactory.hideLoadingScreen(),
      );
    };
  },
]);
