angular.module('relayHealth').controller('masLogCtrl', [
  '$scope',
  'API_BASE_URL',
  '$http',
  'loadingScreenFactory',
  '$uibModal',
  '$rootScope',
  '$state',
  '$timeout',
  'storageManager',
  function masLogCtrl($scope, API_BASE_URL, $http, loadingScreenFactory, $uibModal, $rootScope, $state, $timeout, storageManager) {
    const { storage } = storageManager;
    let firstTimeLoad = true;

    $scope.rideStatus = ['Select Status', 'Accepted', 'Rejected'];

    const initSearchForm = () => {
      $scope.searchFormQuery = {
        status: 'Select Status',
        recipient_phone_number: '',
        rider_name: '',
        dateRange: {
          startDate: null,
          endDate: null,
        },
        invoice_number: null,
      };
      $scope.MASLogQuery = {
        status: 'Select Status',
        recipient_phone_number: '',
        rider_name: '',
        dateRange: {
          startDate: null,
          endDate: null,
        },
        invoice_number: null,
      };
    };

    $scope.dateRangePickerOptions = {
      parentEl: '#current-app #page-wrapper',
    };

    $scope.moment = moment;

    $scope.sortBy = 'loggedAt';
    $scope.sortOrder = 1;
    const adjustPagination = () => {
      $scope.pagination.lastItem = ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage + $scope.pagination.itemsPerPage;
      if ($scope.pagination.lastItem > $scope.pagination.totalItems) {
        $scope.pagination.lastItem = $scope.pagination.totalItems;
      }
    };

    const getMASLogs = (pageNumber, limit, searchStr) => {
      const request = $http.get(`${API_BASE_URL}mas-service/trips?page=${pageNumber + 1}&limit=${limit}${searchStr}`);
      loadingScreenFactory.showLoadingScreen();
      request.then(
        (data) => {
          loadingScreenFactory.hideLoadingScreen();
          if (!data.data.success) {
            return;
          }
          $scope.auditLogs = data.data.data.trips;
          $scope.pagination.totalItems = data.data.data.total_count;
          firstTimeLoad = false;
          adjustPagination();
        },
        () => {
          loadingScreenFactory.hideLoadingScreen();
        },
      );
    };

    const getSearchString = () => {
      const searchFormQuery = angular.copy($scope.searchFormQuery);

      if (searchFormQuery.status === 'Select Status') {
        // remove if from
        delete searchFormQuery.status;
      }
      const searchStr = [];
      if (searchFormQuery.dateRange.startDate && searchFormQuery.dateRange.endDate) {
        if (typeof $scope.searchFormQuery.dateRange.startDate === 'object') {
          searchFormQuery.startDate = $scope.searchFormQuery.dateRange.startDate.format('MM-DD-YYYY 00:00:00');
          searchFormQuery.endDate = $scope.searchFormQuery.dateRange.endDate.format('MM-DD-YYYY 23:59:59');
        } else {
          searchFormQuery.startDate = $scope.searchFormQuery.dateRange.startDate;
          searchFormQuery.endDate = $scope.searchFormQuery.dateRange.endDate;
        }
      }
      delete searchFormQuery.dateRange;
      if (searchFormQuery.recipient_phone_number) {
        searchFormQuery.recipient_phone_number = `${searchFormQuery.recipient_phone_number.replace(/[^0-9]+/g, '')}`;
      }
      const searchFormQueryKeys = Object.keys(searchFormQuery);
      for (let keyIndex = 0; keyIndex < searchFormQueryKeys.length; keyIndex += 1) {
        const key = searchFormQueryKeys[keyIndex];
        if (searchFormQuery[key] && key !== 'currentPage') {
          searchStr.push(`${key}=${searchFormQuery[key]}`);
        }
      }
      const order = $scope.sortOrder ? 'DESC' : 'ASC';
      const orderArray = [[$scope.sortBy, order]];
      const orderString = encodeURI(JSON.stringify(orderArray));
      searchStr.push(`order=${orderString}`);

      let temp = searchStr.join('&');
      if (searchStr.length > 0) temp = `&${temp}`;

      return temp;
    };

    $scope.sort = (sortBy) => {
      if ($scope.sortBy === sortBy) {
        $scope.sortOrder = !$scope.sortOrder;
      }
      $scope.sortBy = sortBy;
      getMASLogs($scope.pagination.currentPage - 1, 10, getSearchString());
    };

    const initPagination = () => {
      $scope.pagination = {
        totalItems: 0,
        currentPage: 1,
        lastItem: 0,
        itemsPerPage: 10,
      };
    };

    function writeSearchFilter() {
      const prevSearchValues = storage.getData('filterDataMASLogs');
      if (!prevSearchValues) {
        return;
      }
      const prevForm = prevSearchValues;
      $scope.MASLogQuery.rider_name = prevForm.rider_name;
      $scope.MASLogQuery.status = prevForm.status;
      $scope.MASLogQuery.recipient_phone_number = prevForm.recipient_phone_number;
    }

    const init = () => {
      initPagination();
      initSearchForm();
      writeSearchFilter();

      function initFormAndLogs() {
        $timeout(() => {
          const stateParams = $state.params;
          // setting params into form
          angular.extend($scope.searchFormQuery, stateParams);
          if (angular.isUndefined(stateParams.status)) {
            $scope.searchFormQuery.status = 'Select Status';
          }
          $scope.MASLogQuery = $scope.searchFormQuery;
          writeSearchFilter();
          $scope.pagination.currentPage = angular.isDefined(stateParams.currentPage) ? parseInt(stateParams.currentPage, 10) + 1 : 1;
          getMASLogs($scope.pagination.currentPage - 1, 10, getSearchString());
        }, 0);
      }
      initFormAndLogs();
    };
    init();

    $scope.pageChanged = () => {
      if (!firstTimeLoad) {
        $state.go($state.current, { currentPage: $scope.pagination.currentPage - 1 });
        getMASLogs($scope.pagination.currentPage - 1, 10, getSearchString());
      }
    };
    $scope.showRideDetails = (MASLog, invoiceNumber) => {
      if (angular.isDefined(MASLog.rideDetails)) {
        Object.assign(MASLog, {
          rideDetails: undefined,
        });
        return;
      }

      const request = $http.get(`${API_BASE_URL}logs/getLogDetails/mas/${invoiceNumber}`);
      loadingScreenFactory.showLoadingScreen();
      request.then(
        (response) => {
          loadingScreenFactory.hideLoadingScreen();
          const { data } = response;
          if (data.success) {
            Object.assign(MASLog, {
              rideDetails: data.logDetails,
            });
          }
        },
        () => {
          loadingScreenFactory.hideLoadingScreen();
        },
      );
    };

    $scope.showMeta = (MASLog) => {
      const modalInstance = $uibModal.open({
        animation: true,
        template: require('../../sub-modules/logs/log-details.html'),
        size: 'lg',
        backdrop: 'static',
        resolve: {
          meta() {
            const { _source } = MASLog;
            return angular.copy(_source);
          },
        },
        controllerAs: 'logDetailsCtrl',
        controller: [
          'meta',
          '$uibModalInstance',
          function logDetailsCtrl(meta, $uibModalInstance) {
            Object.assign(this, {
              meta,
              closePopup() {
                $uibModalInstance.close();
              },
            });
          },
        ],
      });
      $scope.$on('$destroy', () => {
        modalInstance.close();
      });
    };

    function getStateParams() {
      const stateParams = angular.copy($scope.searchFormQuery);
      if (stateParams.status === 'Select Status') {
        delete stateParams.status;
      }
      return stateParams;
    }

    $scope.filterMASLogs = () => {
      $scope.searchFormQuery = angular.copy($scope.MASLogQuery);
      const stateParams = getStateParams();
      stateParams.currentPage = 0;
      $state.go($state.current, stateParams);
      storage.setData('filterDataMASLogs', stateParams);
      getMASLogs(0, 10, getSearchString());
    };

    $scope.clearFilters = () => {
      storage.removeData('filterDataMASLogs');
      initSearchForm();
      getMASLogs(0, 10, getSearchString());
      const stateParams = {};
      angular.extend(stateParams, $scope.searchFormQuery);
      stateParams.status = '';
      stateParams.currentPage = 0;
      $scope.pagination.currentPage = 1;
      $state.go($state.current, stateParams);
    };

    $scope.enterOnFormSearch = (event) => {
      if (event.keyCode === 13) {
        $scope.pagination.currentPage = 1;
        $scope.searchFormQuery = angular.copy($scope.MASLogQuery);
        const stateParams = getStateParams();
        stateParams.currentPage = 0;
        $state.go($state.current, stateParams);
        getMASLogs(0, 10, getSearchString());
      }
    };
  },
]);
