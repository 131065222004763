angular.module('relayHealth')
  .controller('rideOrderOutOfNetworkCtrl', [
    '$scope',
    'API_BASE_URL',
    '$http',
    'toaster',
    '$state',
    'orderDetails',
    '$uibModalInstance',
    function rideOrderOutOfNetworkCtrl($scope, API_BASE_URL, $http, toaster, $state, orderDetails, $uibModalInstance) {
      this.outOfNetworkDetails = {
        apptId: orderDetails.appt_id,
        orderId: orderDetails.orderId,
        outOfNetworkNote: null,
      };

      this.confirmDisable = false;

      this.closeModal = () => {
        $uibModalInstance.dismiss('cancel');
      };

      this.confirmOutOfNetwork = async () => {
        if (!this.outOfNetworkDetails.outOfNetworkNote) {
          toaster.pop({
            type: 'error',
            title: 'Notes are required to mark order as Out Of Network.',
            timeout: 3000,
          });
          return;
        }
        const requestData = {
          type: 'out of network',
          note: this.outOfNetworkDetails.outOfNetworkNote,
        };

        this.confirmDisable = true;
        const {
          apptId,
        } = this.outOfNetworkDetails;
        $http
          .put(`${API_BASE_URL}vendors/appt/${apptId}/mark-as-OON`, requestData)
          .then(() => {
            toaster.pop({
              type: 'success',
              title: 'Ride order marked as Out Of Network',
              timeout: 3000,
            });
            $state.go('main.dashboard.superAdminDashboard');
          })
          .catch((errorResult) => {
            toaster.pop({
              type: 'error',
              title: `Error while Updating Ride Status: ${errorResult.data.message}`,
              timeout: 3000,
            });
            this.confirmDisable = false;
          });
      };
    },
  ]);
