import StorageProvider from '../storageProvider';
import Command from '../../common/command';

export default class SetItem extends Command {
  constructor() {
    super();
  }
  execute(key,data){
    const storageType = StorageProvider.instance.storageType;
    const storageObj = new storageType();
    storageObj.setItem(key,data);
    }
  }

