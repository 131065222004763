import angular from 'angular';

const watcherRemovers = [];

const timerRemovers = [];

const controller = [
  '$scope',
  '$element',
  '$window',
  '$document',
  '$timeout',
  function affixController($scope, $element, $window, $document, $timeout) {
    const elemTopOffset = $scope.topOffset;
    // Restore affix to its original position
    $scope.resetAffix = function resetAffix() {
      $scope.resetDimention();
      // Set topOffset if not set
      if (angular.isUndefined(elemTopOffset)) {
        $scope.topOffset = $element.offset().top;
      }
      // Set bottomOffset according to relational-div or set default
      if (angular.isDefined($scope.relatedContainer)) {
        const relatedContainer = $($scope.relatedContainer);
        $scope.bottomOffset = $document[0].body.scrollHeight - relatedContainer.offset().top - relatedContainer.height();
      } else if (angular.isUndefined($scope.bottomOffset)) {
        $scope.bottomOffset = 0;
      }
      // Completely reset affix if already applied
      let currentClasses = '';
      if ($element.hasClass('affix') || $element.hasClass('affix-bottom') || $element.hasClass('affix-top')) {
        currentClasses = $element.attr('class');
        $element.removeClass('affix affix-bottom affix-top').css('top', '0px');

        if (angular.isUndefined(elemTopOffset)) {
          $scope.topOffset = $element.offset().top;
        }
        const { offset } = $element.data('bs.affix').options;
        offset.top = $scope.topOffset;
        offset.bottom = $scope.bottomOffset;
        $element.attr('class', currentClasses);
        $element.affix('checkPosition');
      }
      timerRemovers.push($timeout(() => {
        angular.element($window).scroll();
      }, 200));
    };
    // Reset Dimentions
    $scope.resetDimention = function resetDimention() {
      if (angular.isDefined($scope.fullHeightOffset)) {
        if (angular.element($window).width() > $scope.fullHeightOffset) {
          $element.height(angular.element($window).height());
          $element.width($element.parent('div:eq(0)').width());
        } else {
          $element.height('auto');
          $element.width('auto');
        }
      }
    };
    // Create Affix
    $scope.createAffix = function createAffix() {
      $element.affix({
        offset: {
          top: $scope.topOffset,
          bottom: $scope.bottomOffset,
        },
      });
    };
  }];

const directiveDef = ['$window',
  '$timeout',
  '$document',
  function affixDirective($window, $timeout, $document) {
    return {
      restrict: 'A',
      scope: {
        relatedContainer: '@?',
        bottomOffset: '@?',
        topOffset: '@?',
        fullHeightOffset: '@?',
      },
      controller,
      link($scope) {
        timerRemovers.push($timeout(() => {
          $scope.resetAffix();
          $scope.createAffix();
          // Reset affix offset on-change of document height
          watcherRemovers.push($scope.$watch(() => $document.height(),
            (newValue, oldValue) => {
              if (newValue !== oldValue) {
                $scope.resetAffix();
              }
            }));
          // May be on window-resize document height does not change, only width changes
          angular.element($window).on('resize', $scope.resetDimention);
          watcherRemovers.push($scope.$on('resetAffix', () => {
            $scope.resetAffix();
          }));
        }, 1000));
        // Destroy watchers & timers
        $scope.$on('$destroy', () => {
          angular.element($window).off('resize', $scope.resetDimention);
          angular.forEach(watcherRemovers, (w) => {
            w();
          });
          angular.forEach(timerRemovers, (t) => {
            $timeout.cancel(t);
          });
        });
      },
    };
  },
];

export default directiveDef;
