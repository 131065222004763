angular
  .module('relayHealth')
  .directive('backButton', () => ({
    restrict: 'A',
    link(scope, element) {
      element.on('click', () => {
        history.back();
        scope.$apply();
      });
    },
  }))
  .directive('spinnerHtml', [
    function spinnerHtml() {
      return {
        restrict: 'E',
        template:
          '<div class="sk-spinner sk-spinner-fading-circle"> <div class="sk-circle1 sk-circle"> </div> <div class="sk-circle2 sk-circle" > </div> <div class="sk-circle3 sk-circle" > </div> <div class="sk-circle4 sk-circle" > </div> <div class="sk-circle5 sk-circle" > </div> <div class="sk-circle6 sk-circle" > </div> <div class="sk-circle7 sk-circle" > </div> <div class="sk-circle8 sk-circle" > </div><div class="sk-circle9 sk-circle"></div> <div class="sk-circle10 sk-circle" > </div><div class="sk-circle11 sk-circle"></div> <div class="sk-circle12 sk-circle" > </div></div>',
        link() {},
      };
    },
  ])
  .factory('loadingScreenFactory', [
    '$rootScope',
    function loadingScreenFactory($rootScope) {
      const loadingScreenFactoryObject = {};
      loadingScreenFactoryObject.showLoadingScreen = function () {
        $rootScope.showLoading = true;
      };
      loadingScreenFactoryObject.hideLoadingScreen = function () {
        $rootScope.showLoading = false;
      };
      return loadingScreenFactoryObject;
    },
  ])
  .directive('loadingScreen', [
    function loadingScreen() {
      return {
        restrict: 'E', // E = Element, A = Attribute, C = Class, M = Comment
        template: `<div ng-if="showLoading" class="loading">
            <img src="${require('../img/relay-wheel.svg')}"/>
          </div>`,
      };
    },
  ])
  .directive('scrollDetector', [function () {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            const scopeElement = element[0];
            element.bind('scroll', function () {
                if (scopeElement.scrollTop + scopeElement.offsetHeight >= scopeElement.scrollHeight) {
                   scope.$apply(attrs.scrollDetector);
                }
            });
        }
    };
}])
  .directive('compareTo', [
    function compareTo() {
      return {
        require: 'ngModel',
        scope: {
          otherModelValue: '=compareTo',
        },
        link(scope, element, attributes, ngModel) {
          ngModel.$validators.compareTo = function (modelValue) {
            return modelValue == scope.otherModelValue;
          };

          scope.$watch('otherModelValue', () => {
            ngModel.$validate();
          });
        },
      };
    },
  ])
  .directive('uiSelectSetTouched', [
    function uiSelectSetTouched() {
      return {
        require: 'uiSelect',
        restrict: 'A',
        link($scope, el, attrs, uiSelect) {
          angular.element(uiSelect.focusser).on('blur', () => {
            uiSelect.ngModel.$setTouched();
          });
        },
      };
    },
  ])
  .directive('clearSelect', [
    '$rootScope',
    function clearSelect($rootScope) {
      return {
        link(scope, element, attributes, ngModel) {
          $rootScope.$on('selectClear', (event, data) => {
            scope.$select.selected = {};
          });
        },
      };
    },
  ])
  .directive('notNull', [
    function notNull() {
      return {
        require: 'ngModel',
        link(scope, element, attributes, ngModel) {
          element.on('blur', () => {
            if (angular.isUndefined(ngModel.$modelValue)) ngModel.$setDirty();
          });

          ngModel.$validators.notNull = function (modelValue) {
            return modelValue != null;
          };
        },
      };
    },
  ])
  .directive('touchStopPropagation', [
    function touchStopPropagation() {
      return {
        scope: {},
        link(scope, element) {
          element.on('touchstart', (event) => {
            event.stopPropagation();
          });
        },
      };
    },
  ])
  .directive('phoneRequireValidity', [
    '$timeout',
    function phoneRequireValidity($timeout) {
      return {
        require: 'ngModel',
        link(scope, element, attributes, ngModel) {
          // firing validation on country change
          element.on('countrychange', () => {
            ngModel.$validate();
          });

          element.on('blur', () => {
            $timeout(() => {
              if (ngModel.$$rawModelValue == '') {
                ngModel.$setViewValue('');
              }
            }, 0);
          });
        },
      };
    },
  ])
  .directive('triggerClickOnTableHeader', [
    '$timeout',
    function triggerClickOnTableHeader($timeout) {
      return {
        link() {
          // this directive has been made to solve a front-end ui issue
          // foo table plugin does not paginate the table initially
          // and shows the complete list of data
          // here we trigger a click on the firstName table header
          // which sorts and paginates the table
          const firstName = document.getElementById('tableFirstName');
          $timeout(() => {
            firstName.click();
          }, 100);
        },
      };
    },
  ])
  .directive('signupWizardBar', [
    '$rootScope',
    function signupWizardBar($rootScope) {
      return {
        link(scope) {
          $rootScope.$on('signupStepChanged', (event, data) => {
            const currentElement = document.querySelector(`[data-stage=${data.stage}]`);
            currentElement.className = 'allowed active';
            const previousElement = document.querySelector(`[data-stage=${scope.previousStage}]`);
            previousElement.className = 'allowed';
          });
        },
      };
    },
  ])
  .directive('popOver', [
    '$rootScope',
    function popOver($rootScope) {
      return {
        link(scope, element, attributes, ngModel) {
          $('body').on('click', (e) => {
            if ($(e.target).data('toggle') !== 'popover' && $(e.target).parents('.popover.in').length === 0) {
              $('[data-toggle="popover"]').popover('hide');
            }
          });
          element.popover({ html: true, container: 'body', placement: 'auto' });
        },
      };
    },
  ])
  .directive('toolTip', [
    function toolTip() {
      return {
        link() {
          $('[data-toggle="tooltip"]').tooltip({ container: 'body', html: true });
        },
      };
    },
  ])
  .directive('popOver', [
    function popOver() {
      return {
        link() {
          $('[data-toggle="popover"]').popover({ container: 'body', html: true });
        },
      };
    },
  ])
  .directive('signupWizardBarStep', [
    '$rootScope',
    function signupWizardBarStep($rootScope) {
      return {
        link(scope, element, attributes, ngModel) {
          element.on('click', () => {
            const latestStage = scope.latestStage || 1;
            const nextPageNum = parseInt(attributes.stage.match(/\d+/)[0]) || 0;
            const currentPageNum = parseInt(scope.currentStage.match(/\d+/)[0]) || 0;
            if (currentPageNum == nextPageNum) {
              return false;
            }
            if (nextPageNum <= latestStage) {
              const currentElement = document.querySelector(`[data-stage=${scope.currentStage}]`);
              currentElement.className = 'allowed active';
              const previousElement = document.querySelector(`[data-stage=${scope.previousStage}]`);
              previousElement.className = 'allowed';

              $rootScope.$broadcast('signupWizardBarStepClicked', { attributes });
            } else {
              setTimeout(() => {
                // var previousElement = document.querySelector("[data-stage=" + scope.currentStage + "]");
                // previousElement.className = 'active';
                // element.removeClass('active');
                const previousElement = document.querySelector(`[data-stage=${scope.previousStage}]`);
                previousElement.className = 'allowed';
                const nextStage = attributes.stage;
                const nextElement = document.querySelector(`[data-stage=${nextStage}]`);
                if (!nextElement.classList.contains('allowed')) {
                  nextElement.className = '';
                }
              }, 100);
            }
          });
        },
      };
    },
  ])
  .directive('stripeAddCard', [
    '$timeout',
    '$rootScope',
    '$http',
    'API_BASE_URL',
    'STRIPE_KEY_PUBLISHABLE',
    'loadingScreenFactory',
    '$state',
    function stripeAddCard($timeout, $rootScope, $http, API_BASE_URL, STRIPE_KEY_PUBLISHABLE, loadingScreenFactory, $state) {
      return {
        link(scope, element, attributes, ngModel) {
          // firing validation on country change
          const stripe = Stripe(STRIPE_KEY_PUBLISHABLE);
          const elements = stripe.elements();
          const style = {
            base: {
              color: '#32325d',
              lineHeight: '24px',
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSmoothing: 'antialiased',
              fontSize: '16px',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#fa755a',
              iconColor: '#fa755a',
            },
          };
          const addAlertToErrorMessage = function (message) {
            return `<div class="alert alert-danger margin-top-10">${message}</div>`;
          };

          const card = elements.create('card', { style, hidePostalCode: true });
          const clearButton = document.getElementsByClassName('clear-button');
          if (clearButton && clearButton[0]) {
            clearButton[0].addEventListener('click', () => {
              card.clear();
            });
          }
          const addCardButton = document.getElementsByClassName('addCard');
          if (addCardButton && addCardButton.length && addCardButton[0].addEventListener) {
            addCardButton[0].addEventListener('click', (event) => {
              $rootScope.$broadcast('stripeCardAddStart');
              const cardErrorsElement = document.getElementById('card-errors');
              const errorText = cardErrorsElement.innerHTML;
              if (errorText === '') {
                loadingScreenFactory.showLoadingScreen();
                const cardData = {
                  address_line1: scope.state.billingAddress.address1,
                  address_city: scope.state.billingAddress.city,
                  address_state: scope.state.billingAddress.state.state_name,
                  address_zip: scope.state.billingAddress.zip,
                  name: scope.state.cardHolderName,
                };
                stripe
                  .createToken(card, cardData)
                  .then((result) => {
                    if (result.error) {
                      const errorElement = document.getElementById('card-errors');
                      errorElement.textContent = result.error.message;
                      loadingScreenFactory.hideLoadingScreen();
                      $rootScope.$broadcast('stripeCardError', { message: result.error.message });
                    } else {
                      // send the token to the server
                      let ownerId = scope.state.userId;
                      let ownerType = 'user';
                      const signupType = scope.state.signupType;
                      if (signupType === 'business' || signupType === 'rider') {
                        ownerType = 'org';
                        if (signupType === 'business') {
                          ownerId = scope.state.businessInfo.orgId;
                        } else {
                          ownerId = scope.state.accountInfo.orgId;
                        }
                      }
                      const payload = {
                        paymentType: 2,
                        token: result.token.id,
                        monthlyInvoice: scope.state.monthlyInvoice,
                        invoiceProfile: true,
                        makeDefaultMethod: true,
                        ownerId,
                        ownerType,
                      };

                      $http
                        .post(`${API_BASE_URL}paymentProfiles/card/add`, payload)
                        .then(({
                          data,
                        }) => {
                          if (data.success) {
                            loadingScreenFactory.hideLoadingScreen();
                            const cardNumber = `************${data.data.last4}`;
                            const expMonth = data.data.exp_month;
                            const expYear = data.data.exp_year;
                            scope.maskedStripeCardNumber = cardNumber;
                            scope.updateStripeCard = false;
                            $rootScope.$broadcast('stripeCardAdded', { cardNumber, expMonth, expYear });
                            // mask the card number and store it in localstorage
                          } else {
                            loadingScreenFactory.hideLoadingScreen();
                            $rootScope.$broadcast('stripeCardError', { message: data.message });
                          }
                        })
                        .catch(({
                          data, success, headers, config,
                        }) => {
                          loadingScreenFactory.hideLoadingScreen();
                          $rootScope.$broadcast('stripeCardError', { message: 'Could not add your card details' });
                        });
                    }
                  })
                  .catch((error) => {
                    loadingScreenFactory.hideLoadingScreen();
                    $rootScope.$broadcast('stripeCardError', { message: error.message });
                  });
              } else {
                loadingScreenFactory.hideLoadingScreen();
                $rootScope.$broadcast('stripeCardError', { message: errorText });
              }
            });
          }

          const updateCardButton = document.getElementsByClassName('updateCard');
          if (updateCardButton && updateCardButton.length && updateCardButton[0].addEventListener) {
            updateCardButton[0].addEventListener('click', (event) => {
              $rootScope.$broadcast('stripeCardAddStart');
              const cardErrorsElement = document.getElementById('card-errors');
              const errorText = cardErrorsElement.innerHTML;
              if (errorText === '') {
                const cardData = {
                  address_line1: scope.state.billingAddress.address1,
                  address_city: scope.state.billingAddress.city,
                  address_state: scope.state.billingAddress.state.state_name,
                  address_zip: scope.state.billingAddress.zip,
                  name: scope.state.cardHolderName,
                };
                stripe
                  .createToken(card, cardData)
                  .then((result) => {
                    if (result.error) {
                      const errorElement = document.getElementById('card-errors');
                      errorElement.textContent = result.error.message;
                      $rootScope.$broadcast('stripeCardError', { message: result.error.message });
                    } else {
                      // send the token to the server
                      let ownerId = scope.state.userId;
                      let ownerType = 'user';
                      const data = {
                        paymentType: 2,
                        ownerId,
                        ownerType,
                        token: result.token.id,
                        monthlyInvoice: scope.state.monthlyInvoice,
                        invoiceProfile: true,
                        makeDefaultMethod: true,
                      };

                      $http
                        .post(`${API_BASE_URL}paymentProfiles/card/add`, data)
                        .then(({
                          apiResult, success, headers, config,
                        }) => {
                          if (apiResult.success) {
                            const cardNumber = `************${apiResult.data.last4}`;
                            const expMonth = apiResult.data.exp_month;
                            const expYear = apiResult.data.exp_year;
                            scope.maskedStripeCardNumber = cardNumber;
                            scope.updateStripeCard = false;
                            $rootScope.$broadcast('stripeCardAdded', { cardNumber, expMonth, expYear });
                            // mask the card number and store it in localstorage
                          } else {
                            $rootScope.$broadcast('stripeCardError', { message: apiResult.message });
                          }
                        })
                        .catch(({
                          apiResult, success, headers, config,
                        }) => {
                          $rootScope.$broadcast('stripeCardError', { message: 'Could not add your card details' });
                        });
                    }
                  })
                  .catch((error) => {
                    $rootScope.$broadcast('stripeCardError', { message: error.message });
                  });
              } else {
                $rootScope.$broadcast('stripeCardError', { message: errorText });
              }
            });
          }
          const addCardEditPage = document.getElementsByClassName('addCardEditPage');
          if (addCardEditPage.length != 0) {
            addCardEditPage[0].addEventListener('click', (event) => {
              const cardErrorsElement = document.getElementById('card-errors');
              const errorText = cardErrorsElement.innerHTML;
              if (errorText === '') {
                stripe
                  .createToken(card)
                  .then((result) => {
                    if (result.error) {
                      const errorElement = document.getElementById('card-errors');
                      errorElement.textContent = result.error.message;
                      $rootScope.$broadcast('stripeCardErroronPaymentPage', { message: result.error.message });
                    } else {
                      $rootScope.$broadcast('stripeCardAddedonPaymentPage', { token: result.token.id });
                    }
                  })
                  .catch((error) => {
                    $rootScope.$broadcast('stripeCardError', { message: error.message });
                  });
              }
            });
          }

          card.mount('#card-element');
          if (card) {
            card.addEventListener('change', (event) => {
              const displayError = document.getElementById('card-errors');
              if (event.error) {
                displayError.innerHTML = addAlertToErrorMessage(event.error.message);
              } else {
                displayError.innerHTML = '';
              }
            });
          }
        },
      };
    },
  ])
  .directive('dateSetValidity', [
    function dateSetValidity() {
      return {
        require: 'ngModel',
        link(scope, element, attributes, ngModel) {
          ngModel.$setDirty();
          ngModel.$setValidity();
        },
      };
    },
  ])
  .directive('updatingTime', [
    '$interval',
    $interval => ({
      scope: {
        timeStamp: '@timeStamp',
      },
      link(scope) {
        scope.newTimeStamp = moment(parseInt(scope.timeStamp)).fromNow();
        $interval(() => {
          scope.newTimeStamp = moment(parseInt(scope.timeStamp)).fromNow();
        }, 10000);
      },
      template: '<span class="pull-right text-muted small">{{newTimeStamp}}</span>',
      replace: true,
    }),
  ])
  .directive('tileView', [
    '$rootScope',
    ($rootScope) => {
      'ngInject';

      return {
        template: require('../views/tileView.html'),
        scope: {
          externalOrderId:'@',
          externalId:'@',
          tileNumber: '@',
          entityName: '@',
          //   sourceLatLongs: "=",
          //  destLatLongs: "=",
          status: '@',
          eventDateTime: '@',
          fileNumber: '@',
          phone: '@',
          footerType: '@',
          appointmentId: '@',
          screen: '@',
          eta: '@',
          mapImagePath: '@',
          timezone: '@',
          providerId: '@',
          bookNow: '@',
          tripTimeType: '@',
          fileNumberLabel: '@',
          pocPhone: '@',
          showPoc: '@',
          recurrenceDescription: '@',
          orgType: '@',
          zipPhone: '@',
          orgName: '@',
        },
        controller: [
          '$scope',
          ($scope) => {
            'ngInject';

            $scope.moment = moment;
            $scope.Number = Number;
            $scope.objFilePath = $rootScope.objFilePath;
            const footerColor = {
              info: '#23c6c8',
              plain: '#e7eaec',
              danger: '#ed5565',
              warn: '#F9A825',
              success: '#1c84c6',
              pending: 'yellow',
              purple: 'purple',
            };
            $scope.getStyle = function () {
              const color = $scope.footerType === 'plain' || $scope.footerType === 'pending' ? 'black' : 'white';
              const style = {
                color,
                'font-weight': 'bold',
                'background-color': footerColor[$scope.footerType],
              };
              return style;
            };
          },
        ],
        link(scope, element, attrs) {
          // var markers = [];
          // var latlong = []

          // var map = new google.maps.Map(element.find(".tileMap")[0], {
          //     zoom: 12,
          //     draggable: false,
          //     disableDefaultUI: true,
          //     scrollwheel: false,
          //     disableDoubleClickZoom: true,
          // });

          // var sourcelatLong = new google.maps.LatLng(scope.sourceLatLongs[0], scope.sourceLatLongs[1]);
          // latlong.push(sourcelatLong);
          // markers.push(new google.maps.Marker({
          //     position: sourcelatLong,
          //     map: map,
          //     label: 'S',
          //     icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
          // }));
          // var destLatLong = new google.maps.LatLng(scope.destLatLongs[0], scope.destLatLongs[1]);
          // latlong.push(destLatLong);
          // markers.push(new google.maps.Marker({
          //     position: destLatLong,
          //     map: map,
          //     label: 'D'
          //         // icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
          // }));
          // var bounds = new google.maps.LatLngBounds();
          // markers.forEach(function(marker) {
          //     bounds.extend(marker.position);
          // })

          // google.maps.event.trigger(map, 'resize');
          // map.fitBounds(bounds);

          scope.getEtaLabel = function () {
            if (
              [
                'Patient Enroute',
                'Rider Enroute',
                'Failed CC Payment',
                'Variance Issue',
                'Request Overdue',
                'Rider En Route',
                'Patient En Route',
                'Ride En Route-Late Arrival',
              ].indexOf(scope.status) != -1
            ) {
              return 'ETA to Destination';
            }
            return 'ETA to Pickup';
          };
        },
      };
    },
  ])
  .directive('uiSelectRequired', () => ({
    require: 'ngModel',
    link(scope, element, attrs, ctrl) {
      ctrl.$validators.uiSelectRequired = function (modelValue, viewValue) {
        return !_.isEmpty(modelValue);
      };
    },
  }))
  .directive('pullToRefresh', [
    function () {
      return {
        scope: {
          callback: '=callback',
          args: '@args',
        },
        link(scope, element, attrs, ctrl) {
          const myElement = element[0];
          // create a simple instance
          // by default, it only adds horizontal recognizers
          const mc = new Hammer.Manager(myElement, {
            touchAction: 'auto',
            recognizers: [
              [
                Hammer.Pan,
                {
                  direction: Hammer.DIRECTION_VERTICAL,
                },
              ],
            ],
          });

          // let the pan gesture support all directions.
          // this will block the vertical scrolling on a touch-device while on the element
          mc.get('pan').set({
            direction: Hammer.DIRECTION_ALL,
          });

          const refresh = function (event) {
            scope.callback(scope.args);
          };
          // listen to events...
          let timer;
          mc.on('pandown', (ev) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
              refresh(ev);
            }, 150);
          });
        },
      };
    },
  ])
  .directive('dropDownComponent', [
    function () {
      return {
        template: '',
        scope: true,
        link(scope, element, attrs, ctrl) {},
      };
    },
  ])
  .directive('formComponent', [
    function () {
      return {
        template: require('../views/formComponentTemplate.html'),
        scope: {
          type: '@',
          titleMap: '=',
          withRadio: '@',
          groupName: '@',
          radioModel: '=',
          options: '=',
        },
        controller: [
          '$scope',
          ($scope) => {
            'ngInject';

            const componentType = {
              select: {
                text: 'Drop Down Box',
              },
              radio: {
                text: 'Radio Buttons',
              },
              checkbox: {
                text: 'Check Box',
              },
              text: {
                text: 'Free Text',
              },
              date:{
                text: 'Date',
              }
            };
            $scope.title = componentType[$scope.type].text;
          },
        ],
        link(scope, element, attrs, ctrl) {
          const option = {
            text: '',
          };

          scope.onAdd = function () {
            scope.options.push(angular.copy(option));
          };
          scope.onMinus = function (index) {
            scope.options.splice(index, 1);
          };
          scope.setModelValue = function () {
            scope.options = [];
            scope.radioModel = scope.type;
          };
        },
      };
    },
  ])
  .directive('invoiceMap', [
    function () {
      return {
        scope: {
          ride_detail: '=rideDetails',
        },
        template: '<div class="invoice-page-map"></div> <button style="display:none;" id="mapbutton-{{index}}" ng-click="resizeMap()">Test</button>',
        controller: [
          '$scope',
          'googleService',
          function ($scope, googleService) {
            $scope.sourceIcon = googleService.sourceIcon;
          },
        ],
        link(scope, element) {
          let map;
          const markers = [];
          scope.resizeMap = function () {
            google.maps.event.trigger(map, 'resize');
            const bounds = new google.maps.LatLngBounds();
            markers.forEach((marker) => {
              bounds.extend(marker.position);
            });
            map.fitBounds(bounds);
          };
          const createSourceMap = function () {
            map = new google.maps.Map(element[0].querySelector('.invoice-page-map'), {
              zoom: 12,
            });
            if (scope.ride_detail.source_lat_long && scope.ride_detail.source_lat_long.length) {
              const sourcelatLong = new google.maps.LatLng(scope.ride_detail.source_lat_long[0], scope.ride_detail.source_lat_long[1]);
              markers.push(
                new google.maps.Marker({
                  position: sourcelatLong,
                  map,
                  label: 'S',
                  icon: scope.sourceIcon,
                }),
              );
            }
            if (scope.ride_detail.dest_lat_long && scope.ride_detail.dest_lat_long.length) {
              const destLatLong = new google.maps.LatLng(scope.ride_detail.dest_lat_long[0], scope.ride_detail.dest_lat_long[1]);
              markers.push(
                new google.maps.Marker({
                  position: destLatLong,
                  map,
                  label: 'D',
                  // icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
                }),
              );
            }
            const bounds = new google.maps.LatLngBounds();
            markers.forEach((marker) => {
              bounds.extend(marker.position);
            });

            google.maps.event.trigger(map, 'resize');
            map.fitBounds(bounds);

            if (scope.ride_detail && scope.ride_detail.route_lat_long && scope.ride_detail.route_lat_long.length > 0) {
              const flightPlanCoordinates = scope.ride_detail.route_lat_long;
              const flightPath = new google.maps.Polyline({
                path: flightPlanCoordinates,
                geodesic: true,
                strokeColor: '#FF0000',
                strokeOpacity: 1.0,
                strokeWeight: 2,
              });
              flightPath.setMap(map);
            }
          };
          createSourceMap();
        },
      };
    },
  ])
  .directive('tableResponsiveContainerScroll', [
    '$timeout',
    function ($timeout) {
      return {
        scope: {},
        link(scope, element, attrs, ctrl) {
          let scrollDirection;
          let previousScroll = 0;
          let timer;

          function isElementInViewport(el) {
            const rect = el.getBoundingClientRect();

            const winWidth = window.innerWidth || document.documentElement.clientWidth;

            const winHeight = window.innerHeight || document.documentElement.clientHeight;

            return rect.bottom > 0 && rect.right > 0 && rect.left < winWidth && /* or $(window).width() */ rect.top < winHeight;
          }

          function refresh() {
            const temp = isElementInViewport(element[0]);
            let top = element.find('.table-indicator')[0].style.top;

            top = top.replace('%', '');
            top = parseInt(top);
            if (temp && scrollDirection === 'DOWN' && top <= 53) {
              // down scroll
              element.find('.table-indicator').animate({
                top: '+=5%',
              });
            } else if (temp && scrollDirection === 'UP' && top >= 0) {
              // up scroll
              if (top - 5 <= 0) {
                element.find('.table-indicator').animate({
                  top: '0%',
                });
              } else {
                element.find('.table-indicator').animate({
                  top: '-=5%',
                });
              }
            } else {
              // If element is not in viewport, do nothing
            }
          }

          $(window).on('scroll.tableResponsiveContainerScroll', function (event) {
            const currentScroll = $(this).scrollTop();
            if (currentScroll > previousScroll) {
              scrollDirection = 'DOWN';
            } else {
              scrollDirection = 'UP';
            }

            previousScroll = currentScroll;
            clearTimeout(timer);
            timer = setTimeout(refresh, 150);
          });

          scope.$on('$destroy', () => {
            $(window).off('scroll.tableResponsiveContainerScroll');
            clearTimeout(timer);
          });
        },
      };
    },
  ])
  .directive('dynamicScript', () => ({
    scope: {},
    link(scope, element, attrs, ctrl) {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = `${attrs.src}?cache_burst=${Math.random()}`;
      element.append(s);
    },
  }))
  .directive('showToasterOnClick', [
    'toaster',
    function showToasterOnClick(toaster) {
      return {
        link(scope, element, attrs, ctrl) {
          element.on('click', (event) => {
            toaster.clear();
            if (!scope.state.acceptTc) {
              toaster.pop({
                type: 'info',
                title: 'Please read and accept the terms of service before continuing',
                showCloseButton: true,
                timeout: 100,
              });
            }
          });
        },
      };
    },
  ])
  .directive('addCard', [
    '$timeout',
    '$rootScope',
    '$http',
    'API_BASE_URL',
    'STRIPE_KEY_PUBLISHABLE',
    'loadingScreenFactory',
    '$state',
    function addCard($timeout, $rootScope, $http, API_BASE_URL, STRIPE_KEY_PUBLISHABLE, loadingScreenFactory, $state) {
      return {
        link(scope, element, attributes, ngModel) {
          // firing validation on country change
          const stripe = Stripe(STRIPE_KEY_PUBLISHABLE);
          const elements = stripe.elements();
          const style = {
            base: {
              color: '#32325d',
              lineHeight: '24px',
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSmoothing: 'antialiased',
              fontSize: '16px',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#fa755a',
              iconColor: '#fa755a',
            },
          };
          const addAlertToErrorMessage = function (message) {
            return `<div class="alert alert-danger margin-top-10">${message}</div>`;
          };

          const card = elements.create('card', { style, hidePostalCode: true });

          $rootScope.$on('addCard', () => {
            const cardErrorsElement = document.getElementById('card-errors');
            const errorText = cardErrorsElement.innerHTML;
            if (errorText === '') {
              loadingScreenFactory.showLoadingScreen();
              const stateName = scope.selectedState ? scope.selectedState.state_name : '';
              const cardData = {
                address_line1: scope.state.billingAddress.address1,
                address_city: scope.state.billingAddress.city,
                address_state: stateName,
                address_zip: scope.state.billingAddress.zip,
                name: scope.state.cardHolderName,
              };
              stripe
                .createToken(card, cardData)
                .then((result) => {
                  if (result.error) {
                    const errorElement = document.getElementById('card-errors');
                    errorElement.textContent = result.error.message;
                    loadingScreenFactory.hideLoadingScreen();
                    $rootScope.$broadcast('stripeCardError', { message: 'Could not add your card details' });
                  } else {
                    // send the token to the server
                    let ownerId = scope.state.userId;
                    let ownerType = 'user';
                    const signupType = scope.state.signupType;
            
                    if (signupType === 'business' || signupType === 'rider') {
                      ownerType = 'org';
                      if (signupType === 'business') {
                        ownerId = scope.state.businessInfo.orgId;
                      } else {
                        ownerId = scope.state.accountInfo.orgId;
                      }
                    }
                    const payload = {
                      paymentType: 2,
                      token: result.token.id,
                      monthlyInvoice: scope.state.monthlyInvoice,
                      invoiceProfile: true,
                      makeDefaultMethod: true,
                      ownerId,
                      ownerType,
                    };

                    $http
                      .post(`${API_BASE_URL}paymentProfiles/card/add`, payload)
                      .then(({
                        data, success, headers, config,
                      }) => {
                        if (data.success) {
                          loadingScreenFactory.hideLoadingScreen();
                          const cardNumber = `************${data.data.last4}`;
                          const expMonth = data.data.exp_month;
                          const expYear = data.data.exp_year;
                          const stripe_customer_id = data.data.stripe_customer_id;
                          scope.maskedStripeCardNumber = cardNumber;
                          scope.updateStripeCard = false;
                          $rootScope.$broadcast('stripeCardAdded', {
                            cardNumber,
                            expMonth,
                            expYear,
                            stripe_customer_id,
                          });
                          // mask the card number and store it in localstorage
                        } else {
                          loadingScreenFactory.hideLoadingScreen();
                          $rootScope.$broadcast('stripeCardError', { message: 'Could not add your card details' });
                        }
                      })
                      .catch(({
                        data, success, headers, config,
                      }) => {
                        loadingScreenFactory.hideLoadingScreen();
                        $rootScope.$broadcast('stripeCardError', { message: 'Could not add your card details' });
                      });
                  }
                })
                .catch((error) => {
                  loadingScreenFactory.hideLoadingScreen();
                  // $rootScope.$broadcast('stripeCardError', { message: 'Could not add your card details' });
                });
            } else {
              loadingScreenFactory.hideLoadingScreen();
              $rootScope.$broadcast('stripeCardError', { message: 'Could not add your card details' });
            }
          });

          $rootScope.$on('updateCard', () => {
            const cardErrorsElement = document.getElementById('card-errors');
            const errorText = cardErrorsElement.innerHTML;
            loadingScreenFactory.showLoadingScreen();
            if (errorText === '') {
              const stateName = scope.selectedState ? scope.selectedState.state_name : '';
              const cardData = {
                address_line1: scope.state.billingAddress.address1,
                address_city: scope.state.billingAddress.city,
                address_state: stateName,
                address_zip: scope.state.billingAddress.zip,
                name: scope.state.cardHolderName,
              };
              stripe
                .createToken(card, cardData)
                .then((result) => {
                  if (result.error) {
                    loadingScreenFactory.hideLoadingScreen();
                    const errorElement = document.getElementById('card-errors');
                    errorElement.textContent = result.error.message;
                    $rootScope.$broadcast('stripeCardError', { message: 'Could not add your card details' });
                  } else {
                    // send the token to the server
                    let ownerId = scope.state.userId;
                    let ownerType = 'user';
                    const signupType = scope.state.signupType;
                    if (signupType === 'business' || signupType === 'rider') {
                      ownerType = 'org';
                      if (signupType === 'business') {
                        ownerId = scope.state.businessInfo.orgId;
                      } else {
                        ownerId = scope.state.accountInfo.orgId;
                      }
                    }
                    const data = {
                      paymentType: 2,
                      ownerId,
                      ownerType,
                      token: result.token.id,
                      monthlyInvoice: scope.state.monthlyInvoice,
                      invoiceProfile: true,
                      makeDefaultMethod: true,
                    };
                    $http
                      .post(`${API_BASE_URL}paymentProfiles/card/add`, data)
                      .then(({
                        apiResult, success, headers, config,
                      }) => {
                        if (apiResult.success) {
                          const cardNumber = `************${apiResult.data.last4}`;
                          const expMonth = apiResult.data.exp_month;
                          const expYear = apiResult.data.exp_year;
                          scope.maskedStripeCardNumber = cardNumber;
                          const stripe_customer_id = apiResult.data.stripe_customer_id;
                          scope.updateStripeCard = false;
                          loadingScreenFactory.hideLoadingScreen();
                          $rootScope.$broadcast('stripeCardAdded', {
                            cardNumber,
                            expMonth,
                            expYear,
                            stripe_customer_id,
                          });
                          // mask the card number and store it in localstorage
                        } else {
                          loadingScreenFactory.hideLoadingScreen();
                          $rootScope.$broadcast('stripeCardError', { message: 'Could not add your card details' });
                        }
                      })
                      .catch(({
                        apiResult, success, headers, config,
                      }) => {
                        loadingScreenFactory.hideLoadingScreen();
                        $rootScope.$broadcast('stripeCardError', { message: 'Could not add your card details' });
                      });
                  }
                })
                .catch((error) => {
                  loadingScreenFactory.hideLoadingScreen();
                  // $rootScope.$broadcast('stripeCardError', { message: 'Could not add your card details' });
                });
            } else {
              loadingScreenFactory.hideLoadingScreen();
              $rootScope.$broadcast('stripeCardError', { message: 'Could not add your card details' });
            }
          });

          card.mount('#card-element');
          if (card) {
            card.addEventListener('change', (event) => {
              const displayError = document.getElementById('card-errors');
              if (event.error) {
                displayError.innerHTML = addAlertToErrorMessage(event.error.message);
              } else {
                displayError.innerHTML = '';
              }
            });
          }
        },
      };
    },
  ])
  .directive('lvlDraggable', [
    '$rootScope',
    'uuid',
    function ($rootScope, uuid) {
      return {
        restrict: 'A',
        link(scope, el, attrs, controller) {
          angular.element(el).attr('draggable', 'true');

          let id = angular.element(el).attr('id');
          const fromList = angular.element(el).attr('data-from');

          if (!id) {
            id = uuid.new();
            angular.element(el).attr('id', id);
          }

          el.bind('dragstart', (e) => {
            e.dataTransfer.setData('Text', fromList);
            if (scope.user && !scope.user.checked) {
              angular.element(el).triggerHandler('click');
            }
            $rootScope.$emit('LVL-DRAG-START');
          });

          el.bind('dragend', (e) => {
            $rootScope.$emit('LVL-DRAG-END');
          });
        },
      };
    },
  ])
  .directive('lvlDropTarget', [
    '$rootScope',
    'uuid',
    function lvlDropTarget($rootScope, uuid) {
      return {
        restrict: 'A',
        scope: {
          onDrop: '&',
        },
        link(scope, el, attrs, controller) {
          const containerElement = angular.element(el);
          let id = containerElement.attr('id');

          if (!id) {
            id = uuid.new();
            angular.element(el).attr('id', id);
          }

          el.bind('dragover', (e) => {
            if (e.preventDefault) {
              e.preventDefault();
            }

            e.dataTransfer.dropEffect = 'move';
            return false;
          });

          el.bind('dragenter', (e) => {
            // this / e.target is the current hover target.
            const element = angular.element(e.target).addClass('lvl-over');
            if (element.length > 0 && containerElement.length > 0) {
              if (element.hasClass('list-group-item')) {
                const offsettop = element[0].offsetTop;
                const containertop = containerElement[0].scrollTop;
                if (offsettop - containertop > 0 && offsettop - containertop < 120) {
                  containerElement.stop(true, false).animate({ scrollTop: containertop - 130 }, 500);
                } else if (offsettop > containertop && offsettop - containertop > 360) {
                  containerElement.stop(true, false).animate({ scrollTop: containertop + 130 }, 500);
                }
              }
            }
            if (e.preventDefault) {
              e.preventDefault();
            }
          });

          el.bind('dragleave', (e) => {
            angular.element(e.target).removeClass('lvl-over'); // this / e.target is previous target element.
            if (e.preventDefault) {
              e.preventDefault();
            }
          });

          el.bind('drop', (e) => {
            if (e.preventDefault) {
              e.preventDefault();
            }

            if (e.stopPropagation) {
              e.stopPropagation();
            }
            const fromList = e.dataTransfer.getData('Text');
            scope.onDrop({ dropEl: e.target.id, fromList });
            $rootScope.$emit('LVL-DRAG-END');
          });

          $rootScope.$on('LVL-DRAG-START', () => {
            const tempEl = document.getElementById(id);
            angular.element(tempEl).addClass('lvl-target');
          });

          $rootScope.$on('LVL-DRAG-END', () => {
            const tempEl = document.getElementById(id);
            angular.element(tempEl).removeClass('lvl-target');

            const els = document.getElementsByClassName('lvl-over');
            for (let e = 0; e < els.length; e++) {
              angular.element(els[e]).removeClass('lvl-over');
            }
          });
        },
      };
    },
  ])
  .directive('uiSelectChoices', [
    '$timeout',
    '$parse',
    '$compile',
    '$document',
    '$filter',
    function uiSelectChoices($timeout, $parse, $compile, $document, $filter) {
      return function (scope, elm, attr) {
        if (attr.allChoices) {
          const raw = elm[0];
          let scrollCompleted = true;
          scope.pagingOptions = {
            allOptions: scope.$eval(attr.allChoices),
          };
          attr.refresh = 'addMoreItems()';
          const refreshCallBack = $parse(attr.refresh);
          elm.bind('scroll', (event) => {
            const remainingHeight = raw.offsetHeight - raw.scrollHeight;
            const scrollTop = raw.scrollTop;
            const percent = Math.abs((scrollTop / remainingHeight) * 100);
            if (percent >= 80) {
              if (scrollCompleted) {
                scrollCompleted = false;
                event.preventDefault();
                event.stopPropagation();
                const callback = function () {
                  scope.addingMore = true;
                  refreshCallBack(scope, {
                    $event: event,
                  });
                  scrollCompleted = true;
                };
                $timeout(callback, 100);
              }
            }
          });
          const closeDestroyer = scope.$on('uis:close', () => {
            const pagingOptions = scope.$select.pagingOptions || {};
            pagingOptions.filteredItems = undefined;
            pagingOptions.page = 0;
          });
          scope.addMoreItems = function (doneCalBack) {
            const $select = scope.$select;
            let allItems = scope.pagingOptions.allOptions;
            let moreItems = [];
            const itemsThreshold = 100;
            const search = $select.search;
            const pagingOptions = ($select.pagingOptions = $select.pagingOptions || {
              page: 0,
              pageSize: 10,
              items: $select.items,
            });
            if (pagingOptions.page === 0) {
              pagingOptions.items.length = 0;
            }
            if (!pagingOptions.originalAllItems) {
              pagingOptions.originalAllItems = scope.pagingOptions.allOptions;
            }
            const searchDidNotChange = search && pagingOptions.prevSearch && search == pagingOptions.prevSearch;
            if (pagingOptions.filteredItems && searchDidNotChange) {
              allItems = pagingOptions.filteredItems;
            }
            pagingOptions.prevSearch = search;
            if (search && search.length > 0 && pagingOptions.items.length < allItems.length && !searchDidNotChange) {
              pagingOptions.filteredItems = undefined;
              moreItems = $filter('propsFilter')(pagingOptions.originalAllItems, { name: search });
              // if filtered items are too many scrolling should occur for filtered items
              if (moreItems.length > itemsThreshold) {
                pagingOptions.page = 0;
                pagingOptions.items.length = 0;
                allItems = pagingOptions.filteredItems = moreItems;
              } else {
                allItems = moreItems;
                pagingOptions.items.length = 0;
                pagingOptions.filteredItems = undefined;
              }
            }
            pagingOptions.page++;
            if (pagingOptions.page * pagingOptions.pageSize < allItems.length) {
              moreItems = allItems.slice(pagingOptions.items.length, pagingOptions.page * pagingOptions.pageSize);
            } else {
              moreItems = allItems.slice(pagingOptions.items.length, allItems.length);
            }
            for (let k = 0; k < moreItems.length; k++) {
              pagingOptions.items.push(moreItems[k]);
            }
            scope.calculateDropdownPos();
            scope.$broadcast('uis:refresh');
            if (doneCalBack) doneCalBack();
          };
          scope.$on('$destroy', () => {
            elm.off('scroll');
            closeDestroyer();
          });
          //   throw new Error('ief:ui-select: Attribute all-choices is required in  ui-select-choices so that we can handle  pagination.');
        }
      };
    },
  ])
  .directive('leaveConfirmation', [
    '$window',
    '$transitions',
    '$q',
    function leaveConfirmation($window, $transitions, $q) {
      return {
        template:
          '<div class="leave-confirmation" ng-if="model.modalVisible">'
          + '<div style="position:relative; top: 35%;">'
          + '<h3>{{leaveText}}</h3>'
          + '<h4> Are you sure you want to leave this page? </h3>'
          + '<button class="btn" style="background-color: #d0d0d0; border-color: #000; color: #000" ng-click="continue()">Leave page</button> &nbsp; <button class="btn btn-primary" ng-click="stay()">Stay on page</button>'
          + '</div>'
          + '</div>',
        scope: {
          alertBeforeLeave: '=leaveConfirmation',
          leaveText: '@leaveText',
        },
        link(scope) {
          scope.model = {
            modalVisible: false,
            pendingAction: null,
          };

          const showConfirmModal = async function (transition) {
            if (scope.alertBeforeLeave && transition.to().name !== 'login') {
              scope.model.modalVisible = true;
              scope.model.pendingAction = $q.defer();
              return scope.model.pendingAction.promise;
            } else {
              scope.changeLocationOff();
              $window.onbeforeunload = null;
              return true;
            }
          };

          // User wants to stay on the page
          scope.stay = function () {
            scope.model.modalVisible = false;
            scope.model.pendingAction && scope.model.pendingAction.resolve(false);
          };

          // User really wants to navigate to that page which was saved before in a temp variable
          scope.continue = function () {
            scope.model.modalVisible = false;
            scope.changeLocationOff();
            $window.onbeforeunload = null;
            scope.model.pendingAction && scope.model.pendingAction.resolve(true);
          };

          // A location change is triggered by the user e.g. by clicking on a link
          scope.changeLocationOff = $transitions.onBefore({}, showConfirmModal);

          // In case that the user clicks the refresh/back button or makes a hard url change
          $window.onbeforeunload = function (event) {
            if (scope.alertBeforeLeave) {
              event.returnValue = scope.leaveText;
              return scope.leaveText;
            }
          };
        },
      };
    },
  ])
  .directive('stripeCardReusable', [
    '$timeout',
    '$rootScope',
    '$http',
    'API_BASE_URL',
    'STRIPE_KEY_PUBLISHABLE',
    'loadingScreenFactory',
    '$state',
    function stripeCardReusable($timeout, $rootScope, $http, API_BASE_URL, STRIPE_KEY_PUBLISHABLE, loadingScreenFactory, $state) {
      return {
        restrict: 'A',
        scope: { options: '=' },
        link(scope, element, attributes, ngModel) {
          // firing validation on country change
          const stripe = Stripe(STRIPE_KEY_PUBLISHABLE);
          const elements = stripe.elements();
          const style = {
            base: {
              color: '#32325d',
              lineHeight: '24px',
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSmoothing: 'antialiased',
              fontSize: '16px',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#fa755a',
              iconColor: '#fa755a',
            },
          };

          const addAlertToErrorMessage = function (message) {
            return `<div class="alert alert-danger margin-top-10">${message}</div>`;
          };

          const cardNumber = elements.create('cardNumber', {
            style,
            hidePostalCode: true,
          });
          cardNumber.mount('#card-number');

          const cardExpiry = elements.create('cardExpiry', {
            style,
          });
          cardExpiry.mount('#card-expiry');

          const cardCvc = elements.create('cardCvc', {
            style,
          });
          cardCvc.mount('#card-cvc');

          // handling stripe errors
          if (cardNumber) {
            cardNumber.addEventListener('change', (event) => {
              const displayError = document.getElementById('card-errors');
              if (event.error) {
                displayError.innerHTML = addAlertToErrorMessage(event.error.message);
              } else {
                displayError.innerHTML = '';
              }
            });
          }

          if (cardExpiry) {
            cardExpiry.addEventListener('change', (event) => {
              const displayError = document.getElementById('card-errors');
              if (event.error) {
                displayError.innerHTML = addAlertToErrorMessage(event.error.message);
              } else {
                displayError.innerHTML = '';
              }
            });
          }

          if (cardCvc) {
            cardCvc.addEventListener('change', (event) => {
              const displayError = document.getElementById('card-errors');
              if (event.error) {
                displayError.innerHTML = addAlertToErrorMessage(event.error.message);
              } else {
                displayError.innerHTML = '';
              }
            });
          }

          angular.extend(scope.options, {
            getToken(cardData) {
              const cardErrorsElement = document.getElementById('card-errors');
              const errorText = cardErrorsElement.innerHTML;
              return new Promise((resolve, reject) => {
                if (errorText === '') {
                  const errorElement = document.getElementById('card-errors');
                  stripe
                    .createToken(cardNumber, cardData)
                    .then((result) => {
                      if (result.error) {
                        errorElement.innerHTML = addAlertToErrorMessage(result.error.message);
                        reject(new Error(result.error.message));
                      } else {
                        resolve({ token: result.token });
                      }
                    })
                    .catch((error) => {
                      errorElement.innerHTML = addAlertToErrorMessage(error.message);
                      reject(new Error(error.message));
                    });
                } else {
                  reject(new Error(errorText));
                }
              });
            },
          });
        },
      };
    },
  ])
  .directive('riderProviderDetails', [
    function riderProviderDetails() {
      return {
        restrict: 'E',
        link(scope, element, attrs) {
          Object.assign(scope, {
            username: attrs.username,
            avatar: attrs.avatar,
            reputation: attrs.reputation,
          });
        },
        template: require('../views/rider-provider-details.html'),
      };
    },
  ])
  .directive('phoneNumberInput', [function phoneNumberInput() {
    return {
      restrict: 'A',
      link(scope, element) {
        const [{ id: elementId }] = element;
        angular.element(`#${elementId}`).intlTelInput({
          nationalMode: true,
          initialCountry: 'us',
          preferredCountries: ['us', 'in'],
          defaultCountry: 'us',
          formatOnDisplay: false,
          separateDialCode: true,
        });
        element.on('phone-number-changed', (event, phone) => {
          angular.element(`#${elementId}`).intlTelInput('setNumber', phone);
        });
        element.on('countrychange', () => {
          /* this directive is used in multiple places but
           not sure whether more than 10 digit number
           for countries other than US can be used everywhere or not */
          if (!['orgContactPhone', 'childOrgContactPhone'].includes(elementId)) return;
          let mask = '(999) 999-9999';
          let isSelectedCountryUS = true;
          if (element.intlTelInput('getSelectedCountryData').iso2 !== 'us') {
            mask = '(999) 999-99999?9?9?9?9?';
            isSelectedCountryUS = false;
          }
          Object.assign(scope, {
            mask,
            isSelectedCountryUS,
          });
        });
      },
    };
  }])
  .directive('stripeAccountReusable', [
    '$timeout',
    '$rootScope',
    '$http',
    'API_BASE_URL',
    'STRIPE_KEY_PUBLISHABLE',
    'loadingScreenFactory',
    '$state',
    function stripeAccountReusable($timeout, $rootScope, $http, API_BASE_URL, STRIPE_KEY_PUBLISHABLE, loadingScreenFactory, $state) {
      return {
        restrict: 'A',
        scope: { options: '=' },
        link(scope, element, attributes, ngModel) {
          // firing validation on country change
          const stripe = Stripe(STRIPE_KEY_PUBLISHABLE);

          const addAlertToErrorMessage = function (message) {
            return `<div class="alert alert-danger margin-top-10">${message}</div>`;
          };

          angular.extend(scope.options, {
            getToken(accountDetails) {
          
              const stripeAccountErrorsElement = document.getElementById('stripe-account-errors');
              stripeAccountErrorsElement.innerHTML = '';
              return new Promise((resolve, reject) => {
                stripe
                  .createToken('bank_account', accountDetails)
                  .then((result) => {
                    if (result.error) {
                      stripeAccountErrorsElement.innerHTML = addAlertToErrorMessage(result.error.message);
                      reject(new Error(result.error.message));
                    } else {
                      resolve({ token: result.token });
                    }
                  })
                  .catch((error) => {
                    stripeAccountErrorsElement.innerHTML = addAlertToErrorMessage(error.message);
                    reject(new Error(error.message));
                  });
              });
            },
          });
        },
      };
    },
  ]);
