import { navigateToUrl } from 'single-spa';

angular
  .module('relayHealth')
  .service('SessionTimeoutNotification', [
    'toaster',
    function SessionTimeoutNotification(toaster) {
      return {
        isVisible: false,
        isIgnored: false,
        session_expiring_in_time: 0,
        session_expiring_in_time_label: '0 minutes',
        toasterInstance: null,
        show(counter) {
          if (!this.isVisible) {
            this.isVisible = true;
            this.toasterInstance = toaster.pop({
              type: 'info',
              body: 'session-timeout-notification',
              bodyOutputType: 'directive',
              timeout: 0,
            });
          }
          this.setRemainingExpiryTime(counter);
        },
        hide() {
          this.isVisible = false;
          if (this.toasterInstance) {
            toaster.clear(this.toasterInstance);
            this.toasterInstance = null;
          }
        },
        ignore() {
          this.isIgnored = true;
          this.hide();
        },
        reconsider() {
          this.isIgnored = false;
          this.hide();
        },
        setRemainingExpiryTime(counter) {
          this.session_expiring_in_time = counter;
          if (this.session_expiring_in_time < 0) {
            this.session_expiring_in_time = 0;
          }
          const minutes = Math.floor(this.session_expiring_in_time / 60);
          const seconds = this.session_expiring_in_time % 60;
          this.session_expiring_in_time_label = `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds} minutes`;
        },
        getExpiryTimeValue() {
          return this.session_expiring_in_time;
        },
        getExpiryTimeLabel() {
          return this.session_expiring_in_time_label;
        },
      };
    },
  ])
  .service('SessionTimeoutHandler', [
    'SESSION_EXPIRATION_TIME',
    'SESSION_EXPIRATION_NOTIFICATION_TIME',
    'SessionTimeoutNotification',
    '$http',
    'API_BASE_URL',
    'toaster',
    '$interval',
    '$rootScope',
    'pubnubNotificationFactory',
    function SessionTimeoutHandler(
      SESSION_EXPIRATION_TIME,
      SESSION_EXPIRATION_NOTIFICATION_TIME,
      SessionTimeoutNotification,
      $http,
      API_BASE_URL,
      toaster,
      $interval,
      $rootScope,
      pubnubNotificationFactory,
    ) {
      const sessionValidity = SESSION_EXPIRATION_TIME * 60000; // Configurable total duration till the session is expected to live... currently 20 minutes
      let sessionExpiryTime = -1;
      let sessionInitTimestamp = 0;

      // Configurable time when the user shall be notified of expiring session... currently 17 minutes
      const sessionExpiryNotificationWaitTime = SESSION_EXPIRATION_NOTIFICATION_TIME * 60000;
      const updateSession = (type = 'extend') => {
        $http
          .get(`${API_BASE_URL}verify/login`, {})
          .then((result) => {
            const userDetails = JSON.parse(localStorage.getItem('userDetails'));
            if (type !== 'extend' && ((userDetails.sessionExpiry && userDetails.sessionExpiry < new Date().getTime()) || !userDetails.sessionExpiry)) {
              $rootScope.$broadcast('removeClickHandler');
              pubnubNotificationFactory.unsubscribeAllChannel();
              const res = $http.put(`${API_BASE_URL}user/logout`, {});
              res.then(({ data: { success, message } }) => {
                if (success) {
                  localStorage.clear();
                  navigateToUrl(`/${process.env.PUBLIC_URL_PREFIX}current/login`);
                  return;
                }
                toaster.pop({
                  type: 'error',
                  title: message,
                  showCloseButton: true,
                  timeout: 10000,
                });
              });
              res.catch(() => {
                toaster.pop({
                  type: 'error',
                  title: 'Not able to logout',
                  showCloseButton: true,
                  timeout: 10000,
                });
              });
              return false;
            }

            if (result.data.success) {
              if (type === 'extend') {
              // eslint-disable-next-line no-use-before-define
                setExpiryTime(SESSION_EXPIRATION_TIME * 60);
                userDetails.sessionExpiry = Date.now() + 20 * 60 * 1000;
                localStorage.setItem('userDetails',  JSON.stringify(userDetails));
              }
              return toaster.pop({
                type: type === 'extend' ? 'success' : 'error',
                title: type === 'extend' ? 'Session Extended' : 'Session still active on the server',
                showCloseButton: true,
                timeout: 5000,
              });
            }
            throw new Error(type === 'extend' ? 'Failed to extend session' : 'Session Expired');
          })
          .catch((error) => {
            if (error.message) {
              toaster.pop({
                type: 'error',
                title: error.message,
                showCloseButton: true,
                timeout: 5000,
              });
            }
          });
      };

      let timeoutHandlerLoopId = null;
      const initTimeoutHandlerLoop = () => {
        timeoutHandlerLoopId = $interval(() => {
          if (sessionExpiryTime < 0) {
            return; // Timeframe during when there is no active session
          }
          const interval = new Date().getTime() - sessionInitTimestamp;

          if (!SessionTimeoutNotification.isIgnored && !SessionTimeoutNotification.isVisible && interval >= sessionExpiryNotificationWaitTime) {
            // User had been idle for the configured time... show him the session timing out message
            SessionTimeoutNotification.show(Math.floor((sessionValidity - interval) / 1000));
          } else if (SessionTimeoutNotification.isIgnored || interval < sessionExpiryNotificationWaitTime) {
            SessionTimeoutNotification.hide();
          }

          if (sessionValidity < interval) {
            sessionExpiryTime = -1;
            sessionInitTimestamp = 0;
            $interval.cancel(timeoutHandlerLoopId);
            timeoutHandlerLoopId = null;
            SessionTimeoutNotification.hide();
            updateSession('expire'); // This will auto logout the user as session has expired
          } else {
            SessionTimeoutNotification.setRemainingExpiryTime(Math.floor((sessionValidity - interval) / 1000));
          }
        }, 1000);
      };
      function setExpiryTime(time) {
        sessionExpiryTime = time;
        sessionInitTimestamp = new Date().getTime();
        SessionTimeoutNotification.reconsider(); // Un-ignore session timeout notification in case the last one was muted by the user
        if (!timeoutHandlerLoopId) {
          initTimeoutHandlerLoop();
        }
      }
      // Handling logout case and reseting counters and removing timeout loop
      $rootScope.$on('logout', () => {
        sessionExpiryTime = -1;
        sessionInitTimestamp = 0;
        if (timeoutHandlerLoopId) {
          $interval.cancel(timeoutHandlerLoopId);
          timeoutHandlerLoopId = null;
        }
      });

      return {
        setExpiryTime,
        getExpiryTime() {
          return sessionExpiryTime;
        },
        updateSession,
      };
    },
  ])
  .directive('sessionTimeoutNotification', [
    'SessionTimeoutNotification',
    'SessionTimeoutHandler',
    function sessionTimeoutNotification(SessionTimeoutNotification, SessionTimeoutHandler) {
      return {
        scope: true,
        restrict: 'A',
        template: `<div class="session-timeout-notification-container">
            <div>Your session is about to expire in {{sessionTimeoutNotification.getExpiryTimeLabel()}}. Do you wish to continue?</div>
            <div class="session-timeout-notification-buttons">
                <button class="btn btn-primary" ng-click="extend()">Yes</button>
                <button class="btn btn-default" ng-click="cancel()">No</button>
            </div>
        </div>`,
        link: ($scope) => {
          $scope.sessionTimeoutNotification = SessionTimeoutNotification;
          $scope.extend = () => {
            SessionTimeoutNotification.ignore();
            SessionTimeoutHandler.updateSession('extend');
          };
          $scope.cancel = () => {
            SessionTimeoutNotification.ignore();
          };
        },
      };
    },
  ]);
