const rideStatus = {
  PENDING_APPROVAL: {
    type: 'pending',
    status: 'Pending Approval',
    error_level: 1,
    queue: '',
    exit_sqs: true,
  },
  CONFIRMED: {
    type: 'scheduled',
    status: 'Confirmed',
    error_level: 1,
    queue: 'bookCab',
    exit_sqs: false,
  },
  RIDE_BOOKED: {
    type: 'active',
    status: 'Ride Booked',
    error_level: 1,
    queue: '',
    exit_sqs: false,
  },
  RECURRENCE_RIDE_BOOKED: {
    type: 'active',
    status: 'Recurrence Ride Booked',
    error_level: 1,
    queue: '',
    exit_sqs: false,
  },
  GET_READY: {
    type: 'active',
    status: 'Get Ready',
    error_level: 1,
    queue: '',
    exit_sqs: false,
  },
  FAILED: {
    type: 'issue',
    status: 'Failed',
    error_level: 1,
    queue: 'bookCab',
    exit_sqs: true,
  },
  FINDING_DRIVER: {
    type: 'active',
    status: 'Finding Driver',
    error_level: 1,
    queue: 'findingDriver',
    exit_sqs: false,
  },
  DRIVER_CONFIRMED: {
    type: 'active',
    status: 'Driver Confirmed',
    error_level: 1,
    queue: 'driverConfirmed',
    exit_sqs: false,
    rebookTryAlert: {
      count: 2,
      error_level: 1,
    },
  },
  DRIVER_RECONFIRMED: {
    type: 'active',
    status: 'Driver Reconfirmed',
    error_level: 1,
    queue: 'driverConfirmed',
    exit_sqs: false,
  },
  DRIVER_ARRIVED: {
    type: 'active',
    status: 'Driver Arrived',
    error_level: 2,
    queue: 'driverArrived',
    exit_sqs: false,
    rebookTryAlert: {
      count: 2,
      error_level: 1,
    },
  },
  PATIENT_ENROUTE: {
    type: 'active',
    status: 'Patient Enroute',
    error_level: 1,
    queue: 'patientEnroute',
    exit_sqs: false,
    rebookTryAlert: {
      count: 2,
      error_level: 1,
    },
  },
  RIDE_COMPLETED: {
    type: 'active',
    status: 'Ride Completed',
    error_level: 1,
    queue: 'bookCab',
    exit_sqs: true,
  },
  VARIANCE_UNDER: {
    type: 'active',
    status: 'Variance Under',
    error_level: 2,
    queue: 'bookCab',
    exit_sqs: true,
  },
  VARIANCE_OVER: {
    type: 'active',
    status: 'Variance Over',
    error_level: 2,
    queue: 'bookCab',
    exit_sqs: true,
  },
  APPOINTMENT_COMPLETED: {
    type: 'completed',
    status: 'Appointment Completed',
    error_level: 1,
    queue: '',
    exit_sqs: true,
  },
  PENDING_RETURN_REQUEST: {
    type: 'pending return',
    status: 'Pending Return Request',
    error_level: 1,
    queue: '',
    exit_sqs: true,
  },
  RIDE_CANCELED: {
    type: 'completed',
    status: 'Ride Canceled',
    error_level: 4,
    queue: 'bookCab',
    exit_sqs: true,
  },
  RIDE_REJECTED: {
    type: 'completed',
    status: 'Rejected',
    error_level: 1,
    queue: 'bookCab',
    exit_sqs: true,
  },
  APPOINTMENT_CANCELED: {
    type: 'canceled',
    status: 'Appointment Canceled',
    error_level: 1,
    queue: '',
    exit_sqs: true,
  },
  LATE_ARRIVAL: {
    type: 'issue',
    status: 'Late Arrival',
    error_level: 2,
    queue: 'patientEnroute',
    exit_sqs: false,
  },
  DRIVER_CANCELED: {
    type: 'issue',
    status: 'Driver Canceled',
    error_level: 1,
    queue: 'bookCab',
    exit_sqs: true,
  },
  NO_DRIVER_AVAILABLE: {
    type: 'issue',
    status: 'No Driver Available',
    error_level: 1,
    queue: 'bookCab',
    exit_sqs: true,
  },
  MISSED_SURGE_APPROVAL: {
    type: 'issue',
    status: 'Missed Surge Approval',
    error_level: 1,
    queue: '',
    exit_sqs: false,
  },
  UATP_OTHER: {
    type: 'issue',
    status: 'Failed-Other',
    error_level: 1,
    queue: 'bookCab',
    exit_sqs: true,
  },
  MANUALLY_RESOLVED: {
    type: 'completed',
    status: 'Manually Resolved',
    error_level: 1,
    queue: '',
    exit_sqs: true,
  },
  SURGE_APPROVAL_NEEDED: {
    type: 'issue',
    status: 'Surge Approval Needed',
    error_level: 2,
    queue: 'bookCab',
    exit_sqs: false,
  },
  SURGE_APPROVED: {
    type: 'active',
    status: 'Surge Approved',
    error_level: 1,
    queue: 'bookCab',
    exit_sqs: false,
  },
  PENDING_RIDE: {
    type: 'pending',
    status: 'pending',
    error_level: 1,
    queue: 'bookCab',
    exit_sqs: true,
  },
  POTENTIAL_UNAVAILABILITY: {
    type: 'issue',
    status: 'Potential Unavailability',
    error_level: 2,
    queue: 'bookCab',
    exit_sqs: false,
  },
  VARIANCE_ISSUE: {
    type: 'issue',
    status: 'Variance Issue',
    error_level: 2,
    queue: '',
    exit_sqs: true,
  },
  PENDING_NEXT_RIDE: {
    type: 'pending next',
    status: 'Pending Next Ride',
    error_level: 1,
    queue: '',
    exit_sqs: true,
  },
  PATIENT_DELETED: {
    type: 'issue',
    status: 'Patient Deleted',
    error_level: 2,
    queue: 'bookCab',
    exit_sqs: true,
  },
  DEPARTURE_OVERDUE: {
    type: 'issue',
    status: 'Departure Overdue',
    error_level: 2,
    queue: '',
    exit_sqs: false,
  },
  PENDING_SUBMISSION: {
    type: 'issue',
    status: 'Pending Submission',
    error_level: 1,
    queue: '',
    exit_sqs: true,
  },
  PATIENT_NO_SHOW: {
    type: 'issue',
    status: 'Patient No Show',
    error_level: 1,
    queue: 'bookCab',
    exit_sqs: true,
  },
  REQUEST_OVERDUE: {
    type: 'issue',
    status: 'Request Overdue',
    error_level: 1,
    queue: '',
    exit_sqs: true,
  },
  SPECIAL_RIDE: {
    type: 'special_ride',
    status: 'Special Ride',
    error_level: 3,
    queue: '',
    exit_sqs: true,
  },
  FAILED_CC_AUTH: {
    type: 'completed',
    status: 'Failed CC Auth',
    error_level: 2,
    queue: 'bookCab',
    exit_sqs: true,
  },
  FAILED_CC_PAYMENT: {
    type: 'issue',
    status: 'Failed CC Payment',
    error_level: 1,
    queue: 'bookCab',
    exit_sqs: true,
  },
  FAILED_FUSE: {
    type: 'issue',
    status: 'Failed Fuse',
    error_level: 2,
    queue: 'bookCab',
    exit_sqs: false,
  },
  ARRIVAL_TIME_EXCEEDED: {
    type: 'issue',
    status: 'Arrival Time Exceeded',
    error_level: 2,
    queue: 'driverConfirmed',
    exit_sqs: false,
  },
  PENDING_RIDE_REQUEST: {
    type: 'pending',
    status: 'Pending Ride Request',
    error_level: 1,
  },
  READY_TO_PROCESS: {
    type: 'pending',
    status: 'Ready To Process',
    error_level: 1,
  },
  OFFER_REJECTED: {
    status: 'Offer Rejected',
    error_level: 1,
  },
  OFFER_EXPIRED: {
    status: 'Offer Expired',
    error_level: 1,
  },
  RESCINDED: {
    status: 'Rescinded',
    error_level: 1,
  },
  UATP: {
    type: 'alert',
    status: 'UATP',
    error_level: 1,
    queue: '',
    exit_sqs: false,
  },
  OFFER_CREATED: {
    type: 'alert',
    status: 'Offer Created',
    error_level: 1,
    queue: '',
    exit_sqs: false,
  },
  NEPF: {
    type: 'alert',
    status: 'NEPF',
    error_level: 1,
    queue: '',
    exit_sqs: false,
  },
  NDA: {
    type: 'alert',
    status: 'NDA',
    error_level: 1,
    queue: '',
    exit_sqs: false,
  },
  ORDER_UPDATED: {
    type: 'alert',
    status: 'Order Updated',
    error_level: 1,
    queue: '',
    exit_sqs: false,
  },
  OFFERED: {
    type: 'processing',
    status: 'Offered',
    error_level: 1,
    queue: '',
    exit_sqs: false,
  },
  TWENTY_FOUR_HOUR_CONFIRMATION_MISSING: {
    type: 'alert',
    status: '24 Hour Confirmation Missing',
    error_level: 1,
    queue: '',
    exit_sqs: false,
  },
  CONFIRM_MEMBER_PICKUP: {
    type: 'issue',
    status: 'Confirm Member Pickup',
    error_level: 1,
    queue: '',
    exit_sqs: false,
  },
  CONFIRM_MEMBER_DROPOFF: {
    type: 'issue',
    status: 'Confirm Member Dropoff',
    error_level: 1,
    queue: '',
    exit_sqs: false,
  },
  PENDING_RE_OFFER: {
    type: 'alert',
    status: 'Pending - Re-Offer',
    error_level: 1,
    queue: '',
    exit_sqs: false,
  },
  NO_DRIVER_ASSIGNED: {
    type: 'issue',
    status: 'No Driver Assigned',
    error_level: 1,
    queue: '',
    exit_sqs: false,
  },
  PROVIDER_NO_SHOW: {
    type: 'completed',
    status: 'Provider No Show',
    error_level: 1,
    queue: '',
    exit_sqs: false,
  },
  RETRO_QUEUED: {
    type: 'completed',
    status: 'Retro Queued',
    belongs: 'ride',
    error_level: 1,
  },
  DELETED: {
    type: 'deleted',
    status: 'Deleted',
    belongs: 'both',
    error_level: 1,
    queue: '',
    exit_sqs: false,
  },
  VALIDATION_FAILED: {
    type: 'completed',
    status: 'Validation Failed',
    belongs: 'both',
    error_level: 1,
    queue: '',
    exit_sqs: false,
  },
  OFFERED_NDA: {
    type: 'alert',
    status: 'Offered NDA',
    error_level: 1,
    queue: '',
    exit_sqs: false,
  },
  PENDING_DISPOSITION: {
    type: 'completed',
    status: 'Pending Disposition',
    error_level: 1,
    belongs: 'both',
    queue: '',
    exit_sqs: false,
  }
};
export default Object.freeze(rideStatus);
