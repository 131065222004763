angular.module('relayHealth').controller('setPasswordCtrl', [
  '$scope',
  '$state',
  'API_BASE_URL',
  '$http',
  '$location',
  '$timeout',
  'toaster',
  'PASSWORD_PUBLIC_KEY',
  function setPasswordCtrl(
    $scope,
    $state,
    API_BASE_URL,
    $http,
    $location,
    $timeout,
    toaster,
    PASSWORD_PUBLIC_KEY,
  ) {
    const crypt = new JSEncrypt();
    crypt.setPublicKey(PASSWORD_PUBLIC_KEY);
    const { params } = $state;

    if (!params.id || !params.token) {
      $scope.isValidLink = false;
    } else {
      $scope.isValidLink = true;
    }

    if (params.firstName && params.lastName && params.username) {
      $scope.user = {
        first_name: params.firstName,
        last_name: params.lastName,
        username: params.username,
      };
    }

    $scope.changePassword = () => {
      const user = _.cloneDeep($scope.user);
      const ascii = /^[\x20-\x7E]+$/;
      if (user.confirmPassword !== user.password) {
        toaster.pop({
          type: 'error',
          title: 'Password do not match',
          showCloseButton: true,
          timeout: 10000,
        });
        return;
      }
      const { password } = user;

      if (password.length < 8) {
        toaster.pop({
          type: 'error',
          title: 'Password length should be atleast 8 characters',
          showCloseButton: true,
          timeout: 10000,
        });
        return;
      }
      if (password.match(/\s/g)) {
        toaster.pop({
          type: 'error',
          title: 'Password should not contain spaces',
          showCloseButton: true,
          timeout: 10000,
        });
        return;
      }
      if (password.search(/\d/) === -1) {
        toaster.pop({
          type: 'error',
          title: 'Password should contain a digit',
          showCloseButton: true,
          timeout: 10000,
        });
        return;
      }
      if (password.search(/[a-z]/) === -1) {
        toaster.pop({
          type: 'error',
          title: 'Password should contain a lower case letter',
          showCloseButton: true,
          timeout: 10000,
        });
        return;
      }
      if (password.search(/[A-Z]/) === -1) {
        toaster.pop({
          type: 'error',
          title: 'Password should contain an upper case letter',
          showCloseButton: true,
          timeout: 10000,
        });
        return;
      }
      if (password.search(/[!@#$%^&*()_+.,;:]/) === -1) {
        toaster.pop({
          type: 'error',
          title: 'Password should contain a special symbol',
          showCloseButton: true,
          timeout: 10000,
        });
        return;
      }
      if (!ascii.test(password)) {
        toaster.pop({
          type: 'error',
          title: 'Password should not contain control character or non printable character',
          showCloseButton: true,
          timeout: 10000,
        });
        return;
      }
      if (password.match(/[\\]/g)) {
        toaster.pop({
          type: 'error',
          title: 'Password should not contain backslash',
          showCloseButton: true,
          timeout: 10000,
        });
        return;
      }

      for (let i = 0; i + 5 <= user.first_name.length; i += 1) {
        if (password.toLowerCase().indexOf(user.first_name.toLowerCase().substring(i, i + 5)) !== -1) {
          toaster.pop({
            type: 'error',
            title: "Password can not contain First name's significant portions",
            showCloseButton: true,
            timeout: 10000,
          });
          return;
        }
      }
      for (let x = 0; x + 5 <= user.last_name.length; x += 1) {
        if (password.toLowerCase().indexOf(user.last_name.toLowerCase().substring(x, x + 5)) !== -1) {
          toaster.pop({
            type: 'error',
            title: "Password can not contain Last name's significant portions",
            showCloseButton: true,
            timeout: 10000,
          });
          return;
        }
      }
      user.password = crypt.encrypt(user.password, 'base64');
      user.confirmPassword = crypt.encrypt(user.confirmPassword, 'base64');
      $http.put(`${API_BASE_URL}user/resetPasswordSubmit/${params.id}/${params.token}`, user)
        .then(({ data: { success } }) => {
          if (success) {
            $scope.showSuccessMessage = true;
            $timeout(() => {
              $location.search('id', null);
              $location.search('token', null);
              $location.path('/login');
            }, 2000);
          }
        },
        () => {});
    };

    $scope.showSuccessMessage = false;
  },
]);
