function resetPasswordCtrl($scope,
  $state,
  API_BASE_URL,
  $http,
  $location,
  $timeout,
  toaster,
  PASSWORD_PUBLIC_KEY,
  $rootScope,
  redirectUserHomeIfLoggedInService) {
  const crypt = new JSEncrypt();

  $scope.newPassword = '';
  $scope.confirmPassword = '';
  const { params } = $state;
  if (!params.id || !params.token) {
    $scope.isValidLink = false;
  } else {
    $scope.isValidLink = true;
  }

  // forget passoword page display the popup page
  $scope.resetModelInvisble = false;
  $scope.branch_url_link = '#';
  $scope.mobile_device_name = '';

  if (params.device_name) {
    const getGenerateBranchUrl = $http.post(`${API_BASE_URL}generateBranchUrl`, params);
    getGenerateBranchUrl.then(
      (result) => {
        if (result.data.success) {
          $scope.resetModelInvisble = true;
          $scope.mobile_device_name = params.device_name;
          $scope.branch_url_link = result.data.url;
        } else {
          $scope.resetModelInvisble = false;
          $location.path(`/${process.env.PUBLIC_URL_PREFIX}current/resetpassword`);
        }
      },
      () => {
        $scope.resetModelInvisble = false;
      },
    );
  }

  $scope.closeResetPopup = () => {
    $scope.resetModelInvisble = false;
  };

  function login(user) {
    const res = $http.post(`${API_BASE_URL}user/login`, user);
    res.then(({ data: { success, message, user_data: userData } }) => {
      if (!success) {
        toaster.pop({
          type: 'error',
          title: message,
          showCloseButton: true,
          timeout: 10000,
        });
        return;
      }
      localStorage.setItem('userDetails', JSON.stringify(userData));
      Object.assign($rootScope, {
        userName: `${userData.first_name} ${userData.last_name}`,
      });
      redirectUserHomeIfLoggedInService();
    });
    res.catch(({ data }) => {
      toaster.pop({
        type: 'error',
        title: data && data.message ? data.message : 'Not able to login',
        showCloseButton: true,
        timeout: 10000,
      });
    });
  }

  crypt.setPublicKey(PASSWORD_PUBLIC_KEY);
  $scope.changePassword = () => {
    $scope.loading = true;
    const password = $scope.newPassword;
    const obj = { password: crypt.encrypt(password, 'base64') };
    $http
      .put(`${API_BASE_URL}user/setNewPassword/${params.id}/${params.token}`, obj)
      .then(({ data }) => {
        if (data.success) {
          $scope.showErrorMessage = false;
          $scope.showSuccessMessage = true;
          $timeout(() => {
            if (data.username) {
              const user = { password: obj.password, username: data.username };
              login(user);
            } else {
              $location.path('/login');
            }
          }, 2000);
        } else {
          toaster.pop({
            type: 'error',
            title: data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        }
      })
      .catch((error) => {
        const message = _.get(error, 'data.message.err', '');
        $scope.showErrorMessage = true;
        $scope.showSuccessMessage = false;
        toaster.pop({
          type: 'error',
          title: message || error.data.message,
          showCloseButton: true,
          timeout: 10000,
        });
      })
      .finally(() => {
        $scope.loading = false;
      });
  };

  $scope.showSuccessMessage = false;
  $scope.showErrorMessage = false;


  $scope.validatePassword = () => {
    $scope.error_msg = '';
    $scope.error_msg_confirm = '';
    const ascii = /^[\x20-\x7E]+$/;
    const password = $scope.newPassword || '';
    if (password.length < 8) {
      $scope.error_msg = 'Password length should be atleast 8';
      return;
    }
    if (password.match(/\s/g)) {
      $scope.error_msg = 'Password should not contain spaces';
      return;
    }
    if (password.search(/\d/) === -1) {
      $scope.error_msg = 'Password should contain a digit';
      return;
    }
    if (password.search(/[a-z]/) === -1) {
      $scope.error_msg = 'Password should contain a lower case letter';
      return;
    }
    if (password.search(/[A-Z]/) === -1) {
      $scope.error_msg = 'Password should contain an upper case letter';
      return;
    }
    if (password.search(/[!@#$%^&*()_+.,;:]/) === -1) {
      $scope.error_msg = 'Password should contain a special symbol';
      return;
    }
    if (!ascii.test(password)) {
      $scope.error_msg = 'Password should not contain control character or non printable character';
      return;
    }
    if (password.match(/[\\]/g)) {
      $scope.error_msg = 'Password should not contain backslash';
      return;
    }

    if ($scope.confirmPassword !== $scope.newPassword) {
      if ($scope.confirmPassword && !$scope.error_msg) {
        $scope.error_msg_confirm = 'Password and confirm password do not match';
      }
      $scope.hasError = true;
      return;
    }

    $scope.passwordMatch = true;
    $scope.error_msg = '';
    $scope.error_msg_confirm = '';
    $scope.hasError = false;
  };

  $scope.checkConfirmPassword = () => {
    if ($scope.newPassword === $scope.confirmPassword) {
      $scope.error_msg = '';
      $scope.error_msg_confirm = '';
      $scope.hasError = false;
      $scope.validatePassword();
    } else if (!$scope.err_msg_confirm) $scope.error_msg_confirm = 'Password and confirm password do not match';
  };

  $scope.$on('emitLoginEvent', () => {
    $scope.$emit('login');
  });
}

angular.module('relayHealth').controller('resetPasswordCtrl', [
  '$scope',
  '$state',
  'API_BASE_URL',
  '$http',
  '$location',
  '$timeout',
  'toaster',
  'PASSWORD_PUBLIC_KEY',
  '$rootScope',
  'redirectUserHomeIfLoggedInService',
  resetPasswordCtrl,
]);
