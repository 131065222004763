angular.module('relayHealth').controller('marketSegmentListCntrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  '$state',
  'toaster',
  '$location',
  'loadingScreenFactory',
  'storageManager',
  function marketSegmentListCntrl(
    $scope,
    $http,
    API_BASE_URL,
    $state,
    toaster,
    $location,
    loadingScreenFactory,
    storageManager,
  ) {
    const { storage } = storageManager;
    const currentState = $state.current.name;
    $scope.permissionsConstants = relaylib.permissions.permissionsConstants;
    // controller setup - start
    $scope.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (
      $location.search().table_page === true
      || $location.search().table_page === undefined
      || Number.isNaN($location.search().table_page)
    ) {
      $location.search('table_page', 1);
    }
    $scope.addMarketSegment = { showAddMarketSegmentForm: false };
    $scope.form = {};
    $scope.pagination = {
      totalItems: 0,
      currentPage: 1,
      lastItem: 0,
      itemsPerPage: 20,
    };
    $scope.helpEmailOptions = [];
    $scope.helpPhoneOptions = [];
    $scope.operationsTeam = [];
    $scope.operationsTeamMap = {}; // used for populating operations team in market segment list
    $scope.marketSegmentList = [];
    $scope.filterMarketSegmentsData = {}; // for filtering data
    $scope.matchData = {};
    $scope.marketSegmentDropdownList = [];


    function writeSearchFilter() {
      const prevSearchValues = storage.getDataByKey('keepFilterData', currentState);
      if (!prevSearchValues) {
        return;
      }
      const prevForm = prevSearchValues;
      $scope.filterMarketSegmentsData.marketSegment = $scope.marketSegmentDropdownList.find(obj => obj.name === prevForm.name);
      $scope.filterMarketSegmentsData.help_email_id = prevForm.help_email_id;
      $scope.filterMarketSegmentsData.help_phone_id = prevForm.help_phone_id;
      $scope.filterMarketSegmentsData.billing_method = prevForm.billing_method;
      $scope.filterMarketSegmentsData.operations_team_id = prevForm.operations_team_id;
    }

    function getDropdownMarketSegments() {
      const getDropdownMarketSegmentsCall = $http({
        url: `${API_BASE_URL}marketSegments`,
        method: 'GET',
      });
      getDropdownMarketSegmentsCall.then(
        (result) => {
          if (result.data.success) {
            $scope.marketSegmentDropdownList = result.data.data;
            writeSearchFilter();
          } else {
            toaster.pop({
              type: 'error',
              title: result.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        },
        (error) => {
          toaster.pop({
            type: 'error',
            title: error.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    }
    getDropdownMarketSegments();
    function getAllHelpOptions() {
      const getAllHelpOptionsCall = $http({
        url: `${API_BASE_URL}helpcontacts`,
        method: 'GET',
      });
      getAllHelpOptionsCall.then(
        (result) => {
          if (result.data.success && result.data.data && result.data.data.length > 0) {
            $scope.helpEmailOptions = [];
            $scope.helpPhoneOptions = [];
            let i;
            for (i = 0; i < result.data.data.length; i += 1) {
              if (result.data.data[i].type === 'phone') {
                $scope.helpPhoneOptions.push(result.data.data[i]);
              } else if (result.data.data[i].type === 'email') {
                $scope.helpEmailOptions.push(result.data.data[i]);
              }
            }
          } else {
            toaster.pop({
              type: 'error',
              title: 'Unable to fetch help email or help phone number list',
              showCloseButton: true,
              timeout: 10000,
            });
          }
        },
        (error) => {
          toaster.pop({
            type: 'error',
            title: error.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    }

    getAllHelpOptions();

    function getOperationsTeam() {
      const getOperationsTeamCall = $http({
        url: `${API_BASE_URL}operationsteams`,
        method: 'GET',
      });
      getOperationsTeamCall.then(
        (result) => {
          if (result.data.success && result.data.data && result.data.data.length > 0) {
            $scope.operationsTeam = result.data.data;
            let i;
            for (i = 0; i < result.data.data.length; i += 1) {
              $scope.operationsTeamMap[result.data.data[i].id] = result.data.data[i].name;
            }
          } else {
            $scope.operationsTeam = [];
            $scope.operationsTeamMap = {};
            toaster.pop({
              type: 'error',
              title: 'Unable to fetch operations team',
              showCloseButton: true,
              timeout: 10000,
            });
          }
        },
        (error) => {
          $scope.operationsTeam = [];
          $scope.operationsTeamMap = {};
          toaster.pop({
            type: 'error',
            title: error.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    }
    getOperationsTeam();
    // controller setup - end
    // add market segment form and functions - start
    $scope.marketSegment = {};
    $scope.canceClicked = () => {
      $scope.marketSegment = {};
      $scope.addMarketSegment.showAddMarketSegmentForm = false;
      if ($scope.form.addMarketSegmentForm) {
        $scope.form.addMarketSegmentForm.$setUntouched();
        $scope.form.addMarketSegmentForm.$setPristine();
        $scope.form.addMarketSegmentForm.submitted = false;
      }
    };
    $scope.addMarketSegmentClicked = () => {
      if ($scope.form.addMarketSegmentForm && $scope.form.addMarketSegmentForm.$valid) {
        if (!$scope.marketSegment.billing_template_id) {
          $scope.marketSegment.billing_template_id = 1; // This is default set for time being
        }
        const addMarketSegmentCall = $http.post(`${API_BASE_URL}marketSegments`, $scope.marketSegment);
        loadingScreenFactory.showLoadingScreen();
        addMarketSegmentCall.then(
          (result) => {
            loadingScreenFactory.hideLoadingScreen();
            if (result.data.success) {
              toaster.pop({
                type: 'success',
                title: 'Market Segment added successfully',
                showCloseButton: true,
                timeout: 10000,
              });
              $state.transitionTo('main.marketSegment.manageMarketSegment', {
                marketSegmentId: result.data.marketSegmentId,
                edit: true,
              });
            } else {
              if (typeof result.data.message === 'string') {
                toaster.pop({
                  type: 'error',
                  title: result.data.message,
                  showCloseButton: true,
                  timeout: 10000,
                });
              } else {
                Object.keys(result.data.message).forEach(key => toaster.pop({
                  type: 'error',
                  title: result.data.message[key],
                  showCloseButton: true,
                  timeout: 10000,
                }));
              }
              $scope.form.addMarketSegmentForm.submitted = true;
            }
          },
          (error) => {
            loadingScreenFactory.hideLoadingScreen();
            if (typeof error.data.message === 'string') {
              toaster.pop({
                type: 'error',
                title: error.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
            } else {
              Object.keys(error.data.message).forEach(key => toaster.pop({
                type: 'error',
                title: error.data.message[key],
                showCloseButton: true,
                timeout: 10000,
              }));
            }
            $scope.form.addMarketSegmentForm.submitted = true;
          },
        );
      } else {
        $scope.form.addMarketSegmentForm.submitted = true;
      }
    };
    $scope.addOnEnter = (event) => {
      if (event.keyCode === 13) {
        $scope.addMarketSegmentClicked();
      }
    };
    // add market segment form and functions - end
    // table page change route handling - start
    $scope.pageChanged = (newPage) => {
      $location.search('table_page', newPage);
    };

    function adjustPagination() {
      $scope.pagination.lastItem = ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage + 20;
      if ($scope.pagination.lastItem > $scope.pagination.totalItems) {
        $scope.pagination.lastItem = $scope.pagination.totalItems;
      }
    }

    // table page change route handling - end
    // list of market segments handler and pagination - start
    function getMarketSegments() {
      const storedFilterData = storage.getDataByKey('keepFilterData', currentState);
      if (storedFilterData) {
        $scope.matchData = {
          name: storedFilterData.name ? storedFilterData.name : undefined,
          help_phone_id: storedFilterData.help_phone_id ? storedFilterData.help_phone_id : undefined,
          help_email_id: storedFilterData.help_email_id ? storedFilterData.help_email_id : undefined,
          billing_method: storedFilterData.billing_method ? storedFilterData.billing_method : undefined,
          operations_team_id: storedFilterData.operations_team_id ? storedFilterData.operations_team_id : undefined,
        };
      }
      const getMarketSegmentsCall = $http({
        url: `${API_BASE_URL}marketSegments?offset=${($location.search().table_page - 1) * $scope.pagination.itemsPerPage}&limit=${
          $scope.pagination.itemsPerPage
        }`,
        method: 'GET',
        params: $scope.matchData,
      });
      loadingScreenFactory.showLoadingScreen();
      getMarketSegmentsCall.then(
        (result) => {
          loadingScreenFactory.hideLoadingScreen();
          if (result.data.success) {
            $scope.marketSegmentList = result.data.data;
            $scope.pagination.totalItems = result.data.totalCount;
            $scope.pagination.currentPage = $location.search().table_page;
            adjustPagination();
          } else {
            $scope.pagination.totalItems = 0;
            adjustPagination();
            toaster.pop({
              type: 'error',
              title: result.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        },
        (error) => {
          loadingScreenFactory.hideLoadingScreen();
          $scope.pagination.totalItems = 0;
          $scope.marketSegmentList.length = 1;
          adjustPagination();
          toaster.pop({
            type: 'error',
            title: error.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    }

    $scope.$on('$locationChangeSuccess', () => {
      if ($location.$$path !== '/main/marketSegment/marketSegmentList') {
        $location.search('table_page', null);
        return;
      }
      if (
        $location.search().table_page === true
            || $location.search().table_page === undefined
            || Number.isNaN($location.search().table_page)
      ) {
        $location.search('table_page', 1);
      } else {
        getMarketSegments();
      }
    });

    getMarketSegments();

    // list of market segments handler and pagination - end
    // market segment filter form - start
    $scope.clearFilters = () => {
      storage.removeDataByKey('keepFilterData', currentState);
      $scope.filterMarketSegmentsData = {};
      $scope.filterMarketSegmentClicked();
    };
    $scope.filterMarketSegmentClicked = (isSearch = false) => {
      $scope.pagination.currentPage = 1;
      $scope.matchData = {};
      $scope.matchData = {
        name:
          $scope.filterMarketSegmentsData.marketSegment && $scope.filterMarketSegmentsData.marketSegment.name
            ? $scope.filterMarketSegmentsData.marketSegment.name
            : undefined,
        help_phone_id: $scope.filterMarketSegmentsData.help_phone_id ? $scope.filterMarketSegmentsData.help_phone_id : undefined,
        help_email_id: $scope.filterMarketSegmentsData.help_email_id ? $scope.filterMarketSegmentsData.help_email_id : undefined,
        billing_method: $scope.filterMarketSegmentsData.billing_method ? $scope.filterMarketSegmentsData.billing_method : undefined,
        operations_team_id: $scope.filterMarketSegmentsData.operations_team_id ? $scope.filterMarketSegmentsData.operations_team_id : undefined,
      };
      if (isSearch) {
        storage.setDataByKey('keepFilterData', currentState, $scope.matchData);
      }
      getMarketSegments();
    };
    $scope.enterOnFormSearch = (event) => {
      if (event.keyCode === 13) {
        $scope.filterMarketSegmentClicked();
      }
    };
    // market segment filter form - start
  },
]);
