angular.module('relayHealth')
  .controller('tripInfoCtrl', ['$scope', 'setMapPoints', 'GOOGLE_PLACES_ATTRIBUTES',
    function tripInfoCtrl($scope, setMapPoints, GOOGLE_PLACES_ATTRIBUTES) {
      $scope.googlePlacesAttributes = GOOGLE_PLACES_ATTRIBUTES;
      let map;

      const setMap = function setMap() {
        if (map) {
          return;
        }
        map = new google.maps.Map(document.getElementById('tripInfoMap'), {
          zoom: 12,
        });
        const markers = [];
        const mapPoints = {
          source: {
            latitude: $scope.rideDetails.tripInfo.source_lat_lng[0],
            longitude: $scope.rideDetails.tripInfo.source_lat_lng[1],
          },
          destination: {
            latitude: $scope.rideDetails.tripInfo.dest_lat_lng[0],
            longitude: $scope.rideDetails.tripInfo.dest_lat_lng[1],
          },
        };
        setMapPoints(map, markers, mapPoints);
      };

      $scope.$on('tripInfoSelected', () => {
        setMap();
      });
    }]);
