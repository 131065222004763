import statusConsts from "./constants/statusConsts";
import transportationTypeConsts from "./constants/transportationTypeConsts";
import caseStatusMappings from "./constants/caseStatusMappings";
import reasonType from "./constants/reasonType";
import statusMapping from "./constants/statusMapping";

export const vendorPortal = {
  caseStatusMappings,
  statusConsts: statusConsts,
  transportationTypeConsts: transportationTypeConsts,
  reasonType,
  statusMapping
};
