angular
  .module('relayHealth')
  .controller('parentOrgDashboardCtrl', [
    '$scope',
    'orgHierarchyFactory',
    function parentOrgDashboardCtrl($scope, orgHierarchyFactory) {
      $scope.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      $scope.organisations = orgHierarchyFactory;
      $scope.isNavCollapsed = true;
      $scope.isCollapsed = false;
      $scope.isCollapsedHorizontal = false;
      $scope.userTypedInFilterBox = () => {
        $scope.organisations.copyOrganisationsForParentOrgFilter();
        $scope.organisations.uncollapseChildren();
      };
    },
  ])
  .filter('orgFilter', () => {
    function analyzeChildren(child, searchText, depth) {
      if (child.children && child.children.length) {
        for (let j = child.children.length - 1; j > -1; j -= 1) {
          const result = analyzeChildren(child.children[j], searchText, depth + 1);
          if (!result) {
            child.children.splice(j, 1);
          }
        }
      }
      if ((!child.children || !child.children.length) && child.name.toLowerCase().indexOf(searchText.toLowerCase()) === -1) {
        return null;
      }
      return child;
    }
    return function orgFilter(organisations, searchText) {
      if (!searchText) {
        return organisations;
      }
      analyzeChildren({ name: 'root', children: organisations }, searchText, 0);
      return organisations;
    };
  });
