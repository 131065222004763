const directiveDef = [
  function breadcrumbDirective() {
    return {
      restrict: 'E',
      template: require('./breadcrumb.html'),
      scope: {
        pageTitle: '=',
        breadcrumbs: '=',
        currentPage: '=',
      },
    };
  },
];
export default directiveDef;
