import moment from 'moment';

angular
  .module('relayHealth')
  .factory('messageCenterService', ['API_BASE_URL', '$http',
    function messageCenterService(API_BASE_URL, $http) {
      let unreadCount = 0;

      async function getMessages(pageNumber, count) {
        const {
          data: {
            messages,
            total,
            unreadCount: newUnreadCount,
          },
        } = await $http.get(`${API_BASE_URL}messages?pageNumber=${pageNumber}&count=${count}`);
        unreadCount = newUnreadCount;
        return {
          messages: messages.map(({
            id, title, body, createdAt, read_at: readAt, meta,
          }) => ({
            id,
            title,
            body,
            createdAt: moment(createdAt),
            readAt,
            meta,
          })),
          total,
        };
      }

      async function markMessageRead(notificationMessageId) {
        const { data } = await $http.patch(`${API_BASE_URL}messages`, { notificationMessageId });
        if (unreadCount > 0) {
          unreadCount -= 1;
        }
        return data;
      }

      function getUnreadCount() {
        return unreadCount;
      }

      function incrementUnreadCount() {
        unreadCount = parseInt(unreadCount, 10) + 1;
      }

      return {
        getMessages,
        markMessageRead,
        getUnreadCount,
        incrementUnreadCount,
      };
    }]);
