import moment from 'moment';
import app from '../../../app';

app
  .controller('inviteLogCtrl', [
    '$scope',
    '$timeout',
    'API_BASE_URL',
    '$http',
    'toaster',
    'loadingScreenFactory',
    '$uibModal',
    '$state',
    'Pubnub',
    function inviteLogCtrl(
      $scope,
      $timeout,
      API_BASE_URL,
      $http,
      toaster,
      loadingScreenFactory,
      $uibModal,
      $state,
      Pubnub,
    ) {
      $scope.pageTitle = 'Manage Riders';
      $scope.showAddRiderForm = false;
      $scope.now = moment();
      $scope.addRide = {};
      $scope.exportButtonText = 'Export';
      $scope.moment = moment;
      $scope.dateRangeExport = {
        startDate: null,
        endDate: null,
      };

      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      const datePickerToggleState = {
        '#riderDob': false,
        '#riderDoi': false,
      };

      const showToaster = (type, title) => {
        toaster.pop({
          type,
          title,
          showCloseButton: true,
          timeout: 10000,
        });
      };

      $scope.dateOptions = {
        singleDatePicker: true,
        parentEl: '#current-app #page-wrapper',
        locale: {
          format: 'MM-DD-YYYY',
        },
        showDropdowns: true,
        eventHandlers: {
          'showCalendar.daterangepicker': ({ picker: { element: [{ id }] } }) => {
            datePickerToggleState[`#${id}`] = true;
          },
          'hideCalendar.daterangepicker': ({ picker: { element: [{ id }] } }) => {
            datePickerToggleState[`#${id}`] = false;
          },
        },
      };

      $scope.toggleDatePicker = function toogleDatePicker(elementName) {
        if (datePickerToggleState[elementName]) {
          datePickerToggleState[elementName] = false;
          $timeout(() => {
            $(elementName).data('daterangepicker').hide();
          });
        } else {
          datePickerToggleState[elementName] = true;
          $timeout(() => {
            $(elementName).data('daterangepicker').show();
          });
        }
      };

      $scope.pagination = {
        totalItems: 0,
        currentPage: 1,
        lastItem: 0,
        itemsPerPage: 20,
      };

      const adjustPagination = function adjustPagination() {
        $scope.pagination.lastItem = ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage + 20;
        if ($scope.pagination.lastItem > $scope.pagination.totalItems) {
          $scope.pagination.lastItem = $scope.pagination.totalItems;
        }
      };

      const getSearchString = () => {
        const searchStr = [];
        const filterVal = $scope.filterRider;
        Object.keys(filterVal).forEach((key) => {
          if (filterVal[key]) {
            if (key === 'phone') {
              const phone = filterVal[key]
                .replace(/-/g, '')
                .replace(' ', '')
                .replace(/_/g, '')
                .replace('(', '')
                .replace(')', '')
                .trim();
              searchStr.push(`${key}=${phone}`);
            } else if (key === 'riderName') {
              const riderName = filterVal[key]
                .replace(/[ ]/g, '%20')
                .trim();
              searchStr.push(`${key}=${riderName}`);
            } else if (key === 'dateOfBirth') {
              const formatedDate = moment(filterVal[key]).format('MM/DD/YYYY');
              searchStr.push(`${key}=${formatedDate.trim()}`);
            } else if (key === 'organization') {
              searchStr.push(`org_id=${filterVal[key].id}`);
            } else {
              searchStr.push(`${key}=${filterVal[key].trim()}`);
            }
          }
        });
        let temp = searchStr.join('&');
        if (searchStr.length > 0) {
          temp = `&${temp}`;
        }
        return temp;
      };

      const getInvitedRiders = async (offset = 0, limit = $scope.pagination.itemsPerPage) => {
        loadingScreenFactory.showLoadingScreen();
        try {
          const headers = {
            username: userDetails.username,
            usertoken: userDetails.user_token,
            'Content-Type': 'application/json',
          };
          let requestUrl = `${API_BASE_URL}invite?`;
          if (offset >= 0 && limit > 0) {
            requestUrl += `&offset=${offset}&limit=${limit}`;
          }
          if ($scope.filterRider) {
            requestUrl += getSearchString();
          }
          const { data: { success, data, count } } = await $http.get(requestUrl, { headers });
          if (success) {
            $scope.invitationList = data;
            $scope.pagination.totalItems = count;
            adjustPagination();
          }
        } catch (err) {
          const message = (err.data && err.data.message) ? err.data.message : err.messsage;
          toaster.pop({
            type: 'error',
            title: message,
            showCloseButton: true,
          });
        } finally {
          loadingScreenFactory.hideLoadingScreen();
          $scope.$apply();
        }
      };

      $scope.searchButtonClicked = () => {
        $scope.pagination.currentPage = 1;
        getInvitedRiders();
      };

      $scope.clearFilters = () => {
        $scope.filterRider = {};
        getInvitedRiders();
      };

      const init = async () => {
        try {
          $scope.invitationList = [];
          getInvitedRiders();
        } catch (err) {
          const message = err.data.message ? err.data.message : err.messsage;
          toaster.pop({
            type: 'error',
            title: message,
            body: err.message,
            showCloseButton: true,
          });
        }
      };
      init();

      $scope.pageChanged = function pageChanged(currentPage) {
        const offset = (currentPage - 1) * $scope.pagination.itemsPerPage;
        const limit = $scope.pagination.itemsPerPage;
        getInvitedRiders(offset, limit);
      };


      $scope.getInvitationLogs = async (index, invitation) => {
        if (invitation.logDetails) {
          Object.assign(invitation, {
            logDetails: null,
          });
          return;
        }
        try {
          loadingScreenFactory.showLoadingScreen();
          const { id } = invitation;
          const { data: { success, message, hits } } = await $http.get(`${API_BASE_URL}invite/${id}/logs`);
          if (!success) {
            showToaster('error', message);
          } else {
            $scope.invitationList[index].logDetails = hits;
          }
        } catch (e) {
          showToaster('error', e.message);
        } finally {
          loadingScreenFactory.hideLoadingScreen();
          $scope.$apply();
        }
      };

      $scope.showMeta = (metaLog) => {
        const modalInstance = $uibModal.open({
          animation: true,
          template: require('../log-details.html'),
          size: 'lg',
          backdrop: 'static',
          resolve: {
            meta() {
              return angular.copy(metaLog);
            },
          },
          controllerAs: 'logDetailsCtrl',
          controller: [
            'meta',
            '$uibModalInstance',
            function modalCtrl(meta, $uibModalInstance) {
              Object.assign(this, {
                meta,
                closePopup() {
                  $uibModalInstance.close();
                },
              });
            },
          ],
        });
        $scope.$on('$destroy', () => {
          modalInstance.close();
        });
      };

      const getLatestProcessedExportRecords = async () => {
        try {
          const { data: { success, data, message } } = await $http.get(`${API_BASE_URL}export/getLatestProcessedExportRecords/invite_only_logs`);
          if (success) {
            $scope.exportList = data;
          } else {
            showToaster('error', message);
          }
        } catch (e) {
          showToaster('error', e.message);
        }
      };
      getLatestProcessedExportRecords();

      const listenForPubnubMessages = () => {
        $scope.$on(Pubnub.getMessageEventNameFor(userDetails.user_id), (event, { message: { type } }) => {
          if (type === 'fileReady'
            && ($state.current.name === 'main.dashboard.logs.inviteLogs')) {
            getLatestProcessedExportRecords();
          }
        });
      };
      listenForPubnubMessages();

      $scope.exportInDateRange = async () => {
        try {
          const url = `${API_BASE_URL}saveExportFilters`;
          $scope.exportButtonText = 'Processing';
          const { data: { success } } = await $http.post(url, {
            start_date: $scope.dateRangeExport.startDate.valueOf(),
            end_date: $scope.dateRangeExport.endDate.valueOf(),
            fileName: `invite_only_logs_${$scope.dateRangeExport.startDate.format('MM-DD-YYYY')}-${$scope.dateRangeExport.endDate.format('MM-DD-YYYY')}-${new Date().getTime()}_${userDetails.user_id}.csv`,
            page: 'invite_only_logs',
          });
          $scope.exportButtonText = 'Export';
          if (!success) {
            showToaster('error', 'Failed');
          } else {
            showToaster('info', 'Please wait for your file');
            getLatestProcessedExportRecords();
          }
        } catch (e) {
          showToaster('error', e.message);
        }
      };

      $scope.getAllOrgsForSelectBox = (offset, limit) => {
        // get orgtype based on selected filter
        const orgType = 'all';
        return $http
          .get(`${API_BASE_URL}organisation/getOrganizations/${orgType}/${offset}/${limit}`)
          .then((resp) => {
            const response = (resp.data.org_details || []).sort((a, b) => a.name.localeCompare(b.name));
            return {
              data: response,
              count: resp.data.totalCount,
            };
          });
      };
    },
  ]);
