/**
 * INSPINIA - Responsive Admin Theme
 *
 * Main directives.js file
 * Define directives for used plugin
 *
 *
 * Functions (directives)
 *  - sideNavigation
 *  - iboxTools
 *  - minimalizaSidebar
 *  - vectorMap
 *  - sparkline
 *  - icheck
 *  - ionRangeSlider
 *  - dropZone
 *  - responsiveVideo
 *  - customValid
 *  - closeOffCanvas
 *  - clockPicker
 *  - fitHeight
 *  - truncate
 *  - touchSpin
 *  - markdownEditor
 *  - resizeable
 *
 */

/**
 * pageTitle - Directive for set Page title - mata title
 */
function pageTitle($transitions, $timeout, SITE_NAME = 'RelaySOLUTIONS') {
  return {
    link(scope, element) {
      const listener = function (transition) {
        // Default title - load on Dashboard 1
        let title = SITE_NAME;
        // Create your own title pattern
        const toState = transition.to();
        if (toState.data && toState.data.pageTitle) title = `${title} | ${toState.data.pageTitle}`;
        $timeout(() => {
          element.text(title);
        });
      };

      $transitions.onSuccess({}, listener);
    },
  };
}

/**
 * sideNavigation - Directive for run metsiMenu on sidebar navigation
 */
function sideNavigation($timeout) {
  return {
    restrict: 'A',
    link(scope, element) {
      // Call the metsiMenu plugin and plug it to sidebar navigation
      $timeout(() => {
        element.metisMenu();
      });
    },
  };
}

/**
 * responsibleVideo - Directive for responsive video
 */
function responsiveVideo() {
  return {
    restrict: 'A',
    link(scope, element) {
      const figure = element;
      const video = element.children();
      video
        .attr('data-aspectRatio', video.height() / video.width())
        .removeAttr('height')
        .removeAttr('width');

      // We can use $watch on $window.innerWidth also.
      $(window)
        .resize(() => {
          const newWidth = figure.width();
          video.width(newWidth).height(newWidth * video.attr('data-aspectRatio'));
        })
        .resize();
    },
  };
}

/**
 * iboxTools - Directive for iBox tools elements in right corner of ibox
 */
function iboxTools($timeout) {
  return {
    restrict: 'A',
    scope: true,
    template: require('../../views/common/ibox_tools.html'),
    controller: [
      '$scope',
      '$element',
      ($scope, $element) => {
        'ngInject';

        // Function for collapse ibox
        $scope.showhide = function (event) {
          event.stopPropagation();
          const ibox = $element.closest('div.ibox');
          const icon = $element.find('i:first');
          const content = ibox.find('div.ibox-content');
          content.slideToggle(200);
          // Toggle icon from up to down
          icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
          ibox.toggleClass('').toggleClass('border-bottom');
          $timeout(() => {
            ibox.resize();
            ibox.find('[id^=map-]').resize();
            ibox.find('[id^=mapbutton-]').triggerHandler('click');
          }, 50);
        };
        // Function for close ibox
        $scope.closebox = function () {
          const ibox = $element.closest('div.ibox');
          ibox.remove();
        };
      },
    ],
  };
}

/**
 * iboxTools - Directive for iBox tools elements in right corner of ibox
 */
function iboxToolsButton($timeout) {
  return {
    restrict: 'A',
    scope: true,
    template: require('../../views/common/ibox_tools.html'),
    controller: [
      '$scope',
      '$element',
      ($scope, $element) => {
        'ngInject';

        const icon = $element.find('i:first');
        icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
        // Function for collapse ibox
        $scope.showhide = function () {
          const ibox = jQuery('div#ibox-button');

          const content = ibox.find('div.ibox-content');
          content.slideToggle(200);
          // Toggle icon from up to down
          icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
          ibox.toggleClass('').toggleClass('border-bottom');
          $timeout(() => {
            ibox.resize();
            ibox.find('[id^=map-]').resize();
          }, 50);
        };
        // Function for close ibox
        $scope.closebox = function () {
          const ibox = $element.closest('div#ibox-button');
          ibox.remove();
        };
      },
    ],
  };
}

/**
 * minimalizaSidebar - Directive for minimalize sidebar
 */
function minimalizaSidebar($timeout) {
  return {
    restrict: 'A',
    template:
      '<a class="navbar-minimalize minimalize-styl-2 btn btn-primary " href="" ng-click="minimalize();$event.stopPropagation();"><i class="fa fa-bars"></i></a>',
    controller: [
      '$scope',
      '$element',
      '$location',
      '$rootScope',
      '$document',
      function ($scope, $element, $location, $rootScope, $document) {
        $scope.minimalize = function (event) {
          const queryString = $location.search();

          const searchObj = { nav: null };

          for (const key in queryString) {
            searchObj[key] = queryString[key];
          }

          if (queryString.nav) {
            searchObj.nav = null;
          } else {
            searchObj.nav = 'true';
          }

          $location.search(searchObj);

          $('body').toggleClass('mini-navbar');
          $('.invisible-toggle').toggleClass('invisible-unless-minimized');
          if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
            // Hide menu in order to smoothly turn on when maximize menu
            $('#side-menu').hide();
            // For smoothly turn on menu
            setTimeout(() => {
              $('#side-menu').fadeIn(400);
            }, 200);
          } else if ($('body').hasClass('fixed-sidebar')) {
            $('#side-menu').hide();
            setTimeout(() => {
              $('#side-menu').fadeIn(400);
            }, 100);
          } else {
            // Remove all inline style from jquery fadeIn function to reset menu state
            $('#side-menu').removeAttr('style');
          }
        };

        $rootScope.$on('minimizeNavbar', () => {
          $scope.minimalize();
        });

        $rootScope.$on('removeClickHandler', () => {
          $document.off('click', onDocumentClick);
        });

        function onDocumentClick() {
          if($('body').hasClass('body-small') && $('body').hasClass('mini-navbar')){
            $scope.minimalize();
          } 
        }

        const ismobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
        if (ismobile) {
          $document.on('touchstart', onDocumentClick);
        }

        $scope.$on('$destroy', onDocumentClick);
      },
    ],
  };
}

function closeOffCanvas() {
  return {
    restrict: 'A',
    template: '<a class="close-canvas-menu" ng-click="closeOffCanvas()"><i class="fa fa-times"></i></a>',
    controller: [
      '$scope',
      '$element',
      ($scope, $element) => {
        'ngInject';

        $scope.closeOffCanvas = () => {
          $('body').toggleClass('mini-navbar');
        };
      },
    ],
  };
}

/**
 * vectorMap - Directive for Vector map plugin
 */
function vectorMap() {
  return {
    restrict: 'A',
    scope: {
      myMapData: '=',
    },
    link(scope, element, attrs) {
       element.vectorMap({
        map: 'world_mill_en',
        backgroundColor: 'transparent',
        regionStyle: {
          initial: {
            fill: '#e4e4e4',
            'fill-opacity': 0.9,
            stroke: 'none',
            'stroke-width': 0,
            'stroke-opacity': 0,
          },
        },
        series: {
          regions: [
            {
              values: scope.myMapData,
              scale: ['#1ab394', '#22d6b1'],
              normalizeFunction: 'polynomial',
            },
          ],
        },
      });
      const destroyMap = function () {
        element.remove();
      };
      scope.$on('$destroy', () => {
        destroyMap();
      });
    },
  };
}

/**
 * sparkline - Directive for Sparkline chart
 */
function sparkline() {
  return {
    restrict: 'A',
    scope: {
      sparkData: '=',
      sparkOptions: '=',
    },
    link(scope, element, attrs) {
      scope.$watch(scope.sparkData, () => {
        render();
      });
      scope.$watch(scope.sparkOptions, () => {
        render();
      });
      var render = function () {
        $(element).sparkline(scope.sparkData, scope.sparkOptions);
      };
    },
  };
}

/**
 * icheck - Directive for custom checkbox icheck
 */
function icheck($timeout) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link($scope, element, $attrs, ngModel) {
      return $timeout(() => {
        const { value } = $attrs;

        $scope.$watch($attrs.ngModel, () => {
          $(element).iCheck('update');
        });

        $scope.$watch($attrs.ngDisabled, (newValue) => {
          $(element).iCheck(newValue ? 'disable' : 'enable');
          $(element).iCheck('update');
        });

        return $(element)
          .iCheck({
            checkboxClass: 'icheckbox_square-green',
            radioClass: 'iradio_square-green',
          })
          .on('ifChanged', (event) => {
            if ($(element).attr('type') === 'checkbox' && $attrs.ngModel) {
              $scope.$apply(() => ngModel.$setViewValue(event.target.checked));
            }
            if ($(element).attr('type') === 'radio' && $attrs.ngModel) {
              return $scope.$apply(() => ngModel.$setViewValue(value));
            }
          });
      });
    },
  };
}

/**
 * ionRangeSlider - Directive for Ion Range Slider
 */
function ionRangeSlider() {
  return {
    restrict: 'A',
    scope: {
      rangeOptions: '=',
    },
    link(scope, elem, attrs) {
      elem.ionRangeSlider(scope.rangeOptions);
    },
  };
}

/**
 * dropZone - Directive for Drag and drop zone file upload plugin
 */
function dropZone() {
  return {
    restrict: 'C',
    link(scope, element, attrs) {
      const config = {
        url: 'http://localhost:8080/upload',
        maxFilesize: 100,
        paramName: 'uploadfile',
        maxThumbnailFilesize: 10,
        parallelUploads: 1,
        autoProcessQueue: false,
      };

      const eventHandlers = {
        addedfile(file) {
          scope.file = file;
          if (this.files[1] != null) {
            this.removeFile(this.files[0]);
          }
          scope.$apply(() => {
            scope.fileAdded = true;
          });
        },

        success(file, response) {},
      };

      const dropzone = new Dropzone(element[0], config);

      angular.forEach(eventHandlers, (handler, event) => {
        dropzone.on(event, handler);
      });

      scope.processDropzone = function () {
        dropzone.processQueue();
      };

      scope.resetDropzone = function () {
        dropzone.removeAllFiles();
      };
    },
  };
}

/**
 * customValid - Directive for custom validation example
 */
function customValid() {
  return {
    require: 'ngModel',
    link(scope, ele, attrs, c) {
      scope.$watch(attrs.ngModel, () => {
        // You can call a $http method here
        // Or create custom validation

        const validText = 'Inspinia';

        if (scope.extras == validText) {
          c.$setValidity('cvalid', true);
        } else {
          c.$setValidity('cvalid', false);
        }
      });
    },
  };
}

/**
 * clockPicker - Directive for clock picker plugin
 */
function clockPicker() {
  return {
    restrict: 'A',
    link(scope, element) {
      element.clockpicker();
    },
  };
}

/**
 * fitHeight - Directive for set height fit to window height
 */
function fitHeight() {
  return {
    restrict: 'A',
    link(scope, element) {
      element.css('height', `${$(window).height()}px`);
      element.css('min-height', `${$(window).height()}px`);
    },
  };
}

/**
 * truncate - Directive for truncate string
 */
function truncate($timeout) {
  return {
    restrict: 'A',
    scope: {
      truncateOptions: '=',
    },
    link(scope, element) {
      $timeout(() => {
        element.dotdotdot(scope.truncateOptions);
      });
    },
  };
}

/**
 * touchSpin - Directive for Bootstrap TouchSpin
 */
function touchSpin() {
  return {
    restrict: 'A',
    scope: {
      spinOptions: '=',
    },
    link(scope, element, attrs) {
      scope.$watch(scope.spinOptions, () => {
        render();
      });
      var render = function () {
        $(element).TouchSpin(scope.spinOptions);
      };
    },
  };
}

/**
 * markdownEditor - Directive for Bootstrap Markdown
 */
function markdownEditor() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link(scope, element, attrs, ngModel) {
      $(element).markdown({
        savable: false,
        onChange(e) {
          ngModel.$setViewValue(e.getContent());
        },
      });
    },
  };
}

function inputMask() {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      maskFormat: '@',
    },
    link(scope, element, attrs, ngModel) {
      if (!scope.maskFormat) {
        alert('Provide a mask-format attribute');
        return;
      }
      element.inputmask({ mask: scope.maskFormat });
      element.on('keyup', (event) => {
        scope.$apply(() => {
          ngModel.$setViewValue(element.val());
        });
      });
    },
  };
}

/**
 * displayOrganisationChildren - Directive for nested org hierarchy
 */
function displayOrganisationChildren() {
  return {
    restrict: 'E',
    scope: {
      organisationChildren: '=',
      depth: '=',
    },
    controller: [
      '$scope',
      '$element',
      '$window',
      'orgHierarchyFactory',
      function ($scope, $element, $window, orgHierarchyFactory) {
        $scope.selectOrg = function (organisation, event) {
          event.stopPropagation();
          return orgHierarchyFactory.switchOrganisation(organisation);
        };
        $scope.addMarginAndColorByNestingDepth = function (depth) {
          const depthPlusOne = depth + 1;
          const red = 89;
          const green = 186;
          const blue = 167;
          const alpha = (0.5 / depthPlusOne) * 1.3;
          return {
            'background-color': `rgba(${red}, ${green}, ${blue}, ${alpha})`,
            'margin-left': depth * 20,
          };
        };
      },
    ],
    template: require('../../views/common/display_organisation_children.html'),
  };
}

/**
 *
 * Pass all functions into module
 */

angular
  .module('relayHealth')
  .directive('pageTitle', ['$transitions', '$timeout', pageTitle])
  .directive('sideNavigation', ['$timeout', sideNavigation])
  .directive('iboxTools', ['$timeout', iboxTools])
  .directive('iboxToolsButton', ['$timeout', iboxToolsButton])
  .directive('minimalizaSidebar', ['$timeout', minimalizaSidebar])
  .directive('vectorMap', vectorMap)
  .directive('sparkline', sparkline)
  .directive('icheck', ['$timeout', icheck])
  .directive('ionRangeSlider', ionRangeSlider)
  .directive('dropZone', dropZone)
  .directive('responsiveVideo', responsiveVideo)
  .directive('customValid', customValid)
  .directive('closeOffCanvas', closeOffCanvas)
  .directive('clockPicker', clockPicker)
  .directive('fitHeight', fitHeight)
  .directive('truncate', ['$timeout', truncate])
  .directive('touchSpin', touchSpin)
  .directive('markdownEditor', markdownEditor)
  .directive('inputMask', inputMask)
  .directive('displayOrganisationChildren', displayOrganisationChildren);
