import broadcastCategories from './constants/broadcastCategories';
import broadcastTypes from './constants/broadcastTypes';
import broadcastTargets from './constants/broadcastTargets';
import broadcastStatuses from './constants/broadcastStatuses';
import broadcastChannelConstants from './constants/broadcastChannelConstants';

export const broadcasts = {
    broadcastCategories: broadcastCategories,
    broadcastTypes: broadcastTypes,
    broadcastTargets: broadcastTargets,
    broadcastStatuses: broadcastStatuses,
    broadcastChannelConstants: broadcastChannelConstants
};