angular
  .module('relayHealth')
  .filter('join', () => (array, separator, prop) => {
    if (!Array.isArray(array)) {
      return array; // if not array return original - can also throw error
    }

    return (prop ? array.map(item => item[prop]) : array).join(separator);
  })
  .filter('sanitize', () => input => input.replace(/_/g, ' '))
  .filter('patient2rider', () => input => (input ? input.replace(/patient/g, 'rider').replace(/Patient/g, 'Rider') : null))
  .filter('enroute2EnRoute', () => input => (input ? input.replace(/Enroute/g, 'En Route') : null))
  .filter('lateArrival2EnRouteLateArrival', () => input => (input ? input.replace(/Late Arrival/g, 'Ride En Route-Late Arrival') : null))
  .filter('appointment2trip', () => input => (input ? input.replace(/appointment/g, 'trip').replace(/Appointment/g, 'Trip') : null))
  .filter('variance2RideComplete', () => input => (['Variance Issue', 'Variance Under', 'Variance Over'].includes(input) ? 'Ride Completed (Under Process)' : input))
  .filter('arrivalTimeExceeded2LateArrival', () => (input) => {
    if (input) {
      return input.replace(/Arrival Time Exceeded/g, 'Late Arrival');
    }
    return null;
  })
  .filter('localeDate', () => (input, moment, zone) => {
    if (!zone) {
      return `${moment(input).format('MM/DD/YYYY hh:mm a')} ${moment()
        .tz(moment.tz.guess())
        .zoneAbbr()}`;
    }
    return `${moment(input)
      .tz(zone)
      .format('MM/DD/YYYY hh:mm a')} ${moment()
      .tz(zone)
      .zoneAbbr()}`;
  })
  .filter('displayRole', () => input => relaylib.common.services.userRolesDisplayTextService(input))
  .filter('tel', () => (tel) => {
    if (!tel) {
      return '';
    }

    const value = tel
      .toString()
      .trim()
      .replace(/^\+/, '');

    if (value.match(/[^0-9]/)) {
      return tel;
    }

    let country;
    let city;
    let number;

    switch (value.length) {
      case 10: // +1PPP####### -> C (PPP) ###-####
        country = 1;
        city = value.slice(0, 3);
        number = value.slice(3);
        break;

      case 11: // +CPPP####### -> CCC (PP) ###-####
        [country] = value;
        city = value.slice(1, 4);
        number = value.slice(4);
        break;

      case 12: // +CCCPP####### -> CCC (PP) ###-####
        country = value.slice(0, 2);
        city = value.slice(2, 5);
        number = value.slice(5);
        break;

      default:
        return tel;
    }

    if (country === 1) {
      country = '';
    } else {
      country = `+${country}`;
    }

    number = `${number.slice(0, 3)}-${number.slice(3)}`;

    return `${country} (${city}) ${number}`.trim();
  })
  .filter('capitalize', () => (input) => {
    if (input && typeof input === 'string') {
      const splitStr = input.toLowerCase().split(' ');
      for (let i = 0; i < splitStr.length; i += 1) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(' ');
    }

    // return (!!input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
  })
  .filter('orderObjectBy', () => (items, field, reverse) => {
    const filtered = [];
    angular.forEach(items, (item) => {
      filtered.push(item);
    });
    filtered.sort((a, b) => {
      let af;
      let bf;
      if (typeof a[field] === 'string') af = a[field].toUpperCase();

      if (typeof b[field] === 'string') bf = b[field].toUpperCase();

      return af > bf ? 1 : -1;
    });
    if (reverse) filtered.reverse();
    return filtered;
  })
  .filter('timeFromNow', () => value => moment(parseInt(value, 10)).fromNow())
  .filter('phoneFormat', () => (phoneNumber) => {
    if (!phoneNumber) return '';
    phoneNumber = phoneNumber.replace(/[-)\s(]/g, '');
    try {
      const {
        countryCallingCode,
        nationalNumber,
      } = libPhoneNumber.parsePhoneNumber(phoneNumber.includes('+') ? phoneNumber : `+${phoneNumber}`); // plus sign is required to parse phone number properly
      return `${countryCallingCode}-${nationalNumber.substring(0,3)}-${nationalNumber.substring(3, 6)}-${nationalNumber.substring(6)}`;
    } catch (error) {
      // some rare cases if the phone number fails to parse at all (may be country code is not given at all)
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
    }
  })
  .filter('memberFilter', () => (member) => {
    if (!member) return false;
    if (member === 'org_superadmin') {
      return 'Admin';
    }

    if (member === 'orgadmin') {
      return 'User';
    }

    if (member === 'riderUser') {
      return 'User';
    }
  })
  .filter(
    'propsFilter',
    () => function propsFilter(items, props) {
      if (!angular.isArray(items)) {
        return items;
      }
      const out = [];
      const extraItems = [];
      const keys = Object.keys(props);
      items.forEach((item) => {
        let partialMatch = false;
        for (let i = 0; i < keys.length; i += 1) {
          const prop = keys[i];
          const text = props[prop].toLowerCase();
          const itemText = item[prop].toString().toLowerCase();
          const re = new RegExp(`^${text}`);
          if (re.test(itemText)) {
            out.push(item);
            return;
          }
          if (itemText.indexOf(text) !== -1) {
            partialMatch = true;
          }
        }
        if (partialMatch) {
          extraItems.push(item);
        }
      });
      return out.concat(extraItems);
    },
  )
  .filter('arrayFilter', () => function arrayFilter(items, searchString) {
    let out = [];

    if (angular.isArray(items)) {
      items.forEach((item) => {
        let itemMatches = false;
        const text = searchString.toLowerCase();
        if (
          item
            .toString()
            .toLowerCase()
            .indexOf(text) !== -1
        ) {
          itemMatches = true;
        }
        if (itemMatches) {
          out.push(item);
        }
      });
    } else {
      // Let the output be the input untouched
      out = items;
    }

    return out;
  })
  .filter('propsFilterForDynamicExport', () => function propsFilterForDynamicExport(items, props) {
    if (angular.isArray(items)) {
      const out = [];
      const extraItems = [];
      const keys = Object.keys(props);
      items.forEach((item) => {
        let partialMatch = false;
        for (let i = 0; i < keys.length; i += 1) {
          const prop = keys[i];
          const text = props[prop].toLowerCase();
          const itemText = item[prop].toString().toLowerCase();
          const re = new RegExp(`^${text}`);
          if (re.test(itemText)) {
            out.push(item);
            return;
          } 
          
          if (itemText.indexOf(text) !== -1 || item.checked) {
            partialMatch = true;
          }
        }
        if (partialMatch) { extraItems.push(item); }
      });
      return out.concat(extraItems); // .concat(selectedItems);
    }
    // Let the output be the input untouched
    return items;
  })
  .filter('isMasterOrg', ['MASTER_ORG_LEVEL', MASTER_ORG_LEVEL => orgLevel => MASTER_ORG_LEVEL.indexOf(parseInt(orgLevel, 10)) !== -1])
  .filter('permission', ['permissionsManager', permissionsManager => permission => permissionsManager.isAllowed(permission)]);
