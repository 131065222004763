angular.module('relayHealth').controller('ReleaseNotesListCtrl', [
  '$scope',
  '$state',
  '$uibModal',
  '$http',
  'API_BASE_URL',
  'loadingScreenFactory',
  'SweetAlert',
  'toaster',
  function ReleaseNotesListCtrl($scope, $state, $uibModal, $http, API_BASE_URL, loadingScreenFactory, SweetAlert, toaster) {
    const watcherRemovers = [];

    function convertToDM(inData) {
      return {
        id: inData.id,
        title: inData.title,
        message: inData.content,
        targets: inData.targets,
        isTargetWeb: inData.targets.indexOf($scope.broadcastTargets.WEB) >= 0,
        isTargetAndroid: inData.targets.indexOf($scope.broadcastTargets.ANDROID) >= 0,
        isTargetIos: inData.targets.indexOf($scope.broadcastTargets.IOS) >= 0,
        isTargetDriverAppIos: inData.targets.indexOf($scope.broadcastTargets.DRIVER_IOS) >= 0,
        isTargetDriverAppAndroid: inData.targets.indexOf($scope.broadcastTargets.DRIVER_ANDROID) >= 0,
        version: inData.tags && inData.tags.version,
      };
    }

    function init() {
      $scope.breadcrumbs = [
        {
          label: 'Home',
          onClick() {
            $state.go('main.dashboard.superAdminDashboard', null, { reload: true });
          },
        },
      ];

      $scope.broadcastTargets = relaylib.broadcasts.broadcastTargets;
      $scope.broadcastCats = relaylib.broadcasts.broadcastCategories;
      $scope.broadcastStatuses = relaylib.broadcasts.broadcastStatuses;
      $scope.dataRefresh = { value: false };
      $scope.dataRefetch = false;
      $scope.isLoading = false;
      $scope.searchTxt = '';
    }

    $scope.fetchData = function fetchData(paginationParams) {
      $scope.isLoading = true;

      const params = Object.assign({
        category: $scope.broadcastCats.RELEASE_NOTES,
        sorton: 'createdAt',
      }, paginationParams);

      if ($scope.searchTxt) {
        params.search = $scope.searchTxt;
      }

      return $http
        .get(`${API_BASE_URL}broadcasts`, {
          params,
        })
        .then((resp) => {
          if (resp.data) {
            $scope.broadcastsData = _.map(resp.data.data, d => convertToDM(d));
          } else {
            $scope.broadcastsData = [];
          }
          return resp.data;
        }, (error) => {
          toaster.pop({
            type: 'error',
            title: (error.data || {}).message ? error.data.message : error.message,
            timeout: 3000,
          });
        })
        .finally(() => {
          $scope.isLoading = false;
        });
    };

    $scope.refreshData = () => {
      $scope.dataRefresh.value = true;
    };

    $scope.searchData = (e) => {
      if (!e || e.keyCode === 13) {
        // search data iff, either button pressed or enter pressed
        $scope.dataRefetch = true;
      }
    };

    $scope.removeItem = (broadcastId) => {
      if (broadcastId) {
        SweetAlert.swal(
          {
            title: 'Are you sure you want to delete this item ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonClass: 'btn-warning',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            closeOnConfirm: true,
            closeOnCancel: true,
          },
          (isConfirm) => {
            if (isConfirm) {
              $scope.isLoading = true;
              $http
                .delete(`${API_BASE_URL}broadcasts/${broadcastId}`)
                .then((resp) => {
                  if (resp.data) {
                    $scope.refreshData();
                  } else {
                    // Do nothing on error
                  }
                  return resp.data;
                })
                .finally(() => {
                  $scope.isLoading = false;
                });
            }
          },
        );
      }
      return false;
    };

    $scope.openModal = (editData) => {
      const modalInstance = $uibModal.open({
        template: require('./create-release-note.html'),
        controller: 'CreateReleaseNoteCtrl',
        size: 'lg',
        backdrop: 'static',
        windowTopClass: 'inmodal',
        resolve: {
          editInfo: editData,
        },
      });

      modalInstance.result.then(() => {
        $scope.refreshData();
      });
    };

    watcherRemovers.push(
      $scope.$watch('isLoading', (n) => {
        if (n) {
          loadingScreenFactory.showLoadingScreen();
        } else {
          loadingScreenFactory.hideLoadingScreen();
        }
      }),
    );

    $scope.$on('$destroy', () => {
      angular.forEach(watcherRemovers, (w) => {
        w();
      });
    });

    init();
  },
]);
