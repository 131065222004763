import notificationIndicatorTemplate from './template.html';

angular
  .module('relayHealth')
  .component('notificationIndicator', {
    template: notificationIndicatorTemplate,
    controller: [
      'messageCenterService',
      '$scope',
      '$state',
      'toaster',
      'permissionFilter',
      function notificationIndicatorComponent({
        getUnreadCount,
        getMessages,
        markMessageRead,
      }, $scope, $state, toaster, permissionFilter) {
        this.getUnreadCount = getUnreadCount;
        this.messages = [];
        this.invokeGetMessages = function invokeGetMessages() {
          getMessages(0, 5)
            .then(({ messages }) => {
              $scope.$apply(() => {
                this.loading = false;
                this.messages = messages;
              });
            });
        };
        this.getMessages = (open) => {
          if (!open) return;
          this.loading = true;
          this.invokeGetMessages();
        };
        this.markMessageRead = async ({
          id,
          readAt,
          meta: {
            state,
            params,
          },
        }) => {
          if (!readAt) {
            try {
              await markMessageRead(id);
            } catch (err) {
              toaster.error({
                type: 'error',
                title: err,
                showCloseButton: true,
                timeout: 10000,
              });
            }
          }
          $state.go(state, params, { reload: true });
        };
        if (permissionFilter(relaylib.permissions.permissionsConstants.SHOW_SIDE_NAV_PROFILE_MGMT)) {
          this.invokeGetMessages();
        }
      },
    ],
  });
