angular.module('relayHealth').controller('providerManagementCtrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  '$state',
  'loadingScreenFactory',
  'toaster',
  'storageManager',
  'SweetAlert',
  function providerManagementCtrl(
    $scope,
    $http,
    API_BASE_URL,
    $state,
    loadingScreenFactory,
    toaster,
    storageManager,
    SweetAlert,
  ) {
    loadingScreenFactory.hideLoadingScreen();

    // all const assignments
    const { storage } = storageManager;
    const currentState = $state.current.name;
    const filterQuery = {};

    // all scope assignments
    $scope.breadcrumbs = [
      {
        label: 'Provider Management',
      },
    ];
    $scope.tabs = {
      independent: {
        heading: 'Independent',
        type: 'independent',
        active: true,
        url: `${API_BASE_URL}driver-api/v1/providers`,
        pagination: {
          totalItems: 0,
          currentPage: 1,
          lastItem: 0,
          itemsPerPage: 20,
          data: [],
        },
        searchFormQuery: {},
      },
      agency: {
        heading: 'Agency',
        type: 'agency',
        active: false,
        url: `${API_BASE_URL}driver-api/v1/providers`,
        pagination: {
          totalItems: 0,
          currentPage: 1,
          lastItem: 0,
          itemsPerPage: 20,
          data: [],
        },
        searchFormQuery: {},
      },
    };

    $scope.statusList = [{
      name: 'Uninvited',
      value: false,
    }, {
      name: 'Invited',
      value: true,
    }];

    // all const funtions

    function showToaster(type, message, customMessage) {
      toaster.clear();
      let messageType = 'error';
      if (['info', 'error', 'success'].includes(type)) {
        messageType = type;
      }
      toaster.pop({
        type: messageType,
        title: message || customMessage,
        showCloseButton: true,
        timeout: 10000,
      });
    }

    function adjustPagination(tabKey) {
      const { pagination } = $scope.tabs[tabKey];
      pagination.lastItem = (pagination.currentPage - 1) * pagination.itemsPerPage + pagination.itemsPerPage;
      if (pagination.lastItem > pagination.totalItems) {
        pagination.lastItem = pagination.totalItems;
      }
    }

    const createServiceAreaString = (providers) => {
      if (!providers.length) {
        return;
      }
      for (let providerIndex = 0; providerIndex < providers.length; providerIndex += 1) {
        if (providers[providerIndex].addresses && providers[providerIndex].addresses.length) {
          const zipCodeArray = [];
          for (let addressIndex = 0; addressIndex < providers[providerIndex].addresses.length; addressIndex += 1) {
            zipCodeArray.push(providers[providerIndex].addresses[addressIndex].postalCode);
          }
          Object.assign(providers[providerIndex], {
            serviceAreas: zipCodeArray.join(', '),
            lastSeenAt: providers[providerIndex].lastSeenAt ? moment(providers[providerIndex].lastSeenAt).format('DD-MM-YYYY HH:mm') : null,
          });
        }
      }
    };

    function getProviders(matchData, tabKey) {
      if (!$scope.tabs[tabKey].url) {
        return;
      }
      const getProvidersCall = $http({
        url: $scope.tabs[tabKey].url,
        method: 'GET',
        params: matchData,
      });
      loadingScreenFactory.showLoadingScreen();
      getProvidersCall.then((result) => {
        loadingScreenFactory.hideLoadingScreen();
        if (result.data.success) {
          createServiceAreaString(result.data.data.items);
          $scope.tabs[tabKey].pagination.data = result.data.data.items;
          $scope.tabs[tabKey].pagination.totalItems = result.data.data.totalCount;
        } else {
          $scope.tabs[tabKey].pagination.data = [];
          $scope.tabs[tabKey].pagination.totalItems = 0;
          showToaster('error', result.data.message, '');
        }
        adjustPagination(tabKey);
      }, (error) => {
        loadingScreenFactory.hideLoadingScreen();
        $scope.tabs[tabKey].pagination.data = [];
        $scope.tabs[tabKey].pagination.totalItems = 0;
        adjustPagination(tabKey);
        showToaster('error', error.data.message, '');
      });
    }

    const prepareFilterRequestData = (tabKey) => {
      const matchData = {
        name: filterQuery[tabKey].name || undefined,
        type: $scope.tabs[tabKey].type,
        offset: (($scope.tabs[tabKey].pagination.currentPage - 1) * $scope.tabs[tabKey].pagination.itemsPerPage),
        limit: $scope.tabs[tabKey].pagination.itemsPerPage,
      };

      if (filterQuery[tabKey].status !== undefined) {
        matchData.invited = !!filterQuery[tabKey].status;
      }

      if (filterQuery[tabKey].email) {
        matchData.email = filterQuery[tabKey].email.trim();
      }
      if (filterQuery[tabKey].phone) {
        matchData.phone = filterQuery[tabKey].phone.replace(/-/g, '').replace(' ', '').replace(/_/g, '').replace('(', '')
          .replace(')', '');
      }
      if (filterQuery[tabKey].cellPhone) {
        matchData.cellPhone = filterQuery[tabKey].cellPhone.replace(/-/g, '').replace(' ', '').replace(/_/g, '').replace('(', '')
          .replace(')', '');
      }
      return matchData;
    };

    function filterProviders(tabKey, isSearch = false) {
      const matchData = prepareFilterRequestData(tabKey);
      const storedData = storage.getDataByKey('keepFilterData', currentState);
      if (isSearch) {
        storage.setDataByKey('keepFilterData', currentState, { key: tabKey, data: matchData });
      } else if (storedData && storedData.key === tabKey) {
        matchData.name = storedData.data.name || undefined;
        matchData.email = storedData.data.email || undefined;
        matchData.phone = storedData.data.phone || undefined;
        matchData.invited = storedData.data.invited || undefined;
        matchData.cellPhone = storedData.data.cellPhone || undefined;
      } else {
        //
      }
      getProviders(matchData, tabKey);
    }

    const writeSearchFilter = (tabKey) => {
      const prevSearchValues = storage.getDataByKey('keepFilterData', currentState);
      if (!prevSearchValues) {
        return;
      }
      const prevForm = prevSearchValues.data;
      $scope.tabs[tabKey].searchFormQuery.name = prevForm.name;
      $scope.tabs[tabKey].searchFormQuery.email = prevForm.email;
      $scope.tabs[tabKey].searchFormQuery.phone = prevForm.phone;
      $scope.tabs[tabKey].searchFormQuery.address = prevForm.address;
      $scope.tabs[tabKey].searchFormQuery.status = prevForm.status;
      $scope.tabs[tabKey].searchFormQuery.cellPhone = prevForm.cellPhone;
    };

    const prepareDataForSendInvite = providerDetails => ({
      vendorCode: providerDetails.vendorCode,
      name: providerDetails.name,
      phone: providerDetails.phone,
      cellPhone: providerDetails.cellPhone,
      email: providerDetails.email,
      status: providerDetails.status ? { invited: providerDetails.status.invited } : { invited: false },
      imageUrl: providerDetails.imageUrl,
      isActive: providerDetails.isActive,
      pushEnabled: providerDetails.pushEnabled,
      lastSeenAt: providerDetails.lastSeenAt,
    });

    const sendInvite = async (providerDetails) => {
      try {
        if (!providerDetails.email) {
          showToaster('error', 'Provider email is missing', '');
          return;
        }
        if (!providerDetails.cellPhone) {
          showToaster('error', 'Provider cell phone is required for sending invite', '');
          return;
        }
        const requestBody = prepareDataForSendInvite(providerDetails);
        const sendInviteResponse = await $http.post(`${API_BASE_URL}providers/invite`, requestBody);
        if (sendInviteResponse.data.success) {
          showToaster('info', sendInviteResponse.data.message, '');
          Object.assign(providerDetails.status, {
            invited: true,
          });
        } else {
          showToaster('error', sendInviteResponse.data.message, '');
        }
      } catch (error) {
        showToaster('error', error.data.message, '');
      }
    };


    const init = () => {
      Object.keys($scope.tabs).forEach((tabKey) => {
        filterQuery[tabKey] = {};
        filterProviders(tabKey);
      });
    };

    // all scope functions

    $scope.pageChanged = function pageChanged(tabKey) {
      filterProviders(tabKey);
    };

    $scope.searchButtonClicked = function searchButtonClicked(tabKey) {
      $scope.tabs[tabKey].pagination.currentPage = 1;
      filterQuery[tabKey] = angular.copy($scope.tabs[tabKey].searchFormQuery);
      filterProviders(tabKey, true);
    };

    $scope.getStatus = status => `${
      status.invited
        ? 'Invited'
        : 'Uninvited'
    } (${
      status.active
        ? 'Active'
        : 'Inactive'
    })`;

    $scope.clearFilters = function clearFilters(tabKey) {
      storage.removeDataByKey('keepFilterData', currentState);
      filterQuery[tabKey] = {};
      $scope.tabs[tabKey].searchFormQuery = {};
      $scope.tabs[tabKey].pagination.currentPage = 1;
      filterProviders(tabKey);
    };

    $scope.enterOnFormSearch = function enterOnFormSearch(event, tabKey) {
      if (event.keyCode === 13) {
        if ($scope.tabs[tabKey].searchFormQuery.phone && $scope.tabs[tabKey].searchFormQuery.phone.charAt(0) === '_') {
          delete $scope.tabs[tabKey].searchFormQuery.phone;
        }
        if ($scope.tabs[tabKey].searchFormQuery.cellPhone && $scope.tabs[tabKey].searchFormQuery.cellPhone.charAt(0) === '_') {
          delete $scope.tabs[tabKey].searchFormQuery.cellPhone;
        }
        $scope.searchButtonClicked(tabKey);
      }
    };

    $scope.sendInviteClicked = (providerDetails) => {
      if (
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
          .test(providerDetails.email)
      ) {
        sendInvite(providerDetails);
        return;
      }
      showToaster('error', 'The email address is either missing or invalid. Please verify', '');
    };

    $scope.resendInviteClicked = (providerDetails) => {
      SweetAlert.swal({
        title: '',
        text: `To resend the invite, please validate that ${providerDetails.email} is valid.`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        closeOnConfirm: true,
        confirmButtonColor: '#DD6B55',
        cancelButtonText: 'No',
      },
      (isConfirm) => {
        if (isConfirm) {
          sendInvite(providerDetails);
        }
      });
    };

    async function activateOrDeactivateProvider(isActive, { vendorCode, status }) {
      try {
        const { data: { success, message } } = await $http.patch(`${API_BASE_URL}providers/toggleActivation`, {
          vendorCode,
          active: isActive,
        });
        if (success) {
          showToaster('info', message, '');
          Object.assign(status, { active: isActive });
        } else {
          showToaster('error', message, '');
        }
      } catch ({ data: { message } }) {
        showToaster('error', message, '');
      }
    }

    $scope.toggleProviderActivationClicked = (providerDetails) => {
      const toggledActivationStatus = (providerDetails.status ? !providerDetails.status.active : true);
      const buttonObj = {
        color: toggledActivationStatus ? '#55dd6b' : '#DD6B55',
        text: toggledActivationStatus ? 'Activate' : 'Deactivate',
      };
      SweetAlert.swal({
        title: '',
        text: `Are you sure you want to ${buttonObj.text} this user?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: buttonObj.text,
        closeOnConfirm: true,
        confirmButtonColor: buttonObj.color,
        cancelButtonText: 'Cancel',
      },
      (isConfirm) => {
        if (isConfirm) {
          activateOrDeactivateProvider(toggledActivationStatus, providerDetails);
        }
      });
    };


    // execution

    writeSearchFilter();
    init();
  }]);
