angular.module('relayHealth').controller('accountInfoCtrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  '$state',
  '$timeout',
  'signupStateService',
  'signupAuthCheckService',
  'SweetAlert',
  'signupInitialStateService',
  'signupNavigationValidationService',
  '$stateParams',
  'updateSignupStep',
  function accountInfoCtrl(
    $scope,
    $http,
    API_BASE_URL,
    $state,
    $timeout,
    signupStateService,
    signupAuthCheckService,
    SweetAlert,
    signupInitialStateService,
    signupNavigationValidationService,
    $stateParams,
    updateSignupStep,
  ) {
    $scope.states = '';
    $scope.stageNumber = 4;
    $scope.accountInfoResponseError = '';
    $scope.selectedState = '';
    $scope.error = {
      address1: '',
      city: '',
      state: '',
      zip: '',
    };

    function init() {
      $scope.state = signupInitialStateService.initialState();
      // check if navigation from previous page is forward type or backward type
      // if forward type then run validation check from previous page to pages
      // between the previous and current pages.
      let hasErrors = false;
      if ($scope.state.currentPage < 4) {
        signupNavigationValidationService.termsOfServiceValidation((errors) => {
          if (Object.keys(errors).length > 0) {
            hasErrors = true;
            $state.go('main.rider_signup_terms_of_service', { errors });
          }
        });
      }

      if ($stateParams.errors) {
        const { errors } = $stateParams;
        Object.keys(errors).forEach((err) => {
          $scope.error[err] = errors[err];
        });

        $timeout(() => {
          $scope.error = {
            address1: '',
            city: '',
            state: '',
            zip: '',
          };
        }, 10000);
      }

      if (!hasErrors) {
        if ($scope.stageNumber > $scope.state.latestStage) {
          $scope.state.latestStage = 4;
          signupStateService.setState('signupState', $scope.state);
        }
        $scope.state.currentPage = 4;
        signupStateService.setState('signupState', $scope.state);
        signupAuthCheckService.accountInfoAllowed();

        $http
          .get(`${API_BASE_URL}states`)
          .then(({ data }) => {
            if (data.success) {
              $scope.states = data.states;
              let stateName = '';
              if ($scope.state && $scope.state.accountInfo && parseInt($scope.state.accountInfo.state, 10)) {
                stateName = parseInt($scope.state.accountInfo.state, 10);
              } else {
                stateName = $scope.state.accountInfo.state;
              }
              if (typeof stateName === 'number') {
                for (let i = 0; i < data.states.length; i += 1) {
                  if (data.states[i].id === stateName) {
                    $scope.state.accountInfo.state = data.states[i].id;
                    $scope.selectedState = data.states[i];
                    break;
                  }
                }
              }

              if (typeof stateName === 'string') {
                for (let i = 0; i < data.states.length; i += 1) {
                  if (data.states[i].state_name === stateName) {
                    $scope.state.accountInfo.state = data.states[i].id;
                    $scope.selectedState = data.states[i];
                    signupStateService.setState('signupState', $scope.state);
                    break;
                  }
                }
              }
            }
          });
        updateSignupStep(3);
      }
    }

    init();

    $scope.previousPage = () => {
      $state.go('main.rider_signup_terms_of_service');
    };

    $scope.nextPage = () => {
      const state = signupStateService.getState('signupState');
      const body = angular.copy($scope.state.accountInfo);
      body.signupType = $scope.state.signupType;
      body.state_id = body.state;
      body.userId = $scope.state.userId;
      body.step = 4;
      body.state = body.state.state_name;
      const headers = {
        usertoken: state.usertoken,
        username: state.email,
        'Content-Type': 'application/json',
      };
      $http
        .put(`${API_BASE_URL}rider/update`, [body], headers)
        .then(({ data }) => {
          if (data.success && !state.addUsersAlertShown) {
            SweetAlert.swal(
              {
                title: 'Do you want to set up a family plan now?',
                text: 'Add addtional users to your account now, or set up users in Relay after you have set up your account.',
                type: 'success',
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes',
                closeOnCancel: true,
                cancelButtonText: 'No',
                showCancelButton: true,
              },
              (result) => {
                state.addUsersAlertShown = true;
                signupStateService.setState('signupState', state);
                if (!result) {
                  $state.go('main.rider_signup_payment');
                } else {
                  $state.go('main.rider_signup_add_users');
                }
              },
            );
          } else if (!data.success) {
            // show the error
            const errors = data.errorMessages;
            if (typeof errors === 'string') {
              $scope.accountInfoResponseError = errors;
            } else {
              Object.keys(errors).forEach((err) => {
                $scope.error[err] = errors[err];
              });
            }

            $timeout(() => {
              $scope.accountInfoResponseError = '';
              $scope.error = {
                address1: '',
                city: '',
                state: '',
                zip: '',
              };
            }, 5000);
          } else {
            $state.go('main.rider_signup_add_users');
          }
        })
        .catch(({ data }) => {
          // error handling code
          // error should be displayed below the respective field
          const errors = data.errorMessages;
          if (typeof errors === 'string') {
            $scope.accountInfoResponseError = errors;
          } else {
            Object.keys(errors).forEach((err) => {
              $scope.error[err] = errors[err];
            });
          }

          $timeout(() => {
            $scope.accountInfoResponseError = '';
            $scope.error = {
              address1: '',
              city: '',
              state: '',
              zip: '',
            };
          }, 5000);
        });
    };

    $scope.changeObj = (obj, key, objValue) => {
      let value = objValue;
      if (!obj || !key) {
        return false;
      }
      const state = signupStateService.getState('signupState');
      if (key === 'state') {
        for (let i = 0; i < $scope.states.length; i += 1) {
          if ($scope.states[i].id === value.id) {
            $scope.selectedState = $scope.states[i];
            break;
          }
        }
        value = value.id;
      }
      if (state && state[obj]) {
        state[obj][key] = value;
        $scope.state[obj][key] = value;
        signupStateService.setState('signupState', state);
      }
      return true;
    };
  },
]);
