const reasonType = [
  {
    name: "Base Plus Pricing",
    value: "base_plus_pricing"
  },
  {
    name: "SRA – After Hours, Weekends, or Holidays",
    value: "sra_after_hours_weekends_or_holidays"
  },
  {
    name: "PTA - Multi-load",
    value: "pta_multi_load"
  },
  {
    name: "PTA - Out of county",
    value: "pta_out_of_county"
  },
  {
    name: "SRA – Out of Area",
    value: "sra_out_of_area"
  },
  {
    name: "SRA – Out of network",
    value: "sra_out_of_network"
  },
  {
    name: "SRA – Additional Fees",
    value: "sra_additional_fees",
    subReason: [
      {
        name: "Bariatric",
        value: "bariatric"
      },
      {
        name: "Tolls",
        value: "tolls"
      },
      {
        name: "Attendant fee",
        value: "attendant_fee"
      },
      {
        name: "Additional Man Assist",
        value: "additional_man_assist"
      },
      {
        name: "Other",
        value: "other"
      }
    ]
  }
];
Object.freeze(reasonType);
export default reasonType;
