// CSS

import 'bootstrap/dist/css/bootstrap.min.css';

import 'metismenu/dist/metisMenu.css';

import 'bootstrap-daterangepicker/daterangepicker.css';

import 'angular-datepicker/dist/angular-datepicker.css';

import 'daterangepicker/daterangepicker.css';

import 'ui-select/dist/select.css';

import 'font-awesome/css/font-awesome.css';

import 'ng-dialog/css/ngDialog.min.css';

import 'ng-dialog/css/ngDialog-theme-default.min.css';

import 'angularjs-toaster/toaster.min.css';

import 'bootstrap-duallistbox/src/bootstrap-duallistbox.css';

import 'intl-tel-input/build/css/intlTelInput.css';

import 'jsonformatter/dist/json-formatter.min.css';

import 'segoe-fonts';

import 'sweetalert/lib/sweet-alert.css';

import 'ladda/dist/ladda-themeless.min.css';

import './animate.css';

import './scheduler.css';

import '../unorganized-js/icheck/custom.css';

import '../unorganized-js/angular-rating/rating.css';

import './modal-gmap_autocomplete.css';

import './ngTagsInput/ng-tags-input-custom.min.css';

import './style.scss';

import 'jasny-bootstrap/dist/css/jasny-bootstrap.min.css';

import './quill.css';

import '../../../node_modules/text-security/text-security.css';