angular.module('relayHealth')
  .controller('masOrderInfoCtrl', ['$scope',
    'setMapPoints',
    '$timeout',
    function masOrderInfoCtrl(
      $scope,
      setMapPoints,
      $timeout,
    ) {
      // Note: This controller inherit scope from rideOrderCtrl..
      // open modal functions:
      let timeout;

      $timeout(() => {
        $scope.zipPhone = $scope.originalRideDetails.zipPhone;
        $('#org_contact_number').trigger('phone-number-changed', $scope.zipPhone);
      });


      let map;
      const setMap = function setMap() {
        if (map) {
          return;
        }
        map = new google.maps.Map(document.getElementById('orderInfoMap'), {
          zoom: 12,
        });
        const markers = [];
        const mapPoints = {
          source: {
            latitude: $scope.rideDetails.tripInfo.source_lat_lng[0],
            longitude: $scope.rideDetails.tripInfo.source_lat_lng[1],
          },
          destination: {
            latitude: $scope.rideDetails.tripInfo.dest_lat_lng[0],
            longitude: $scope.rideDetails.tripInfo.dest_lat_lng[1],
          },
        };
        setMapPoints(map, markers, mapPoints);
      };

      $scope.$on('orderInfoSelected', () => {
        setMap();
      });

      $scope.$on('rideOrderFetched', () => {
        if (!$scope.providerTabs.isOrderUpdate) {
          const top = $('#current-app').scrollTop();
          if (top < 5) {
            timeout = $timeout(() => {
              $('#current-app').animate({ scrollTop: $('#availableVendorsSection').offset().top }, 500);
            }, 500);
          }
        }
        $timeout(() => {
          $scope.zipPhone = $scope.originalRideDetails.zipPhone;
        });
      });

      $scope.$on('$destroy', () => {
        if (typeof timeout === 'function') {
          timeout();
        }
      });
    }]);
