const rideStatusKeyMappings = {
    "Offered": 'Offer Pending',
    "Potential Unavailability": 'Potential Unavailability',
    "Failed Fuse": 'Failed Fuse',
    "Series Confirmed": 'Series Confirmed',
    "Confirmed": 'Confirmed',
    "Order Updated": 'Order Update',
    "NDA": 'No Driver Assigned',
    "NEPF": 'No Eligible Providers',
    "UATP": 'UATP',
    "Offer Created": 'Send Offer',
    "24 Hour Confirmation Missing": '24 Hour Confirmation Missing',
    "Pending - Re-Offer": 'Send Offer',
    "Confirm Member Pickup": 'Confirm Member Pick-up',
    "Confirm Member Dropoff": 'Confirm Member Drop-Off',
    "Offered NDA": 'No Driver Assigned',
    "Pending Disposition": "Pending Disposition",
};
Object.freeze(rideStatusKeyMappings);
export default rideStatusKeyMappings;