angular.module('relayHealth').controller('forgotPasswordCtrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  '$timeout',
  function forgotPasswordCtrl($scope, $http, API_BASE_URL, $timeout) {
    function initMessages(isShowMessage, isOperationSuccess, message) {
      $scope.showMessage = isShowMessage;
      $scope.operationSuccess = isOperationSuccess;
      $scope.message = message;
    }

    $scope.sendMail = () => {
      $scope.loading = true;
      const http = $http.post(`${API_BASE_URL}user/forgotPassword`, { username: $scope.username });
      http
        .then(({ data }) => {
          if (data.success) {
            initMessages(true, true, data.message);
            $timeout(() => {
              initMessages(false, true, '');
            }, 3000);
          }
        })
        .catch((error) => {
          initMessages(true, false, error.data.message);
          $timeout(() => {
            initMessages(false, true, '');
          }, 3000);
        })
        .finally(() => {
          $scope.loading = false;
        });
    };

    initMessages(false, false, '');
  },
]);
