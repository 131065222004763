angular.module('relayHealth').service('ShowBroadcastService', [
  'toaster',
  '$timeout',
  '$http',
  'API_BASE_URL',
  function showBroadcastService(toaster, $timeout, $http, API_BASE_URL) {
    const broadcastLib = relaylib.broadcasts;
    this.toastInstance = {};
    this.init = function init() {
      const params = {
        status: broadcastLib.broadcastStatuses.ACTIVE,
        target: broadcastLib.broadcastTargets.WEB,
        offset: 0,
        limit: 50,
      };
      return $http
        .get(`${API_BASE_URL}broadcasts`, {
          params,
        })
        .then((resp) => {
          if (resp.data && resp.data.totalCount > 0) {
            _.each(resp.data.data, (d) => {
              if (d.category !== broadcastLib.broadcastCategories.RELEASE_NOTES) {
                this.showBroadcast(d);
              }
            });
          }
          return resp.data;
        });
    };
    this.setBroadcast = function setBroadcast(broadcastData) {
      if (!broadcastData || !broadcastData.payload) {
        return;
      }

      if (
        broadcastData.payload.type === broadcastLib.broadcastTypes.NON_OBTRUSIVE
        && broadcastData.payload.targets
        && broadcastData.payload.targets.indexOf(broadcastLib.broadcastTargets.WEB) >= 0
      ) {
        if (broadcastData.key === broadcastLib.broadcastChannelConstants.BROADCAST_ACTIVATED) {
          this.showBroadcast(broadcastData.payload);
        } else {
          this.hideBroadcast(broadcastData.payload);
        }
      }
    };
    this.showBroadcast = function showBroadcast(broadcast) {
      if (this.toastInstance[broadcast.id]) {
        this.hideBroadcast(broadcast);
      }
      $timeout(() => {
        this.toastInstance[broadcast.id] = toaster.pop({
          type: broadcast.category,
          title: broadcast.title,
          body: broadcast.content,
          timeout: broadcast.duration * 60 * 1000,
          showCloseButton: true,
          toasterId: 9999,
          tapToDismiss: false,
        });
      });
    };
    this.hideBroadcast = function hideBroadcast(broadcast) {
      if (this.toastInstance[broadcast.id]) {
        const toastInstance = this.toastInstance[broadcast.id];
        toaster.clear(toastInstance);
        this.toastInstance[broadcast.id] = null;
        delete this.toastInstance[broadcast.id];
      }
    };
  },
]);
