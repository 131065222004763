angular.module('relayHealth').controller('enhancedBillingTOSCtrl',
  [
    '$scope',
    '$http',
    'API_BASE_URL',
    '$uibModalInstance',
    '$timeout',
    function enhancedBillingTOSCtrl(
      $scope,
      $http,
      API_BASE_URL,
      $uibModalInstance,
      $timeout,
    ) {
      $scope.error = '';
      $scope.accepted = () => {
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        const body = { userId: userDetails.user_id };
        const headers = {
          username: userDetails.username,
          usertoken: userDetails.usertoken,
          'Content-Type': 'application/json',
        };
        $http.patch(`${API_BASE_URL}user/tosaccepted`, body, headers)
          .then((res) => {
            const { data } = res;
            if (data && data.success) {
              userDetails.first_time_visit = false;
              localStorage.setItem('userDetails', JSON.stringify(userDetails));
              $uibModalInstance.close();
            } else {
              $scope.error = 'Could not update Terms and Conditions';
              $timeout(() => {
                $scope.error = '';
              }, 5000);
            }
          })
          .catch(() => {
            $scope.error = 'Could not update Terms and Conditions';
            $timeout(() => {
              $scope.error = '';
            }, 5000);
          });
      };
    },
  ]);
