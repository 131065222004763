angular.module('relayHealth').controller('paymentCtrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  '$state',
  '$timeout',
  'signupStateService',
  'signupAuthCheckService',
  'signupInitialStateService',
  '$stateParams',
  'signupNavigationValidationService',
  'updateSignupStep',
  function paymentCtrl(
    $scope,
    $http,
    API_BASE_URL,
    $state,
    $timeout,
    signupStateService,
    signupAuthCheckService,
    signupInitialStateService,
    $stateParams,
    signupNavigationValidationService,
    updateSignupStep,
  ) {
    $scope.states = '';
    $scope.state = {};
    $scope.billingAddress = {
      checked: false,
    };
    $scope.selectedState = {};
    $scope.paymentResponseError = '';
    $scope.paymentResponseSuccess = '';
    $scope.updateStripeCard = false;
    $scope.showClearCard = true;
    $scope.stageNumber = 6;

    $scope.error = {
      cardHolderName: '',
      address1: '',
      city: '',
      state: '',
      zip: '',
    };

    function init() {
      $scope.state = signupInitialStateService.initialState();
      const currentPage = parseInt($scope.state.currentPage, 10);
      if ($scope.state.created_by_user && $scope.state.signupType === 'business') {
        $state.go('main.business_signup_add_organization');
      }
      let hasErrors = false;
      if (currentPage < 6) {
        if (currentPage < 4) {
          signupNavigationValidationService.termsOfServiceValidation((errors) => {
            if (Object.keys(errors).length > 0) {
              hasErrors = true;
              $state.go($scope.state.signupType === 'business' ? 'main.business_signup_terms_of_service' : 'main.rider_signup_terms_of_service', {
                errors,
              });
            }
          });
        }

        if (currentPage < 5) {
          if ($scope.state.signupType === 'business') {
            signupNavigationValidationService.organizationValidation((errors) => {
              if (Object.keys(errors).length > 0) {
                hasErrors = true;
                $state.go('main.business_signup_add_organization', { errors });
              }
            });
          } else {
            signupNavigationValidationService.accountInfoValidation((errors) => {
              if (Object.keys(errors).length > 0) {
                hasErrors = true;
                $state.go('main.rider_signup_account_information', { errors });
              }
            });
          }
        }

        if (currentPage < 6) {
          signupNavigationValidationService.addUsers((errors) => {
            if (Object.keys(errors).length > 0) {
              hasErrors = true;
              $state.go($scope.state.signupType === 'business' ? 'main.business_signup_add_users' : 'main.rider_signup_add_users', { errors });
            }
          });
        }
      }

      if (!hasErrors) {
        signupAuthCheckService.addPaymentAllowed();
        let stateMatch = false;
        if ($scope.state.signupType === 'business') {
          const { businessInfo, billingAddress } = $scope.state;
          if (typeof businessInfo.state === 'number') {
            stateMatch = !!(billingAddress.state === businessInfo.state || (billingAddress.state && billingAddress.state.id === businessInfo.state));
          }
          if (typeof businessInfo.state === 'object') {
            stateMatch = billingAddress.state.id === businessInfo.state.id;
          }
          if (
            businessInfo.address1 === billingAddress.address1
            && businessInfo.city === billingAddress.city
            && stateMatch
            && businessInfo.zip === billingAddress.zip
          ) {
            $scope.state.billingAddressSameAsAddress = true;
          } else {
            $scope.state.billingAddressSameAsAddress = false;
          }
        } else {
          const { accountInfo, billingAddress } = $scope.state;

          if (typeof accountInfo.state === 'number') {
            stateMatch = !!((billingAddress.state && billingAddress.state.id === accountInfo.state) || billingAddress.state === accountInfo.state);
          }
          if (typeof accountInfo.state === 'object') {
            stateMatch = billingAddress.state.id === accountInfo.state.id;
          }

          if (
            accountInfo.address1 === billingAddress.address1
            && accountInfo.city === billingAddress.city
            && stateMatch
            && accountInfo.zip === billingAddress.zip
          ) {
            $scope.state.billingAddressSameAsAddress = true;
          } else {
            $scope.state.billingAddressSameAsAddress = false;
          }
        }
        if ($scope.stageNumber > $scope.state.latestStage) {
          $scope.state.latestStage = 6;
          signupStateService.setState('signupState', $scope.state);
        }
        $scope.state.currentPage = 6;
        signupStateService.setState('signupState', $scope.state);
        $http
          .get(`${API_BASE_URL}states`)
          .then(({ data }) => {
            if (data.success) {
              // organization details updated
              $scope.states = data.states;
              const userState = $scope.state.accountInfo.state;
              const stateName = $scope.state.billingAddress.state ? parseInt($scope.state.billingAddress.state, 10) : '';

              if (typeof stateName === 'number') {
                for (let i = 0; i < data.states.length; i += 1) {
                  if (data.states[i].id === stateName) {
                    $scope.state.billingAddress.state = data.states[i].id;
                    $scope.selectedState = data.states[i];
                    signupStateService.setState('signupState', $scope.state);
                    break;
                  }
                }
              }

              if ($scope.state.signupType === 'rider' && typeof userState === 'string') {
                for (let i = 0; i < data.states.length; i += 1) {
                  if (data.states[i].state_name === userState) {
                    $scope.state.accountInfo.state = data.states[i].id;
                    break;
                  }
                }
              }
            }
          })
          .catch(() => {
            // could not organization details
          });
        $http
          .get(`${API_BASE_URL}userPaymentProfile`)
          .then(({ data }) => {
            if (data.success) {
              if (data.data) {
                const stripeData = data.data.stripe_profile;
                const cardNumber = `************${stripeData.last4}`;
                $scope.state.cardHolderName = stripeData.name;
                $scope.state.billingAddress.address1 = stripeData.address_line1;
                const stateName = stripeData.address_state;
                for (let i = 0; i < $scope.states.length; i += 1) {
                  if ($scope.states[i].state_name === stateName) {
                    $scope.state.billingAddress.state = $scope.states[i].id;
                    $scope.selectedState = $scope.states[i];
                    break;
                  }
                }
                $scope.state.billingAddress.zip = stripeData.address_zip;
                $scope.state.billingAddress.city = stripeData.address_city;
                $scope.state.maskedStripeCardNumber = cardNumber;
                $scope.updateStripeCard = false;
                signupStateService.setState('signupState', $scope.state);
              } else {
                $scope.state.stripeCardAdded = false;
              }
            }
          })
          .catch(() => {
            // could not organization details
          });

        updateSignupStep(5);
      }
    }

    init();

    $scope.previousPage = () => {
      if ($scope.state.signupType === 'rider') {
        $state.go('main.rider_signup_add_users');
      } else if ($scope.state.signupType === 'business') {
        $state.go('main.business_signup_add_users');
      } else {
        $state.go('main.dashboard.superAdminDashboard');
      }
    };

    $scope.$on('stripeCardError', () => {
      $scope.paymentResponseError = 'Could not add card details';
      $timeout(() => {
        $scope.paymentResponseError = '';
      }, 5000);
    });

    $scope.billingAddressChecked = () => {
      // if billingAddress is checked fill in the details from the state
      let businessAddress = '';
      if ($scope.state.billingAddressSameAsAddress) {
        if ($scope.state.signupType === 'rider') {
          businessAddress = angular.copy($scope.state.accountInfo);
        } else {
          businessAddress = angular.copy($scope.state.businessInfo);
        }
        const { billingAddress } = $scope.state;
        billingAddress.address1 = businessAddress.address1;
        billingAddress.city = businessAddress.city;
        billingAddress.state = businessAddress.state;
        const stateName = businessAddress.state;
        if (typeof stateName === 'number') {
          for (let i = 0; i < $scope.states.length; i += 1) {
            if ($scope.states[i].id === stateName) {
              $scope.state.billingAddress.state = $scope.states[i].id;
              $scope.selectedState = $scope.states[i];
              break;
            }
          }
        }
        billingAddress.zip = businessAddress.zip;
        $scope.state.billingAddress = billingAddress;
        signupStateService.setState('signupState', $scope.state);
      } else {
        // clear the address text boxes
        $scope.state.billingAddress = {};
        $scope.selectedState = {};
        $scope.state.billingAddressSameAsAddress = false;
        $scope.$emit('selectClear');
        $scope.paymentForm.address1.$setPristine();
        $scope.paymentForm.city.$setPristine();
        $scope.paymentForm.state.$setPristine();
        $scope.paymentForm.zip.$setPristine();
        signupStateService.setState('signupState', $scope.state);
      }
    };

    $scope.$on('stripeCardAdded', (event, data) => {
      const userDetails = signupStateService.getState('userDetails');
      $scope.paymentResponseSuccess = 'Your card details have been added';
      const state = signupStateService.getState('signupState');
      $scope.state.stripeCardAdded = true;
      state.stripeCardAdded = true;
      state.maskedStripeCardNumber = data.cardNumber;
      $scope.state.maskedStripeCardNumber = data.cardNumber;

      $scope.state.expMonth = data.expMonth;
      $scope.state.expYear = data.expYear;
      state.expMonth = data.expMonth;
      state.expYear = data.expYear;

      userDetails.organisation.stripe_customer_id = true;
      signupStateService.setState('userDetails', userDetails);
      signupStateService.setState('signupState', state);
      $scope.showClearCard = false;
      // $scope.showSummaryButton = false;

      $timeout(() => {
        $scope.paymentResponseSuccess = '';
        if ($scope.state.signupType === 'rider') {
          return $state.go('main.rider_signup_summary');
        }
        if ($scope.state.signupType === 'business') {
          return $state.go('main.business_signup_summary');
        }
        return $state.go('main.dashboard.superAdminDashboard');
      }, 3000);
    });

    $scope.clearCard = () => {
      $scope.state.monthlyInvoice = false;
      $scope.state.cardHolderName = '';
      $scope.state.billingAddress = {
        streetAddress: '',
        city: '',
        state: '',
        zip: '',
      };
      $scope.billingAddress.checked = false;
      signupStateService.setState('signupState', $scope.state);
      $scope.paymentForm.$setPristine();
    };

    $scope.skipPayment = () => {
      const state = signupStateService.getState('signupState');
      const userDetails = signupStateService.getState('userDetails');
      state.monthlyInvoice = $scope.state.monthlyInvoice;
      signupStateService.setState('signupState', state);
      const data = { org_id: $scope.state.businessInfo.orgId, user_id: $scope.state.userId };
      const headers = {
        username: userDetails.username,
        usertoken: userDetails.user_token,
        'Content-Type': 'application/json',
      };
      $http
        .put(`${API_BASE_URL}paymentProfiles/skipPayment`, data, { headers })
        .then(() => $state.go('main.business_signup_summary'))
        .catch(() => {
          $scope.paymentResponseError = 'Could not skip payment';
        });
    };

    $scope.goToSummary = () => {
      if ($scope.state.signupType === 'rider') {
        return $state.go('main.rider_signup_summary');
      }
      if ($scope.state.signupType === 'business') {
        return $state.go('main.business_signup_summary');
      }
      return $state.go('main.dashboard.superAdminDashboard');
    };

    $scope.checkboxChanged = () => {
      const state = signupStateService.getState('signupState');
      state.monthlyInvoice = $scope.state.monthlyInvoice;
      signupStateService.setState('signupState', state);
    };

    $scope.cardHolderNameUpdated = () => {
      signupStateService.setState('signupState', $scope.state);
    };

    $scope.updateBillingAddress = () => {
      signupStateService.setState('signupState', $scope.state);
    };

    $scope.addCard = () => {
      // write conditions to add card, update card or to redirect user to summary page
      const { state } = $scope;
      $scope.error = {};
      let hasErrors = false;
      if (!state.cardHolderName) {
        $scope.error.cardHolderName = 'Name is required';
      }

      if (state.cardHolderName && !state.cardHolderName.match(/^[a-zA-Z'. -]*$/)) {
        $scope.error.cardHolderName = 'Numeric and special characters are not allowed';
      }

      if (Object.keys(state.billingAddress).length > 0) {
        const { billingAddress } = state;
        if (!billingAddress.address1) {
          $scope.error.address1 = 'Street Address is required';
        }
        if (!billingAddress.city) {
          $scope.error.city = 'City is required';
        }

        if (billingAddress.city && !billingAddress.city.match(/^[a-zA-Z'. -]*$/)) {
          $scope.error.city = 'Numeric and special characters are not allowed';
        }

        // if (Object.keys(billingAddress.state).length === 0) {
        //     $scope.error.state = 'State is required';
        // }

        if (!billingAddress.zip) {
          $scope.error.zip = 'Zip is required';
        }
      } else {
        $scope.error.address1 = 'Street Address is required';
        $scope.error.city = 'City is required';
        $scope.error.state = 'State is required';
        $scope.error.zip = 'Zip is required';
      }

      if (Object.keys($scope.error).length > 0) {
        hasErrors = true;
      }
      $timeout(() => {
        $scope.error = {
          cardHolderName: '',
          address1: '',
          city: '',
          state: '',
          zip: '',
        };
      }, 10000);
      if (!state.stripeCardAdded) {
        // write validation for the fields
        // add a card
        if (!hasErrors) {
          $scope.$emit('addCard');
        }
      } else if (state.stripeCardAdded && $scope.updateStripeCard) {
        // update a card
        if (!hasErrors) {
          $scope.$emit('updateCard');
        }
      } else {
        // redirect the user
        updateSignupStep(6);
        $state.go(state.signupType === 'business' ? 'main.business_signup_summary' : 'main.rider_signup_summary');
      }
    };

    $scope.changeObj = (obj, key, objValue) => {
      let value = objValue;
      if (!obj || !key) {
        return false;
      }

      if (key === 'state') {
        for (let i = 0; i < $scope.states.length; i += 1) {
          if ($scope.states[i].id === value.id) {
            $scope.selectedState = $scope.states[i];
            break;
          }
        }
        value = value.id;
      }
      const state = signupStateService.getState('signupState');
      if (state && state[obj]) {
        state[obj][key] = value;
        $scope.state[obj][key] = value;
        signupStateService.setState('signupState', state);
      }
      return true;
    };

    $scope.cancelUpdate = () => {
      $scope.updateStripeCard = false;
    };
  },
]);
