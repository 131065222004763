angular.module('relayHealth').controller('EditBroadcastTmplCtrl', [
  '$scope',
  '$uibModalInstance',
  'tmplInfo',
  '$http',
  'API_BASE_URL',
  function editBroadcastTmplCtrl($scope, $uibModalInstance, tmplInfo, $http, API_BASE_URL) {
    function convertFromDM(inDM) {
      const data = {
        id: inDM.id,
        category: inDM.category,
        title: inDM.title,
        content: inDM.message,
        duration: inDM.duration,
      };
      data.targets = [];
      if (inDM.isTargetWeb) {
        data.targets.push(relaylib.broadcasts.broadcastTargets.WEB);
      }
      if (inDM.isTargetAndroid) {
        data.targets.push(relaylib.broadcasts.broadcastTargets.ANDROID);
      }
      if (inDM.isTargetIos) {
        data.targets.push(relaylib.broadcasts.broadcastTargets.IOS);
      }
      return data;
    }

    function init() {
      if (tmplInfo) {
        $scope.dm = tmplInfo;
      } else {
        $scope.dm = {
          title: '',
          message: '',
          category: '',
          isTargetWeb: false,
          isTargetAndroid: false,
          isTargetIos: false,
          duration: 0,
        };
      }
      $scope.isSaving = false;
    }

    function reset() {
      $scope.dm = {
        title: '',
        message: '',
        category: '',
        isTargetWeb: false,
        isTargetAndroid: false,
        isTargetIos: false,
        duration: 0,
      };
      $scope.isSaving = false;
      if ($scope.addBroadcastFrm) {
        $scope.addBroadcastFrm.$setPristine();
      }
    }

    function isFormValid() {
      return $scope.addBroadcastFrm.$valid && ($scope.dm.isTargetWeb || !$scope.dm.isTargetAndroid || !$scope.dm.isTargetIos);
    }

    $scope.submitMessage = () => {
      if (isFormValid() && !tmplInfo) {
        // Create case
        $scope.isSaving = true;
        const data = convertFromDM($scope.dm);
        $http
          .post(`${API_BASE_URL}broadcast_templates`, data)
          .then((resp) => {
            $uibModalInstance.close(resp);
          })
          .finally(() => {
            $scope.isSaving = false;
          });
      } else if (isFormValid()) {
        // Edit case
        $scope.isSaving = true;
        const data = convertFromDM($scope.dm);
        $http
          .put(`${API_BASE_URL}broadcast_templates/${data.id}`, data)
          .then((resp) => {
            $uibModalInstance.close(resp);
          })
          .finally(() => {
            $scope.isSaving = false;
          });
      } else {
        // Form data invalid. Do nothing
      }
    };

    $scope.cancel = () => {
      reset();
      $uibModalInstance.dismiss('cancel');
    };

    init();
  },
]);
