import app from '../../app';

const { Blob, saveAs, FormData } = window;
app.controller('exportImportInvoiceCtrl', [
  '$scope',
  '$rootScope',
  '$http',
  'API_BASE_URL',
  'toaster',
  'Pubnub',
  '$stateParams',
  function exportImportInvoiceCtrl($scope,
    $rootScope,
    $http,
    API_BASE_URL,
    toaster,
    Pubnub,
    $stateParams) {
    $scope.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    $scope.showAddOrgForm = false;
    $scope.searchFormQuery = {};
    $scope.orgListQuery = {};
    $scope.addOrg = {};
    $scope.statusList = [{ status: 'Active', value: 0 }, { status: 'Inactive', value: 1 }];
    $scope.pagination = {
      totalItems: 0,
      currentPage: 1,
      lastItem: 0,
      itemsPerPage: 20,
    };
    $scope.tabsObject = {
      import: false,
      export: false,
    };
    function resetTabsObject() {
      $scope.tabsObject = {
        import: false,
        export: false,
      };
    }

    function init() {
      if ($stateParams.activeTab && !$scope.tabsObject[$stateParams.activeTab]) {
        resetTabsObject();
        $scope.tabsObject[$stateParams.activeTab] = true;
      }
    }
    init();

    function getLatestProcessedImportRecords() {
      const getLatestProcessedImportRecordsCall = $http.get(`${API_BASE_URL}import/getLatestProcessedImportRecords/MAS`);

      getLatestProcessedImportRecordsCall.then(
        (result) => {
          if (result.data.success) {
            $scope.importList = result.data.data;
          }
        },
        (err) => {
          toaster.pop({
            type: 'error',
            title: err,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    }

    $scope.importCSV = () => {
      const fm = new FormData();

      const element = document.getElementById('import_file');
      if (!element.files.length) {
        toaster.pop({
          type: 'error',
          title: 'Please select a file to upload',
          showCloseButton: true,
          timeout: 10000,
        });
        return;
      }

      if ((element.value).split('.').pop().toLowerCase() !== 'xlsx') {
        toaster.pop({
          type: 'error',
          title: 'Invalid Format. Only XLSX format is allowed.',
          showCloseButton: true,
          timeout: 10000,
        });
        return;
      }

      fm.append('file', element.files[0]);
      const importFuseInclusionCSV = $http({
        url: `${API_BASE_URL}uploadCsvFile/MAS`,
        method: 'POST',
        data: fm,
        headers: {
          'Content-Type': undefined,
        },
      });
      importFuseInclusionCSV
        .then(
          (result) => {
            if (result.data.success) {
              toaster.pop({
                type: 'info',
                title: 'File queued to upload',
                showCloseButton: true,
                timeout: 10000,
              });
              angular.element('#removeFileName').triggerHandler('click');
              getLatestProcessedImportRecords();
            } else {
              toaster.pop({
                type: 'error',
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
            }
          },
          (error) => {
            toaster.pop({
              type: 'error',
              title: error.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          },
        )
        .catch((error) => {
          toaster.pop({
            type: 'error',
            title: error.message,
            showCloseButton: true,
            timeout: 10000,
          });
        });
    };

    $scope.downloadXlsxFile = (filename) => {
      const downloadXlsxFileCall = $http({
        url: `${API_BASE_URL}export/downloadXlsxFile/${filename}`,
        method: 'GET',
        data: { filename },
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'arraybuffer',
      });

      downloadXlsxFileCall.then((result) => {
        const blob = new Blob([result.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        if (blob.size < 70) {
          toaster.pop({
            type: 'error',
            title: 'something wrong',
            showCloseButton: true,
            timeout: 10000,
          });
        } else {
          saveAs(blob, filename);
        }
      });
    };

    getLatestProcessedImportRecords();

    function getLatestProcessedExportRecords() {
      $http
        .get(`${API_BASE_URL}export/getLatestProcessedExportRecords/MAS`)
        .then((result) => {
          if (result.data.success) {
            $scope.exportList = result.data.data;
          }
        })
        .catch((err) => {
          toaster.pop({
            type: 'error',
            title: err,
            showCloseButton: true,
            timeout: 10000,
          });
        });
    }
    getLatestProcessedExportRecords();

    function listenForPubnubMessages() {
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      $rootScope.$on(Pubnub.getMessageEventNameFor(userDetails.user_id), (event, payload) => {
        const {
          module,
          page,
          type,
        } = payload.message;
        if ((module || page).toLowerCase() === 'mas') {
          if (type === 'dispatchImportRequestProcessed') { getLatestProcessedImportRecords(); }
          if (type === 'fileReady') { getLatestProcessedExportRecords(); }
        }
      });
    }
    listenForPubnubMessages();

    $scope.onTabClick = function onTabClick(tabName) {
      if (!$scope.tabsObject[tabName]) {
        resetTabsObject();
        $scope.tabsObject[tabName] = true;
      }
    };
  },
]);
