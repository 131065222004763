const { intlTelInputUtils } = window;
const { userRoles } = relaylib.common.constants;

angular.module('relayHealth').controller('manageUserCntrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  '$state',
  '$stateParams',
  'toaster',
  'SweetAlert',
  'loadingScreenFactory',
  '$window',
  'org_constants',
  'isMasterOrgFilter',
  'permissionFilter',
  'displayRoleFilter',
  '$filter',
  '$timeout',
  'WORKERS_COMPENSATION_KEY',
  'HEALTHCARE_MASS_MARKET_KEY',
  function manageUserCntrl(
    $scope,
    $http,
    API_BASE_URL,
    $state,
    $stateParams,
    toaster,
    SweetAlert,
    loadingScreenFactory,
    $window,
    orgConstants,
    isMasterOrgFilter,
    permissionFilter,
    displayRoleFilter,
    $filter,
    $timeout,
    WORKERS_COMPENSATION_KEY,
    HEALTHCARE_MASS_MARKET_KEY,
  ) {
    $scope.userDetails = {};
    $scope.userDetails.settings = {};
    $scope.permissionsConstants = relaylib.permissions.permissionsConstants;
    const { userId } = $stateParams;
    let editToggle = $stateParams.edit;
    const signedInUser = JSON.parse(localStorage.getItem('userDetails'));
    $scope.signedInUser = signedInUser;
    let userDetails;
    $scope.editAddCreditCard = false;
    $scope.state = {
      billingAddress: {
        state: {},
      },
      userId,
      businessInfo: {},
      personalInfo: {},
    };
    $scope.paymentDetails = {
      stripeCardDetails: 'abc',
    };
    $scope.showAccessList = true;
    $scope.disableForm = true;
    $scope.orgUserCount = 0;
    $scope.currentOrganisation = undefined;
    $scope.lastChangedOrgId = undefined; // org id of the last selection made from the organisation drop-down
    $scope.operationsTeam = [];
    $scope.selectedOperationsTeams = {
      selected: [],
    };
    $scope.initialselectedOperationsTeams = [];
    $scope.org_constants = orgConstants;
    const ROLES = relaylib.common.constants.userRoles;
    $scope.visibleOrganisationChoices = 20;
    $scope.visibleOrgAlertChoices = 20;
    $scope.linkedOrgs = [
      {
        organisation: null,
        org_id: null,
        role: null,
        access_type: null,
        operations_team: [],
        default: true,
        accessTypeList: [],
        loggedInOrg: 'true',
        assignedOrgAlertsList: [],
        assignedOrgAlerts: [],
        visibleOrganisationChoices: 20,
        visibleOrgAlertChoices: 20,
      },
    ];
    $scope.selectedOrgs = [0];
    $scope.setDefaultOrg = (selectedIndex) => {
      $scope.linkedOrgs.forEach((item, index) => {
        if (selectedIndex === index) {
          $scope.linkedOrgs[index].default = true;
        } else {
          $scope.linkedOrgs[index].default = false;
        }
      });
    };
    $scope.addAnotherOrganisation = () => {
      $scope.linkedOrgs.push({
        organisation: null,
        org_id: null,
        role: null,
        access_type: null,
        operations_team: [],
        default: false,
        accessTypeList: [],
        loggedInOrg: 'true',
        assignedOrgAlertsList: [],
        assignedOrgAlerts: [],
        visibleOrganisationChoices: 20,
        visibleOrgAlertChoices: 20,
      });
      $scope.selectedOrgs.push(0);
    };
    $scope.removeOrganisation = (index) => {
      $scope.linkedOrgs.splice(index, 1);
      $scope.selectedOrgs.splice(index, 1);
    };

    function getOperationsTeam() {
      const getOperationsTeamCall = $http({
        url: `${API_BASE_URL}operationsteams`,
        method: 'GET',
      });
      getOperationsTeamCall.then(
        (result) => {
          if (result.data.success && result.data.data && result.data.data.length > 0) {
            $scope.operationsTeam = result.data.data;
          } else {
            $scope.operationsTeam = [];
            toaster.pop({
              type: 'error',
              title: 'Unable to fetch operations team',
              showCloseButton: true,
              timeout: 10000,
            });
          }
        },
        (error) => {
          $scope.operationsTeam = [];
          toaster.pop({
            type: 'error',
            title: error.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    }
    getOperationsTeam();
    if (editToggle) {
      $scope.disableForm = false;
      if (!$scope.userDetails.settings) {
        $scope.userDetails.settings = {};
      }
    }
    $http.get(`${API_BASE_URL}states`).then((result) => {
      if (result.data.success) {
        result.data.states.unshift({
          id: 0,
          state_name: 'Select State',
        });
        $scope.states = result.data.states;
      } else {
        toaster.pop({
          type: 'error',
          title: result.data.message,
          showCloseButton: true,
          timeout: 10000,
        });
      }
    });

    function flattenChildren(childOrgs, allOrgs = []) {
      childOrgs.forEach((org) => {
        allOrgs.push(org);
        if (org.children && org.children.length) {
          flattenChildren(org.children, allOrgs);
        }
      });
      return allOrgs;
    }

    function getAllOrgs() {
      const res = $http.get(`${API_BASE_URL}organisation/getOrganizations/active`);
      loadingScreenFactory.showLoadingScreen();
      res.then(({ data }) => {
        loadingScreenFactory.hideLoadingScreen();
        if (data.success) {
          let allOrgs = data.org_details;
          if (allOrgs.length === 1) {
            allOrgs = flattenChildren(allOrgs);
          }
          $scope.orgList = allOrgs;
        } else {
          toaster.pop({
            type: 'error',
            title: data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        }
      });
      res.catch(() => {
        loadingScreenFactory.hideLoadingScreen();
        toaster.pop({
          type: 'error',
          title: 'Not able to org data',
          showCloseButton: true,
          timeout: 10000,
        });
      });
    }

    getAllOrgs();

    function isLoggedInOrg(signedInUserOrgId, orgId) {
      return signedInUserOrgId.toString() === orgId.toString() ? 'true' : 'false';
    }

    function getUserCount(orgId) {
      if (!orgId) return;
      $http
        .get(`${API_BASE_URL}getUsersByOrgId/${orgId}/0`)
        .then((result) => {
          if (result.data.success) {
            $scope.orgUserCount = result.data.userList.length;
          } else {
            $scope.orgUserCount = 0;
          }
        })
        .catch(() => { });
    }

    $scope.mask = '(999) 999-9999';
    (function registerListenerForCountryChange() {
      $timeout(() => {
        $('.iti__country-list .iti__country').click(() => {
          $timeout(() => {
            if ($('#userPhone').intlTelInput('getSelectedCountryData').iso2 === 'us') {
              $scope.mask = '(999) 999-9999';
            } else {
              $scope.mask = '(999) 999-99999?9?9?9?9?';
            }
          });
        });
      });
    }());

    function showToaster(type, message, customMessage) {
      toaster.clear();
      toaster.pop({
        type: ['info', 'error', 'success'].indexOf(type) > -1 ? type : 'error',
        title: message || customMessage,
        showCloseButton: true,
        timeout: 10000,
      });
    }
    /**
     *
     * @param {*} operationTeams
     * function used to get organisations for opeartion teams
     */
    const getAssignedOrgAlertsList = async (operationTeams) => {
      let alertOrganisations = [];
      try {
        const {
          data:
           { success, message, organisations } =
           {},
        } = await $http.get(`${API_BASE_URL}operationsteams/${operationTeams}/organisations`);
        if (!success) {
          throw new Error(message);
        }
        alertOrganisations = organisations;
      } catch (error) {
        showToaster('error', error.data ? error.data.message : 'Could not fetch user details', '');
      }
      return alertOrganisations;
    };
    /**
     *  operationTeamSelected
     */
    $scope.operationTeamSelected = async (index, operationTeams) => {
      if (
        ![ROLES.MASTER_SUPER_ADMIN, ROLES.PRODUCTION_OPERATION].includes(
          $scope.signedInUser.role,
        )
        || ![ROLES.RIDE_MANAGER, ROLES.NETWORK_OPERATION].includes(
          $scope.linkedOrgs[index].access_type,
        )
      ) {
        return;
      }
      const organisations = await getAssignedOrgAlertsList(operationTeams);
      Object.assign($scope.linkedOrgs[index], { assignedOrgAlertsList: organisations });
    };
    /**
     * operation team removed
     */
    $scope.operationTeamRemoved = async (index, item) => {
      const removeOrgIds = [];
      const remianingOrgAlertList = [];
      $scope.linkedOrgs[index].assignedOrgAlertsList.forEach(
        (assignedOrgAlertList) => {
          if (assignedOrgAlertList.operationTeamId === item.id) {
            removeOrgIds.push(assignedOrgAlertList.orgId);
          } else {
            remianingOrgAlertList.push(assignedOrgAlertList);
          }
        },
      );
      const remianingOrgIds =  $scope.linkedOrgs[index].assignedOrgAlerts.filter(organisationId => !removeOrgIds.includes(organisationId));
      Object.assign($scope.linkedOrgs[index], {
        assignedOrgAlertsList: remianingOrgAlertList,
        assignedOrgAlerts: remianingOrgIds,
      });
    };
    $scope.loadMoreChoices = (type, index) => {
      if (type === 'orgAlert') {
        Object.assign($scope.linkedOrgs[index], {
          visibleOrgAlertChoices:
            $scope.linkedOrgs[index].visibleOrgAlertChoices + 20,
        });
      }
      if (type === 'org') {
        Object.assign($scope.linkedOrgs[index], {
          visibleOrganisationChoices:
            $scope.linkedOrgs[index].visibleOrganisationChoices + 20,
        });
      }
    };

    function getUserDetailsById() {
      const getUserDetailsByIdCall = $http.get(`${API_BASE_URL}users/getUserDetailsById/${userId}`);
      getUserDetailsByIdCall.then(
        (result) => {
          if (result.data.success) {
            $scope.userDetails = result.data.userDetails;
            $scope.userDetails.phoneNumber = result.data.userDetails.phone;
            $scope.userDetails.phone = $scope.userDetails.phoneNumber ? $scope.userDetails.phoneNumber.replace(/[- )(]/g, '').slice(-10) : '';

            if (result.data.userDetails.payment_profile && result.data.userDetails.payment_profile.stripe_profile) {
              $scope.state.billingAddress.address1 = result.data.userDetails.payment_profile.stripe_profile.address_line1;
              $scope.state.billingAddress.city = result.data.userDetails.payment_profile.stripe_profile.address_city;
              $scope.state.billingAddress.zip = result.data.userDetails.payment_profile.stripe_profile.address_zip;
              $scope.state.billingAddress.state.state_name = result.data.userDetails.payment_profile.stripe_profile.address_state;
              $scope.paymentDetails.name = result.data.userDetails.payment_profile.stripe_profile.name;
              $scope.paymentDetails = result.data.userDetails.payment_profile.stripe_profile || {};
              $scope.paymentDetails.last4 = `************${$scope.paymentDetails.last4}`;
            } else {
              $scope.paymentDetails = {};
            }
            getUserCount($scope.userDetails.org_id);
            for (let i = 0; i < $scope.userDetails.organisations.length; i += 1) {
              if ($scope.userDetails.organisations[i].id.toString() === $scope.userDetails.org_id.toString()) {
                $scope.linkedOrgs[0].organisation = $scope.userDetails.organisations[i];
                $scope.orgChanged($scope.linkedOrgs[0].organisation, 0);
                angular.forEach($scope.userDetails.user_operations_teams, (opsTeam) => {
                  if (opsTeam.org_id.toString() === $scope.userDetails.organisations[i].id.toString()) {
                    $scope.linkedOrgs[0].operations_team.push(opsTeam.operations_team_id);
                    if (Array.isArray(opsTeam.alert_org_ids) && opsTeam.alert_org_ids.length) {
                      $scope.linkedOrgs[0].assignedOrgAlerts.push(...opsTeam.alert_org_ids);
                    }
                  }
                });
                angular.forEach($scope.userDetails.user_orgs, (userOrg) => {
                  if (userOrg.org_id.toString() === $scope.userDetails.organisations[i].id.toString()) {
                    $scope.linkedOrgs[0].access_type = userOrg.role;
                  }
                });
                $scope.linkedOrgs[0].loggedInOrg = isLoggedInOrg($scope.signedInUser.org_id, $scope.linkedOrgs[0].organisation.id);
              } else {
                $scope.linkedOrgs.push({
                  organisation: $scope.userDetails.organisations[i],
                  org_id: null,
                  role: null,
                  access_type: null,
                  operations_team: [],
                  default: false,
                  accessTypeList: [],
                  assignedOrgAlertsList: [],
                  assignedOrgAlerts: [],
                });
                $scope.orgChanged($scope.userDetails.organisations[i], $scope.linkedOrgs.length - 1);
                angular.forEach($scope.userDetails.user_operations_teams, (opsTeam) => {
                  if (opsTeam.org_id.toString() === $scope.userDetails.organisations[i].id.toString()) {
                    $scope.linkedOrgs[$scope.linkedOrgs.length - 1].operations_team.push(opsTeam.operations_team_id);
                    if (Array.isArray(opsTeam.alert_org_ids) && opsTeam.alert_org_ids.length) {
                      $scope.linkedOrgs[$scope.linkedOrgs.length - 1].assignedOrgAlerts.push(...opsTeam.alert_org_ids);
                    }
                  }
                });
                angular.forEach($scope.userDetails.user_orgs, (userOrg) => {
                  if (userOrg.org_id.toString() === $scope.userDetails.organisations[i].id.toString()) {
                    $scope.linkedOrgs[$scope.linkedOrgs.length - 1].access_type = userOrg.role;
                  }
                });
                $scope.linkedOrgs[$scope.linkedOrgs.length - 1].loggedInOrg = isLoggedInOrg($scope.signedInUser.org_id, $scope.userDetails.organisations[i].id);
              }
            }
            /**
             * Assigned org alerts is only visible to MSA and PO
             */
            if ([ROLES.MASTER_SUPER_ADMIN, ROLES.PRODUCTION_OPERATION].includes(
              $scope.signedInUser.role,
            )) {
              $scope.linkedOrgs.forEach(async (linkedOrg) => {
                if (
                  [ROLES.RIDE_MANAGER, ROLES.NETWORK_OPERATION].includes(
                    linkedOrg.access_type,
                  )
                  && linkedOrg.operations_team
                  && linkedOrg.operations_team.length
                ) {
                  const organisations = await getAssignedOrgAlertsList(linkedOrg.operations_team);
                  if (linkedOrg.assignedOrgAlerts && linkedOrg.assignedOrgAlerts.length) {
                    organisations.sort((x, y) => {
                      if (linkedOrg.assignedOrgAlerts.includes(x.orgId)) {
                        return -1;
                      }
                      if (linkedOrg.assignedOrgAlerts.includes(y.orgId)) {
                        return 1;
                      }
                      return 0;
                    });
                  }
                  Object.assign(linkedOrg, {
                    assignedOrgAlertsList: organisations,
                    visibleOrgAlertChoices:
                      linkedOrg.assignedOrgAlerts.length + 20,
                  });
                }
              });
            }
            if (!$scope.userDetails.settings) {
              $scope.userDetails.settings = {};
            }
            userDetails = angular.copy($scope.userDetails);
          } else {
            toaster.pop({
              type: 'error',
              title: result.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
            $window.history.back();
          }
        },
        (error) => {
          toaster.pop({
            type: 'error',
            title: error.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
          $window.history.back();
        },
      );
    }
    getUserDetailsById();
    $scope.editDetailsClicked = () => {
      $scope.disableForm = false;
      editToggle = true;
      if (!$scope.userDetails.settings) {
        $scope.userDetails.settings = {};
      }
    };
    $scope.can_see_notifications = (bool) => {
      $scope.userDetails.settings.admin_notifications = bool;
    };
    $scope.can_see_issues = (bool) => {
      $scope.userDetails.settings.admin_issues = bool;
    };
    $scope.is_pr_allowed = (bool) => {
      $scope.userDetails.settings.admin_pr = bool;
    };
    $scope.cancelClicked = function cancelClicked() {
      if (editToggle) {
        $window.history.back();
      } else {
        $scope.userDetails = angular.copy(userDetails);
        if (isMasterOrgFilter($scope.userDetails.organisation.org_level)) {
          const loggedInUserDetails = JSON.parse(localStorage.getItem('userDetails'));
          if ((loggedInUserDetails.role && loggedInUserDetails.role === 'mastersuperadmin') || permissionFilter($scope.permissionsConstants.USER_UPDATE_ANY)) {
            $scope.accessTypeList = [
              {
                type: userRoles.PRODUCTION_OPERATION,
                name: displayRoleFilter(userRoles.PRODUCTION_OPERATION),
              },
              {
                type: 'mastersuperadmin',
                name: 'Master Super Admin',
              },
              {
                type: 'admin',
                name: 'Ride Manager Admin',
              },
              {
                type: 'superadmin',
                name: 'Superadmin',
              },
              {
                type: 'Scheduling admin',
                name: 'Scheduling Admin',
              },
              {
                type: userRoles.NETWORK_OPERATION,
                name: displayRoleFilter(userRoles.NETWORK_OPERATION),
              },
              { type: userRoles.OC_REPORTING, name: displayRoleFilter(userRoles.OC_REPORTING) },
            ];
          } else {
            $scope.accessTypeList = [
              {
                type: 'admin',
                name: 'Ride Manager Admin',
              },
              {
                type: 'superadmin',
                name: 'Superadmin',
              },
              {
                type: 'Scheduling admin',
                name: 'Scheduling Admin',
              },
              { type: userRoles.OC_REPORTING, name: displayRoleFilter(userRoles.OC_REPORTING) },
            ];
          }
          // $scope.userDetails.accessType = undefined
          $scope.showAccessList = true;
        } else if ($scope.userDetails.organisation.org_class === 'business') {
          $scope.accessTypeList = [
            {
              type: 'orgadmin',
              name: 'User',
            },
            {
              type: 'org_superadmin',
              name: 'Admin',
            },
            { type: userRoles.ORG_REPORTING, name: displayRoleFilter(userRoles.ORG_REPORTING) },
          ];
        } else if ($scope.userDetails.organisation.org_class === 'rider') {
          $scope.accessTypeList = [
            {
              type: 'riderUser',
              name: 'User',
            },
            {
              type: 'riderAdmin',
              name: 'Admin',
            },

          ];
        } else {
          $scope.accessTypeList = [];
        }
        $scope.disableForm = false;
      }
    };

    function deleteUser() {
      const deleteUserCall = $http.put(`${API_BASE_URL}users/toggleActivation/${userId}`, {
        is_del: 1,
      });
      loadingScreenFactory.showLoadingScreen();
      deleteUserCall.then(
        (result) => {
          loadingScreenFactory.hideLoadingScreen();
          if (result.data.success) {
            SweetAlert.swal(
              {
                title: 'Deactivated',
                text: 'User deactivated successfully',
                type: 'success',
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'OK',
                closeOnConfirm: true,
              },
              (isConfirm) => {
                if (isConfirm) {
                  $state.go('main.userMgmt.userList', {
                    orgId: userDetails.org_id,
                  });
                }
              },
            );
          } else {
            toaster.pop({
              type: 'error',
              title: result.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        },
        (error) => {
          loadingScreenFactory.hideLoadingScreen();
          toaster.pop({
            type: 'error',
            title: error.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    }
    $scope.activateUser = () => {
      const activateUserCall = $http.put(`${API_BASE_URL}users/toggleActivation/${userId}`, {
        is_del: 0,
      });
      loadingScreenFactory.showLoadingScreen();
      activateUserCall.then(
        (result) => {
          loadingScreenFactory.hideLoadingScreen();
          if (result.data.success) {
            SweetAlert.swal(
              {
                title: 'Activated',
                text: 'User activated successfully',
                type: 'success',
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'OK',
                closeOnConfirm: true,
              },
              (isConfirm) => {
                if (isConfirm) {
                  $state.go('main.userMgmt.userList', {
                    orgId: userDetails.org_id,
                  });
                }
              },
            );
          } else {
            toaster.pop({
              type: 'error',
              title: result.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        },
        (error) => {
          loadingScreenFactory.hideLoadingScreen();
          toaster.pop({
            type: 'error',
            title: error.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    };
    $scope.deleteUser = () => {
      SweetAlert.swal(
        {
          title: 'Are you sure ?',
          text: 'Are you sure you want to deactivate this User?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, deactivate it!',
          closeOnConfirm: true,
          closeOnCancel: true,
        },
        (isConfirm) => {
          if (isConfirm) deleteUser();
        },
      );
    };

    $scope.saveChangesClicked = () => {
      const orgRoles = [];
      $scope.linkedOrgs.forEach((org) => {
        if (!org.organisation || !org.organisation.id || !org.access_type) {
          if (org.default) {
            showToaster('error', 'Default Organization details cannot be empty', '');
          }
          return;
        }
        if (org.default) {
          orgRoles.unshift({
            id: org.organisation ? org.organisation.id : undefined,
            role: org.access_type || undefined,
            operations_team: org.operations_team && org.operations_team.length > 0 ? org.operations_team : undefined,
            assigned_org_alerts: org.assignedOrgAlerts && org.assignedOrgAlerts.length ?  org.assignedOrgAlerts : undefined,
          });
        } else {
          orgRoles.push({
            id: org.organisation ? org.organisation.id : undefined,
            role: org.access_type || undefined,
            operations_team: org.operations_team && org.operations_team.length > 0 ? org.operations_team : undefined,
            assigned_org_alerts: org.assignedOrgAlerts && org.assignedOrgAlerts.length ?  org.assignedOrgAlerts : undefined,
          });
        }
      });

      const phoneNumber = angular.element('#userPhone') ? angular.element('#userPhone').intlTelInput('getNumber', intlTelInputUtils.numberFormat.E164) : '';
      let phoneNumberWithoutCountryCode = angular.element('#userPhone').val() || '';
      phoneNumberWithoutCountryCode = phoneNumberWithoutCountryCode.replace(/[- )(]/g, '');
      if (!$scope.userDetails.phone && (!parseInt(phoneNumberWithoutCountryCode, 10) || phoneNumberWithoutCountryCode.length < 10)) {
        toaster.pop({
          type: 'error',
          title: 'Phone number is not valid.',
          showCloseButton: true,
          timeout: 10000,
        });
        return;
      }
      if (!$scope.userDetails.settings) {
        $scope.userDetails.settings = {};
      }
      const serverData = {
        phone: phoneNumber,
        first_name: $scope.userDetails.first_name,
        last_name: $scope.userDetails.last_name ? $scope.userDetails.last_name : null,
        username: $scope.userDetails.username,
        settings: $scope.userDetails.settings,
        org_level: $scope.userDetails.organisation ? $scope.userDetails.organisation.org_level : undefined,
        org_roles: orgRoles,
      };
      // var currentOrganisation = $scope.currentOrganisation;
      // var nextOrganisation = serverData.org_id;
      // var currentOrganisationUserCount = $scope.orgUserCount;
      // if ($scope.userDetails.accessType) {
      //     serverData.role = getRole($scope.userDetails.accessType)
      // } else {
      //     toaster.pop({
      //         type: 'error',
      //         title: 'Please select role!',
      //         showCloseButton: true,
      //         timeout: 10000
      //     });
      //     return false;
      // }
      // if($scope.isMainContact && $scope.currentOrganisation!=$scope.userDetails.organisation.id) {
      //     toaster.pop({
      //         type: 'error',
      //         title: 'Main Contact cannot be moved, Please add another user and make him as the main contact',
      //         showCloseButton: true,
      //         timeout: 10000
      //     });
      //     return false;
      // }
      // if($scope.userDetails.organisation.main_contact_id === $scope.userDetails.id){
      //     if((currentOrganisation === nextOrganisation) && isMasterOrgFilter($scope.userDetails.organisation.org_level)){
      //         if(serverData.role !== 'mastersuperadmin' && !permissionFilter($scope.permissionsConstants.USER_UPDATE_ANY)){
      //             toaster.pop({
      //                 type: 'error',
      //                 title: 'You cannot change the role of main contact',
      //                 showCloseButton: true,
      //                 timeout: 10000
      //             });
      //             return false;
      //         }
      //     }else{
      //         if(serverData.role !== 'org_superadmin' && serverData.role !== 'riderAdmin' ){
      //             toaster.pop({
      //                 type: 'error',
      //                 title: 'You cannot change the role of main contact',
      //                 showCloseButton: true,
      //                 timeout: 10000
      //             });
      //             return false;
      //         }
      //     }
      // }
      // if (serverData.role == 'mastersuperadmin' || permissionFilter($scope.permissionsConstants.USER_UPDATE_ANY)) {
      //     serverData.operations_team_id = null;
      // }
      const editUserCall = $http.put(`${API_BASE_URL}users/edit/${$scope.userDetails.id}`, serverData);
      loadingScreenFactory.showLoadingScreen();
      editUserCall.then(
        (result) => {
          loadingScreenFactory.hideLoadingScreen();
          if (result.data.success) {
            toaster.pop({
              type: 'success',
              title: 'Changes Saved',
              showCloseButton: true,
              timeout: 10000,
            });
          } else {
            toaster.pop({
              type: 'error',
              title: result.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        },
        (error) => {
          loadingScreenFactory.hideLoadingScreen();
          toaster.pop({
            type: 'error',
            title: error.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    };

    $scope.passwordSettingsClicked = () => $state.go('main.userMgmt.passwordSettings', {
      userId: $scope.userDetails.id,
    });


    $scope.orgChanged = (org, index, uiAction) => {
      if (index > -1) {
        $scope.linkedOrgs[index].accessTypeList = $scope.getAccessType(org);
        const filteredAccessTypes = $filter('orderBy')($scope.linkedOrgs[index].accessTypeList, 'name');
        $scope.linkedOrgs[index].access_type = filteredAccessTypes[0].type;
        $scope.selectedOrgs[index] = org.id;
      } else {
        $scope.accessTypeList = $scope.getAccessType(org);
      }
      /**
       * when org changed than removing operations_team and assignedOrgAlerts
       */
      if (
        uiAction
        && $scope.linkedOrgs[index].org_id
        && $scope.linkedOrgs[index].org_id !== org.id
      ) {
        Object.assign($scope.linkedOrgs[index], {
          operations_team: [],
          assignedOrgAlertsList: [],
          assignedOrgAlerts: [],
        });
      }
    };
    $scope.getAccessType = (org) => {
      if (!org) {
        return [];
      }

      let loggedInUserDetails = localStorage.getItem('userDetails');
      loggedInUserDetails = JSON.parse(loggedInUserDetails);

      let accessType = [];
      if (isMasterOrgFilter(org.org_level)) {
        $scope.userDetails.accessType = '';
        if ((loggedInUserDetails.role && loggedInUserDetails.role === 'mastersuperadmin') || permissionFilter($scope.permissionsConstants.USER_UPDATE_ANY)) {
          accessType = [
            { type: userRoles.PRODUCTION_OPERATION, name: displayRoleFilter(userRoles.PRODUCTION_OPERATION) },
            { type: 'mastersuperadmin', name: 'Master Super Admin' },
            { type: 'admin', name: 'Ride Manager Admin' },
            { type: 'superadmin', name: 'Superadmin' },
            { type: 'Scheduling admin', name: 'Scheduling Admin' },
            { type: userRoles.NETWORK_OPERATION, name: displayRoleFilter(userRoles.NETWORK_OPERATION) },
            { type: userRoles.PROVIDER_MANAGER, name: displayRoleFilter(userRoles.PROVIDER_MANAGER) },
            { type: userRoles.OC_REPORTING, name: displayRoleFilter(userRoles.OC_REPORTING) },
          ];
          $scope.userDetails.accessType = { type: 'Scheduling admin', name: 'Scheduling Admin' };
        } else {
          accessType = [
            { type: 'admin', name: 'Ride Manager Admin' },
            { type: 'superadmin', name: 'Superadmin' },
            { type: 'Scheduling admin', name: 'Scheduling Admin' },
            { type: userRoles.OC_REPORTING, name: displayRoleFilter(userRoles.OC_REPORTING) },
          ];
        }
      } else if (loggedInUserDetails.role && loggedInUserDetails.role === 'riderAdmin') {
        accessType = [{ type: 'riderUser', name: 'User' }, { type: 'riderAdmin', name: 'Admin' }];
      } else if (loggedInUserDetails.role === 'org_superadmin') {
        accessType = [{ type: 'orgadmin', name: 'User' }, { type: 'org_superadmin', name: 'Admin' }];

        if ([WORKERS_COMPENSATION_KEY, HEALTHCARE_MASS_MARKET_KEY].includes(org.market_segment.key)) {
          accessType.push({ type: userRoles.ORG_REPORTING, name: displayRoleFilter(userRoles.ORG_REPORTING) });
        }
      } else if (
        loggedInUserDetails.role === 'mastersuperadmin'
        || loggedInUserDetails.role === 'superadmin'
        || permissionFilter($scope.permissionsConstants.USER_UPDATE_ANY)
      ) {
        if (org.org_class === 'business') {
          accessType = [{ type: 'orgadmin', name: 'User' }, { type: 'org_superadmin', name: 'Admin' }];
          if ([WORKERS_COMPENSATION_KEY, HEALTHCARE_MASS_MARKET_KEY].includes(org.market_segment.key)) {
            accessType.push({ type: userRoles.ORG_REPORTING, name: displayRoleFilter(userRoles.ORG_REPORTING) });
          }
        } else if (org.org_class === 'rider') {
          accessType = [{ type: 'riderUser', name: 'User' }, { type: 'riderAdmin', name: 'Admin' }];
        } else {
          accessType = [];
        }
      } else {
        accessType = [];
      }
      return accessType;
    };
  },
]);
