import app from '../../app';

app
  .controller('secureEmailCtrl', [
    '$scope',
    '$http',
    '$stateParams',
    'toaster',
    'API_BASE_URL',
    'loadingScreenFactory',
    '$uibModal',
    function secureEmailCtrl(
      $scope,
      $http,
      $stateParams,
      toaster,
      API_BASE_URL,
      loadingScreenFactory,
      $uibModal,
    ) {
      this.toggleOtpView = false;
      this.viewMailFormModel = {};
      this.generateOtpModel = {};

      this.generateOtp = async () => {
        loadingScreenFactory.showLoadingScreen();
        try {
          const { hashkey } = $stateParams;
          const headers = {
            type: 'generateOTP',
            email: this.generateOtpModel.email,
            'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
          };

          const requestUrl = `${API_BASE_URL}email-store/${hashkey}`;
          const { data: { success, data: { receiver }, message } } = await $http.get(requestUrl, {
            headers,
          });
          if (success) {
            toaster.pop({
              type: 'info',
              title: `OTP Sent to ${receiver}`,
              showCloseButton: true,
            });
          } else {
            toaster.pop({
              type: 'error',
              title: message,
              showCloseButton: true,
            });
          }
        } catch (err) {
          const message = (err.data) ? err.data.message : err.message;
          toaster.pop({
            type: 'error',
            title: message,
            showCloseButton: true,
          });
        } finally {
          loadingScreenFactory.hideLoadingScreen();
          $scope.$apply();
        }
      };

      const modalInstanceCtrl = function modalInstanceCtrl($uibModalInstance, emailContent, sce) {
        this.message = sce.trustAsHtml(emailContent.message);
        this.subject = emailContent.subject;
        this.cancel = function cancel() {
          $uibModalInstance.dismiss('cancel');
        };
      };

      this.viewMailContent = async () => {
        loadingScreenFactory.showLoadingScreen();
        try {
          const { otp, email } = this.viewMailFormModel;
          const headers = {
            email,
            otp,
            type: 'getEmail',
            'Content-Type': 'application/json',
            'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
          };
          const { hashkey } = $stateParams;
          const requestUrl = `${API_BASE_URL}email-store/${hashkey}`;
          const response = await $http.get(requestUrl, { headers });
          const { data: { success, data: { message, subject } }, data } = response;
          if (success) {
            $uibModal.open({
              template: require('./email-modal.html'),
              controller: ['$uibModalInstance', 'emailContent', '$sce', modalInstanceCtrl],
              controllerAs: '$childCtrl',
              size: 'lg',
              backdrop: 'static',
              windowClass: 'view-email-modal',
              resolve: {
                emailContent() {
                  return { message, subject };
                },
              },
            });
          } else {
            toaster.pop({
              type: 'error',
              title: data.message,
              showCloseButton: true,
            });
          }
        } catch (err) {
          const message = (err.data) ? err.data.message : err.message;
          toaster.pop({
            type: 'error',
            title: message,
            showCloseButton: true,
          });
        } finally {
          loadingScreenFactory.hideLoadingScreen();
          $scope.$apply();
        }
      };
    },
  ]);
