angular.module('relayHealth').factory('requestingOrganizationFactory', [
  'API_BASE_URL',
  '$http',
  function requestingOrganizationFactory(API_BASE_URL, $http) {
    function getRequestingOrganizations({ fields, organizationId }) {
      let result;
      const params = {};
      if (fields) {
        params.fields = fields;
      }
      if (organizationId) {
        // get third party orgainzations based on the id(s) of the organization
        result = $http.get(`${API_BASE_URL}organisations/${organizationId}/third-parties`, {
          params,
        });
      } else {
        // get third party organizations
        params.is_third_party = true;
        result = $http.get(`${API_BASE_URL}organisations`, {
          params,
        });
      }
      return result;
    }
    return {
      getRequestingOrganizations,
    };
  },
]);
