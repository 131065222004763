const sourceTypes = {
    ORDER: 'order',
    APPOINTMENT: 'appointment',
};

const appointmentEntityTypes = {
    sourceToEntityMappings: {
        [sourceTypes.ORDER]: {
            ride_type: 'order_leg',
            appointment_type: 'order'
        },
        [sourceTypes.APPOINTMENT]: {
            ride_type: 'ride',
            appointment_type: 'appointment'
        }
    },
    sourceTypes: sourceTypes,
    supportedSourceTypes: [sourceTypes.ORDER, sourceTypes.APPOINTMENT]
};
Object.freeze(appointmentEntityTypes);
export default appointmentEntityTypes;