import orgAccessTypes from './orgAccessTypes';
import orgClass from './orgClass';
import orgProviders from './orgProviders';
import orgTypes from './orgTypes';
import dbOrgTypes from './db/orgTypes';
export default {
    orgAccessTypes,
    orgClass,
    orgProviders,
    orgTypes,
    dbOrgTypes,
};