const { Blob, saveAs, jQuery } = window;
const { permissionsConstants } = relaylib.permissions;

const uncheck = { checked: false };
const orgTypes = {
  active: 'active',
  inactive: 'inactive',
};

angular.module('relayHealth').controller('downloadsCtrl', [
  '$rootScope',
  '$scope',
  '$http',
  'Pubnub',
  'API_BASE_URL',
  'toaster',
  'SweetAlert',
  'DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID',
  '$state',
  'permissionFilter',
  'isOCCMRole',
  'getMarketSegments',
  '$q',
  '$uibModal',
  function downloadsCtrl(
    $rootScope,
    $scope,
    $http,
    Pubnub,
    API_BASE_URL,
    toaster,
    SweetAlert,
    DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID,
    $state,
    permissionFilter,
    isOCCMRole,
    getMarketSegments,
    $q,
    $uibModal,
  ) {
    const listeners = [];

    const loggedInUserDetails = JSON.parse(localStorage.getItem('userDetails'));

    function getLatestProcessedExportRecords() {
      const data = {};
      $http
        .get(`${API_BASE_URL}export/getLatestProcessedExportRecords/Dynamic Export`, data)
        .then((result) => {
          if (result.data.success) {
            $scope.exportList = result.data.data;
          }
        })
        .catch((err) => {
          toaster.pop({
            type: 'error',
            title: err,
            showCloseButton: true,
            timeout: 10000,
          });
        });
    }

    function checkDownloadDisabled(orgIds, exportRequestOrgIds) {
      return exportRequestOrgIds.some(id => orgIds.indexOf(id) === -1);
    }

    $scope.refreshServiceTypes = function refreshServiceTypes() {
      const deferred = $q.defer();
      if ($scope.request.organizations.length && $scope.request.inactiveOrganizations.length) {
        $scope.request.organizations = [];
        $scope.request.inactiveOrganizations = [];
        $scope.availableServices = [];

        toaster.pop({
          type: 'error',
          title: 'Please make selection from either active or inactive branch account',
          showCloseButton: true,
          timeout: 10000,
        });
        return false;
      }

      if ($scope.availableServices.length) { $scope.availableServices.splice(0); }
      $scope.request.serviceType = null;
      $scope.refreshColumnTypes();

      let orgs = [];
      if ($scope.request.organizations.length) {
        orgs = $scope.request.organizations;
      } else if ($scope.request.inactiveOrganizations.length) {
        orgs = $scope.request.inactiveOrganizations;
      }
      if (orgs.length) {
        const exportRequestOrgIds = orgs.map(org => org.id);
        const orgIdsStr = exportRequestOrgIds.join(',');

        if ($scope.isOCCMRole) {
          if (!$scope.request.marketSegments.length || !$scope.request.accessTypes.length) {
            $scope.download_disabled = true;
          } else {
            $scope.download_disabled = false;
          }
        } else if ($scope.organisations && $scope.organisations.org_details) {
          const orgIdArray = $scope.organisations.org_details.map(org => org.id);
          $scope.download_disabled = checkDownloadDisabled(orgIdArray, exportRequestOrgIds);
        } else {
          $scope.download_disabled = true;
        }

        $http.post(`${API_BASE_URL}getListOfExportableServicesForOrgId`, { orgIds: orgIdsStr }).then(
          (resp) => {
            if (resp && resp.status === 200) {
              $scope.availableServices = resp.data.data || [];
              $scope.selectedA = [];
              $scope.selectedB = [];

              $scope.listA = [];
              $scope.listB = [];
              $scope.items = [];

              $scope.checkedA = false;
              $scope.checkedB = false;
              $scope.searchItemsA = '';
              $scope.searchItemsB = '';
            }
            deferred.resolve(true);
          },
          (error) => {
            toaster.pop({
              type: 'error',
              title: error.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          },
        );
      } else {
        deferred.resolve();
      }
      return deferred.promise.then((resp) => {
        // Set service type if anything found in saved export
        if (resp && $scope.savedExportSel && $scope.savedExportSel.serviceType) {
          $scope.request.serviceType = $scope.savedExportSel.serviceType.value;
          return $scope.refreshColumnTypes();
        }
        return resp;
      });
    };

    function getAllOrganizations() {
      $scope.reloadOrganisations = true;
    }

    $scope.onMarketSegmentAndAccessTypeChange = () => {
      $scope.request.organizations = [];
      $scope.request.inactiveOrganizations = [];
      getAllOrganizations();
    };

    function initDateRange() {
      const date = new Date();
      const y = date.getFullYear();
      const m = date.getMonth();
      const firstDay = new Date(y, m, 1).setHours(0, 0, 0);
      $scope.request.dateRange = { startDate: moment(firstDay), endDate: moment(new Date().setHours(23, 59, 59)) };
    }

    function listenForPubnubMessages() {
      listeners.push(
        $rootScope.$on(Pubnub.getMessageEventNameFor($scope.userDetails.user_id), (event, payload) => {
          if (
            payload.message.type === 'fileReady'
            && payload.message.page === 'Dynamic Export'
            && $state.current.name.indexOf('main.dashboard.downloads') >= 0
          ) {
            getLatestProcessedExportRecords();
          }
        }),
      );
    }

    function init() {
      $scope.request.organizations = [];
      $scope.request.inactiveOrganizations = [];
      $scope.organisations = null;
      initDateRange();
      if (!$scope.isOCCMRole) {
        getAllOrganizations();
      }
      getLatestProcessedExportRecords();
      listenForPubnubMessages();
    }

    $scope.getOrganizations = function getOrganizations(condition, offset, limit) {
      const requestUrl = `${API_BASE_URL}organisation/getOrganizations/${condition}/${offset}/${limit}`;
      const params = {
        orgId: loggedInUserDetails.organisation.id,
        leafNodesOnly: true,
      };
      if ($scope.showChildData()) {
        delete params.leafNodesOnly;
      }
      if ($scope.isOCCMRole) {
        if ($scope.request.marketSegments.length && $scope.request.accessTypes.length) {
          params.access_type = _.map($scope.request.accessTypes, 'id').join(',');
          params.market_segment_id = _.map($scope.request.marketSegments, 'id').join(',');
        } else {
          return $q.resolve({
            data: [],
          });
        }
      }
      return $http
        .get(requestUrl, {
          params,
        })
        .then(({ data }) => {
          if (!(data.org_details && data.org_details.length)) {
            return {
              data: [],
              count: data.totalCount,
            };
          }
          $scope.organisations = data;
          const response = data.org_details.sort((a, b) => a.name.localeCompare(b.name));
          response.forEach((item) => {
            if (item.org_class === 'rider' && item.user && item.user.first_name && item.user.last_name) {
              Object.assign({ name: `${item.user.first_name} ${item.user.last_name}` });
            }
          });
          return {
            data: response,
            count: data.totalCount,
          };
        });
    };

    function reset() {
      $scope.selectedA = [];
      $scope.selectedB = [];

      $scope.listA = [];
      $scope.listB = [];
      $scope.items = [];

      $scope.checkedA = false;
      $scope.checkedB = false;
      $scope.searchItemsA = '';
      $scope.searchItemsB = '';
    }


    function resetForm() {
      $scope.download_disabled = false;
      $scope.savedExportSel = null;
      $scope.request = {
        organizations: [],
        inactiveOrganizations: [],
      };
      if ($scope.isOCCMRole) {
        $scope.request.marketSegments = [];
        $scope.request.accessTypes = [];
      }
      initDateRange();
      if (!$scope.isOCCMRole) {
        getAllOrganizations();
      }
      $scope.refreshServiceTypes();
      reset();
    }

    function download(requestData) {
      $scope.download_disabled = true;
      $http
        .put(`${API_BASE_URL}dynamic-export`, requestData)
        .then(({ data: { message } } = {}) => {
          resetForm();
          toaster.pop({
            type: 'info',
            title: message || 'Please wait for your file.',
            showCloseButton: true,
            timeout: 10000,
          });
          getLatestProcessedExportRecords();
        })
        .catch(({ data: error } = {}) => {
          $scope.download_disabled = false;
          toaster.pop({
            type: 'error',
            title: (error.data || {}).message ? error.data.message : error.message,
            showCloseButton: true,
            timeout: 10000,
          });
          reset();
        });
    }

    $scope.exportList = [];
    $scope.branchList = [];
    $scope.availableFields = [];

    // drag-drop-dual-list #start
    $scope.selectedA = [];
    $scope.selectedB = [];

    $scope.isOCCMRole = isOCCMRole() || permissionFilter(permissionsConstants.MARKET_SEGMENT_READ);
    $scope.permissionsConstants = permissionsConstants;
    $scope.request = {
      organizations: [],
      inactiveOrganizations: [],
    };
    if ($scope.isOCCMRole) {
      $scope.request.marketSegments = [];
      $scope.request.accessTypes = [];
    }
    $scope.listA = [];
    $scope.listB = [];
    $scope.items = [];

    $scope.checkedA = false;
    $scope.checkedB = false;
    $scope.searchItemsA = '';
    $scope.searchItemsB = '';
    // drag-drop-dual-list #end
    $scope.marketSegmentList = [];
    $scope.accessTypeList = Object.values(relaylib.organisation.constants.orgAccessTypes).map(value => ({
      id: value,
      label: value.charAt(0).toUpperCase() + value.slice(1),
    }));

    $scope.loggedInUserOrgId = loggedInUserDetails.organisation.id;
    $scope.userDetails = loggedInUserDetails;
    $scope.occm_id = DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID;

    $scope.availableServices = [];

    getMarketSegments((err, data) => {
      if (!err) {
        $scope.marketSegmentList = data;
      } else {
        toaster.pop({
          type: 'error',
          title: err.msg || err,
          showCloseButton: true,
          timeout: 10000,
        });
        $scope.marketSegmentList = [];
      }
    });

    $scope.refreshColumnTypes = () => {
      const deferred = $q.defer();
      if ($scope.availableFields.length) { $scope.availableFields.splice(0); }
      $scope.request.selectedFields = [];
      if ($scope.request.serviceType) {
        $http.get(`${API_BASE_URL}getListOfColumnsForServiceType/${$scope.request.serviceType}`).then((resp) => {
          if (resp && resp.status === 200) {
            $scope.availableFields = resp.data.data || [];
            $scope.items = angular.copy($scope.availableFields);
            $scope.listA = $scope.items.slice(0);
            $scope.listB = $scope.request.selectedFields;
            $scope.checkedA = false;
            $scope.checkedB = false;

            angular.forEach($scope.items, (element) => {
              Object.assign(element, uncheck);
            });
          }
          deferred.resolve(true);
        });
      } else {
        $scope.items = angular.copy($scope.availableFields);
        $scope.listA = $scope.items.slice(0);
        $scope.listB = [];
        $scope.checkedA = false;
        $scope.checkedB = false;

        angular.forEach($scope.items, (element) => {
          Object.assign(element, uncheck);
        });
        deferred.resolve();
      }
      return deferred.promise.then((resp) => {
        // Set selected columns as per saved export or empty
        if (resp && $scope.savedExportSel && $scope.savedExportSel.selectedFields) {
          let fields = angular.copy($scope.savedExportSel.selectedFields);
          fields = _.uniqBy(fields, 'code');
          $scope.request.selectedFields = fields;
          angular.forEach($scope.request.selectedFields, (field) => {
            Object.assign(field, uncheck);
            $scope.selectField([], $scope.selectedA, field);
          });
          $scope.aToB();
          $scope.savedExportSel = null;
        }
        return true;
      });
    };

    $scope.downloadXlsxFile = (filename) => {
      const downloadXlsxFileCall = $http({
        url: `${API_BASE_URL}export/downloadXlsxFile/${filename}`,
        method: 'GET',
        data: {
          filename,
        },
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'arraybuffer',
      });

      downloadXlsxFileCall.then((result) => {
        const blob = new Blob([result.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        if (blob.size < 70) {
          toaster.pop({
            type: 'error',
            title: 'No Records found! Please try changing date range!',
            showCloseButton: true,
            timeout: 10000,
          });
        } else {
          saveAs(blob, filename);
        }
      });
    };

    $scope.download_disabled = false;

    $scope.dateRangeOptions = {
      parentEl: '#current-app #page-wrapper',
      eventHandlers: {
        'apply.daterangepicker': function applyDaterangepicker() {
          const { request: { dateRange: { startDate, endDate } = {} } = {} } = $scope;
          if (endDate.diff(startDate, 'months') > 5) {
            $scope.showMaxMonthExportLimitPopover = true;
            initDateRange();
          } else {
            $scope.showMaxMonthExportLimitPopover = false;
          }
        },
      },
    };

    $scope.submitExportRequest = () => {
      let orgs = [];
      if ($scope.request.organizations.length) {
        orgs = $scope.request.organizations;
      } else if ($scope.request.inactiveOrganizations.length) {
        orgs = $scope.request.inactiveOrganizations;
      }
      const requestData = {
        orgIds: orgs.map(o => o.id),
        serviceType: $scope.request.serviceType,
        dateRange: {
          start: $scope.request.dateRange.startDate.format('YYYY-MM-DD HH:mm:ss'),
          end: $scope.request.dateRange.endDate.format('YYYY-MM-DD HH:mm:ss'),
        },
        selectedFields: ($scope.request.selectedFields || []).map(f => f.code),
        marketSegmentIds: $scope.request.marketSegments && _.map($scope.request.marketSegments, 'id'),
        accessTypes: $scope.request.accessTypes && _.map($scope.request.accessTypes, 'id'),
      };
      if (!$scope.request.id) {
        SweetAlert.swal(
          {
            title: 'Do you wish to save the export criteria before downloading?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonClass: 'btn-warning',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            closeOnConfirm: true,
            closeOnCancel: true,
          },
          (isConfirm) => {
            if (isConfirm) {
              $scope.saveExportRequest().then(() => download(requestData));
            } else {
              download(requestData);
            }
          },
        );
      } else {
        download(requestData);
      }
    };

    $scope.saveExportRequest = (isNew) => {
      const reqData = angular.copy($scope.request);
      let orgs = [];
      if (reqData.organizations.length) {
        orgs = reqData.organizations;
        reqData.orgsType = orgTypes.active;
      } else if (reqData.inactiveOrganizations.length) {
        orgs = reqData.inactiveOrganizations;
        reqData.orgsType = orgTypes.inactive;
      }
      reqData.serviceType = _.find($scope.availableServices, { value: $scope.request.serviceType });
      $scope.isSaving = true;
      if (reqData.id && !isNew) {
        const query = {
          marketSegments: reqData.marketSegments && _.map(reqData.marketSegments, 'id'),
          accessTypes: reqData.accessTypes && _.map(reqData.accessTypes, 'id'),
          organisations: _.map(orgs, 'id'),
          selectedFields: _.uniqBy(reqData.selectedFields || [], 'code'),
          serviceType: reqData.serviceType,
          orgsType: reqData.orgsType,
        };
        query.dateRange = {
          startDate: reqData.dateRange.startDate.toDate().getTime(),
          endDate: reqData.dateRange.endDate.toDate().getTime(),
        };
        const data = {
          name: $scope.request.name,
          query,
        };
        return $http
          .put(`${API_BASE_URL}saved_exports/${reqData.id}`, data)
          .then(() => {
            $scope.$broadcast('refreshSavedExports');
          })
          .finally(() => {
            $scope.isSaving = false;
            resetForm();
          });
      }
      // Delete id and name in case its 'Save as New'
      if (isNew) {
        delete reqData.id;
        delete reqData.name;
      }
      const modalInstance = $uibModal.open({
        template: require('../sub-modules/saved-exports/save-new-export-req.html'),
        controller: 'SaveNewExportReqCtrl',
        backdrop: 'static',
        windowTopClass: 'inmodal',
        resolve: {
          queryInfo: reqData,
        },
      });

      return modalInstance.result.then(
        () => {
          $scope.$broadcast('refreshSavedExports');
          $scope.isSaving = false;
          resetForm();
        },
        () => {
          $scope.isSaving = false;
          return $q.reject();
        },
      );
    };

    init();

    // controller code for drag-drop-dual-list #start
    function arrayObjectIndexOf(myArray, searchTerm, property) {
      for (let i = 0, len = myArray.length; i < len; i += 1) {
        if (myArray[i][property] === searchTerm) {
          return i;
        }
      }
      return -1;
    }

    $scope.selectField = (list, selected, user) => {
      Object.assign(user, {
        checked: !user.checked,
      });
      if (user.checked) {
        selected.push(user.code);
      } else {
        const selectedId = selected.indexOf(user.code);
        if (selectedId > -1) {
          selected.splice(selectedId, 1);
        }
      }
    };

    function resetA() {
      $scope.selectedA = [];
      angular.forEach($scope.listA, (element) => {
        Object.assign(element, uncheck);
      });
      $scope.checkedA = false;
    }

    function resetB() {
      $scope.selectedB = [];
      angular.forEach($scope.listB, (element) => {
        Object.assign(element, uncheck);
      });
      $scope.checkedB = false;
    }

    $scope.aToB = (dropId, fromList) => {
      if (fromList === 'B') {
        Object.keys($scope.selectedB).forEach((i) => {
          const moveId = arrayObjectIndexOf($scope.items, $scope.selectedB[i], 'code');
          if (moveId <= -1) {
            return;
          }
          if (dropId) {
            const dropTo = arrayObjectIndexOf($scope.listB, dropId, 'code');
            const delId = arrayObjectIndexOf($scope.listB, $scope.selectedB[i], 'code');
            $scope.listB.splice(delId, 1);
            $scope.listB.splice(dropTo, 0, $scope.items[moveId]);
          } else {
            const delId = arrayObjectIndexOf($scope.listB, $scope.selectedB[i], 'code');
            $scope.listB.splice(delId, 1);
            $scope.listB.push($scope.items[moveId]);
          }
        });
        resetA();
        resetB();
        return;
      }

      const selectedAtemp = angular.copy($scope.selectedA);
      Object.keys($scope.selectedA).forEach((i) => {
        const moveId = arrayObjectIndexOf($scope.items, $scope.selectedA[i], 'code');
        if (moveId <= -1) {
          return;
        }
        const text = $scope.items[moveId].text.toLowerCase();

        const re = new RegExp(`^${$scope.searchItemsA.toLowerCase()}`);
        if (re.test(text) || text.indexOf($scope.searchItemsA.toLowerCase()) !== -1) {
          if (dropId) {
            const dropTo = arrayObjectIndexOf($scope.listB, dropId, 'code');
            $scope.listB.splice(dropTo, 0, $scope.items[moveId]);
          } else {
            $scope.listB.push($scope.items[moveId]);
          }

          const delId = arrayObjectIndexOf($scope.listA, $scope.selectedA[i], 'code');
          const delFromSelected = arrayObjectIndexOf(selectedAtemp, $scope.selectedA[i], 'code');
          $scope.listA.splice(delId, 1);
          selectedAtemp.splice(delFromSelected, 1);
        }
      });
      $scope.selectedA = angular.copy(selectedAtemp);
      resetB();
    };

    $scope.bToA = (dropId, fromList) => {
      if (fromList === 'A') {
        Object.keys($scope.selectedA).forEach((i) => {
          const moveId = arrayObjectIndexOf($scope.items, $scope.selectedA[i], 'code');
          if (moveId <= -1) {
            return;
          }
          if (dropId) {
            const dropTo = arrayObjectIndexOf($scope.listA, dropId, 'code');
            const delId = arrayObjectIndexOf($scope.listA, $scope.selectedA[i], 'code');
            $scope.listA.splice(delId, 1);
            $scope.listA.splice(dropTo, 0, $scope.items[moveId]);
          } else {
            const delId = arrayObjectIndexOf($scope.listA, $scope.selectedA[i], 'code');
            $scope.listA.splice(delId, 1);
            $scope.listA.push($scope.items[moveId]);
          }
        });
        resetB();
        resetA();
        return;
      }

      const selectedBtemp = angular.copy($scope.selectedB);

      Object.keys($scope.selectedB).forEach((i) => {
        const moveId = arrayObjectIndexOf($scope.items, $scope.selectedB[i], 'code');

        if (moveId <= -1) {
          return;
        }

        const text = $scope.items[moveId].text.toLowerCase();

        const re = new RegExp(`^${$scope.searchItemsB.toLowerCase()}`);
        if (re.test(text) || text.indexOf($scope.searchItemsB.toLowerCase()) !== -1) {
          if (dropId) {
            const dropTo = arrayObjectIndexOf($scope.listA, dropId, 'code');
            $scope.listA.splice(dropTo, 0, $scope.items[moveId]);
          } else {
            $scope.listA.push($scope.items[moveId]);
          }

          const delId = arrayObjectIndexOf($scope.listB, $scope.selectedB[i], 'code');
          const delFromSelected = arrayObjectIndexOf(selectedBtemp, $scope.selectedB[i], 'code');
          $scope.listB.splice(delId, 1);
          selectedBtemp.splice(delFromSelected, 1);
        }
      });
      $scope.selectedB = angular.copy(selectedBtemp);
      resetA();
    };

    $scope.toggleA = () => {
      if ($scope.selectedA.length > 0) {
        $scope.selectedA = [];
      }
      $scope.checkedA = !$scope.checkedA;
      if ($scope.checkedA) {
        Object.keys($scope.listA).forEach((i) => {
          $scope.listA[i].checked = true;
          $scope.selectedA.push($scope.listA[i].code);
        });
      } else {
        Object.keys($scope.listA).forEach((i) => {
          $scope.listA[i].checked = false;
        });
      }
    };

    $scope.toggleB = () => {
      if ($scope.selectedB.length > 0) {
        $scope.selectedB = [];
      }
      $scope.checkedB = !$scope.checkedB;
      if ($scope.checkedB) {
        Object.keys($scope.listB).forEach((i) => {
          $scope.listB[i].checked = true;
          $scope.selectedB.push($scope.listB[i].code);
        });
      } else {
        Object.keys($scope.listB).forEach((i) => {
          $scope.listB[i].checked = false;
        });
      }
    };

    $scope.drop = (dropEl, fromList, direction) => {
      const drop = angular.element(document.getElementById(dropEl));
      const dropId = drop.attr('data-id');
      direction(dropId, fromList);
      $scope.$digest();
    };

    $scope.clearAll = () => {
      resetForm();
    };

    jQuery(document).ready(() => {
      jQuery.event.addProp('dataTransfer'); // prevent conflict with drag-drop
    });

    listeners.push(
      $scope.$watchCollection('listB', (n, o) => {
        if (n !== o && $scope.request && $scope.request.selectedFields !== n) {
          $scope.request.selectedFields = n;
        }
      }),
    );

    listeners.push(
      $scope.$on('exportSelected', (evt, exportReq) => {
        if (!exportReq.query) {
          return;
        }
        $scope.clearAll();
        $scope.request = {
          id: exportReq.id,
          name: exportReq.name,
          marketSegments: [],
          accessTypes: [],
          organizations: [],
          inactiveOrganizations: [],
        };
        $scope.savedExportSel = exportReq.query;
        if (exportReq.query.dateRange) {
          $scope.request.dateRange = {
            startDate: moment(new Date(exportReq.query.dateRange.startDate)),
            endDate: moment(new Date(exportReq.query.dateRange.endDate)),
          };
        } else {
          initDateRange();
        }
        angular.forEach(exportReq.query.marketSegments, (id) => {
          const ms = _.find($scope.marketSegmentList, { id });
          if (ms) {
            $scope.request.marketSegments.push(ms);
          }
        });
        angular.forEach(exportReq.query.accessTypes, (id) => {
          const at = _.find($scope.accessTypeList, { id });
          if (at) {
            $scope.request.accessTypes.push(at);
          }
        });
        $scope.onMarketSegmentAndAccessTypeChange();
        const orgs = [];
        _.forEach(exportReq.query.organisations, (id) => {
          orgs.push({ id });
        });
        if (exportReq.query.orgsType === orgTypes.inactive) {
          $scope.request.inactiveOrganizations = orgs;
        } else {
          $scope.request.organizations = orgs;
        }
        $scope.refreshServiceTypes();
      }),
    );

    $scope.$on('$destroy', () => {
      angular.forEach(listeners, (fn) => {
        fn();
      });
    });
  },
]);
