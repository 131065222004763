angular.module('relayHealth').controller('tagsListCtrl', [
  '$scope',
  '$http',
  '$filter',
  'API_BASE_URL',
  'loadingScreenFactory',
  '$uibModalInstance',
  'parentFunctionCall',
  'patient_details',
  'for_patient',
  'orgId',
  function tagsListCtrl(
    $scope,
    $http,
    $filter,
    API_BASE_URL,
    loadingScreenFactory,
    $uibModalInstance,
    parentFunctionCall,
    patientDetails,
    forPatient,
    orgId,
  ) {
    loadingScreenFactory.showLoadingScreen();

    $scope.tagsList = [];
    $scope.userAddressList = [];
    $scope.organisationAddressList = [];

    function getTagsList() {
      let getTagsListCall = '';
      if (forPatient) {
        if (patientDetails && (patientDetails.patient_id || (patientDetails.first_name && patientDetails.last_name && patientDetails.phone))) {
          if (patientDetails.patient_id) {
            getTagsListCall = $http.get(`${API_BASE_URL}useraddress?for_patient=true&patient_id=${patientDetails.patient_id}&org_id=${orgId}`);
          } else {
            getTagsListCall = $http.get(`${API_BASE_URL}useraddress?org_id=${orgId}`, {
              params: {
                for_patient: true,
                first_name: patientDetails.first_name,
                last_name: patientDetails.last_name,
                phone: patientDetails.phone,
              },
            });
          }
        } else {
          getTagsListCall = $http.get(`${API_BASE_URL}useraddress?for_patient=true&patient_id=0&org_id=${orgId}`);
        }
      } else {
        getTagsListCall = $http.get(`${API_BASE_URL}useraddress?org_id=${orgId}`);
      }
      getTagsListCall
        .then((result) => {
          if (result.data.success) {
            $scope.tagsList = result.data.data;
            $scope.userAddressList = $filter('filter')($scope.tagsList, { address_type: 'User' });
            $scope.organisationAddressList = $filter('filter')($scope.tagsList, { address_type: 'org' });
          }
          loadingScreenFactory.hideLoadingScreen();
        })
        .catch(() => {
          loadingScreenFactory.hideLoadingScreen();
        });
    }
    getTagsList();
    $scope.closePopup = () => {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.selectAddress = (address) => {
      parentFunctionCall(address);
      $scope.closePopup();
    };
  },
]);
