const ROLES = relaylib.common.constants.userRoles;
angular.module('relayHealth').controller('commonCtrl', [
  '$scope',
  '$rootScope',
  '$http',
  'API_BASE_URL',
  '$state',
  'toaster',
  '$uibModal',
  'Pubnub',
  '$filter',
  'pubnubNotificationFactory',
  '$document',
  '$timeout',
  'NgMap',
  '$sce',
  'DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID',
  'USER_GUIDE_URL',
  'orgHierarchyFactory',
  '$window',
  'SITE_NAME',
  'messageCenterService',
  '$transitions',
  'oldDashboardCheck',
  'showBillingTOS',
  'permissionsManager',
  function commonCtrl(
    $scope,
    $rootScope,
    $http,
    API_BASE_URL,
    $state,
    toaster,
    $uibModal,
    Pubnub,
    $filter,
    pubnubNotificationFactory,
    $document,
    $timeout,
    NgMap,
    $sce,
    DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID,
    USER_GUIDE_URL,
    orgHierarchyFactory,
    $window,
    SITE_NAME,
    messageCenterService,

    $transitions,
    oldDashboardCheck,
    showBillingTOS,
    permissionsManager,
  ) {
    $scope.permissionsConstants = relaylib.permissions.permissionsConstants;

    let userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const defalultVisibleOrganizationCount = 20;
    $scope.visibleChoices = defalultVisibleOrganizationCount;

    $rootScope.$on('userLogin', (event, data) => {
      userDetails = data;
      $('#side-menu').metisMenu('dispose');
      permissionsManager.init(userDetails.permissions);
      Object.assign($rootScope, { userDetails });
      showBillingTOS(userDetails.first_time_visit, $scope, $state);
      $timeout(() => {
        $('#side-menu').metisMenu();
      });
    });
    if (userDetails) {
      Object.assign($rootScope, {
        $state,
        userDetails,
        occmId: DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID,
      });
      // function call here

      showBillingTOS(userDetails.first_time_visit, $scope, $state);
    } else {
      $state.go('login');
      return;
    }

    $scope.orgHierarchyFactory = orgHierarchyFactory;

    $scope.userGuideUrl = USER_GUIDE_URL;
    $scope.org = {
      selectView: '',
    };
    let markers = [];
    if (userDetails.role !== ROLES.PROVIDER_MANAGER) {
      if (!orgHierarchyFactory.request) {
        orgHierarchyFactory.getOrganistionChildren();
      }
      orgHierarchyFactory.request.then(() => {
        $scope.org.selectView = userDetails.hierarchySelected;
      });
    }

    let userSettings;
    function getUserSettings() {
      userDetails = JSON.parse(localStorage.getItem('userDetails'));
      const headers = {
        username: userDetails.username,
        usertoken: userDetails.user_token,
        'Content-Type': 'application/json',
      };
      const request = $http.get(`${API_BASE_URL}user/settings`, { headers });

      request.then(({ data: objSuccess }) => {
        userSettings = objSuccess.data.settings;
      });
    }
    getUserSettings();

    function setMapOnAll(map) {
      for (let i = 0; i < markers.length; i += 1) {
        markers[i].setMap(map);
      }
    }

    function deleteMarkers() {
      setMapOnAll(null);
      markers = [];
    }

    $scope.rootClick = function rootClick() {
      $rootScope.$broadcast('rootClick');
    };

    $scope.logout = function logout() {
      Object.assign($document[0], { title: `${SITE_NAME} | Login` });
      $rootScope.$broadcast('removeClickHandler');
      $scope.$emit('logout');
      pubnubNotificationFactory.unsubscribeAllChannel();
      $http.put(`${API_BASE_URL}user/logout`, {})
        .then(({ data }) => {
          if (data.success) {
            localStorage.clear();
            if (data.logoutUrl) {
              Object.assign($window.location, { href: data.logoutUrl });
            } else {
              Object.assign($window.location, { href: `/${process.env.PUBLIC_URL_PREFIX}current/login` });
            }
            return $window;
          }
          toaster.pop({
            type: 'error',
            title: data.message,
            showCloseButton: true,
            timeout: 10000,
          });
          return true;
        })
        .catch(({ data }) => {
          toaster.pop({
            type: 'error',
            title: data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        });
    };

    $transitions.onStart({}, (transition) => {
      const toState = transition.to();
      if (toState.name === 'main.dashboard.superAdminDashboard') {
        const user = JSON.parse(localStorage.getItem('userDetails'));
        if (!user) {
          return;
        }
        if (user.organisation && user.organisation.childrenCount && user.organisation.childrenCount > 0) {
          $state.go('main.dashboard.parentOrgDashboard');
        }
      } else if (toState.name === 'main.dashboard.createAppointment') {
        const user = JSON.parse(localStorage.getItem('userDetails'));
        if (user.organisation && user.organisation.childrenCount && user.organisation.childrenCount > 0 && oldDashboardCheck.check()) {
          $state.go('main.dashboard.parentOrgDashboard');
        }
      }
    });

    $scope.openCorrespondingPopup = function openCorrespondingPopup(notification) {
      const request = $http.get(`${API_BASE_URL}appointments/${notification.appt_id}`);
      if (notification.appt_status === 'booked' && notification.type === 'surge_applicable') {
        $scope.surgeHref = notification.surge_confirmation_href;
      } else if (notification.appt_status === 'booked' && (notification.booking_status === 0 || !notification.booking_status)) {
        request.then(
          (response) => {
            const { data } = response;
            if (data.success) $scope.confirmAppointment(data.data);
          },
          () => {
          },
        );
      } else {
        request.then(
          (response) => {
            const { data } = response;
            if (data.success) {
              const temp = data.data;
              const event = angular.copy(temp);
              event.start = parseInt(event.appt_date_time, 10) * 1000;
              event.end = parseInt(event.appt_end_time, 10) * 1000;
              $scope.alertOnEventClick(event);
            }
          },
          () => {
          },
        );
      }
    };

    $scope.confirmAppointment = function confirmAppointment(appointment) {
      const patientData = angular.copy(appointment.patient);
      patientData.id = appointment.patient_id;
      const modalInstance = $uibModal.open({
        animation: true,
        template: require('../views/new-appointment.html'),
        controller: 'newAppointmentCtrl',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          day() {
            return new Date();
          },
          appointment() {
            return appointment;
          },
          patient() {
            return patientData || null;
          },
          updateBtnText() {
            return 'Confirm';
          },
        },
      });
      Object.assign($rootScope, { modal: modalInstance });// $rootScope.modal = modalInstance;
    };

    const vm = this;
    $scope.alertOnEventClick = (event) => {
      $timeout(() => {
        NgMap.getMap({ id: 'dialogMap' }).then((map) => {
          vm.dialogMap = map;
          deleteMarkers();
          const sourcelatLong = new google.maps.LatLng(event.source_lat_long[0], event.source_lat_long[1]);
          markers.push(
            new google.maps.Marker({
              position: sourcelatLong,
              map: vm.dialogMap,
              label: 'S',
              icon: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
            }),
          );
          const destLatLong = new google.maps.LatLng(event.dest_lat_long[0], event.dest_lat_long[1]);
          markers.push(
            new google.maps.Marker({
              position: destLatLong,
              map: vm.dialogMap,
              label: 'D',
              // icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
            }),
          );
          google.maps.event.trigger(vm.dialogMap, 'resize');
          vm.dialogMap.setCenter(sourcelatLong);
          vm.dialogMap.setZoom(10);
          setMapOnAll(vm.dialogMap);
        });
      }, 1000);
      let modalInstance = $uibModal.open({
        animation: true,
        template: require('../views/schedulerDetails.html'),
        scope: $scope,
        controller: [
          '$scope',
          'event',
          function modal(scope, modelEvent) {
            Object.assign(scope, {
              event: modelEvent,
              cancel: function cancel() {
                modalInstance.dismiss('cancel');
                deleteMarkers();
              },
              editAppointment: function editAppointment(appt) {
                $scope.cancel();
                const patientData = angular.copy(appt.patient);
                modalInstance = $uibModal.open({
                  animation: true,
                  template: require('../views/new-appointment.html'),
                  controller: 'newAppointmentCtrl',
                  size: 'lg',
                  backdrop: 'static',
                  resolve: {
                    day() {
                      return null;
                    },
                    appointment() {
                      return angular.copy(appt);
                    },
                    patient() {
                      return patientData || null;
                    },
                    updateBtnText() {
                      return null;
                    },
                  },
                });
                Object.assign($rootScope, { modal: modalInstance });// $rootScope.modal = modalInstance;
              },
            });
          },
        ],
        resolve: {
          event() {
            return event;
          },
        },
        size: 'lg',
      });
    };

    const listenForPubnubMessages = () => {
      userDetails = JSON.parse(localStorage.getItem('userDetails'));
      $rootScope.$on(Pubnub.getMessageEventNameFor(userDetails.user_id), (event, payload) => {
        if (payload.message.type === 'User Locked') {
          $scope.logout();
          return;
        }
        if (payload.message.type === 'fileReady') {
          if (!payload.message.error) {
            toaster.pop({
              type: 'success',
              title: `Your file is now available (${payload.message.fileName}).`,
              showCloseButton: true,
              timeout: 10000,
            });
          } else {
            toaster.pop({
              type: 'success',
              title: payload.message.errorMsg,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        } else if (payload.message.type === 'dispatchImportRequestProcessed') {
          if (payload.message.success) {
            toaster.pop({
              type: 'success',
              title: `Your file is processed (${payload.message.fileName}).`,
              showCloseButton: true,
              timeout: 10000,
            });
          } else {
            toaster.pop({
              type: 'error',
              title: `Your import request failed (${payload.message.fileName}).`,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        } else if (payload.message.type === 'issuesAssigned') {
          if (userSettings.admin_notifications) {
            const message = `You have ${payload.message.rideIds.length} new assigned ${payload.message.issueType === 'issues' ? 'issues' : 'alerts'}`;
            pubnubNotificationFactory.showDesktopNotification({ message });
          }
        }
        if (
          payload.message.ride_data
          && (payload.message.ride_data.ride_status === 'Pending Approval'
            || (payload.message.ride_data.ride_status === 'Pending Approval'
              || ['issue', 'alert'].includes(payload.message.ride_data.ride_category)))
          && payload.message.type !== 'Update Ride Manager'
        ) {
          $scope.notifications.push(payload.message);
          $scope.notifications.sort((obj1, obj2) => parseInt(obj2.current_timestamp, 10) - parseInt(obj1.current_timestamp, 10));
          $scope.notifications = $filter('limitTo')($scope.notifications, 10);
          $scope.unreadNotificationCount += 1;
          $scope.unreadNotificationCount = $scope.unreadNotificationCount <= 10 ? $scope.unreadNotificationCount : 10;
          $scope.$apply();
          if (userSettings.admin_notifications) {
            pubnubNotificationFactory.showDesktopNotification(payload.message);
          }
        }
        if (payload.message.type === 'lyft_error') {
          toaster.pop({
            type: 'error',
            title: payload.message.message,
            showCloseButton: true,
            timeout: 0, // Setting timeout to indefinite time for lyft error toasters
          });
        }

        if (payload.message.type === 'Special Ride') {
          if (userSettings.admin_notifications) {
            pubnubNotificationFactory.showDesktopNotification(payload.message);
          }
        }

        if (payload.message.type === 'notification') {
          messageCenterService.incrementUnreadCount();
        }
      });
    };

    $scope.round = (paramValue, paramExp) => {
      let value = paramValue;
      let exp = paramExp;
      if (typeof exp === 'undefined' || +exp === 0) return Math.round(value);

      value = +value;
      exp = +exp;

      if (Number.isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) return NaN;

      // Shift
      value = value.toString().split('e');
      value = Math.round(+`${value[0]}e${value[1] ? +value[1] + exp : exp}`);

      // Shift back
      value = value.toString().split('e');
      return +`${value[0]}e${value[1] ? +value[1] - exp : -exp}`;
    };

    const init = () => {
      $scope.messages = [];
      $scope.unreadMessageCount = 0;
      listenForPubnubMessages();
      $scope.notifications = [];
      $scope.unreadNotificationCount = 0;
      $scope.selectedOrg = userDetails.organisation;
    };
    init();

    let afterApprovalFunc;
    $scope.$on('approveSurgePricing', (event, args) => {
      $scope.surgeHref = args.higher_price_confirmation_url;
      afterApprovalFunc = args.afterApprovalFunction;
    });

    $scope.trustSrc = src => $sce.trustAsResourceUrl(src);

    $scope.closeIframe = () => {
      $scope.surgeHref = undefined;
      afterApprovalFunc();
    };

    $scope.decrementUnreadNotificationCount = () => {
      $scope.unreadNotificationCount -= 1;
    };

    $scope.$on('decrementUnreadNotificationCount', () => {
      $scope.decrementUnreadNotificationCount();
    });

    $scope.$on('openCorrespondingPopup', (event, ntfObj) => {
      $scope.openCorrespondingPopup(ntfObj);
    });

    $scope.minimizeNavbar = () => {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        $rootScope.$broadcast('minimizeNavbar');
      }
    };

    $scope.selectViewOptionSelected = organisationSelected => orgHierarchyFactory.switchOrganisation(organisationSelected);
    const findIndexOfSetting = (settingsArray, settingName) => {
      for (let i = 0; i < settingsArray.length; i += 1) {
        if (settingsArray[i].setting_name === settingName) return i;
      }
      return -1;
    };
    $scope.checkStratusAccess = () => {
      if (userDetails.organisation.settings && userDetails.organisation.settings.length) {
        const stratusSettingIndexInArray = findIndexOfSetting(userDetails.organisation.settings, 'stratus_access');

        if (stratusSettingIndexInArray !== -1 && userDetails.organisation.settings[stratusSettingIndexInArray].setting_value === 'true') {
          return true;
        }
        return false;
      }
      return false;
    };

    $scope.allowAddressLimitModule = false;
    async function allowAddressLimitModule() {
      let orgSettingList = ['allow_address_limit_module'];

      if (userDetails.organisation.settings && userDetails.organisation.settings.length) {
        const addressSettingIndexInArray = findIndexOfSetting(userDetails.organisation.settings, 'allow_address_limit_module');
        if (addressSettingIndexInArray !== -1 && (userDetails.organisation.settings[addressSettingIndexInArray].setting_value).toLowerCase() === 'true') {
          $scope.allowAddressLimitModule = true;
          return;
        }
      }
      if(userDetails.organisation.parent_id) {
        let requestUrl = `${API_BASE_URL}setting/feature-settings/${userDetails.organisation.parent_id}`;
        if (orgSettingList.length) {
          requestUrl += `/${orgSettingList.join(',')}`;
        }
        return $http
          .get(requestUrl, {})
          .then((response) => {
            $scope.allowAddressLimitModule = !!_.get(response, 'data.setting.allow_address_limit_module', false);
            return;
          })
          .catch(() => ({}));
      }
    }
    allowAddressLimitModule();

    $scope.changeCurrentOrganisation = (oldId, parentChange) => {
      const newId = parentChange ? $scope.userDetails.organisation.parent_id : $scope.userDetails.organisation.id;
      if (newId.toString() !== oldId.toString()) {
        $http
          .get(`${API_BASE_URL}user/switch_org/${newId}`)
          .then((result) => {
            const results = result;
            if (results.data.success && results.data.user && results.data.user.id) {
              results.data.user.hasPreviousInvoices = !_.isEmpty(results.data.user.organisation.rides_monthly_invoices);
              const orgIds = [];
              if (results.data.user.organisations) {
                angular.forEach(results.data.user.organisations, (value) => {
                  orgIds.push(value.id);
                });
              }
              results.data.user.orgIds = orgIds;
              const isInviteRiderSettingActive = results.data.user.organisation.settings.find(setting => setting.setting_name === 'invited_riders' && setting.setting_value.toLowerCase() === 'true');
              if (isInviteRiderSettingActive) {
                results.data.user.isInviteRiderSettingActive = true;
              }
              localStorage.setItem('userDetails', JSON.stringify(results.data.user));
              $timeout(() => {
                window.location.href = '/';
              });

              return true;
            }
            $scope.userDetails.organisation.id = Number(oldId);
            return toaster.pop({
              type: 'error',
              title: 'Error while switching Organization',
              showCloseButton: true,
              timeout: 10000,
            });
          })
          .catch((error) => {
            $scope.userDetails.organisation.id = Number(oldId);
            return toaster.pop({
              type: 'error',
              title: error.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          });
      }
    };

    $scope.showChildData = () => {
      if (!oldDashboardCheck.check() && userDetails.organisation.childrenCount) {
        return true;
      }
      return false;
    };
    $scope.showDiv = false;
    $scope.isOpenClose = (isOpen) => {
      $scope.showDiv = isOpen;
      $scope.visibleChoices = defalultVisibleOrganizationCount;
    };

    $scope.loadMoreChoices = () => {
      $scope.visibleChoices += defalultVisibleOrganizationCount;
    };
  },
]);
