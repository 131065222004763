import moment from 'moment';

angular
  .module('relayHealth')
  .controller('receiptDetailLogsCtrl', [
    '$scope',
    'API_BASE_URL',
    '$http',
    'loadingScreenFactory',
    '$uibModal',
    '$state',
    'Pubnub',
    'toaster',
    function receiptDetailLogsCtrl(
      $scope,
      API_BASE_URL,
      $http,
      loadingScreenFactory,
      $uibModal,
      $state,
      Pubnub,
      toaster,
    ) {
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.rideDetails = [];
      this.reasonCodes = [{
        index: 0,
        value: 'Select Reason Code',
      }];
      this.moment = moment;
      this.dateRangeExport = {
        startDate: null,
        endDate: null,
      };
      this.exportButtonText = 'Export';
      this.dateRangeOptions = {
        parentEl: '#current-app #page-wrapper',
      };
      const showToaster = (type, title) => {
        toaster.pop({
          type,
          title,
          showCloseButton: true,
          timeout: 10000,
        });
      };

      const getReasonCodes = async () => {
        try {
          const {
            data: {
              success,
              reasonCodes,
              message,
            },
          } = await $http.get(`${API_BASE_URL}receipt/reasoncode`);
          if (!success) {
            showToaster('error', message);
          } else {
            for (let reasonCodeIndex = 0; reasonCodeIndex < reasonCodes.length; reasonCodeIndex += 1) {
              this.reasonCodes.push({
                index: reasonCodeIndex + 1,
                value: reasonCodes[reasonCodeIndex],
              });
            }
          }
        } catch (e) {
          showToaster('error', e.message);
        }
      };
      getReasonCodes();

      const getLatestProcessedExportRecords = async () => {
        try {
          const { data: { success, data, message } } = await $http.get(`${API_BASE_URL}export/getLatestProcessedExportRecords/receipt_detail_logs`);
          if (success) {
            this.exportList = data;
          } else {
            showToaster('error', message);
          }
        } catch (e) {
          showToaster('error', e.message);
        }
      };
      getLatestProcessedExportRecords();

      const listenForPubnubMessages = () => {
        $scope.$on(Pubnub.getMessageEventNameFor(userDetails.user_id), (event, { message: { type } }) => {
          if (type === 'fileReady'
            && ($state.current.name === 'main.dashboard.logs.receiptDetailLogs')) {
            getLatestProcessedExportRecords();
          }
        });
      };
      listenForPubnubMessages();

      this.exportInDateRange = async () => {
        try {
          const url = `${API_BASE_URL}saveExportFilters`;
          this.exportButtonText = 'Processing';
          const { data: { success } } = await $http.post(url, {
            start_date: this.dateRangeExport.startDate.valueOf(),
            end_date: this.dateRangeExport.endDate.valueOf(),
            fileName: `receipt_detail_logs_${this.dateRangeExport.startDate.format('MM-DD-YYYY')}-${this.dateRangeExport.endDate.format('MM-DD-YYYY')}-${new Date().getTime()}_${userDetails.user_id}.csv`,
            page: 'receipt_detail_logs',
          });
          this.exportButtonText = 'Export';
          if (!success) {
            showToaster('error', 'Failed');
          } else {
            showToaster('info', 'Please wait for your file');
            getLatestProcessedExportRecords();
          }
        } catch (e) {
          showToaster('error', e.message);
        }
      };

      const initSearchForm = () => {
        this.pagination = {
          totalItems: 0,
          currentPage: 1,
          lastItem: 0,
          itemsPerPage: 10,
        };
        this.receiptDetailLogsQuery = {
          dateRange: {
            startDate: null,
            endDate: null,
          },
          patientName: null,
          reasonCode: this.reasonCodes[0],
        };
      };

      const getDate = (timeStamp, timezone) => `${moment(timeStamp).tz(timezone).format('MM/DD/YYYY HH:mm')} ${moment.tz(timezone).zoneAbbr()}`;

      const convertDateTime = (rideDetails) => {
        for (let rideDetailIndex = 0; rideDetailIndex < rideDetails.length; rideDetailIndex += 1) {
          const {
            ride_start_date_time: rideStartDateTime,
            departure_time: departureTime,
            book_cab_time: bookCabTime,
            appointment: {
              appt_timezone: timezone,
            },
          } = rideDetails[rideDetailIndex];
          if (!rideStartDateTime) {
            Object.assign(rideDetails[rideDetailIndex], {
              appt_date_time: '-',
            });
          } else {
            Object.assign(rideDetails[rideDetailIndex], {
              appt_date_time: getDate(rideStartDateTime * 1000, timezone),
            });
          }
          if (!departureTime) {
            Object.assign(rideDetails[rideDetailIndex], {
              departure_time: '-',
            });
          } else {
            Object.assign(rideDetails[rideDetailIndex], {
              departure_time: getDate(departureTime * 1000, timezone),
            });
          }
          if (!bookCabTime) {
            Object.assign(rideDetails[rideDetailIndex], {
              book_cab_time: '-',
            });
          } else {
            Object.assign(rideDetails[rideDetailIndex], {
              book_cab_time: getDate(bookCabTime * 1000, timezone),
            });
          }
        }
      };

      const adjustPagination = (totalCount) => {
        this.pagination.totalItems = totalCount;
        this.pagination.lastItem = this.pagination.currentPage * this.pagination.itemsPerPage;
        if (this.pagination.lastItem > totalCount) {
          this.pagination.lastItem = totalCount;
        }
      };

      const getRideDetails = async (
        offset,
        limit,
      ) => {
        try {
          loadingScreenFactory.showLoadingScreen();
          const params = {
            offset,
            limit,
            order: JSON.stringify([['createdAt', 'DESC']]),
            logType: 'receipt',
          };
          if (this.receiptDetailLogsQuery.patientName) {
            params.patient_name = this.receiptDetailLogsQuery.patientName;
          }
          if (this.receiptDetailLogsQuery.dateRange.startDate
            && this.receiptDetailLogsQuery.dateRange.endDate) {
            params.startDate = this.receiptDetailLogsQuery.dateRange.startDate.format('MM-DD-YYYY 00:00:00');
            params.endDate = this.receiptDetailLogsQuery.dateRange.endDate.format('MM-DD-YYYY 23:59:59');
          }
          if (this.receiptDetailLogsQuery.reasonCode.index) {
            params.reasonCode = this.receiptDetailLogsQuery.reasonCode.value;
          }
          const { data: { audit_logs: rideDetails, total_count: totalCount } } = await $http.get(`${API_BASE_URL}audit_logs`, { params });
          this.rideDetails = rideDetails;
          convertDateTime(rideDetails);
          adjustPagination(totalCount);
        } catch (e) {
          showToaster('error', e.data.message);
        } finally {
          loadingScreenFactory.hideLoadingScreen();
          $scope.$apply();
        }
      };

      this.filterReceiptDetailLogs = () => {
        getRideDetails(0, 10);
      };

      const init = async () => {
        initSearchForm();
        getRideDetails((this.pagination.currentPage - 1), 10);
      };
      init();

      this.pageChanged = () => {
        getRideDetails((this.pagination.currentPage - 1), 10);
      };

      this.showMeta = (metaLog) => {
        const modalInstance = $uibModal.open({
          animation: true,
          template: require('../log-details.html'),
          size: 'lg',
          backdrop: 'static',
          resolve: {
            meta() {
              return angular.copy(metaLog);
            },
          },
          controllerAs: 'logDetailsCtrl',
          controller: [
            'meta',
            '$uibModalInstance',
            function modalCtrl(meta, $uibModalInstance) {
              Object.assign(this, {
                meta,
                closePopup() {
                  $uibModalInstance.close();
                },
              });
            },
          ],
        });
        $scope.$on('$destroy', () => {
          modalInstance.close();
        });
      };

      const formatDistanceAndCost = (newReceiptDetails) => {
        if (newReceiptDetails) {
          const { final_ride_cost: finalRideCost, distance } = newReceiptDetails;
          if (finalRideCost && !Number.isNaN(finalRideCost)) {
            Object.assign(newReceiptDetails, {
              final_ride_cost: Number(newReceiptDetails.final_ride_cost).toFixed(3),
            });
          }
          if (distance && !Number.isNaN(distance)) {
            Object.assign(newReceiptDetails, {
              distance: Number(newReceiptDetails.distance).toFixed(3),
            });
          }
        }
      };

      this.getReceiptDetailLogs = async (index, rideDetail) => {
        if (rideDetail.receiptDetailLogs) {
          Object.assign(rideDetail, {
            receiptDetailLogs: null,
          });
          return;
        }
        try {
          loadingScreenFactory.showLoadingScreen();
          const params = {
            rideId: rideDetail.id,
          };
          const { data: { success, message, hits } } = await $http.get(`${API_BASE_URL}auditLogs/receipt`, { params });
          if (!success) {
            showToaster('error', message);
          } else {
            for (let rideDetailIndex = 0; rideDetailIndex < this.rideDetails.length; rideDetailIndex += 1) {
              delete this.rideDetails[rideDetailIndex].receiptDetailLogs;
            }

            for (let logIndex = 0; logIndex < hits.length; logIndex += 1) {
              hits[logIndex].source.loggedAt = getDate(hits[logIndex].source.loggedAt, rideDetail.appointment.appt_timezone);
              formatDistanceAndCost(hits[logIndex].source.meta.receiptLogDetails.newReceiptDetails);
            }
            this.rideDetails[index].receiptDetailLogs = hits;
          }
        } catch (e) {
          showToaster('error', e.message);
        } finally {
          loadingScreenFactory.hideLoadingScreen();
          $scope.$apply();
        }
      };

      this.clearFilters = () => {
        initSearchForm();
        getRideDetails(0, 10);
      };

      this.searchOnEnter = (event) => {
        if (
          event.keyCode === 13
          && !this.receiptDetailLogsForm.$invalid) {
          this.pagination.currentPage = 1;
          getRideDetails(0, 10);
        }
      };
    },
  ]);
