function signupNavigationCtrl(
  $scope,
  signupStateService,
  $state,
  logoutService,
) {
  // navigationValidationService
  const nav = this;
  $scope.menuItems = [];
  $scope.latestStage = '';
  $scope.currentPage = '';

  nav.$onInit = function onInit() {
    try {
      let signupState = localStorage.getItem('signupState');
      signupState = JSON.parse(signupState);
      $scope.latestStage = signupState.latestStage;
      $scope.currentPage = signupState.currentPage;
      if (
        signupState.userType === 'tempBusiness'
        && !signupState.created_by_user
      ) {
        $scope.menuItems = [
          { name: 'Terms of Service', number: 3 },
          { name: 'Organizational Information', number: 4 },
          { name: 'Add Users', number: 5 },
          { name: 'Payment', number: 6 },
          { name: 'Summary', number: 7 },
        ];
      } else if (signupState.userType === 'tempRider') {
        $scope.menuItems = [
          { name: 'Terms of Service', number: 3 },
          { name: 'Account Information', number: 4 },
          { name: 'Add Users', number: 5 },
          { name: 'Payment', number: 6 },
          { name: 'Summary', number: 7 },
        ];
      } else {
        $scope.menuItems = [
          { name: 'Terms of Service', number: 3 },
          { name: 'Organizational Information', number: 4 },
          { name: 'Add Users', number: 5 },
          { name: 'Summary', number: 7 },
        ];
      }
    } catch (error) {
      // showToaster('error', 'Some error occured');
    }
  };

  this.onMenuItemClicked = function onMenuItemClicked(event) {
    const element = event.currentTarget;
    const step = element.getAttribute('data-step');
    const state = signupStateService.getState('signupState');
    if (state.latestStage < parseInt(step, 10)) {
      return;
    }
    if (state.signupType === 'rider') {
      switch (parseInt(step, 10)) {
        case 3:
          $scope.currentPage = 3;
          $state.go('main.rider_signup_terms_of_service');
          return;
        case 4:
          $scope.currentPage = 4;
          $state.go('main.rider_signup_account_information');
          return;
        case 5:
          $scope.currentPage = 5;
          $state.go('main.rider_signup_add_users');
          return;
        case 6:
          $scope.currentPage = 6;
          $state.go('main.rider_signup_payment');
          return;
        case 7:
          $scope.currentPage = 7;
          $state.go('main.rider_signup_summary');
          return;
        default:
          logoutService();
      }
    } else if (state.signupType === 'business') {
      switch (parseInt(step, 10)) {
        case 3:
          $scope.currentPage = 3;
          $state.go('main.business_signup_terms_of_service');
          return;
        case 4:
          $scope.currentPage = 4;
          $state.go('main.business_signup_add_organization');
          return;
        case 5:
          $scope.currentPage = 5;
          $state.go('main.business_signup_add_users');
          return;
        case 6:
          if (!state.created_by_user) {
            $scope.currentPage = 6;
            $state.go('main.business_signup_payment');
            return;
          }
          $scope.currentPage = 7;
          $state.go('main.business_signup_summary');
          return;

        case 7:
          $scope.currentPage = 7;
          $state.go('main.business_signup_summary');
          return;
        default:
          logoutService();
      }
    }
    // check if the menu item clicked is the current item.
    // if yes then do nothing
    // if the navigation is in backward direction then allow redirection
    // if the navigation is in the forward direction then check validations in the
    // current menu item and before the selected menu item, if there are no errors then
    // allow redirection else redirect to the first screen with validation errors
    // also show the error messages.

    // also remember to set the active page, previous page and latest page value.
  };
}
angular.module('relayHealth').component('signupNavigation', {
  template: require('../views/signup/navigation.html'),
  bindings: {
    onMenuItemClicked: '&',
  },
  controller: [
    '$scope',
    'signupStateService',
    '$state',
    'logoutService',
    signupNavigationCtrl,
  ],
});
