import { navigateToUrl } from 'single-spa';

angular
  .module('relayHealth')
  .service('logoutService', [
    '$document',
    '$rootScope',
    'pubnubNotificationFactory',
    'permissionFilter',
    '$http',
    'API_BASE_URL',
    '$window',
    'toaster',
    'SITE_NAME',
    ($document, $rootScope, pubnubNotificationFactory, $http, API_BASE_URL, $window, toaster, SITE_NAME) => () => {
      $document[0].title = `${SITE_NAME} | Login`;
      $rootScope.$broadcast('removeClickHandler');
      pubnubNotificationFactory.unsubscribeAllChannel();
      const res = $http.put(`${API_BASE_URL}user/logout`, {});
      res.then(({ data: { success, message } }) => {
        if (success) {
          localStorage.clear();
          navigateToUrl(`/${process.env.PUBLIC_URL_PREFIX}current/login`);
          return;
        }
        toaster.pop({
          type: 'error',
          title: message,
          showCloseButton: true,
          timeout: 10000,
        });
      });
      res.catch(() => {
        toaster.pop({
          type: 'error',
          title: 'Not able to logout',
          showCloseButton: true,
          timeout: 10000,
        });
      });
    },
  ])
  .service('isOCCMRole', () => () => {
    const { userRoles } = relaylib.common.constants;
    let userDetails = localStorage.getItem('userDetails');
    userDetails = userDetails && JSON.parse(userDetails);
    const checkRoles = [
      userRoles.MASTER_SUPER_ADMIN,
      userRoles.SUPER_ADMIN,
      userRoles.RIDE_MANAGER,
      userRoles.SCHEDULING_ADMIN,
      userRoles.PRODUCTION_OPERATION,
      userRoles.NETWORK_OPERATION,
      userRoles.PROVIDER_MANAGER,
      userRoles.OC_REPORTING,
    ];
    return checkRoles.includes(userDetails.role);
  })
  .service('getMarketSegments', [
    '$http',
    'API_BASE_URL',
    ($http, API_BASE_URL) => {

      let marketSegments;
      return (callback, params) => {
        if (marketSegments) {
          callback(undefined, marketSegments);
        } else {
          marketSegments = null;
          const getMarketSegmentsCall = $http({
            url: `${API_BASE_URL}marketSegments`,
            method: 'GET',
            params
          });
          getMarketSegmentsCall.then(
            (result) => {
              if (result.data.success) {
                marketSegments = result.data.data;
                callback(undefined, result.data.data);
              } else {
                callback(result.data.message);
              }
            },
            (error) => {
              if (error) {
                return callback((error.data && error.data.message) || error.message || error);
              }
              return callback(true);
            },
          );
        }
      };
    },
  ])
  .service(
    'getMarketSegementSetting',
    () => function getMarketSegementSetting(market_segment, setting_name, type='string') {
      const setting = _.find(_.get(market_segment, 'settings', []), {
        setting_name,
        owner_type: 'market_segment',
      });
      if (setting && setting.setting_value !== null) {
        if (type === 'bool') {
          return String(setting.setting_value).toLowerCase() === 'true';
        }
        if (type === 'int') {
          return +setting.setting_value || 0;
        }
        if (type === 'json' && setting.setting_value.length) {
          return JSON.parse(setting.setting_value);
        }
        if (type === 'string') {
          return setting.setting_value;
        }
      }
      return undefined;
    },
  )
  .service('checkAndClearETA', () => {
    const validStatusesForETA = ['Driver Confirmed', 'Driver Arrived', 'Patient Enroute', 'Late Arrival', 'Arrival Time Exceeded'];
    return function (ride) {
      if (ride && ride.ride_status && validStatusesForETA.indexOf(ride.ride_status) < 0) {
        ride.driver_eta = null;
        ride.redis_driver_eta = null;
      }
    };
  })
  .service('updateSignupStep', [
    '$http',
    'API_BASE_URL',
    'signupStateService',
    '$state',
    function ($http, API_BASE_URL, signupStateService, $state) {
      return function updateSignupStep(step) {
        if (!step) {
          return false;
        }
        const data = { step };
        const state = signupStateService.getState('signupState');
        const userDetails = signupStateService.getState('userDetails');
        const headers = {
          usertoken: state.usertoken,
          username: state.email,
          'Content-Type': 'application/json',
        };
        $http
          .put(`${API_BASE_URL}rider/step`, data, headers)
          .then(({ data, success, headers, config }) => {
            state.step = step;
            userDetails.step = step;
            signupStateService.setState('signupState', state);
            signupStateService.setState('userDetails', userDetails);
          })
          .catch(({ data, success, headers, config }) => { });
      };
    },
  ])
  .service('signupNavigationValidationService', [
    'signupStateService',
    'WORKERS_COMPENSATION_ID',
    'HEALTHCARE_MASS_MARKET_KEY',
    function signupNavigationValidationService(signupStateService, WORKERS_COMPENSATION_ID, HEALTHCARE_MASS_MARKET_KEY) {
      this.termsOfServiceValidation = function (cb) {
        const errors = {};
        const state = signupStateService.getState('signupState');
        if (!state.acceptTc) {
          errors.acceptTc = 'Please Select Terms and Condtions';
        }
        return cb(errors);
      };

      this.accountInfoValidation = function (cb) {
        const errors = {};
        const state = signupStateService.getState('signupState');
        const accountInfo = state.accountInfo;
        if (!accountInfo.address1) {
          errors.address1 = 'Please provide Address value';
        }
        if (accountInfo.address1 && accountInfo.address1.length > 200) {
          errors.address1 = 'Address cannot contain more than 200 characters';
        }

        if (!accountInfo.city) {
          errors.city = 'City is required';
        }

        if (accountInfo.city && !accountInfo.city.length > 100) {
          errors.city = 'City should not contain more than 100 characters';
        }

        if (accountInfo.city && !accountInfo.city.match(/^[a-zA-Z'. -]*$/)) {
          errors.city = 'Numeric and special characters are not allowed';
        }

        if (!accountInfo.state) {
          errors.state = 'State is required';
        }

        if (!accountInfo.zip) {
          errors.zip = 'Zip Code is required';
        }

        return cb(errors);
      };

      this.organizationValidation = function (cb) {
        const errors = {};
        const state = signupStateService.getState('signupState');
        const businessInfo = state.businessInfo;
        if (!businessInfo.orgId) {
          errors.orgId = 'Org Id is required';
        }

        if (!businessInfo.orgName) {
          errors.name = 'Organization Name is required';
        }

        if (businessInfo.orgName && businessInfo.orgName.length > 100) {
          errors.name = 'Organization Name should contain less than 100 characters';
        }
        if (!businessInfo.completeAddress) {
          errors.name = 'Organization Address is required';
        }

        if (!businessInfo.TIN && businessInfo.market_segment_id != WORKERS_COMPENSATION_ID && businessInfo.market_segment_key != HEALTHCARE_MASS_MARKET_KEY) {
          errors.tin = 'TIN is required';
        }

        if (!businessInfo.industry) {
          errors.industry = 'Industry Type is required';
        }
        return cb(errors);
      };

      this.addUsers = function (cb) {
        const errors = {};
        const tempUser = signupStateService.getState('tempUser');

        if (tempUser) {
          if (tempUser.first_name) {
            if (tempUser.first_name.length > 100) {
              errors.first_name = 'First Name should contain less than 100 characters';
            }
            if (!tempUser.first_name.match(/^[a-zA-Z'. -]*$/)) {
              errors.first_name = 'Numeric and special characters are not allowed';
            }
          }

          if (tempUser.last_name) {
            if (tempUser.last_name.length > 100) {
              errors.last_name = 'Last Name should contain less than 100 characters';
            }
          }

          if (tempUser.email) {
            if (tempUser.first_name.length > 100) {
              errors.first_name = 'email Name should contain less than 100 characters';
            }
          }
        }
        return cb(errors);
      };

      this.paymentValidation = function (cb) {
        const errors = {};
        const signupState = signupStateService.getState('signupState');
        if (!signupState.stripeCardAdded && !signupState.monthlyInvoice) {
          errors.paymentResponseError = 'Please Add Card Details';
        }

        if (signupState.userType === 'tempRider' && !signupState.stripeCardAdded) {
          errors.paymentResponseError = 'Please Add Card Details';
        }

        if (signupState.userType === 'tempBusiness' && (!signupState.stripeCardAdded && !signupState.monthlyInvoice && !signupState.created_by_user)) {
          errors.paymentResponseError = 'Please Add Card Details';
        }

        return cb(errors);
      };
    },
  ])
  .service('redirectUserHomeIfLoggedInService', [
    '$state',
    '$rootScope',
    'pubnubNotificationFactory',
    function redirectUserHomeIfLoggedInService($state, $rootScope, pubnubNotificationFactory) {
      return () => {
        const user = JSON.parse(localStorage.getItem('userDetails'));
        const { userRoles } = relaylib.common.constants;
        let redirectAddRide = false;
        if (!user) {
          return;
        }
        // $scope.$emit('login'); // TODO
        $rootScope.$broadcast('emitLoginEvent');
        if (user.organisation.childrenCount > 0 && user.step > 6) {
          $state.go('main.dashboard.parentOrgDashboard');
          return;
        }

        const redirect_add_ride_setting = _.find(user.organisation.settings, { setting_name: 'redirect_add_ride' });
        if (user.organisation.childrenCount <= 0 && redirect_add_ride_setting && redirect_add_ride_setting.setting_value.toLowerCase() == 'true') {
          redirectAddRide = true;
        }

        // write logic to redirect user to their respective screens
        // roles : riderUser, orgAdmin, tempRider, tempBusiness
        if (user.role === 'tempRider') {
          switch (user.step) {
            case 1:
              $state.go('main.rider_signup_verify_otp');
              return;
            case 2:
              $state.go('main.rider_signup_terms_of_service');
              return;
            case 3:
              $state.go('main.rider_signup_account_information');
              return;
            case 4:
              $state.go('main.rider_signup_add_users');
              return;
            case 5:
              $state.go('main.rider_signup_payment');
              return;
            case 6:
              $state.go('main.rider_signup_summary');
              return;
            case 7:
              pubnubNotificationFactory.setUUID();
              pubnubNotificationFactory.subscribePresenceChannel();
              pubnubNotificationFactory.subscribeOnlineChannel();
              pubnubNotificationFactory.subscribeUserIdChannel();
              if (redirectAddRide) {
                $state.go('main.dashboard.createAppointment', {
                  caller: 'Book now',
                  id: null,
                  isFromDashboard: null,
                  rideId: null,
                });
              } else {
                $state.go('main.dashboard.superAdminDashboard');
              }
              return;
            default:
              localStorage.removeItem('userDetails');
              $state.go('login');
          }
        } else if (user.role === 'tempBusiness') {
          switch (user.step) {
            case 1:
              $state.go('main.business_signup_verify_otp');
              return;
            case 2:
              $state.go('main.business_signup_terms_of_service');
              return;
            case 3:
              $state.go('main.business_signup_add_organization');
              return;
            case 4:
              $state.go('main.business_signup_add_users');
              return;
            case 5:
              if (!user.organisation.created_by_user) {
                $state.go('main.business_signup_payment');
                return;
              }
              $state.go('main.business_signup_summary');
              return;

            case 6:
              $state.go('main.business_signup_summary');
              return;
            case 7:
              $state.go('main.signup_add_card');
              return;
            default:
              localStorage.removeItem('userDetails');
              $state.go('login');
          }
        } // as discussed this case will be fixed later
          else if (
          user.role === 'org_superadmin'
          && user.organisation.main_contact_id !== user.user_id
          && user.organisation.billing_method !== 'invoice'
          && user.organisation.org_class === 'business'
          && false
        ) {
          $state.go('main.signup_add_card');
        } else if (user.role === userRoles.PROVIDER_MANAGER) {
          $state.go('main.userMgmt.providerList');
        } else {
          pubnubNotificationFactory.setUUID();
          pubnubNotificationFactory.subscribePresenceChannel();
          pubnubNotificationFactory.subscribeOnlineChannel();
          pubnubNotificationFactory.subscribeUserIdChannel();
          if (redirectAddRide) {
            $state.go('main.dashboard.createAppointment', {
              caller: 'Book now',
              id: null,
              isFromDashboard: null,
              rideId: null,
            });
          } else {
            $state.go('main.dashboard.superAdminDashboard', {}, { reload: true });
          }
        }
      };
    },
  ])
  .service(
    'numberToOffsetString',
    () => function numberToOffsetString(number) {
      let input = Number(number);
      if (isNaN(input)) {
        return '+00:00';
      }
      if (input === 0) {
        return '+00:00';
      }
      input = Math.floor(input);
      let sign;
      let hour;
      let min;
      let hourString;
      let minutesString;
      if (input > 0) {
        sign = '+';
      } else {
        sign = '-';
      }

      const absInput = Math.abs(input);
      const hundredthPlace = Math.floor(absInput / 100);
      if (hundredthPlace > 0) {
        hour = hundredthPlace;
        min = absInput % 100;
      } else {
        hour = absInput;
        min = 0;
      }
      if (hour < 10) {
        hourString = `0${hour}`;
      } else {
        hourString = `${hour}`;
      }
      if (min < 10) {
        minutesString = `0${min}`;
      } else {
        minutesString = `${min}`;
      }
      return `${sign}${hourString}:${minutesString}`;
    },
  )
  .service(
    'showBillingTOS',
    [
      'permissionFilter',
      '$uibModal',
      function showBillingTOS(permissionFilter, $uibModal) {
        return (first_time_visit, scope, $state) => {
          if (!first_time_visit === undefined) {
            return;
          }
          const dashboardArr = ['main.dashboard.superAdminDashboard', 'main.dashboard.clientAdminDashboard'];
          if ((first_time_visit || first_time_visit === null)
            && permissionFilter(scope.permissionsConstants.SHOW_ENHANCED_BILLING_TOS)
            && dashboardArr.includes($state.current.name)) {

            $uibModal.open({
              animation: false,
              template: require('../views/common/enhanced-billing-tos.html'),
              controller: 'enhancedBillingTOSCtrl',
              backdrop: 'static',
              keyboard: false,
            });
          }

        }
      },]
  );
