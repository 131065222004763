angular
  .module('relayHealth')
  .controller('termsOfServiceCtrl', [
    '$scope',
    '$http',
    'API_BASE_URL',
    '$state',
    '$timeout',
    'signupStateService',
    'signupAuthCheckService',
    'signupInitialStateService',
    'updateSignupStep',
    '$stateParams',
    function termsOfServiceCtrl(
      $scope,
      $http,
      API_BASE_URL,
      $state,
      $timeout,
      signupStateService,
      signupAuthCheckService,
      signupInitialStateService,
      updateSignupStep,
      $stateParams,
    ) {
      $scope.termsAndConditionsAccepted = false;
      $scope.state = {};
      $scope.acceptTOSResponseError = false;
      $scope.stageNumber = 3;

      function init() {
        $scope.state = signupInitialStateService.initialState();
        if ($scope.stageNumber > $scope.state.latestStage) {
          $scope.state.latestStage = 3;
          signupStateService.setState('signupState', $scope.state);
        }
        if ($stateParams.errors) {
          $scope.acceptTOSResponseError = $stateParams.errors.acceptTc;
        }
        $scope.state.currentPage = 3;
        signupStateService.setState('signupState', $scope.state);
        signupAuthCheckService.termsConditionsAllowed();
        updateSignupStep(2);
      }

      init();

      $scope.nextPage = () => {
        const state = signupStateService.getState('signupState');
        const userDetails = signupStateService.getState('userDetails');
        const data = { step: 3, t_and_c_check: $scope.state.acceptTc };
        const headers = {
          username: $scope.state.email,
          usertoken: $scope.state.usertoken,
          'Content-Type': 'application/json',
        };
        $http
          .put(`${API_BASE_URL}rider/update`, [data], headers)
          .then(({ data: { success, errorMessages } }) => {
            if (!success) {
              if (typeof errorMessages === 'string') {
                $scope.acceptTOSResponseError = errorMessages || 'Could not accept TOS';
              }
              $timeout(() => {
                $scope.acceptTOSResponseError = '';
              }, 5000);
              return;
            }
            // tos updated
            state.acceptTc = true;
            userDetails.t_and_c_check = true;

            signupStateService.setState('signupState', state);
            signupStateService.setState('userDetails', userDetails);
            if ($scope.state.signupType === 'business') {
              $state.go('main.business_signup_add_organization');
            } else if ($scope.state.signupType === 'rider') {
              $state.go('main.rider_signup_account_information');
            } else {
              $state.go('main.signup_add_card');
            }
          })
          .catch(({ data: { errorMessages } }) => {
            if (typeof errorMessages === 'string') {
              $scope.acceptTOSResponseError = errorMessages || 'Could not accept TOS';
            }
            $timeout(() => {
              $scope.acceptTOSResponseError = '';
            }, 5000);
          });
      };

      $scope.checkboxChanged = () => {
        signupStateService.setState('signupState', $scope.state);
      };
    },
  ]);
