angular
  .module('relayHealth')
  .directive('dispatchDriverButton', [
    '$http',
    'API_BASE_URL',
    'loadingScreenFactory',
    'toaster',
    'SweetAlert',
    function dispatchDriverButton($http, API_BASE_URL, loadingScreenFactory, toaster, SweetAlert) {
      return {
        restrict: 'E',
        controller: [
          '$scope',
          ($scope) => {
            $scope.dispatchDriverFunc = async () => {
              try {
                loadingScreenFactory.showLoadingScreen();
                await $http.put(`${API_BASE_URL}notify/dispatch_driver`, {
                  appt_id: $scope.rideDetails.appt_id,
                  ride_id: $scope.rideDetails.id,
                });
                SweetAlert.swal({
                  title: '',
                  text: 'Driver and rider have been notified.',
                  icon: 'success',
                });
              } catch (error) {
                toaster.pop({
                  type: 'error',
                  title: (error && error.message) || 'Unable to send sms',
                  timeout: 3000,
                });
              } finally {
                loadingScreenFactory.hideLoadingScreen();
              }
            };
          }],
        template: `<button
          class="btn btn-info ng-binding"
          ng-click="dispatchDriverFunc()"
          style="margin-top: 20px;">
          Dispatch Driver!
        </button>`,
      };
    },
  ]);
