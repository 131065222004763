import Ladda from 'ladda';

angular.module('relayHealth').controller('masOrderCtrl', [
  '$scope',
  '$state',
  'API_BASE_URL',
  '$http',
  'toaster',
  'loadingScreenFactory',
  '$stateParams',
  'Pubnub',
  'pubnubNotificationFactory',
  '$transitions',
  'SweetAlert',
  '$uibModal',
  '$timeout',
  function masOrderCtrl(
    $scope,
    $state,
    API_BASE_URL,
    $http,
    toaster,
    loadingScreenFactory,
    $stateParams,
    Pubnub,
    pubnubNotificationFactory,
    $transitions,
    SweetAlert,
    $uibModal,
    $timeout,
  ) {
    // watch removers for all listeners;
    const watcherRemovers = [];

    const validationToTabMapping = {
      dob: {
        activeTab: 'riderInfo',
      },
      pickup_date_time: {
        activeTab: 'tripInfo',
      },
    };
    $scope.moment = moment;
    $scope.invalidLegTime = false;
    $scope.forms = {
      order_info: {},
      ride_info: {},
      trip_info: {},
    };
    // data from stateParams
    let rideId = Number($stateParams.rideId);
    const { caller } = $stateParams;
    $scope.isMasView = true;
    $scope.allowCancelTrip = true;
    $scope.diffLoaded = false;
    $scope.pageHeading = 'MAS Order';

    const loggedInUserDetails = JSON.parse(localStorage.getItem('userDetails'));
    $scope.permissionsConstants = relaylib.permissions.permissionsConstants;
    $scope.rideStatuses = relaylib.ride.status;
    const afterDriverArrivedRideCategories = [
      $scope.rideStatuses.PATIENT_ENROUTE.type,
      $scope.rideStatuses.FAILED.type,
      $scope.rideStatuses.APPOINTMENT_COMPLETED.type,
    ];
    const editableActiveRideStatuses = [
      $scope.rideStatuses.CONFIRMED.status,
      $scope.rideStatuses.DRIVER_CONFIRMED.status,
      $scope.rideStatuses.FINDING_DRIVER.status,
      $scope.rideStatuses.DRIVER_ARRIVED.status,
      $scope.rideStatuses.VALIDATION_FAILED.status,
      $scope.rideStatuses.ARRIVAL_TIME_EXCEEDED.status,
    ];
    const providerIdConstants = relaylib.common.constants.providerIdMapping;
    /**
     * below rideDetails object is being used in child controllers also,
     * so don't delete anything before confirmation
     * */
    $scope.dropdowns = {
      organisations: [],
    };

    function routeToPreviousPage() {
      let stateToGo = 'main.dashboard.scheduled';
      if (caller === 'AR') {
        stateToGo = 'main.dashboard.active';
      } else if (caller === 'dashboard') {
        stateToGo = 'main.dashboard.superAdminDashboard';
      }

      $state.go(stateToGo);
    }

    const resetRideDetails = function resetRideDetails() {
      $scope.rideDetails = {
        isBookAnother: !!$stateParams.bookAnotherId,
        ride_id: rideId,
        org_id: null,
        managed_by: null,
        distance: 0,
        rideInfo: {
          disable: true,
          patient: {
            phone: null,
            first_name: null,
            last_name: null,
          },
          file_no: null,
        },
        tripInfo: {
          disable: true,
          appointmentTime: {
            time: 'FUTURE_APPOINTMENT',
          },
          leg_type: 'single',
          legs: [],
          source_address: '',
          dest_address: '',
        },
        orderInfo: {
          disable: true,
          orderId: '',
          preferredVendorNotes: '',
          prefferedVendor: '',
          orderGeneratedAt: null,
          driverNotes: '',
          orderInternalNotes: '',
        },
        availableVendors: {
          error: '',
          vendorList: [],
          rideShareSelectedCount: 0,
        },
      };
    };

    const popErrors = function popErrors(error, message) {
      toaster.pop({
        type: 'error',
        title: error.message || message || 'Error occured !',
        timeout: 3000,
      });
    };

    function getActiveTab() {
      let activeTab = 'orderInfo';
      if ($scope.rideDetails.failedValidations) {
        const failedValidationTypes = Object.keys($scope.rideDetails.failedValidations);
        const dobFailedValidation = failedValidationTypes.includes('dob');
        if (dobFailedValidation) {
          ({ activeTab } = validationToTabMapping.dob);
        } else {
          ({ activeTab } = validationToTabMapping[failedValidationTypes[0]]);
        }
      }
      return activeTab;
    }

    $scope.tabSelected = (event) => {
      $timeout(() => {
        $scope.$broadcast(event);
      }, 0);
    };

    const setupTabs = function setupTabs() {
      $scope.tabs = {
        riderInfo: {
          heading: 'Rider Information',
          active: false,
          tabSelectedEvent: 'riderInfoSelected',
        },
        tripInfo: {
          heading: 'Trip Information',
          active: false,
          tabSelectedEvent: 'tripInfoSelected',
        },
        orderInfo: {
          heading: 'Order Information',
          active: false,
          tabSelectedEvent: 'orderInfoSelected',
        },
      };

      const activeTab = getActiveTab();
      $scope.tabs[activeTab].active = true;
    };

    $scope.onlineAdmins = [];
    $scope.subscribedLegs = [];

    const getPresenceChannels = function getPresenceChannels() {
      const presenceChannels = $scope.subscribedLegs.map(subscribedRideId => `presence_${subscribedRideId}`);
      return presenceChannels;
    };

    let checkAndUpdateCurrentlyViewingAdminsRunning = false;
    function checkAndUpdateCurrentlyViewingAdmins() {
      if (checkAndUpdateCurrentlyViewingAdminsRunning) {
        return; // Check to prevent parallel calling attempts to this function
      }

      checkAndUpdateCurrentlyViewingAdminsRunning = true;

      const presenceChannels = getPresenceChannels();
      pubnubNotificationFactory.getHereNowOfChannels(presenceChannels, (status, { channels }) => {
        const onlineAdmins = [];
        const onlineAdminsIDs = [];

        presenceChannels.forEach((channel) => {
          if (channels[channel]) {
            const { occupants } = channels[channel];
            if (Array.isArray(occupants)) {
              occupants.forEach((o) => {
                if (o.state && !onlineAdminsIDs.includes(o.state.id)) {
                  onlineAdminsIDs.push(o.state.id);
                  onlineAdmins.push(o.state);
                }
              });
            }
          }
        });
        checkAndUpdateCurrentlyViewingAdminsRunning = false; // Reset flag....so that next attempt to check active users can run successfully.

        $scope.$apply(() => {
          $scope.onlineAdmins = onlineAdmins;
        });
      });
    }

    const presenceListeners = {
      presence(p) {
        const presenceChannels = getPresenceChannels();
        if (!(presenceChannels.includes(p.channel) && ['state-change', 'leave', 'timeout'].includes(p.action))) {
          return;
        }
        checkAndUpdateCurrentlyViewingAdmins();
      },
      message(p) {
        const presenceChannels = getPresenceChannels();
        if (!presenceChannels.includes(p.channel)) {
          return;
        }

        const payload = p.message;
        toaster.pop({
          type: 'info',
          title: payload.message,
          showCloseButton: true,
          timeout: 10000,
        });
      },
    };

    Pubnub.addListener(presenceListeners);

    function subscribePresenceChannelForAllLegs() {
      if ($scope.rideDetails.tripInfo.legs.length) {
        $scope.rideDetails.tripInfo.legs.forEach((leg) => {
          if (!$scope.subscribedLegs.includes(leg.ride_id)) {
            $scope.subscribedLegs.push(leg.ride_id);
            pubnubNotificationFactory.subscribePresenceChannel(`presence_${leg.ride_id}`, {
              name: `${loggedInUserDetails.first_name} ${loggedInUserDetails.last_name}`,
              id: loggedInUserDetails.user_id,
            });
          }
        });
      }
    }

    const getOrganizationDetails = async function getOrganizationDetails() {
      try {
        const requestUrl = `${API_BASE_URL}organisation/getOrgDetailsById/${$scope.rideDetails.org_id}`;
        const {
          data: { orgDetails, success },
        } = await $http.get(requestUrl);
        if (success) {
          $scope.dropdowns.organisations.push(orgDetails);
        } else {
          throw new Error('Unable to find Org Details.');
        }
      } catch (error) {
        popErrors(error, 'Error while getting Org Details');
      }
    };

    const applyTimezoneOffset = function applyTimezoneOffset(utcMoment, pickupOffset) {
      let timeMoment;
      if (pickupOffset) {
        timeMoment = utcMoment.utcOffset(pickupOffset);
      } else if ($scope.rideDetails.orderInfo.appointment_timezone_offset) {
        timeMoment = utcMoment.utcOffset($scope.rideDetails.orderInfo.appointment_timezone_offset);
      } else if ($scope.rideDetails.orderInfo.appt_timezone) {
        timeMoment = utcMoment.tz($scope.rideDetails.orderInfo.appt_timezone);
      } else {
        timeMoment = utcMoment.utcOffset(0);
      }

      return timeMoment;
    };

    const addLyftVendor = ({ lyftConfirmed }) => {
      $scope.rideDetails.availableVendors.vendorList.push({
        providerId: 'LYFT',
        fuseId: 'LYFT',
        name: 'Lyft',
        softwareCategory: 'rideShareProviders',
        tabCategory: 'ep',
        selected: lyftConfirmed,
        checkDisabled: lyftConfirmed || $scope.providerTabs.isOffered,
        status: (lyftConfirmed && 1) || undefined,
        statusString: (lyftConfirmed && 'Confirmed') || undefined,
        isRideShareVendor: true,
      });
      $scope.providerTabs.counts.total = 1;
      $scope.providerTabs.categorySetting.rideShareProviders.show = true;

      if (lyftConfirmed) {
        $scope.providerTabs.isOffered = true;
        $scope.providerTabs.isRideShareSelected = true;
        $scope.rideDetails.availableVendors.rideShareSelectedCount += 1;
        $scope.providerTabs.allSelected.rideShareProviders.ep = true;
      }
    };

    const getDateTimeFromTimestamp = (dateString, offsetString) => {
      const legInfo = {
        date: null,
        time: null,
      };
      let finalDate;

      if (dateString) {
        const tempDate = moment.unix(dateString);
        if (tempDate.isValid()) {
          const afterOffsetDate = applyTimezoneOffset(tempDate, offsetString);
          if (afterOffsetDate.isValid()) {
            finalDate = afterOffsetDate;
          }
        }
      }

      if (finalDate) {
        legInfo.date = finalDate;
        legInfo.time = new Date(1970, 1, 1, finalDate.hours(), finalDate.minutes(), finalDate.seconds());
      }
      return { ...legInfo, finalDate };
    };

    const addRideDetailsToScope = async (rideDetails) => {
      $scope.isEditingAllowed = true;
      try {
        let legOneChanged = false;
        let legOneId;
        // ride details
        $scope.rideDetails.distance = rideDetails.distance;
        $scope.rideDetails.isSnoozed = rideDetails.isSnoozed;
        $scope.rideDetails.failedValidations = rideDetails.failed_validations;

        // timezone details
        $scope.rideDetails.orderInfo.appt_timezone_offset = rideDetails.appointment.appointment_timezone_offset;
        $scope.rideDetails.orderInfo.appt_timezone = rideDetails.appointment.appt_timezone;
        // ride info
        const { patient } = rideDetails;
        if (patient.dob) {
          const patientDobArr = patient.dob.split('-');
          patient.dob = `${patientDobArr[1]}-${patientDobArr[2]}-${patientDobArr[0]}`;
        }
        $scope.rideDetails.rideInfo.patient = patient;
        $scope.rideDetails.rideInfo.health_plan_name = rideDetails.appointment.health_plan;
        $scope.rideDetails.org_id = rideDetails.org_id;
        $scope.rideDetails.external_leg_id = rideDetails.external_leg_id;
        $scope.rideDetails.orderInfo.status = rideDetails.ride_status;
        $scope.rideDetails.additionalDetails = rideDetails.appointment.additional_details;
        $scope.rideDetails.rideInfo.pickupOffset = (rideDetails.ride_additional_detail && rideDetails.ride_additional_detail.pickup_timezone_offset) || 0;
        $scope.rideDetails.orderInfo.appt_id = rideDetails.appt_id;
        // trip info
        $scope.rideDetails.tripInfo.leg_type = rideDetails.leg_type;
        if (rideDetails.book_now) {
          $scope.rideDetails.tripInfo.appointmentTime.time = 'BOOK_NOW';
        } else {
          $scope.rideDetails.tripInfo.appointmentTime.time = 'FUTURE_APPOINTMENT';
        }
        $scope.rideDetails.tripInfo.total_distance = 0;

        if (rideDetails.legs && rideDetails.legs.length) {
          rideDetails.legs.forEach((leg) => {
            const rideNoteArr = [];
            const legInfo = {};
            legInfo.source_address = leg.source_address || '';
            legInfo.source_lat_lng = leg.source_lat_lng;
            legInfo.dest_address = leg.dest_address || '';
            legInfo.dest_lat_lng = leg.dest_lat_lng;
            legInfo.external_leg_id = leg.external_leg_id;
            legInfo.ride_id = leg.id;

            const dateString = leg.pickup_date_time;
            const offsetString = (leg.ride_additional_detail && leg.ride_additional_detail.p_offset) || 0;
            const { date, time, finalDate } = getDateTimeFromTimestamp(dateString, offsetString);
            legInfo.date = date;
            legInfo.time = time;

            if (leg.ride_status === $scope.rideStatuses.POTENTIAL_UNAVAILABILITY.status) {
              $scope.rideDetails.isPotentiallyUnavailable = true;
            }
            // disable cancel trip button when ride status is rider enroute
            if (leg.ride_status === $scope.rideStatuses.PATIENT_ENROUTE.status) {
              $scope.allowCancelTrip = false;
            }
            if (leg.leg_no === 1) {
              $scope.rideDetails.tripInfo.firstLegPickupDate = finalDate;
              $scope.rideDetails.tripInfo.firstLegOffset = offsetString;
              $scope.rideDetails.firstLegStatus = leg.ride_status;

              if (leg.id !== rideId) {
                legOneChanged = true;
                legOneId = leg.id;
              }

              $scope.isBookNowAvailable = leg.ride_category === 'scheduled'
                && leg.ride_style === 'manual_schedule'
                && moment()
                  .utc()
                  .isSame(leg.pickup_date_time * 1000, 'day');
            }

            legInfo.isTimeEditingAllowed = true;
            if (
              !$stateParams.bookAnotherId
              && !(
                (leg.ride_category === 'issue' && leg.ride_status !== $scope.rideStatuses.PATIENT_ENROUTE.status)
                || editableActiveRideStatuses.includes(leg.ride_status)
              )
            ) {
              legInfo.isTimeEditingAllowed = false;
            }
            if (
              rideDetails.legs.length === leg.leg_no
              && !(
                (leg.ride_category === 'issue' && leg.ride_status !== $scope.rideStatuses.PATIENT_ENROUTE.status)
                || editableActiveRideStatuses.includes(leg.ride_status)
              )
            ) {
              $scope.isEditingAllowed = false;
            }

            if (!$scope.cancelableLegId && (editableActiveRideStatuses.includes(leg.ride_status) || leg.ride_category === 'issue')) {
              $scope.cancelableLegId = leg.id;
            }

            const apptRawDate = _.get(leg, 'ride_additional_detail.appointment_date');
            const apptOffset = _.get(leg, 'ride_additional_detail.appointment_timezone_offset', 0);

            const { date: apptDate, time: apptTime } = getDateTimeFromTimestamp(apptRawDate, apptOffset);
            legInfo.apptDate = apptDate;
            legInfo.apptTime = apptTime;

            if (!(date && time)) {
              legInfo.date = apptDate;
              legInfo.time = apptTime;
            }

            if (leg.managed_by) {
              $scope.rideDetails.managed_by = leg.managed_by;
            }

            // calculating distance
            $scope.rideDetails.tripInfo.total_distance += Number(leg.distance);

            // consider ride order as confirmed if it was scheduled once and now in relay statuses.
            legInfo.ride_category = leg.ride_category;
            legInfo.ride_status = leg.ride_status;
            const offerCreatedType = [$scope.rideStatuses.OFFER_CREATED.type, $scope.rideStatuses.OFFERED.type];
            if (!$scope.providerTabs.isConfirmed && !offerCreatedType.includes(legInfo.ride_category)) {
              $scope.providerTabs.isConfirmed = true;
            }
            if (!$scope.providerTabs.isConfirmed && legInfo.ride_status === $scope.rideStatuses.TWENTY_FOUR_HOUR_CONFIRMATION_MISSING.status) {
              $scope.providerTabs.isConfirmed = true;
            }

            if (!$scope.providerTabs.isActive && afterDriverArrivedRideCategories.includes(legInfo.ride_category)) {
              $scope.providerTabs.isActive = true;
            }

            const pickupLocationName = _.get(leg, 'ride_additional_detail.pickup_location_name', '');
            const destinationLocationName = _.get(leg, 'ride_additional_detail.destination_location_name', '');
            if (pickupLocationName) {
              rideNoteArr.push(`Pickup: ${pickupLocationName}`);
            }
            if (destinationLocationName) {
              rideNoteArr.push(`Dropoff: ${destinationLocationName}`);
            }
            if (leg.note_to_driver) {
              rideNoteArr.push(leg.note_to_driver);
            }
            legInfo.rideNotes = rideNoteArr.join(',');
            $scope.rideDetails.tripInfo.legs.push(legInfo);
          });
        }

        $scope.rideDetails.tripInfo.total_distance = Math.ceil($scope.rideDetails.tripInfo.total_distance);

        if ($stateParams.bookAnotherId) {
          const newObjProp = {
            previousRideId: rideDetails.id,
            created_by_patient: 0,
            provider_prod_id: '',
          };
          Object.assign($scope.rideDetails, newObjProp);
        }

        if (legOneChanged) {
          rideId = legOneId;
          $scope.updateFullOrder();
          return;
        }

        $scope.rideDetails.tripInfo.source_address = rideDetails.source_address;
        $scope.rideDetails.tripInfo.source_lat_lng = rideDetails.source_lat_long;
        $scope.rideDetails.tripInfo.dest_address = rideDetails.dest_address;
        $scope.rideDetails.tripInfo.dest_lat_lng = rideDetails.dest_lat_long;

        // mapping ride order details
        $scope.rideDetails.orderInfo.orderId = _.get(rideDetails, 'appointment.external_order_id');
        const orderDateString = _.get(rideDetails, 'appointment.createdAt');
        const apptTimezone = _.get(rideDetails, 'appointment.appt_timezone');
        let orderFinalDate = 'N/A';
        if (orderDateString) {
          if (apptTimezone) {
            orderFinalDate = moment(orderDateString)
              .tz(apptTimezone)
              .format('MM/DD/YYYY h:mm A');
          } else {
            orderFinalDate = moment(orderDateString).format('MM/DD/YYYY h:mm A');
          }
        }
        $scope.rideDetails.orderInfo.orderGeneratedAt = orderFinalDate;

        const hasProviderPreference = _.get(rideDetails, 'ride_additional_detail.has_provider_preference');
        $scope.rideDetails.orderInfo.prefferedVendor = (hasProviderPreference && 'Yes') || 'No';
        $scope.rideDetails.orderInfo.preferredVendorNotes = (hasProviderPreference && rideDetails.provider_preference_notes) || '';

        $scope.rideDetails.orderInfo.driverNotes = '';
        $scope.rideDetails.orderInfo.rideNotes = rideDetails.appointment_notes;

        // now fetch vendor lists and details also
        subscribePresenceChannelForAllLegs();

        const lyftConfirmed = rideDetails.provider_id === providerIdConstants.LYFT && $scope.providerTabs.isConfirmed;
        $scope.isLyftConfirmed = lyftConfirmed;

        if (lyftConfirmed && $scope.rideDetails.firstLegStatus === $scope.rideStatuses.CONFIRMED.status) {
          $scope.providerTabs.isConfirmed = false;
        }

        addLyftVendor({
          lyftConfirmed,
        });

        // broadcast that ride details are fetched successfully
        $scope.$broadcast('rideOrderFetched');
      } catch (error) {
        toaster.pop({
          type: 'error',
          title: error.message,
          timeout: 3000,
        });
      }
    };

    const tabStyle = {
      active: {
        color: '#1ab394',
      },
      inactive: {
        color: 'grey',
      },
    };

    function resetProviderTabs() {
      $scope.providerTabs = {
        isConfirmed: false,
        isRideShareSelected: false,
        isOrderUpdate: false,
        isActive: false,
        activeIndex: 'ep',
        getTabStyle(tab) {
          if (tab.activeCode === $scope.providerTabs.activeIndex) {
            return tabStyle.active;
          }
          return tabStyle.inactive;
        },
        categorySetting: {
          rideShareProviders: {
            code: 'rideShareProviders',
            isRideShareCategory: true,
            title: 'Ride Share Providers',
            visibleOn: ['ep'],
            show: false, // This is false by default and only if lyft is eligible then show it.
          },
        },
        tabs: [
          {
            heading: 'Available Vendors',
            activeCode: 'ep',
            show: true,
          },
        ],
        allSelected: {
          rideShareProviders: {
            ep: false,
          },
        },
        counts: {},
      };

      $scope.providerTabs.categories = [$scope.providerTabs.categorySetting.rideShareProviders];
    }

    function checkIfFutureRideUpdateDisable(rideDetails) {
      if (rideDetails) {
        const rideDate = moment(rideDetails.datetime_in_tz).format('YYYY-MM-DD 00:00:00');
        let currentDate = moment()
          .tz(rideDetails.appt_timezone)
          .format('YYYY-MM-DD 00:00:00');
        currentDate = moment(currentDate);
        if (moment(rideDate).diff(currentDate, 'days') > 0 && !_.get(rideDetails, 'failed_validations.dob')) {
          $scope.disableUpdateTrip = true;
        }
      }
    }

    const fetchRideDetails = async () => {
      if (!$stateParams.rideId && !$stateParams.bookAnotherId) {
        toaster.pop({
          type: 'error',
          title: 'Ride id is missing.',
          timeout: 3000,
        });
        return;
      }
      const url = $stateParams.bookAnotherId
        ? `${API_BASE_URL}ride/getRideDetailsForBookAnother/${$stateParams.bookAnotherId}`
        : `${API_BASE_URL}ride/getRideDetailsById/${$stateParams.rideId}`;
      try {
        const { data: { success, rideDetails } = {} } = await $http.get(url, {
          params: {
            org_id: loggedInUserDetails.organisation.id,
          },
        });

        if (success) {
          checkIfFutureRideUpdateDisable(rideDetails);
          resetProviderTabs();
          resetRideDetails();
          $scope.originalRideDetails = angular.copy(rideDetails);
          await addRideDetailsToScope(rideDetails);
        } else {
          toaster.pop({
            type: 'error',
            title: 'Error while fetching ride details.',
            timeout: 3000,
          });
        }
      } catch (error) {
        toaster.pop({
          type: 'error',
          title: error.message || 'Error while fetching ride details',
          timeout: 3000,
        });
      }
    };

    $scope.updateFullOrder = function updateFullOrder() {
      loadingScreenFactory.showLoadingScreen();
      fetchRideDetails().finally(() => {
        loadingScreenFactory.hideLoadingScreen();
        $scope.$apply();
      });
    };

    $scope.timeChanged = function timeChanged(index) {
      const leg = $scope.rideDetails.tripInfo.legs[index];
      $scope.invalidLegTime = !leg.time || !leg.apptTime || false;
    };

    $scope.providerTabs = {};

    const keepTrip = async function keepTrip(notes) {
      const rideUpdateUrl = `${API_BASE_URL}ride/clear_from_view`;
      const params = {
        ride_id: Number($stateParams.rideId),
        ctaType: 'Keep Trip',
        snooze: true,
      };
      if (notes) {
        params.comments = notes;
      }
      loadingScreenFactory.showLoadingScreen();
      try {
        const {
          data: { success },
        } = await $http.put(rideUpdateUrl, params);
        if (success) {
          SweetAlert.swal(
            {
              title: 'Ride Cleared!',
              text: 'Ride Cleared Successfully',
              type: 'success',
              confirmButtonText: 'Ok',
              closeOnConfirm: true,
              confirmButtonColor: '#DD6B55',
            },
            (isConfirm) => {
              if (isConfirm) {
                routeToPreviousPage();
              }
            },
          );
        }
      } catch (err) {
        toaster.error({
          type: 'error',
          title: err.message,
          showCloseButton: true,
          timeout: 10000,
        });
      } finally {
        loadingScreenFactory.hideLoadingScreen();
      }
    };

    function openNotesPopup(modalData) {
      const notesModal = $uibModal.open({
        animation: true,
        template: require('../../views/notes.html'),
        controller: 'notesCtrl',
        controllerAs: '$notesCtrl',
        size: 'md',
        backdrop: 'static',
        resolve: {
          data() {
            return modalData;
          },
        },
      });
      return new Promise((resolve, reject) => {
        notesModal.result.then(
          (notes) => {
            resolve(notes);
          },
          () => {
            reject();
          },
        );
      });
    }

    $scope.keepTrip = async () => {
      const notesModalData = {};
      if ($scope.rideDetails.isPotentiallyUnavailable) {
        notesModalData.heading = 'Keep Trip';
        notesModalData.confirmationLine = 'Are you sure you want to set the clear Potential Unavailability for this trip ?';
        notesModalData.cta = 'Keep Trip';
      } else if ($scope.rideDetails.orderInfo.status === $scope.rideStatuses.VALIDATION_FAILED.status) {
        notesModalData.heading = $scope.rideStatuses.VALIDATION_FAILED.status;
        notesModalData.confirmationLine = 'Are you sure you want to keep this trip ?';
        notesModalData.cta = 'Keep Trip';
      }
      let notes;
      try {
        notes = await openNotesPopup(notesModalData);
      } catch {
        return;
      }
      try {
        await keepTrip(notes);
      } catch (err) {
        loadingScreenFactory.hideLoadingScreen();
        const message = err.data ? err.data.message : err.message;
        toaster.error({
          type: 'error',
          title: message,
          showCloseButton: true,
          timeout: 10000,
        });
      }
    };

    const addScopeToRideDetailsObject = () => {
      const rideDetails = angular.copy($scope.rideDetails);
      const originalRideDetails = angular.copy($scope.originalRideDetails);
      const rideLegsArr = [];
      originalRideDetails.legs.forEach((orignalLeg, index) => {
        const newLegDetails = orignalLeg;
        const leg = rideDetails.tripInfo.legs.find(currentLeg => currentLeg.ride_id === newLegDetails.id);

        // Incase of book now, Do not update other rides.
        if ($scope.bookType && index) {
          newLegDetails.appointment_date = newLegDetails.ride_additional_detail && newLegDetails.ride_additional_detail.appointment_date;
          rideLegsArr.push(newLegDetails);
          return;
        }
        if (!leg) return;

        const { apptDate, apptTime } = leg;
        if (newLegDetails && apptDate && apptTime) {
          const { date, time } = leg;
          if (!(time && time !== '00' && time.getTime()) || !(apptTime && apptTime !== '00' && apptTime.getTime())) {
            throw new Error('Pickup/Appointment time must be correct.');
          }
          const finalAppDateTime = moment(apptDate)
            .hours(apptTime.getHours())
            .minutes(apptTime.getMinutes())
            .seconds(apptTime.getSeconds());
          const finalPickupDateTime = moment(date)
            .hours(time.getHours())
            .minutes(time.getMinutes())
            .seconds(time.getSeconds());

          if (newLegDetails.ride_additional_detail) {
            newLegDetails.ride_additional_detail.appointment_date = finalAppDateTime.unix();
          }

          if (['active', 'scheduled', 'issue'].includes(newLegDetails.ride_category) && !newLegDetails.book_now && moment(newLegDetails.pickup_date_time * 1000).diff(finalPickupDateTime, 'minutes') !== 0) {
            newLegDetails.ride_style = ''; // If user has changed time then set ride_style to be saved as empty.
            if (originalRideDetails.id === newLegDetails.id) {
              originalRideDetails.ride_style = '';
            }
          }

          const pickupDateTime = finalPickupDateTime.unix();
          if (!newLegDetails.book_now || (newLegDetails.pickup_date_time || pickupDateTime !== _.get(newLegDetails, 'ride_additional_detail.appointment_date'))) {
            newLegDetails.pickup_date_time = pickupDateTime;
            newLegDetails.book_now = false;
          }
          newLegDetails.appointment_date = finalAppDateTime.unix();

          newLegDetails.finalDateTime = finalPickupDateTime;
          newLegDetails.source_address_json = _.get(newLegDetails, 'ride_additional_detail.s_json', {});
          newLegDetails.dest_address_json = _.get(newLegDetails, 'ride_additional_detail.d_json', {});
          rideLegsArr.push(newLegDetails);
        }
      });
      Object.assign(originalRideDetails, (rideLegsArr || [])[0] || {});
      originalRideDetails.scheduled_book_now = true;
      originalRideDetails.legs = rideLegsArr;

      originalRideDetails.book_now = $scope.bookType ? 1 : originalRideDetails.book_now;
      if (originalRideDetails.book_now) {
        originalRideDetails.time = '';
        originalRideDetails.legs[0].pickup_date_time = 0;
      }

      if (rideLegsArr.length) {
        originalRideDetails.date = rideLegsArr[0].finalDateTime.format('YYYY-MM-DD');
        originalRideDetails.time = rideLegsArr[0].finalDateTime.format('HH:mm:ss');
      }
      if ($stateParams.bookAnotherId) {
        originalRideDetails.previousRideId = $scope.rideDetails.previousRideId;
        originalRideDetails.created_by_patient = $scope.rideDetails.created_by_patient;
        originalRideDetails.provider_prod_id = _.get($scope.rideDetails, 'availableVendors.vendorList[0].providerId', '').toLowerCase();
      }
      return originalRideDetails;
    };

    const updateRide = async () => {
      const rideDetails = addScopeToRideDetailsObject();
      delete rideDetails.comments;
      if (rideDetails.previousRideId) {
        const notesModalData = {
          cta: 'Book Another Ride',
          heading: 'Book Another Ride',
          confirmationLine: 'Are you sure you want to Book Another Ride ?',
        };

        const notes = await openNotesPopup(notesModalData);

        const params = {
          params: {
            bookAnotherId: $stateParams.bookAnotherId,
          },
        };
        if (notes) {
          rideDetails.comments = notes;
        }

        loadingScreenFactory.showLoadingScreen();
        return $http.post(`${API_BASE_URL}ride/add/${$scope.rideDetails.org_id}`, rideDetails, params);
      }

      const showPastTimeError = !rideDetails.book_now && (rideDetails.legs || []).some(
        leg => !([$scope.rideStatuses.RIDE_COMPLETED.status, $scope.rideStatuses.REQUEST_OVERDUE.status].includes(leg.ride_status))
          && leg.ride_category !== 'completed'
          && leg.pickup_date_time
          && leg.ride_style !== 'manual_schedule'
          && !leg.book_now
          && moment().diff(moment(leg.pickup_date_time * 1000), 'minutes') > 30,
      );

      if (showPastTimeError) {
        throw new Error('Time is in the past');
      }

      loadingScreenFactory.showLoadingScreen();
      Object.assign(rideDetails, { ctaType: 'Update Ride' });
      return $http.put(`${API_BASE_URL}ride/edit/${rideId}`, rideDetails);
    };

    $scope.bookNowMasCallRides = async () => {
      const l = Ladda.create(document.querySelector('.mas-booknow-call-btn'));
      l.start();
      $scope.bookType = true;
      try {
        const { data: { success } = {} } = await updateRide();
        loadingScreenFactory.hideLoadingScreen();
        if (success) {
          SweetAlert.swal(
            {
              title: 'Trip Booked Successfully.',
              html: true,
              text: '',
              type: 'success',
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'OK',
              closeOnConfirm: true,
            },
            (isConfirm) => {
              if (isConfirm) {
                routeToPreviousPage();
              }
            },
          );
        }
      } catch (err) {
        const message = err.data ? err.data.message : err.message;
        toaster.error({
          type: 'error',
          title: message,
          showCloseButton: true,
          timeout: 10000,
        });
      } finally {
        $scope.bookType = false;
        l.stop();
        loadingScreenFactory.hideLoadingScreen();
        $scope.$apply();
      }
    };

    $scope.updateRideDetails = function updateRideDetails() {
      SweetAlert.swal(
        {
          title: '',
          text: 'Are you sure you want to update this trip?',
          type: 'warning',
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes',
          closeOnCancel: true,
          cancelButtonText: 'No',
          showCancelButton: true,
        },
        async (confirmed) => {
          if (confirmed) {
            // Create new instance for Ladda (loading buttons)
            const l = Ladda.create(document.querySelector('.update-appointment-button'));
            l.start();
            try {
              const { data: { success } = {} } = await updateRide();
              loadingScreenFactory.hideLoadingScreen();
              if (success) {
                SweetAlert.swal(
                  {
                    title: 'Trip Updated Successfully.',
                    html: true,
                    text: '',
                    type: 'success',
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'OK',
                    closeOnConfirm: true,
                  },
                  (isConfirm) => {
                    if (isConfirm) {
                      routeToPreviousPage();
                    }
                  },
                );
              }
            } catch (err) {
              const message = err.data ? err.data.message : err.message;
              toaster.error({
                type: 'error',
                title: message,
                showCloseButton: true,
                timeout: 10000,
              });
            } finally {
              l.stop();
              loadingScreenFactory.hideLoadingScreen();
              $scope.$apply();
            }
          }
        },
      );
    };

    $scope.cancelAppointment = function cancelAppointment() {
      SweetAlert.swal(
        {
          title: 'Cancel?',
          text: $scope.recurrenceAction === 'single' ? 'Only this trip will be cancelled.' : 'Are you sure you want to cancel this trip?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn-danger',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          closeOnConfirm: false,
          closeOnCancel: true,
        },
        async (isConfirm) => {
          if (!isConfirm) {
            return;
          }
          loadingScreenFactory.showLoadingScreen();
          let finalRideId = $scope.cancelableLegId;
          if (!finalRideId) {
            if ($stateParams.bookAnotherId) {
              finalRideId = $stateParams.bookAnotherId;
            } else {
              toaster.error('No Ride id found.');
              return;
            }
          }
          const params = {
            ctaType: 'Cancel Trip',
            ride_id: finalRideId,
          };
          try {
            const { data: response } = await $http.put(`${API_BASE_URL}ride/cancel`, params);

            if (response.success) {
              loadingScreenFactory.hideLoadingScreen();
              SweetAlert.swal('Cancelled!', 'Your trip has been cancelled.', 'success');

              routeToPreviousPage();
            }
          } catch (err) {
            const message = err.message ? err.message : err.data.message;
            loadingScreenFactory.hideLoadingScreen();
            toaster.error(message);
          } finally {
            $scope.$apply();
          }
        },
      );
    };

    $scope.bookNowForPickupDateTimeValidationFailed = async function bookNowForPickupDateTimeValidationFailed() {
      const rideDetails = addScopeToRideDetailsObject();
      rideDetails.book_now = 1;
      rideDetails.ctaType = 'Book Now';
      try {
        const { data: { success } = {} } = await $http.put(`${API_BASE_URL}ride/edit/${rideId}`, rideDetails);
        if (success) {
          SweetAlert.swal(
            {
              title: 'Trip Booked Successfully.',
              text: 'Trip Booked Successfully.',
              type: 'success',
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'OK',
              closeOnConfirm: true,
            },
            (isConfirm) => {
              if (isConfirm) {
                routeToPreviousPage();
              }
            },
          );
        }
      } catch (error) {
        const message = error.data ? error.data.message : error.message;
        toaster.error({
          type: 'error',
          title: message,
          showCloseButton: true,
          timeout: 10000,
        });
      }
    };

    $scope.clearAlert = async () => {
      const notesModalData = {
        heading: 'Clear Alert',
        confirmationLine: 'Are you sure you want to clear the Potential Unavailability for this trip permanently?',
        cta: 'Clear Alert',
      };
      let notes;
      try {
        notes = await openNotesPopup(notesModalData);
      } catch {
        return;
      }
      try {
        const rideUpdateUrl = `${API_BASE_URL}ride/clear_from_view`;
        const params = {
          ride_id: Number($stateParams.rideId),
          ctaType: 'Clear Alert',
        };
        if (notes) {
          params.comments = notes;
        }
        loadingScreenFactory.showLoadingScreen();
        const { data: { success } = {} } = await $http.put(rideUpdateUrl, params);
        if (success) {
          SweetAlert.swal(
            {
              title: 'Trip Cleared Successfully.',
              text: 'Trip Cleared Successfully.',
              type: 'success',
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'OK',
              closeOnConfirm: true,
            },
            (isConfirm) => {
              if (isConfirm) {
                routeToPreviousPage();
              }
            },
          );
        }
      } catch (err) {
        const message = err.data ? err.data.message : err.message;
        toaster.error({
          type: 'error',
          title: message,
          showCloseButton: true,
          timeout: 10000,
        });
      } finally {
        loadingScreenFactory.hideLoadingScreen();
      }
    };

    (async () => {
      resetProviderTabs();
      resetRideDetails();
      loadingScreenFactory.showLoadingScreen();
      fetchRideDetails().finally(() => {
        getOrganizationDetails();
        loadingScreenFactory.hideLoadingScreen();
        setupTabs();
        $scope.$apply();
      });
    })();

    $scope.$on('$destroy', () => {
      angular.forEach(watcherRemovers, (w) => {
        if (typeof w === 'function') w();
      });
      Pubnub.removeListener(presenceListeners);
      const presenceChannels = $scope.subscribedLegs.map(subRideId => `presence_${subRideId}`);
      presenceChannels.forEach((channel) => {
        pubnubNotificationFactory.unsubscribeChannel(channel);
      });
    });
  },
]);
