import angular from 'angular';

const controllerDef = [
  '$scope',
  '$q',
  '$timeout',
  function selectPagedCtrl($scope, $q, $timeout) {
    const watcherRemovers = [];
    let itemsArr = [];

    let offset = 0;

    let inited = false;

    let outputItemsUpdated = false;

    /**
     * Setup default values for options
     */
    function setupDefaults() {
      $scope.idKey = $scope.idKey || 'id';
      $scope.outputKey = $scope.outputKey || $scope.idKey || 'id';
      $scope.listDisplayKey = $scope.listDisplayKey || 'label';
      $scope.btnDisplayKey = $scope.btnDisplayKey || $scope.listDisplayKey || 'label';
      $scope.searchKey = $scope.searchKey || $scope.listDisplayKey || 'label';
      $scope.disableKey = $scope.disableKey || 'isDisabled';
    }

    /**
     * Initialize default values
     */
    function init() {
      $scope.outputItems = [];
      $scope.items = [];
      offset = 0;
      $scope.limit = $scope.fetchLimit || 20;

      setupDefaults();
      $scope.fetch();
      inited = true;
    }

    function updateSelections() {
      if ($scope.items && $scope.items.length > 0 && !$scope.isLoading) {
        angular.forEach($scope.items, (item) => {
          let found;
          if ($scope.isSingleSelect) {
            // No array in case of single select
            found = $scope.selectedItems && $scope.selectedItems[$scope.idKey] === item[$scope.idKey];
          } else {
            found = $scope.selectedItems && $scope.selectedItems.find(it => it[$scope.idKey] === item[$scope.idKey]);
          }
          // Update selected based on found/no-found
          // eslint-disable-next-line no-param-reassign
          item.selected = !!found;
        });
      }
    }

    function onDataFetchComplete() {
      $scope.items = angular.copy(itemsArr);
      updateSelections();
      itemsArr = [];
      const timer = $timeout(() => {
        $scope.fetchCompleteFn({ data: angular.copy($scope.items) });
      });
      timer.then(() => {
        $timeout.cancel(timer);
      });
    }

    function loadMoreData(currentCount, totalCount) {
      const timer = $timeout(() => {
        if (totalCount > 0 && currentCount < totalCount) {
          const newLimit = Math.max(Math.floor(totalCount / 10), $scope.limit);

          const promises = [];
          for (let i = currentCount; i < totalCount; i += newLimit) {
            offset = i;
            promises.push(
              $scope.fetchFn({
                params: {
                  offset,
                  limit: newLimit,
                },
              }),
            );
          }
          $q.all(promises).then((responses) => {
            for (let i = 0; i < responses.length; i += 1) {
              itemsArr = itemsArr.concat(responses[i].data);
            }
            $scope.isLoading = false;
            onDataFetchComplete();
          });
        } else {
          $scope.fetch(true);
        }
      }, 1000);
      timer.then(() => {
        $timeout.cancel(timer);
      });
    }

    $scope.onItemSelectionChange = () => {
      outputItemsUpdated = true;
      const timer = $timeout(() => {
        if ($scope.isSingleSelect) {
          // No array in case of single select
          [$scope.selectedItems] = $scope.outputItems;
        } else {
          $scope.selectedItems = $scope.outputItems;
        }
        const innerTimer = $timeout(() => {
          $scope.onSelectionChange();
        });
        innerTimer.then(() => {
          $timeout.cancel(innerTimer);
        });
      });
      timer.then(() => {
        outputItemsUpdated = false;
        $timeout.cancel(timer);
      });
    };

    $scope.fetch = (callNext, countLimit) => {
      // If static list provided, do not fetch
      if ($scope.useList) {
        return $q.resolve(true);
      }
      if (!callNext) {
        offset = 0;
        $scope.items = [];
        itemsArr = [];
      } else {
        offset = itemsArr.length;
      }

      $scope.isLoading = true;
      return $scope
        .fetchFn({
          params: {
            offset,
            limit: countLimit || $scope.limit,
          },
        })
        .then(
          (resp) => {
            if (resp && resp.data && resp.data.length > 0) {
              itemsArr = itemsArr.concat(resp.data);
              if (resp.count === itemsArr.length) {
                $scope.isLoading = false;
                onDataFetchComplete();
              } else {
                // Dont stop loading indicator yet
                loadMoreData(itemsArr.length, resp.count);
              }
            } else {
              $scope.isLoading = false;
              onDataFetchComplete();
            }
            return resp;
          },
          () => {
            $scope.isLoading = false;
          },
        );
    };

    watcherRemovers.push(
      $scope.$watch('itemsList', (n) => {
        if ($scope.useList) {
          itemsArr = n;
          onDataFetchComplete();
        }
      }),
    );

    watcherRemovers.push(
      $scope.$watch('reload', (n, o) => {
        if (n !== o && inited && n) {
          $scope.fetch();
          const timer = $timeout(() => {
            $scope.reload = false;
          });
          timer.then(() => {
            $timeout.cancel(timer);
          });
        }
      }),
    );

    watcherRemovers.push(
      $scope.$watch('isLoading', (n) => {
        if (n) {
          $scope.items = [
            {
              label: 'Loading ....',
              isDisabled: true,
            },
          ];
        }
      }),
    );

    watcherRemovers.push(
      $scope.$watch('selectedItems', (n, o) => {
        if (n !== o && !outputItemsUpdated) {
          updateSelections();
        }
      }),
    );

    $scope.$on('$destroy', () => {
      angular.forEach(watcherRemovers, (w) => {
        w();
      });
    });

    init();
  },
];
const directiveDef = [
  function selectPagedDirective() {
    return {
      restrict: 'E',
      template: require('./selectPaged.html'),
      scope: {
        controlId: '@',
        useList: '=?',
        itemsList: '=?',
        selectedItems: '=',
        btnDisplayKey: '@',
        listDisplayKey: '@',
        idKey: '@',
        searchKey: '@',
        outputKey: '@',
        disableKey: '@',
        isDisabled: '=?',
        isSingleSelect: '=?',
        reload: '=?',
        fetchFn: '&',
        fetchLimit: '=',
        fetchCompleteFn: '&',
        onSelectionChange: '&',
        tickProperty: '&',
      },
      controller: controllerDef,
    };
  },
];
export default directiveDef;
