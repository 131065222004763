const AUTO_ASSIGN_SETTING_NAME = 'auto_assign_vendor';
const ALL_AUTO_ASSIGN_SETTINGS = [
  { settingName: 'top_n_providers_value', type: 'int', editable: true },
  { settingName: 'cost_cutoff_value', type: 'int', editable: true },
  { settingName: 'expiry_time_value', type: 'int', editable: true },
  { settingName: 'quality_cutoff_value', type: 'int', editable: true },
  { settingName: 'dos_value', type: 'int', editable: true },
  { settingName: 'auto_assign_cutoff_value', type: 'int', editable: true },
  { settingName: 'mileage_cutoff_value', type: 'int', editable: true },
  { settingName: 'mileage_radius_value', type: 'int', editable: false },
  { settingName: 'dos_offer_expiry', type: 'json', editable: true },

  {
    settingName: 'cost_cutoff_condition', type: 'string', editable: false, defaultValue: 'lte',
  },
  {
    settingName: 'dos_condition', type: 'string', editable: false, defaultValue: 'gt',
  },
  {
    settingName: 'dos_unit', type: 'string', editable: true, dependent: 'dos_value',
  },
  {
    settingName: 'quality_cutoff_condition', type: 'string', editable: false, defaultValue: 'gte',
  },
  {
    settingName: 'auto_assign_cutoff_condition', type: 'string', editable: false, defaultValue: 'lte',
  },
  {
    settingName: 'auto_assign_cutoff_unit', type: 'string', editable: true, dependent: 'auto_assign_cutoff_value',
  },
  { settingName: 'dos_expiry_combination', type: 'bool', editable: false },


];
angular.module('relayHealth')
  .constant('AUTO_ASSIGN_SETTINGS', {
    AUTO_ASSIGN_SETTING_NAME,
    ALL_AUTO_ASSIGN_SETTINGS,
  });
