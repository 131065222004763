// import RIDE_STATUSES from '../ride/status';
const statuses = {
  Offered: 'Offered',
  Failed: 'Failed',
  UATP: 'PendingReOffer',
  NEPF: 'PendingReOffer',
  Confirmed: 'Accepted',
  NDA: 'NoDriverAssigned',
  'Ready To Process': 'PendingNewOrder',
  'Ride Canceled': 'Cancelled',
  'Failed-Other': 'FailedOther',
  'Pending - Re-Offer': 'PendingReOffer',
  'Driver Confirmed': 'DriverEnroute',
  'Driver Arrived': 'DriverArrived',
  'Patient Enroute': 'RiderEnroute',
  'Ride Completed': 'Completed',
  'Pending Return Request': 'PendingReturnRequest',
  'Request Overdue': 'RequestOverdue',
  'Provider No Show': 'ProviderNoShow',
  'Patient No Show': 'MemberNoShow',
  'Confirm Member Pickup': 'ConfirmPickup',
  'Offer Created': 'PendingNewOrder',
  'Confirm Member Dropoff': 'ConfirmDropoff',
  '24 Hour Confirmed': '24HourConfirmed',
  'Order Updated': 'PendingReOffer',
  'Retro Queued': 'PendingNewOrder',
  'Out Of Network': 'Accepted',
  'Offered NDA': 'Offered',
  'Variance Issue': 'Variance',
  'Finding Driver': 'FindingDriver',
  '24 Hour Confirmation Missing': 'Accepted',
  'Driver Canceled': 'DriverCancelled',
  'Potential Unavailability': 'PendingReOffer',
  'No Driver Available': 'NoDriverAvailable',
  'Arrival Time Exceeded': 'DriverLateArrival',
  'Late Arrival': 'RiderEnrouteLateArrival',
  'Pending Disposition': 'PendingDisposition',
};

module.exports = statuses;
