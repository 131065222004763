const statusConsts = {
    0: "Offered",
    3: "Confirmation Email Sent",
    4: "Confirmation Email: Accept",
    5: "Confirmation Email: Reject",
    6: "Confirmation Email: Expired",
    7: "Vendor Portal: Accept",
    8: "Vendor Portal: 24 Hr. Confirm",
    9: "Vendor Portal: Final Confirm",
    10: "Vendor Portal: Reject",
    11: "Rush: Accept",
    12: "Rush: 24 Hr. Confirm",
    13: "Rush: Final Confirm",
    14: "Rush: Remove Vendor",
    15: "Rush: Change Vendor",
    16: "Confirmations: Accept",
    17: "Confirmations: 24 Hr. Confirm",
    18: "Confirmations: Final Confirm",
    19: "Confirmations: Remove Vendor",
    20: "Confirmations: Change Vendor",
    21: "Claimant: Vendor Exclusion",
    22: "Vendor Compliance: Vendor Exclusion",
    23: "Assignment: Change Vendor",
    24: "Fuse: Vendor Inactive",
    25: "Assignment: Accept",
    26: "Assignment: 24 Hr. Confirm",
    27: "Assignment: Final Confirm",
    28: "Rush AutoAssign",
    29: "Rush Offer Email Sent",
    30: "Rush Offer Email: Accept",
    31: "Rush Offer Email: Reject",
    32: "Rush Offer Email: Expired",
    33: "Vendor Portal Offer: Accept",
    34: "Vendor Portal Offer: Reject",
    35: "Travel Event: Added to Travel Event",
    36: "Preferred Vendor",
    37: "Assignment Management: Accept",
    38: "Assignment Management: 24 Hr. Confirm",
    39: "Assignment Management: Final Confirm",
    40: "Assignment Management: Remove Vendor",
    41: "Assignment Management: Change Vendor"
};

Object.freeze(statusConsts);
export default statusConsts;