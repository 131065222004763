const { broadcasts: broadcastLib } = relaylib;

angular.module('relayHealth').service('ShowReleasePopupService', [
  '$uibModal',
  '$state',
  '$http',
  'API_BASE_URL',
  '$timeout',
  function ShowReleasePopupService($uibModal, $state, $http, API_BASE_URL, $timeout) {
    let modalInstance = null;

    let isInited = false;
    function show(data) {
      $timeout(() => {
        if (!modalInstance && data.tags) {
          modalInstance = $uibModal.open({
            template: `
              <div class="modal-header">
                  <button type="button" class="close" ng-click="cancel()">
                      <span aria-hidden="true">×</span><span class="sr-only">Close</span>
                  </button>
                  <h4 class="modal-title">New Version Released</h4>
              </div>
              <div class="modal-body">
                  <p>Welcome to RelaySOLUTIONS <strong>Version ${data.tags.version}</strong></p>
                  <p>Want to see what is new ?</p>
              </div>
              <div class="modal-footer">
                  <div class="checkbox pull-left">
                      <label>
                          <input type="checkbox" name="dontshow" ng-model="noShowAgain">
                          Do not show me again
                      </label>
                  </div>
                  <button type="button" class="btn btn-white" ng-click="cancel()">Exit</button>
                  <button type="button" class="btn btn-primary" ng-click="view()">View Now</button>
              </div>`,
            controller: [
              '$scope',
              '$uibModalInstance',
              ($scope, $uibModalInstance) => {
                $scope.cancel = () => {
                  if ($scope.noShowAgain) {
                    $uibModalInstance.close();
                  } else {
                    $uibModalInstance.dismiss('cancel');
                  }
                };
                $scope.view = () => {
                  $uibModalInstance.close(true);
                };
              },
            ],
            backdrop: 'static',
            windowTopClass: 'inmodal',
          });
          modalInstance.result.then(
            (isView) => {
              if (isView) {
                $state.go('main.dashboard.help.releaseNotesView');
              }
              $http.put(`${API_BASE_URL}broadcasts/useracted/${data.id}`);
              modalInstance = null;
            },
            () => {
              modalInstance = null;
            },
          );
        }
      });
    }
    this.show = show;
    this.init = (hardReset) => {
      const user = window.localStorage.getItem('userDetails');
      if (user && (!isInited || hardReset)) {
        const params = {
          status: broadcastLib.broadcastStatuses.ACTIVE,
          target: broadcastLib.broadcastTargets.WEB,
          category: broadcastLib.broadcastCategories.RELEASE_NOTES,
        };
        isInited = true;
        $http
          .get(`${API_BASE_URL}broadcasts/nonacted`, {
            params,
          })
          .then((resp) => {
            if (resp.data && resp.data.data && resp.data.data.length > 0) {
              show(resp.data.data[0]);
            }
            return resp.data;
          });
      }
    };
  },
]);
