angular.module('relayHealth').factory('filePathFactory', [
  function filePathFactory() {
    function getImagePathObjects() {
      return {
        FAV_ICON_PATH: require('../img/favicon-32x32.png'),
        PLUS_ICON_PATH: require('../img/add-plus-button.png'),
        RELAY_INVOICE_LOGO: require('../img/relay_logo1024.png'),
        INVOICE_METER_ICON: require('../img/meter_icon.png'),
        RIDE_ID_ICON: require('../img/RIDE_ID_ICON.png'),
        INVOICE_TRANSPORT_ICON: require('../img/transport_type.png'),
        INVOICE_LICENSE_ICON: require('../img/licence_plate.png'),
        INVOICE_CAR_MODEL_ICON: require('../img/car_model.png'),
        PRIVACY_POLICY_PATH: require('../views/privacy-policy.html'),
        ACTIVE_RIDE_AVTAR_ICON: require('../img/Avatar_icon/Male_icon48x48.png'),
        ACTIVE_RIDE_UBER_ICON: require('../img/uber_icon.png'),
        ACTIVE_RIDE_LYFT_ICON: require('../img/lyft_icon.png'),
        MAP_DEFAULT_IMAGE: require('../img/default-map-image.png'),
        TILE_TEMPLATE_PATH: `'${require('../views/tileView.html')}'`,
        INVOICE_APPT_TYPE: require('../img/app_type.png'),
        INVOICE_FACILITY_NAME: require('../img/name_of_facility.png'),
        INVOICE_PATIENT_ICON: require('../img/patient_2.png'),
        DOLLAR_IN_CIRCLE: require('../img/dollar_in_circle.svg'),
        USER_ICON: require('../img/Avatar_icon/Male_icon_white94x94.png'),
        EXTERNAL_ID_ICON: require('../img/EXTERNAL_LEG_ID.png'),
      };
    }

    return {
      getImagePathObjects,
    };
  },
]);
