const { permissionsConstants } = relaylib.permissions;

angular.module('relayHealth').controller('ReleaseNotesViewCtrl', [
  '$scope',
  '$state',
  '$http',
  'API_BASE_URL',
  'loadingScreenFactory',
  '$timeout',
  'SweetAlert',
  'permissionFilter',
  'toaster',
  function releaseNotesViewCtrl($scope, $state, $http, API_BASE_URL, loadingScreenFactory, $timeout, SweetAlert, permissionFilter, toaster) {
    const watcherRemovers = [];
    let allLoaded = false;

    let lastCount = 0;

    function init() {
      $scope.breadcrumbs = [
        {
          label: 'Home',
          onClick() {
            $state.go('main.dashboard.superAdminDashboard', null, { reload: true });
          },
        },
      ];

      $scope.broadcastTargets = relaylib.broadcasts.broadcastTargets;
      $scope.broadcastCats = relaylib.broadcasts.broadcastCategories;
      $scope.broadcastStatuses = relaylib.broadcasts.broadcastStatuses;
      $scope.dataRefetch = false;
      $scope.isLoading = false;
      $scope.showBack = permissionFilter(permissionsConstants.RELEASE_NOTES_CREATE);
      $scope.broadcastsData = [];
      allLoaded = false;
      lastCount = 0;
    }

    function refreshData() {
      SweetAlert.swal(
        {
          title: 'An update has been made to release notes. The list will be refreshed.',
          type: 'info',
          showCancelButton: false,
          confirmButtonClass: 'btn-primary',
          confirmButtonText: 'Ok',
          closeOnConfirm: true,
        },
        () => {
          lastCount = 0;
          allLoaded = false;
          $scope.broadcastsData = [];
          $scope.fetchData();
        },
      );
    }

    function checkIfMoreNeededForScroll() {
      $timeout(() => {
        if (!allLoaded && ($(window).height() >= $(document).height() || $('#vertical-timeline').height() < $(document).height() - 370)) {
          $scope.$emit('list:loadmore');
        }
      });
    }

    $scope.fetchData = () => {
      if (!allLoaded && !$scope.isLoading) {
        $scope.isLoading = true;
        const params = {
          limit: 2,
          offset: $scope.broadcastsData.length,
          sorton: 'createdAt',
          target: relaylib.broadcasts.broadcastTargets.WEB,
        };
        params.category = $scope.broadcastCats.RELEASE_NOTES;
        return $http
          .get(`${API_BASE_URL}broadcasts`, {
            params,
          })
          .then((resp) => {
            if (resp.data && resp.data.data && resp.data.data.length > 0) {
              $scope.broadcastsData = $scope.broadcastsData.concat(resp.data.data);
            }

            if (lastCount > 0 && lastCount !== resp.data.totalCount) {
              // An update occured, lets refresh the whole list
              refreshData();
            }

            // Store last count for later comparison
            lastCount = resp.data.totalCount;
            // Set to true if all items loaded and no more fetch is needed
            allLoaded = $scope.broadcastsData.length === lastCount;

            checkIfMoreNeededForScroll();
            return resp.data;
          })
          .catch((error) => {
            toaster.pop({
              title: (error.data || {}).message ? error.data.message : error.message,
              showCloseButton: true,
              type: 'error',
              timeout: 10000,
            });
          })
          .finally(() => {
            $scope.isLoading = false;
          });
      }
      return false;
    };

    watcherRemovers.push(
      $scope.$watch('isLoading', (n) => {
        if (n) {
          loadingScreenFactory.showLoadingScreen();
        } else {
          loadingScreenFactory.hideLoadingScreen();
        }
      }),
    );

    $scope.$on('$destroy', () => {
      angular.forEach(watcherRemovers, (w) => {
        w();
      });
    });

    init();
  },
]);
