angular.module('relayHealth').controller('organizationDetailsCtrl', [
  '$scope',
  '$http',
  'googleService',
  'API_BASE_URL',
  '$state',
  '$timeout',
  'signupStateService',
  'signupAuthCheckService',
  'SweetAlert',
  'signupInitialStateService',
  '$stateParams',
  'updateSignupStep',
  'signupNavigationValidationService',
  'WORKERS_COMPENSATION_ID',
  'HEALTHCARE_MASS_MARKET_KEY',
  'GOOGLE_PLACES_ATTRIBUTES',
  function organizationDetailsCtrl(
    $scope,
    $http,
    googleService,
    API_BASE_URL,
    $state,
    $timeout,
    signupStateService,
    signupAuthCheckService,
    SweetAlert,
    signupInitialStateService,
    $stateParams,
    updateSignupStep,
    signupNavigationValidationService,
    WORKERS_COMPENSATION_ID,
    HEALTHCARE_MASS_MARKET_KEY,
    GOOGLE_PLACES_ATTRIBUTES,
  ) {
    $scope.termsAndConditionsAccepted = false;
    $scope.signupType = '';
    $scope.userRole = '';
    $scope.acceptTc = false;
    $scope.validationTin = true;
    $scope.states = '';
    $scope.stageNumber = 4;
    $scope.organizationInfoResponseError = '';
    $scope.error = {
      name: '',
      address1: '',
      city: '',
      state: '',
      completeAddress: '',
      zip: '',
      tin: '',
      industry: '',
      market_segment_id: '',
    };
    $scope.industryList = [
      { name: 'Accounting' },
      { name: 'Airlines/Aviation' },
      { name: 'Alternative Dispute Resolution' },
      { name: 'Alternative Medicine' },
      { name: 'Animation' },
      { name: 'Apparel and Fashion' },
      { name: 'Architecture and Planning' },
      { name: 'Arts and Crafts' },
      { name: 'Automotive' },
      { name: 'Aviation and Aerospace' },
      { name: 'Banking' },
      { name: 'Biotechnology' },
      { name: 'Broadcast Media' },
      { name: 'Building Materials' },
      { name: 'Business Supplies and Equipment' },
      { name: 'Capital Markets' },
      { name: 'Chemicals' },
      { name: 'Civic and Social Organization' },
      { name: 'Civil Engineering' },
      { name: 'Commercial Real Estate' },
      { name: 'Computer and Network Security' },
      { name: 'Computer Games' },
      { name: 'Computer Hardware' },
      { name: 'Computer Networking' },
      { name: 'Computer Software' },
      { name: 'Construction' },
      { name: 'Consumer Electronics' },
      { name: 'Consumer Goods' },
      { name: 'Consumer Services' },
      { name: 'Cosmetics' },
      { name: 'Dairy' },
      { name: 'Defense and Space' },
      { name: 'Design' },
      { name: 'Education Management' },
      { name: 'E-Learning' },
      { name: 'Electrical/Electronic Manufacturing' },
      { name: 'Entertainment' },
      { name: 'Environmental Services' },
      { name: 'Events Services' },
      { name: 'Executive Office' },
      { name: 'Facilities Services' },
      { name: 'Farming' },
      { name: 'Financial Services' },
      { name: 'Fine Art' },
      { name: 'Fishery' },
      { name: 'Food and Beverages' },
      { name: 'Food Production' },
      { name: 'Fund-Raising' },
      { name: 'Furniture' },
      { name: 'Gambling and Casinos' },
      { name: 'Glass, Ceramics and Concrete' },
      { name: 'Government Administration' },
      { name: 'Government Relations' },
      { name: 'Graphic Design' },
      { name: 'Health, Wellness and Fitness' },
      { name: 'Higher Education' },
      { name: 'Hospital and Health Care' },
      { name: 'Hospitality' },
      { name: 'Human Resources' },
      { name: 'Import and Export' },
      { name: 'Individual and Family Services' },
      { name: 'Industrial Automation' },
      { name: 'Information Services' },
      { name: 'Information Technology and Services' },
      { name: 'Insurance' },
      { name: 'International Affairs' },
      { name: 'International Trade and Development' },
      { name: 'Internet' },
      { name: 'Investment Banking' },
      { name: 'Investment Management' },
      { name: 'Judiciary' },
      { name: 'Law Enforcement' },
      { name: 'Law Practice' },
      { name: 'Legal Services' },
      { name: 'Legislative Office' },
      { name: 'Leisure, Travel and Tourism' },
      { name: 'Libraries' },
      { name: 'Logistics and Supply Chain' },
      { name: 'Luxury Goods and Jewelry' },
      { name: 'Machinery' },
      { name: 'Management Consulting' },
      { name: 'Maritime' },
      { name: 'Marketing and Advertising' },
      { name: 'Market Research' },
      { name: 'Mechanical or Industrial Engineering' },
      { name: 'Media Production' },
      { name: 'Medical Devices' },
      { name: 'Medical Practice' },
      { name: 'Mental Health Care' },
      { name: 'Military' },
      { name: 'Mining and Metals' },
      { name: 'Motion Pictures and Film' },
      { name: 'Museums and Institutions' },
      { name: 'Music' },
      { name: 'Nanotechnology' },
      { name: 'Newspapers' },
      { name: 'Non-Profit Organization Management' },
      { name: 'Oil and Energy' },
      { name: 'Online Media' },
      { name: 'Outsourcing/Offshoring' },
      { name: 'Package/Freight Delivery' },
      { name: 'Packaging and Containers' },
      { name: 'Paper and Forest Products' },
      { name: 'Performing Arts' },
      { name: 'Pharmaceuticals' },
      { name: 'Philanthropy' },
      { name: 'Photography' },
      { name: 'Plastics' },
      { name: 'Political Organization' },
      { name: 'Primary/Secondary Education' },
      { name: 'Printing' },
      { name: 'Professional Training and Coaching' },
      { name: 'Program Development' },
      { name: 'Public Policy' },
      { name: 'Public Relations and Communications' },
      { name: 'Public Safety' },
      { name: 'Publishing' },
      { name: 'Railroad Manufacture' },
      { name: 'Ranching' },
      { name: 'Real Estate' },
      { name: 'Recreational Facilities and Services' },
      { name: 'Religious Institutions' },
      { name: 'Renewables and Environment' },
      { name: 'Research' },
      { name: 'Restaurants' },
      { name: 'Retail' },
      { name: 'Security and Investigations' },
      { name: 'Semiconductors' },
      { name: 'Shipbuilding' },
      { name: 'Sporting Goods' },
      { name: 'Sports' },
      { name: 'Staffing and Recruiting' },
      { name: 'Supermarkets' },
      { name: 'Telecommunications' },
      { name: 'Textiles' },
      { name: 'Think Tanks' },
      { name: 'Tobacco' },
      { name: 'Translation and Localization' },
      { name: 'Transportation/Trucking/Railroad' },
      { name: 'Utilities' },
      { name: 'Venture Capital and Private Equity' },
      { name: 'Veterinary' },
      { name: 'Warehousing' },
      { name: 'Wholesale' },
      { name: 'Wine and Spirits' },
      { name: 'Wireless' },
      { name: 'Writing and Editing' },
    ];
    $scope.googlePlacesAttributes = GOOGLE_PLACES_ATTRIBUTES;
    $scope.addressObj = {};
    function init() {
      $scope.state = signupInitialStateService.initialState();
      let hasErrors = false;
      if ($scope.state.currentPage < 4) {
        signupNavigationValidationService.termsOfServiceValidation((errors) => {
          if (Object.keys(errors).length > 0) {
            hasErrors = true;
            $state.go('main.business_signup_terms_of_service', { errors });
          }
        });
      }

      if ($stateParams.errors) {
        Object.keys($stateParams.errors).forEach((err) => {
          $scope.error[err] = $stateParams.errors[err];
        });

        $timeout(() => {
          $scope.error = {
            name: '',
            address1: '',
            city: '',
            completeAddress: '',
            state: '',
            zip: '',
            tin: '',
            industry: '',
            market_segment_id: '',
          };
        }, 10000);
      }

      if (!hasErrors) {
        if ($scope.state.businessInfo) {
          if (
            $scope.state.businessInfo.market_segment_id
              === WORKERS_COMPENSATION_ID
            || $scope.state.businessInfo.market_segment_key
              === HEALTHCARE_MASS_MARKET_KEY
          ) {
            $scope.validationTin = false;
          }
          const email = $scope.state.email ? $scope.state.email.toLowerCase() : '';
          const { orgName } = $scope.state.businessInfo;
          $scope.state.businessInfo.orgName = orgName.indexOf(email) >= 0 ? '' : orgName;
        }
        if ($scope.stageNumber > $scope.state.latestStage) {
          $scope.state.latestStage = 4;
          signupStateService.setState('signupState', $scope.state);
        }
        $scope.state.currentPage = 4;
        signupStateService.setState('signupState', $scope.state);
        signupAuthCheckService.organizationDetailsAllowed();
        const industry = $scope.state.businessInfo.industry || '';
        for (let i = 0; i < $scope.industryList.length; i += 1) {
          if (industry === $scope.industryList[i].name) {
            $scope.state.businessInfo.industry = $scope.industryList[i];
            break;
          }
        }
        $http
          .get(`${API_BASE_URL}states`)
          .then(({ data }) => {
            if (data.success) {
              $scope.states = data.states;
              const stateName = $scope.state.businessInfo.state ? parseInt($scope.state.businessInfo.state, 10) : '';
              if (typeof stateName === 'number') {
                for (let i = 0; i < data.states.length; i += 1) {
                  if (data.states[i].id === stateName) {
                    $scope.state.businessInfo.state = data.states[i].id;
                    $scope.selectedState = data.states[i];
                    signupStateService.setState('signupState', $scope.state);
                    break;
                  }
                }
              }
            }
          })
          .catch(() => {
            // could not organization details
          });
        updateSignupStep(3);
      }
    }

    init();

    const geocoder = new google.maps.Geocoder();
    const parseAddressString = function parseAddressString(
      address,
    ) {
      return new Promise((resolve, reject) => {
        geocoder.geocode(
          {
            address,
          },
          (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              resolve(results);
            } else reject(results);
          },
        );
      });
    };
    const getAddressComponents = (place, setterPincodeProperty) => {
      let pincode;
      let country;
      let state;
      let city;
      place.address_components.forEach((obj) => {
        if (obj.types.includes('country')) {
          country = obj.long_name;
          $scope.addressObj.country = country;
          $scope.state.businessInfo.country = country;
        } else if (obj.types.includes('administrative_area_level_1')) {
          state = obj.short_name;// In order to apply check on state Code in DB.
          $scope.addressObj.state_name = state;
          for (let i = 0; i < $scope.states.length; i += 1) {
            if ($scope.states[i].state_code === $scope.addressObj.state_name) {
              $scope.state.businessInfo.state_id = $scope.states[i].id;
            }
          }
        } else if (obj.types.includes('locality')) {
          city = obj.long_name;
          $scope.addressObj.city = city;
          $scope.state.businessInfo.city = city;
        } else if (obj.types.includes('postal_code')) {
          pincode = obj.short_name;
          $scope.addressObj.zip = pincode;
          $scope.state.businessInfo[setterPincodeProperty] = pincode;
        }
      });
      if (pincode) {
        $scope.addressObj.zip = pincode;
        $scope.state.businessInfo[setterPincodeProperty] = pincode;
        return;
      }
      const latLng = {
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      };
      googleService.geocode(latLng).then((results) => {
        results.forEach((element) => {
          element.address_components.forEach((component) => {
            if (component.types.includes('postal_code')) {
              pincode = component.short_name;
              $scope.addressObj.zip = pincode;
              $scope.state.businessInfo[setterPincodeProperty] = pincode;
            }
          });
        });
      });
    };
    const setOrgAddressDetails = function setOrgAddressDetails(addressDetails) {
      const address = {
        address1: addressDetails.name, // In order to resolve the issue of showing duplicate address
        lat: addressDetails.geometry.location.lat(),
        lng: addressDetails.geometry.location.lng(),
      };
      Object.assign($scope.state.businessInfo, address);
      $scope.$apply();
    };
    let isAddressSelectedFromSuggestions = false;

    $scope.addressChanged = async function addressChanged() {
      let addressInfo = this.getPlace();
      isAddressSelectedFromSuggestions = true;
      if (
        !addressInfo.address_components
        && !addressInfo.geometry
      ) {
        const result = await parseAddressString(addressInfo.name);
        [addressInfo] = result;
        [addressInfo.name] = (addressInfo.formatted_address && addressInfo.formatted_address.split(',')) || [''];
      }
      getAddressComponents(
        addressInfo,
        'zip',
      );
      setOrgAddressDetails(addressInfo);
      $scope.myBusinessForm.completeAddress.$setValidity('valid', true);
    };
    $scope.setAddressInvalid = function setAddressInvalid() {
      $timeout(() => {
        let ngChangeTriggered = true;
        if (isAddressSelectedFromSuggestions) {
          ngChangeTriggered = false;
          isAddressSelectedFromSuggestions = false;
        }
        // To reduce the chances of modal updation.
        if (ngChangeTriggered) {
          $scope.addressObj.zip = null;
          if ($scope.state.businessInfo.completeAddress) {
            $scope.myBusinessForm.completeAddress.$setValidity('valid', false);
          } else { $scope.myBusinessForm.completeAddress.$setValidity('valid', true); }
        } else {
          $scope.myBusinessForm.completeAddress.$setValidity('valid', true);
        }
      }, 1000);
    };
    $scope.nextPage = () => {
      const state = signupStateService.getState('signupState');
      const body = angular.copy($scope.state.businessInfo);
      body.signupType = $scope.state.signupType;
      body.industry = $scope.state.businessInfo.industry.name;
      body.userId = $scope.state.userId;
      if (body.TIN) {
        body.tin = angular.copy(body.TIN);
        body.tin = body.tin.replace(/[- )(]/g, '');
      } else {
        body.tin = '';
      }

      body.name = body.orgName;
      delete body.interest;
      delete body.TIN;
      delete body.state;
      delete body.signupType;
      delete body.orgName;
      body.step = 4;
      $http
        .put(`${API_BASE_URL}rider/update`, [body])
        .then(({ data }) => {
          state.businessInfo.completeAddress = $scope.state.businessInfo.completeAddress;
          signupStateService.setState('signupState', state);
          if (data.success && !state.addUsersAlertShown) {
            SweetAlert.swal(
              {
                title: 'Do you want to set up users now?',
                text: 'Add additional users to your business account now or set up users in Relay after you have set up your account',
                type: 'success',
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes',
                closeOnCancel: true,
                cancelButtonText: 'No',
                showCancelButton: true,
              },
              (result) => {
                const signupState = signupStateService.getState('signupState');
                state.addUsersAlertShown = true;
                signupStateService.setState('signupState', state);
                if (!result) {
                  if (!signupState.created_by_user) {
                    $state.go('main.business_signup_payment');
                  } else {
                    $state.go('main.business_signup_summary');
                  }
                } else {
                  $state.go('main.business_signup_add_users');
                }
              },
            );
          } else if (!data.success) {
            // show the error
            const errors = data.errorMessages;
            if (typeof errors === 'string') {
              $scope.organizationInfoResponseError = errors;
            } else {
              Object.keys(errors).forEach((err) => {
                $scope.error[err] = errors[err];
              });
            }

            $timeout(() => {
              $scope.organizationInfoResponseError = '';
              $scope.error = {
                name: '',
                address1: '',
                city: '',
                completeAddress: '',
                state: '',
                zip: '',
                tin: '',
                industry: '',
              };
            }, 5000);
          } else {
            $state.go('main.business_signup_add_users');
          }
        })
        .catch(({ data }) => {
          // error handling code
          // error should be displayed below the respective field
          const errors = data.errorMessages;
          if (typeof errors === 'string') {
            $scope.organizationInfoResponseError = errors;
          } else {
            Object.keys(errors).forEach((err) => {
              $scope.error[err] = errors[err];
            });
          }

          $timeout(() => {
            $scope.organizationInfoResponseError = '';
            $scope.error = {
              name: '',
              address1: '',
              city: '',
              state: '',
              zip: '',
              tin: '',
              industry: '',
            };
          }, 5000);
        });
    };

    $scope.changeObj = (obj, key, objValue) => {
      if (!obj || !key) {
        return false;
      }

      let value = objValue;

      if (key === 'state') {
        for (let i = 0; i < $scope.states.length; i += 1) {
          if ($scope.states[i].id === objValue.id) {
            $scope.selectedState = $scope.states[i];
            break;
          }
        }
        value = objValue.id;
      }

      const state = signupStateService.getState('signupState');
      if (state && state[obj]) {
        state[obj][key] = value;
        $scope.state[obj][key] = value;
        signupStateService.setState('signupState', state);
      }
      return true;
    };

    $scope.previousPage = () => $state.go('main.business_signup_terms_of_service');
  },
]);
