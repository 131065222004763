angular.module('relayHealth').controller('invoiceCtrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  '$state',
  'SweetAlert',
  'DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID',
  '$uibModal',
  '$q',
  'loadingScreenFactory',
  'toaster',
  'providerService',
  'orgHierarchyFactory',
  '$filter',
  function invoiceCtrl(
    $scope,
    $http,
    API_BASE_URL,
    $state,
    SweetAlert,
    DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID,
    $uibModal,
    $q,
    loadingScreenFactory,
    toaster,
    providerService,
    orgHierarchyFactory,
    $filter,
  ) {
    let healthPlan;
    let planState;
    const variance = ($state.params && $state.params.variance) || false;
    const apptId = ($state.params && $state.params.appt_id) || 902;
    const COST_PLUS = 'cost plus';
    const COST_PLUS_FLAT_FEE = 'cost plus flat fee';
    $scope.PMPM = 'pmpm';
    $scope.COST_PER_MILE = 'cost per mile';
    $scope.PATIENT_NO_SHOW = 'Patient No Show';
    $scope.dbOrgTypes = relaylib.organisation.constants.dbOrgTypes;
    $scope.isRideOrder = false;
    $scope.DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID = DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID;
    $scope.permissionsConstants = relaylib.permissions.permissionsConstants;
    const providerMapping = relaylib.common.constants.providerIdMapping;
    const ghRideShareRideProviders = [providerMapping.LYFT, providerMapping.UBER];
    $scope.invoiceDetails = {};
    $scope.isPrRide = false;
    $scope.billingMethodInvoice = false;
    $scope.moment = moment;
    $scope.getVendorFareBreakdown = (ride) => {
      const { receipt: { fare_breakdown: fareBreakdown = [] } = {}, productSubGroup = '', provider_id: providerId } = ride;
      const isSedan = (productSubGroup.toLowerCase() === 'sedan');
      let updateFareBreakdown = fareBreakdown;
      if (fareBreakdown.length && providerId === 4) {
        const riderNoShowFee = _.filter(fareBreakdown, { type: 'Rider No Show' });
        if (riderNoShowFee.length) {
          updateFareBreakdown = riderNoShowFee;
        } else {
          const flatFee = _.filter(fareBreakdown, { type: 'Flat Rate' });
          if (flatFee.length) {
            updateFareBreakdown = flatFee;
          } else {
            updateFareBreakdown = isSedan ? _.filter(fareBreakdown, { type: 'Base Rate' }) : _.filter(fareBreakdown, { type: 'Load fee' });
            const milesFee = _.find(fareBreakdown, fare => fare.type.includes(' mile'));
            if (milesFee) {
              milesFee.type = 'mile';
              updateFareBreakdown.push(milesFee);
            }
          }
        }
        updateFareBreakdown.forEach((fare, index) => {
          const { type } = fare;
          if (['Flat Rate', 'Base Rate'].includes(type)) {
            updateFareBreakdown[index].type = type.replace('Rate', 'fee');
          } else if (type === 'Rider No Show') {
            updateFareBreakdown[index].type = 'Claimant No Show Rate';
          }
        });
      }
      return updateFareBreakdown;
    };
    $scope.getFeeType = (ride = {}) => {
      let feeType;
      let currentFeeType;
      const { receipt: { occm_fee_type: occmFeeType = '', additional_details: additionalDetails = {} } = {} } = ride;
      if (additionalDetails) {
        ({ currentFeeType } = additionalDetails);
      }
      feeType = occmFeeType || currentFeeType;
      if (feeType === COST_PLUS) {
        feeType = COST_PLUS_FLAT_FEE;
      }
      return feeType ? $filter('sanitize')(feeType) : $scope.PMPM;
    };
    const getCalculatedDetails = function getCalculatedDetails() {
      let unit = '';
      if ($scope.apptDetails.ride_details[0].receipt) {
        unit = $scope.apptDetails.ride_details[0].receipt.currency_code;
      }

      let totalDue = 0;
      for (let i = 0; i < $scope.apptDetails.ride_details.length; i += 1) {
        if ($scope.apptDetails.ride_details[i].receipt) {
          if (!$scope.apptDetails.ride_details[i].receipt.final_ride_cost) {
            $scope.apptDetails.ride_details[i].receipt.final_ride_cost = 0;
          }
          totalDue += parseFloat(
            $scope.apptDetails.ride_details[i].receipt.final_ride_cost,
          );
          unit = $scope.apptDetails.ride_details[i].receipt.currency_code;
        }
      }
      $scope.invoiceDetails.totalDue = totalDue;
      $scope.invoiceDetails.unit = unit;
    };

    const setDataIfFirstRideDetails = function setDataIfFirstRideDetails(firstLegDetails) {
      if ($scope.isRideOrder) {
        $scope.tripDateTime = _.get(firstLegDetails, 'ride_additional_detail.appointment_date');
      } else {
        $scope.tripDateTime = _.get(firstLegDetails, 'appt_date_time');
      }
      $scope.invoiceDetails.pickupJourney = {
        ride_detail: firstLegDetails,
      };
      if (firstLegDetails.is_pr_ride) {
        $scope.isPrRide = true;
      }
    };

    const setDataIfFirstReceiptAvailable = function setDataIfFirstReceiptAvailable(firstReceiptDetails) {
      $scope.invoiceDetails.pickupJourney.receipt = firstReceiptDetails;
      if (firstReceiptDetails.billing_method === 'invoice') {
        $scope.billingMethodInvoice = true;
      }
    };

    this.editReceiptFlag = false;

    const getInvoiceDetails = async () => {
      let url = `${API_BASE_URL}invoice/getInvoiceDetailsByApptId/${apptId}`;
      if (variance) {
        url = `${url}/${variance}`;
      }

      const userDetails = JSON.parse(localStorage.getItem('userDetails'));

      try {
        const {
          data: { data } = {},
        } = await $http.get(url, {
          params: {
            org_id: userDetails.organisation.id,
          },
        });

        if (!data) {
          return;
        }
        $scope.isGHTRide = data.organisation.market_segment.key === 'GHT';

        Object.assign($scope.invoiceDetails, {
          patient: data.patient,
          organisation: data.organisation,
          invoice: data.invoice,
          invoice_generation_time: data.invoice_generation_time,
        });
        if (data.ride_details[0] && data.ride_details[0].ride_additional_detail && data.ride_details[0].ride_additional_detail.source_address_json) {
          planState = data.ride_details[0].ride_additional_detail.source_address_json.state;
        }
        if (data.external_customer) {
          healthPlan = data.external_customer.customer_account_number;
        }
        const softwareProviderId = _.get(data, 'ride_details[0].vendor_lists[0].vendor_list_details[0].softwareId');
        $scope.isVendorWithSoftwareProviderId = !!softwareProviderId;
        $scope.apptDetails = data;

        $scope.apptDetails.zone = moment.tz($scope.apptDetails.appt_timezone).zoneAbbr();

        $scope.isRideOrder = !!$scope.apptDetails.external_order_id;
        $scope.isMASRide = (_.get($scope.apptDetails, 'additional_details.external_identifier', '') === 'MAS');
        $scope.orgType = _.get($scope.apptDetails, 'organisation.org_type', '');
        $scope.isGHTShareRide = ($scope.isRideOrder && ghRideShareRideProviders.includes($scope.apptDetails.provider_id));
        if (data.ride_details && data.ride_details.length > 0) {
          setDataIfFirstRideDetails(data.ride_details[0]);
        }

        if (data.receipts && data.receipts.length > 0) {
          setDataIfFirstReceiptAvailable(data.receipts[0]);
        }

        getCalculatedDetails();

        if ($scope.invoiceDetails.organisation.customizable_file_number) {
          $scope.fileNumberLabel = $scope.invoiceDetails.organisation.file_number_label;
        } else {
          try {
            const { data: { orgsWithInheritedSettings: [{ file_number_label: ancestorfileNumberLabel } = {}] = [] } } = await orgHierarchyFactory.getOrgsWithInheritedSettings($scope.invoiceDetails.organisation.id,
              [
                'file_number_label',
              ]);
            if (ancestorfileNumberLabel) {
              $scope.fileNumberLabel = ancestorfileNumberLabel;
            }
          } catch (error) {
            toaster.pop({
              type: 'error',
              title: (error.data || {}).message ? error.data.message : error.message,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        }

        const settings = await Promise.all([
          $http.get(`${API_BASE_URL}organisation/${data.org_id}/settings/edit_receipt`),
          $http.get(`${API_BASE_URL}organisation/${data.org_id}/settings/disable_edit_finalized_receipt`),
        ]);

        const [{
          data: {
            settings: { edit_receipt: editReceipt = 'false' } = {},
          } = {},
        }, {
          data: {
            settings: { disable_edit_finalized_receipt: disableEditFinalInvoice = 'false' } = {},
          } = {},
        }] = settings;

        if (JSON.parse(disableEditFinalInvoice) === true && $scope.apptDetails.invoice_generated) {
          return;
        }

        this.editReceiptFlag = JSON.parse(editReceipt) === true;
      } catch (err) {
        toaster.pop({
          type: 'error',
          title: err.message,
          showCloseButton: true,
          timeout: 10000,
        });
      } finally {
        $scope.$apply();
      }
    };
    getInvoiceDetails();

    $scope.getProviderName = function getProviderName(providerId, rideDetail) {
      // provider name for traditional vendors are saved in driver_name of ride details
      if (rideDetail && rideDetail.provider_id === providerMapping.EXTERNAL) {
        const providerName = _.get(rideDetail, 'vendor_lists[0].vendor_list_details[0].name');
        return providerName;
      }
      return providerService.getProviderNameById(providerId);
    };

    $scope.ridelog = [];
    $scope.getLogs = (rideId, index) => {
      $(`#log${index}`).toggle();
      const getRideLog = $http({
        url: `${API_BASE_URL}logs/getLogDetails/auditLogs/${rideId}`,
        method: 'GET',
      });
      getRideLog.then((response) => {
        $scope.ridelog[index] = response.data.logDetails;
      });
    };

    $scope.calculateMins = (end, start) => {
      const mins = Math.floor((end - start) / 60);
      const secs = (end - start) % 60;
      return `${mins}:${secs} mins`;
    };

    // the code is getting added
    $scope.variances = [
      { value: 1, name: 'Billable' },
      { value: 2, name: 'Waived' },
    ];

    function showVarianceUpdatedAlert() {
      SweetAlert.swal(
        {
          title: 'Variance Type Changed',
          // text: "A One Call Representative will be in contact with you shortly",
          type: 'success',
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'OK',
        },
        () => {
          $state.go('main.dashboard.completed');
        },
      );
    }

    $scope.changeVariance = function changeVariance(rideId, varianceType) {
      const data = {
        variance_type: varianceType,
      };
      const updateVariance = $http({
        url: `${API_BASE_URL}ride/updateVariance/${rideId}`,
        method: 'PUT',
        data,
      });
      updateVariance.then(
        () => {
          showVarianceUpdatedAlert();
        },
        () => {
          showVarianceUpdatedAlert();
        },
      );
    };

    const modalPromiseResolve = function modalPromiseResolve(modalInstance) {
      modalInstance.result.then((result) => {
        if (result.success) {
          getInvoiceDetails();
        }
      });
    };

    $scope.openEditInvoicePopup = function openEditInvoicePopup(event, rideId) {
      event.stopPropagation();
      loadingScreenFactory.showLoadingScreen();
      const modalInstance = $uibModal.open({
        animation: true,
        template: require('./editReceiptPopup.html'),
        appendTo: $('#wrapper'),
        controller: 'editReceiptPopupCtrl as $ctrl',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          async rideAndReceiptData() {
            try {
              const {
                data: {
                  receiptDetails,
                } = {},
              } = await $http.get(
                `${API_BASE_URL}ride/receipt/${rideId}`,
              );

              if (!receiptDetails.receipt) {
                throw new Error('Receipt not found');
              }

              const { data: { reasonCodes } } = await $http.get(
                `${API_BASE_URL}receipt/reasoncode/${receiptDetails.organisation.id}`,
              );

              return {
                receiptDetails, reasonCodes, healthPlan, planState,
              };
            } catch (err) {
              toaster.pop({
                type: 'error',
                title: (err.data && err.data.message) || err.message,
                showCloseButton: true,
                timeout: 10000,
              });

              throw err;
            } finally {
              loadingScreenFactory.hideLoadingScreen();
            }
          },
        },
      });
      modalPromiseResolve(modalInstance);
    };

    $scope.regenerateInvoice = function regenerateInvoice() {
      const backendData = {
        appt_id: apptId,
      };
      const regenerateInvoiceCall = $http.post(
        `${API_BASE_URL}invoice/regenerate`,
        backendData,
      );
      regenerateInvoiceCall.then(
        (result) => {
          if (result.data.success) {
            SweetAlert.swal({
              title: result.data.message,
              text: result.data.message,
              type: 'success',
            });
          }
        },
        (err) => {
          toaster.pop({
            type: 'error',
            title: err.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    };

    $scope.formatDate = function formatDate(timestampInSeconds) {
      if (timestampInSeconds && moment.unix(timestampInSeconds).isValid()) {
        return moment.unix(timestampInSeconds).tz($scope.apptDetails.appt_timezone).format('MM/DD/YYYY hh:mm A z');
      }
      return 'N/A';
    };

    this.moveToPreviousPage = () => {
      const { retroQueueTab } = $state.params;
      if (retroQueueTab) {
        $state.go('main.dashboard.superAdminDashboard', {
          opentab: 'retroQueueRides',
        });
      } else {
        window.history.back();
      }
    };
  },
]);
