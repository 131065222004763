angular.module('relayHealth')
  .controller('retroQueueOrderCompleteModal', [
    'API_BASE_URL',
    '$http',
    'toaster',
    '$state',
    'orderDetails',
    '$uibModalInstance',
    function retroQueueOrderCompleteModal(API_BASE_URL, $http, toaster, $state, orderDetails, $uibModalInstance) {
      this.orderCompleteDetails = {
        apptId: orderDetails.apptId,
        orderId: orderDetails.orderId,
        rideNotes: null,
      };

      this.confirmDisable = false;

      this.closeModal = () => {
        $uibModalInstance.dismiss('cancel');
      };

      this.confirmOrderComplete = async () => {
        if (!this.orderCompleteDetails.rideNotes) {
          toaster.pop({
            type: 'error',
            title: 'Notes are required to mark Order Complete.',
            timeout: 3000,
          });
          return;
        }
        const requestData = {
          orderId: this.orderCompleteDetails.orderId,
          note: this.orderCompleteDetails.rideNotes,
        };

        this.confirmDisable = true;
        const {
          apptId,
        } = this.orderCompleteDetails;
        $http
          .patch(`${API_BASE_URL}vendors/appt/${apptId}/clear-retro-queue-order`, requestData)
          .then(() => {
            this.closeModal();
            toaster.pop({
              type: 'success',
              title: 'Ride order marked as Order Complete',
              timeout: 3000,
            });
            // get tab data now.
          })
          .catch((errorResult) => {
            this.closeModal();
            toaster.pop({
              type: 'error',
              title: `Error while marking Order as Complete : ${errorResult.data.message}`,
              timeout: 3000,
            });
            this.confirmDisable = false;
          });
      };
    },
  ]);
