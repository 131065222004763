angular
  .module('relayHealth')
  .factory('urlFactory', () => ({
    getParameterByName(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);

      const results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
  }))
  .factory('uuid', () => {
    const svc = {
      new() {
        function p8(s) {
          const p = `${Math.random().toString(16)}000000000`.substr(2, 8);
          return s ? `-${p.substr(0, 4)}-${p.substr(4, 4)}` : p;
        }
        return p8() + p8(true) + p8(true) + p8();
      },

      empty() {
        return '00000000-0000-0000-0000-000000000000';
      },
    };

    return svc;
  });
