const {
  permissions: { permissionsConstants },
} = relaylib;

function routeConfig($stateProvider, $urlRouterProvider, IdleProvider, $httpProvider, $locationProvider) {
  $locationProvider.html5Mode(true);
  // Configure Idle settings
  IdleProvider.idle(60);
  IdleProvider.timeout(3600);

  $urlRouterProvider.otherwise('/main/dashboard/super-admin-dashboard');
  // $http interceptor

  $httpProvider.interceptors.push([
    '$q',
    '$injector',
    'MAX_SERVER_ERROR_RETRY',
    function interceptor($q, $injector, MAX_SERVER_ERROR_RETRY) {
      let flagSessionExpired = false;
      return {
        request(config) {
          const userDetails = JSON.parse(localStorage.getItem('userDetails'));
          if (userDetails) {
            Object.assign(config.headers, {
              usertoken: userDetails.user_token,
              username: userDetails.username,
              // disable IE ajax request caching
              'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
              // extra
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
            });
          } else {
            const $location = $injector.get('$location');
            // pages that can open without login
            if (
              $location.$$path.indexOf('/webtrack')
              && $location.$$path.indexOf('/generateInvoiceLambda') === -1
              && $location.$$path.indexOf('/generate-map') === -1
              && $location.$$path.indexOf('/secure-email') === -1
              && $location.$$path !== '/reset'
              && $location.$$path !== '/resetpassword'
              && $location.$$path !== '/forgot_password'
              && $location.$$path !== '/privacy_policy'
              && $location.$$path !== '/signup-confirm-password'
              && $location.$$path !== '/business-signup-redirect'
              && $location.$$path !== '/appointment/deny'
              && $location.$$path !== '/sso-redirect-url'
            ) {
              $location.path('/login');
            }
          }
          return config;
        },
        response(res) {
          const sessionExpiryTime = res.headers('session-valid-till');
          if (sessionExpiryTime) {
            const SessionTimeoutHandler = $injector.get('SessionTimeoutHandler');
            SessionTimeoutHandler.setExpiryTime(sessionExpiryTime);
          }
          flagSessionExpired = true;
          return res;
        },
        responseError(rejection) {
          const $window = $injector.get('$window');
          const $rootScope = $injector.get('$rootScope');
          const SweetAlert = $injector.get('SweetAlert');
          const pubnubNotificationFactory = $injector.get('pubnubNotificationFactory');
          const userDetails = JSON.parse(localStorage.getItem('userDetails'));
          if (rejection.status === 401 && flagSessionExpired) {
            $rootScope.$emit('logout');
            if (angular.isDefined($rootScope.modal)) {
              $rootScope.modal.close();
            }
            SweetAlert.swal(
              {
                title: 'Session Expired',
                text: 'Please log back into Relay',
                type: 'warning',
              },
              () => {
                window.onkeydown = null;
                let redirectTo = `/${process.env.PUBLIC_URL_PREFIX}current/login`;
                if (userDetails && userDetails.sso_logout_url) {
                  redirectTo = userDetails.sso_logout_url;
                }
                $window.location.href = redirectTo;
              },
            );
            flagSessionExpired = false;
            pubnubNotificationFactory.unsubscribeAllChannel();
            localStorage.clear();
          } else if ([503, 502, 521, 504, 405].includes(rejection.status)) {
            let serverErrorCount = parseInt(localStorage.getItem('serverErrorCount'), 10);
            if (!serverErrorCount) {
              serverErrorCount = 0;
            }
            serverErrorCount += 1;
            localStorage.setItem('serverErrorCount', serverErrorCount);
            if (serverErrorCount >= MAX_SERVER_ERROR_RETRY) {
              SweetAlert.swal(
                {
                  title: 'Something went wrong',
                  text: 'Do you want to reload?',
                  type: 'warning',
                },
                () => {
                  localStorage.setItem('serverErrorCount', 0);
                  $window.location.reload(true);
                },
              );
            } else {
              window.location.reload(true);
            }
            //
          } else {
            // For other errors, let it return rejected promise only
          }
          return $q.reject(rejection);
        },
      };
    },
  ]);

  $stateProvider
    // new states
    .state('main.dashboard.transportation', {
      url: '/transportation',
      templateProvider: () => import('../views/reporting.html'),
      controller: 'reportingCtrl',
      data: { pageTitle: 'Transportation Dashboard', specialClass: 'gray-bgdf' },
    })
    .state('main.dashboard.org-transportation', {
      url: '/org-transportation',
      templateProvider: () => import('../views/org-reporting.html'),
      controller: 'orgReportingCtrl',
      data: { pageTitle: 'Transportation Dashboard', specialClass: 'gray-bgdf' },
    })
    .state('main.dashboard.addressauditreport', {
      url: '/address-audit-report',
      templateProvider: () => import('../views/address-audit-report.html'),
      data: { pageTitle: 'Address Audit Report' },
      controller: 'addressAuditReportCtrl',
    })
    .state('main.language.dashboard', {
      url: '/language',
      templateProvider: () => import('../views/language-dashboard.html'),
      controller: 'languageReportingCtrl',
      data: { pageTitle: 'Video Interpretation Dashboard', specialClass: 'gray-bgdf' },
    })
    .state('main.language.org-dashboard', {
      url: '/org-language',
      templateProvider: () => import('../views/org-language-dashboard.html'),
      controller: 'orgLanguageReportingCtrl',
      data: { pageTitle: 'Transportation', specialClass: 'gray-bgdf' },
    })
    .state('login', {
      url: '/login',
      templateProvider: () => import('../views/login.html'),
      controller: '',
      data: { pageTitle: 'Login', specialClass: 'gray-bg' },
    })
    .state('set_password', {
      url: '/reset?token&id&firstName&lastName&username',
      templateProvider: () => import('../views/set-password.html'),
      data: { pageTitle: 'Set Password', specialClass: 'gray-bg' },
    })
    .state('reset_password', {
      url: '/resetpassword?token&id&device_name',
      templateProvider: () => import('../views/reset-password.html'),
      data: { pageTitle: 'Set Password', specialClass: 'gray-bg' },
    })
    .state('forgot_password', {
      url: '/forgot_password',
      templateProvider: () => import('../views/forgot-password.html'),
      data: { pageTitle: 'Forgot password', specialClass: 'gray-bg' },
    })
    .state('privacy_policy', {
      url: '/privacy_policy',
      templateProvider: () => import('../views/privacy-policy.html'),
      data: {
        pageTitle: 'Privacy policy',
        specialClass: 'gray-bg',
      },
    })
    .state('business_rider_signup_confirm_password', {
      url: '/signup-confirm-password',
      templateProvider: () => import('../views/common/business-rider-signup-confirm-password.html'),
      data: { pageTitle: 'Confirm Password', specialClass: 'gray-bg' },
      controller: 'businessRiderSignupConfirmPasswordCtrl',
      resolve: {
        acl: [
          '$state',
          ($state) => {
            const userDetails = JSON.parse(localStorage.getItem('userDetails'));
            if (userDetails) {
              $state.go('main.dashboard.superAdminDashboard');
            }
          },
        ],
      },
    })
    .state('business_signup_redirect', {
      url: '/business-signup-redirect',
      templateProvider: () => import('../views/common/business-signup-redirect.html'),
      data: { pageTitle: 'Signup', specialClass: 'gray-bg' },
      controller: 'businessSignupRedirectCtrl',
      resolve: {
        acl: [
          '$state',
          ($state) => {
            const userDetails = JSON.parse(localStorage.getItem('userDetails'));
            if (userDetails) {
              $state.go('main.dashboard.superAdminDashboard');
            }
          },
        ],
      },
    })
    .state('sso_redirect_url', {
      url: '/sso-redirect-url?username&usertoken',
      data: { pageTitle: 'SSO Login' },
      resolve: {
        acl: [
          '$stateParams', '$http', 'API_BASE_URL', '$state',
          async ({ username, usertoken }, $http, API_BASE_URL, $state) => {
            localStorage.removeItem('userDetails');
            const {
              data: {
                success,
                data: {
                  user: userData,
                },
              },
            } = await $http({
              url: `${API_BASE_URL}user/verify-user-token`,
              method: 'POST',
              data: {},
              headers: {
                username,
                usertoken,
              },
            });
            if (!success) {
              localStorage.removeItem('userDetails');
              window.location.href = `/${process.env.PUBLIC_URL_PREFIX}current/login`;
            }
            userData.orgIds = userData.organisations.map(({ id }) => id);
            const isInviteRiderSettingActive = userData.organisation.settings.find(setting => setting.setting_name === 'invited_riders' && setting.setting_value.toLowerCase() === 'true');
            if (isInviteRiderSettingActive) {
              userData.isInviteRiderSettingActive = true;
            }
            localStorage.setItem('userDetails', JSON.stringify(userData));

            const {
              role,
              organisation,
            } = userData;

            if (!role) {
              localStorage.removeItem('userDetails');
              window.location.href = `/${process.env.PUBLIC_URL_PREFIX}current/login`;
            }

            if (organisation.childrenCount > 0) {
              window.location.href = `/${process.env.PUBLIC_URL_PREFIX}current/main/dashboard/parent-org-dashboard`;
              return;
            }

            const redirectAddRideSetting = organisation
              && organisation.settings
              && organisation
                .settings
                .find(({ setting_name: name }) => name === 'redirect_add_ride');

            const redirectAddRide = redirectAddRideSetting
              && redirectAddRideSetting.setting_value.toLowerCase() === 'true';

            if (redirectAddRide) {
              $state.go('main.dashboard.createAppointment', {
                caller: 'Book now',
              });
              return;
            }
            $state.go('main.dashboard');
          },
        ],
      },
    })
    .state('api_docs', {
      url: '/api-docs',
      data: { pageTitle: 'API Docs' },
      resolve: {
        acl: [
          async () => {
            const userDetails = JSON.parse(localStorage.getItem('userDetails'));
            window.location.href = `${process.env.API_DOCS_URL}index.html?usertoken=${userDetails.user_token}&username=${userDetails.username}`;
          },
        ],
      },
    })
    .state('main', {
      abstract: true,
      url: '/main',
      templateProvider: () => require('../views/common/content.html'),
      resolve: {
        loadPermissionManager: [
          'permissionsManager',
          (permissionsManager) => {
            const userDetails = JSON.parse(localStorage.getItem('userDetails'));
            if (userDetails) {
              permissionsManager.init(userDetails.permissions);
            }
            return true;
          },
        ],
      },
    })
    .state('main.adjuster', {
      url: '/adjuster',
      templateProvider: () => import('../views/adjuster-list.html'),
      data: {
        pageTitle: 'Adjuster',
      },
    })
    .state('main.userMgmt', {
      abstract: true,
      url: '/userMgmt',
      template: '<ui-view/>',
    })
    .state('main.userMgmt.orgList', {
      url: '/orgList?marketSegmentId',
      templateProvider: () => import('../views/org.html'),
      data: {
        pageTitle: 'Organization Management',
        parentRoutes: ['main.userMgmt.orgList', 'main.marketSegment.manageMarketSegment', 'main.marketSegment.marketSegmentList'],
      },
      controller: 'orgListCntrl',
    })
    .state('main.invitations', {
      url: '/invitations?org_id?org_name',
      templateProvider: () => import('../sub-modules/list-rider/list.html'),
      data: {
        pageTitle: 'Manage Riders',
      },
      controller: 'riderListCntrl',
    })
    .state('main.manageInvitations', {
      url: '/manage-invitations/:id?edit',
      templateProvider: () => import('../sub-modules/manage-invitations/edit.html'),
      data: {
        pageTitle: 'Manage Riders',
      },
      controller: 'manageRiderCntrl as $ctrl',
    })
    .state('main.userMgmt.manageOrg', {
      url: '/manageOrg/?orgId?caller?edit?active_page',
      templateProvider: () => import('../views/manage-org.html'),
      data: { pageTitle: 'Profile Management', parentRoutes: ['main.userMgmt.orgList'] },
      controller: 'manageOrgCntrl',
    })
    .state('main.userMgmt.passwordSettings', {
      url: '/passwordSettings/:userId',
      templateProvider: () => import('../views/password-settings.html'),
      data: {
        pageTitle: 'Password Settings',
        parentRoutes: ['main.userMgmt.userList'],
      },
      controller: 'passordSettingsCntrl',
    })
    .state('main.userMgmt.manageUser', {
      url: '/manageUser/?userId?edit',
      templateProvider: () => import('../views/manage-user.html'),
      data: {
        pageTitle: 'Users',
        parentRoutes: ['main.userMgmt.userList'],
      },
      controller: 'manageUserCntrl',
    })
    .state('main.userMgmt.userOrgList', {
      url: '/userOrgList',
      templateProvider: () => import('../views/user-org-list.html'),
      data: {
        pageTitle: 'Users',
      },
      controller: 'userOrgListCntrl',
    })
    .state('main.signup_phone_verification', {
      url: '/signup-phone-verification',
      templateProvider: () => import('../views/signup/verify-otp.html'),
      data: { pageTitle: 'Phone Verification', specialClass: 'gray-bg' },
      controller: 'signupVerifyOtpCtrl',
    })
    .state('main.business_signup_verify_otp', {
      url: '/business/verify-otp',
      templateProvider: () => import('../views/signup/verify-otp.html'),
      data: { pageTitle: 'Verify Your Cell Phone', specialClass: 'gray-bg' },
      controller: 'signupVerifyOtpCtrl',
    })
    .state('main.business_signup_terms_of_service', {
      url: '/business/terms-of-service',
      templateProvider: () => import('../views/signup/terms-of-service.html'),
      data: { pageTitle: 'Terms Of Service', specialClass: 'gray-bg' },
      params: { errors: null },
      controller: 'termsOfServiceCtrl',
    })
    .state('main.signup_terms_of_service', {
      url: '/signup-terms-of-service',
      templateProvider: () => import('../views/signup-terms-conditions.html'),
      data: { pageTitle: 'Terms Of Service', specialClass: 'gray-bg' },
      controller: 'signupTermsConditionsCtrl',
    })
    .state('main.business_signup_add_organization', {
      url: '/business/organization-details',
      templateProvider: () => import('../views/signup/business-organization-information.html'),
      data: { pageTitle: 'Organization Information', specialClass: 'gray-bg' },
      params: { errors: null },
      controller: 'organizationDetailsCtrl',
    })
    .state('main.business_signup_add_users', {
      url: '/business/add-users',
      templateProvider: () => import('../views/signup/add-users.html'),
      data: { pageTitle: 'Add Users', specialClass: 'gray-bg' },
      params: { errors: null },
      controller: 'addUsersCtrl',
    })
    .state('main.business_signup_payment', {
      url: '/business/payment',
      templateProvider: () => import('../views/signup/payment.html'),
      data: { pageTitle: 'Payment', specialClass: 'gray-bg' },
      params: { errors: null },
      controller: 'paymentCtrl',
    })
    .state('main.business_signup_summary', {
      url: '/business/summary',
      templateProvider: () => import('../views/signup/summary.html'),
      data: { pageTitle: 'Summary', specialClass: 'gray-bg' },
      params: { errors: null },
      controller: 'summaryCtrl',
    })
    .state('main.rider_signup_verify_otp', {
      url: '/rider/verify-otp',
      templateProvider: () => import('../views/signup/verify-otp.html'),
      data: { pageTitle: 'Verify Your Cell Phone', specialClass: 'gray-bg' },
      controller: 'signupVerifyOtpCtrl',
    })
    .state('main.rider_signup_terms_of_service', {
      url: '/rider/terms-of-service',
      templateProvider: () => import('../views/signup/terms-of-service.html'),
      data: { pageTitle: 'Terms Of Service', specialClass: 'gray-bg' },
      params: { errors: null },
      controller: 'termsOfServiceCtrl',
    })
    .state('main.rider_signup_account_information', {
      url: '/rider/account-information',
      templateProvider: () => import('../views/signup/rider-account-information.html'),
      data: { pageTitle: 'Account Information', specialClass: 'gray-bg' },
      params: { errors: null },
      controller: 'accountInfoCtrl',
    })
    .state('main.rider_signup_add_users', {
      url: '/rider/add-users',
      templateProvider: () => import('../views/signup/add-users.html'),
      data: { pageTitle: 'Add Users', specialClass: 'gray-bg' },
      params: { errors: null },
      controller: 'addUsersCtrl',
    })
    .state('main.rider_signup_payment', {
      url: '/rider/payment',
      templateProvider: () => import('../views/signup/payment.html'),
      data: { pageTitle: 'Payment', specialClass: 'gray-bg' },
      params: { errors: null },
      controller: 'paymentCtrl',
    })
    .state('main.rider_signup_summary', {
      url: '/rider/summary',
      templateProvider: () => import('../views/signup/summary.html'),
      data: { pageTitle: 'Summary', specialClass: 'gray-bg' },
      params: { errors: null },
      controller: 'summaryCtrl',
    })
    .state('main.thank_you', {
      url: '/thank-you',
      templateProvider: () => import('../views/signup/thank-you.html'),
      data: { pageTitle: 'Thank You', specialClass: 'gray-bg' },
      controller: 'thankYouCtrl',
    })
    .state('main.userMgmt.providerList', {
      url: '/providerMgmt',
      templateProvider: () => import('../views/provider-management.html'),
      data: {
        pageTitle: 'Provider Management',
        parentRoutes: ['main.userMgmt.orgList'],
      },
      controller: 'providerManagementCtrl',
    })
    .state('main.userMgmt.userList', {
      url: '/userList/?orgId',
      templateProvider: () => import('../views/user-list.html'),
      data: {
        pageTitle: 'User Management',
        parentRoutes: ['main.userMgmt.orgList', 'main.userMgmt.userList'],
      },
      controller: 'userListCntrl',
    })
    .state('main.userMgmt.questionnaireList', {
      url: '/questionnaireList?orgId',
      templateProvider: () => import('../views/questionnaire-list.html'),
      reloadOnSearch: false,
      data: {
        pageTitle: 'Questionnaires',
        parentRoutes: ['main.userMgmt.orgList'],
      },
      controller: 'questionnaireListCtrl',
    })
    .state('main.userMgmt.manageQuestionnaire', {
      url: '/manageQuestionnaire?orgId?questionnaireId?',
      templateProvider: () => import('../views/manage-questionnaire.html'),
      data: {
        pageTitle: 'Manage Questionnaire',
        parentRoutes: ['main.userMgmt.orgList'],
      },
      controller: 'manageQuestionnaireCtrl',
    })
    .state('main.globalSetting', {
      url: '/globalSetting',
      templateProvider: () => import('../sub-modules/global-settings/globalSettingHtml.html'),
      data: {
        pageTitle: 'Global Settings',
      },
      controller: 'globalSettingCtrl',
      resolve: {
        acl: [
          '$state',
          'permissionFilter',
          ($state, permissionFilter) => {
            if (!permissionFilter(permissionsConstants.SHOW_GLOBAL_SETTINGS)) {
              $state.go('main.dashboard.superAdminDashboard');
            }
          },
        ],
      },
    })
    .state('main.profile', {
      url: '/profile',
      templateProvider: () => import('../views/user-profile.html'),
      data: { pageTitle: 'User settings' },
    })
    .state('main.changePassword', {
      url: '/change-password',
      templateProvider: () => import('../views/change-password.html'),
      data: {
        pageTitle: 'Change Password',
      },
    })
    .state('main.dashboard', {
      abstract: true,
      url: '/dashboard',
      template: '<div ui-view></div>',
    })
    .state('main.language', {
      abstract: true,
      url: '/dashboard',
      template: '<ui-view/>',
    })
    .state('main.dashboard.superAdminDashboard', {
      url: '/super-admin-dashboard?opentab&currentPage',
      templateProvider: () => import('../views/super-admin-dashboard.html'),
      data: {
        pageTitle: 'Home',
        parentRoutes: ['main.dashboard.superAdminDashboard'],
      },
      reloadOnSearch: false,
    })
    .state('main.dashboard.clientAdminDashboard', {
      url: '/client-admin-dashboard?opentab',
      templateProvider: () => import('../views/client-admin-dashboard.html'),
      data: {
        pageTitle: 'Home',
      },
      controller: 'clientAdminDashboardCtrl as $ctrl',
    })
    .state('main.dashboard.parentOrgDashboard', {
      url: '/parent-org-dashboard',
      templateProvider: () => import('../views/parent-org-dashboard.html'),
      controller: 'parentOrgDashboardCtrl',
      data: {
        pageTitle: 'Home',
      },
    })
    .state('main.dashboard.completed', {
      url:
        '/completedAppointment?organizationName&patientName&status&patientPhoneNumber&currentPage&startDate&endDate&referenceNo&activeTab&is_pr_ride&userList',
      reloadOnSearch: false,
      templateProvider: () => import('../views/completed-appointments.html'),
      data: {
        pageTitle: 'Previous Trips',
        parentRoutes: ['main.dashboard.completed'],
      },
      controller: 'completedAppointmentsCtrl',
    })
    // Invoice Routes
    .state('main.invoice', {
      url: '/invoice',
      template: '<ui-view></ui-view>',
    })
    .state('main.invoice.listing', {
      url: '/listing?org&tab',
      templateProvider: () => import('../sub-modules/invoices/listing.html'),
      data: {
        pageTitle: 'Monthly Invoices',
      },
      params: {
        org: {
          dynamic: true,
        },
        tab: {
          dynamic: true,
        },
      },
      controller: 'invoiceSummaryController as $ctrl',
    })
    .state('main.dashboard.invoice', {
      url: '/invoice/:appt_id?variance',
      templateProvider: () => import('../sub-modules/invoices/invoice.html'),
      data: {
        pageTitle: 'Ride Details',
        parentRoutes: ['main.dashboard.completed', 'main.dashboard.active', 'main.dashboard.superAdminDashboard'],
      },
      params: {
        retroQueueTab: null,
      },
      controller: 'invoiceCtrl as $ctrl',
    })
    .state('main.invoice.details', {
      url: '/:org/:type/:year/:month',
      reloadOnSearch: false,
      templateProvider: () => import('../sub-modules/invoices/details.html'),
      data: {
        pageTitle: 'Monthly Invoices',
      },
      controller: 'invoiceDetailsCtrl',
    })
    .state('main.dashboard.active', {
      url: '/activeRides',
      templateProvider: () => import('../views/active-rides.html'),
      data: {
        pageTitle: 'Active Rides',
        parentRoutes: ['main.dashboard.active'],
      },
      controller: 'activeRidesCtrl',
    })
    .state('main.dashboard.checkAvailability', {
      url: '/checkAvailability',
      templateProvider: () => import('../views/check-availability.html'),
      data: { pageTitle: 'Check Availability' },
      controller: 'checkAvailabilityCntrl',
    })
    .state('main.dashboard.scheduled', {
      url: '/scheduledAppointments?organizationName&patientName&status&patientPhone&currentPage&startDate&endDate&referenceNo&activeTab',
      reloadOnSearch: false,
      templateProvider: () => import('../views/scheduled-appointments.html'),
      data: {
        pageTitle: 'Scheduled Trips',
        parentRoutes: ['main.dashboard.scheduled'],
      },
      controller: 'scheduledApptsCtrl',
    })
    .state('main.dashboard.createAppointment', {
      url: '/createAppointments?isFromDashboard&caller&rideId&bookAnotherId&variance_type&recurrenceAction',
      templateProvider: () => import('../sub-modules/appointment/create.html'),
      data: {
        pageTitle: 'Request a Ride',
        parentRoutes: ['main.dashboard.scheduled', 'main.dashboard.active', 'main.dashboard.superAdminDashboard'],
      },
      controller: 'createAppointmentCtrl as $ctrl',
      onExit: [
        '$stateParams',
        'Pubnub',
        'pubnubNotificationFactory',
        ($stateParams, Pubnub, pubnubNotificationFactory) => {
          'ngInject';

          const rideId = $stateParams.rideId
            || $stateParams.bookAnotherId
            || '';

          if (rideId) {
            // If we had any rideId for this route... unsubscribe the presence channel for the same
            pubnubNotificationFactory.unsubscribeChannel(`presence_${rideId}`);
          }
        },
      ],
    })
    .state('main.dashboard.rideOrderDetails', {
      url: '/ride-order-details?caller&rideId',
      params: {
        rideId: {
          dynamic: true,
        },
      },
      data: {
        pageTitle: 'Ride Order Details',
        parentRoutes: ['main.dashboard.scheduled', 'main.dashboard.active', 'main.dashboard.superAdminDashboard'],
      },
      views: {
        '': {
          templateProvider: () => import('../views/ride-order-details.html'),
          controller: 'rideOrderCtrl',
        },
        'riderInfo@main.dashboard.rideOrderDetails': {
          templateProvider: () => import('../views/ride-order-details-ride-info.html'),
          controller: 'rideInfoCtrl',
        },
        'tripInfo@main.dashboard.rideOrderDetails': {
          templateProvider: () => import('../views/ride-order-details-trip-info.html'),
          controller: 'tripInfoCtrl',
        },
        'orderInfo@main.dashboard.rideOrderDetails': {
          templateProvider: () => import('../views/ride-order-details-order-info.html'),
          controller: 'orderInfoCtrl',
        },
      },
    })
    .state('main.dashboard.masOrderDetails', {
      url: '/mas-order-details?caller&rideId&bookAnotherId',
      params: {
        rideId: {
          dynamic: true,
        },
      },
      data: {
        pageTitle: 'MAS Order Details',
        parentRoutes: ['main.dashboard.superAdminDashboard', 'main.dashboard.scheduled', 'main.dashboard.active'],
      },
      views: {
        '': {
          templateProvider: () => import('../views/ride-order-details.html'),
          controller: 'masOrderCtrl',
        },
        'riderInfo@main.dashboard.masOrderDetails': {
          templateProvider: () => import('../views/ride-order-details-ride-info.html'),
          controller: 'masRideInfoCtrl',
        },
        'tripInfo@main.dashboard.masOrderDetails': {
          templateProvider: () => import('../views/ride-order-details-trip-info.html'),
          controller: 'masTripInfoCtrl',
        },
        'orderInfo@main.dashboard.masOrderDetails': {
          templateProvider: () => import('../views/ride-order-details-order-info.html'),
          controller: 'masOrderInfoCtrl',
        },
      },
    })
    .state('main.dashboard.downloads', {
      url: '/downloads',
      templateProvider: () => import('../views/downloads.html'),
      data: {
        pageTitle: 'Downloads',
      },
      controller: 'downloadsCtrl',
      redirectTo: 'main.dashboard.downloads.recentExports',
    })
    .state('main.dashboard.downloads.recentExports', {
      url: '/recent',
      views: {
        tabContent: {
          templateProvider: () => import('../views/recent-exports-tab.html'),
        },
      },
    })
    .state('main.dashboard.downloads.savedExports', {
      url: '/saved',
      views: {
        tabContent: {
          templateProvider: () => import('../sub-modules/saved-exports/saved-exports.html'),
          controller: 'SavedExportReqsCtrl',
        },
      },
    })
    .state('webTracking', {
      url: '/webtrack/:rideId',
      templateProvider: () => import('../sub-modules/web-tracking/template.html'),
      controller: 'webTrackController',
      data: {
        pageTitle: 'Tracking',
        specialClass: 'gray-bg',
      },
    })
    .state('generateInvoiceLambda', {
      url: '/generateInvoiceLambda/:appt_id',
      templateProvider: () => import('../views/invoice-lambda.html'),
      controller: 'invoiceCtrl as $ctrl',
    })
    .state('main.dashboard.logs', {
      url: '/logs',
      abstract: true,
      template: '<ui-view/>',
      reloadOnSearch: false,
    })
    .state('main.dashboard.logs.auditLog', {
      url: '/auditLog?level&appt_id&status&startDate&endDate&file_no&patient_id&patient_name&ride_id&currentPage&orderId&masInvoiceNumber&tripIdentifierType',
      templateProvider: () => import('../views/logs/audit-log.html'),
      controller: 'auditLogCtrl',
      data: { pageTitle: 'Audit Logs' },
      reloadOnSearch: false,
    })
    .state('main.dashboard.logs.accessLog', {
      url: '/accessLog',
      templateProvider: () => import('../views/logs/access-log.html'),
      controller: 'accessLogCtrl',
      data: { pageTitle: 'Admin Access Logs' },
      reloadOnSearch: false,
    })
    .state('main.dashboard.logs.masLog', {
      url: '/masLog?type&level&username&phone&startDate&endDate&currentPage',
      templateProvider: () => import('../views/logs/mas-log.html'),
      controller: 'masLogCtrl',
      data: { pageTitle: 'MAS Logs' },
      reloadOnSearch: false,
    })
    .state('main.dashboard.logs.accountLog', {
      url: '/accountLog',
      templateProvider: () => import('../views/logs/access-log.html'),
      controller: 'accessLogCtrl',
      data: { pageTitle: 'Account Logs' },
      reloadOnSearch: false,
    })

    .state('main.dashboard.logs.fuseAccessLogs', {
      url: '/fuseAccessLogs?type&level&username&phone&startDate&endDate&currentPage',
      templateProvider: () => import('../views/logs/fuse-access-logs.html'),
      controller: 'fuseAccessLogsCtrl',
      data: { pageTitle: 'Fuse Logs' },
      reloadOnSearch: false,
    })
    .state('main.dashboard.logs.trackingLog', {
      url: '/trackingLog?level&username&phone&startDate&endDate&currentPage',
      templateProvider: () => import('../views/logs/tracking-log.html'),
      controller: 'trackingLogCtrl',
      reloadOnSearch: false,
    })
    .state('main.dashboard.logs.rideOrderLogs', {
      url: '/rideOrderLogs',
      templateProvider: () => import('../sub-modules/logs/ride-order/ride-order-logs.html'),
      controller: 'rideOrderLogsCtrl as $ctrl',
      data: { pageTitle: 'Ride Order Logs' },
      reloadOnSearch: false,
    })
    .state('main.dashboard.logs.receiptDetailLogs', {
      url: '/receiptDetailLogs',
      templateProvider: () => import('../sub-modules/logs/receipt/receipt-detail-logs.html'),
      data: { pageTitle: 'Receipt Detail Logs' },
      controller: 'receiptDetailLogsCtrl as $ctrl',
      reloadOnSearch: false,
    })
    .state('main.dashboard.logs.inviteLogs', {
      url: '/inviteLogs',
      reloadOnSearch: false,
      templateProvider: () => import('../sub-modules/logs/invite/list.html'),
      data: { pageTitle: 'Invite Logs' },
      controller: 'inviteLogCtrl as $ctrl',
      resolve: {
        acl: [
          '$state',
          'permissionFilter',
          ($state, permissionFilter) => {
            if (!permissionFilter(permissionsConstants.SHOW_INVITE_ONLY_LOGS)) {
              $state.go('main.dashboard.superAdminDashboard');
              return true;
            }
          },
        ],
      },
    })
    .state('main.dashboard.fuseReports', {
      url: '/fuse',
      abstract: true,
      template: '<ui-view/>',
      reloadOnSearch: false,
    })
    .state('main.dashboard.fuseReports.bwb_reports', {
      url: '/bwb_reports',
      reloadOnSearch: false,
      templateProvider: () => import('../views/bwb-reports.html'),
      data: {
        pageTitle: 'BWB Report',
      },
      controller: 'bwbOutboundReportCtrl',
    })
    .state('main.dashboard.fuseReports.performance_reports', {
      url: '/performance_reports',
      reloadOnSearch: false,
      templateProvider: () => import('../views/performance-reports.html'),
      data: {
        pageTitle: 'Performance Report',
      },
      controller: 'performanceReportCtrl',
    })
    .state('main.userMgmt.inclusion', {
      url: '/fuseInclusion',
      reloadOnSearch: false,
      templateProvider: () => import('../views/inclusion.html'),
      data: {
        pageTitle: 'Fuse Inclusion List',
        parentRoutes: ['main.userMgmt.orgList', 'main.userMgmt.manageOrg'],
      },
      controller: 'inclusionCtrl',
    })
    .state('main.dashboard.contactUs', {
      url: '/contactUs',
      reloadOnSearch: false,
      templateProvider: () => import('../views/contact-us-new.html'),
      data: { pageTitle: 'Contact Us' },
      controller: 'contactUsCtrl',
    })
    .state('main.userMgmt.importOrg', {
      url: '/importOrg',
      reloadOnSearch: false,
      templateProvider: () => import('../views/import-org.html'),
      data: {
        pageTitle: 'Import Organizations',
        parentRoutes: ['main.userMgmt.orgList'],
      },
      controller: 'importOrgCtrl',
    })
    .state('main.language.completed', {
      url: '/language/completedAppointment',
      reloadOnSearch: false,
      templateProvider: () => import('../views/language-completed-appointments.html'),
      data: {
        pageTitle: 'Language Completed Appointments',
      },
      controller: 'languageCompletedAppointmentsCtrl',
    })
    .state('main.marketSegment', {
      abstract: true,
      url: '/marketSegment',
      template: '<ui-view/>',
    })
    .state('main.marketSegment.marketSegmentList', {
      url: '/marketSegmentList',
      templateProvider: () => import('../views/market-segment-list.html'),
      data: {
        pageTitle: 'Market Segments',
        parentRoutes: ['main.marketSegment.marketSegmentList'],
      },
      controller: 'marketSegmentListCntrl',
    })
    .state('main.marketSegment.manageMarketSegment', {
      url: '/manageMarketSegment/?marketSegmentId?edit',
      templateProvider: () => import('../views/manage-market-segment.html'),
      data: {
        pageTitle: 'Manage Market Segment',
        parentRoutes: ['main.marketSegment.marketSegmentList'],
      },
      controller: 'manageMarketSegmentCntrl',
    })
    .state('main.dashboard.help', {
      abstract: true,
    })
    .state('main.dashboard.help.broadcasts', {
      url: '/broadcasts',
      data: {
        pageTitle: 'Information Broadcasts',
      },
      views: {
        '@main.dashboard': {
          templateProvider: () => import('../sub-modules/broadcasts/broadcasts.html'),
          controller: 'BroadcastsCtrl',
        },
      },
      resolve: {
        acl: [
          'permissionFilter',
          (permissionFilter) => {
            const userDetails = JSON.parse(localStorage.getItem('userDetails'));
            return userDetails.role === relaylib.common.constants.userRoles.MASTER_SUPER_ADMIN || permissionFilter(permissionsConstants.BROADCAST_READ);
          },
        ],
      },
    })
    .state('main.dashboard.help.releaseNotesView', {
      url: '/releasenotesview',
      data: {
        pageTitle: 'Release Notes',
      },
      views: {
        '@main.dashboard': {
          templateProvider: () => import('../sub-modules/release-notes/release-notes-view.html'),
          controller: 'ReleaseNotesViewCtrl',
        },
      },
    })
    .state('main.dashboard.masInvoiceAdministration', {
      url: '/mas-invoice-administration',
      data: {
        pageTitle: 'MAS Invoice Administration',
      },
      templateProvider: () => import('../sub-modules/mas-invoice-administration/template.html'),
      controller: 'masInvoiceAdminCtrl',
      reloadOnSearch: false,
    })
    .state('main.dashboard.masExportImportInvoice', {
      url: '/mas-export-import-invoice',
      reloadOnSearch: false,
      templateProvider: () => import('../sub-modules/mas-invoice-administration/export-import-invoice.html'),
      data: {
        pageTitle: 'Import Invoice',
      },
      params: { activeTab: 'export' },
      controller: 'exportImportInvoiceCtrl',
      resolve: {
        acl: [
          'permissionFilter',
          '$state',
          (permissionFilter, $state) => {
            if (!permissionFilter(permissionsConstants.IMPORT_ORG)) {
              $state.go('main.dashboard.superAdminDashboard');
            }
          },
        ],
      },
    })
    .state('main.dashboard.help.releaseNotes', {
      url: '/releasenotes',
      data: {
        pageTitle: 'Release Notes',
      },
      views: {
        '@main.dashboard': {
          templateProvider: () => import('../sub-modules/release-notes/release-notes-list.html'),
          controller: 'ReleaseNotesListCtrl',
        },
      },
      resolve: {
        acl: [
          'permissionFilter',
          (permissionFilter) => {
            const userDetails = JSON.parse(localStorage.getItem('userDetails'));
            return userDetails.role === relaylib.common.constants.userRoles.MASTER_SUPER_ADMIN || permissionFilter(permissionsConstants.RELEASE_NOTES_READ);
          },
        ],
      },
    })
    .state('main.dashboard.videoLib', {
      url: '/video_library',
      templateProvider: () => import('../sub-modules/video-library/template.html'),
      data: {
        pageTitle: 'Video Library',
      },
      controller: 'videoLibraryCntrl',
    })
    .state('main.dashboard.playVideo', {
      url: '/play_video?url&desccription',
      templateProvider: () => import('../views/video.html'),
      data: {
        pageTitle: 'Play Video',
      },
      controller: 'videoLibraryCntrl',
    })
    .state('adjuster', {
      url: '/appointment/deny?appt&adjuster',
      templateProvider: () => import('../views/appointment-deny.html'),
      controller: 'adjusterApptDenyCtrl',
      data: {
        pageTitle: 'Cancel Appointment',
        specialClass: 'gray-bg',
      },
    })
    .state('main.messages', {
      url: '/messages',
      templateProvider: () => import('../sub-modules/message-center/message.html'),
      data: {
        pageTitle: 'Messages',
      },
      controller: 'notificationMessageCtrl as $Ctrl',
    })
    .state('secure-email', {
      url: '/secure-email/:hashkey',
      templateProvider: () => import('../sub-modules/secure-email/view-email.html'),
      controller: 'secureEmailCtrl as $ctrl',
      data: { pageTitle: 'Secure Email' },
      resolve: {
        isHash: [
          '$stateParams',
          '$state',
          '$timeout',
          function isHash({ hashkey }, $state, $timeout) {
            if (!hashkey) {
              $timeout(() => {
                $state.go('login');
              });
            }
          },
        ],
      },
    });
}

angular
  .module('relayHealth')
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'IdleProvider',
    '$httpProvider',
    '$locationProvider',
    routeConfig,
  ])
  .run([
    '$rootScope',
    '$state',
    'ShowReleasePopupService',
    '$location',
    'storageManager',
    '$transitions',
    'oldDashboardCheck',
    ($rootScope, $state, ShowReleasePopupService, $location, storageManager, $transitions, oldDashboardCheck) => {
      const { storage } = storageManager;

      $transitions.onStart({}, (transition) => {
        const toAllowedStates = transition.to().data.parentRoutes;
        const getFilterData = storage.getData('keepFilterData');
        if (getFilterData) {
          $.each(getFilterData, (key) => {
            if ($.inArray(key, toAllowedStates) === -1) {
              storage.removeDataByKey('keepFilterData', key);
            }
          });
        }
      });

      Object.assign($rootScope, {
        $state,
      });

      $transitions.onSuccess({}, (transition) => {
        const toState = transition.to();
        const fromState = transition.from();
        $('[data-toggle="popover"]').popover('hide');
        let userDetails = localStorage.getItem('userDetails');
        if (!userDetails) {
          return false;
        }
        userDetails = JSON.parse(userDetails);
        const redirectionCondition = (userDetails.role === 'orgadmin' && userDetails.organisation.org_class === 'business')
          || (userDetails.organisation.org_class === 'rider' && userDetails.role === 'riderUser')
          || (userDetails.organisation.org_class === 'rider' && userDetails.role === 'tempRider')
          || (userDetails.organisation.org_class === 'business' && userDetails.role === 'tempBusiness');
        if (redirectionCondition) {
          if (Object.prototype.hasOwnProperty.call(userDetails, 'phone_verified') && !userDetails.phone_verified) {
            if (userDetails.role === 'tempRider') {
              $state.go('main.rider_signup_verify_otp');
              return true;
            }
            if (userDetails.role === 'tempBusiness') {
              $state.go('main.business_signup_verify_otp');
              return true;
            }
            if (userDetails.role === 'orgadmin' || userDetails.role === 'riderUser') {
              $state.go('main.signup_phone_verification');
              return true;
            }
            return false;
          }
          // check if terms and conditions is pending
          if (Object.prototype.hasOwnProperty.call(userDetails, 't_and_c_check') && !userDetails.t_and_c_check) {
            if (userDetails.role === 'tempRider') {
              return $state.go('main.rider_signup_terms_of_service');
            }
            if (toState.name !== 'login' && (userDetails.role === 'orgadmin' || userDetails.role === 'riderUser')) {
              $state.go('main.signup_terms_of_service');
              return true;
            }
            if (userDetails.role === 'tempBusiness') {
              return $state.go('main.business_signup_terms_of_service');
            }
            localStorage.removeItem('userDetails');
          }
        }

        // routing conditions for org_superadmin as member
        if (
          (userDetails.role === 'org_superadmin' || userDetails.role === 'riderAdmin')
          && userDetails.user_id !== userDetails.organisation.main_contact_id
          && userDetails.org_id === userDetails.organisation.id
        ) {
          if (!userDetails.phone_verified) {
            return $state.go('main.signup_phone_verification');
          }
          if (!userDetails.t_and_c_check) {
            return $state.go('main.signup_terms_of_service');
          }
        }
        if (userDetails.password_expired) {
          setTimeout(() => {
            $location.path('/main/change-password');
          }, 0);
          return false;
        }

        if (toState.name !== 'main.thank_you') {
          ShowReleasePopupService.init(fromState.name === 'login');
          return true;
        }
        return false;
      });

      $transitions.onEnter({ entering: 'main.dashboard.superAdminDashboard' }, (transition) => {
        if (!oldDashboardCheck.check()) {
          return transition.router.stateService.target('main.dashboard.clientAdminDashboard');
        }
        return true;
      });

      $transitions.onEnter({ entering: 'main.dashboard.clientAdminDashboard' }, (transition) => {
        if (oldDashboardCheck.check()) {
          return transition.router.stateService.target('main.dashboard.superAdminDashboard');
        }
        return true;
      });

      // client org feature flag basic parent org redirect to new dashborad
      $transitions.onEnter({ entering: 'main.dashboard.parentOrgDashboard' }, (transition) => {
        if (!oldDashboardCheck.check()) {
          return transition.router.stateService.target('main.dashboard.clientAdminDashboard');
        }
        return true;
      });

      Object.assign($rootScope, {
        $state,
      });
    },
  ]);
