angular.module('relayHealth').controller('adjusterApptDenyCtrl', [
  '$scope', '$http', 'API_BASE_URL', '$state', 'redirectUserHomeIfLoggedInService', 'loadingScreenFactory',
  function adjusterApptDenyCtrl($scope, $http, API_BASE_URL, $state, redirectUserHomeIfLoggedInService, loadingScreenFactory) {
    if (!$state.params.appt || !$state.params.adjuster) {
      redirectUserHomeIfLoggedInService();
    }
    loadingScreenFactory.showLoadingScreen();
    $scope.denyAppointment = function denyAppointment() {
      const res = $http.delete(`${API_BASE_URL}appointment/adjuster/${$state.params.appt}/${$state.params.adjuster}`);
      res.then(({ data }) => {
        loadingScreenFactory.hideLoadingScreen();
        $scope.message = data.message;
      });
      res.catch(({ data }) => {
        loadingScreenFactory.hideLoadingScreen();
        $scope.message = data.message;
      });
    };
    $scope.denyAppointment();
  },
]);
