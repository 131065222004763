const { ride: { status: rideStatuses }, permissions: { permissionsConstants } } = relaylib;

angular.module('relayHealth').controller('auditLogCtrl', [
  '$scope',
  'API_BASE_URL',
  '$http',
  'loadingScreenFactory',
  '$uibModal',
  'storageManager',
  'TRIP_IDENTIFIERS',
  'toaster',
  'requestingOrganizationFactory',
  function auditLogCtrl(
    $scope, API_BASE_URL,
    $http, loadingScreenFactory,
    $uibModal,
    storageManager,
    TRIP_IDENTIFIERS,
    toaster,
    requestingOrganizationFactory,
  ) {
    const { storage } = storageManager;
    const requestingOrganizationsVisibleCount = 20;
    const itemsPerPage = 10;
    const [fileNumber, mas, rideOrderId] = TRIP_IDENTIFIERS.map(identifier => identifier.type);
    $scope.rideStatus = ['Select Status'];
    $scope.permissionsConstants = permissionsConstants;

    Object.keys(rideStatuses).forEach((keyIndex) => {
      if (![
        rideStatuses.RESCINDED.status,
        rideStatuses.OFFER_EXPIRED.status,
        rideStatuses.OFFER_REJECTED.status,
      ].includes(rideStatuses[keyIndex].status)
      ) {
        $scope.rideStatus.push(rideStatuses[keyIndex].status);
      }
    });

    const initSearchForm = () => {
      $scope.auditLogQuery = {
        appt_id: undefined,
        status: 'Select Status',
        dateRange: {
          startDate: null,
          endDate: null,
        },
        file_no: undefined,
        patient_id: undefined,
        patient_name: undefined,
        ride_id: undefined,
        orderId: undefined,
        masInvoiceNumber: undefined,
        tripIdentifierType: undefined,
        requestingOrganization: undefined,
      };
    };

    $scope.moment = moment;

    $scope.sortBy = 'createdAt';
    $scope.sortOrder = 1;
    const adjustPagination = () => {
      $scope.pagination.lastItem = ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage + $scope.pagination.itemsPerPage;
      if ($scope.pagination.lastItem > $scope.pagination.totalItems) {
        $scope.pagination.lastItem = $scope.pagination.totalItems;
      }
    };

    const getAuditLogs = async (offset, limit) => {
      const params = {
        status: $scope.auditLogQuery.status !== 'Select Status' ? $scope.auditLogQuery.status : undefined,
        patient_name: $scope.auditLogQuery.patient_name,
        order: JSON.stringify([[$scope.sortBy, $scope.sortOrder ? 'DESC' : 'ASC']]),
        offset,
        limit,
      };
      if ($scope.auditLogQuery.tripIdentifierType === fileNumber) {
        params.file_no = $scope.auditLogQuery.file_no;
      } else if ($scope.auditLogQuery.tripIdentifierType === mas) {
        params.masInvoiceNumber = $scope.auditLogQuery.masInvoiceNumber;
      } else if ($scope.auditLogQuery.tripIdentifierType === rideOrderId) {
        params.orderId = $scope.auditLogQuery.orderId;
      }
      if ($scope.auditLogQuery.dateRange.startDate && $scope.auditLogQuery.dateRange.endDate) {
        params.startDate = $scope.auditLogQuery.dateRange.startDate.format('MM-DD-YYYY 00:00:00');
        params.endDate = $scope.auditLogQuery.dateRange.endDate.format('MM-DD-YYYY 23:59:59');
      }
      if ($scope.auditLogQuery.requestingOrganization) {
        params.requestingOrganizationId = $scope.auditLogQuery.requestingOrganization.id;
      }
      loadingScreenFactory.showLoadingScreen();
      try {
        const { data: { audit_logs: auditLogs, total_count: totalCount } = {} } = await $http.get(`${API_BASE_URL}audit_logs`, {
          params,
        });
        $scope.auditLogs = auditLogs;
        $scope.pagination.totalItems = totalCount;
        adjustPagination();
      } catch (error) {
        toaster.pop({
          type: 'error',
          title: error.message || error.data.message || 'Error fetching audit logs',
          showCloseButton: true,
          timeout: 10000,
        });
      } finally {
        loadingScreenFactory.hideLoadingScreen();
        $scope.$apply();
      }
    };


    $scope.sort = (sortBy) => {
      if ($scope.sortBy === sortBy) {
        $scope.sortOrder = !$scope.sortOrder;
      }
      $scope.sortBy = sortBy;
      getAuditLogs(($scope.pagination.currentPage - 1) * 10, 10);
    };

    const initPagination = () => {
      $scope.pagination = {
        totalItems: 0,
        currentPage: 1,
        lastItem: 0,
        itemsPerPage,
      };
    };

    function writeSearchFilter() {
      const prevSearchValues = storage.getData('filterDataAuditLogs');
      if (!prevSearchValues) {
        return;
      }
      const prevForm = prevSearchValues;
      $scope.auditLogQuery.patient_name = prevForm.patient_name;
      $scope.auditLogQuery.status = prevForm.status;
      $scope.auditLogQuery.file_no = prevForm.file_no;
      $scope.auditLogQuery.dateRange = {
        startDate: prevForm.startDate ? moment(prevSearchValues.startDate) : null,
        endDate: prevForm.endDate ? moment(prevSearchValues.endDate) : null,
      };
      $scope.auditLogQuery.orderId = prevForm.orderId;
      $scope.auditLogQuery.masInvoiceNumber = prevForm.masInvoiceNumber;
      $scope.auditLogQuery.tripIdentifierType = prevForm.tripIdentifierType || undefined;
      $scope.auditLogQuery.requestingOrganization = prevForm.requestingOrganization;
    }

    const init = () => {
      initPagination();
      initSearchForm();
      writeSearchFilter();
      getAuditLogs(0, itemsPerPage);
    };
    $scope.loadMoreChoices = () => {
      $scope.requestingOrganizations.visibleChoices += requestingOrganizationsVisibleCount;
    };
    const getRequestingOrganizations = async (organizationId) => {
      try {
        const { data: { organisations: requestingOrganizations } } = await requestingOrganizationFactory.getRequestingOrganizations({
          organizationId,
          fields: ['orgId', 'orgName'],
        });
        $scope.requestingOrganizations = {
          data: requestingOrganizations,
          visibleChoices: requestingOrganizationsVisibleCount,
        };
      } catch (error) {
        toaster.pop({
          type: 'error',
          title: error.message || 'Unable to fetch Requesting Organizations',
          showCloseButton: true,
          timeout: 10000,
        });
      } finally {
        $scope.$apply();
      }
    };
    init();
    getRequestingOrganizations();

    $scope.pageChanged = () => {
      getAuditLogs(($scope.pagination.currentPage - 1) * 10, itemsPerPage);
    };
    $scope.showRideDetails = (auditLog) => {
      if (angular.isDefined(auditLog.rideDetails)) {
        Object.assign(auditLog, {
          rideDetails: undefined,
        });
        return;
      }
      let url = `${API_BASE_URL}logs/getLogDetails/auditLogs/${auditLog.id}`;
      if (auditLog.appointment.additional_details && auditLog.appointment.additional_details.external_identifier === 'MAS') {
        url += `?&masInvoiceNumber=${auditLog.appointment.additional_details.external_id}`;
      }
      const request = $http.get(url);
      loadingScreenFactory.showLoadingScreen();
      request.then(
        (response) => {
          loadingScreenFactory.hideLoadingScreen();
          const { data } = response;
          if (data.success) {
            Object.assign(auditLog, {
              rideDetails: data.logDetails,
            });
          }
        },
        () => {
          loadingScreenFactory.hideLoadingScreen();
        },
      );
    };

    $scope.showMeta = (auditLog) => {
      const modalInstance = $uibModal.open({
        animation: true,
        template: require('../../sub-modules/logs/log-details.html'),
        size: 'lg',
        backdrop: 'static',
        resolve: {
          meta() {
            const { _source: { meta } } = auditLog;
            return angular.copy(meta);
          },
        },
        controllerAs: 'logDetailsCtrl',
        controller: [
          'meta',
          '$uibModalInstance',
          function logDetailsCtrl(meta, $uibModalInstance) {
            Object.assign(this, {
              meta,
              closePopup() {
                $uibModalInstance.close();
              },
            });
          },
        ],
      });
      $scope.$on('$destroy', () => {
        modalInstance.close();
      });
    };

    $scope.filterAuditLogs = () => {
      storage.setData('filterDataAuditLogs', angular.copy($scope.auditLogQuery));
      getAuditLogs(0, itemsPerPage);
    };

    $scope.clearFilters = () => {
      storage.removeData('filterDataAuditLogs');
      initSearchForm();
      getAuditLogs(0, itemsPerPage);
      $scope.pagination.currentPage = 1;
    };

    $scope.enterOnFormSearch = (event) => {
      if (event.keyCode === 13) {
        $scope.pagination.currentPage = 1;
        getAuditLogs(0, itemsPerPage);
      }
    };
    $scope.tripIdentifierChanged = () => {
      $scope.auditLogQuery.orderId = null;
      $scope.auditLogQuery.masInvoiceNumber = null;
      $scope.auditLogQuery.fileNo = null;
    };
  },
]);
