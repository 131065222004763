angular
  .module('relayHealth')
  .controller('rideOrderSendOfferCtrl', [
    '$scope',
    'API_BASE_URL',
    '$http',
    'toaster',
    'rideDetails',
    '$uibModalInstance',
    'isOrderUpdate',
    'numberToOffsetString',
    'isHR68',
    function rideOrderSendOfferCtrl(
      $scope,
      API_BASE_URL,
      $http,
      toaster,
      rideDetails,
      $uibModalInstance,
      isOrderUpdate,
      numberToOffsetString,
      isHR68,
    ) {
      $scope.disableSendButton = false;
      $scope.form = {};
      $scope.exceptionReasonType = [
        {
          name: 'Lower Cost',
          value: 'lower_cost',
        },
        {
          name: 'No Provider Available',
          value: 'no_provider_available',
        },
        {
          name: 'Natural Disaster',
          value: 'natural_disaster',
        },
        {
          name: 'Other',
          value: 'other',
        },
      ];
      $scope.offerDetails = {
        selectedFullVendorArray: null,
        date: null,
        time: null,
        flatRateTripLevel: null,
        flatRateLegLevelTotal: null,
        flatRateForLegs: {},
        internalNotes: '',
        agencyNotes: '',
        minDate: moment().subtract(1, 'days'),
        flatRateType: 'trip',
        selectedNonEligibleOtherProviders: null,
        selectedNonEligibleOtherProvidersExceptionReason: null,
      };
      $scope.legs = rideDetails.tripInfo.legs;
      if (!isOrderUpdate) {
        $scope.legs.forEach(leg => Object.assign($scope.offerDetails.flatRateForLegs, { [leg.external_leg_id]: null }));
      }
      $scope.offerExpiryValidity = {
        correctDate: false,
        diffText: '',
      };

      const actualOffset = rideDetails.tripInfo.firstLegOffset;
      const actualOffsetString = numberToOffsetString(actualOffset);
      const flatRateTypes = {
        trip: 'Trip Level',
        leg: 'Leg Level',
      };

      $scope.sendOfferButtonDisable = false;

      $scope.actualPickupDate = rideDetails.tripInfo.firstLegPickupDate;

      $scope.date = $scope.actualPickupDate;
      $scope.time = new Date(
        1970,
        1,
        1,
        $scope.actualPickupDate.hours(),
        $scope.actualPickupDate.minutes(),
      );
      $scope.preferredVendorNotes = rideDetails.orderInfo.preferredVendorNotes || 'N/A';
      $scope.reasonType = relaylib.vendorPortal.reasonType;
      $scope.additionalReasons = [];
      function calculateSelectedVendors() {
        $scope.offerDetails.selectedFullVendorArray = [];
        $scope.offerDetails.selectedNonEligibleOtherProviders = [];
        if (_.get(rideDetails, 'availableVendors.vendorList')) {
          if (isOrderUpdate) {
            rideDetails.availableVendors.vendorList.forEach((vendor) => {
              if (vendor.selected) {
                if (
                  isHR68
                  && vendor.tabCategory === 'ne'
                  && vendor.region_status === 'other'
                ) {
                  $scope.offerDetails.selectedNonEligibleOtherProviders.push(
                    vendor,
                  );
                  $scope.offerDetails.selectedNonEligibleOtherProvidersExceptionReason = vendor.exceptionReason;
                } else {
                  $scope.offerDetails.selectedFullVendorArray.push(vendor);
                }
              }
            });
          } else {
            rideDetails.availableVendors.vendorList.forEach((vendor) => {
              if (vendor.selected && !vendor.checkDisabled) {
                if (
                  isHR68
                  && vendor.tabCategory === 'ne'
                  && vendor.region_status === 'other'
                ) {
                  $scope.offerDetails.selectedNonEligibleOtherProviders.push(
                    vendor,
                  );
                } else {
                  $scope.offerDetails.selectedFullVendorArray.push(vendor);
                }
              }
            });
          }
        }
      }

      $scope.reasonTypeSelected = () => {
        $scope.additionalReasons = ($scope.reasonType.find(reason => reason.value === $scope.offerDetails.reasonType) || {}).subReason || [];
        if ($scope.additionalReasons.length) {
          $scope.additionalReasons = $scope.additionalReasons.map(reason => ({ ...reason, checked: false }));
        }
      };

      function setFlatRateFields(tripInfo) {
        if (!tripInfo.latestFlatRate) {
          return;
        }
        const {
          latestFlatRate: {
            legToFlatFeeMapping = {},
            type = '',
            reasonType: {
              reason = null,
              subReason: subReasons = [],
            } = {},
          } = {},
        } = tripInfo;
        const totalFlatRate = +Object.values(legToFlatFeeMapping).reduce((total, num) => total + num, 0).toFixed(2);
        const flatRateFields = {
          flatRateType: type,
          reasonType: reason,
        };
        if (type === 'trip') {
          flatRateFields.flatRateTripLevel = totalFlatRate;
        } else if (type === 'leg') {
          flatRateFields.flatRateForLegs = legToFlatFeeMapping;
          flatRateFields.flatRateLegLevelTotal = totalFlatRate;
        }
        Object.assign($scope.offerDetails, flatRateFields);
        $scope.reasonTypeSelected();
        if (($scope.additionalReasons || []).length && subReasons.length) {
          subReasons.forEach((subReason) => {
            ($scope.additionalReasons.find(additionalReason => additionalReason.value === subReason) || {}).checked = true;
          });
        }
      }

      function init() {
        setFlatRateFields(rideDetails.tripInfo);
        calculateSelectedVendors();
      }

      init();

      $scope.closeModal = function closeModal() {
        $uibModalInstance.dismiss('cancel');
      };

      function validateExpiryDate() {
        const result = {
          valid: false,
          correctDate: false,
          oldDate: false,
          before: false,
          offer_expiration_utc_time: '',
          diff: {
            day: 0,
            hour: 0,
            min: 0,
            expiryMoment: null,
            pickupMoment: null,
          },
        };

        if ($scope.offerDetails.date && $scope.offerDetails.time) {
          let expiryMoment;
          const actualPickupDate = rideDetails.tripInfo.firstLegPickupDate;
          const timeMoment = moment($scope.offerDetails.time);
          if (timeMoment.isValid()) {
            const timeString = timeMoment.format('HH:mm');
            expiryMoment = moment(
              `${$scope.offerDetails.date} ${timeString}${actualOffsetString}`,
            );
            expiryMoment.utcOffset(actualOffset);
            if (expiryMoment.isBefore(actualPickupDate)) {
              result.before = true;
            }

            result.correctDate = true;

            result.diff.expiryMoment = moment(expiryMoment);
            result.diff.pickupMoment = actualPickupDate;

            const tempActualPickupMoment = moment(actualPickupDate);

            result.diff.day = tempActualPickupMoment.diff(expiryMoment, 'days');
            result.diff.hour = tempActualPickupMoment
              .subtract(result.diff.day, 'days')
              .diff(expiryMoment, 'hours');
            result.diff.min = tempActualPickupMoment
              .subtract(result.diff.hour, 'hours')
              .diff(expiryMoment, 'minutes');

            // whether date is valid or not
            const now = moment.utc();
            const nowAfterOffset = now.utcOffset(actualOffset);
            if (expiryMoment.isAfter(nowAfterOffset)) {
              result.valid = true;
              result.offer_expiration_utc_time = expiryMoment
                .utcOffset(0)
                .format('YYYY-MM-DDTHH:mm:SS');
            } else {
              result.oldDate = true;
            }
          }
        }

        return result;
      }

      function validateForm() {
        if ($scope.offerDetails.selectedFullVendorArray.length < 1 && !$scope.offerDetails.selectedNonEligibleOtherProviders.length) {
          toaster.pop({
            type: 'error',
            title: 'No Provider is selected.',
            timeout: 3000,
          });
          return false;
        }

        if (!$scope.offerDetails.date || !$scope.offerDetails.time) {
          toaster.pop({
            type: 'error',
            title: 'Offer expiry data/time field is mandatory.',
            timeout: 3000,
          });
          return false;
        }

        const validityData = validateExpiryDate();
        if (validityData.offer_expiration_utc_time) {
          $scope.offer_expiration_utc_time = validityData.offer_expiration_utc_time;
        } else {
          if (validityData.oldDate) {
            toaster.pop({
              type: 'error',
              title:
              'Offer Expiry Time selected is before current time in ride order timezone.',
              timeout: 3000,
            });
          } else {
            toaster.pop({
              type: 'error',
              title: 'Offer Expiry Time field is not valid',
              timeout: 3000,
            });
          }
          return false;
        }

        return true;
      }

      $scope.onExpiryDateChange = function onExpiryDateChange() {
        const result = validateExpiryDate();
        if (result.correctDate) {
          $scope.offerExpiryValidity.correctDate = true;
          $scope.offerExpiryValidity.diffText = `Expiry date/time (${result.diff.expiryMoment.format(
            'YYYY-MM-DD hh:mm A',
          )}) 
                         is ${Math.abs(result.diff.day)} day(s), ${Math.abs(
  result.diff.hour,
)} hour(s), ${Math.abs(result.diff.min)} min(s) 
                         ${(result.before && 'before')
                           || 'after'} Pickup date/time (${result.diff.pickupMoment.format(
  'YYYY-MM-DD hh:mm A',
)})`;
        } else {
          $scope.offerExpiryValidity.correctDate = false;
        }
      };

      $scope.sendOffer = function sendOffer() {
        if ($scope.form.sendOfferForm && $scope.form.sendOfferForm.$valid) {
          if (validateForm()) {
            $scope.sendOfferButtonDisable = true;
            $scope.disableSendButton = true;
            const { orderId } = rideDetails.orderInfo;
            const subReason = $scope.additionalReasons.filter(additionalReason => additionalReason.checked).map(additionalReason => additionalReason.value);
            const { reasonType: reason } = $scope.offerDetails;
            const sendOfferData = {
              offers: [
                {
                  ride_id: rideDetails.ride_id,
                  order_internal_notes: $scope.offerDetails.internalNotes,
                  providers_offer: [],
                },
              ],
            };

            const flatRateForLegs = { legToFlatFeeMapping: {}, type: $scope.offerDetails.flatRateType, reasonType: null };
            if ($scope.offerDetails.flatRateType === 'trip') {
              if ($scope.offerDetails.flatRateTripLevel === 0 || $scope.offerDetails.flatRateTripLevel) {
                const flatRatePerLeg = +($scope.offerDetails.flatRateTripLevel / $scope.legs.length).toFixed(2);
                $scope.legs.forEach((leg) => {
                  flatRateForLegs.legToFlatFeeMapping[leg.external_leg_id] = flatRatePerLeg;
                });
              }
            } else if ($scope.offerDetails.flatRateType === 'leg') {
              if ($scope.offerDetails.flatRateLegLevelTotal === 0 || $scope.offerDetails.flatRateLegLevelTotal) {
                flatRateForLegs.legToFlatFeeMapping = $scope.offerDetails.flatRateForLegs;
              }
            }

            if (Object.keys(flatRateForLegs.legToFlatFeeMapping).length) {
              sendOfferData.offers[0].flat_rate_for_legs = flatRateForLegs;
              sendOfferData.offers[0].reasonType = subReason.length ? {
                reason,
                subReason,
              } : {
                reason,
              };
              sendOfferData.offers[0].flat_rate_type = flatRateTypes[$scope.offerDetails.flatRateType];
              flatRateForLegs.reasonType = sendOfferData.offers[0].reasonType;
            }

            for (
              let i = 0;
              i < $scope.offerDetails.selectedFullVendorArray.length;
              i += 1
            ) {
              sendOfferData.offers[0].providers_offer.push({
                registered_email:
                $scope.offerDetails.selectedFullVendorArray[i].email,
                provider_id:
                $scope.offerDetails.selectedFullVendorArray[i].fuseId,
                legal_name: $scope.offerDetails.selectedFullVendorArray[i].name,
                is_rescinded: 0,
                offer_expiration_utc_time: $scope.offer_expiration_utc_time,
              });
            }
            for (
              let i = 0;
              i < $scope.offerDetails.selectedNonEligibleOtherProviders.length;
              i += 1
            ) {
              sendOfferData.offers[0].providers_offer.push({
                registered_email:
                $scope.offerDetails.selectedNonEligibleOtherProviders[i].email,
                provider_id:
                $scope.offerDetails.selectedNonEligibleOtherProviders[i].fuseId,
                legal_name: $scope.offerDetails.selectedNonEligibleOtherProviders[i].name,
                is_rescinded: 0,
                offer_expiration_utc_time: $scope.offer_expiration_utc_time,
              });
            }
            sendOfferData.isRescind = false;
            if (
              $scope.offerDetails
                .selectedNonEligibleOtherProvidersExceptionReason
            ) {
              sendOfferData.exceptionReason =                $scope.offerDetails.selectedNonEligibleOtherProvidersExceptionReason;
            }
            $http
              .post(`${API_BASE_URL}order/${orderId}/offers/send`, sendOfferData)
              .then((result) => {
                $scope.disableSendButton = false;
                $scope.closeModal();
                if (result.data.success) {
                  toaster.pop({
                    type: 'success',
                    title: 'Offers sent to selected Providers.',
                    timeout: 3000,
                  });
                } else {
                  toaster.pop({
                    type: 'error',
                    title:
                    result.data.message
                    || 'Not able to send offers to selected Providers.',
                    timeout: 3000,
                  });
                }
              })
              .catch((error) => {
                $scope.disableSendButton = false;
                $scope.closeModal();
                toaster.pop({
                  type: 'error',
                  title:
                  error.message || error.data.message
                  || 'Not able to send offers to selected Providers.',
                  timeout: 3000,
                });
              });
          }
        } else {
          $scope.submitted = true;
        }
      };
      $scope.addLegFlatRates = function addLegFlatRates() {
        $scope.offerDetails.flatRateLegLevelTotal = +Object.values($scope.offerDetails.flatRateForLegs).reduce((total, num) => total + (num || 0), 0).toFixed(2);
      };
      $scope.checkIfAnyLegHasFlatRate = function checkIfAnyLegHasFlatRate() {
        const ifAnyLegHasFlatRate = Object.values($scope.offerDetails.flatRateForLegs).some(flatRateForLeg => (flatRateForLeg === 0 || flatRateForLeg));
        if (!ifAnyLegHasFlatRate) {
          $scope.offerDetails.flatRateLegLevelTotal = null;
          $scope.offerDetails.ifAnyLegHasFlatRate = false;
        } else {
          $scope.offerDetails.ifAnyLegHasFlatRate = true;
        }
      };
    },
  ]);
