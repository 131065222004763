angular
  .module('relayHealth')
  .controller('newAppointmentCtrl', [
    '$scope',
    '$rootScope',
    '$http',
    'API_BASE_URL',
    '$state',
    'toaster',
    'NgMap',
    '$timeout',
    'SweetAlert',
    '$uibModalInstance',
    'day',
    'appointment',
    'patient',
    'updateBtnText',
    function newAppointmentCtrl(
      $scope,
      $rootScope,
      $http,
      API_BASE_URL,
      $state,
      toaster,
      NgMap,
      $timeout,
      SweetAlert,
      $uibModalInstance,
      day,
      appointment,
      patient,
      updateBtnText,
    ) {
      $scope.ismeridian = true;
      $scope.hstep = 1;
      $scope.mstep = 1;
      $scope.cancel = (notClose) => {
        if ($scope.pickupMarker && typeof $scope.pickupMarker.setMap === 'function') {
          $scope.pickupMarker.setMap(null);
        }
        if ($scope.dropMarker && typeof $scope.dropMarker.setMap === 'function') {
          $scope.dropMarker.setMap(null);
        }
        if (!notClose) {
          $uibModalInstance.dismiss('cancel');
          if ($scope.pickupLatLng) delete $scope.pickupMarker;
          if ($scope.dropLatLng) delete $scope.dropLatLng;
        }
      };

      function setAppointmentData(data) {
        Object.assign(data, {
          appt_date_time: moment(parseInt(data.appt_date_time, 10) * 1000),
          appt_end_time: moment(parseInt(data.appt_end_time, 10) * 1000),
          drop_location: data.dest_address,
          new_pickup_location: data.source_address,
        });

        $scope.appointment = data;
      }

      const vm = this;

      $scope.initiateAppntment = () => {
        const now = moment();
        $scope.minDate = angular.copy(now);

        if ($scope.pickupMarker && typeof $scope.pickupMarker.setMap === 'function') {
          $scope.pickupMarker.setMap(null);
        }
        if ($scope.dropMarker && typeof $scope.dropMarker.setMap === 'function') {
          $scope.dropMarker.setMap(null);
        }
        if (appointment) {
          $scope.operation = 'Edit';
          $scope.patientNameDisabled = true;
          setAppointmentData(angular.copy(appointment));
          $scope.updateBtnText = updateBtnText;
        } else {
          $scope.operation = 'Schedule';
          $scope.appointment = {
            patient: patient || {},
            appt_date_time: moment(day.getTime()) || now,
          };
        }

        $scope.showAppntWizard = true;

        $timeout(() => {
          NgMap.getMap({ id: 'appointMap' }).then((map) => {
            vm.map = map;
            google.maps.event.trigger(vm.map, 'resize');
            if (appointment) {
              $scope.pickupLatLng = new google.maps.LatLng(appointment.source_lat_long[0], appointment.source_lat_long[1]);
              $scope.addPickupMarker(vm.map, appointment.short_source, $scope.pickupLatLng);

              $scope.dropLatLng = new google.maps.LatLng(appointment.dest_lat_long[0], appointment.dest_lat_long[1]);
              $scope.addDestMarker(vm.map, appointment.short_dest, $scope.dropLatLng);

              $scope.setBounds();
            }
          });
        }, 1000);
      };

      $scope.initiateAppntment();

      function patients() {
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        let url;
        if ($state.params && $state.params.divId) {
          url = `${API_BASE_URL}patient/${$state.params.divId}`;
        } else {
          url = `${API_BASE_URL}patient/${userDetails.division[0].div_id}`;
        }
        const res = $http.get(url);
        res.then(({ data }) => {
          if (data.success) {
            $scope.patients = data.patient_data;
            if (appointment && !appointment.patient) {
              data.patient_data.forEach((value) => {
                if (value.id === appointment.patient_id) {
                  Object.assign(appointment, {
                    patient: value,
                  });
                  $scope.appointment.patient = value;
                }
              });
            }
          } else {
            toaster.pop({
              type: 'error',
              title: data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        });
        res.catch(() => toaster.pop({
          type: 'error',
          title: 'Not able to get riders data',
          showCloseButton: true,
          timeout: 10000,
        }));
      }

      if (!patient && !appointment) {
        patients();
      } else if (patient) {
        $scope.appointment.patient = patient;
        $scope.patientNameDisabled = true;
      }

      const geocoder = new google.maps.Geocoder();

      $scope.setHomeAddress = () => {
        if ($scope.pickupMarker && typeof $scope.pickupMarker.setMap === 'function') {
          $scope.pickupMarker.setMap(null);
          $scope.appointment.new_pickup_location = '';
          $scope.appointment.short_source = '';
        }
        if ($scope.appointment.use_home_address && $scope.appointment.patient) {
          const loc = [];
          if ($scope.appointment.patient.address1 && $scope.appointment.patient.address1 !== '') {
            loc.push($scope.appointment.patient.address1);
            $scope.appointment.short_source = $scope.appointment.patient.address1;
          }
          if ($scope.appointment.patient.address2 && $scope.appointment.patient.address2 !== '') {
            loc.push($scope.appointment.patient.address2);
          }
          if ($scope.appointment.patient.city && $scope.appointment.patient.city !== '') {
            loc.push($scope.appointment.patient.city);
          }
          if ($scope.appointment.patient.state && $scope.appointment.patient.state !== '') {
            loc.push($scope.appointment.patient.state);
          }
          if ($scope.appointment.patient.country) {
            loc.push($scope.appointment.patient.country);
          }
          $scope.appointment.temp_pickup_location = '';
          $scope.appointment.temp_pickup_location = loc.join(', ');

          geocoder.geocode({ address: $scope.appointment.temp_pickup_location }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              const latitude = results[0].geometry.location.lat();
              const longitude = results[0].geometry.location.lng();

              $scope.pickupLatLng = new google.maps.LatLng(latitude, longitude);
              $scope.appointment.source_lat_long = [latitude, longitude];

              $scope.addPickupMarker(vm.map, loc[0], $scope.pickupLatLng);
              $scope.setBounds();
            }
          });
        } else {
          $scope.appointment.temp_pickup_location = '';
        }
      };

      $scope.$watch('appointment.patient', (newV) => {
        if (newV && newV.id && $scope.appointment.use_home_address) {
          $scope.setHomeAddress();
        }
      });

      $scope.pickupChanged = () => {
        $scope.products = undefined;
        const pickupPlace = this.getPlace();

        $scope.pickupLatLng = new google.maps.LatLng(pickupPlace.geometry.location.lat(), pickupPlace.geometry.location.lng());
        $scope.appointment.source_lat_long = [pickupPlace.geometry.location.lat(), pickupPlace.geometry.location.lng()];
        $scope.appointment.short_source = pickupPlace.name;

        $scope.addPickupMarker(vm.map, pickupPlace.name, $scope.pickupLatLng);
        $scope.setBounds();
      };

      $scope.addPickupMarker = (map, name, latLng) => {
        if ($scope.pickupMarker && typeof $scope.pickupMarker.setMap === 'function') {
          $scope.pickupMarker.setMap(null);
        }
        const contentString = `<div><h4>Pickup Location</h4><div>${name}</div></div>`;
        const infowindow = new google.maps.InfoWindow({
          content: contentString,
        });
        $scope.pickupMarker = new google.maps.Marker({
          position: latLng,
          map,
          label: '',
          icon: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
          animation: google.maps.Animation.DROP,
        });
        $scope.pickupMarker.addListener('click', () => {
          infowindow.open(map, $scope.pickupMarker);
        });
      };

      $scope.addDestMarker = (map, name, latLng) => {
        if ($scope.dropMarker && typeof $scope.dropMarker.setMap === 'function') {
          $scope.dropMarker.setMap(null);
        }
        const contentString = `<div><h3>Drop Location</h1><div>${name}</div></div>`;
        const infowindow = new google.maps.InfoWindow({
          content: contentString,
        });
        $scope.dropMarker = new google.maps.Marker({
          position: latLng,
          map,
          label: '',
          icon: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
          animation: google.maps.Animation.DROP,
        });
        $scope.dropMarker.addListener('click', () => {
          infowindow.open(map, $scope.dropMarker);
        });
      };

      $scope.dropChanged = () => {
        $scope.products = undefined;
        const dropPlace = this.getPlace();

        $scope.dropLatLng = new google.maps.LatLng(dropPlace.geometry.location.lat(), dropPlace.geometry.location.lng());
        $scope.appointment.short_dest = dropPlace.name;
        $scope.appointment.dest_lat_long = [dropPlace.geometry.location.lat(), dropPlace.geometry.location.lng()];

        $scope.addDestMarker(vm.map, dropPlace.name, $scope.dropLatLng);
        $scope.setBounds();
      };

      $scope.setBounds = () => {
        const bounds = new google.maps.LatLngBounds();
        if ($scope.pickupMarker && typeof $scope.pickupMarker.setMap === 'function' && $scope.dropMarker && typeof $scope.dropMarker.setMap === 'function') {
          bounds.extend($scope.pickupLatLng);
          bounds.extend($scope.dropLatLng);
          vm.map.fitBounds(bounds);
          vm.map.panToBounds(bounds);
        } else if (
          $scope.pickupMarker
          && typeof $scope.pickupMarker.setMap === 'function'
          && (!$scope.dropMarker || typeof $scope.dropMarker.setMap !== 'function')
        ) {
          vm.map.setCenter($scope.pickupLatLng);
        } else if (
          (!$scope.pickupMarker || typeof $scope.pickupMarker.setMap !== 'function')
          && ($scope.dropMarker && typeof $scope.dropMarker.setMap === 'function')
        ) {
          vm.map.setCenter($scope.dropLatLng);
        }
      };

      $scope.create = () => {
        if (!$scope.appointment.patient.id) {
          toaster.error('Please select rider');
        } else if (!$scope.appointment.appt_date_time) {
          toaster.error('Please select trip date');
        } else if (!$scope.appointment.use_home_address && !$scope.appointment.new_pickup_location) {
          toaster.error('Please select pickup location');
        } else if ($scope.appointment.use_home_address && !$scope.appointment.temp_pickup_location) {
          toaster.error('Please select pickup location');
        } else if (!$scope.appointment.drop_location) {
          toaster.error('Please select drop location');
        } else {
          const userDetails = JSON.parse(localStorage.getItem('userDetails'));
          const appntmnt = {};
          appntmnt.patient_id = $scope.appointment.patient.id;
          if ($scope.appointment.appt_date_time) {
            let date = new Date($scope.appointment.appt_date_time);
            date = new Date(date.setSeconds(0));
            date = date.setMilliseconds(0);
            $scope.appointment.appt_date_time = date;
            appntmnt.appt_date_time = moment($scope.appointment.appt_date_time).unix();
          }
          appntmnt.source_address = $scope.appointment.use_home_address ? $scope.appointment.temp_pickup_location : $scope.appointment.new_pickup_location;
          appntmnt.dest_address = $scope.appointment.drop_location;
          appntmnt.provider_id = userDetails.user_id;
          appntmnt.short_source = $scope.appointment.short_source;
          appntmnt.book_now = $scope.appointment.book_now || 0;
          appntmnt.short_dest = $scope.appointment.short_dest;
          appntmnt.provider_name = $scope.appointment.provider_name ? $scope.appointment.provider_name : 'uber';

          appntmnt.source_lat_long = $scope.appointment.source_lat_long;
          appntmnt.dest_lat_long = $scope.appointment.dest_lat_long;
          $scope.requestPending = true;

          if ($scope.appointment.id) {
            $http.put(`${API_BASE_URL}appointment/edit/${$scope.appointment.id}`, appntmnt).then(
              ({ data: { data: { prices = [] } = {} }, message }) => {
                $scope.requestPending = false;
                if (!prices.length) {
                  toaster.error(message);
                }
                $scope.products = prices;
                if (appointment) {
                  const selectedProduct = $scope.products.find(value => (value.product_id === appointment.uber_prod_id));
                  if (selectedProduct) {
                    selectedProduct.selected = true;
                    $scope.appointment.productSelected = selectedProduct;
                  }
                }
              },
              (error) => {
                $scope.requestPending = false;
                if (angular.isObject(error.data.error_messages)) {
                  angular.forEach(error.data.error_messages, (errorMessage) => {
                    toaster.error(errorMessage || 'Something went wrong');
                  });
                } else {
                  toaster.error(error.data.message || error.data.error_messages || 'Something went wrong');
                }
              },
            );
          } else {
            $http.post(`${API_BASE_URL}appointment/add/${patient.org_id}`, appntmnt).then(
              (response) => {
                $scope.requestPending = false;
                if (!response.data.data || !response.data.data.appointment_id) {
                  toaster.error('Error occured while creating trip');
                } else if (response.data.data && response.data.data.prices) {
                  $scope.appointment.id = response.data.data.appointment_id;
                  $scope.products = response.data.data.prices;
                  // $scope.goNext(2);
                  // $scope.cancel(true);
                } else {
                  toaster.error(response.data.message);
                }
              },
              (error) => {
                $scope.requestPending = false;
                if (angular.isObject(error.data.error_messages)) {
                  angular.forEach(error.data.error_messages, (errorMessage) => {
                    toaster.error(errorMessage || 'Something went wrong');
                  });
                } else {
                  toaster.error(error.data.message || error.data.error_messages || 'Something went wrong');
                }
              },
            );
          }
        }
      };

      $scope.doSubmit = () => {
        if (!$scope.appointment.id) {
          toaster.error('Trip not created');
        } else if (!$scope.appointment.productSelected || !$scope.appointment.productSelected.product_id) {
          toaster.error('Please select product');
        } else {
          const doConfirm = {};
          doConfirm.appointment_id = $scope.appointment.id;
          doConfirm.uber_prod_id = $scope.appointment.productSelected.product_id;
          doConfirm.price = `${$scope.appointment.productSelected.low_estimate.toString()}-${$scope.appointment.productSelected.high_estimate.toString()}`;
          doConfirm.patient_id = $scope.appointment.patient.id;
          doConfirm.source_lat_long = $scope.appointment.source_lat_long;
          doConfirm.dest_lat_long = $scope.appointment.dest_lat_long;
          doConfirm.short_source = $scope.appointment.short_source;
          doConfirm.short_dest = $scope.appointment.short_dest;
          doConfirm.distance = $scope.appointment.productSelected.distance;
          doConfirm.currency = $scope.appointment.productSelected.currency_code;
          doConfirm.eta = $scope.appointment.productSelected.duration;
          $scope.requestPending = true;
          $http.put(`${API_BASE_URL}appointment/confirm`, doConfirm)
            .then(
              () => {
                $scope.requestPending = false;
                $scope.showAppntWizard = false;
                $scope.cancel();
                $rootScope.$broadcast('appointmentBooked');

                if ($scope.updateBtnText === 'Confirm') $rootScope.$broadcast('refreshTilesCount');

                const msg = $scope.operation === 'Schedule' ? 'Booked!' : 'Updated!';
                SweetAlert.swal({
                  title: msg,
                  text: `Trip ${msg} Successfully`,
                  type: 'success',
                });
                if (
                  $scope.appointment.appt_status === 'booked'
                  && $state.current.name === 'main.dashboard.superAdminDashboard'
                ) {
                  if ($scope.appointment.booking_status === 0) {
                    $rootScope.$broadcast('refreshDashboard');
                  } else if ($scope.appointment.booking_status === 1) {
                    $rootScope.$broadcast('refreshApproved');
                  }
                }
              },
              (error) => {
                $scope.requestPending = false;
                if (error.data.error_messages && angular.isObject(error.data.error_messages)) {
                  angular.forEach(error.data.error_messages, (errorMessage) => {
                    toaster.error(errorMessage || 'Something went wrong');
                  });
                } else {
                  toaster.error(error.data.message || error.data.error_messages || 'Something went wrong');
                }
              },
            );
        }
      };

      $scope.openDatePicker = (e) => {
        $timeout(() => {
          $($(e.currentTarget).siblings()[0])[0].focus();
        }, 500);
      };
    },
  ])
  .filter('showMinutes', [
    () => function showMinutes(seconds) {
      if (seconds) {
        const minutes = seconds / 60;

        const m = minutes > 9 ? minutes.toString() : `0${minutes.toString()}`;
        const second = seconds % 60;
        const s = second > 9 ? second.toString() : `0${second.toString()}`;

        return `${m}:${s}`;
      }
      return 'No Estimate Available';
    },
  ]);
