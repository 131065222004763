const featureFlags = {
    NO_REQUEST_OVERDUE_ALERT: 'no_request_overdue_alert',
    NO_VARIANCE_ALERT: 'no_variance_alert',
    NO_LATE_ARRIVAL_ALERT: 'no_late_arrival_alert',
    DISABLE_FUTURE_RIDE_UPDATE: 'disable_future_ride_update',
    LOCK_AFTER_UPDATE: 'lock_after_update',
    UPDATE_LOCKED_RIDE: 'update_locked_ride',
    ZIPCODE_AVAILABILITY: 'zipcode_availability',
    DISABLE_LYFT_AVAILABILITY: 'disable_lyft_availability',
    ALLOW_RECURRENCE: 'allow_recurrence',
    SCHEDULED_CAB_BUFFER_TIME: 'scheduled_cab_buffer_time',
    NO_RIDER_LATE_ARRIVAL_ALERT: 'no_rider_late_arrival_alert',
    CONSIDER_AVERAGE_WAIT_TIME: 'consider_average_wait_time',
    MAIN_CONTACT_REQUIRED: 'main_contact_required', // organisation contact details linked to user
};
Object.freeze(featureFlags);
export default featureFlags;