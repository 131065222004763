angular.module('relayHealth').directive('preventBlur', () => ({
  restrict: 'A',
  link(scope, element) {
    element.on('blur', () => {
      const { userAgent } = window.navigator;
      const isInternetExplorer = userAgent.includes('MSIE ') || userAgent.includes('Trident/');
      if (!isInternetExplorer) {
        const isInvalidField = element.hasClass('ng-invalid');
        if (isInvalidField) {
          element.focus();
        }
      }
    });
  },
}));
