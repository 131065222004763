const rideAdditionalDetailsMapping = {
    AdditionalChildPassengerCount: "additional_child_psngr_count",
    AdditionalAdultPassengerCount: "additional_adult_psngr_count",
    InfantChildSeatCount: "infant_child_seat_count",
    ToddlerChildSeatCount: "toddler_child_seat_count",
    RequiresCarSeatAssistance: "provides_car_seat_assistance",
    RequiresAssistanceWithVehicleDoor: "provides_assit_with_vehicle_door",
    RequiresAssistanceWithSeatBelt: "provides_assit_with_seat_belt",
    RequiresAssistanceWithMobilityDevice: "provides_assit_with_mobility_device",
    HasManualWheelchair: "has_manual_wheel_chair",
    HasElectricWheelchair: "has_electric_wheel_chair",
    RequiresDoorThroughDoorAssistance: "provides_door_through_door_assistance",
    RequiresDoorToDoorAssistance: "provides_door_to_door_assitance",
    HasServiceAnimal: "has_service_animal"
};
Object.freeze(rideAdditionalDetailsMapping);
export default rideAdditionalDetailsMapping;