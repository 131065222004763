const singleton = Symbol();
const singletonEnforcer = Symbol();

const _permissions = new WeakMap();
/**
 * Manager class for all permissions for a logged in user
 */
class PermissionsManager {
    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) {
          throw new Error('Cannot construct singleton');
        }

        _permissions.set(this, []);
    }

    /**
     * Holds the singleton instance of this class
     */
    static get instance() {
        if (!this[singleton]) {
          this[singleton] = new PermissionsManager(singletonEnforcer);
        }

        return this[singleton];
    }
    set instance(val) {
        throw new Error('Cannot set singleton');
    }

    /**
     * Invoked to setup permissions array
     * @param {Array} permissions   Permissions to set
     */
    init(permissions) {
        _permissions.set(this, permissions || []);
    }

    /**
     * Invoked to verify if the specified permission is allowed or not
     * @param {String} permission Permission key to look for
     */
    isAllowed(permission) {
        let isAllowed = false;
        if (_permissions.get(this).length > 0 && _permissions.get(this).indexOf(permission) > -1) {
            isAllowed = true;
        }
        return isAllowed;
    }
}

export default PermissionsManager;