const rideStatus = {
    'CONFIRMED': {
        'type': 'scheduled',
        'status': 'Confirmed',
        'error_level': 1,
        'queue': 'bookCab',
        'exit_sqs': false
    },
    'APPOINTMENT_COMPLETED': {
        'type': 'completed',
        'status': 'Appointment Completed',
        'error_level': 1,
        'queue': '',
        'exit_sqs': true
    },
    'PENDING_RETURN_REQUEST': {
        'type': 'pending return',
        'status': 'Pending Return Request',
        'error_level': 1,
        'queue': '',
        'exit_sqs': true
    },
    'APPOINTMENT_CANCELED': {
        'type': 'canceled',
        'status': 'Appointment Canceled',
        'error_level': 1,
        'queue': '',
        'exit_sqs': true
    },
    'APPOINTMENT_REJECTED': {
        'type': 'rejected',
        'status': 'Appointment Rejected',
        'error_level': 1,
        'queue': '',
        'exit_sqs': true
    },
    'APPOINTMENT_POTENTIAL_UNAVAILABILITY': {
        'type': 'issue',
        'status': 'Potential Unavailability',
        'error_level': 2,
        'queue': '',
        'exit_sqs': false
    },
    'PATIENT_NO_SHOW': {
        'type': 'issue',
        'status': 'Patient No Show',
        'error_level': 1,
        'queue': 'bookCab',
        'exit_sqs': true
    },
    'OFFER_REJECTED': {
        'status': 'Offer Rejected',
        'error_level': 1
    },
    'OFFER_EXPIRED': {
        'status': 'Offer Expired',
        'error_level': 1
    },
    'ORDER_RECEIVED': {
        'type': 'pending',
        'status': 'Order Received',
        'error_level': 1
    },
    'ORDER_QUEUED': {
        'status': 'Order Queued',
        'error_level': 1
    },
    'OFFER_RESCINDED': {
        'status': 'Offer Rescinded',
        'error_level': 1
    },
    'ORDER_UPDATED': {
        'type': 'alert',
        'status': 'Order Updated',
        'error_level': 1,
        'queue': '',
        'exit_sqs': false
    },
    'OFFERED': {
        'type': 'processing',
        'status': 'Offered',
        'error_level': 1,
        'queue': '',
        'exit_sqs': false
    },
    'PENDING_DISPOSITION': {
        'type': 'completed',
        'status': 'Pending Disposition',
        'error_level': 1,
    },
};
export default Object.freeze(rideStatus);
