import Ladda from 'ladda';

angular
  .module('relayHealth')
  .controller('scheduledApptsCtrl', [
    '$scope',
    '$http',
    'API_BASE_URL',
    'toaster',
    '$state',
    'loadingScreenFactory',
    '$location',
    '$stateParams',
    'DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID',
    'getMarketSegments',
    'ngDialog',
    'SweetAlert',
    'isOCCMRole',
    'permissionFilter',
    '$q',
    'storageManager',
    '$timeout',
    'requestingOrganizationFactory',
    'orgHierarchyFactory',
    function scheduledApptsCtrl(
      $scope,
      $http,
      API_BASE_URL,
      toaster,
      $state,
      loadingScreenFactory,
      $location,
      $stateParams,
      DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID,
      getMarketSegments,
      ngDialog,
      SweetAlert,
      isOCCMRole,
      permissionFilter,
      $q,
      storageManager,
      $timeout,
      requestingOrganizationFactory,
      orgHierarchyFactory,
    ) {
      $scope.moment = moment;
      $scope.provider = { providerNameList: [] };
      const { permissionsConstants } = relaylib.permissions;
      $scope.permissionsConstants = permissionsConstants;
      $scope.keyMap = relaylib.common.constants.rideStatusMapping || {};
      $scope.dbOrgTypes = relaylib.organisation.constants.dbOrgTypes;
      let userDetails = JSON.parse(localStorage.getItem('userDetails'));
      const requestingOrganizationsVisibleCount = 20;
      const { storage } = storageManager;
      const currentState = $state.current.name;
      function writeSearchFilter(tab) {
        const prevSearchValues = storage.getDataByKey('keepFilterData', currentState);
        let tabToSearchOn = tab;
        if (prevSearchValues) {
          const prevForm = prevSearchValues.data;
          tabToSearchOn = prevSearchValues.tab;
          [$scope.tabs[tabToSearchOn].scheduled.organization] = _.filter($scope.orgList.data, { name: prevForm.organizationName });
          $scope.tabs[tabToSearchOn].scheduled.patientName = prevForm.patientName;
          $scope.tabs[tabToSearchOn].scheduled.claimNumber = prevForm.claimNumber;
          $scope.tabs[tabToSearchOn].scheduled.dateRange = prevForm.dateRange;
          $scope.tabs[tabToSearchOn].scheduled.market_segment_id = prevForm.market_segment_id;
          $scope.tabs[tabToSearchOn].scheduled.status = prevForm.status;
          $scope.tabs[tabToSearchOn].scheduled.userList = prevForm.userList;
          $scope.tabs[tabToSearchOn].scheduled.orderId = prevForm.orderId;
          $scope.tabs[tabToSearchOn].scheduled.masInvoiceNumber = prevForm.masInvoiceNumber;
          $scope.tabs[tabToSearchOn].scheduled.tripIdentifierType = prevForm.tripIdentifierType;
          $scope.tabs[tabToSearchOn].scheduled.providerName = prevForm.providerName;
          if ($scope.tabs[tabToSearchOn].scheduled.organization) {
            $scope.orgChanged($scope.tabs[tabToSearchOn].scheduled.organization.id, true);
          }
          $scope.tabs[tabToSearchOn].scheduled.requestingOrganization = prevForm.requestingOrganization;
          $timeout(() => {
            $scope.searchButtonClicked(tabToSearchOn);
          });
        } else {
          $scope.searchButtonClicked(tabToSearchOn);
        }
      }

      let isInited = false;
      function init() {
        if (isInited) {
          return;
        }
        isInited = true;
        Object.keys($scope.tabs).some((tab) => {
          if ($scope.tabs[tab].active) {
            writeSearchFilter(tab);
            return true;
          }
          return false;
        });
      }

      function toasterError(message) {
        toaster.pop({
          type: 'error',
          title: message || 'Could not fetch users List',
          showCloseButton: true,
          timeout: 10000,
        });
      }
      $scope.loadMoreOrgChoices = () => {
        $scope.organizations.visibleChoices += requestingOrganizationsVisibleCount;
      };
      // $scope.searchFormQuery = { dateRange: {} };
      let laddaButton;
      $scope.DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID = DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID;
      $scope.isOCCMRole = isOCCMRole() || permissionFilter(permissionsConstants.MARKET_SEGMENT_READ);
      $scope.disableOrgList = false;
      /*
        *@param orgData: object of org data
        *checkOrg function used to check is org selected or not,
        * if selected call orgChanged function
        * if selected any org then refresh user list
        */
      $scope.checkOrg = (orgData) => {
        if (!orgData) {
          $scope.tabs.rides.scheduled.userList = [];
          $scope.tabs.rides.reloadRequesters = true;
        } else {
          $scope.orgChanged(orgData.id);
        }
      };
      const orgUsers = {};
      $scope.getUsersList = (offset, limit, tabName) => {
        const getUsersUrl = 'getUsersByOrgIdForDropBox/';

        if (!($scope.tabs[tabName] && _.get($scope.tabs[tabName], 'scheduled.organization.id'))) {
          return $q.resolve({ data: [] });
        }
        if (Object.prototype.hasOwnProperty.call(orgUsers, $scope.tabs[tabName].scheduled.organization.id)) {
          return $q.resolve({
            data: orgUsers[$scope.tabs[tabName].scheduled.organization.id],
            count: orgUsers[$scope.tabs[tabName].scheduled.organization.id].length,
          });
        }
        // Do nothing if data not found in cache

        const selectedOrg = $scope.tabs[tabName].scheduled.organization.id;
        return $http.get(`${API_BASE_URL}${getUsersUrl}${selectedOrg}/${offset}/${limit}`).then(
          (resp) => {
            let userList = [];
            const { data } = resp;
            if (data.success) {
              userList = data.userList.map(user => ({ name: user.full_name, userId: user.ride_requester_id }));
            } else {
              toasterError(data.message);
            }
            return {
              data: userList,
              count: Number(data.totalCount),
            };
          },
          (data) => {
            toasterError(data.message);
          },
        );
      };

      $scope.cacheUserList = (data, tabName) => {
        if (data && data.length > 0 && $scope.tabs[tabName] && _.get($scope.tabs[tabName], 'scheduled.organization.id')) {
          orgUsers[$scope.tabs[tabName].scheduled.organization.id] = data;
        }
      };

      $scope.displayItemsperPages = 17;
      if ($scope.showChildData()) {
        $scope.displayItemsperPages = 18;
      }
      $scope.tabs = {
        rides: {
          heading: 'Vendor Rides',
          active: true,
          url: 'rides/getRidesListByRideCategory/scheduled/appt_date_time ASC/',
          pagination: {
            totalItems: 0,
            currentPage: 1,
            lastItem: 0,
            itemsPerPage: $scope.displayItemsperPages,
            data: [],
          },
          firstTimeLoad: true,
          scheduledRides: {},
          searchFormQuery: {
            dateRange: {
              startDate: null,
              endDate: null,
            },
          },
          scheduled: {
            dateRange: {
              startDate: null,
              endDate: null,
            },
            userList: [],
          },
          show: true,
        },
        pr: {
          heading: 'Mileage Tracker',
          active: false,
          url: 'rides/getRidesListByRideCategory/scheduled/appt_date_time ASC/',
          pagination: {
            totalItems: 0,
            currentPage: 1,
            lastItem: 0,
            itemsPerPage: 20,
            data: [],
          },
          firstTimeLoad: true,
          scheduledRides: {},
          searchFormQuery: {
            dateRange: {},
          },
          scheduled: {
            dateRange: {},
          },
          show: false,
        },
      };

      $scope.dateRangeOptions = {
        parentEl: '#current-app #page-wrapper',
        format: 'YYYY-MM-DD',
        locale: {
          format: 'YYYY-MM-DD',
        },
        eventHandlers: {
          'apply.daterangepicker': () => {
            const activeTab = Object.keys($scope.tabs)
              .find(tabKey => $scope.tabs[tabKey].active);

            const element = document.getElementById(`dateRange-${activeTab}`);

            if (!element) {
              return;
            }

            const dateRangeFilter = element.value;
            if (dateRangeFilter) {
              const dateRangeFilterArr = dateRangeFilter.split(' - ');
              $scope.tabs[activeTab].searchFormQuery.dateRange.startDate = `${dateRangeFilterArr[0]} 00:00:00`;
              $scope.tabs[activeTab].searchFormQuery.dateRange.endDate = `${dateRangeFilterArr[1]} 23:59:59`;
              $scope.tabs[activeTab].scheduled.dateRange = $scope.tabs[activeTab].searchFormQuery.dateRange;
            }
          },
        },
      };

      const statusColor = {
        danger: '#ed5565',
        plain: '#e7eaec',
        warn: '#F9A825',
        info: '#23c6c8',
      };

      if ($stateParams) {
        const tabKey = $stateParams.activeTab || 'rides';

        $location.search('activeTab', tabKey);

        Object.keys($scope.tabs).forEach((key) => {
          if (tabKey === key) {
            $scope.tabs[key].active = true;
          } else {
            $scope.tabs[key].active = false;
          }
        });

        Object.keys($stateParams).forEach((key) => {
          if (key === 'startDate' && $stateParams.startDate) {
            $scope.tabs[tabKey].searchFormQuery.dateRange.startDate = $stateParams.startDate;
            $scope.tabs[tabKey].scheduled.dateRange.startDate = $stateParams.startDate;
          } else if (key === 'endDate' && $stateParams.endDate) {
            $scope.tabs[tabKey].searchFormQuery.dateRange.endDate = $stateParams.endDate;
            $scope.tabs[tabKey].scheduled.dateRange.endDate = $stateParams.endDate;
          } else if (key === 'currentPage' && $stateParams.currentPage) {
            $scope.tabs[tabKey].pagination.currentPage = $stateParams.currentPage;
            $scope.tabs[tabKey].scheduled.currentPage = $stateParams.currentPage;
          } else if (key === 'organizationName' && $stateParams.organizationName) {
            $scope.tabs[tabKey].searchFormQuery.organization = {
              name: $stateParams.organizationName,
            };
            $scope.tabs[tabKey].scheduled.organization = {
              name: $stateParams.organizationName,
            };
          } else {
            $scope.tabs[tabKey].searchFormQuery[key] = $stateParams[key];
            $scope.tabs[tabKey].scheduled[key] = $stateParams[key];
          }
        });
      }

      $scope.scheduledRides = [
        {
          text: 'REQUEST A RIDE',
        },
      ];

      const statusObj = {
        'Potential Unavailability': {
          type: 'warn',
        },
        'Failed Fuse': {
          type: 'warn',
        },
        Confirmed: {
          type: 'info',
        },
        'Pending Approval': {
          type: 'pending',
        },
        'Departure Overdue': {
          type: 'warn',
        },
        'Pending Submission': {
          type: 'warn',
        },
        'Get Ready': {
          type: 'warn',
        },
        'Patient Enroute': {
          type: 'warn',
        },
        '(s) Potential Unavailability': {
          type: 'warn',
        },
        'Offer Created': {
          type: 'danger',
        },
        UATP: {
          type: 'danger',
        },
        NEPF: {
          type: 'danger',
        },
        NDA: {
          type: 'danger',
        },
        'Order Updated': {
          type: 'danger',
        },
        Offered: {
          type: 'danger',
        },
        '24 Hour Confirmation Missing': {
          type: 'danger',
        },
        'Pending - Re-Offer': {
          type: 'warn',
        },
        'Validation Failed': {
          type: 'warn',
        },
        'Offered NDA': {
          type: 'warn',
        },
      };

      const filterStatusObj = [
        {
          type: 'info',
          displayText: $scope.keyMap.Confirmed,
          statusInDB: 'Confirmed',
        },
        {
          type: 'purple',
          displayText: $scope.keyMap['Series Confirmed'],
          statusInDB: 'Series Confirmed',
        },
        {
          type: 'warn',
          displayText: $scope.keyMap['Failed Fuse'],
          statusInDB: 'Failed Fuse',
        },
        {
          type: 'warn',
          displayText: $scope.keyMap['Potential Unavailability'],
          statusInDB: 'Potential Unavailability',
        },
        {
          type: 'danger',
          displayText: $scope.keyMap['Offer Created'],
          statusInDB: ['Offer Created', 'Pending - Re-Offer'],
        },
        {
          type: 'danger',
          displayText: $scope.keyMap.UATP,
          statusInDB: 'UATP',
        },
        {
          type: 'danger',
          displayText: $scope.keyMap.NEPF,
          statusInDB: 'NEPF',
        },
        {
          type: 'danger',
          displayText: $scope.keyMap.NDA,
          statusInDB: 'NDA',
        },
        {
          type: 'danger',
          displayText: $scope.keyMap['Order Updated'],
          statusInDB: 'Order Updated',
        },
        {
          type: 'danger',
          displayText: $scope.keyMap.Offered,
          statusInDB: 'Offered',
        },
        {
          type: 'danger',
          displayText: $scope.keyMap['24 Hour Confirmation Missing'],
          statusInDB: '24 Hour Confirmation Missing',
        },
        {
          type: 'warn',
          displayText: 'Validation Failed',
          statusInDB: 'Validation Failed',
        },
        {
          type: 'warn',
          displayText: `${$scope.keyMap.NDA} (Yellow)`,
          statusInDB: 'Offered NDA',
        },
      ];
      $scope.statusList = filterStatusObj;

      $scope.marketSegmentList = [];

      function setSearchFilters(tabKey) {
        let matchData = {};

        // filter
        let dateRange = '';
        if (
          $scope.tabs[tabKey].searchFormQuery.dateRange
        && $scope.tabs[tabKey].searchFormQuery.dateRange.startDate
        && $scope.tabs[tabKey].searchFormQuery.dateRange.endDate
        ) {
          let startDate;
          let endDate;
          if (typeof $scope.tabs[tabKey].searchFormQuery.dateRange.startDate === 'object') {
            startDate = `${$scope.tabs[tabKey].searchFormQuery.dateRange.startDate.format('YYYY-MM-DD')} 00:00:00`;
          } else {
            ({ startDate } = $scope.tabs[tabKey].searchFormQuery.dateRange);
          }
          if (typeof $scope.tabs[tabKey].searchFormQuery.dateRange.startDate === 'object') {
            endDate = `${$scope.tabs[tabKey].searchFormQuery.dateRange.endDate.format('YYYY-MM-DD')} 23:59:59`;
          } else {
            ({ endDate } = $scope.tabs[tabKey].searchFormQuery.dateRange);
          }
          dateRange = {
            startDate,
            endDate,
          };
        }

        let userListString = '';
        if ($scope.tabs[tabKey].searchFormQuery && $scope.tabs[tabKey].searchFormQuery.userList) {
          const { userList } = $scope.tabs[tabKey].searchFormQuery;
          const userListLength = userList.length;
          userList.forEach((user, index) => {
            if (index === userListLength - 1) {
              userListString += user.userId;
            } else {
              userListString += `${user.userId},`;
            }
          });
        }

        matchData = {
          organizationName: $scope.tabs[tabKey].searchFormQuery.organization ? $scope.tabs[tabKey].searchFormQuery.organization.name : undefined,
          patientName: $scope.tabs[tabKey].searchFormQuery.patientName ? $scope.tabs[tabKey].searchFormQuery.patientName.trim() : undefined,
          claimNumber: $scope.tabs[tabKey].searchFormQuery.claimNumber ? $scope.tabs[tabKey].searchFormQuery.claimNumber : undefined,
          market_segment_id: $scope.tabs[tabKey].searchFormQuery.market_segment_id ? $scope.tabs[tabKey].searchFormQuery.market_segment_id : undefined,
          status: $scope.tabs[tabKey].searchFormQuery.status !== 'Select Status' ? $scope.tabs[tabKey].searchFormQuery.status : undefined,
          userList: $scope.tabs[tabKey].searchFormQuery.userList ? userListString : undefined,
          orderId: $scope.tabs[tabKey].searchFormQuery.orderId ? $scope.tabs[tabKey].searchFormQuery.orderId : undefined,
          masInvoiceNumber: $scope.tabs[tabKey].searchFormQuery.masInvoiceNumber || undefined,
          tripIdentifierType: $scope.tabs[tabKey].searchFormQuery.tripIdentifierType || undefined,
          requestingOrganization: $scope.tabs[tabKey].searchFormQuery.requestingOrganization || undefined,
        };
        if ($scope.tabs[tabKey].searchFormQuery.providerName) {
          matchData.providerName = $scope.tabs[tabKey].searchFormQuery.providerName;
        }
        if (dateRange) {
          matchData.dateRange = dateRange;
        }
        // filters ends here
        if ($scope.tabs[tabKey].searchFormQuery.patientPhone) {
          matchData.patientPhone = $scope.tabs[tabKey].searchFormQuery.patientPhone
            .replace(/-/g, '')
            .replace(' ', '')
            .replace(/_/g, '')
            .replace('(', '')
            .replace(')', '');
        }

        if (tabKey === 'pr') {
          matchData.is_pr_ride = true;
        } else if (tabKey === 'rides') {
          matchData.is_pr_ride = false;
        }

        return angular.copy(matchData);
      }

      function setUrlFilters(tabKey, filters) {
        if ($scope.tabs[tabKey].active) {
          $location.search('activeTab', tabKey);

          Object.keys(filters, (key) => {
            if (key !== 'dateRange') {
              $location.search(key, filters[key]);
            }
          });
          $location.search('currentPage', $scope.tabs[tabKey].pagination.currentPage);
        }
        return filters;
      }

      function adjustPagination(tabKey) {
        $scope.tabs[tabKey].pagination.lastItem = ($scope.tabs[tabKey].pagination.currentPage - 1) * $scope.tabs[tabKey].pagination.itemsPerPage + $scope.displayItemsperPages;
        if ($scope.tabs[tabKey].pagination.lastItem > $scope.tabs[tabKey].pagination.totalItems) {
          $scope.tabs[tabKey].pagination.lastItem = $scope.tabs[tabKey].pagination.totalItems;
        }
      }

      async function setAncestorOrgFileNumberLabels(orgIds, tabKey) {
        if (!orgIds || !orgIds.length) {
          return;
        }
        const uniqueOrgIds = Array.from(new Set(orgIds));
        try {
          const { data: { orgsWithInheritedSettings = [] } } = await orgHierarchyFactory.getOrgsWithInheritedSettings(uniqueOrgIds.join(),
            [
              'file_number_label',
            ]);
          orgsWithInheritedSettings.forEach((orgWithInheritedSettings) => {
            const ridesToUpdate = $scope.tabs[tabKey].scheduledRides.filter(ride => orgWithInheritedSettings.id === ride.org_id);

            if (ridesToUpdate.length) {
              ridesToUpdate.forEach((rideToUpdate) => {
                Object.assign(rideToUpdate, { fileNumberLabel: orgWithInheritedSettings.file_number_label });
              });
            }
          });
          $scope.$apply();
        } catch (error) {
          toaster.pop({
            type: 'error',
            title: (error.data || {}).message ? error.data.message : error.message,
            showCloseButton: true,
            timeout: 10000,
          });
        }
      }

      function getAllScheduledAppointments(tabKey, filters) {
        loadingScreenFactory.showLoadingScreen();

        $http({
          url: `${API_BASE_URL}rides/getRidesListByRideCategory/scheduled/appt_date_time ASC/${($scope.tabs[tabKey].pagination.currentPage - 1)
          * $scope.tabs[tabKey].pagination.itemsPerPage}/${$scope.tabs[tabKey].pagination.itemsPerPage}`,
          method: 'GET',
          params: Object.assign({
            org_id: userDetails.organisation.id,
          }, filters),
        })
          .then(
            async ({ data: { data, totalCount, success } }) => {
              loadingScreenFactory.hideLoadingScreen();
              if (laddaButton && laddaButton.isLoading()) {
                laddaButton.stop();
              }
              if (!success) {
                return;
              }

              const orgsIdsForFetchingAncestors = [];

              const rides = data.map((ride) => {
                if (!ride.ride_status) {
                  return ride;
                }

                let rideStatus = ride.ride_status;

                if (
                  ride.appointment
                  && ride.appointment.recurrence_id
                  && ride.appointment.recurrence_id > 0
                ) {
                  if (ride.ride_status === 'Confirmed') {
                    rideStatus = 'Series Confirmed';
                  } else if (ride.ride_status === 'Potential Unavailability') {
                    rideStatus = '(s) Potential Unavailability';
                  }
                }

                let statusType = ride.ride_sorting === 2 ? 'danger' : 'warn';

                if (ride.ride_category !== 'alert' && statusObj[ride.ride_status]) {
                  statusType = statusObj[ride.ride_status].type;
                }


                if (_.get(ride, 'appointment.organisation.customizable_file_number', false)) {
                  Object.assign(ride, { fileNumberLabel: ride.appointment.organisation.file_number_label });
                } else if (ride.org_id) {
                  orgsIdsForFetchingAncestors.push(
                    ride.org_id,
                  );
                }

                return Object.assign(ride, {
                  statusType,
                  ride_status: rideStatus,
                });
              });

              $scope.tabs[tabKey].scheduledRides = tabKey === 'rides' ? $scope.scheduledRides.concat(rides) : rides;
              if (tabKey === 'rides' && $scope.showChildData()) {
                $scope.tabs[tabKey].scheduledRides = rides;
              }
              if (orgsIdsForFetchingAncestors.length) {
                setAncestorOrgFileNumberLabels(orgsIdsForFetchingAncestors, tabKey);
              }
              $scope.tabs[tabKey].firstTimeLoad = false;
              $scope.tabs[tabKey].pagination.totalItems = totalCount;
              adjustPagination(tabKey);
            },
            () => {
              loadingScreenFactory.hideLoadingScreen();
              if (laddaButton && laddaButton.isLoading()) {
                laddaButton.stop();
              }
              $scope.tabs[tabKey].scheduledRides = {};
              if (tabKey === 'rides') {
                // add ride tile
                $scope.tabs[tabKey].scheduledRides.length = 1;
              }

              $scope.tabs[tabKey].pagination.totalItems = 0;
              adjustPagination(tabKey);
            },
          );
      }

      function filterScheduledAppts(tabKeyIn, isSearch = false) {
        if ($scope.tabs[tabKeyIn].active) {
          $location.url($location.path());
        }
        const matchData = setSearchFilters(tabKeyIn);
        if (isSearch) {
          const matchDataCopy = angular.copy(matchData);
          matchDataCopy.userList = $scope.tabs[tabKeyIn].searchFormQuery.userList;
          storage.setDataByKey('keepFilterData', currentState, { tab: tabKeyIn, data: matchDataCopy });
        }
        if (matchData.requestingOrganization) {
          Object.assign(matchData, {
            requestingOrganizationId: matchData.requestingOrganization.id,
            requestingOrganization: undefined,
          });
        }
        setUrlFilters(tabKeyIn, matchData);
        getAllScheduledAppointments(tabKeyIn, matchData);
      }

      $scope.pageChanged = (key) => {
        if ($scope.tabs[key].firstTimeLoad === false) {
          filterScheduledAppts(key);
        }
      };

      $scope.tripIdentifierChanged = function pageChanged(tabKey) {
        $scope.tabs[tabKey].scheduled.orderId = null;
        $scope.tabs[tabKey].scheduled.masInvoiceNumber = null;
        $scope.tabs[tabKey].scheduled.claimNumber = null;
      };

      $scope.refreshButtonClicked = (tabKey) => {
      // Create new instance for Ladda (loading buttons)
        laddaButton = Ladda.create(document.querySelector('.confirm-appointment-button'));
        // Disable confirm/book appointpent button and show loading icon
        laddaButton.start();
        $scope.searchButtonClicked(tabKey);
      };


      const getAllOrganizations = async () => {
        loadingScreenFactory.showLoadingScreen();
        const params = {
          fields: ['orgId', 'orgName'],
          displayOrgChildren: true,
        };
        const ROLES = relaylib.common.constants.userRoles;
        if (
          userDetails.organisation.is_third_party
          && [ROLES.ORG_SUPERADMIN, ROLES.ORG_ADMIN].includes(userDetails.role)
        ) {
          params.getDelegatingOrgs = true;
        }
        if (userDetails.belongsToParentOrg) {
          params.orgId = userDetails.organisation.id;
        }
        try {
          const { data: { organisations: organizations } } = await $http.get(`${API_BASE_URL}organisations`, {
            params,
          });
          $scope.organizations = {
            data: organizations,
            visibleChoices: requestingOrganizationsVisibleCount,
          };
        } catch (error) {
          toaster.pop({
            type: 'error',
            title: error.message || 'Unable to fetch Organizations',
            showCloseButton: true,
            timeout: 10000,
          });
        } finally {
          // eslint-disable-next-line no-use-before-define
          onOrgFetchComplete($scope.organizations);
        }
      };
      getAllOrganizations();
      const onOrgFetchComplete = (list) => {
        loadingScreenFactory.hideLoadingScreen();
        $scope.orgList = list;
        getMarketSegments((err, data) => {
          if (!err) {
            $scope.marketSegmentList = data;
            init();
          } else {
            toaster.pop({
              type: 'error',
              title: err.msg || err,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        });

        if (list.length === 1) {
          [$scope.tabs.rides.scheduled.organization] = list;
          $scope.orgChanged(list[0].id);
          $scope.disableOrgList = true;
        }
      };

      $scope.loadMoreChoices = () => {
        $scope.requestingOrganizations.visibleChoices += requestingOrganizationsVisibleCount;
      };

      const getRequestingOrganizations = async (organizationId) => {
        try {
          const { data: { organisations: requestingOrganizations } } = await requestingOrganizationFactory.getRequestingOrganizations({
            organizationId,
            fields: ['orgId', 'orgName'],
          });
          $scope.requestingOrganizations = {
            data: requestingOrganizations,
            visibleChoices: requestingOrganizationsVisibleCount,
          };
        } catch (error) {
          toaster.pop({
            type: 'error',
            title: error.message || 'Unable to fetch Requesting Organizations',
            showCloseButton: true,
            timeout: 10000,
          });
        } finally {
          $scope.$apply();
        }
      };
      getRequestingOrganizations();

      $scope.clearFilters = (tabKey) => {
        storage.removeDataByKey('keepFilterData', currentState);
        $scope.tabs[tabKey].searchFormQuery = {
          dateRange: {
            startDate: null,
            endDate: null,
          },
          requestingOrganization: undefined,
        };
        $scope.tabs[tabKey].scheduled = {
          dateRange: {
            startDate: null,
            endDate: null,
          },
          organization: $scope.tabs[tabKey].scheduled.organization,
          userList: [],
          patientName: '',
          providerName: undefined,
          requestingOrganization: undefined,
        };
        if (!$scope.disableOrgList) {
          delete $scope.tabs[tabKey].scheduled.organization;
        }
        $scope.tabs[tabKey].pagination.currentPage = 1;
        $location.url($location.path());
        filterScheduledAppts(tabKey);
        getRequestingOrganizations();
      };

      $scope.orgChanged = (orgId, notEmptyUserList) => {
        if (!orgId) {
          toasterError('Org Id is required to fetch users list');
        } else {
          if (!notEmptyUserList) {
            $scope.tabs.rides.scheduled.userList = [];
          }
          $scope.tabs.rides.reloadRequesters = true;
        }
        Object.keys($scope.tabs).forEach((tab) => {
          $scope.tabs[tab].scheduled.requestingOrganization = undefined;
        });
        getRequestingOrganizations(orgId);
      };

      $scope.searchButtonClicked = (tabKey) => {
        $scope.tabs[tabKey].pagination.currentPage = 1;
        // filter
        let startDate;
        let endDate;
        if (
          $scope.tabs[tabKey].scheduled.providerName
          && $scope.tabs[tabKey].scheduled.providerName.trim()
        ) {
          $scope.tabs[
            tabKey
          ].searchFormQuery.providerName = $scope.tabs[
            tabKey
          ].scheduled.providerName.trim();
        } else {
          $scope.tabs[tabKey].searchFormQuery.providerName = null;
        }
        if ($scope.tabs[tabKey].scheduled.dateRange && $scope.tabs[tabKey].scheduled.dateRange.startDate && $scope.tabs[tabKey].scheduled.dateRange.endDate) {
          if (typeof $scope.tabs[tabKey].scheduled.dateRange.startDate === 'object') {
            startDate = `${$scope.tabs[tabKey].scheduled.dateRange.startDate.format('YYYY-MM-DD')} 00:00:00`;
          } else {
            ({ startDate } = $scope.tabs[tabKey].scheduled.dateRange);
          }
          if (typeof $scope.tabs[tabKey].scheduled.dateRange.endDate === 'object') {
            endDate = `${$scope.tabs[tabKey].scheduled.dateRange.endDate.format('YYYY-MM-DD')} 23:59:59`;
          } else {
            ({ endDate } = $scope.tabs[tabKey].scheduled.dateRange);
          }
        }
        $scope.tabs[tabKey].searchFormQuery.dateRange.endDate = endDate;
        $scope.tabs[tabKey].searchFormQuery.dateRange.startDate = startDate;

        if ($scope.tabs[tabKey].scheduled.organization) {
          $scope.tabs[tabKey].searchFormQuery.organization = {};
          $scope.tabs[tabKey].searchFormQuery.organization.name = $scope.tabs[tabKey].scheduled.organization.name;
        } else {
          $scope.tabs[tabKey].searchFormQuery.organization = {};
        }
        if ($scope.tabs[tabKey].scheduled.patientName) {
          if ($scope.tabs[tabKey].scheduled.patientName.trim()) {
            $scope.tabs[tabKey].searchFormQuery.patientName = $scope.tabs[tabKey].scheduled.patientName.trim();
          }
        } else {
          $scope.tabs[tabKey].searchFormQuery.patientName = null;
        }

        $scope.tabs[tabKey].searchFormQuery.claimNumber = $scope.tabs[tabKey].scheduled.claimNumber ? $scope.tabs[tabKey].scheduled.claimNumber : false;
        $scope.tabs[tabKey].searchFormQuery.market_segment_id = $scope.tabs[tabKey].scheduled.market_segment_id
          ? $scope.tabs[tabKey].scheduled.market_segment_id
          : false;
        $scope.tabs[tabKey].searchFormQuery.orderId = ($scope.tabs[tabKey].scheduled.orderId) ? $scope.tabs[tabKey].scheduled.orderId : false;
        $scope.tabs[tabKey].searchFormQuery.masInvoiceNumber = $scope.tabs[tabKey].scheduled.masInvoiceNumber || false;
        $scope.tabs[tabKey].searchFormQuery.tripIdentifierType = $scope.tabs[tabKey].scheduled.tripIdentifierType || false;
        if ($scope.tabs[tabKey].scheduled.status) {
          $scope.tabs[tabKey].searchFormQuery.status = $scope.tabs[tabKey].scheduled.status;
        } else {
          $scope.tabs[tabKey].searchFormQuery.status = null;
        }
        $scope.tabs[tabKey].searchFormQuery.requestingOrganization = $scope.tabs[tabKey].scheduled.requestingOrganization || undefined;

        // filters ends here
        if ($scope.tabs[tabKey].scheduled.patientPhone) {
          $scope.tabs[tabKey].searchFormQuery.patientPhone = $scope.tabs[tabKey].scheduled.patientPhone
            .replace(/-/g, '')
            .replace(' ', '')
            .replace(/_/g, '')
            .replace('(', '')
            .replace(')', '');
        } else {
          $scope.tabs[tabKey].searchFormQuery.patientPhone = null;
        }

        if ($scope.tabs[tabKey].scheduled.userList) {
          $scope.tabs[tabKey].searchFormQuery.userList = $scope.tabs[tabKey].scheduled.userList;
        } else {
          $scope.tabs[tabKey].searchFormQuery.userList = null;
        }
        filterScheduledAppts(tabKey, true);
      };

      let userSettings;
      function getUserSettings() {
        userDetails = localStorage.getItem('userDetails');
        userDetails = JSON.parse(userDetails);
        // fetch scheduled ride list for riderAdmin, we are not calling this
        // function from UI
        if (userDetails.role && userDetails.role === 'riderAdmin') {
          $scope.searchButtonClicked('rides');
        }
        const headers = {
          username: userDetails.username,
          usertoken: userDetails.user_token,
          'Content-Type': 'application/json',
        };
        const request = $http.get(`${API_BASE_URL}user/settings/`, { headers });
        request.then(({ data: objSuccess }) => {
          userSettings = objSuccess.data;

          if (userSettings.organisation && userSettings.organisation.pr_status) {
            if (userSettings.settings.admin_pr) {
              $scope.tabs.pr.show = true;
            } else {
              $scope.tabs.pr.show = false;
            }
          } else {
            $scope.tabs.pr.show = false;
          }
        });
      }
      getUserSettings();

      $scope.tabClicked = (tabKey) => {
        $location.url($location.path());
        const matchData = setSearchFilters(tabKey);
        setUrlFilters(tabKey, matchData);
        $location.search('activeTab', tabKey);
      };

      $scope.getStyle = (statusType) => {
        const SeverityType = statusObj[statusType].type;
        return {
          color: 'black',
          'font-weight': 'bold',
          'background-color': statusColor[SeverityType],
        };
      };

      $scope.recurrenceOptions = null;
      $scope.recurrenceTileOptions = null;

      $scope.recurrenceTileOptionsOkClicked = () => {
        if ($scope.recurrenceOptions.action === 'single' || $scope.recurrenceOptions.action === 'series') {
          $scope.recurrenceTileOptions.close();
          $scope.redirectForEdit($scope.recurrenceOptions.ride, $scope.recurrenceOptions.action);
        } else if ($scope.recurrenceOptions.action === 'cancel') {
          $scope.recurrenceTileOptions.close();
          const params = {
            cancel_type: 'recurrence_series',
            ride_id: $scope.recurrenceOptions.ride.id,
            recurrence_id: $scope.recurrenceOptions.ride.appointment.recurrence_id,
          };

          SweetAlert.swal(
            {
              title: 'Cancel this series?',
              text: 'All trips in this series will be canceled. Are you sure you want to continue?',
              type: 'warning',
              showCancelButton: true,
              confirmButtonClass: 'btn-danger',
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
              closeOnConfirm: true,
              closeOnCancel: true,
            },
            (isConfirm) => {
              if (isConfirm) {
                if ($scope.recurrenceTileOptions) {
                  $scope.recurrenceTileOptions.close();
                }
                const request = $http.put(`${API_BASE_URL}ride/cancel`, params);
                loadingScreenFactory.showLoadingScreen();
                request.then(({ data: response }) => {
                  loadingScreenFactory.hideLoadingScreen();
                  if (response.success) {
                    SweetAlert.swal('Cancelled!', 'Your trip has been cancelled.', 'success');
                    filterScheduledAppts('rides');
                    setTimeout(() => {
                      filterScheduledAppts('rides');
                    }, 5000);
                  } else {
                    toaster.error(response.message ? response.message : 'Failed to cancel trip.');
                  }
                });

                request.catch(({ data: response }) => {
                  loadingScreenFactory.hideLoadingScreen();
                  toaster.error(response.message ? response.message : 'Failed to cancel trip.');
                });
              }
            },
          );
        } else {
          $scope.recurrenceTileOptions.close();
        }
      };

      $scope.recurrenceTileOptionsCloseClicked = () => {
        $scope.recurrenceTileOptions.close();
      };

      $scope.scheduledRideTileClicked = (ride) => {
        if (ride.appointment && ride.appointment.recurrence_id > 0) {
          $scope.recurrenceOptions = {
            action: 'single',
            ride,
          };

          $scope.recurrenceTileOptions = ngDialog.open({
            template: 'ngDialogTemplate1',
            className: 'ngdialog-theme-default',
            scope: $scope,
            name: 'ngDialogTemplate1',
            closeByNavigation: true,
          });
        } else {
          $scope.redirectForEdit(ride);
        }
      };

      $scope.redirectForEdit = (ride, action) => {
        const params = {
          rideId: ride.id,
        };

        if (ride.ride_status === 'Pending Approval') {
          params.isFromDashboard = true;
        }

        if (action) {
          params.recurrenceAction = action;
        }

        const organisationType = ride.appointment.organisation.org_type;
        if (organisationType === 'mas') {
          $state.go('main.dashboard.masOrderDetails', { rideId: ride.id, caller: 'scheduled' });
        } else if (ride.appointment.external_order_id > 0) {
          $state.go('main.dashboard.rideOrderDetails', { rideId: ride.id, caller: 'scheduled' });
        } else {
          $state.go('main.dashboard.createAppointment', params);
        }
      };

      $scope.enterOnFormSearch = (event, tabKey) => {
        if (event.keyCode === 13) {
          $scope.searchButtonClicked(tabKey);
        }
      };

      $scope.refreshScheduleAppointments = (key) => {
        filterScheduledAppts(key);
      };
      /** IIFE to fetch provider names */
      (async () => {
        try {
          const { data: { providerNames } } = await $http.get(`${API_BASE_URL}vendors/provider-names`);
          $scope.provider.providerNameList = providerNames;
        } catch (err) {
          toasterError('Failed to get provider name list');
        }
      })();
      $scope.startsWith = (state, viewValue) => {
        if (viewValue.length < 3) {
          return undefined;
        }
        return state.substr(0, viewValue.length).toLowerCase() === viewValue.toLowerCase();
      };
    },
  ]);
