angular.module('relayHealth').controller('notificationMessageCtrl',
  ['$scope',
    '$state',
    'toaster',
    'loadingScreenFactory',
    'messageCenterService',
    function notificationMessageCtrl($scope, $state, toaster, loadingScreenFactory, messageCenterService) {
      this.messages = [];
      this.pagination = {
        totalItems: 0,
        currentPage: 1,
        itemsPerPage: 10,
      };

      this.getUnreadCount = messageCenterService.getUnreadCount;

      this.pageChanged = async () => {
        try {
          loadingScreenFactory.showLoadingScreen();
          const {
            messages,
            total,
          } = await messageCenterService.getMessages(this.pagination.currentPage - 1, this.pagination.itemsPerPage);
          this.messages = messages;
          this.pagination.totalItems = total;
        } catch (err) {
          toaster.error({
            type: 'error',
            title: err,
            showCloseButton: true,
            timeout: 10000,
          });
        } finally {
          loadingScreenFactory.hideLoadingScreen();
          $scope.$apply();
        }
      };

      this.pageChanged();

      this.markMessageRead = async ({
        id,
        readAt,
        meta: {
          state,
          params,
        },
      }) => {
        if (!readAt) {
          try {
            const noOfRow = await messageCenterService.markMessageRead(id);
            if (noOfRow === 1) {
              this.messages.forEach((message) => {
                if (message.id === id) {
                  Object.assign(message, { readAt: new Date() });
                }
              });
            }
          } catch (err) {
            toaster.error({
              type: 'error',
              title: err,
              showCloseButton: true,
              timeout: 10000,
            });
          } finally {
            $scope.$apply();
          }
        }
        $state.go(state, params);
      };
    },
  ]);
