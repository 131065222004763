const findKey = require('lodash/findKey');
const isEmpty = require('lodash/isEmpty');
const statuses = {
    0: "Unknown",
    1: "Sticky",
    2: "AutoAssign",
    3: "Confirmation Email Sent",
    4: "Confirmation Email: Accept",
    5: "Confirmation Email: Reject",
    6: "Confirmation Email: Expired",
    7: "Vendor Portal: Accept",
    8: "Vendor Portal: 24 Hr. Confirm",
    9: "Vendor Portal: Final Confirm",
    10: "Vendor Portal: Reject"
};

const findValueByKey = key => {
    return statuses[key];
};

const findKeyByValue = value => {
    return findKey(statuses, status => {
        return status === value;
    });
};

const filterKeysByValues = values => {
    if(isEmpty(values) || !Array.isArray(values)){
        return [];
    }
    return values.map(value => {
        return findKeyByValue(value);
    });
};

const allStatuses = () => {
    return statuses;
}

module.exports = {
    findValueByKey,
    findKeyByValue,
    filterKeysByValues,
    allStatuses
};