angular.module('relayHealth').controller('BroadcastsCtrl', [
  '$scope',
  '$state',
  '$uibModal',
  '$http',
  'API_BASE_URL',
  'loadingScreenFactory',
  'toaster',
  'SweetAlert',
  function BroadcastsCtrl($scope, $state, $uibModal, $http, API_BASE_URL, loadingScreenFactory, toaster, SweetAlert) {
    const watcherRemovers = [];
    $scope.permissionsConstants = relaylib.permissions.permissionsConstants;
    function convertToDM(inData) {
      return {
        id: inData.id,
        title: inData.title,
        message: inData.content,
        category: inData.category,
        targets: inData.targets,
        isTargetWeb: inData.targets.indexOf($scope.broadcastTargets.WEB) >= 0,
        isTargetAndroid: inData.targets.indexOf($scope.broadcastTargets.ANDROID) >= 0,
        isTargetIos: inData.targets.indexOf($scope.broadcastTargets.IOS) >= 0,
        isTargetDriverAppIos: inData.targets.indexOf($scope.broadcastTargets.DRIVER_IOS) >= 0,
        isTargetDriverAppAndroid: inData.targets.indexOf($scope.broadcastTargets.DRIVER_ANDROID) >= 0,
        isActive: inData.status === relaylib.broadcasts.broadcastStatuses.ACTIVE,
        duration: inData.duration,
        isAuthenticated: inData.isAuthenticated,
      };
    }

    function init() {
      $scope.breadcrumbs = [
        {
          label: 'Home',
          onClick() {
            $state.go('main.dashboard.superAdminDashboard', null, { reload: true });
          },
        },
      ];

      $scope.broadcastTargets = relaylib.broadcasts.broadcastTargets;
      $scope.broadcastCats = relaylib.broadcasts.broadcastCategories;
      $scope.broadcastStatuses = relaylib.broadcasts.broadcastStatuses;
      $scope.dataRefresh = { value: false };
      $scope.dataRefetch = false;
      $scope.isLoading = false;
      $scope.searchTxt = '';
    }

    $scope.fetchData = (paginationParams) => {
      $scope.isLoading = true;
      const params = Object.assign({
        category: `${
          relaylib.broadcasts.broadcastCategories.INFO
        },${
          relaylib.broadcasts.broadcastCategories.WARN
        },${
          relaylib.broadcasts.broadcastCategories.ERROR
        }`,
      }, paginationParams);
      if ($scope.searchTxt) {
        params.search = $scope.searchTxt;
      }
      return $http
        .get(`${API_BASE_URL}broadcasts`, {
          params,
        })
        .then((resp) => {
          if (resp.data) {
            $scope.broadcastsData = _.map(resp.data.data, d => convertToDM(d));
          } else {
            $scope.broadcastsData = [];
          }
          return resp.data;
        })
        .finally(() => {
          $scope.isLoading = false;
        });
    };

    $scope.refreshData = () => {
      $scope.dataRefresh.value = true;
    };

    $scope.searchData = (e) => {
      if (!e || e.keyCode === 13) {
        // search data iff, either button pressed or enter pressed
        $scope.dataRefetch = true;
      }
    };

    $scope.updateStatus = (broadcast, newStatus) => {
      $scope.isLoading = true;
      let data;
      if (newStatus) {
        data = {
          status: $scope.broadcastStatuses.ACTIVE,
        };
      } else {
        data = {
          status: $scope.broadcastStatuses.INACTIVE,
        };
      }
      return $http
        .patch(`${API_BASE_URL}broadcasts/${broadcast.id}/changestatus`, data)
        .then((resp) => {
          if (resp.data) {
            $scope.searchData();
          } else {
            // Do nothing on error
          }
          return resp.data;
        })
        .finally(() => {
          $scope.isLoading = false;
        });
    };

    $scope.viewBroadcast = (broadcast) => {
      toaster.pop({
        type: broadcast.category,
        title: broadcast.title,
        body: broadcast.message,
        timeout: 5000,
        showCloseButton: true,
        toasterId: 9999,
      });
    };

    $scope.removeItem = (broadcastId) => {
      if (broadcastId) {
        SweetAlert.swal(
          {
            title: 'Are you sure you want to delete this item ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonClass: 'btn-warning',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            closeOnConfirm: true,
            closeOnCancel: true,
          },
          (isConfirm) => {
            if (isConfirm) {
              $scope.isLoading = true;
              $http
                .delete(`${API_BASE_URL}broadcasts/${broadcastId}`)
                .then((resp) => {
                  if (resp.data) {
                    $scope.refreshData();
                  } else {
                    // Do nothing on error
                  }
                  return resp.data;
                })
                .finally(() => {
                  $scope.isLoading = false;
                });
            }
          },
        );
      }
      return false;
    };

    $scope.openModal = (editData, tmplData) => {
      const modalInstance = $uibModal.open({
        template: require('./createBroadcast.html'),
        controller: 'CreateBroadcastCtrl',
        size: 'lg',
        backdrop: 'static',
        windowTopClass: 'inmodal',
        resolve: {
          editInfo: editData,
          tmplInfo: tmplData,
        },
      });

      modalInstance.result.then(() => {
        $scope.refreshData();
      });
    };

    $scope.openEditTmplModal = (tmplData) => {
      const modalInstance = $uibModal.open({
        template: require('./editBroadcastTmpl.html'),
        controller: 'EditBroadcastTmplCtrl',
        size: 'lg',
        backdrop: 'static',
        windowTopClass: 'inmodal',
        resolve: {
          tmplInfo: tmplData,
        },
      });

      modalInstance.result.then(() => {
        toaster.pop({
          type: 'success',
          title: 'Saved successfully',
          timeout: 2000,
          showCloseButton: true,
        });
      });
    };

    $scope.openTemplatesModal = () => {
      const modalInstance = $uibModal.open({
        template: require('./selectBroadcastTmpl.html'),
        controller: 'SelectBroadcastTmplCtrl',
        size: 'lg',
        backdrop: 'static',
        windowTopClass: 'inmodal',
      });

      modalInstance.result.then((resp) => {
        if (resp.action === 'select') {
          $scope.openModal(null, resp.tmpl);
        } else {
          $scope.openEditTmplModal(resp.tmpl);
        }
      });
    };

    watcherRemovers.push(
      $scope.$watch('isLoading', (n) => {
        if (n) {
          loadingScreenFactory.showLoadingScreen();
        } else {
          loadingScreenFactory.hideLoadingScreen();
        }
      }),
    );

    $scope.$on('$destroy', () => {
      angular.forEach(watcherRemovers, (w) => {
        w();
      });
    });

    init();
  },
]);
