/**
 * INSPINIA - Responsive Admin Theme
 * 2.5
 *
 * Custom scripts
 */

$(document).ready(() => {
  // Append config box / Only for demo purpose
  // $.get("views/skin-config.html", function (data) {
  //    $('body').append(data);
  // });

  // Full height of sidebar
  function fix_height() {
    const heightWithoutNavbar = $('body > #wrapper').height() - 61;
    $('.sidebard-panel').css('min-height', `${heightWithoutNavbar}px`);

    const navbarHeigh = $('nav.navbar-default').height();
    const wrapperHeigh = $('#page-wrapper').height();

    if (navbarHeigh > wrapperHeigh) {
      $('#page-wrapper').css('min-height', `${navbarHeigh}px`);
    }

    if (navbarHeigh < wrapperHeigh) {
      $('#page-wrapper').css('min-height', `${$(window).height()}px`);
    }

    if ($('body').hasClass('fixed-nav')) {
      if (navbarHeigh > wrapperHeigh) {
        $('#page-wrapper').css('min-height', `${navbarHeigh - 60}px`);
      } else {
        $('#page-wrapper').css('min-height', `${$(window).height() - 60}px`);
      }
    }
  }


  $(window).bind('load resize scroll', () => {
    if (!$('body').hasClass('body-small')) {
      fix_height();
    }
  });

  // Move right sidebar top after scroll
  $(window).scroll(() => {
    if ($(window).scrollTop() > 0 && !$('body').hasClass('fixed-nav')) {
      $('#right-sidebar').addClass('sidebar-top');
    } else {
      $('#right-sidebar').removeClass('sidebar-top');
    }
  });


  setTimeout(() => {
    fix_height();
  });
});

// Minimalize menu when screen is less than 768px
(function () {
  $(window).bind('load resize', () => {
    if ($(document).width() < 769) {
      $('body').addClass('body-small');
    } else {
      $('body').removeClass('body-small');
    }
  });
}());
