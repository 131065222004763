angular.module('relayHealth').controller('orgListCntrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  '$state',
  'toaster',
  '$location',
  'loadingScreenFactory',
  '$stateParams',
  'WORKERS_COMPENSATION_KEY',
  'HEALTHCARE_MASS_MARKET_KEY',
  'GROUP_HEALTH_KEY',
  'storageManager',
  'permissionFilter',
  function orgListCntrl(
    $scope,
    $http,
    API_BASE_URL,
    $state,
    toaster,
    $location,
    loadingScreenFactory,
    $stateParams,
    WORKERS_COMPENSATION_KEY,
    HEALTHCARE_MASS_MARKET_KEY,
    GROUP_HEALTH_KEY,
    storageManager,
    permissionFilter,
  ) {
    const {
      storage,
    } = storageManager;
    const {
      common: {
        constants: {
          featureFlags: {
            MAIN_CONTACT_REQUIRED,
          } = {},
        } = {},
      } = {},
      permissions: {
        permissionsConstants,
      } = {},
    } = relaylib;
    const currentState = $state.current.name;
    $scope.marketSegmentKeys = {
      WORKERS_COMPENSATION_KEY,
      HEALTHCARE_MASS_MARKET_KEY,
      GROUP_HEALTH_KEY,
    };
    $scope.permissionsConstants = permissionsConstants;
    $scope.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    $scope.marketSegmentId = $stateParams.marketSegmentId;
    $scope.marketSegmentDisabled = !!$scope.marketSegmentId;
    if ($location.search().table_page === true
      || $location.search().table_page === undefined
      || Number.isNaN($location.search().table_page)
    ) {
      $location.search('table_page', 1);
    }
    $scope.showAddOrgForm = {
      show: false,
    };
    $scope.searchFormQuery = {};

    $scope.orgListQuery = {};
    $scope.addOrg = {
      name: null,
      mainContact: {
        firstName: null,
        lastName: null,
        mainContactEmail: null,
      },
      orgContactPhone: null,
    };
    $scope.mask = '(999) 999-9999';
    $scope.addOrg.orgAccessType = [];
    $scope.showAccessTypes = true;
    $scope.accessTypeRequired = false;
    $scope.accessTypeDisabled = permissionFilter($scope.permissionsConstants.SHOW_ACCESS_TYPES);
    $scope.statusList = [{
      status: 'Active',
      value: 0,
    }, {
      status: 'Inactive',
      value: 1,
    }];
    $scope.orgTypeList = [{
      name: 'business',
      value: 0,
    }, {
      name: 'rider',
      value: 1,
    }];
    $scope.orgListQuery.orgType = {
      name: 'business',
      value: 0,
    };
    $scope.fetchedListOrgType = 0;
    $scope.mainContactRequired = true;
    $scope.orgContactNumberRequired = false;
    if ($scope.marketSegmentId) $scope.orgListQuery.market_segment_id = parseInt($scope.marketSegmentId, 10);
    $scope.pagination = {
      totalItems: 0,
      currentPage: 1,
      lastItem: 0,
      itemsPerPage: 20,
    };

    $scope.orgAccessType = Object.values(relaylib.organisation.constants.orgAccessTypes).map(e => ({
      type: e,
    }));
    $scope.marketSegmentList = [];

    $scope.validationReqTinNumber = true;

    $scope.selectedMarketSegment = {};

    $scope.marketSegmentChanged = (currentSelectedMarketSegment, newMarketSegment) => {
      Object.assign(currentSelectedMarketSegment, {
        market_segment_id: newMarketSegment.id,
        market_segment_key: newMarketSegment.key,
      });
      const {
        settings = [],
      } = newMarketSegment;
      $scope.mainContactRequired = !!settings.find(setting => setting.setting_name === MAIN_CONTACT_REQUIRED && setting.setting_value === 'true');
      if ($scope.addOrg.market_segment_key === WORKERS_COMPENSATION_KEY || $scope.addOrg.market_segment_key === HEALTHCARE_MASS_MARKET_KEY) {
        $scope.validationReqTinNumber = false;
      } else {
        $scope.validationReqTinNumber = true;
      }
      if ($scope.addOrg.market_segment_key === GROUP_HEALTH_KEY) {
        $scope.orgContactNumberRequired = true;
      } else {
        $scope.orgContactNumberRequired = false;
      }
    };

    $scope.validatePhoneNumber = () => {
      const phoneNo = angular.element('#orgContactPhone').intlTelInput('getNumber', window.intlTelInputUtils.numberFormat.E164);
      if (!phoneNo.length) {
        $scope.addOrgForm.orgContactPhone.$setValidity('invalidPhone', true);
        return true;
      }
      const isValidPhoneNumber = window.intlTelInputUtils.isValidNumber(phoneNo);
      if (!$scope.addOrgForm) {
        return false;
      }
      if (isValidPhoneNumber) {
        $scope.addOrgForm.orgContactPhone.$setValidity('invalidPhone', true);
      } else {
        $scope.addOrgForm.orgContactPhone.$setValidity('invalidPhone', false);
      }
      return isValidPhoneNumber;
    };

    function getMarketSegments() {
      const getMarketSegmentsCall = $http({
        url: `${API_BASE_URL}marketSegments`,
        method: 'GET',
      });
      getMarketSegmentsCall.then(
        ({
          data: {
            success,
            message,
            data,
          },
        }) => {
          if (!success) {
            toaster.pop({
              type: 'error',
              title: message,
              showCloseButton: true,
              timeout: 10000,
            });
            return;
          }
          $scope.marketSegmentList = $scope.marketSegmentId
            ? data.filter(item => item.id === parseInt($scope.marketSegmentId, 10))
            : data;
        },
        (error) => {
          toaster.pop({
            type: 'error',
            title: error.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    }

    getMarketSegments();

    $scope.importOrgs = () => {
      $state.go('main.userMgmt.importOrg');
    };

    $scope.pageChanged = (newPage) => {
      $location.search('table_page', newPage);
    };

    $scope.onOrgFetchComplete = (list) => {
      $scope.orgListSelected = list;
      const prevSearchData = storage.getDataByKey('keepFilterData', currentState);
      if (!prevSearchData) {
        return;
      }
      $scope.orgListQuery.organization = $scope.orgListSelected
        .find(({
          name,
        }) => name === prevSearchData.organizationName);
    };

    function writeSearchFilter() {
      const prevSearchValues = storage.getDataByKey('keepFilterData', currentState);
      if (!prevSearchValues) {
        return;
      }
      const prevForm = prevSearchValues;
      $scope.orgListQuery.mainContact = prevForm.mainContact;
      $scope.orgListQuery.email = prevForm.email;
      $scope.orgListQuery.status = $scope.statusList.find(({
        value,
      }) => value === prevForm.status);
      $scope.orgListQuery.orgType = $scope.orgTypeList.find(({
        value,
      }) => value === prevForm.orgType);
      $scope.orgListQuery.market_segment_id = prevForm.market_segment_id;
    }


    function getOrgType() {
      let orgType;
      // first check, is orgType oject is initilized or not
      if ($scope.orgListQuery.orgType && ($scope.orgListQuery.orgType.value === 1 || $scope.orgListQuery.orgType.value === '1')) {
        orgType = 'rider';
      } else if ($scope.orgListQuery.orgType && ($scope.orgListQuery.orgType.value === 0 || $scope.orgListQuery.orgType.value === '0')) {
        orgType = 'business';
      } else {
        orgType = 'all';
      }
      return orgType;
    }

    let matchData;

    function getAllOrgs() {
      // org class param captured
      // get orgtype based on selected filter
      let orgType = getOrgType();
      if ($scope.orgListQuery.orgType && $scope.marketSegmentId && !Number.isNaN($scope.marketSegmentId)) {
        matchData.market_segment_id = $scope.marketSegmentId;
      }

      if (matchData.orgType === 1) {
        orgType = 'rider';
      } else if (matchData.orgType === 0) {
        orgType = 'business';
      } else {
        // this should be skipped.
      }

      // in case user come back from org detail page, we are setting filter data from local storage
      $scope.orgListQuery.orgType = {
        name: orgType,
        value: matchData.orgType,
      };

      function adjustPagination() {
        $scope.pagination.lastItem = ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage + 20;
        if ($scope.pagination.lastItem > $scope.pagination.totalItems) {
          $scope.pagination.lastItem = $scope.pagination.totalItems;
        }
      }

      const getAllOrgsCall = $http({
        url: `${API_BASE_URL}organisation/getOrganizations/${orgType}/${($location.search().table_page - 1) * $scope.pagination.itemsPerPage}/${
          $scope.pagination.itemsPerPage
        }`,
        method: 'GET',
        params: matchData,
      });
      loadingScreenFactory.showLoadingScreen();
      getAllOrgsCall.then(
        (result) => {
          loadingScreenFactory.hideLoadingScreen();
          if (result.data.success) {
            $scope.orgList = result.data.org_details;
            $scope.pagination.totalItems = result.data.totalCount;
            $scope.pagination.currentPage = $location.search().table_page;
            $scope.fetchedListOrgType = $scope.orgListQuery.orgType.value;
            adjustPagination();
            writeSearchFilter();
          } else {
            $scope.pagination.totalItems = 0;
            adjustPagination();
            toaster.pop({
              type: 'error',
              title: result.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        },
        (error) => {
          loadingScreenFactory.hideLoadingScreen();
          $scope.orgList.length = 1;
          $scope.pagination.totalItems = 0;
          adjustPagination();
          toaster.pop({
            type: 'error',
            title: error.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    }

    function filterOrganizations(isSearch = false) {
      matchData = {
        organizationName: $scope.searchFormQuery.organization
          ? $scope.searchFormQuery.organization.rider_org_name || $scope.searchFormQuery.organization.name
          : undefined,
        mainContact: $scope.searchFormQuery.mainContact ? $scope.searchFormQuery.mainContact.trim() : undefined,
        status: $scope.searchFormQuery.status ? $scope.searchFormQuery.status.value : undefined,
        email: $scope.searchFormQuery.email ? $scope.searchFormQuery.email.trim() : undefined,
        orgType: $scope.searchFormQuery.orgType ? $scope.searchFormQuery.orgType.value : undefined,
        market_segment_id: $scope.searchFormQuery.market_segment_id ? $scope.searchFormQuery.market_segment_id : undefined,
      };
      const storedData = storage.getDataByKey('keepFilterData', currentState);
      if (isSearch) {
        storage.setDataByKey('keepFilterData', currentState, matchData);
      } else if (storedData) {
        matchData = {
          organizationName: storedData.organizationName ? storedData.organizationName : undefined,
          mainContact: storedData.mainContact ? storedData.mainContact : undefined,
          status: [0, 1].includes(storedData.status) ? storedData.status : undefined,
          email: storedData.email ? storedData.email : undefined,
          orgType: storedData.orgType ? storedData.orgType : undefined,
          market_segment_id: storedData.market_segment_id ? storedData.market_segment_id : undefined,
        };
      } else {
        // this should be skipped
      }
      getAllOrgs();
    }

    $scope.clearFilters = () => {
      storage.removeDataByKey('keepFilterData', currentState);

      $scope.searchFormQuery = {};
      $scope.orgListQuery = {
        mainContact: '',
      };

      $scope.orgListQuery.orgType = {
        name: 'business',
        value: 0,
      }; // orgType select box reset
      if ($scope.marketSegmentId) {
        $scope.orgListQuery.market_segment_id = parseInt($scope.marketSegmentId, 10);
      }
      $scope.pagination.currentPage = 1;
      filterOrganizations();
      $scope.orgTypeFilterChanged();
    };

    function resetAddOrgForm() {
      $scope.addOrg = {
        name: null,
        mainContact: {
          firstName: null,
          lastName: null,
          mainContactEmail: null,
        },
        orgContactPhone: null,
      };
      $scope.addOrg.orgAccessType = [];
      $scope.showAddOrgForm = {
        show: false,
      };
      $scope.addOrgForm.$setPristine();
      $scope.addOrgForm.$setUntouched();
      $scope.validationReqTinNumber = true;
    }

    $scope.canceClicked = () => {
      resetAddOrgForm();
    };

    $scope.addOrgClicked = () => {
      const addOrg = angular.copy($scope.addOrg);
      addOrg.tin = addOrg.tin ? addOrg.tin.replace(/[- )(]/g, '') : '';
      if (!addOrg.orgAccessType.length) {
        $scope.accessTypeRequired = true;
        return;
      }
      if (addOrg.orgContactPhone) {
        addOrg.orgContactPhone = angular.element('#orgContactPhone').intlTelInput('getNumber', window.intlTelInputUtils.numberFormat.E164);
      }
      $scope.accessTypeRequired = false;
      addOrg.access_types = addOrg.orgAccessType.map(e => e.type);
      const addOrgCall = $http.post(`${API_BASE_URL}organisation/add`, addOrg);
      loadingScreenFactory.showLoadingScreen();
      addOrgCall.then(
        (result) => {
          loadingScreenFactory.hideLoadingScreen();
          if (result.data.success) {
            filterOrganizations();
            toaster.pop({
              type: 'success',
              title: 'Organization added successfully',
              showCloseButton: true,
              timeout: 10000,
            });
            $state.go('main.userMgmt.manageOrg', {
              orgId: result.data.org_id,
            });
            resetAddOrgForm();
          } else {
            if (typeof result.data.message === 'string') {
              toaster.pop({
                type: 'error',
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
            } else {
              Object.keys(result.data.message).forEach(key => toaster.pop({
                type: 'error',
                title: result.data.message[key],
                showCloseButton: true,
                timeout: 10000,
              }));
            }
            const el = angular.element(document.getElementById('tin'));
            if (el) {
              const $injector = el.injector();
              if ($injector) {
                $injector.invoke([
                  '$compile',
                  function compileTin($compile) {
                    $compile(el)($scope);
                  },
                ]);
              }
            }
          }
        },
        (error) => {
          loadingScreenFactory.hideLoadingScreen();
          if (typeof error.data.message === 'string') {
            toaster.pop({
              type: 'error',
              title: error.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          } else {
            Object.keys(error.data.message).forEach(key => toaster.pop({
              type: 'error',
              title: error.data.message[key],
              showCloseButton: true,
              timeout: 10000,
            }));
          }
          const el = angular.element(document.getElementById('tin'));
          if (el) {
            const $injector = el.injector();
            if ($injector) {
              $injector.invoke([
                '$compile',
                ($compile) => {
                  $compile(el)($scope);
                },
              ]);
            }
          }
        },
      );
    };
    // scope function added to handle the orgType filter change
    $scope.reloadOrganisations = {};
    $scope.orgTypeFilterChanged = () => {
      $scope.reloadOrganisations.value = true;
    };

    $scope.getAllOrgsForSelectBox = (offset, limit) => {
      // get orgtype based on selected filter
      const orgType = getOrgType();

      const queryParams = {};

      if ($scope.marketSegmentId && !Number.isNaN($scope.marketSegmentId)) {
        queryParams.market_segment_id = $scope.marketSegmentId;
      }

      return $http
        .get(`${API_BASE_URL}organisation/getOrganizations/${orgType}/${offset}/${limit}`, {
          params: {
            orgId: queryParams,
          },
        })
        .then((resp) => {
          let response = resp.data;
          response = (response.org_details || []).sort((a, b) => a.name.localeCompare(b.name));
          if (orgType === 'rider') {
            response.forEach((item) => {
              if (item.user && item.user.first_name && item.user.last_name) {
                Object.assign(item, {
                  rider_org_name: item.name,
                  name: `${item.user.first_name} ${item.user.last_name}`,
                });
              }
            });
          }
          return {
            data: response,
            count: resp.data.totalCount,
          };
        });
    };

    filterOrganizations();

    $scope.enterOnFormSearch = (event) => {
      if (event.keyCode === 13) {
        if ($scope.orgListQuery.phone && $scope.orgListQuery.phone.charAt(0) === '_') {
          $scope.orgListQuery.phone = undefined;
        }
        $scope.searchFormQuery = angular.copy($scope.orgListQuery);
        $scope.searchButtonClicked();
      }
    };

    $scope.searchButtonClicked = () => {
      $scope.pagination.currentPage = 1;
      $scope.searchFormQuery = angular.copy($scope.orgListQuery);
      filterOrganizations(true);
    };
    $scope.addOnEnter = (event) => {
      if ($scope.addOrgForm.$valid && event.keyCode === 13) {
        $scope.addOrgClicked();
      }
    };

    $scope.setForm = (addOrgForm) => {
      $scope.addOrgForm = addOrgForm;
    };

    $scope.getStatus = (isDeleted) => {
      if (isDeleted) {
        return 'Inactive';
      }
      return 'Active';
    };


    $scope.$on('$locationChangeSuccess', () => {
      if ($location.$$path !== '/main/userMgmt/orgList') {
        $location.search('table_page', null);
        return;
      }
      if ($location.search().table_page === true
        || $location.search().table_page === undefined
        || Number.isNaN($location.search().table_page)) {
        $location.search('table_page', 1);
        return;
      }
      filterOrganizations();
    });
  },
]);
