const {
  common: {
    constants: {
      featureFlags,
      billingFeeServiceMapping: {
        modeType = {}, costType = {}, billingFeeTypeList = {},
      },
    },
  },
  productCodes: { ProductCodes },
} = relaylib;
const { get } = _;
const { jQuery } = window;
angular
  .module('relayHealth')
  .controller('manageOrgCntrl', [
    '$scope',
    '$http',
    'googleService',
    'API_BASE_URL',
    '$state',
    '$stateParams',
    'toaster',
    'SweetAlert',
    'loadingScreenFactory',
    '$window',
    '$timeout',
    'DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID',
    'getMarketSegementSetting',
    'PAYMENT_METHODS',
    'WORKERS_COMPENSATION_ID',
    'HEALTHCARE_MASS_MARKET_KEY',
    'GROUP_HEALTH_KEY',
    'isOCCMRole',
    '$location',
    'org_constants',
    'capitalizeFilter',
    'permissionFilter',
    'AUTO_ASSIGN_SETTINGS',
    'dosOverlap',
    function manageOrgCntrl(
      $scope,
      $http,
      googleService,
      API_BASE_URL,
      $state,
      $stateParams,
      toaster,
      SweetAlert,
      loadingScreenFactory,
      $window,
      $timeout,
      DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID,
      getMarketSegementSetting,
      PAYMENT_METHODS,
      WORKERS_COMPENSATION_ID,
      HEALTHCARE_MASS_MARKET_KEY,
      GROUP_HEALTH_KEY,
      isOCCMRole,
      $location,
      orgConstants,
      capitalizeFilter,
      permissionFilter,
      AUTO_ASSIGN_SETTINGS,
      dosOverlap,
    ) {
      $scope.selectedA = [];
      $scope.checkedA = false;
      $scope.checkedB = false;
      $scope.selectedB = [];
      $scope.validateDos = false;
      $scope.emptyDosValues = false;
      $scope.dosPattern = /^[0-9]\d*$/;
      $scope.addDosExpiration = {};
      let prevDosValue;
      const watcherRemovers = [];
      const uncheck = { checked: false };
      $scope.isOCCMRole = isOCCMRole();
      $scope.permissionsConstants = relaylib.permissions.permissionsConstants;
      $scope.orgProvidersLib = relaylib.organisation.constants.orgProviders;
      $scope.groupHealthMarketSegmentKey = GROUP_HEALTH_KEY;
      $scope.daysOfMonth = Array(...Array(21)).map((x, i) => i + 8);
      $scope.monthlyInvoiceExists = false;
      $scope.orgConstants = orgConstants;
      $scope.tripTypeConstants = relaylib.common.constants.tripTypeConstants;
      $scope.mask = '(999) 999-9999';
      $scope.isSelectedCountryUS = true;
      $scope.providerConstants = {
        LYFT: relaylib.common.constants.providerIdMapping.LYFT.toString(),
        UBER: relaylib.common.constants.providerIdMapping.UBER.toString(),
      };
      $scope.billingFeeTypeList = billingFeeTypeList || {};
      const { orgId } = $stateParams;
      const activePage = $stateParams.active_page;
      const noEmailSelectedString = '--no email selected--';
      $scope.orgAccessType = Object.values(relaylib.organisation.constants.orgAccessTypes).map(e => ({
        type: e,
      }));
      $scope.isSelectedCountryUS = true;
      $scope.orgThirdPartyList = [];
      $scope.accessTypeRequired = false;
      $scope.childAccessTypeRequired = false;
      $scope.showEmailText = true;
      $scope.tabsObject = {
        account: false,
        payment: false,
        external_api: false,
        sso: false,
      };
      if (activePage && Object.prototype.hasOwnProperty.call($scope.tabsObject, activePage)) {
        $scope.tabsObject[activePage] = true;
      } else {
        $scope.tabsObject.account = true;
      }
      let editToggle = $stateParams.edit;
      $scope.generateData = {
        pKey: null,
        keyID: null,
      };
      if ($stateParams.caller) {
        $scope.pageCaller = $stateParams.caller;
      } else {
        $scope.pageCaller = 'ORG';
      }
      $scope.newAccountFields = {
        country: 'US',
        currency: 'usd',
        routing_number: '',
        account_number: '',
        account_holder_name: '',
        account_holder_type: '',
      };
      $scope.newAccountOptions = {};
      $scope.accountHolderTypes = [
        {
          name: 'individual',
          value: 'Individual',
        },
        {
          name: 'company',
          value: 'Company',
        },
      ];
      $scope.micro_deposits = {};
      $scope.org_payment_profile = '';
      $scope.dropdownOpen = false;
      $scope.occmId = DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID;
      let orgDetails;
      $scope.forms = {};
      $scope.disableForm = true;
      $scope.disableExternalApiForm = true;
      $scope.AddChildOrgError = '';
      $scope.emailList = [noEmailSelectedString];
      $scope.emailListWithId = [];
      $scope.currentMainContactId = '';
      $scope.disablePaymentForm = true;
      $scope.paymentDetails = {};
      $scope.addOrg = {
        access_types: [],
        name: undefined,
        mainContact: {
          firstName: undefined,
          lastName: undefined,
        },
      };
      $scope.showAddChildOrg = false;
      $scope.selectedChildOrgs = {
        selected: [],
      };
      $scope.initialSelectedChildOrgs = [];
      $scope.disableSaveButton = false;
      $scope.children = {
        savedOrg: null,
      };
      $scope.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      $scope.keyList = [];
      $scope.apiuserOrgSettings = {};
      const initialSSOSettings = {
        parent_org: {},
        current_org: {},
      };
      $scope.openModalName = '';
      $scope.selectedPaymentMethod = {};
      $scope.disableSSOFormTopFields = true;
      $scope.sso_child_org = {
        is_child_org: false,
        parent_org_id: '',
        org_customer_id: '',
      };
      $scope.sso_login_initiator = [
        {
          name: 'SP Initiator',
          id: 'sp',
        },
        {
          name: 'IDP Initiator',
          id: 'idp',
        },
      ];
      $scope.addressSettings = {
        addressFound: true,
        isValidatedAddress: false,
      };
      let isAddressSelectedFromSuggestions = false;
      $scope.addressObj = {};
      $scope.editDos = (index) => {
        if (!$scope.orgDetails.auto_assign_vendor || $scope.disableForm) {
          return;
        }
        if ($scope.addDosExpiration.$$hashKey && prevDosValue) {
          $scope.orgDetails.dos_offer_expiry.push({ ...prevDosValue });
        }
        $scope.addDosExpiration = { ...$scope.orgDetails.dos_offer_expiry[index] };
        prevDosValue = { ...$scope.orgDetails.dos_offer_expiry[index] };
        $scope.onDosConditionChange();
        $scope.orgDetails.dos_offer_expiry.splice(index, 1);
      };
      $scope.clearDos = () => {
        if (!$scope.orgDetails.auto_assign_vendor || $scope.disableForm) {
          return;
        }
        if ($scope.addDosExpiration.$$hashKey) {
          $scope.orgDetails.dos_offer_expiry.push({ ...prevDosValue });
        }
        $scope.addDosExpiration = {};
        $scope.addDosExpiration.expiry = '';
        $scope.addDosExpiration.dos = '';
        $scope.addDosExpiration.topProvidersValue = '';
        $scope.forms.orgEdit.$setUntouched();
        $scope.forms.orgEdit.$setPristine();
        $scope.onDosConditionChange();
      };
      $scope.deleteDos = (index) => {
        if (!$scope.orgDetails.auto_assign_vendor || $scope.disableForm) {
          return;
        }
        $scope.orgDetails.dos_offer_expiry.splice(index, 1);
        $scope.onAutoAssignFieldChange('dos_offer_expiry');
        $scope.onDosConditionChange();
      };
      $scope.onDosConditionChange = () => {
        $scope.validateDos = false;
        $scope.emptyDosValues = false;
        $scope.dosPlaceholder = 'e.g. 5';
        $scope.dosPattern = /^[0-9]\d*$/;
        if ($scope.addDosExpiration && $scope.addDosExpiration.check === 'between') {
          $scope.dosPlaceholder = 'e.g. 5-10';
          $scope.dosPattern = /^[0-9]+-[0-9]+$/;
        }
      };
      $scope.addDosAndExpiry = () => {
        $scope.validateDos = dosOverlap.checkValidity($scope.orgDetails.dos_offer_expiry, $scope.addDosExpiration);
        if ($scope.validateDos || $scope.disableForm) {
          return;
        }
        if (
          !($scope.addDosExpiration.check
            && $scope.addDosExpiration.dos
            && $scope.addDosExpiration.dosUnit
            && $scope.addDosExpiration.expiry
            && $scope.addDosExpiration.expiryUnit
            && $scope.addDosExpiration.topProvidersValue
          )
        ) {
          $scope.emptyDosValues = true;
          return;
        }
        if ($scope.addDosExpiration.check === 'between') {
          const values = $scope.addDosExpiration.dos.split('-');
          if (Number(values[0]) > Number(values[1])) {
            $scope.wrongValues = true;
            return;
          }
        }
        if ($scope.addDosExpiration.$$hashKey) {
          let found;
          $scope.orgDetails.dos_offer_expiry.forEach((dosSetting, index) => {
            if (dosSetting.$$hashKey === $scope.addDosExpiration.$$hashKey) {
              found = true;
              $scope.orgDetails.dos_offer_expiry[index] = { ...$scope.addDosExpiration };
              $scope.addDosExpiration = {};
            }
          });
          if (!found) {
            $scope.orgDetails.dos_offer_expiry.push({ ...$scope.addDosExpiration });
            $scope.addDosExpiration = {};
          }
        } else {
          $scope.orgDetails.dos_offer_expiry.push({ ...$scope.addDosExpiration });
          $scope.addDosExpiration = {};
        }
        $scope.onDosConditionChange();
        $scope.onAutoAssignFieldChange('dos_offer_expiry');
      };
      const spSsoUrl = 'sso/login/';
      const idpSsoUrl = 'sso/response/';
      const { userRoles } = relaylib.common.constants;
      $scope.isInviteRiderActivated = false;
      $scope.userRoles = userRoles;
      $scope.validateProviderId = function validateProviderId(data, index) {
        let response;
        if (Number.isNaN(data) || data < 1) {
          response = 'Provider ID is not valid';
        }
        for (let i = 0; i < $scope.orgDetails.excluded_vendor_list.length; i += 1) {
          if (i !== index && $scope.orgDetails.excluded_vendor_list[i] === data) {
            response = 'This Provider ID is already added';
          }
        }
        return response;
      };

      $scope.validatePhoneNumber = (formElement, elementId) => {
        const phoneNo = angular.element(`#${elementId}`).intlTelInput('getNumber', window.intlTelInputUtils.numberFormat.E164);
        if (!phoneNo.length) {
          formElement.$setValidity('invalidPhone', true);
          return true;
        }
        const isValidPhoneNumber = window.intlTelInputUtils.isValidNumber(phoneNo);
        if (isValidPhoneNumber) {
          formElement.$setValidity('invalidPhone', true);
        } else {
          formElement.$setValidity('invalidPhone', false);
        }
        return isValidPhoneNumber;
      };

      $scope.removeProvider = function removeProvider(index) {
        $scope.orgDetails.excluded_vendor_list.splice(index, 1);
      };

      $scope.addProvider = function addProvider(index) {
        if (index === $scope.orgDetails.excluded_vendor_list.length - 1) {
          $scope.orgDetails.excluded_vendor_list.push(0);
        }
      };

      $scope.onOrgTypesOpen = function onOrgTypesOpen(isOpen) {
        const payerOrgType = $scope.orgTypes.find(element => element.id === 'payer');
        if (isOpen) {
          payerOrgType.disabled = true;
          payerOrgType.title = 'Payer Name and system code do not exist.';
          if (permissionFilter($scope.permissionsConstants.SHOW_PAYER)) {
            $http
              .get(`${API_BASE_URL}organisation/payer/${$scope.orgDetails.id}`)
              .then((result) => {
                if (result.data.success) {
                  payerOrgType.disabled = false;
                  payerOrgType.title = payerOrgType.type;
                }
              })
              .catch(() => {
              }).finally(() => {
                loadingScreenFactory.hideLoadingScreen();
              });
          }
        }
      };
      const geocoder = new google.maps.Geocoder();
      const parseAddressString = function parseAddressString(
        address,
      ) {
        return new Promise((resolve, reject) => {
          geocoder.geocode(
            {
              address,
            },
            (results, status) => {
              if (status === google.maps.GeocoderStatus.OK) {
                resolve(results);
              } else reject(results);
            },
          );
        });
      };
      function updateBreadcrumb() {
        if ($scope.pageCaller === 'PM') {
          $scope.breadcrumbs = [
            {
              label: 'Profile Management',
            },
          ];
        } else {
          $scope.breadcrumbs = [
            {
              label: 'Organization Management',
              onClick() {
                $state.go('main.userMgmt.orgList');
              },
            },
          ];
          if ($scope.orgDetails) {
            if ($scope.orgDetails.org_class === 'rider') {
              $scope.breadcrumbs.push({
                label: capitalizeFilter($scope.orgDetails.user.first_name),
              });
            } else {
              $scope.breadcrumbs.push({
                label: $scope.orgDetails.name,
              });
            }
          }
        }
      }
      $scope.modalShow = (modal, bool, math, paymentMethod) => {
        if (bool) {
          $scope.openModalName = _.get(modal, 'type');
          $scope.selectedPaymentMethodCopy = angular.copy($scope.selectedPaymentMethod || {});
          $scope.selectedPaymentMethod = angular.copy(paymentMethod || {});
        }
      };
      const disableCostFeeType = (feeCostType) => {
        if (
          $scope.billingFeeTypeList[feeCostType]
          && $scope.billingFeeTypeList[feeCostType].sort_order + 1
          <= Object.keys($scope.billingFeeTypeList).length
        ) {
          Object.keys($scope.billingFeeTypeList).forEach((type) => {
            $scope.orgDetails[type].disableCostFeeType = false;
            if (
              $scope.billingFeeTypeList[type].sort_order
              === $scope.billingFeeTypeList[feeCostType].sort_order + 1
              && !$scope.orgDetails[feeCostType].current_fee_type
            ) {
              $scope.orgDetails[type].current_fee_type = null;
              $scope.orgDetails[type].disableCostFeeType = true;
              $scope.orgDetails[type].currentFeeTypeList = [];
            }
          });
        }
      };
      const getBillingFeeServices = () => {
        $scope.modeTypeEnabled = {};
        Object.values($scope.billingFeeTypeList).forEach((billingFeeType) => {
          $scope.billingFeeTypeList[billingFeeType.key].billingFeeList = billingFeeType.billingFeeList.filter(service => service.ui_visible === true)
            .sort((a, b) => a.sort_order - b.sort_order);
          $scope.modeTypeEnabled[billingFeeType.key] = billingFeeType.billingFeeList
            .reduce((list, service) => { if (service.mode_type_enable) { list.push(service.key); } return list; }, []);
        });
      };
      getBillingFeeServices();
      const getBillingFeesModeGroups = () => {
        const products = ProductCodes.filterProductCodes({ ui_visible: true })
          .map((product) => {
            const { product_code: code, sub_group: name } = product;
            return {
              code,
              name,
            };
          });
        products.sort((a, b) => b.code - a.code);
        return products;
      };

      const getBillingFeeModes = () => {
        const groups = getBillingFeesModeGroups();
        return {
          [modeType.ALL_MODE]: {
            key: modeType.ALL_MODE,
            display: 'All Mode',
            groups: [{
              code: 0,
              required: true,
            }],
          },
          [modeType.PER_MODE]: {
            key: modeType.PER_MODE,
            display: 'Per Mode',
            groups,
          },
        };
      };
      $scope.billingFeesModes = getBillingFeeModes();
      $scope.setBillingFeeOnUserAction = (feeCostType, key, event) => {
        const feeCostTypeDetails = $scope.orgDetails[feeCostType];
        feeCostTypeDetails.currentFeeTypeList = [];
        feeCostTypeDetails.current_fee_type = null;
        if (event.target.checked) {
          feeCostTypeDetails.currentFeeTypeList[key] = true;
          feeCostTypeDetails.current_fee_type = key;
          if (!feeCostTypeDetails.current_mode_type) {
            feeCostTypeDetails.current_mode_type = modeType.ALL_MODE;
          }
        }
        disableCostFeeType(feeCostType);
      };
      $scope.updateFeeDetail = ({ code, name }, rate, feeCostType) => {
        const { current_fee_type: currentFeeType } = $scope.orgDetails[feeCostType];
        if (rate === '' || code === undefined || rate === null) {
          if ($scope.orgDetails[feeCostType].currentModeValue[currentFeeType][code]) {
            delete $scope.orgDetails[feeCostType].currentModeValue[currentFeeType][code];
          }
        } else {
          const group = {
            code: Number(code),
            rate,
          };
          if (name) {
            group.name = name;
          }
          if (!$scope.orgDetails[feeCostType].currentModeValue[currentFeeType]) {
            $scope.orgDetails[feeCostType].currentModeValue[currentFeeType] = [];
            $scope.orgDetails[feeCostType].currentModeValue[currentFeeType][code] = {};
          }
          $scope.orgDetails[feeCostType].currentModeValue[currentFeeType][code] = group;
        }
      };
      const getSetting = function getSetting(key, type = 'bool') {
        const setting = _.find($scope.orgDetails.settings, {
          setting_name: key,
        });
        if (setting && setting.setting_value !== null) {
          if (type === 'bool') {
            return String(setting.setting_value).toLowerCase() === 'true';
          }
          if (type === 'int') {
            return +setting.setting_value || 0;
          }
          if (type === 'json' && setting.setting_value.length) {
            return JSON.parse(setting.setting_value);
          }
          if (type === 'string') {
            return setting.setting_value;
          }
        }
        return undefined;
      };

      function initExcludedVendorList() {
        $scope.orgDetails.excluded_vendor_list = getSetting('excluded_vendor_list', 'json');
        if ($scope.orgDetails.excluded_vendor_list) {
          $scope.orgDetails.excluded_vendor_list.push(0);
        } else {
          $scope.orgDetails.excluded_vendor_list = [0];
        }
      }

      const resetSettings = function resetSettings() {
        $scope.orgDetails.stratus_access = false;
        $scope.orgDetails.pay_invoice = false;
        if ($scope.orgDetails.market_segment) {
          $scope.orgDetails.market_segment.pay_invoice = false;
        }
        $scope.orgDetails.pickup_time_allowed = false;
        $scope.orgDetails.steerage = false;
      };

      function populateProvidersFromOrgSettings() {
        $scope.orgDetails.org_providers = getSetting('org_providers', 'json');
        const setting = $scope.orgDetails.org_providers;
        if (setting) {
          if (setting.lyft) {
            Object.assign($scope.orgDetails, {
              lyftSelected: true,
              lyftTag: setting.lyft.tag,
            });
          }
          if (setting.uber) {
            Object.assign($scope.orgDetails, {
              uberSelected: true,
              uberTag: setting.uber.tag,
            });
          }
          if (setting.traditional) {
            Object.assign($scope.orgDetails, {
              traditionalSelected: true,
              traditionalTag: setting.traditional.tag,
            });
          }
          if (setting.lyft && setting.uber) {
            Object.assign($scope.orgDetails, {
              allProvidersSelected: true,
            });
          }
        }
      }
      const getAutoAssignSettings = async (requiredSettings = []) => {
        loadingScreenFactory.showLoadingScreen();
        const params = { setting_name: requiredSettings.join(',') };
        params.sourceLevel = true;
        let autoAssignSettings = {};
        /** Key that used to distinguish whether we need to return the _source Parameter from api or not */
        // const sourceLevel = true;
        try {
          const getAutoAssignSettingsCall = await $http({
            url: `${API_BASE_URL}setting/organization/${orgId}/auto-assign`,
            method: 'GET',
            params,
          });
          if (getAutoAssignSettingsCall.data && getAutoAssignSettingsCall.data.success) {
            const { settings } = getAutoAssignSettingsCall.data;
            autoAssignSettings = settings;
          }
        } catch (err) {
          const message = err.data && err.data.message ? err.data.message : err.message;
          toaster.pop({
            type: 'error',
            title: message,
            showCloseButton: true,
            timeout: 10000,
          });
        } finally {
          loadingScreenFactory.hideLoadingScreen();
          $scope.$apply();
        }
        return autoAssignSettings;
      };

      const populateAutoAssignOrgSettings = async function populateAutoAssignOrgSettings() {
        if ($scope.orgDetails.market_segment.key === 'GHT') {
          const autoAssignSettings = {};
          const notFoundAutoAssignSettings = [];
          const autoAssignSettingsName = AUTO_ASSIGN_SETTINGS.ALL_AUTO_ASSIGN_SETTINGS;
          const autoAssign = getSetting(AUTO_ASSIGN_SETTINGS.AUTO_ASSIGN_SETTING_NAME, 'bool');
          autoAssignSettings[AUTO_ASSIGN_SETTINGS.AUTO_ASSIGN_SETTING_NAME] = autoAssign === undefined || autoAssign === null ? true : getSetting(AUTO_ASSIGN_SETTINGS.AUTO_ASSIGN_SETTING_NAME, 'bool');
          autoAssignSettingsName
            .forEach((setting) => {
              const settingValue = getSetting(setting.settingName, setting.type);
              if (settingValue === undefined
                || settingValue === null) {
                notFoundAutoAssignSettings.push(setting.settingName);
              }
              autoAssignSettings[setting.settingName] = settingValue;
            });
          Object.assign($scope.orgDetails, autoAssignSettings);
          if (notFoundAutoAssignSettings.length) {
            const settings = await getAutoAssignSettings(notFoundAutoAssignSettings);
            Object.assign($scope.orgDetails, settings);
          }
          if (!$scope.orgDetails.dos_offer_expiry) {
            $scope.orgDetails.dos_offer_expiry = [];
          } else if (typeof $scope.orgDetails.dos_offer_expiry === 'string') {
            $scope.orgDetails.dos_offer_expiry = JSON.parse($scope.orgDetails.dos_offer_expiry);
          }
          if (typeof $scope.orgDetails.dos_expiry_combination === 'string') {
            $scope.orgDetails.dos_expiry_combination = $scope.orgDetails.dos_expiry_combination.toLowerCase() === 'true';
          }
          $scope.$apply();
        }
      };
      function arrayObjectIndexOf(myArray, searchTerm) {
        return myArray.findIndex(value => value.id === Number(searchTerm));
      }
      const populateOrgSettings = function populateOrgSettings() {
        if ($scope.orgDetails.settings.length > 0) {
          Object.assign($scope.orgDetails, {
            stratus_access: getSetting('stratus_access'),
            cdi_rate: getSetting('cdi_rate', 'int'),
            asl_rate: getSetting('asl_rate', 'int'),
            spoken_english_rate: getSetting('spoken_english_rate', 'int'),
            audio_rollover_rate: getSetting('audio_rollover_rate', 'int'),
            old_dashboard: getSetting('old_dashboard'),
            auto_pay: getSetting('auto_pay'),
            steerage: getSetting('steerage'),
            pay_invoice: getSetting('pay_invoice'),
            vendor_preference_order: getSetting('vendor_preference_order', 'json'),
            ebsCsvWithApData: getSetting('ebs_csv_with_ap_data'),
            ebsCsvWithArData: getSetting('ebs_csv_with_ar_data'),
          });
          populateAutoAssignOrgSettings();
          const pickupTimeSetting = _.find($scope.orgDetails.settings, {
            setting_name: 'trip_time_type',
          });
          const devicesSetting = _.find($scope.orgDetails.settings, {
            setting_name: 'devices',
          });
          const autoPayDateSetting = _.find($scope.orgDetails.settings, {
            setting_name: 'auto_pay_date',
          });
          initExcludedVendorList();
          $scope.orgDetails.auto_pay_date = (autoPayDateSetting && Number(autoPayDateSetting.setting_value)) || 8;
          $scope.orgDetails.pickup_time_allowed = pickupTimeSetting && pickupTimeSetting.setting_value.indexOf('pickup') >= 0;
          const payInvoiceSettingValue = getMarketSegementSetting($scope.orgDetails.market_segment, 'pay_invoice');
          $scope.orgDetails.market_segment.pay_invoice = `${payInvoiceSettingValue}`.toLowerCase() === 'true';
          if (devicesSetting) {
            $scope.orgDetails.devices = (devicesSetting.setting_value && devicesSetting.setting_value.split(',')) || '';
          }
          populateProvidersFromOrgSettings();
        } else {
          resetSettings();
        }
      };
      const populateAncestorMileageLimitOrgSettings = function populateAncestorMileageLimitOrgSettings() {
        const orgAllDetails = angular.copy($scope.orgDetails);
        const { ancestors: orgAncestors } = orgAllDetails;
        const ancestorsLength = orgAncestors.length;
        for (let i = ancestorsLength - 1; i >= 0; i -= 1) {
          if (orgAncestors[i].settings.length) {
            const mileageLimitEnabledSetting = _.find(orgAncestors[i].settings, {
              setting_name: 'mileage_limit_enabled',
            });
            const mileageLimitTypeSetting = _.find(orgAncestors[i].settings, {
              setting_name: 'mileage_limit_type',
            });
            const mileageLimitValueSetting = _.find(orgAncestors[i].settings, {
              setting_name: 'mileage_limit_value',
            });
            if (mileageLimitEnabledSetting && mileageLimitEnabledSetting.setting_value === 'true') {
              $scope.orgDetails.mileage_limit_type = mileageLimitTypeSetting.setting_value;
              $scope.orgDetails.mileage_limit_value = mileageLimitValueSetting.setting_value;
              break;
            }
          }
        }
      };
      function getPaymentProfiles(id) {
        $scope.paymentMethods = [];
        const data = {};
        data.params = {
          owner_id: id,
          owner_type: 'org',
          invoice_profile: true,
        };
        const getPaymentProfilesCall = $http.get(`${API_BASE_URL}paymentProfiles`, data);
        loadingScreenFactory.showLoadingScreen();
        getPaymentProfilesCall.then(
          (result) => {
            loadingScreenFactory.hideLoadingScreen();
            if (result.data.success) {
              const resultData = result.data.data;
              if (resultData.length > 0) {
                for (let i = 0; i < resultData.length; i += 1) {
                  if (resultData[i].payment_method === PAYMENT_METHODS.BANK_ACCOUNT) {
                    $scope.paymentMethods.unshift({
                      type: resultData[i].stripe_profile.object,
                      id: resultData[i].id,
                      last4: resultData[i].stripe_profile.last4,
                      open: false,
                      status: resultData[i].stripe_profile.status,
                      name: resultData[i].stripe_profile.account_holder_name,
                      bank_name: resultData[i].stripe_profile.bank_name,
                      account_type: resultData[i].stripe_profile.account_holder_type,
                    });
                  }
                }
              }
              $scope.paymentMethods = _.orderBy($scope.paymentMethods, ['type', 'status'], ['desc', 'desc']);
              const monthlyInvoiceDefaultPaymentProfile = orgDetails.default_monthly_invoice_payment_profile_id
                && _.find($scope.paymentMethods, {
                  id: orgDetails.default_monthly_invoice_payment_profile_id,
                });
              if (monthlyInvoiceDefaultPaymentProfile) {
                monthlyInvoiceDefaultPaymentProfile.isPrimary = true;
                const autoPaySetting = _.find($scope.orgDetails.settings, {
                  setting_name: 'auto_pay',
                });
                if (autoPaySetting && autoPaySetting.setting_value.toLowerCase() === 'true') {
                  monthlyInvoiceDefaultPaymentProfile.auto_pay = true;
                } else {
                  monthlyInvoiceDefaultPaymentProfile.auto_pay = false;
                }
                const monthlyInvoicePaymentMethodIndex = _.findIndex($scope.paymentMethods, {
                  id: monthlyInvoiceDefaultPaymentProfile.id,
                });
                $scope.paymentMethods.splice(0, 0, $scope.paymentMethods.splice(monthlyInvoicePaymentMethodIndex, 1)[0]);
              }
            }
          },
          (err) => {
            toaster.pop({
              type: 'error',
              title: err,
              showCloseButton: true,
              timeout: 10000,
            });
          },
        );
      }
      $scope.openStartCalender = (event) => {
        event.stopPropagation();
        $timeout(() => {
          angular.element('#calendarDate').triggerHandler('focus');
        }, 100);
      };
      $scope.closeStartCalender = (event) => {
        event.stopPropagation();
        $timeout(() => {
          angular.element('#calendarDate').triggerHandler('blur');
        }, 100);
      };
      $scope.calendarDate = moment(new Date().getTime());
      $scope.deletePaymentProfile = (selectedPaymentMethod) => {
        const sweetAlertOptions = {
          title: 'Delete Payment Method',
          type: 'warning',
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes! Delete it',
          closeOnConfirm: true,
          showCancelButton: true,
          closeOnCancel: true,
        };
        if (selectedPaymentMethod.isPrimary && selectedPaymentMethod.auto_pay) {
          sweetAlertOptions.text = 'Deleting this payment method will disable Auto Pay. Are you sure you want to delete this?';
        } else {
          sweetAlertOptions.text = 'Are you sure you want to delete this payment method?';
        }
        SweetAlert.swal(sweetAlertOptions, (isConfirm) => {
          if (isConfirm) {
            loadingScreenFactory.showLoadingScreen();
            $http
              .delete(`${API_BASE_URL}paymentProfiles/${selectedPaymentMethod.id}`)
              .then((response) => {
                if (response.data.success) {
                  toaster.pop({
                    type: 'success',
                    title: response.data.message,
                    showCloseButton: true,
                    timeout: 10000,
                  });
                  $scope.modalShow('', true, null);
                  if (selectedPaymentMethod.isPrimary && selectedPaymentMethod.auto_pay) {
                    $scope.selectedPaymentMethod.auto_pay = false;
                    $scope.saveAutoPaySetting($scope.selectedPaymentMethod, {
                      hideMessage: true,
                    });
                  } else {
                    getPaymentProfiles(orgId);
                  }
                } else {
                  toaster.pop({
                    type: 'error',
                    title: response.data.message,
                    showCloseButton: true,
                    timeout: 10000,
                  });
                }
                loadingScreenFactory.hideLoadingScreen();
              })
              .catch((errorResult) => {
                toaster.pop({
                  type: 'error',
                  title: errorResult.message || (errorResult.data && errorResult.data.message) || '',
                  showCloseButton: true,
                  timeout: 10000,
                });
                loadingScreenFactory.hideLoadingScreen();
              });
          }
        });
      };
      $scope.newAccountPaymentSubmit = () => {
        $scope.newAccountOptions.getToken($scope.newAccountFields).then(
          (result) => {
            const body = {
              ownerType: 'org',
              ownerId: orgId,
              token: result.token.id,
              invoiceProfile: true,
            };
            loadingScreenFactory.showLoadingScreen();
            $http
              .post(`${API_BASE_URL}paymentProfiles/bankaccount`, body)
              .then((response) => {
                loadingScreenFactory.hideLoadingScreen();
                if (response.data.success) {
                  const paymentProfile = response.data.data;
                  getPaymentProfiles(orgId);
                  return SweetAlert.swal(
                    {
                      title: 'Bank Account Information Received',
                      text: `In 2-3 business days, you will receive two small(normally less than one dollar) 
                      deposits to this account from us. Once you receive them, enter those amounts under 
                      "Micro Deposits" to complete setup.`,
                      type: 'success',
                      confirmButtonColor: '#DD6B55',
                      confirmButtonText: 'OK',
                    },
                    () => {
                      $scope.modalShow(
                        {
                          type: 'bank_account',
                        },
                        true,
                        null,
                        {
                          id: paymentProfile.payment_profile_id,
                          type: 'bank_account',
                          last4: paymentProfile.last4,
                          open: false,
                          status: paymentProfile.status,
                          name: paymentProfile.account_holder_name,
                          bank_name: paymentProfile.bank_name,
                          account_type: paymentProfile.account_holder_type,
                        },
                      );
                      $scope.newAccountFields = {
                        country: 'US',
                        currency: 'usd',
                        routing_number: '',
                        account_number: '',
                        account_holder_name: '',
                        account_holder_type: '',
                      };
                    },
                  );
                }
                toaster.pop({
                  type: 'error',
                  title: response.data.message,
                  showCloseButton: true,
                  timeout: 10000,
                });
                return true;
              })
              .catch((err) => {
                loadingScreenFactory.hideLoadingScreen();
                toaster.pop({
                  type: 'error',
                  title: err.message || (err.data && err.data.message) || '',
                  showCloseButton: true,
                  timeout: 10000,
                });
              });
            // use token to send to api
          },
          () => {
            // show error
            /* toaster.pop({
                  type: 'error',
                  title: error.message || (error.data && error.data.message) || "",
                  showCloseButton: true,
                  timeout: 10000
                }); */
            loadingScreenFactory.hideLoadingScreen();
          },
        );
      };

      const showToaster = function showToaster(toasterType, message, toasterMessage) {
        toaster.clear();
        let type = toasterType;
        let customMessage = toasterMessage;
        type = ['info', 'error', 'success'].indexOf(type) > -1 ? type : 'error';
        if (!customMessage) {
          customMessage = '';
        }
        toaster.pop({
          type,
          title: message || customMessage,
          showCloseButton: true,
          timeout: 10000,
        });
      };
      // fetch org sso template data
      $scope.ssoTemplateData = {};
      function getOrgSsoTemplate(ssoType) {
        if (!ssoType) {
          return;
        }
        $http.get(`${API_BASE_URL}organisation/getOrgSsoTemplate/${ssoType}/${$scope.sso_setting.login_initiator}`).then((result) => {
          $scope.ssoTemplateData = result.data.orgSsoTempDetails;
        });
      }

      /**
       * This Function is the one getting all the Api keys
       */
      const getApiKeys = () => {
        const getApiKeysCall = $http({
          url: `${API_BASE_URL}apitoken`,
          method: 'GET',
          params: {
            org_id: orgId,
          },
        });
        getApiKeysCall.then(
          (result) => {
            if (result.data.success) {
              $scope.keyList = result.data.data;
            } else {
              toaster.pop({
                type: 'error',
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
            }
          },
          (error) => {
            toaster.pop({
              type: 'error',
              title: error.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          },
        );
      };

      function findIndexOfStratusAccess(settingsArray) {
        for (let i = 0; i < settingsArray.length; i += 1) {
          if (settingsArray[i].setting_name === 'stratus_access') return i;
        }
        return true;
      }

      function findIndexOfOrgSetting(settingsArray, settingName) {
        for (let i = 0; i < settingsArray.length; i += 1) {
          if (settingsArray[i].setting_name === settingName) return i;
        }
        return -1;
      }

      const getEligibleChildrenOrganisations = function getEligibleChildrenOrganisations(currentOrgId) {
        $scope.children.loading = true;
        return $http
          .get(`${API_BASE_URL}organisation/getEligibleChildrenOrganisations`, {
            params: {
              orgId: currentOrgId,
            },
          })
          .then(({
            data: {
              result,
            },
          }) => {
            // children orgs values are populated here
            $scope.children.allChildren = result;

            const currentOrgIdInteger = parseInt(currentOrgId, 10);

            $scope.initialSelectedChildOrgs = result
              .filter(({
                parent_id: parentId,
              }) => parentId === currentOrgIdInteger);

            $scope.selectedChildOrgs = {
              selected: [...$scope.initialSelectedChildOrgs],
            };

            // sort the children orgs by selected first
            $scope.children.allChildren.sort(({
              selected: child1,
            }, {
              selected: child2,
            }) => (child1 < child2 ? 1 : -1));
          })
          .catch((error) => {
            showToaster(
              'error',
              error.data ? error.data.message : 'Could not get eligible children organizations',
              'Could not get eligible children organizations',
            );
          })
          .finally(() => {
            $scope.children.loading = false;
          });
      };

      const getThirdPartyOrgList = async function getThirdPartyOrgList() {
        try {
          const { data: { org_details: orgList = [] } } = await $http({
            url: `${API_BASE_URL}organisation/getOrganizations`,
            method: 'GET',
            params: { is_third_party: true },
          });
          $scope.orgThirdPartyList = orgList.map(org => ({
            id: org.id,
            name: org.name,
            org_key: org.org_key,
          }));
          $scope.orgDetails.thirdPartyOrgs = $scope.orgThirdPartyList.filter(org => (_.find($scope.orgDetails.grantedBy, { granted_to: org.id })));
        } catch (error) {
          showToaster(
            'error',
            error.data ? error.data.message : 'Could not get third party organizations',
            'Could not get third party organizations',
          );
        }
      };

      const showEmptyOptionForEmail = () => {
        if (!$scope.orgDetails) return;
        if (!$scope.orgDetails.user) { // user is null
          $scope.orgDetails.user = {
            username: $scope.showEmailText ? '' : noEmailSelectedString,
          };
        }
      };

      const getOrgTypes = async function getOrgTypes(selectedOrgTypes) {
        $scope.orgTypes = Object.keys(relaylib.organisation.constants.orgTypes).map(orgKey => ({
          type: relaylib.organisation.constants.orgTypes[orgKey],
          id: orgKey,
          title: relaylib.organisation.constants.orgTypes[orgKey],
        }));
        if (_.isArray(selectedOrgTypes)) {
          $scope.orgDetails.org_types = $scope.orgTypes.filter(org => (_.includes(selectedOrgTypes, org.id)));
        }
      };

      // saml setting
      $scope.saml_config_detail = {};
      $scope.enable_saml_group_key = false;
      function decideDefaultOrgProvider() {
        if ($scope.orgDetails.org_type === 'mas') {
          // this should assign mas related data

          Object.assign($scope.orgDetails, {
            uberSelected: false,
            lyftSelected: false,
            allProvidersSelected: false,
            uberTag: $scope.orgProvidersLib.tagConsts.MAS,
            lyftTag: $scope.orgProvidersLib.tagConsts.MAS,
          });
          return;
        }
        if ($scope.orgDetails.market_segment && $scope.orgDetails.market_segment.key === 'GHT') {
          // this changes tag to gh
          Object.assign($scope.orgDetails, {
            uberSelected: false,
            lyftSelected: false,
            allProvidersSelected: false,
            uberTag: $scope.orgProvidersLib.tagConsts.GH,
            lyftTag: $scope.orgProvidersLib.tagConsts.GH,
            traditionalSelected: false,
            traditionalTag: $scope.orgProvidersLib.tagConsts.GH,
          });
          return;
        }
        // this is the fallback tag which is set to occm
        Object.assign($scope.orgDetails, {
          uberSelected: false,
          lyftSelected: false,
          allProvidersSelected: false,
          uberTag: $scope.orgProvidersLib.constants.UBER.DEFAULT_TAG,
          lyftTag: $scope.orgProvidersLib.constants.LYFT.DEFAULT_TAG,
        });
      }
      function getOrgDetailsById() {
        if (orgId) {
          let userDetails = localStorage.getItem('userDetails');
          userDetails = JSON.parse(userDetails);
          return $http
            .get(`${API_BASE_URL}organisation/getOrgDetailsById/${orgId}`)
            .then((result) => {
              if (result.data.success) {
                $scope.apiuserOrgSettings.apiuser_count = result.data.orgDetails.apiuser_count;
                $scope.apiuserOrgSettings.webhook_url = result.data.orgDetails.webhook_url;
                $scope.ancestorsList = result.data.orgDetails.ancestors;
                $scope.apiuserOrgSettings.secret = result.data.orgDetails.secret;
                $scope.orgDetails = result.data.orgDetails;
                const {
                  fee_details: feeDetails = {},
                  phone,
                } = $scope.orgDetails || {};

                if (phone) {
                  $timeout(() => {
                    angular.element('#orgContactPhone').trigger('phone-number-changed', phone);
                  });
                }
                $scope.showEmailText = !$scope.emailList.filter(email => email !== noEmailSelectedString).length;
                showEmptyOptionForEmail();
                Object.values(costType).forEach((key) => {
                  $scope.orgDetails[key] = {};
                });
                Object.values(costType).forEach((key) => {
                  const costTypeDetails = $scope.orgDetails[key];
                  if (feeDetails && feeDetails[key]) {
                    costTypeDetails.currentFeeTypeList = [];
                    const { current_fee_type: currentFeeType = {} } = feeDetails[key];
                    costTypeDetails.current_fee_type = currentFeeType;
                    costTypeDetails.currentFeeTypeList[currentFeeType] = true;
                    if (feeDetails[key][currentFeeType] && feeDetails[key][currentFeeType].current_mode_type) {
                      costTypeDetails.current_mode_type = feeDetails[key][currentFeeType].current_mode_type;
                      const currentModeValue = {};

                      Object.keys(feeDetails[key])
                        .forEach((feeType) => {
                          const { current_mode_value: modeValues = [] } = feeDetails[key][feeType];
                          if (modeValues.length) {
                            modeValues.forEach((product) => {
                              const { code = 0, name, rate = 0 } = product;
                              if (!currentModeValue[feeType]) {
                                currentModeValue[feeType] = {};
                              }
                              currentModeValue[feeType][code] = {
                                code,
                                name,
                                rate,
                              };
                            });
                          }
                        });
                      costTypeDetails.currentModeValue = currentModeValue;
                    }
                  }
                  disableCostFeeType(key);
                });
                $scope.state.businessInfo.orgId = $scope.orgDetails.id;
                $scope.org_payment_profile = result.data.orgDetails.payment_profile;
                $scope.monthlyInvoiceExists = !!(result.data.orgDetails.rides_monthly_invoices);
                $scope.orgDetails.access_types = result.data.orgDetails.access_types.map(e => ({
                  type: e,
                }));
                const selectedOrgTypes = result.data.orgDetails.org_types;
                getOrgTypes(selectedOrgTypes);
                if (_.includes(selectedOrgTypes, 'third_party')) {
                  $scope.apiuserOrgSettings.is_third_party = true;
                  $scope.orgDetails.is_third_party = true;
                } else {
                  $scope.apiuserOrgSettings.is_third_party = false;
                  $scope.orgDetails.is_third_party = false;
                }
                $scope.apiuserOrgSettings.third_party_org_key = _.get(result, 'data.orgDetails.org_access_mappings[0].grantedTo.org_key', null);
                if ($scope.apiuserOrgSettings.third_party_org_key) {
                  $scope.apiuserOrgSettings.third_party_status = _.get(result, 'data.orgDetails.org_access_mappings[0].status', null);
                  $scope.getThirdPartyOrgDetails($scope.apiuserOrgSettings.third_party_org_key);
                }
                getPaymentProfiles(orgId);
                getThirdPartyOrgList();
                if (
                  $scope.userDetails.role === 'mastersuperadmin'
                  || $scope.userDetails.role === 'org_superadmin'
                  || permissionFilter($scope.permissionsConstants.GET_API_KEYS)
                ) {
                  getApiKeys();
                }
                /**
                 * Decision maker to show details of parent/own sso details.
                 */
                const orgSsoSettings = {};
                const parentOrgSsoSettings = {};
                // get sso template start here
                if ($scope.orgDetails.org_sso_setting != null && $scope.orgDetails.org_sso_setting.setting) {
                  $scope.sso_setting.login_initiator = $scope.orgDetails.org_sso_setting.login_initiator;
                  angular.forEach($scope.orgDetails.org_sso_setting.setting, (value, key) => {
                    $scope.enable_saml_group_key = key === 'sso_key_mapping';
                    orgSsoSettings[key] = {};
                    $scope.saml_config_detail[key] = {};
                    angular.forEach(value, (ivalue, ikey) => {
                      orgSsoSettings[key][ikey] = ivalue;
                    });
                  });
                }

                // get parent sso template start here
                if ($scope.orgDetails.parent_org_sso_setting != null && $scope.orgDetails.parent_org_sso_setting.setting) {
                  $scope.sso_setting.login_initiator = $scope.orgDetails.parent_org_sso_setting.login_initiator;
                  angular.forEach($scope.orgDetails.parent_org_sso_setting.setting, (value, key) => {
                    $scope.enable_saml_group_key = key === 'sso_key_mapping';
                    parentOrgSsoSettings[key] = {};
                    angular.forEach(value, (ivalue, ikey) => {
                      parentOrgSsoSettings[key][ikey] = ivalue;
                    });
                  });
                }

                initialSSOSettings.current_org = angular.copy(orgSsoSettings);
                initialSSOSettings.parent_org = angular.copy(parentOrgSsoSettings);
                $scope.saml_config_detail = $scope.orgDetails.is_child_org ? parentOrgSsoSettings : orgSsoSettings;

                $scope.sso_setting.checkbox = !!$scope.orgDetails.enabled_sso;

                $scope.sso_child_org = {
                  is_child_org: $scope.orgDetails.is_child_org,
                  parent_org_id: $scope.orgDetails.org_parent_id,
                  org_customer_id: $scope.orgDetails.org_customer_id,
                };

                const ssoKeyToShow = $scope.orgDetails.sso_key;
                let loginInitiatorType = spSsoUrl;
                if ($scope.sso_setting.login_initiator === 'idp') {
                  loginInitiatorType = idpSsoUrl;
                }

                let parentSsoKeyToShow = '';
                if ($scope.ancestorsList.length > 0) {
                  angular.forEach($scope.ancestorsList, (value) => {
                    if (value.id === $scope.sso_child_org.parent_org_id) {
                      parentSsoKeyToShow = value.sso_key;
                    }
                  });
                }
                const ssokey = $scope.orgDetails.is_child_org ? parentSsoKeyToShow : ssoKeyToShow;
                $scope.ssoKeyUrl = API_BASE_URL + loginInitiatorType + ssokey;
                initialSSOSettings.current_ssokey = angular.copy(ssoKeyToShow);
                initialSSOSettings.parent_ssokey = angular.copy(parentSsoKeyToShow);

                getOrgSsoTemplate($scope.orgDetails.sso_type);
                // get sso template end here
                $scope.state.monthlyInvoice = $scope.orgDetails.billing_method === 'invoice';
                $scope.billing.billing_method = $scope.orgDetails.billing_method || 'invoice';
                $scope.currentMainContactId = $scope.orgDetails.main_contact_id;
                userDetails.organisation.main_contact_id = $scope.currentMainContactId;
                $scope.state.billingAddress = result.data.orgDetails.billing_address;
                localStorage.setItem('userDetails', JSON.stringify(userDetails));
                $scope.billing_method = result.data.orgDetails.billing_method;
                if (!$scope.orgDetails.physical_address || !$scope.orgDetails.physical_address.state_id) {
                  $scope.state_id = 0;
                } else {
                  $scope.state_id = $scope.orgDetails.physical_address.state_id;
                }

                // check stratus access setting
                if ($scope.orgDetails.settings.length > 0) {
                  const stratusSettingIndexInArray = findIndexOfStratusAccess($scope.orgDetails.settings);
                  if (stratusSettingIndexInArray !== -1 && $scope.orgDetails.settings[stratusSettingIndexInArray].setting_value.toLowerCase() === 'true') {
                    $scope.orgDetails.stratus_access = true;
                  } else {
                    $scope.orgDetails.stratus_access = false;
                  }

                  const tripTypeSetting = _.find($scope.orgDetails.settings, {
                    setting_name: 'trip_type',
                  });
                  const pickupTimeSetting = _.find($scope.orgDetails.settings, {
                    setting_name: 'trip_time_type',
                  });
                  const cdiRateSetting = _.find($scope.orgDetails.settings, {
                    setting_name: 'cdi_rate',
                  });
                  const aslRateSetting = _.find($scope.orgDetails.settings, {
                    setting_name: 'asl_rate',
                  });
                  const spokenEnglishRateSetting = _.find($scope.orgDetails.settings, {
                    setting_name: 'spoken_english_rate',
                  });
                  const audioRolloverRateSetting = _.find($scope.orgDetails.settings, {
                    setting_name: 'audio_rollover_rate',
                  });
                  const devicesSetting = _.find($scope.orgDetails.settings, {
                    setting_name: 'devices',
                  });
                  const oldDashboardSetting = _.find($scope.orgDetails.settings, {
                    setting_name: 'old_dashboard',
                  });
                  const autoPaySetting = _.find($scope.orgDetails.settings, {
                    setting_name: 'auto_pay',
                  });
                  const autoPayDateSetting = _.find($scope.orgDetails.settings, {
                    setting_name: 'auto_pay_date',
                  });
                  const steerageSetting = _.find($scope.orgDetails.settings, {
                    setting_name: 'steerage',
                  });
                  const accountLookup = _.find($scope.orgDetails.settings, {
                    setting_name: 'account_lookup',
                  });
                  const futureLookup = _.find($scope.orgDetails.settings, {
                    setting_name: 'future_lookup',
                  });
                  const customizedIDNumber = _.find($scope.orgDetails.settings, {
                    setting_name: 'customized_id_number',
                  });
                  const customizedIDNumberLabel = _.find($scope.orgDetails.settings, {
                    setting_name: 'customized_id_number_label',
                  });
                  const claimLookup = _.find($scope.orgDetails.settings, {
                    setting_name: 'claim_lookup',
                  });
                  const payer = _.find($scope.orgDetails.settings, {
                    setting_name: 'payer',
                  });
                  const availabilityMatrix = $scope.orgDetails.settings.find(setting => setting.setting_name === featureFlags.ZIPCODE_AVAILABILITY);
                  const fileNumberRequired = $scope.orgDetails.settings.find(setting => setting.setting_name === 'file_number_required');
                  const isInviteRiderActive = $scope.orgDetails.settings.find(setting => setting.setting_name === 'invited_riders');
                  if (futureLookup && futureLookup.setting_value.toLowerCase() === 'true') {
                    $scope.orgDetails.future_lookup = true;
                  } else {
                    $scope.orgDetails.future_lookup = false;
                  }
                  if (claimLookup && claimLookup.setting_value.toLowerCase() === 'true') {
                    $scope.orgDetails.claim_lookup = true;
                  } else {
                    $scope.orgDetails.claim_lookup = false;
                  }
                  $scope.orgDetails.customized_id_number = (customizedIDNumber && customizedIDNumber.setting_value.toLowerCase() === 'true');
                  if (customizedIDNumberLabel && customizedIDNumberLabel.setting_value) {
                    $scope.orgDetails.customized_id_number_label = customizedIDNumberLabel.setting_value;
                  }
                  if (accountLookup && accountLookup.setting_value.toLowerCase() === 'true') {
                    $scope.orgDetails.account_lookup = true;
                  } else if (accountLookup && accountLookup.setting_value.toLowerCase() === 'false') {
                    $scope.orgDetails.account_lookup = false;
                  } else {
                    $scope.orgDetails.account_lookup = true;
                  }
                  $scope.orgDetails.availabilityMatrix = (availabilityMatrix && JSON.parse(availabilityMatrix.setting_value));
                  $scope.orgDetails.fileNumberRequired = (fileNumberRequired && JSON.parse(fileNumberRequired.setting_value));
                  if (payer && payer.setting_value.toLowerCase() === 'true') {
                    $scope.orgDetails.payer = true;
                  } else {
                    $scope.orgDetails.payer = false;
                  }
                  $scope.orgDetails.invited_riders = isInviteRiderActive && JSON.parse(isInviteRiderActive.setting_value);
                  $scope.isInviteRiderActivated = isInviteRiderActive && JSON.parse(isInviteRiderActive.setting_value);
                  if (autoPaySetting && autoPaySetting.setting_value.toLowerCase() === 'true') {
                    $scope.orgDetails.auto_pay = true;
                  } else {
                    $scope.orgDetails.auto_pay = false;
                  }
                  $scope.orgDetails.auto_pay_date = (autoPayDateSetting && Number(autoPayDateSetting.setting_value)) || 8;
                  if (oldDashboardSetting && oldDashboardSetting.setting_value.toLowerCase() === 'true') {
                    $scope.orgDetails.old_dashboard = true;
                  } else {
                    $scope.orgDetails.old_dashboard = false;
                  }
                  if (pickupTimeSetting && pickupTimeSetting.setting_value.indexOf('pickup') >= 0) {
                    $scope.orgDetails.pickup_time_allowed = true;
                  } else {
                    $scope.orgDetails.pickup_time_allowed = false;
                  }

                  if (tripTypeSetting && tripTypeSetting.setting_value) {
                    $scope.orgDetails.trip_type = Number(tripTypeSetting.setting_value);
                  }

                  const payInvoiceIndexInArray = findIndexOfOrgSetting($scope.orgDetails.settings, 'pay_invoice');
                  if (payInvoiceIndexInArray !== -1 && $scope.orgDetails.settings[payInvoiceIndexInArray].setting_value.toLowerCase() === 'true') {
                    $scope.orgDetails.pay_invoice = true;
                  } else {
                    $scope.orgDetails.pay_invoice = false;
                  }
                  const payInvoiceSettingValue = getMarketSegementSetting($scope.orgDetails.market_segment, 'pay_invoice');
                  if (payInvoiceSettingValue && payInvoiceSettingValue.toLowerCase() === 'true') {
                    $scope.orgDetails.market_segment.pay_invoice = true;
                  } else {
                    $scope.orgDetails.market_segment.pay_invoice = false;
                  }
                  if (cdiRateSetting) {
                    $scope.orgDetails.cdi_rate = +cdiRateSetting.setting_value || 0;
                  }
                  if (aslRateSetting) {
                    $scope.orgDetails.asl_rate = +aslRateSetting.setting_value || 0;
                  }
                  if (spokenEnglishRateSetting) {
                    $scope.orgDetails.spoken_english_rate = +spokenEnglishRateSetting.setting_value || 0;
                  }
                  if (audioRolloverRateSetting) {
                    $scope.orgDetails.audio_rollover_rate = +audioRolloverRateSetting.setting_value || 0;
                  }
                  if (devicesSetting) {
                    $scope.orgDetails.devices = (devicesSetting.setting_value && devicesSetting.setting_value.split(',')) || '';
                  }
                  if (steerageSetting && steerageSetting.setting_value.toLowerCase() === 'true') {
                    $scope.orgDetails.steerage = true;
                  } else {
                    $scope.orgDetails.steerage = false;
                  }
                } else {
                  $scope.orgDetails.stratus_access = false;
                  $scope.orgDetails.pay_invoice = false;
                  if ($scope.orgDetails.market_segment) $scope.orgDetails.market_segment.pay_invoice = false;
                  $scope.orgDetails.pickup_time_allowed = false;
                  $scope.orgDetails.steerage = false;
                }

                // set defaults to org_providers
                decideDefaultOrgProvider();


                $scope.getOrgTripSetting();
                populateOrgSettings();
                if (permissionFilter($scope.permissionsConstants.SHOW_MILEAGE_LIMIT_SETTINGS)) {
                  const mileageLimitType = _.find($scope.orgDetails.settings, {
                    setting_name: 'mileage_limit_type',
                  });
                  const mileageLimitValue = _.find($scope.orgDetails.settings, {
                    setting_name: 'mileage_limit_value',
                  });
                  const mileageLimitEnabled = getSetting('mileage_limit_enabled');
                  Object.assign($scope.orgDetails, {
                    mileage_limit_type: mileageLimitType && mileageLimitType.setting_value,
                    mileage_limit_enabled: mileageLimitEnabled,
                    mileage_limit_value: mileageLimitValue && mileageLimitValue.setting_value,
                  });
                  if (!mileageLimitEnabled) {
                    populateAncestorMileageLimitOrgSettings();
                  }
                }
                const addressLimitEnabled = getSetting('address_limit_enabled');
                const addressLimitType = _.find($scope.orgDetails.settings, {
                  setting_name: 'address_limit_type',
                });
                const allowAddressLimitModule = getSetting('allow_address_limit_module');
                Object.assign($scope.orgDetails, {
                  address_limit_type: addressLimitType && addressLimitType.setting_value,
                  address_limit_enabled: addressLimitEnabled,
                  allow_address_limit_module: allowAddressLimitModule,
                });
                if ($scope.marketSegmentList.length) {
                  $scope.marketSegmentChanged();
                }

                switch ($scope.orgDetails.trip_type) {
                  case $scope.tripTypeConstants.BOTH_ACTIVE:
                    Object.assign($scope.orgDetails, {
                      scheduleSelected: true,
                      ondemandSelected: true,
                    });
                    break;
                  case $scope.tripTypeConstants.SCHEDULE_ACTIVE:
                    Object.assign($scope.orgDetails, {
                      scheduleSelected: true,
                      ondemandSelected: false,
                    });
                    break;
                  case $scope.tripTypeConstants.ON_DEMAND_ACTIVE:
                    Object.assign($scope.orgDetails, {
                      scheduleSelected: false,
                      ondemandSelected: true,
                    });
                    break;
                  default:
                    Object.assign($scope.orgDetails, {
                      scheduleSelected: false,
                      ondemandSelected: false,
                    });
                }

                orgDetails = angular.copy($scope.orgDetails);

                const el = angular.element(document.getElementById('tin'));

                if (el) {
                  const newInjector = el.injector();
                  if (newInjector) {
                    newInjector.invoke([
                      '$compile',
                      ($compile) => {
                        $compile(el)($scope);
                      },
                    ]);
                  }
                }

                if (orgDetails.eligableOrgHierarchyParent || orgDetails.children.length) {
                  return getEligibleChildrenOrganisations(orgId);
                }
              } else {
                showToaster('error', result.data ? result.data.message : 'Could not get Organization details', 'Could not get Organization details');
              }
              return true;
            })
            .catch((error) => {
              showToaster('error', error.data ? error.data.message : 'Could not get Organization details', 'Could not get Organization details');
            });
        }
        $state.go('main.userMgmt.orgList');
        return true;
      }
      $scope.verifyAccount = (accountDetails) => {
        const body = {
          amounts: [$scope.micro_deposits.amount_one, $scope.micro_deposits.amount_two],
        };
        loadingScreenFactory.showLoadingScreen();
        $http
          .post(`${API_BASE_URL}paymentProfiles/${accountDetails.id}/bankaccount/verify`, body)
          .then((response) => {
            if (response.data.success) {
              $scope.micro_deposits = {};
              SweetAlert.swal(
                {
                  title: 'Bank Account verified',
                  text: 'Bank Account verified successfully',
                  type: 'success',
                  confirmButtonColor: '#DD6B55',
                  confirmButtonText: 'OK',
                },
                () => {
                  getPaymentProfiles(orgId);
                  $scope.modalShow('', true, null);
                },
              );
            } else {
              $scope.micro_deposits = {};
              toaster.pop({
                type: 'error',
                title: response.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
            }
            loadingScreenFactory.hideLoadingScreen();
          })
          .catch((errorResult) => {
            $scope.micro_deposits = {};
            toaster.pop({
              type: 'error',
              title: errorResult.message || (errorResult.data && errorResult.data.message) || '',
              showCloseButton: true,
              timeout: 10000,
            });
            loadingScreenFactory.hideLoadingScreen();
          });
      };
      /**
       * Generate Key Function gives keypair and that can be downloaded
       */
      $scope.generateKey = () => {
        const organID = parseInt(orgId, 10);
        loadingScreenFactory.showLoadingScreen();
        $scope.isLoading = true;
        $http({
          url: `${API_BASE_URL}apitoken`,
          method: 'POST',
          data: {
            org_id: organID,
          },
        }).then(
          (result) => {
            loadingScreenFactory.hideLoadingScreen();
            $scope.isLoading = false;
            if (result.data.success) {
              toaster.pop({
                type: 'success',
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
              $scope.generateData = {
                pKey: result.data.data.secret,
                keyID: result.data.data.id,
              };
              getApiKeys();
            } else {
              loadingScreenFactory.hideLoadingScreen();
              $scope.isLoading = false;
              toaster.pop({
                type: 'error',
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
            }
          },
          (error) => {
            loadingScreenFactory.hideLoadingScreen();
            $scope.isLoading = false;
            toaster.pop({
              type: 'error',
              title: error.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          },
        );
      };
      /**
       * This is the one Deciding whether a org is permitted for third party integration and to what extend
       */
      let priorExternalApiData;
      $scope.updateOrgApiUser = () => {
        const dataObj = {
          apiuser_count: $scope.apiuserOrgSettings.apiuser_count,
        };
        if ($scope.apiuserOrgSettings.webhook_url || $scope.apiuserOrgSettings.secret) {
          if ($scope.apiuserOrgSettings.webhook_url && $scope.apiuserOrgSettings.secret) {
            dataObj.webhook_url = $scope.apiuserOrgSettings.webhook_url;
            dataObj.secret = $scope.apiuserOrgSettings.secret;
          } else {
            return toaster.pop({
              type: 'error',
              title: 'Webhook url and secret are required here',
              showCloseButton: true,
              timeout: 10000,
            });
          }
        }
        if ($scope.apiuserOrgSettings.third_party_orgname) {
          dataObj.org_key = $scope.apiuserOrgSettings.third_party_org_key;
        }
        dataObj.status = $scope.apiuserOrgSettings.third_party_status;
        dataObj.is_third_party = $scope.apiuserOrgSettings.is_third_party;

        return $http({
          url: `${API_BASE_URL}organisation/apiuser/settings`,
          method: 'PATCH',
          params: {
            org_id: orgId,
          },
          data: dataObj,
        }).then(
          (result) => {
            if (result.data.success) {
              SweetAlert.swal(
                {
                  title: 'Changes Saved',
                  text: result.data.message,
                  type: 'success',
                  confirmButtonColor: '#DD6B55',
                  confirmButtonText: 'OK',
                  closeOnConfirm: true,
                },
                (isConfirm) => {
                  if (isConfirm) getOrgDetailsById();
                  $scope.disableExternalApiForm = true;
                  priorExternalApiData = angular.copy($scope.apiuserOrgSettings);
                },
              );
            } else {
              toaster.pop({
                type: 'error',
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
            }
          },
          (error) => {
            toaster.pop({
              type: 'error',
              title: error.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          },
        );
      };
      /**
       * Once disabled we cannot re-enable a key
       * @param {*public key id to be disabled} key
       */
      $scope.disableKey = (key) => {
        const organID = parseInt(orgId, 10);
        $http({
          url: `${API_BASE_URL}apitoken/deactive`,
          method: 'PATCH',
          data: {
            org_id: organID,
            key_id: key,
          },
        }).then(
          (result) => {
            if (result.data.success) {
              toaster.pop({
                type: 'success',
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
              getApiKeys();
            } else {
              toaster.pop({
                type: 'error',
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
            }
          },
          (error) => {
            toaster.pop({
              type: 'error',
              title: error.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          },
        );
      };
      /**
       * Function called upon downloading the private key
       */
      $scope.downloadPrivate = () => {
        const filename = `secret+${Date.now()}.pem`;
        const blob = new window.Blob([$scope.generateData.pKey], {
          type: 'text/text',
        });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          const e = document.createEvent('MouseEvents');

          const a = document.createElement('a');
          a.download = filename;
          a.href = window.URL.createObjectURL(blob);
          a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
          e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
          a.dispatchEvent(e);
          // window.URL.revokeObjectURL(url); // clean the url.createObjectURL resource
        }
      };
      /**
       * This function is one to format the public key before adding to file
       * This is a dormant function at the moment
       * @param {*public key cert to be formatted} cert
       */
      $scope.convertCertificate = (certificate) => {
        let cert = certificate;
        // Certificate must be in this specific format or else the function won't accept it
        const beginCert = '-----BEGINPUBLICKEY-----';
        const endCert = '-----ENDPUBLICKEY-----';
        cert = cert.replace('\n', '');
        cert = cert.replace(beginCert, '');
        cert = cert.replace(endCert, '');
        let result = '-----BEGIN PUBLIC KEY-----';
        while (cert.length > 0) {
          if (cert.length > 64) {
            result += `\n${cert.substring(0, 64)}`;
            cert = cert.substring(64, cert.length);
          } else {
            result += `\n${cert}`;
            cert = '';
          }
        }
        if (result[result.length] !== '\n') result += '\n';
        result += '-----END PUBLIC KEY----- \n';
        return result;
      };
      /**
       * This function downloads the public key
       * @param {*secret is the public key we are to download} secret
       */
      $scope.downloadPublicKey = (secret) => {
        const pubName = `key+${Date.now()}.pub`;
        //  var pubVal = $scope.convertCertificate(secret)
        const blob = new window.Blob([secret], {
          type: 'text/text',
        });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, pubName);
        } else {
          const e = document.createEvent('MouseEvents');

          const a = document.createElement('a');
          a.download = pubName;
          a.href = window.URL.createObjectURL(blob);
          a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
          e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
          a.dispatchEvent(e);
          // window.URL.revokeObjectURL(url); // clean the url.createObjectURL resource
        }
      };
      $scope.marketSegmentList = [];
      const getMarketSegments = function getMarketSegments() {
        const getMarketSegmentsCall = $http({
          url: `${API_BASE_URL}marketSegments`,
          method: 'GET',
        });
        getMarketSegmentsCall.then(
          (result) => {
            if (result.data.success) {
              $scope.marketSegmentList = result.data.data;
              let i;
              let payInvoiceIndx;
              for (i = 0; i < $scope.marketSegmentList.length; i += 1) {
                if ($scope.marketSegmentList[i].name === 'Mass Market') {
                  payInvoiceIndx = findIndexOfOrgSetting($scope.marketSegmentList[i].settings, 'pay_invoice');
                  if (payInvoiceIndx !== -1 && $scope.marketSegmentList[i].settings[payInvoiceIndx].setting_value.toLowerCase() === 'true') {
                    $scope.marketSegmentList[i].pay_invoice = true;
                  } else {
                    $scope.marketSegmentList[i].pay_invoice = false;
                  }
                }
              }
              if ($scope.orgDetails) {
                $scope.marketSegmentChanged();
              }
            } else {
              toaster.pop({
                type: 'error',
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
            }
          },
          (error) => {
            toaster.pop({
              type: 'error',
              title: error.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          },
        );
      };
      $scope.getMarketSegementSetting = getMarketSegementSetting;
      getMarketSegments();
      // (if edit button clicked and role is correct do following)
      if (
        editToggle
        && $scope.userDetails
        && ($scope.userDetails.role === 'mastersuperadmin'
          || $scope.userDetails.role === 'riderAdmin'
          || $scope.userDetails.role === 'org_superadmin'
          || permissionFilter($scope.permissionsConstants.ORG_UPDATE))
      ) {
        $scope.disableForm = false;
        $scope.disableSSOForm = false;
        $scope.disableExternalApiForm = false;
      }
      $scope.accessTypeDisabled = $scope.disableForm || !permissionFilter(relaylib.permissions.permissionsConstants.SHOW_ACCESS_TYPES);
      // do end
      $scope.billing = {};
      $scope.state = {
        billingAddress: {
          state: {},
        },
        monthlyInvoice: false,
        signupType: 'business',
        userId: $scope.userDetails.user_id,
        businessInfo: {},
        personalInfo: {},
      };
      const getEmailList = function getEmailList() {
        if (orgId) {
          $http
            .get(`${API_BASE_URL}organisation/${orgId}/emails`)
            .then((result) => {
              if (result.data.success) {
                $scope.emailListWithId = result.data.emailList;
                $scope.emailList = [noEmailSelectedString];
                $scope.emailList = $scope.emailList.concat($scope.emailListWithId.reduce((filtered, email) => {
                  // this code filters out null and repeating email values from emailListWithId array
                  if (!!email.username && filtered.indexOf(email.username) === -1) {
                    filtered.push(email.username);
                  }
                  return filtered;
                }, []));
              } else {
                showToaster('error', result.data ? result.data.message : 'Could not Fetch emails', 'Could not Fetch emails');
              }
            })
            .catch((error) => {
              showToaster('error', error.data ? error.data.message : 'Could not Fetch emails', 'Could not Fetch emails');
            });
        } else {
          $state.go('main.userMgmt.orgList');
        }
      };
      getEmailList();
      $scope.mainContactEmailChanged = function mainContactEmailChanged(email) {
        if (!email) {
          return false;
        }
        if (email === noEmailSelectedString) {
          Object.assign($scope.orgDetails.user, {
            first_name: undefined,
            last_name: undefined,
          });
          return true;
        }
        const mainContact = _.find($scope.emailListWithId, {
          username: email,
        }) || {};
        $scope.disableSaveButton = true;
        $scope.orgDetails.main_contact_id = mainContact.id;
        $http
          .get(`${API_BASE_URL}user/details-by-email/${email}`)
          .then((result) => {
            const { data } = result;
            if (data.success) {
              const { userDetails } = data;
              $scope.orgDetails.user.first_name = userDetails.first_name;
              $scope.orgDetails.user.last_name = userDetails.last_name;
              const { phone } = $scope.orgDetails;
              if (phone) {
                $timeout(() => {
                  angular.element('#orgContactPhone').trigger('phone-number-changed', phone);
                });
              }
            } else {
              showToaster('error', result.data ? result.data.message : 'Could not fetch user details', 'Could not fetch user details');
            }
          })
          .catch((error) => {
            showToaster('error', error.data ? error.data.message : 'Could not fetch user details', 'Could not fetch user details');
          })
          .finally(() => {
            $scope.disableSaveButton = false;
          });
        return true;
      };
      $scope.resetChildrenOrgs = function resetChildrenOrgs() {
        $scope.selectedChildOrgs.selected = angular.copy($scope.initialSelectedChildOrgs);
      };
      $scope.getOrgTripSetting = () => {
        if ($scope.orgDetails && $scope.orgDetails.trip_type === undefined) {
          const orgAllDetails = angular.copy($scope.orgDetails);
          const orgAncestors = orgAllDetails.ancestors;
          const ancestorsLength = orgAncestors.length - 1;
          for (let i = ancestorsLength; i >= 0; i -= 1) {
            if (orgAncestors[i].settings.length > 0) {
              const tripTypeSetting = _.find(orgAncestors[i].settings, {
                setting_name: 'trip_type',
              });
              if (tripTypeSetting && tripTypeSetting.setting_value) {
                $scope.orgDetails.trip_type = Number(tripTypeSetting.setting_value);
                break;
              }
            }
          }
        }
      };

      getOrgDetailsById();
      $http
        .get(`${API_BASE_URL}states`)
        .then((result) => {
          if (result.data.success) {
            result.data.states.unshift({
              id: 0,
              state_name: 'Select State',
            });
            $scope.states = result.data.states;
          } else {
            showToaster('error', result.data ? result.data.message : 'Could not get State data', 'Could not get State data');
          }
        })
        .catch((error) => {
          showToaster('error', error.data ? error.data.message : 'Could not get State data', 'Could not get State data');
        });
      $http
        .get(`${API_BASE_URL}paymentProfileTypes`)
        .then((result) => {
          if (result.data.success) {
            $scope.paymentProfileTypes = result.data.data;
          } else {
            showToaster('error', result.data ? result.data.message : 'Could not get payment profiles data', 'Could not get payment profiles data');
          }
        })
        .catch((error) => {
          showToaster('error', error.data ? error.data.message : 'Could not get payment profiles data', 'Could not get payment profiles data');
        });
      $scope.updateQuestionnairesSettings = () => {
        if ($scope.orgDetails.questionnaire_active) {
          loadingScreenFactory.showLoadingScreen();
          $http.get(`${API_BASE_URL}organisation/getActiveQuestionnaireForOrg/${$scope.orgDetails.id}`).then((result) => {
            if (result.data.success) {
              $scope.activeQuestionnaire = result.data.activeQuestionnaire;
              if (result.data.activeQuestionnaire) {
                return;
              }
              $scope.orgDetails.questionnaire_active = false;
              showToaster('error', 'Please create new or turn ON existing questionnaire to enable this feature.');
            } else {
              $scope.orgDetails.questionnaire_active = false;
              showToaster('error', result.data ? result.data.message : 'Could not update Questionnaire settings', 'Could not update Questionnaire settings');
            }
          }).catch((error) => {
            $scope.orgDetails.questionnaire_active = false;
            showToaster('error', (error.message || 'Could not update Questionnaire settings'), 'Could not update Questionnaire settings');
          }).finally(() => {
            loadingScreenFactory.hideLoadingScreen();
          });
        }
      };

      $scope.updateStratusAccessSetting = (bool) => {
        $scope.orgDetails.stratus_access = bool;
      };
      $scope.editDetailsClicked = () => {
        $scope.accessTypeDisabled = !permissionFilter(relaylib.permissions.permissionsConstants.SHOW_ACCESS_TYPES);
        $scope.disableForm = false;
        editToggle = true;
      };
      $scope.editPaymentDetailsClicked = () => {
        $scope.billing.prior_billing_method = $scope.billing.billing_method;
        $scope.disablePaymentForm = false;
        $scope.disableFromOrgTab = false;
      };
      function customInjectorCompiler(el) {
        if (el) {
          const injector = el.injector();
          if (injector) {
            injector.invoke([
              '$compile',
              ($compile) => {
                $compile(el)($scope);
              },
            ]);
          }
        }
      }

      $scope.cancelClicked = () => {
        // (if cancel button clicked and role is correct do following)
        if (
          editToggle
          && $scope.userDetails
          && ($scope.userDetails.role === 'mastersuperadmin'
            || $scope.userDetails.role === 'riderAdmin'
            || $scope.userDetails.role === 'org_superadmin'
            || permissionFilter($scope.permissionsConstants.ORG_READ))
        ) {
          $window.history.back();
        } else {
          $scope.orgDetails = angular.copy(orgDetails);
          $scope.resetChildrenOrgs();
          $scope.state_id = 0;
          $scope.forms.orgEdit.submitted = false;
          const el = angular.element(document.getElementById('tin'));
          customInjectorCompiler(el);
        }
        // do end
      };
      // clear the addOrg object's value
      // as the user has cancelled adding a child organization

      const resetAddChildOrgForm = function resetAddChildOrgForm() {
        $scope.addOrg = { access_types: [], name: null, mainContact: { firstName: null, lastName: null } };
        $scope.showAddChildOrg = false;
        $scope.childAccessTypeRequired = false;
        $scope.addOrgForm.$setPristine();
        $scope.addOrgForm.$setUntouched();
      };

      $scope.cancelAddChildOrg = () => {
        resetAddChildOrgForm();
      };

      $scope.paymentTabCancelClicked = () => {
        if ($scope.billing.prior_billing_method) {
          $scope.billing.billing_method = $scope.billing.prior_billing_method;
        }
        $scope.disablePaymentForm = true;
      };

      const deleteOrg = async () => {
        loadingScreenFactory.showLoadingScreen();
        try {
          await $http.put(`${API_BASE_URL}organisation/toggleActivation/${orgId}`, {
            is_del: 1,
          });
          SweetAlert.swal(
            {
              title: 'Suspended',
              text: 'Organization suspended successfully',
              type: 'success',
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'OK',
              closeOnConfirm: true,
            },
            (isConfirm) => {
              if (isConfirm) {
                $state.go('main.userMgmt.orgList');
              }
            },
          );
        } catch (error) {
          showToaster('error', error.data ? error.data.message : 'Could not delete organization', 'Could not delete organization');
        } finally {
          loadingScreenFactory.hideLoadingScreen();
        }
      };

      $scope.activateOrg = () => {
        const activateOrgCall = $http.put(`${API_BASE_URL}organisation/toggleActivation/${orgId}`, {
          is_del: 0,
        });
        loadingScreenFactory.showLoadingScreen();
        activateOrgCall
          .then(() => {
            loadingScreenFactory.hideLoadingScreen();
            SweetAlert.swal(
              {
                title: 'Activated',
                text: 'Organization activated successfully',
                type: 'success',
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'OK',
                closeOnConfirm: true,
              },
              (isConfirm) => {
                if (isConfirm) $state.go('main.userMgmt.orgList');
              },
            );
          })
          .catch((error) => {
            loadingScreenFactory.hideLoadingScreen();
            showToaster('error', error.data ? error.data.message : 'Could not activate organization', 'Could not activate organization');
          });
      };
      $scope.deleteOrg = () => {
        SweetAlert.swal(
          {
            title: 'Are you sure ?',
            text: 'Are you sure you want to suspend this Organization?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, suspend it!',
            closeOnConfirm: true,
            closeOnCancel: true,
          },
          (isConfirm) => {
            // in some cases the second sweetalert was not fired or sometimes
            // was fired but did not provide opportunity to the user to click the "OK" button
            // due to which the user was not being redirected.
            // now due to timeout the user is shown the second sweetalert and on clicking the
            // "OK" button the user is redirected to the org list page.
            $timeout(() => {
              if (isConfirm) deleteOrg();
            }, 100);
          },
        );
      };
      $scope.disableFromOrgTab = false;
      $scope.updateMethodOfPayment = function updateMethodOfPayment() {
        loadingScreenFactory.showLoadingScreen();
        const serverData = {
          billing_method: $scope.billing.billing_method,
        };
        $http
          .put(`${API_BASE_URL}organisation/billing-method/${$scope.orgDetails.id}`, serverData)
          .then((result) => {
            if (result.data.success) {
              SweetAlert.swal({
                title: 'Changes Saved',
                text: 'Method of payment edited successfully',
                type: 'success',
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'OK',
                closeOnConfirm: true,
              });
            } else {
              showToaster('error', result.data ? result.data.message : 'Could not update billing method', 'Could not update billing method');
            }
          })
          .catch((error) => {
            showToaster('error', error.data ? error.data.message : 'Could not update billing method', 'Could not update billing method');
            if ($scope.billing.prior_billing_method) {
              $scope.billing.billing_method = $scope.billing.prior_billing_method;
            }
          })
          .finally(() => {
            loadingScreenFactory.hideLoadingScreen();
            $scope.disablePaymentForm = true;
          });
      };
      const getAddressComponents = (place, setterPincodeProperty) => {
        let pincode;
        let country;
        let state;
        let city;
        place.address_components.forEach((obj) => {
          if (obj.types.indexOf('country') !== -1) {
            country = obj.long_name;
            $scope.addressObj.country = country;
          }
          if (obj.types.indexOf('administrative_area_level_1') !== -1) {
            state = obj.short_name; // In order to apply check on state Code in DB.
            $scope.addressObj.state_name = state;
          }
          if (obj.types.indexOf('locality') !== -1) {
            city = obj.long_name;
            $scope.addressObj.city = city;
          }
          if (obj.types.indexOf('postal_code') !== -1) {
            pincode = obj.short_name;
            $scope.addressObj.zip = pincode;
            $scope.orgDetails.physical_address = Object.assign($scope.orgDetails.physical_address, $scope.addressObj);
          }
        });
        if (pincode) {
          $scope.orgDetails.physical_address[setterPincodeProperty] = pincode;
          $scope.addressObj.zip = pincode;
          $scope.orgDetails.physical_address = Object.assign($scope.orgDetails.physical_address, $scope.addressObj);
          return;
        }
        const latlng = {
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        };
        googleService.geocode(latlng).then((results) => {
          for (let i = 0; i < results.length; i += 1) {
            for (let j = 0; j < results[i].address_components.length; j += 1) {
              const obj = results[i].address_components[j];
              if (obj.types.indexOf('postal_code') !== -1) {
                pincode = obj.short_name;
                $scope.addressObj.zip = pincode;
                $scope.orgDetails.physical_address = Object.assign($scope.orgDetails.physical_address, $scope.addressObj);
                break;
              }
            }

            if (typeof pincode !== 'undefined') {
              break;
            }
          }
        });
      };
      const setValidatedAddressDetails = function setValidatedAddressDetails(tag, addressType, addressDetails) {
        const addresss = {};
        addresss.tag = tag;
        addresss.address_type = addressType;
        addresss.place_id = addressDetails.place_id;
        // addresss.address = addressDetails.formatted_address;
        addresss.address = `${$scope.orgDetails.physical_address.completeAddress}, ${$scope.orgDetails.physical_address.zip}`;
        addresss.short_address = addressDetails.formatted_address;
        addresss.latitude = addressDetails.geometry.location.lat();
        addresss.longitude = addressDetails.geometry.location.lng();
        $scope.orgDetails.validatedAddress = addresss;
        $scope.$apply();
      };
      const setOrgAddressDetails = function setOrgAddressDetails(addressDetails) {
        const Addresss = {};
        Addresss.address1 = addressDetails.name;
        Addresss.lat = addressDetails.geometry.location.lat();
        Addresss.lng = addressDetails.geometry.location.lng();
        // Addresss.completeAddress = addressDetails.formatted_address;
        $scope.orgDetails.physical_address = Object.assign($scope.orgDetails.physical_address, Addresss);
        $scope.$apply();
        setValidatedAddressDetails('orgAddress', 'org', addressDetails);
      };
      $scope.setAddressInvalid = function setAddressInvalid() {
        // To reduce the chances of modal updation.
        $timeout(() => {
          let ngChangeTriggered = true;
          if (isAddressSelectedFromSuggestions) {
            ngChangeTriggered = false;
            isAddressSelectedFromSuggestions = false;
          }
          if (ngChangeTriggered) {
            $scope.addressObj.zip = null;
            if ($scope.orgDetails.physical_address.completeAddress) {
              $scope.forms.orgEdit.completeAddress.$setValidity('valid', false);
            } else { $scope.forms.orgEdit.completeAddress.$setValidity('valid', true); }
          } else {
            $scope.forms.orgEdit.completeAddress.$setValidity('valid', true);
          }
        }, 1000);
      };
      $scope.addressChanged = async function addressChanged() {
        let addressInfo = this.getPlace();
        isAddressSelectedFromSuggestions = true;
        if (
          typeof addressInfo.address_components === 'undefined'
          && typeof addressInfo.geometry === 'undefined'
        ) {
          const result = await parseAddressString(addressInfo.name);
          [addressInfo] = result;
          [addressInfo.name] = (addressInfo.formatted_address && addressInfo.formatted_address.split(',')) || [''];
          getAddressComponents(
            addressInfo,
            'zip',
          );
        } else {
          getAddressComponents(
            addressInfo,
            'zip',
          );
        }
        setOrgAddressDetails(addressInfo);
        $scope.forms.orgEdit.completeAddress.$setValidity('valid', true);
      };
      $scope.addOnAddressEnter = async (event) => {
        let addressInfo;
        if (event.keyCode === 13) {
          const result = await parseAddressString($scope.orgDetails.physical_address.completeAddress);
          [addressInfo] = result;
          getAddressComponents(
            addressInfo,
            'zip',
          );
          setOrgAddressDetails(addressInfo);
        }
      };

      $scope.saveChangesClicked = async function saveChangesClicked() {
        const uploadImageComponent = document.getElementById('logoUpload');
        if ($scope.forms.orgEdit && $scope.forms.orgEdit.$valid) {
          const addressScope = $scope.orgDetails.physical_address ? $scope.orgDetails.physical_address : {};
          if (!$scope.orgDetails.user && $scope.mainContactRequired) {
            SweetAlert.swal({
              title: 'Main Contact not found',
              text: 'Please add a main contact to your organisation before making changes to it',
              type: 'error',
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'OK',
              closeOnConfirm: true,
            });
            return false;
          }
          if (addressScope.state) {
            delete addressScope.state;
          }
          let children = [];
          if (orgDetails.eligableOrgHierarchyParent || $scope.selectedChildOrgs.selected.length) {
            children = $scope.selectedChildOrgs.selected;
          }
          if (!$scope.orgDetails.access_types.length) {
            $scope.accessTypeRequired = true;
          } else {
            $scope.accessTypeRequired = false;
          }
          if (!$scope.orgDetails.selectedProviders.length) {
            showToaster('error', 'At least one provider should be selected');
            return false;
          }
          const orgProviders = [];
          const vendorPreferenceOrder = [];
          $scope.orgDetails.selectedProviders.forEach((provider) => {
            vendorPreferenceOrder.push(String(provider.id));
            orgProviders.push({
              name: provider.label.toLowerCase(),
              tag: $scope.orgDetails[`${provider.label.toLowerCase()}Tag`],
            });
          });
          // if (vendorPreferenceOrder.includes('4') && vendorPreferenceOrder[vendorPreferenceOrder.length - 1] !== '4') {
          //   const traditionalProviderIndex = arrayObjectIndexOf($scope.orgDetails.selectedProviders, 4);
          //   const traditionalProvider = $scope.orgDetails.selectedProviders[traditionalProviderIndex];
          //   $scope.orgDetails.selectedProviders.splice(traditionalProviderIndex, 1);
          //   $scope.orgDetails.selectedProviders.push(traditionalProvider);
          //   SweetAlert.swal({
          //     title: 'Error',
          //     text: 'Rideshare Providers are preferred over Traditional providers.',
          //     type: 'error',
          //     confirmButtonColor: '#DD6B55',
          //     confirmButtonText: 'OK',
          //     closeOnConfirm: true,
          //   });
          //   return false;
          // }
          let tripType = 0;
          if ($scope.orgDetails.scheduleSelected) {
            tripType += $scope.tripTypeConstants.SCHEDULE_ACTIVE;
          }
          if ($scope.orgDetails.ondemandSelected) {
            tripType += $scope.tripTypeConstants.ON_DEMAND_ACTIVE;
          }
          let isModeValueEmpty = false;
          const feeDetails = {};
          Object.values(costType).every((type) => {
            const deepCloneOrgDetails = _.cloneDeep($scope.orgDetails[type]);
            const { currentModeValue = {}, current_fee_type: currentFeeType, current_mode_type: currentModeType } = deepCloneOrgDetails;
            deepCloneOrgDetails.current_mode_value = [];
            if (currentFeeType) {
              Object.keys(currentModeValue)
                .forEach((feeType) => {
                  if (feeType === currentFeeType) {
                    const feeDetail = currentModeValue[feeType];
                    Object.keys(feeDetail)
                      .forEach((key) => {
                        feeDetail[key].rate = Number.isNaN(feeDetail[key].rate) ? 0 : Number(feeDetail[key].rate);
                        if (currentModeType === modeType.ALL_MODE && feeDetail[key].code === 0) {
                          delete feeDetail[key].code;
                          deepCloneOrgDetails.current_mode_value.push(feeDetail[key]);
                        } else if (currentModeType === modeType.PER_MODE && feeDetail[key].code !== 0) {
                          deepCloneOrgDetails.current_mode_value.push(feeDetail[key]);
                        }
                      });
                  }
                });
              if (!deepCloneOrgDetails.current_mode_value.length && $scope.modeTypeEnabled[type].includes(currentFeeType)) {
                isModeValueEmpty = true;
                return false;
              }
            }
            feeDetails[type] = deepCloneOrgDetails;
            return true;
          });
          if (isModeValueEmpty) {
            showToaster('error', 'At least one mode type cost is required', '');
            return false;
          }
          if ($scope.orgDetails.allow_address_limit_module && $scope.orgDetails.address_limit_enabled) {
            // throws error if address is not valid or not exist in db
            if (!$scope.orgDetails.validatedAddress && !$scope.orgDetails.physical_address.completeAddress) {
              showToaster('error', 'Address Limit Setting is On, Organization Address is not a valid Address. Please Select valid Organization Address or Disable the Address Settings.', '');
              return false;
            }
          }
          const mainContactEmail = get($scope.orgDetails, 'user.username');
          const serverData = {
            name: $scope.orgDetails.name,
            children,
            firstName: get($scope.orgDetails, 'user.first_name', null),
            lastName: get($scope.orgDetails, 'user.last_name', null),
            mainContactEmail: mainContactEmail && mainContactEmail !== noEmailSelectedString ? mainContactEmail : null,
            orgContactPhone: $scope.orgDetails.phone ? angular.element('#orgContactPhone').intlTelInput('getNumber', window.intlTelInputUtils.numberFormat.E164) : null,
            pr_status: $scope.orgDetails.pr_status,
            rate_per_mile: $scope.orgDetails.rate_per_mile,
            org_providers: orgProviders,
            fee_details: feeDetails,
            addresses: addressScope,
            client_id: $scope.orgDetails.client_id,
            parent_org_id: $scope.orgDetails.parent_org_id,
            special_ride: $scope.orgDetails.special_ride,
            customizable_file_number: $scope.orgDetails.customizable_file_number,
            file_number_label: $scope.orgDetails.file_number_label,
            ride_invoice_history: $scope.orgDetails.ride_invoice_history,
            currentMainContactId: $scope.currentMainContactId,
            questionnaire_active: $scope.orgDetails.questionnaire_active,
            stratus_access: $scope.orgDetails.stratus_access,
            pickup_time_allowed: $scope.orgDetails.pickup_time_allowed,
            tin: $scope.orgDetails.tin ? $scope.orgDetails.tin.replace(/[- )(]/g, '') : '',
            org_class: $scope.orgDetails.org_class,
            market_segment_id: $scope.orgDetails.market_segment_id,
            market_segment_key: ($scope.orgDetails.market_segment || {}).key,
            pay_invoice: $scope.orgDetails.pay_invoice,
            specific_org_settings: $scope.orgDetails.specific_org_settings,
            footer_text: $scope.orgDetails.footer_text,
            logo_path: $scope.orgDetails.logo_path,
            cdi_rate: ($scope.orgDetails.stratus_access && (+$scope.orgDetails.cdi_rate || 0)) || orgDetails.cdi_rate,
            asl_rate: ($scope.orgDetails.stratus_access && (+$scope.orgDetails.asl_rate || 0)) || orgDetails.asl_rate,
            spoken_english_rate: ($scope.orgDetails.stratus_access && (+$scope.orgDetails.spoken_english_rate || 0)) || orgDetails.spoken_english_rate,
            audio_rollover_rate: ($scope.orgDetails.stratus_access && (+$scope.orgDetails.audio_rollover_rate || 0)) || orgDetails.audio_rollover_rate,
            devices: ($scope.orgDetails.stratus_access && ($scope.orgDetails.devices || []).map(device => device.device_id)) || orgDetails.devices,
            old_dashboard: $scope.orgDetails.old_dashboard,
            access_types: $scope.orgDetails.access_types.map(e => e.type),
            trip_type: tripType,
            steerage: $scope.orgDetails.steerage,
            external_cust_id: $scope.orgDetails.external_cust_id,
            vendor_preference_order: vendorPreferenceOrder,
            excluded_vendor_list: $scope.orgDetails.excluded_vendor_list.filter(item => !!item),
            future_lookup: $scope.orgDetails.future_lookup ? $scope.orgDetails.future_lookup : false,
            claim_lookup: $scope.orgDetails.claim_lookup ? $scope.orgDetails.claim_lookup : false,
            account_lookup: $scope.orgDetails.account_lookup ? $scope.orgDetails.account_lookup : false,
            [featureFlags.ZIPCODE_AVAILABILITY]: $scope.orgDetails.availabilityMatrix ? $scope.orgDetails.availabilityMatrix : false,
            invited_riders: $scope.orgDetails.invited_riders ? $scope.orgDetails.invited_riders : false,
            customized_id_number: $scope.orgDetails.customized_id_number ? $scope.orgDetails.customized_id_number : false,
            customized_id_number_label: $scope.orgDetails.customized_id_number_label,
            thirdPartyOrgs: $scope.orgDetails.thirdPartyOrgs,
            fileNumberRequired: $scope.orgDetails.fileNumberRequired,
            ebs_csv_with_ap_data: $scope.orgDetails.ebsCsvWithApData,
            ebs_csv_with_ar_data: $scope.orgDetails.ebsCsvWithArData,
            address_limit_enabled: $scope.orgDetails.address_limit_enabled,
            address_limit_type: $scope.orgDetails.address_limit_type,
            validatedAddress: $scope.orgDetails.validatedAddress,
          };
          const autoAssignSettingValue = $scope.orgDetails[AUTO_ASSIGN_SETTINGS.AUTO_ASSIGN_SETTING_NAME];
          serverData[AUTO_ASSIGN_SETTINGS.AUTO_ASSIGN_SETTING_NAME] = autoAssignSettingValue;
          if ($scope.orgDetails.market_segment.key === 'GHT' && (autoAssignSettingValue !== undefined
            || autoAssignSettingValue !== null)) {
            const autoAssignSettingsName = AUTO_ASSIGN_SETTINGS.ALL_AUTO_ASSIGN_SETTINGS;
            autoAssignSettingsName.forEach((setting) => {
              const { settingName, editable, dependent } = setting;
              // eslint-disable-next-line no-underscore-dangle
              if (editable && ($scope.orgDetails._source[settingName] === 'org' || (dependent && $scope.orgDetails._source[dependent] === 'org'))) {
                if (typeof $scope.orgDetails[settingName] !== 'number' && !$scope.orgDetails[settingName].length) {
                  serverData[settingName] = null;
                } else {
                  serverData[settingName] = $scope.orgDetails[settingName];
                }
                if (dependent && ($scope.orgDetails[dependent] === '' || $scope.orgDetails[dependent] === null)) {
                  serverData[settingName] = null;
                }
              }
            });
          }
          if (serverData.children.length > 0) {
            serverData.show_child_data = true;
          }
          if (!serverData.future_lookup && !serverData.claim_lookup && !serverData.account_lookup) {
            showToaster('error', 'At least one billing option is required', '');
            return false;
          }
          if ($scope.orgDetails.parent_id) {
            serverData.parent_id = $scope.orgDetails.parent_id;
            serverData.type = 'childOrg';
          }
          serverData.is_third_party = false;
          serverData.payer = false;

          if ($scope.orgDetails.org_types && $scope.orgDetails.org_types.length > 0) {
            if (_.find($scope.orgDetails.org_types, orgType => orgType.id === 'third_party')) {
              serverData.is_third_party = true;
            }
            if (_.find($scope.orgDetails.org_types, orgType => orgType.id === 'payer')) {
              serverData.payer = true;
            }
          }

          if (serverData.claim_lookup || serverData.future_lookup) {
            if(!serverData.payer){
              showToaster('error', 'Please work with IT to complete the Organization Payer setup to use Claim/Future Claim', '');
              return false;
            }
            const checkOrgClaimSearchAvailability = $http.get(`${API_BASE_URL}organisation/checkClaimSearch/${$scope.orgDetails.id}/payer`);
            const claimCriteriaFailed = await checkOrgClaimSearchAvailability.then(
              (result) => {
                if (!result.data.success) {
                  showToaster('error', 'Please work with IT to complete the Organization Payer setup to use Claim/Future Claim', '');
                  return true;
                }
              },
              (error) => {
                toaster.pop({
                  type: 'error',
                  title: error.data ? error.data.message : '',
                  showCloseButton: true,
                  timeout: 10000,
                });
                showToaster('error', error.data ? error.data.message : '', '');
                return true;
              },
            );

            if(claimCriteriaFailed){
              return false;
            }
          }

          if (permissionFilter($scope.permissionsConstants.SHOW_MILEAGE_LIMIT_SETTINGS)) {
            serverData.mileage_limit_type = $scope.orgDetails.mileage_limit_type;
            serverData.mileage_limit_value = $scope.orgDetails.mileage_limit_value;
            serverData.mileage_limit_enabled = $scope.orgDetails.mileage_limit_enabled;
          }
          loadingScreenFactory.showLoadingScreen();
          const fm = new window.FormData();
          fm.append('data', JSON.stringify(serverData));
          if (uploadImageComponent && uploadImageComponent.files && uploadImageComponent.files.length) {
            fm.append('file', uploadImageComponent.files[0]);
          }
          let url = `${API_BASE_URL}organisation/edit/${$scope.orgDetails.id}`;
          url += $scope.orgDetails.main_contact_id ? `/${$scope.orgDetails.main_contact_id}` : '';
          $http({
            url,
            method: 'POST',
            data: fm,
            headers: {
              'Content-Type': undefined,
            },
          })
            .then((result) => {
              $scope.forms.orgEdit.submitted = false;
              $scope.addressSettings.isValidatedAddress = false;
              getEmailList();
              if (result.data.success) {
                SweetAlert.swal(
                  {
                    title: 'Changes Saved',
                    text: 'Organization edited successfully',
                    type: 'success',
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'OK',
                    closeOnConfirm: true,
                  },
                  (isConfirm) => {
                    if (isConfirm) getOrgDetailsById();
                    $scope.disableFromOrgTab = true;
                    if (uploadImageComponent && uploadImageComponent.files && uploadImageComponent.files.length) {
                      uploadImageComponent.value = '';
                    }
                  },
                );
              } else {
                loadingScreenFactory.hideLoadingScreen();
                const errorMessage = result.data.message;
                if (typeof errorMessage === 'object') {
                  Object.keys(errorMessage).forEach((key) => {
                    showToaster('error', errorMessage[key], '');
                  });
                } else if (typeof errorMessage === 'string') {
                  showToaster('error', errorMessage, '');
                }
              }
            })
            .catch((error) => {
              if (error.data && error.data.message && error.data.message.toLowerCase() === 'this is not an eligible parent organisation') {
                $scope.orgDetails.eligableOrgHierarchyParent = false;
              }
              showToaster('error', error.data ? error.data.message : 'Could not update Organization', 'Could not update Organization');
            })
            .finally(() => {
              loadingScreenFactory.hideLoadingScreen();
              $scope.disablePaymentForm = true;
            });
        } else {
          $scope.forms.orgEdit.submitted = true;
        }
        return true;
      };
      $scope.paymentMethodChanged = function paymentMethodChanged(selected) {
        $scope.billing.billing_method = selected;
      };
      $scope.inviteRiderOnChange = (inviteRider) => {
        if (!inviteRider) {
          $scope.orgDetails.customized_id_number = false;
        }
      };
      $scope.onAutoAssignFieldChange = (settingName) => { /* eslint-disable no-underscore-dangle */
        if ($scope.orgDetails._source[settingName] !== 'org') {
          $scope.orgDetails._source[settingName] = 'org';
        }
      };
      $scope.openFormBuilder = function openFormBuilder() {
        const params = {
          orgId,
        };
        $state.go('main.userMgmt.manageQuestionnaire', params);
      };
      // used to scroll the user to top of the page
      // and show the add child organization drop down
      $scope.validationChildOrgReqTinNumber = true;
      $scope.showAddChildDropDown = function showAddChildDropDown() {
        if ($scope.orgDetails.tin) {
          $scope.addOrg.tin = $scope.orgDetails.tin;
        }
        if (
          ($scope.orgDetails.market_segment_id && $scope.orgDetails.market_segment_id === WORKERS_COMPENSATION_ID)
          || $scope.orgDetails.market_segment.key === HEALTHCARE_MASS_MARKET_KEY
        ) {
          $scope.validationChildOrgReqTinNumber = false;
        } else {
          $scope.validationChildOrgReqTinNumber = true;
        }
        $('#current-app').animate(
          {
            scrollTop: 0,
          },
          'slow',
        );
        $scope.showAddChildOrg = true;
      };

      // this function is used to validate the addOrg object's values
      // before the data is sent to the server to be saved as a child org of the
      // currently selected parent org.
      function validateAddOrg() {
        const {
          addOrg,
        } = $scope;
        const ghtMarketSegment = $scope.marketSegmentList.find(marketSegment => marketSegment.key === $scope.groupHealthMarketSegmentKey);
        if (!addOrg.name) {
          $scope.AddChildOrgError = 'Organization name is required';
          return false;
        }
        if (addOrg.name && !addOrg.name.match(/^[-\sa-zA-Z0-9\\//]+$/)) {
          $scope.AddChildOrgError = 'Organisation name can not contain special symbols except hyphen and slashes';
          const el = angular.element(document.getElementById('tin-child'));
          customInjectorCompiler(el);
          return false;
        }
        if (!addOrg.market_segment_id) {
          $scope.AddChildOrgError = 'Market Segment is required';
          return false;
        }
        if (!ghtMarketSegment) {
          if (!addOrg.mainContact.firstName) {
            $scope.AddChildOrgError = 'First name is required';
            return false;
          }
          if (!addOrg.mainContact.lastName) {
            $scope.AddChildOrgError = 'Last name is required';
            return false;
          }
          if (!addOrg.mainContactEmail) {
            $scope.AddChildOrgError = 'Main Contact Email is required';
            return false;
          }
        }
        if (addOrg.mainContact.firstName && !addOrg.mainContact.firstName.match(/^[-\sa-zA-Z0-9\\//]+$/)) {
          $scope.AddChildOrgError = 'First name can not contain special symbols except hyphen and slashes';
          return false;
        }
        if (addOrg.mainContact.lastName && !addOrg.mainContact.lastName.match(/^[-\sa-zA-Z0-9\\//]+$/)) {
          $scope.AddChildOrgError = 'Last name can not contain special symbols except hyphen and slashes';
          return false;
        }
        let marketSegmentKey;
        addOrg.tin = addOrg.tin ? addOrg.tin.replace(/[- )(]/g, '') : '';
        if ($scope.marketSegmentList.length) {
          marketSegmentKey = ($scope.marketSegmentList.find(marketSegment => marketSegment.id === addOrg.market_segment_id) || {}).key;
        }
        if (!addOrg.orgContactPhone && marketSegmentKey === $scope.groupHealthMarketSegmentKey) {
          $scope.AddChildOrgError = 'Organization Contact Number is required for Group Health Market Segment';
          return false;
        }
        if (!addOrg.tin && addOrg.market_segment_id !== WORKERS_COMPENSATION_ID && (marketSegmentKey !== HEALTHCARE_MASS_MARKET_KEY)) {
          $scope.AddChildOrgError = 'Tax Identification Number is required.';
          return false;
        }
        if (addOrg.tin.length !== 9 && addOrg.market_segment_id !== WORKERS_COMPENSATION_ID && (marketSegmentKey !== HEALTHCARE_MASS_MARKET_KEY)) {
          $scope.AddChildOrgError = 'Tax Identification Number should be of 9 digits.';
          return false;
        }
        return true;
      }
      // adds child organization to the selected parent organization
      $scope.addOrgClicked = function addOrgClicked() {
        const orgData = angular.copy($scope.addOrg);
        orgData.tin = $scope.addOrg.tin ? $scope.addOrg.tin.replace(/[- )(]/g, '') : '';
        orgData.type = 'childOrg';
        orgData.parent_id = orgId;
        orgData.is_third_party = $scope.orgDetails.is_third_party;
        if (_.isEmpty($scope.addOrg.access_types)) {
          $scope.childAccessTypeRequired = true;
          $scope.AddChildOrgError = 'Access Type is required';
          $timeout(() => {
            $scope.AddChildOrgError = '';
          }, 5000);
          return;
        }
        orgData.access_types = $scope.addOrg.access_types.map(e => e.type);
        // validate the data before sending it to backend as this prevents unnecessary hits on the server.
        // note:: similar code exists in the project where validations are not
        // performed on the front-end and api-endpoint are hit regardless of
        // the data being invalid.
        if (validateAddOrg()) {
          if ($scope.marketSegmentList.length) {
            const marketSegmentKey = ($scope.marketSegmentList.find(marketSegment => marketSegment.id === orgData.market_segment_id) || {}).key;
            if (marketSegmentKey) {
              orgData.market_segment_key = marketSegmentKey;
            }
          }
          const addOrgCall = $http.post(`${API_BASE_URL}organisation/add`, orgData);
          loadingScreenFactory.showLoadingScreen();
          addOrgCall.then(
            (result) => {
              // if the organization has been added then hide the loading screen
              // and show a success toaster.
              loadingScreenFactory.hideLoadingScreen();
              if (result.data.success) {
                getEligibleChildrenOrganisations(orgId);
                showToaster('success', 'Organization added successfully');
                resetAddChildOrgForm();
              } else {
                // else first clear the toaster in order to clear the previous toaster element
                // and then show the error toaster.
                toaster.clear();
                if (result.data && typeof result.data.message === 'string') {
                  showToaster('error', result.data ? result.data.message : 'Could not add Organization', 'Could not add Organization');
                } else {
                  Object.keys(result.data.message).forEach((key) => {
                    showToaster('error', result.data.message[key], '');
                  });
                }
                // below code is used to solve the data-mask issue of not showing the
                // masking in some cases.
                const el = angular.element(document.getElementById('tin-child'));
                customInjectorCompiler(el);
              }
            },
            (error) => {
              // else first clear the toaster in order to clear the previous toaster element
              // and then show the error toaster.
              toaster.clear();
              loadingScreenFactory.hideLoadingScreen();
              if (error.data && error.data.message && typeof error.data.message === 'string') {
                $scope.AddChildOrgError = error.data.message || 'Could not add Organization';
              } else if (error.data && error.data.message) {
                Object.keys(error.data.message).forEach((key) => {
                  $scope.AddChildOrgError = error.data.message[key];
                });
              } else {
                Object.keys(error.message).forEach((key) => {
                  $scope.AddChildOrgError = error.message[key];
                });
              }
              $timeout(() => {
                $scope.AddChildOrgError = '';
              }, 5000);
              const el = angular.element(document.getElementById('tin-child'));
              customInjectorCompiler(el);
            },
          );
        } else {
          $timeout(() => {
            $scope.AddChildOrgError = '';
          }, 5000);
        }
      };
      $scope.validationReqTinNumber = true;
      $scope.orgContactNumberRequired = true;
      $scope.mainContactRequired = false;

      $scope.marketSegmentChanged = function marketSegmentChanged() {
        const marketSegmentSelected = $scope.marketSegmentList.find(marketSegment => marketSegment.id === $scope.orgDetails.market_segment_id);
        $scope.orgDetails.steerage = false;
        if (marketSegmentSelected && marketSegmentSelected.key === 'GHT') {
          $scope.orgDetails.steerage = true;
        }
        const noEmailSelectedPresentInDropDown = $scope.emailList.find(email => email === noEmailSelectedString);
        for (let index = 0; index < $scope.marketSegmentList.length; index += 1) {
          if ($scope.marketSegmentList[index].id === $scope.orgDetails.market_segment_id) {
            if ($scope.marketSegmentList[index].id === WORKERS_COMPENSATION_ID || $scope.marketSegmentList[index].key === HEALTHCARE_MASS_MARKET_KEY) {
              $scope.validationReqTinNumber = false;
            } else {
              $scope.validationReqTinNumber = true;
            }
            if ($scope.marketSegmentList[index].key === GROUP_HEALTH_KEY) {
              $scope.orgContactNumberRequired = true;
              if (!noEmailSelectedPresentInDropDown) {
                $scope.emailList = [noEmailSelectedString].concat($scope.emailList);
              }
            } else {
              $scope.emailList = $scope.emailList.filter(email => email !== noEmailSelectedString);
              $scope.orgContactNumberRequired = false;
            }
            $scope.orgDetails.market_segment = $scope.marketSegmentList[index];
            const { settings } = $scope.marketSegmentList[index];
            $scope.mainContactRequired = !!settings.find(setting => setting.setting_name === featureFlags.MAIN_CONTACT_REQUIRED && setting.setting_value === 'true');
            $scope.showEmailText = !$scope.emailList.filter(email => email !== noEmailSelectedString).length;
            break;
          }
        }
        populateAutoAssignOrgSettings();
      };
      // @saml setting
      $scope.sso_setting = {};
      $scope.sso_setting.checkbox = false;
      $scope.disableSSOForm = true;
      $scope.sso_setting.login_initiator = 'sp';
      $scope.onTabClick = function onTabClick(tabName) {
        if (!$scope.tabsObject[tabName]) {
          $location.search('activePage', tabName);
          $scope.tabsObject = {
            account: false,
            payment: false,
            external_api: false,
            sso: false,
          };
          $scope.tabsObject[tabName] = true;
        }
      };
      $scope.onInvoiceDefaultPaymentChange = function onInvoiceDefaultPaymentChange() {
        if (!_.get($scope, 'selectedPaymentMethod.isPrimary')) {
          return;
        }
        SweetAlert.swal(
          {
            title: 'Update default Payment Method',
            text: 'Are you sure you want to make this payment method default for paying monthly invoices?',
            type: 'warning',
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes',
            closeOnConfirm: true,
            showCancelButton: true,
            closeOnCancel: true,
          },
          (isConfirm) => {
            if (isConfirm) {
              loadingScreenFactory.showLoadingScreen();
              const fm = new window.FormData();
              const addressScope = $scope.orgDetails.physical_address ? $scope.orgDetails.physical_address : {};
              delete addressScope.state;
              const params = {
                name: orgDetails.name,
                firstName: orgDetails.user.first_name,
                lastName: orgDetails.user.last_name,
                mainContactEmail: orgDetails.user.username,
                tin: $scope.orgDetails.tin ? $scope.orgDetails.tin.replace(/[- )(]/g, '') : '',
                market_segment_id: $scope.orgDetails.market_segment_id,
                market_segment_key: ($scope.orgDetails.market_segment || {}).key,
                addresses: addressScope,
                default_monthly_invoice_payment_profile_id: _.get($scope, 'selectedPaymentMethod.id'),
              };
              fm.append('data', JSON.stringify(params));
              let url = `${API_BASE_URL}organisation/edit/${$scope.orgDetails.id}`;
              url += $scope.orgDetails.main_contact_id ? `/${$scope.orgDetails.main_contact_id}` : '';
              $http({
                url,
                method: 'POST',
                data: fm,
                headers: {
                  'Content-Type': undefined,
                },
              }).then(
                (result) => {
                  loadingScreenFactory.hideLoadingScreen();
                  if (result.data.success) {
                    getOrgDetailsById();
                    $scope.selectedPaymentMethod.auto_pay = true;
                    toaster.pop({
                      type: 'success',
                      title: 'Monthly Invoice Card updated.',
                      showCloseButton: true,
                      timeout: 10000,
                    });
                  } else {
                    $scope.selectedPaymentMethod.isPrimary = !$scope.selectedPaymentMethod.isPrimary;
                    toaster.pop({
                      type: 'error',
                      title: result.data.message,
                      showCloseButton: true,
                      timeout: 10000,
                    });
                  }
                },
                () => {
                  loadingScreenFactory.hideLoadingScreen();
                  $scope.selectedPaymentMethod.isPrimary = !$scope.selectedPaymentMethod.isPrimary;
                },
              );
            } else {
              $scope.selectedPaymentMethod.isPrimary = !$scope.selectedPaymentMethod.isPrimary;
            }
          },
        );
      };
      $scope.onBillingAddressFormSubmit = function onBillingAddressFormSubmit() {
        loadingScreenFactory.showLoadingScreen();
        const fm = new window.FormData();
        const addressScope = $scope.orgDetails.physical_address ? $scope.orgDetails.physical_address : {};
        delete addressScope.state;
        const params = {
          name: orgDetails.name,
          firstName: orgDetails.user.first_name,
          lastName: orgDetails.user.last_name,
          mainContactEmail: orgDetails.user.username,
          tin: $scope.orgDetails.tin ? $scope.orgDetails.tin.replace(/[- )(]/g, '') : '',
          market_segment_id: $scope.orgDetails.market_segment_id,
          market_segment_key: ($scope.orgDetails.market_segment || {}).key,
          addresses: addressScope,
          billing_address: $scope.state.billingAddress,
        };
        fm.append('data', JSON.stringify(params));
        let url = `${API_BASE_URL}organisation/edit/${$scope.orgDetails.id}`;
        url += $scope.orgDetails.main_contact_id ? `/${$scope.orgDetails.main_contact_id}` : '';
        $http({
          url,
          method: 'POST',
          data: fm,
          headers: {
            'Content-Type': undefined,
          },
        }).then(
          (result) => {
            loadingScreenFactory.hideLoadingScreen();
            if (result.data.success) {
              toaster.pop({
                type: 'success',
                title: 'Billing Address updated successfully.',
                showCloseButton: true,
                timeout: 10000,
              });
            } else {
              toaster.pop({
                type: 'error',
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
            }
          },
          () => {
            loadingScreenFactory.hideLoadingScreen();
          },
        );
      };
      $scope.saveAutoPaySetting = function saveAutoPaySetting(selectedPaymentMethod, config = {}) {
        loadingScreenFactory.showLoadingScreen();
        const fm = new window.FormData();
        const addressScope = _.omit($scope.orgDetails.physical_address, ['state']);
        delete addressScope.state;
        if ($scope.selectedPaymentMethod.auto_pay !== $scope.selectedPaymentMethodCopy.auto_pay) {
          const params = {
            name: orgDetails.name,
            firstName: orgDetails.user.first_name,
            lastName: orgDetails.user.last_name,
            mainContactEmail: orgDetails.user.username,
            tin: $scope.orgDetails.tin ? $scope.orgDetails.tin.replace(/[- )(]/g, '') : '',
            market_segment_id: $scope.orgDetails.market_segment_id,
            market_segment_key: ($scope.orgDetails.market_segment || {}).key,
            addresses: addressScope,
            auto_pay: $scope.selectedPaymentMethod.auto_pay,
            auto_pay_date: $scope.orgDetails.auto_pay_date,
          };
          if ($scope.selectedPaymentMethod.auto_pay) {
            params.default_monthly_invoice_payment_profile_id = $scope.selectedPaymentMethod.id;
          }
          fm.append('data', JSON.stringify(params));
          let url = `${API_BASE_URL}organisation/edit/${$scope.orgDetails.id}`;
          url += $scope.orgDetails.main_contact_id ? `/${$scope.orgDetails.main_contact_id}` : '';
          $http({
            url,
            method: 'POST',
            data: fm,
            headers: {
              'Content-Type': undefined,
            },
          })
            .then((result) => {
              if (result.data.success) {
                getOrgDetailsById();
                if ($scope.selectedPaymentMethod.auto_pay) {
                  $scope.selectedPaymentMethod.isPrimary = true;
                }
                if (!config.hideMessage) {
                  toaster.pop({
                    type: 'success',
                    title: 'Auto Pay setting saved successfully.',
                    showCloseButton: true,
                    timeout: 10000,
                  });
                }
              } else {
                toaster.pop({
                  type: 'error',
                  title: result.data.message,
                  showCloseButton: true,
                  timeout: 10000,
                });
              }
            })
            .finally(() => loadingScreenFactory.hideLoadingScreen());
        } else {
          loadingScreenFactory.hideLoadingScreen();
        }
      };
      /** ============================start here saml UI================ */
      // this function is used to saml setting enable or disable
      $scope.updateSSOSettings = function updateSSOSettings() {
        if ($scope.sso_setting.checkbox) {
          $scope.sso_setting.checkbox = false;
        } else {
          $scope.sso_setting.checkbox = true;
        }
        $scope.forms.orgSSOFromSubmit.submitted = false;
      };
      // disable the button edit or save
      let initialCheckboxSetting;
      $scope.editSSODetailsClicked = function editSSODetailsClicked() {
        initialCheckboxSetting = $scope.sso_setting.checkbox;
        $scope.disableSSOFormTopFields = false;
        if (!$scope.sso_child_org.is_child_org) {
          $scope.disableSSOForm = false;
          initialSSOSettings.current_org = angular.copy($scope.saml_config_detail);
        } else {
          $scope.samlActiveMappingField.branch = !!(initialSSOSettings.parent_org.sso_key_mapping && initialSSOSettings.parent_org.sso_key_mapping.branch);
        }
      };
      $scope.editExternalApiClicked = function editExternalApiClicked() {
        priorExternalApiData = angular.copy($scope.apiuserOrgSettings);
        $scope.disableExternalApiForm = false;
      };
      $scope.cancelExternalApiClicked = function cancelExternalApiClicked() {
        if (priorExternalApiData) {
          $scope.apiuserOrgSettings = angular.copy(priorExternalApiData);
        }
        $scope.disableExternalApiForm = true;
      };

      /*
      * Creator: Bhupinder Garg
      * Reason: Dynamic Validations. samlActiveMapping will contain id of entered mapping field values as key
      * and true/false based on the fact if it is entered or not.
      * Date: 1Jan2019
      */
      $scope.samlActiveMappingField = {};
      $scope.currentSAMLField = function currentSAMLField(id, value) {
        if (value.length > 0) {
          $scope.samlActiveMappingField[id] = true;
        } else {
          $scope.samlActiveMappingField[id] = false;
        }
      };

      $scope.childOrgSwitched = function childOrgSwitched(isChildOrg) {
        $scope.disableSSOForm = isChildOrg;
        let ssoKey = '';
        if (isChildOrg) {
          $scope.saml_config_detail = initialSSOSettings.parent_org;
          ssoKey = initialSSOSettings.parent_ssokey;
        } else {
          $scope.saml_config_detail = initialSSOSettings.current_org;
          ssoKey = initialSSOSettings.current_ssokey;
        }

        let loginInitiatorType = spSsoUrl;
        if ($scope.sso_setting.login_initiator === 'idp') {
          loginInitiatorType = idpSsoUrl;
        }
        $scope.ssoKeyUrl = API_BASE_URL + loginInitiatorType + ssoKey;
      };

      $scope.getSsoDetailsOfParent = function getSsoDetailOfParent(selectedOrgObject) {
        const organizationId = selectedOrgObject.id;
        const ssoKey = selectedOrgObject.sso_key;
        if (!organizationId) {
          toaster.pop({
            type: 'error',
            title: 'Please select parent organisation to copy sso details from.',
            showCloseButton: true,
            timeout: 10000,
          });
        }

        const getSsoDetailsOfParent = $http.get(`${API_BASE_URL}organisation/ssoConfig/${organizationId}`);
        loadingScreenFactory.showLoadingScreen();
        getSsoDetailsOfParent
          .then((orgConfiObj) => {
            const orgConfigDetail = orgConfiObj.data;
            loadingScreenFactory.hideLoadingScreen();
            $scope.disableSSOForm = true;

            // get sso template start here
            if (orgConfigDetail.orgConfigDetails != null && orgConfigDetail.orgConfigDetails.setting) {
              $scope.sso_setting.login_initiator = orgConfigDetail.orgConfigDetails.login_initiator;
              angular.forEach(orgConfigDetail.orgConfigDetails.setting, (value, key) => {
                $scope.enable_saml_group_key = key === 'sso_key_mapping';
                $scope.saml_config_detail[key] = {};
                angular.forEach(value, (ivalue, ikey) => {
                  $scope.saml_config_detail[key][ikey] = ivalue;
                });
              });

              const loginInitiatorUrl = $scope.orgDetails.enabled_sso && $scope.sso_setting.login_initiator === 'idp' ? idpSsoUrl : spSsoUrl;
              $scope.ssoKeyUrl = API_BASE_URL + loginInitiatorUrl + ssoKey;
              $scope.ssoTemplateChangedByLoginInitiator($scope.sso_setting.login_initiator);

              toaster.pop({
                type: 'Active',
                title: 'SSO Details copied from parent.',
                showCloseButton: true,
                timeout: 10000,
              });
            } else {
              toaster.pop({
                type: 'error',
                title: 'Unable to copy SSO Details from parent.',
                showCloseButton: true,
                timeout: 10000,
              });
            }
          })
          .catch((error) => {
            loadingScreenFactory.hideLoadingScreen();
            toaster.pop({
              type: 'error',
              title: error.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          });
      };

      // from submit for add and update
      $scope.saveSsoSettingClicked = function saveSsoSettingClicked() {
        if ($scope.forms.orgSSOFromSubmit && $scope.forms.orgSSOFromSubmit.$valid) {
          // org id existing check
          if (!$scope.orgDetails.id) {
            SweetAlert.swal({
              title: 'Organisation id not found',
              text: 'Please add organisation before making changes to it',
              type: 'error',
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'OK',
              closeOnConfirm: true,
            });
            return false;
          }
          if (!$scope.saml_config_detail) {
            $scope.saml_config_detail = {};
          }
          $scope.saml_config_detail.enabled_sso = $scope.sso_setting.checkbox;
          $scope.saml_config_detail.sso_type = 'saml';
          $scope.saml_config_detail.login_initiator = $scope.sso_setting.login_initiator;
          // Adding child org details to saml config details
          if ($scope.sso_child_org.is_child_org) {
            $scope.saml_config_detail.is_child_org = $scope.sso_child_org.is_child_org;
            $scope.saml_config_detail.org_parent_id = $scope.sso_child_org.parent_org_id;
            $scope.saml_config_detail.org_customer_id = $scope.sso_child_org.org_customer_id;
          }
          if ($scope.orgDetails.org_sso_setting != null && $scope.orgDetails.org_sso_setting.id) {
            // update sso setting info in db
            const editOrgSsoSettingCall = $http.put(
              `${API_BASE_URL}organisation/ssoConfig/update/${$scope.orgDetails.id}/${$scope.orgDetails.org_sso_setting.id}`,
              $scope.saml_config_detail,
            );
            loadingScreenFactory.showLoadingScreen();
            editOrgSsoSettingCall
              .then((result) => {
                loadingScreenFactory.hideLoadingScreen();
                $scope.disableSSOForm = true;
                $scope.disableSSOFormTopFields = true;

                if (!result.data.success) {
                  toaster.pop({
                    type: 'error',
                    title: result.data.message,
                    showCloseButton: true,
                    timeout: 10000,
                  });
                  return;
                }
                getOrgDetailsById();
                SweetAlert.swal(
                  {
                    title: 'Activated',
                    text: 'Organization Sso setting updated successfully',
                    type: 'success',
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'OK',
                    closeOnConfirm: true,
                  },
                  () => {
                    if ($scope.orgDetails.enabled_sso) {
                      $scope.sso_setting.checkbox = $scope.orgDetails.enabled_sso;
                      const ssokeyToShow = $scope.sso_child_org.is_child_org ? initialSSOSettings.parent_ssokey : initialSSOSettings.current_ssokey;
                      if ($scope.sso_setting.login_initiator === 'idp') {
                        $scope.ssoKeyUrl = API_BASE_URL + idpSsoUrl + ssokeyToShow;
                      } else {
                        $scope.ssoKeyUrl = API_BASE_URL + spSsoUrl + ssokeyToShow;
                      }
                    }
                  },
                );
              })
              .catch((error) => {
                loadingScreenFactory.hideLoadingScreen();
                toaster.pop({
                  type: 'error',
                  title: error.data.message,
                  showCloseButton: true,
                  timeout: 10000,
                });
              });
          } else {
            // add sso setting info in db
            const addOrgSsoSettingCall = $http.post(`${API_BASE_URL}organisation/ssoConfig/add/${$scope.orgDetails.id}`, $scope.saml_config_detail);
            addOrgSsoSettingCall.then(
              (result) => {
                // if the sso setting has been added then hide the loading screen
                // and show a success toaster.
                loadingScreenFactory.hideLoadingScreen();
                if (result.data.success) {
                  $scope.disableSSOForm = true;
                  $scope.orgDetails.org_sso_setting = result.data.org_sso_setting_info;
                  if (result.data.org_sso_setting_info.id) {
                    if ($scope.sso_setting.login_initiator === 'idp') {
                      $scope.ssoKeyUrl = API_BASE_URL + idpSsoUrl + $scope.orgDetails.sso_key;
                    } else {
                      $scope.ssoKeyUrl = API_BASE_URL + spSsoUrl + $scope.orgDetails.sso_key;
                    }
                  }
                  toaster.pop({
                    type: 'success',
                    title: 'Organization Sso setting added successfully',
                    showCloseButton: true,
                    timeout: 10000,
                  });
                  $scope.orgSSOFromSubmit = {};
                } else {
                  // else first clear the toaster in order to clear the previous toaster element
                  // and then show the error toaster.
                  toaster.clear();
                  if (typeof result.data.message === 'string') {
                    toaster.pop({
                      type: 'error',
                      title: result.data.message,
                      showCloseButton: true,
                      timeout: 10000,
                    });
                  } else {
                    Object.keys(result.data.message).forEach((key) => {
                      toaster.pop({
                        type: 'error',
                        title: result.data.message[key],
                        showCloseButton: true,
                        timeout: 10000,
                      });
                    });
                  }
                  // below code is used to solve the data-mask issue of not showing the
                  // masking in some cases.
                  const el = angular.element(document.getElementById('tin-child'));
                  customInjectorCompiler(el);
                }
              },
              (error) => {
                // else first clear the toaster in order to clear the previous toaster element
                // and then show the error toaster.
                toaster.clear();
                loadingScreenFactory.hideLoadingScreen();
                if (typeof error.data.message === 'string') {
                  $scope.AddChildOrgError = error.data.message;
                } else {
                  Object.keys(error.data.message).forEach((key) => {
                    $scope.AddChildOrgError = error.data.message[key];
                  });
                }
                $timeout(() => {
                  $scope.AddChildOrgError = '';
                }, 5000);
                const el = angular.element(document.getElementById('tin-child'));
                customInjectorCompiler(el);
              },
            );
          }
        } else {
          $scope.forms.orgSSOFromSubmit.submitted = true;
        }
        return true;
      };
      $scope.ssoCancelClicked = function ssoCancelClicked() {
        // $scope.saml_config_detail = initialSSOSettings;
        $scope.disableSSOForm = true;
        if (initialCheckboxSetting !== undefined) {
          $scope.sso_setting.checkbox = initialCheckboxSetting;
        }
        $scope.saml_config_detail = $scope.sso_child_org.is_child_org ? initialSSOSettings.parent_org : initialSSOSettings.current_org;
        $scope.forms.orgSSOFromSubmit.submitted = false;
      };
      $scope.ssoTemplateChangedByLoginInitiator = function ssoTemplateChangedByLoginInitiator(loginInitiatorType) {
        if (!loginInitiatorType) {
          return false;
        }
        return getOrgSsoTemplate($scope.orgDetails.sso_type);
      };
      /** ============================end here saml UI================ */
      updateBreadcrumb();

      function resetA() {
        $scope.selectedA = [];
        angular.forEach($scope.orgDetails.availableProviders, (element) => {
          Object.assign(element, uncheck);
        });
        $scope.checkedA = false;
      }
      function resetB() {
        $scope.selectedB = [];
        angular.forEach($scope.orgDetails.selectedProviders, (element) => {
          Object.assign(element, uncheck);
        });
        $scope.checkedB = false;
      }

      $scope.aToB = (dropId, fromList) => {
        if ($scope.disableForm) {
          return;
        }
        if (fromList === 'selectedProvidersList') {
          $scope.selectedB.forEach((selectedProviderId) => {
            const provider = $scope.orgDetails.selectedProviders.find(vendor => vendor.id === selectedProviderId);
            if (!provider) {
              return;
            }
            const delId = arrayObjectIndexOf($scope.orgDetails.selectedProviders, selectedProviderId);
            if (delId === -1) {
              showToaster('error', 'Invalid operation.');
              return;
            }
            if (dropId) {
              const dropTo = arrayObjectIndexOf($scope.orgDetails.selectedProviders, dropId);
              if (dropTo === -1) {
                showToaster('error', 'Invalid operation.');
                return;
              }
              $scope.orgDetails.selectedProviders.splice(delId, 1);
              $scope.orgDetails.selectedProviders.splice(dropTo, 0, provider);
            } else {
              $scope.orgDetails.selectedProviders.splice(delId, 1);
              $scope.orgDetails.selectedProviders.push(provider);
            }
          });
          resetA();
          resetB();
          // if ($scope.orgDetails.selectedProviders.some(provider => provider.id === 4) && $scope.orgDetails.selectedProviders[$scope.orgDetails.selectedProviders.length - 1].id !== 4) {
          //   const traditionalProviderIndex = arrayObjectIndexOf($scope.orgDetails.selectedProviders, 4);
          //   const traditionalProvider = $scope.orgDetails.selectedProviders[traditionalProviderIndex];
          //   $scope.orgDetails.selectedProviders.splice(traditionalProviderIndex, 1);
          //   $scope.orgDetails.selectedProviders.push(traditionalProvider);
          //   SweetAlert.swal(
          //     {
          //       title: 'Error',
          //       text: 'Rideshare Providers are preferred over Traditional providers.',
          //       type: 'error',
          //       confirmButtonColor: '#DD6B55',
          //       confirmButtonText: 'OK',
          //       closeOnConfirm: true,
          //     },
          //   );
          // }
          return;
        }

        $scope.selectedA.forEach((selectedProviderId) => {
          const provider = $scope.orgDetails.availableProviders.find(vendor => vendor.id === selectedProviderId);
          if (!provider) {
            return;
          }
          if (dropId) {
            const dropTo = arrayObjectIndexOf($scope.orgDetails.selectedProviders, dropId);
            if (dropTo === -1) {
              showToaster('error', 'Invalid operation.');
              return;
            }
            $scope.orgDetails.selectedProviders.splice(dropTo, 0, provider);
          } else {
            $scope.orgDetails.selectedProviders.push(provider);
          }

          const delId = arrayObjectIndexOf($scope.orgDetails.availableProviders, selectedProviderId);
          if (delId === -1) {
            showToaster('error', 'Invalid operation.');
            return;
          }
          $scope.orgDetails.availableProviders.splice(delId, 1);
        });
        $scope.selectedA = [];
        resetB();
      };

      $scope.bToA = (dropId, fromList) => {
        if ($scope.disableForm) {
          return;
        }
        if (fromList === 'availableProvidersList') {
          $scope.selectedA.forEach((selectedProviderId) => {
            const provider = $scope.orgDetails.availableProviders.find(vendor => vendor.id === selectedProviderId);
            if (!provider) {
              return;
            }
            const delId = arrayObjectIndexOf($scope.orgDetails.availableProviders, selectedProviderId);
            if (delId === -1) {
              showToaster('error', 'Invalid operation.');
              return;
            }
            if (dropId) {
              const dropTo = arrayObjectIndexOf($scope.orgDetails.availableProviders, dropId);
              if (dropTo === -1) {
                showToaster('error', 'Invalid operation.');
                return;
              }
              $scope.orgDetails.availableProviders.splice(delId, 1);
              $scope.orgDetails.availableProviders.splice(dropTo, 0, provider);
            } else {
              $scope.orgDetails.availableProviders.splice(delId, 1);
              $scope.orgDetails.availableProviders.push(provider);
            }
          });
          resetB();
          resetA();
          return;
        }

        $scope.selectedB.forEach((selectedProviderId) => {
          const provider = $scope.orgDetails.selectedProviders.find(vendor => vendor.id === selectedProviderId);
          if (!provider) {
            return;
          }


          if (dropId) {
            const dropTo = arrayObjectIndexOf($scope.orgDetails.availableProviders, dropId);
            if (dropTo === -1) {
              showToaster('error', 'Invalid operation.');
              return;
            }
            $scope.orgDetails.availableProviders.splice(dropTo, 0, provider);
          } else {
            $scope.orgDetails.availableProviders.push(provider);
          }

          const delId = arrayObjectIndexOf($scope.orgDetails.selectedProviders, selectedProviderId);
          if (delId === -1) {
            showToaster('error', 'Invalid operation.');
            return;
          }
          $scope.orgDetails.selectedProviders.splice(delId, 1);
        });
        $scope.selectedB = [];
        resetA();
      };

      $scope.toggleA = () => {
        if ($scope.disableForm) {
          return;
        }
        if ($scope.selectedA.length > 0) {
          $scope.selectedA = [];
        }
        $scope.checkedA = !$scope.checkedA;
        if ($scope.checkedA) {
          $scope.orgDetails.availableProviders.forEach((provider) => {
            Object.assign(provider, { checked: true });
            $scope.selectedA.push(provider.id);
          });
        } else {
          $scope.orgDetails.availableProviders.forEach((provider) => {
            Object.assign(provider, { checked: false });
          });
        }
      };

      $scope.toggleB = () => {
        if ($scope.disableForm) {
          return;
        }
        if ($scope.selectedB.length > 0) {
          $scope.selectedB = [];
        }
        $scope.checkedB = !$scope.checkedB;
        if ($scope.checkedB) {
          $scope.orgDetails.selectedProviders.forEach((provider) => {
            Object.assign(provider, { checked: true });
            $scope.selectedB.push(provider.id);
          });
        } else {
          $scope.orgDetails.selectedProviders.forEach((provider) => {
            Object.assign(provider, { checked: false });
          });
        }
      };
      jQuery(document).ready(() => {
        jQuery.event.addProp('dataTransfer'); // prevent conflict with drag-drop
      });
      $scope.drop = (dropEl, fromList, direction) => {
        const drop = angular.element(document.getElementById(dropEl));
        const dropId = drop.attr('data-id');
        direction(dropId, fromList);
        $scope.$apply();
      };
      $scope.selectField = (list, selected, user) => {
        if ($scope.disableForm) {
          return;
        }
        Object.assign(user, {
          checked: !user.checked,
        });
        if (user.checked) {
          selected.push(user.id);
        } else {
          const selectedId = selected.indexOf(user.id);
          if (selectedId > -1) {
            selected.splice(selectedId, 1);
          }
        }
      };

      $scope.getThirdPartyOrgDetails = async function getThirdPartyOrgDetails(organizationKey) {
        $scope.apiuserOrgSettings.third_party_orgname = null;
        if (organizationKey && organizationKey.trim()) {
          loadingScreenFactory.showLoadingScreen();
          try {
            const response = await $http.get(`${API_BASE_URL}organisation/detailsByOrgKey/${organizationKey}`, {
            });
            const { data: { message: { name } } } = response;
            if (name) {
              $scope.apiuserOrgSettings.third_party_orgname = name;
            }
          } catch (error) {
            showToaster('error', error.data ? error.data.message : 'Organization not found.');
          } finally {
            loadingScreenFactory.hideLoadingScreen();
            $scope.$apply();
          }
        }
      };
      watcherRemovers.push(
        $scope.$watch('orgDetails', () => {
          updateBreadcrumb();
        }),
      );
      $scope.$on('$destroy', () => {
        angular.forEach(watcherRemovers, (w) => {
          w();
        });
      });
    },
  ])
  .directive('compareFee', [
    function compareFee() {
      return {
        require: 'ngModel',
        scope: {
          occm_fee: '=compareFee',
        },
        link(scope, element, attributes, ngModel) {
          const watcherRemovers = [];
          Object.assign(ngModel, {
            compareFees(modelValue) {
              return !scope.occ_fee || !!modelValue;
            },
          });
          watcherRemovers.push(
            scope.$watch('occm_fee', () => {
              ngModel.$validate();
            }),
          );
          scope.$on('$destroy', () => {
            angular.forEach(watcherRemovers, (w) => {
              w();
            });
          });
        },
      };
    },
  ]);
