export default function createRelayBillingMethodHtmlTemplate(dataAssignPdfObject) {
  let pdfHtml = `<html><head><style>
      body{
          font-family: 'Helvetica', 'Arial', sans-serif;
          margin:0px;
      }
  </style>
  <style>
  @font-face {
      font-family: 'CircularStd-Book';
      src: url('../fonts/CircularStd-Book.eot') format('eot'),
           url('../fonts/CircularStd-Book.otf') format('otf'),
           url('../fonts/CircularStd-Book.ttf') format('truetype'),
           url('../fonts/CircularStd-Book.woff') format('woff'),
           url('../fonts/CircularStd-Book.svg#CircularStd-Book') format('svg');
      font-weight: normal;
      font-style: normal;
  
  }
  @font-face {
      font-family: 'CircularStd-bold';
      src: url('../fonts/CircularStd-Bold.eot') format('eot'),
           url('../fonts/CircularStd-Bold.otf') format('otf'),
           url('../fonts/CircularStd-Bold.ttf') format('truetype'),
           url('../fonts/CircularStd-Bold.woff') format('woff'),
           url('../fonts/CircularStd-Bold.svg#CircularStd-Bold') format('svg');
      font-weight: normal;
      font-style: normal;
  
  }
  .overflowhidden{
      overflow: hidden;
  }
  .ibox-content.p-xl.overflowhidden{
      padding: 0;
  }
  .wrapper-relay-invoice{
      height: 99.99%;
      display: -ms-flexbox;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-box;
      display: box;
      -ms-flex-direction: row;
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
      -ms-box-orient: horizontal;
      box-orient: horizontal;
      }
    .sidebar-relay-invoice {
        width: 100%;
        max-width: 280px;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: #dbdbdb;
        z-index: 1;
        padding: 0 20px;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        -ms-flex: 0 150px;
        -webkit-box-flex:  0;
        -moz-box-flex:  0;
        -ms-box-flex:  0;
        box-flex:  0;
    }
    
    .green-bg {
        width: 440px;
        height: 310px;
        background-color: #1ab394;
        border-radius: 50px;
        transform: rotate(-18deg);
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        -webkit-transform: rotate(-18deg);
        -moz-transform: rotate(-18deg);
        position: relative;
        z-index: 2;
        top: -50px;
        left: -90px;
        display: table;
    }
    
    .header-content {
        position: relative;
        z-index: 4;
        transform: rotate(18deg);
        -webkit-transform: rotate(18deg);
        -moz-transform: rotate(18deg);
        display: table-cell;
        vertical-align: middle;
        padding-left: 30%;
        padding-right: 65px;
    }
    
    .header-content h1 {
        text-transform: uppercase;
        font-size: 30pt;
        color: #fff;
        font-family: 'CircularStd-bold';
        letter-spacing: 4pt;
        margin-bottom: 15px;
    }
    
    .send-to {
        margin-top: 65px;
    }
    .send-to h2 {
        font-size: 24pt;
        color: #3e3e40;
        font-family: 'CircularStd-bold';
        margin-bottom: 5px;
        line-height: 10px;
    }
    .send-to h3 {
        font-size: 15pt;
        color: #3e3e40;
        font-family: 'CircularStd-bold';
        margin-bottom: 5px;
        text-transform: capitalize;
        word-break: break-all;
        line-height: 20px;
    }
    .send-to p {
        font-size: 11pt;
        color: #3e3e40;
        font-family: 'CircularStd-book';
        margin-bottom: 10px;
        text-transform: capitalize;
        line-height: 15px;
    }
    .main{
        width: calc(100% - 300px);
        -ms-flex: 1;
         -webkit-box-flex: 1;
         -moz-box-flex: 1;
         -ms-box-flex: 1;
         box-flex: 1;
    }
    .row {
        clear: both;
        display: inline-block;
        width: 90%;
    }
    
    .r-logo {
        width: 50%;
        text-align: center;
        padding: 45px 0;
    }
    .r-logo img {
        max-width: 115px;
    }
    .pull-right{
        float: right;
    }
    .invoice-info{
        display: table;
        width: 80%;
        margin-bottom: 5px;
    }
    .cell{
        display: table-cell;
    }
    .invoice-info .cell{
        font-family: 'CircularStd-bold';
        color: #fff;
        font-size: 10pt;
    }
    .payment-info {
        padding: 0 30px;
        margin: 90px 0 80px;
    }
    .payment-info h2 {
        font-size: 20pt;
        color: #3e3e40;
        font-family: 'CircularStd-bold';
        margin-bottom: 5px;
    }
    .payment-date {
        color: #1ab394;
        font-weight: bold;
        font-size: 15pt;
    }
    .payment-detail .table {
        display: table;
        width: 100%;
        max-width: 400px;
        margin: 5px 0;
    }
    
    .payment-detail .cell {
        font-size: 13pt;
        width: 60%;
        font-weight: bold;
        font-family: 'CircularStd-book';
    }
    
    .text-right{
        text-align: right;
    }
    .text-left{
        text-align: left;
    }
    .payment-detail-table {
        padding: 0 30px;
    }
    .payment-detail-table .row.no-border{
        border-bottom: 0 none;
    }
    .payment-detail-table .row {
        display: table;
        width: 100%;
        border-bottom: 1px solid #9e9e9d;
        padding: 10px 0;
        margin: 0;
    }
    .payment-detail-table .cell.title {
        font-size: 13pt;
        color: #373c4a;
        font-weight: bold;
        font-family: 'CircularStd-bold';
        margin-bottom: 5px;
        text-transform: capitalize;
    }
    .happy-service{
        margin:50px 0;
        text-align: center;
        color: #3e3e40;
        font-family: 'CircularStd-bold';
        font-size: 16pt;
    }
    .duo-upon {
        margin-top: 13.5em;
        padding: 10px 0;
        border-bottom: 2px solid #3e3e40;
        margin-bottom: 10px;
    }
    .duo-upon h2 {
        font-size: 17pt;
        line-height: 18px;
       // font-family: 'CircularStd-bold';
    }
    .font_size_left{
        font-size: 7pt;
    }
    .duo-upon .row{
        display: table;
        padding: 5px 15px;
    }
    .duo-upon .cell{
        color: #626262;
        word-break: break-all;
    }
    .col-8{
        width: 66.66666667%;
    }
    .col-4{
        width: 33.33333333%;
    }
    .address p {
        font-size: 11pt;
        color: #626262;
        font-family: 'CircularStd-book';
        line-height: 20px;
    }
    
    .address p span {
        color: #1ab394;
    }
    .once-call {
        margin: 25px 0;
        text-align: center;
    }
    .once-call img {
        max-width: 140px;
    }
    .font_size_cell{
        font-size: 11pt;
    }
    </style>
    <style>@page { size: auto; margin: 0mm; }</style>
    <title>${dataAssignPdfObject.fileName}</title>
  </head><body onload="window.print()">
      <div class="wrapper-relay-invoice">
                          <div class="sidebar-relay-invoice">
                              <div class="green-bg">
                                  <div class="header-content">
                                      <h1 class=col-xs-12>INVOICE</h1>
                                      <div class="invoice-info">
                                          <span class="cell col-xs-4">Invoice #</span>
                                          <span class="cell col-xs-8 text-right">${dataAssignPdfObject.invoiceNo}</span>
                                      </div>
                                      <div class="invoice-info">
                                          <span class="cell col-xs-4">Date</span>
                                          <span class="cell col-xs-8 text-right">${dataAssignPdfObject.dateProperties.invoiceDate}</span>
                                      </div>
                                  </div>
                              </div>
                              <div class="send-to">
                                  <h2>Invoice to:</h2><h3>${dataAssignPdfObject.orgName}</h3>
                                  <p>${dataAssignPdfObject.orgAddress}</p>
                                  <p>${dataAssignPdfObject.orgAddress1}</p>
                                  <p>${dataAssignPdfObject.orgAddress2}</p>
                              </div>
                              <div class="duo-upon">
                                  <h2>${dataAssignPdfObject.dueStatus}</h2>
                              </div>
                              <div class="address">
                                  <p>EIN: 81-0633083<br> P.O. BOX 206811<br> Dallas, TX 75320-6811<br> PH: 866-672-5797 - opt7 <br> Fax: 904-997-7173 <br> Email: <span>ARTT@onecallcm.com</span></p>
                              </div>
                          </div>
                          <div class="main">
                              <div class="row">
                                  <div class="r-logo pull-right">
                                      <img src="https://s3-us-west-2.amazonaws.com/relayhealthcare/static_assets/relay-logo-translate.png" alt="">
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="payment-info">
                                      <h2>Payment Info:</h2>
                                      <div class="payment-date">${dataAssignPdfObject.dateProperties.showDate}</div>
                                      <div class="payment-detail">
                                          <div class="table">
                                              <span class="cell">Total Due:</span>
                                              <span class="cell">$${(dataAssignPdfObject.totalAdministrativeCost + dataAssignPdfObject.totalCostOfCancellationFees + dataAssignPdfObject.totalCostOfCompltRides).toFixed(2)}</span>
                                          </div>
                                          ${dataAssignPdfObject.isLanguageInvoice
    ? `<div class="table">
                                                <span class="cell">Total Video Appointments:</span>
                                                <span class="cell"> ${dataAssignPdfObject.totalVideoAppointments.toFixed(0)}</span>
                                            </div>`
    : `<div class="table">
                                                <span class="cell">Total Appointments:</span>
                                                <span class="cell"> ${dataAssignPdfObject.totalApptCountFinal.toFixed(0)}</span>
                                            </div>`
}
                                          ${dataAssignPdfObject.isLanguageInvoice
    ? `<div class="table">
                                                <span class="cell">Total Audio Rollover Appointments:</span>
                                                <span class="cell"> ${dataAssignPdfObject.totalOpiAppointments.toFixed(0)}</span>
                                            </div>`
    : `<div class="table">
                                                <span class="cell">Total Completed Rides:</span>
                                                <span class="cell"> ${dataAssignPdfObject.totalRidesCountFinal.toFixed(0)}</span>
                                            </div>`
}
                                      </div>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="payment-detail-table">
                                      <div class="row no-border">
                                          <span class="cell title">Details</span>
                                          <span class="cell title text-right">Amount</span>
                                      </div>
                                      ${dataAssignPdfObject.isLanguageInvoice
    ? `<div class="row">
                                          <span class="cell font_size_cell">Total Cost of Video Appointments (${dataAssignPdfObject.totalVideoAppointments.toFixed(0)})</span>
                                          <span class="cell text-right font_size_cell"> $${dataAssignPdfObject.totalVideoAppointmentsCost.toFixed(2)}</span>
                                        </div>`
    : `<div class="row">
                                          <span class="cell font_size_cell">Total Cost of Completed Rides (${dataAssignPdfObject.totalRidesCountFinal.toFixed(0)})</span>
                                          <span class="cell text-right font_size_cell"> $${dataAssignPdfObject.totalCostOfCompltRides.toFixed(2)}</span>
                                        </div>`
}
                                      ${dataAssignPdfObject.isLanguageInvoice
    ? `<div class="row">
                                          <span class="cell font_size_cell">Total Cost of Audio Rollover Appointments (${dataAssignPdfObject.totalOpiAppointments.toFixed(0)})</span>
                                          <span class="cell text-right font_size_cell"> $${dataAssignPdfObject.totalOpiAppointmentsCost.toFixed(2)}</span>
                                        </div>`
    : `<div class="row">
                                          <span class="cell font_size_cell">Total Cost of Cancellation Fees (${dataAssignPdfObject.totalRidesCancelCountFinal.toFixed(0)})</span>
                                          <span class="cell text-right font_size_cell"> $${dataAssignPdfObject.totalCostOfCancellationFees.toFixed(2)}</span>
                                        </div>
                                        <div class="row">
                                            <span class="cell font_size_cell">Total Administrative Costs</span>
                                            <span class="cell text-right font_size_cell">  $${dataAssignPdfObject.totalAdministrativeCost.toFixed(2)}</span>
                                        </div>`
}
                                      <div class="row no-border">
                                          <span class="cell title">Total Due</span>
                                          <span class="cell title text-right">$${(dataAssignPdfObject.totalAdministrativeCost + dataAssignPdfObject.totalCostOfCancellationFees + dataAssignPdfObject.totalCostOfCompltRides).toFixed(2)}</span>
                                      </div>`;
  if (dataAssignPdfObject.invoicePaid) {
    pdfHtml += `<div class="row no-border">
        <span class="cell title">Total Paid</span>
        <span class="cell title text-right">$${(dataAssignPdfObject.totalAdministrativeCost + dataAssignPdfObject.totalCostOfCancellationFees + dataAssignPdfObject.totalCostOfCompltRides).toFixed(2)}</span>
    </div>`;
  }
  pdfHtml += '</div></div></div></div></body></html>';
  return pdfHtml;
}
