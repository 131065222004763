angular.module('relayHealth').controller('clearAlertPopupCtrl', [
  '$scope',
  '$rootScope',
  '$http',
  'API_BASE_URL',
  'SweetAlert',
  '$uibModalInstance',
  'loadingScreenFactory',
  'toaster',
  'rideDetails',
  function clearAlertPopupCtrl($scope, $rootScope, $http, API_BASE_URL, SweetAlert, $uibModalInstance, loadingScreenFactory, toaster, rideDetails) {
    // all scope assignments
    $scope.rideDetails = rideDetails;

    // all const functions

    // this function refreshes scheduling alerts tab on dashboard
    const refreshSchedulingAlertsTab = () => {
      $rootScope.$broadcast('refreshSchedulingAlertsTab');
    };

    // all scope functions

    // this function closes comments popup
    $scope.closePopup = () => {
      $uibModalInstance.dismiss('cancel');
    };

    // this funtion clears NDA alert and changes ride status to offer created
    $scope.clearNoDriverAssignedAlert = () => {
      if (!$scope.rideDetails.comments || !$scope.rideDetails.comments.trim()) {
        toaster.pop({
          type: 'error',
          title: 'Comments are mandatory',
          showCloseButton: true,
          timeout: 10000,
        });
        return;
      }
      const clearNoDriverAssignedAlertUrl = `${API_BASE_URL}ride/update/${$scope.rideDetails.id}`;
      const params = {
        comments: $scope.rideDetails.comments,
        ride_status: 'Offer Created',
      };
      if ($scope.rideDetails.ride_status === 'Offered NDA') {
        params.ride_status = 'Offered';
        params.ride_category = 'processing';
      }
      loadingScreenFactory.showLoadingScreen();
      const clearNoDriverAssignedAlertCall = $http.put(clearNoDriverAssignedAlertUrl, params);
      clearNoDriverAssignedAlertCall.then((result) => {
        loadingScreenFactory.hideLoadingScreen();
        if (result.data.success) {
          SweetAlert.swal({
            title: 'Alert addressed!',
            text: 'Alert addressed Successfully',
            type: 'success',
          });
          refreshSchedulingAlertsTab();
          $scope.closePopup();
        }
      }, (err) => {
        loadingScreenFactory.hideLoadingScreen();
        toaster.pop({
          type: 'error',
          title: err.data.message,
          showCloseButton: true,
          timeout: 10000,
        });
      });
    };
  }]);
