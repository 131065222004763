angular.module('relayHealth').controller('SavedExportReqsCtrl', [
  '$scope',
  '$state',
  '$http',
  'API_BASE_URL',
  'loadingScreenFactory',
  'SweetAlert',
  function SavedExportReqsCtrl($scope, $state, $http, API_BASE_URL, loadingScreenFactory, SweetAlert) {
    const watcherRemovers = [];

    function convertToDM(inData) {
      return {
        id: inData.id,
        name: inData.name,
        serviceType: inData.query.serviceType ? inData.query.serviceType.label : '',
        query: inData.query,
        createdAt: inData.createdAt,
      };
    }

    function init() {
      $scope.dataRefresh = { value: false };
      $scope.dataRefetch = false;
      $scope.isLoading = false;
      $scope.searchTxt = '';
    }

    $scope.fetchData = (paginationParams) => {
      $scope.isLoading = true;
      const params = Object.assign({}, paginationParams);
      if ($scope.searchTxt) {
        params.search = $scope.searchTxt;
      }
      return $http
        .get(`${API_BASE_URL}saved_exports`, {
          params,
        })
        .then((resp) => {
          if (resp.data) {
            $scope.exportReqsData = _.map(resp.data.data, d => convertToDM(d));
          } else {
            $scope.exportReqsData = [];
          }
          return resp.data;
        })
        .finally(() => {
          $scope.isLoading = false;
        });
    };

    $scope.refreshData = () => {
      $scope.dataRefresh.value = true;
    };

    $scope.searchData = (e) => {
      if (!e || e.keyCode === 13) {
        // search data iff, either button pressed or enter pressed
        $scope.dataRefetch = true;
      }
    };

    $scope.selectItem = (exportReq) => {
      $scope.$emit('exportSelected', exportReq);
    };

    $scope.removeItem = (exportReqId) => {
      if (exportReqId) {
        SweetAlert.swal(
          {
            title: 'Are you sure you want to delete this item ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonClass: 'btn-warning',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            closeOnConfirm: true,
            closeOnCancel: true,
          },
          (isConfirm) => {
            if (isConfirm) {
              $scope.isLoading = true;
              $http
                .delete(`${API_BASE_URL}saved_exports/${exportReqId}`)
                .then((resp) => {
                  if (resp.data) {
                    $scope.refreshData();
                  }
                  return resp.data;
                })
                .finally(() => {
                  $scope.isLoading = false;
                });
            }
          },
        );
      }
      return false;
    };

    watcherRemovers.push(
      $scope.$on('refreshSavedExports', () => {
        $scope.refreshData();
      }),
    );

    watcherRemovers.push(
      $scope.$watch('isLoading', (n) => {
        if (n) {
          loadingScreenFactory.showLoadingScreen();
        } else {
          loadingScreenFactory.hideLoadingScreen();
        }
      }),
    );

    $scope.$on('$destroy', () => {
      angular.forEach(watcherRemovers, (w) => {
        w();
      });
    });

    init();
  },
]);
