// Mapping for appointment status from DB to UI


const appointmentStatusMappings = {
    'Patient No Show': 'Rider No Show',
    'Appointment Canceled':  'Trip Canceled',
    'Appointment Rejected':  'Trip Rejected',
    'Appointment Completed': 'Trip Completed',
};

export default function getAppointmentStatusMapping(appointmentStatus){
    const appointmentStatuses = Object.keys(appointmentStatusMappings);
    if (appointmentStatuses.includes(rideStatus)) {
        return appointmentStatusMappings[appointmentStatus];
    }
    return appointmentStatus;
}
