angular.module('relayHealth').controller('SaveNewExportReqCtrl', [
  '$scope',
  '$uibModalInstance',
  'queryInfo',
  '$http',
  'API_BASE_URL',
  'toaster',
  '$transitions',
  function SaveNewExportReqCtrl(
    $scope,
    $uibModalInstance,
    queryInfo,
    $http,
    API_BASE_URL,
    toaster,
    $transitions,
  ) {
    const watcherRemovers = [];

    function init() {
      let orgs = [];
      if (queryInfo.organizations.length) {
        orgs = queryInfo.organizations;
      } else if (queryInfo.inactiveOrganizations.length) {
        orgs = queryInfo.inactiveOrganizations;
      }
      if (queryInfo) {
        const reqData = {
          marketSegments: queryInfo.marketSegments && _.map(queryInfo.marketSegments, 'id'),
          accessTypes: queryInfo.accessTypes && _.map(queryInfo.accessTypes, 'id'),
          organisations: _.map(orgs, 'id'),
          selectedFields: angular.copy(queryInfo.selectedFields),
          serviceType: queryInfo.serviceType && angular.copy(queryInfo.serviceType),
          orgsType: queryInfo.orgsType || 'active',
        };
        reqData.dateRange = {
          startDate: queryInfo.dateRange.startDate.toDate().getTime(),
          endDate: queryInfo.dateRange.endDate.toDate().getTime(),
        };
        $scope.dm = {
          name: '',
          query: reqData,
        };
      } else {
        $scope.dm = {
          name: '',
          query: null,
        };
      }
      $scope.isSaving = false;
      $scope.nameExistsErr = false;
    }

    function reset() {
      $scope.dm = {
        name: '',
        query: null,
      };
      $scope.isSaving = false;
      $scope.nameExistsErr = false;
      if ($scope.saveNewExportForm) {
        $scope.saveNewExportForm.$setPristine();
      }
    }

    $scope.saveNew = () => {
      if ($scope.saveNewExportForm.$valid) {
        // Create case
        $scope.isSaving = true;
        const data = angular.copy($scope.dm);
        // Trim name
        data.name = data.name.trim();
        $http
          .post(`${API_BASE_URL}saved_exports`, data)
          .then(
            (resp) => {
              toaster.pop({
                type: 'success',
                title: 'Saved successfully.',
                showCloseButton: true,
              });
              $uibModalInstance.close(resp);
            },
            (err) => {
              if (err.status === 400 && err.data && err.data.code === 'nameExists') {
                $scope.nameExistsErr = true;
              }
            },
          )
          .finally(() => {
            $scope.isSaving = false;
          });
      } else {
        // Form data invalid. Do nothing
      }
    };

    $scope.cancel = () => {
      reset();
      $uibModalInstance.dismiss('cancel');
    };

    watcherRemovers.push(
      $transitions.onStart({}, () => {
        $scope.cancel();
      }),
    );

    $scope.$on('$destroy', () => {
      angular.forEach(watcherRemovers, (w) => {
        w();
      });
    });

    init();
  },
]);
