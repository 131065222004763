// Toggle switch plugin
import 'angular-bootstrap-toggle-switch/dist/js/bootstrap-switch.min';
import 'angular-bootstrap-toggle-switch/dist/css/bootstrap3/bootstrap-switch.css';

// Device detector plugin
import 're-tree';
import 'ua-device-detector';
import 'ng-device-detector';

// Toaster plugin
import 'angularjs-toaster';

// infinite scroll plugin
import ngInfiniteScroll from 'ng-infinite-scroll';

// isteven/angular-multi-select
import 'isteven-angular-multiselect/isteven-multi-select';
import 'isteven-angular-multiselect/isteven-multi-select.css';

// angular-xeditable plugin for inline-edit
import 'angular-xeditable';
import 'angular-xeditable/dist/css/xeditable.min.css';

// angularjs moment picker - https://indrimuska.github.io/angular-moment-picker/
import 'angular-moment-picker';
import 'angular-moment-picker/dist/angular-moment-picker.css';

import breadcrumb from './breadcrumb/breadcrumbMain';
import pagination from './paginate/paginateMain';
import selectPaged from './selectPaged/selectPagedMain';
import affixDiv from './affix';

export default angular.module('relayComps', [
  breadcrumb,
  pagination,
  'ng.deviceDetector',
  'toggle-switch',
  ngInfiniteScroll,
  'isteven-multi-select',
  selectPaged,
  affixDiv,
  'xeditable',
  'moment-picker',
]).name;
