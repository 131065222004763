import userRoles from './userRoles';
import serviceTypes from './serviceTypes';
import rideStatusKeyMappings from './rideStatusKeyMappings';
import tripTypeConstants from './tripTypeConstants';
import unitsConstant from './unitsConstant';
import rideAdditionalDetailsMapping from './rideAdditionalDetailsMapping';
import userRolesDisplayTextMapping from './userRolesDisplayTextMapping';
import rideFlags from './rideFlags';
import providerIdMapping from './providerIdMapping';
import featureFlags from './featureFlags';
import billingOptionMapping from './billingOptionMapping';
import appointmentEntityTypes from './appointmentEntityTypes';
import keyValueMapping from './keyValueMapping';
import billingFeeServiceMapping from './billingFeeServiceMapping';

export default {
    userRoles,
    serviceTypes,
    tripTypeConstants,
    unitsConstant,
    rideAdditionalDetailsMapping,
    userRolesDisplayTextMapping,
    rideFlags,
    providerIdMapping,
    featureFlags,
    billingOptionMapping,
    rideStatusMapping: rideStatusKeyMappings,
    appointmentEntityTypes,
    keyValueMapping,
    billingFeeServiceMapping
};
