// Ride Status Mappings from Database to display in UI
const rideStatusMappings = {
    'Patient No Show' : {
        default: 'Rider No Show',
        active: 'Pending Ride Request',
    },
    'Ride Completed': {
        default: 'Ride Completed',
        active: 'Pending Ride Request',
    },
    'Arrival Time Exceeded': {
        default: 'Late Arrival',
    },
    'Patient Enroute':{
        default: 'Rider En Route'
    },
    'Late Arrival':{
        default: 'Rider En Route-Late Arrival'
    },
    'Provider No Show': {
        default: 'Provider No Show',
        active: 'Pending Ride Request',
    }
};

export default function getRideStatusMapping(rideStatus, rideCategory) {
    const rideStatuses = Object.keys(rideStatusMappings);
    if (rideStatuses.includes(rideStatus)) {
        const displayRideStatus = rideStatusMappings[rideStatus][rideCategory];
        return displayRideStatus ? displayRideStatus : rideStatusMappings[rideStatus].default;
    }
    return rideStatus;
}