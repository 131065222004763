const { intlTelInputUtils } = window;
const { userRoles } = relaylib.common.constants;

angular.module('relayHealth').controller('userOrgListCntrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  '$state',
  'toaster',
  'loadingScreenFactory',
  'isMasterOrgFilter',
  'permissionFilter',
  'displayRoleFilter',
  'WORKERS_COMPENSATION_KEY',
  'HEALTHCARE_MASS_MARKET_KEY',
  function userOrgListCntrl(
    $scope,
    $http,
    API_BASE_URL,
    $state,
    toaster,
    loadingScreenFactory,
    isMasterOrgFilter,
    permissionFilter,
    displayRoleFilter,
    WORKERS_COMPENSATION_KEY,
    HEALTHCARE_MASS_MARKET_KEY,
  ) {
    $scope.permissionsConstants = relaylib.permissions.permissionsConstants;
    $scope.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    $scope.showAddUserForm = false;

    function adjustPagination() {
      $scope.pagination.lastItem = ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage
        + 20;
      if ($scope.pagination.lastItem > $scope.pagination.totalItems) {
        $scope.pagination.lastItem = $scope.pagination.totalItems;
      }
    }

    function getRole({ name: roleName }) {
      const nameLowerCased = roleName.toLowerCase();

      const accessType = $scope.accessTypeList.find(({
        name,
      }) => name.toLowerCase() === nameLowerCased);

      return accessType.type;
    }

    $scope.addUser = {};
    $scope.pagination = {
      totalItems: 0,
      currentPage: 1,
      lastItem: 0,
      itemsPerPage: 20,
    };
    const { userDetails } = $scope.userDetails;

    function getOrgData() {
      const res = $http({
        url: `${API_BASE_URL}organisation/getOrganizations/all/${
          ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage
        }/${
          $scope.pagination.itemsPerPage
        }`,
        method: 'GET',
        params: {
          orgId: userDetails.organisation.id,
        },
      });
      loadingScreenFactory.showLoadingScreen();
      res.then(({ data }) => {
        loadingScreenFactory.hideLoadingScreen();
        if (data.success) {
          $scope.orgList = data.org_details;
          $scope.pagination.totalItems = data.totalCount;
          adjustPagination();
        } else {
          $scope.pagination.totalItems = 0;
          adjustPagination();
          toaster.pop({
            type: 'error',
            title: data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        }
      });
      res.catch(() => {
        loadingScreenFactory.hideLoadingScreen();
        $scope.pagination.totalItems = 0;
        adjustPagination();
        toaster.pop({
          type: 'error',
          title: 'Not able to get staffmember data',
          showCloseButton: true,
          timeout: 10000,
        });
      });
    }

    $scope.pageChanged = function pageChanged() {
      getOrgData();
    };

    $scope.canceClicked = function canceClicked() {
      $scope.addUser = {};
      $scope.showAddUserForm = false;
    };

    $scope.setAddUserForm = function setAddUserForm(addUserform) {
      $scope.addUserForm = addUserform;
    };

    $scope.addOnEnter = function addOnEnter(event) {
      if ($scope.addUserForm.$valid && event.keyCode === 13) {
        $scope.addUserClicked();
      }
    };

    $scope.validatePhoneNumber = () => {
      const phoneNo = $('#txtPhoneNo').intlTelInput('getNumber', window.intlTelInputUtils.numberFormat.E164);
      return window.intlTelInputUtils.isValidNumber(phoneNo);
    };

    $scope.addUserClicked = function addUserClicked() {
      let role = '';
      if ($scope.addUser.accessType) {
        role = getRole($scope.addUser.accessType);
      } else {
        role = undefined;
      }
      const userPhone = $('#txtPhoneNo').intlTelInput(
        'getNumber',
        intlTelInputUtils.numberFormat.E164,
      );
      const serverData = {
        org_id: $scope.addUser.organisation
          ? $scope.addUser.organisation.id
          : undefined,
        phone: userPhone,
        username: $scope.addUser.userEmail,
        role,
        firstName: $scope.addUser.firstName,
        lastName: $scope.addUser.lastName,
      };
      const addUserCall = $http.post(`${API_BASE_URL}users/add`, serverData);
      loadingScreenFactory.showLoadingScreen();
      addUserCall.then(
        ({
          data: { success, message, user_id: userId },
        }) => {
          loadingScreenFactory.hideLoadingScreen();
          if (success) {
            toaster.pop({
              type: 'success',
              title: 'User added successfully',
              showCloseButton: true,
              timeout: 10000,
            });
            $scope.addUser = {};
            if (role !== 'org_superadmin' && role !== 'orgadmin') {
              $state.transitionTo('main.userMgmt.manageUser', {
                userId,
              });
            }
          } else {
            Object.keys(message)
              .forEach(key => toaster.pop({
                type: 'error',
                title: message[key],
                showCloseButton: true,
                timeout: 10000,
              }));
          }
        },
        ({ data: { message } }) => {
          loadingScreenFactory.hideLoadingScreen();
          Object.keys(message)
            .forEach(key => toaster.pop({
              type: 'error',
              title: message[key],
              showCloseButton: true,
              timeout: 10000,
            }));
        },
      );
    };

    $scope.getAllOrganizations = function getAllOrganizations(offset, limit) {
      return $http
        .get(
          `${API_BASE_URL}organisation/getOrganizations/active/${offset}/${limit}`,
        )
        .then((resp) => {
          let response = resp.data;
          response = (response.org_details || []).sort((a, b) => a.name.localeCompare(b.name));
          return {
            data: response,
            count: resp.data.totalCount,
          };
        });
    };

    getOrgData();

    $scope.getStatus = function getStatus(isDeleted) {
      if (isDeleted) {
        return 'Inactive';
      }
      return 'Active';
    };

    $scope.orgChange = function orgChange(org) {
      $scope.addUser.accessType = '';
      if (isMasterOrgFilter(org.org_level)) {
        const loggedInUserDetails = JSON.parse(
          localStorage.getItem('userDetails'),
        );
        if (
          (loggedInUserDetails.role
            && loggedInUserDetails.role === 'mastersuperadmin')
          || permissionFilter($scope.permissionsConstants.USER_UPDATE_ANY)
        ) {
          $scope.accessTypeList = [
            {
              type: userRoles.PRODUCTION_OPERATION,
              name: displayRoleFilter(userRoles.PRODUCTION_OPERATION),
            },
            { type: 'admin', name: 'Ride Manager Admin' },
            { type: 'mastersuperadmin', name: 'Master Superadmin' },
            { type: 'superadmin', name: 'Superadmin' },
            { type: 'Scheduling admin', name: 'Scheduling Admin' },
            { type: userRoles.OC_REPORTING, name: displayRoleFilter(userRoles.OC_REPORTING) },
            {
              type: userRoles.NETWORK_OPERATION,
              name: displayRoleFilter(userRoles.NETWORK_OPERATION),
            },
          ];
        } else {
          $scope.accessTypeList = [
            { type: 'admin', name: 'Ride Manager Admin' },
            { type: 'superadmin', name: 'Superadmin' },
            { type: 'Scheduling admin', name: 'Scheduling Admin' },
            { type: userRoles.OC_REPORTING, name: displayRoleFilter(userRoles.OC_REPORTING) },
          ];
        }
      } else {
        $scope.accessTypeList = [
          { type: 'orgadmin', name: 'User' },
          { type: 'org_superadmin', name: 'Admin' },

        ];
        if ([WORKERS_COMPENSATION_KEY, HEALTHCARE_MASS_MARKET_KEY].includes(org.market_segment.key)) {
          $scope.accessTypeList.push({ type: userRoles.ORG_REPORTING, name: displayRoleFilter(userRoles.ORG_REPORTING) });
        }
      }
    };
  },
]);
