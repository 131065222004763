function thankYouCtrl($scope,
  $state,
  signupStateService,
  $document,
  $rootScope,
  pubnubNotificationFactory,
  $http,
  $window,
  toaster,
  API_BASE_URL,
  SITE_NAME) {
  const [document] = $document;
  const { location } = $window;

  $scope.state = {};

  function init() {
    // signupAuthCheckService.thankYouAllowed();

    try {
      $scope.state = signupStateService.getState('signupState');
      if (!$scope.state) {
        $state.go('main.dashboard.superAdminDashboard');
        return;
      }

      $scope.state.latestStage = 8;
      signupStateService.setState('signupState', $scope.state);
    } catch (error) {
      $state.go('main.dashboard.superAdminDashboard');
    }
  }

  init();

  $scope.goToLogin = () => {
    signupStateService.clearState('signupState');
    signupStateService.clearState('tempUser');
    if ($scope.state.signupType === 'business' && !$scope.state.maskedStripeCardNumber && $scope.state.monthlyInvoice) {
      $scope.logout();
      return;
    }
    $state.go('main.dashboard.superAdminDashboard', {}, { reload: true });
  };

  $scope.goToRelaySolutions = () => {
    signupStateService.clearState('userDetails');
    signupStateService.clearState('signupState');
    signupStateService.clearState('tempUser');
    window.location = 'https://www.relaysolutions.com/';
  };

  $scope.logout = () => {
    document.title = `${SITE_NAME} | Login`;
    $rootScope.$broadcast('removeClickHandler');
    $scope.$emit('logout');
    pubnubNotificationFactory.unsubscribeAllChannel();
    const res = $http.put(`${API_BASE_URL}user/logout`, {});
    res.then(({ data }) => {
      if (data.success) {
        localStorage.clear();
        location.href = `/${process.env.PUBLIC_URL_PREFIX}current/login`;
        return;
      }
      toaster.pop({
        type: 'error',
        title: data.message,
        showCloseButton: true,
        timeout: 10000,
      });
    });
    res.catch(() => toaster.pop({
      type: 'error',
      title: 'Not able to logout',
      showCloseButton: true,
      timeout: 10000,
    }));
  };
}

angular.module('relayHealth').controller('thankYouCtrl', [
  '$scope',
  '$state',
  'signupStateService',
  '$document',
  '$rootScope',
  'pubnubNotificationFactory',
  '$http',
  '$window',
  'toaster',
  'API_BASE_URL',
  'SITE_NAME',
  thankYouCtrl,
]);
