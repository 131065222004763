angular.module('relayHealth').constant('NG_QUILL_CONFIG', {
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'link'],
    ],
  },
  theme: 'snow',
  debug: 'warn',
  placeholder: '',
  readOnly: false,
  bounds: document.body,
  scrollContainer: null,
}).config([
  'ngQuillConfigProvider',
  'NG_QUILL_CONFIG',
  function config(ngQuillConfigProvider, NG_QUILL_CONFIG) {
    ngQuillConfigProvider.set(NG_QUILL_CONFIG);
  },
]);
