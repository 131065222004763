const caseStatusMappings = {
    completed: {
        vendor_portal_string_name:  "yes",
        value:  1
    },
    cancelled: {
        vendor_portal_string_name:  "Cancelled",
        value:  2
    },
    'Patient No Show': {
        vendor_portal_string_name:  "NoShow",
        value:  6
    },
    'Provider No Show': {
        vendor_portal_string_name:  "Missed",
        value:  3
    },
    'Ride Completed': {
        vendor_portal_string_name:  "Yes",
        value:  1
    },
    'Other': {
        vendor_portal_string_name: 'Other',
        value: 5
    }
};
Object.freeze(caseStatusMappings);
export default caseStatusMappings;