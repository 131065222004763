function invoiceSummaryController(
  $scope,
  $http,
  API_BASE_URL,
  toaster,
  INVOICE_TYPES,
  isOCCMRole,
  loadingScreenFactory,
  $state,
  $stateParams,
) {
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));

  this.isOccmRole = isOCCMRole();
  this.invoiceList = [];
  this.months = moment.months();
  this.pagination = {
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 10,
  };

  this.getInvoiceList = async (activeTab) => {
    if (!this.tabs[activeTab].filter.organization) {
      return;
    }

    $state.go('.', {
      org: this.tabs[activeTab].filter.organization.id,
      tab: activeTab,
    });

    loadingScreenFactory.showLoadingScreen();
    try {
      const {
        data: {
          result: {
            rows: invoiceList,
            count,
          },
        },
      } = await $http({
        url: `${
          API_BASE_URL
        }monthlyInvoices?limit=${
          this.pagination.itemsPerPage
        }&offset=${
          (this.pagination.currentPage - 1) * this.pagination.itemsPerPage
        }&org_id=${
          this.tabs[activeTab].filter.organization.id
        }&invoice_type=${
          activeTab === 'transportation' ? INVOICE_TYPES.RELAY : INVOICE_TYPES.LANGUAGE
        }`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      });

      this.invoiceList.length = 0;
      this.invoiceList.push(...invoiceList);
      this.pagination.totalItems = count;
    } catch (err) {
      toaster.pop({
        type: 'error',
        title: err.data.msg,
        showCloseButton: true,
        timeout: 10000,
      });
    } finally {
      loadingScreenFactory.hideLoadingScreen();
      $scope.$apply();
    }
  };

  const getAllOrganizations = async () => {
    loadingScreenFactory.showLoadingScreen();
    const body = {
      params: {
        include_monthly_invoices: true,
      },
    };
    if (userDetails.belongsToParentOrg) {
      body.params.orgId = userDetails.organisation.id;
    }
    try {
      const {
        data: {
          success,
          org_details: orgDetails,
          message,
        },
      } = await $http.get(`${API_BASE_URL}organisation/getOrganizations`, body);

      if (!success) {
        throw new Error(message);
      }
      this.orgList = orgDetails.map(({ id, name }) => ({ id, name }));

      if (userDetails.role === 'org_superadmin' && this.isOccmRole) {
        const userOrg = orgDetails.find(({ id }) => id === userDetails.org_id);
        if (userOrg && userOrg.rides_monthly_invoices && userOrg.rides_monthly_invoices.length) {
          this.tabs.translation.show = !userOrg.rides_monthly_invoices
            .find(({
              invoice_type: invoiceType,
            }) => invoiceType === INVOICE_TYPES.LANGUAGE);
        }
      }
    } catch (err) {
      toaster.pop({
        type: 'error',
        title: err,
        showCloseButton: true,
        timeout: 10000,
      });
    } finally {
      loadingScreenFactory.hideLoadingScreen();
      $scope.$apply();
    }
  };

  (async () => {
    await getAllOrganizations();


    const orgId = $stateParams.org ? parseInt($stateParams.org, 10) : userDetails.organisation.id;
    const organization = this.orgList.find(({ id }) => id === orgId);

    this.tabs = {
      transportation: {
        heading: 'Transportation',
        filter: {
          organization,
        },
        active: $stateParams.tab ? $stateParams.tab === 'transportation' : true,
        show: true,
      },
      translation: {
        heading: 'Translation',
        filter: {
          organization,
        },
        active: $stateParams.tab === 'translation',
        show: true,
      },
    };

    $scope.$apply();
  })();
}

angular.module('relayHealth').controller('invoiceSummaryController', [
  '$scope',
  '$http',
  'API_BASE_URL',
  'toaster',
  'INVOICE_TYPES',
  'isOCCMRole',
  'loadingScreenFactory',
  '$state',
  '$stateParams',
  invoiceSummaryController,
]);
