/* eslint-disable no-param-reassign */
angular.module('relayHealth').controller('orderInfoCtrl', [
  '$scope',
  '$state',
  '$uibModal',
  'setMapPoints',
  'toaster',
  '$timeout',
  '$stateParams',
  '$http',
  'API_BASE_URL',
  'loadingScreenFactory',
  'SweetAlert', function orderInfoCtrl(
    $scope,
    $state,
    $uibModal,
    setMapPoints,
    toaster,
    $timeout,
    $stateParams,
    $http,
    API_BASE_URL,
    loadingScreenFactory,
    SweetAlert,
  ) {
    // Note: This controller inherit scope from rideOrderCtrl..
    // open modal functions:
    let UATPModal;
    let timeout;
    let sendOfferModalInstance;
    let outOfNetworkModal;
    let map;
    const { caller }                 = $stateParams;
    const bookWithRideShareProviders = () => {
      SweetAlert.swal({
        title: '',
        text: 'Do you want to confirm order with selected Ride Share Provider?',
        type: 'warning',
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes',
        closeOnCancel: true,
        cancelButtonText: 'No',
        showCancelButton: true,
      }, (confirmed) => {
        if (confirmed) {
          const selectedVendors = [];
          for (let i = 0; i < $scope.rideDetails.availableVendors.vendorList.length; i += 1) {
            if ($scope.rideDetails.availableVendors.vendorList[i].isRideShareVendor && $scope.rideDetails.availableVendors.vendorList[i].selected) {
              selectedVendors.push({
                vendorCode: $scope.rideDetails.availableVendors.vendorList[i].providerId,
                vendorFuseId: $scope.rideDetails.availableVendors.vendorList[i].fuseId,
              });
            }
          }
          loadingScreenFactory.showLoadingScreen();
          const apiCall = $http.put(`${API_BASE_URL}order/${$scope.rideDetails.orderInfo.orderId}/ride-share-offers/confirm`, {
            providers: selectedVendors,
          });

          apiCall.then((result) => {
            if (!result.data.success) {
              toaster.pop({
                type: 'error',
                title:
                result.data.message
                || 'Unable to confirm order with selected provider',
                timeout: 3000,
              });
            }
          }).catch((error) => {
            toaster.pop({
              type: 'error',
              title:
              error.message || error.data.message
              || 'Unable to confirm order with selected provider',
              timeout: 3000,
            });
          }).finally(() => {
            $scope.updateFullOrder();
            loadingScreenFactory.hideLoadingScreen();
            $scope.$apply();
          });
        }
      });
    };
    const setMap                     = function setMap() {
      if (map) return;
      map             = new google.maps.Map(document.getElementById('orderInfoMap'), { zoom: 12 });
      const markers   = [];
      const mapPoints = {
        source: {
          latitude: $scope.rideDetails.tripInfo.source_lat_lng[0],
          longitude: $scope.rideDetails.tripInfo.source_lat_lng[1],
        },
        destination: {
          latitude: $scope.rideDetails.tripInfo.dest_lat_lng[0],
          longitude: $scope.rideDetails.tripInfo.dest_lat_lng[1],
        },
      };
      setMapPoints(map, markers, mapPoints);
    };

    $scope.providerMapping = relaylib.common.constants.providerIdMapping;
    $scope.vendorCTAs      = {
      actions: [
        {
          actionText: 'Select status',
          noAction: true,
          categoryVisibility: ['rideShareProviders', 'withoutSoftwareId', 'withSoftwareId'],
        },
        {
          actionText: '24 Hour Confirm',
          statusCode: 8,
          isAcceptance: true,
          categoryVisibility: ['withoutSoftwareId', 'withSoftwareId'],
        },
        {
          actionText: 'Accepted',
          statusCode: 7,
          isAcceptance: true,
          categoryVisibility: ['withoutSoftwareId', 'withSoftwareId'],
        },
        {
          actionText: 'Rejected',
          statusCode: 10,
          isAcceptance: true,
          categoryVisibility: ['withoutSoftwareId', 'withSoftwareId'],
        },
        {
          actionText: 'Rejected',
          statusCode: 101,
          isAcceptance: true,
          categoryVisibility: ['rideShareProviders'],
        },
        {
          actionText: 'Rescind',
          isRescind: true,
          categoryVisibility: ['withoutSoftwareId', 'withSoftwareId'],
        },
      ],
      disableAll: false,
    };

    // todo Move both functions to a single file as it is also getting used in "src/current/sub-modules/mas/controller.js"

    function openNotesPopup(modalData) {
      const notesModal = $uibModal.open({
        animation: true,
        template: require('../views/notes.html'),
        controller: 'notesCtrl',
        controllerAs: '$notesCtrl',
        size: 'md',
        backdrop: 'static',
        resolve: {
          data() {
            return modalData;
          },
        },
      });
      return new Promise((resolve, reject) => {
        notesModal.result.then(resolve, reject);
      });
    }

    function routeToPreviousPage() {
      let stateToGo = 'main.dashboard.scheduled';
      if (caller === 'AR') {
        stateToGo = 'main.dashboard.active';
      } else if (caller === 'dashboard') {
        stateToGo = 'main.dashboard.superAdminDashboard';
      }

      $state.go(stateToGo);
    }

    $scope.clearAlert = async () => {
      const rideUpdateUrl  = `${API_BASE_URL}ride/clear_from_view`;
      const notesModalData = {
        heading: 'Clear Alert',
        confirmationLine: 'Are you sure you want to clear the Potential Unavailability for this trip permanently?',
        cta: 'Clear Alert',
      };
      const params         = {
        ride_id: Number($stateParams.rideId),
        ctaType: 'Keep Trip',
        snooze: true,
      };
      const notes          = await openNotesPopup(notesModalData);
      if (notes) params.comments = notes;
      loadingScreenFactory.showLoadingScreen();

      try {
        const { data: { success } = {} } = await $http.put(rideUpdateUrl, params);
        if (success) {
          SweetAlert.swal(
            {
              title: 'Trip Cleared Successfully.',
              text: 'Trip Cleared Successfully.',
              type: 'success',
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'OK',
              closeOnConfirm: true,
            },
            (isConfirm) => { if (isConfirm) routeToPreviousPage(); },
          );
        }
      } catch (err) {
        const message = err.data ? err.data.message : err.message;
        toaster.error({
          type: 'error',
          title: message,
          showCloseButton: true,
          timeout: 10000,
        });
      } finally {
        loadingScreenFactory.hideLoadingScreen();
      }
    };

    /**
     * This is used for disposition scenario.
     * Please feel free to update comment if enhancing or chaning.
     */
    $scope.openUpdateRideStatusModal = () => {
      const { legToUpdate } = $scope.rideDetails;

      $uibModal.open({
        animation: true,
        template: require('../views/comment-modal.html'),
        controller: [
          '$scope', 'rideDetails', 'commentModaltitle', '$uibModalInstance',
          function modalController(scope, rideDetails, commentModaltitle, uibModalInstance) {
            scope.rideDetails       = Object.assign({}, rideDetails);
            scope.commentModaltitle = commentModaltitle;

            // this function closes comments popup
            scope.closePopup         = () => {
              uibModalInstance.close();
            };
            scope.commentModalSubmit = () => {
              const url    = `${API_BASE_URL}ride/update/${scope.rideDetails.id}`;
              const params = {
                comments: scope.rideDetails.comments,
                ride_status: 'Confirmed',
                ride_category: 'scheduled',
                send_update_to_vp: true,
              };

              loadingScreenFactory.showLoadingScreen();
              const rideUpdated = $http.put(url, params);
              rideUpdated.then((result) => {
                loadingScreenFactory.hideLoadingScreen();
                if (result.data.success) {
                  SweetAlert.swal({
                    title: 'Alert addressed!',
                    text: 'Alert addressed Successfully',
                    type: 'success',
                  });
                  scope.closePopup();
                  $scope.providerTabs.isDispositionUpdate = false;
                }
              }, (err) => {
                loadingScreenFactory.hideLoadingScreen();
                toaster.pop({
                  type: 'error',
                  title: err.data.message,
                  showCloseButton: true,
                  timeout: 10000,
                });
              });
            };
          }],
        size: 'md',
        backdrop: 'static',
        resolve: {
          rideDetails() {
            return { id: legToUpdate.ride_id };
          },
          commentModaltitle() {
            return 'Confirm Update Order';
          },
        },
      });
    };

    $scope.openSendOfferModal = function openSendOfferModal(isOrderUpdate) {
      if ($scope.rideDetails.availableVendors.rideShareSelectedCount) {
        bookWithRideShareProviders();
      } else {
        let notProceed = true;
        if (isOrderUpdate) {
          for (let i = 0; i < $scope.rideDetails.availableVendors.vendorList.length; i += 1) {
            if ($scope.rideDetails.availableVendors.vendorList[i].selected) {
              notProceed = false;
              break;
            }
          }
        } else {
          for (let i = 0; i < $scope.rideDetails.availableVendors.vendorList.length; i += 1) {
            if ($scope.rideDetails.availableVendors.vendorList[i].selected && !$scope.rideDetails.availableVendors.vendorList[i].checkDisabled) {
              notProceed = false;
              break;
            }
          }
        }
        if (notProceed) {
          toaster.pop({
            type: 'error',
            title: 'Please select atleast one provider to proceed.',
            timeout: 3000,
          });
          return;
        }
        sendOfferModalInstance = $uibModal.open({
          animation: true,
          template: require('../views/ride-order-send-offer-modal.html'),
          controller: 'rideOrderSendOfferCtrl',
          size: 'md',
          backdrop: 'static',
          resolve: {
            rideDetails() {
              return $scope.rideDetails;
            },
            isOrderUpdate() {
              return isOrderUpdate;
            },
            isHR68() {
              return $scope.isHR68;
            },
          },
        });
        sendOfferModalInstance.result.then(() => {
          $scope.updateFullOrder();
        }, () => {
          $scope.updateFullOrder();
        });
      }
    };

    $scope.openUATPModal = function openUATPModal() {
      UATPModal = $uibModal.open({
        animation: true,
        backdrop: 'static',
        keyboard: false,
        template: require('../views/ride-order-uatp-modal.html'),
        controller: 'rideOrderUATPCtrl',
        size: 'md',
        resolve: {
          rideDetails() {
            return $scope.rideDetails;
          },
        },
      });
      UATPModal.result.then(() => {
        // todo jaspreet -  go to another state , remove this code if implemented inside modal.
      }, () => {
        // need to implement this too
      });
    };

    $scope.orderStatusChange = function orderStatusChange(vendor) {
      if (!vendor || !vendor.selectedAction || vendor.selectedAction.noAction) {
        return;
      }
      SweetAlert.swal(
        {
          title: '',
          text: vendor.isRideShareVendor
            ? `Are you sure, you want to ${vendor.selectedAction.actionText === 'Rejected' ? 'reject' : 'cancel'
            } selected Ride Share Provider?`
            : 'Are you sure, you want to change the status of the offer?',
          type: 'warning',
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes',
          closeOnCancel: true,
          cancelButtonText: 'No',
          showCancelButton: true,
        },
        async (confirmed) => {
          if (!confirmed) {
            Object.assign(vendor, { selectedAction: $scope.vendorCTAs.actions[0] });
            return;
          }
          try {
            loadingScreenFactory.showLoadingScreen();
            $scope.vendorCTAs.disableAll = true;
            let apiCall;
            const action                 = vendor.selectedAction;
            if (action.isRescind) {
              if (vendor.isRideShareVendor) {
                apiCall = $http.put(`${API_BASE_URL}order/${$scope.rideDetails.orderInfo.orderId}/ride-share-offers/cancel`);
              } else {
                apiCall = $http.post(`${API_BASE_URL}order/${$scope.rideDetails.orderInfo.orderId}/offers/send`, {
                  offers: [
                    {
                      ride_id: $scope.rideDetails.ride_id,
                      providers_offer: [
                        {
                          provider_id: vendor.fuseId,
                          is_rescinded: 1,
                          offer_expiration_utc_time: vendor.expiryTimeString,
                        },
                      ],
                    },
                  ],
                  isRescind: true,
                });
              }
            } else if (action.isAcceptance) {
              if (vendor.isRideShareVendor) {
                apiCall = $http.put(`${API_BASE_URL}order/${$scope.rideDetails.orderInfo.orderId}/ride-share-offers/reject`, {
                  providerId: vendor.providerId,
                });
              } else {
                apiCall = $http.post(`${API_BASE_URL}order/${$scope.rideDetails.orderInfo.orderId}/offers/status`, {
                  set_provider_acceptance_status: [
                    {
                      external_order_id: $scope.rideDetails.orderInfo.orderId,
                      provider_id: vendor.fuseId,
                      order_subgroup_id: '1',
                      acceptance_status: action.statusCode,
                    },
                  ],
                });
              }
            } else {
              apiCall = null;
            }

            if (apiCall) {
              const result = await apiCall;
              if (result.data.success) {
                $timeout(() => {
                  $scope.updateFullOrder();
                });
              } else {
                Object.assign(vendor, { selectedAction: $scope.vendorCTAs.actions[0] });
              }
            }
          } catch (error) {
            Object.assign(vendor, { selectedAction: $scope.vendorCTAs.actions[0] });
            toaster.pop({
              type: 'error',
              title: (error && error.message) || 'Unable to update status of provider',
              timeout: 3000,
            });
          } finally {
            loadingScreenFactory.hideLoadingScreen();
            $scope.vendorCTAs.disableAll = false;
            $scope.$apply();
          }
        },
      );
    };

    $scope.openOutOfNetworkModal = function openOutOfNetworkModal() {
      outOfNetworkModal = $uibModal.open({
        animation: true,
        template: require('../views/ride-order-out-of-network-modal.html'),
        controller: 'rideOrderOutOfNetworkCtrl as $ctrl',
        size: 'md',
        resolve: {
          orderDetails() {
            return _.get($scope, 'rideDetails.orderInfo', {});
          },
        },
      });
    };

    $scope.filterVendorCTAs = (action, category) => {
      if (action && typeof action === 'object') {
        return Array.isArray(action.categoryVisibility) && action.categoryVisibility.includes(category);
      }
      return false;
    };

    $scope.$on('rideOrderFetched', () => {
      setMap();
      if (!$scope.providerTabs.isOrderUpdate) {
        const top = $('#current-app').scrollTop();
        if (top < 5) {
          timeout = $timeout(() => {
            $('#current-app').animate({ scrollTop: $('#availableVendorsSection').offset().top }, 500);
          }, 500);
        }
      }
    });
    $scope.$on('$destroy', () => {
      if (sendOfferModalInstance) {
        sendOfferModalInstance.dismiss('cancel');
      }
      if (UATPModal) {
        UATPModal.dismiss('cancel');
      }
      if (outOfNetworkModal) {
        outOfNetworkModal.dismiss('cancel');
      }
      if (typeof timeout === 'function') {
        timeout();
      }
    });
  }]);
