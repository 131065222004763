angular
  .module('relayHealth').directive('rideStatusDropdown', [
    '$rootScope',
    '$http',
    'API_BASE_URL',
    'loadingScreenFactory',
    'toaster',
    'SweetAlert',
    '$state',
    'NO_SHOW_MAPPING',
    'ngDialog',
    function rideStatusDropdown($rootScope, $http, API_BASE_URL, loadingScreenFactory, toaster, SweetAlert, $state, NO_SHOW_MAPPING, ngDialog) {
      return {
        restrict: 'E',
        controller: ['$scope', ($scope) => {
          const externalLegId = $scope.rideDetails.external_leg_id;
          const orderId = $scope.rideDetails.appointment.external_order_id;
          const rideStatuses = {
            [relaylib.ride.status.DRIVER_ARRIVED.status]: -1,
            'Rider En Route': -1,
            [relaylib.ride.status.RIDE_COMPLETED.status]: 1,
            [relaylib.ride.status.PROVIDER_NO_SHOW.status]: 3,
            'Rider No Show': 6,
            [relaylib.ride.status.PENDING_DISPOSITION.status]: 0,
          };
          $scope.checkboxModel = {
            member: false,
            provider: false,
            facility: false,
          };
          $scope.showPendingDispostion = false;
          $scope.selectedReason = {};
          $scope.rideStatusesList = Object.keys(rideStatuses);
          let statusReasons = [];
          let cancelReasonCodes = [];
          const { rideDetails: { ride_additional_detail: { status_change_reason: previousStatusReason } = {} } } = $scope;
          $scope.statusReasonList = [];
          const getstatusReasons = async () => {
            try {
              loadingScreenFactory.showLoadingScreen();
              const data = await $http.get(`${API_BASE_URL}noshowcodes`);
              statusReasons = data.data.data;
              const cancelReasonData = await $http.get(`${API_BASE_URL}cancellationcodes`);
              cancelReasonCodes = cancelReasonData.data.data;
            } catch (error) {
              toaster.pop({
                type: 'error',
                title: (error && error.message) || 'Unable to get no show codes',
                timeout: 3000,
              });
            } finally {
              loadingScreenFactory.hideLoadingScreen();
            }
          };
          getstatusReasons();
          const getSystemSettings = async () => {
            try {
              loadingScreenFactory.showLoadingScreen();
              const data = await $http.get(`${API_BASE_URL}setting/default?setting_name=traditional_ride_cancellation`);
              $scope.traditionalRideCancellation = data.data.settings.traditional_ride_cancellation;
              if ((!($scope.rideDetails.ride_status === 'Patient Enroute' || $scope.rideDetails.displayStatus === 'Patient Enroute') && $scope.traditionalRideCancellation) && !$scope.rideStatusesList.includes(relaylib.ride.status.RIDE_CANCELED.status)) {
                rideStatuses[relaylib.ride.status.RIDE_CANCELED.status] = 2;
                $scope.rideStatusesList.push(relaylib.ride.status.RIDE_CANCELED.status);
              }
            } catch (error) {
              toaster.pop({
                type: 'error',
                title: (error && error.message) || 'Unable to get system settings',
                timeout: 3000,
              });
            } finally {
              loadingScreenFactory.hideLoadingScreen();
            }
          };
          getSystemSettings();
          const swalHandler = async (selectedAction, vendorCTAs, selectedReason) => {
            $scope.vendorCTAs = vendorCTAs;
            let action = selectedAction;
            let apiStatus = rideStatuses[action];
            if (action === 'Rider En Route') {
              action = relaylib.ride.status.PATIENT_ENROUTE.status;
              apiStatus = -1;
            }
            const payload = {
              status: `${apiStatus}`,
              statusText: `${action}`,
              no_show_code_id: selectedReason,
              ride_id: $scope.rideDetails.id,
            };
            if (action === relaylib.ride.status.PENDING_DISPOSITION.status) {
              payload.contactType = {
                member: $scope.checkboxModel.member,
                provider: $scope.checkboxModel.provider,
                facility: $scope.checkboxModel.facility,
              };
            }
            if (!action || !externalLegId || !orderId) {
              toaster.pop({
                type: 'error',
                title: 'Unable to update status of provider',
                timeout: 3000,
              });
              return;
            }

            try {
              loadingScreenFactory.showLoadingScreen();
              const {
                data: {
                  success, showMessage, rideData, statusMismatchTrack: {
                    isStatusHonored = false,
                    isFinalDispositionStatus = false,
                  } = {},
                },
              } = await $http.post(`${API_BASE_URL}order/${orderId}/assignment/status`, payload);

              if (!isStatusHonored && isFinalDispositionStatus) {
                SweetAlert.swal({
                  title: 'Error',
                  text: 'Vendor Invoiced, Status updates are not honored',
                  type: 'error',
                }, () => {
                  if (showMessage) {
                    $state.go('main.dashboard.completed');
                  } else {
                    // refresh active ride page if appt is not completed
                    $rootScope.$broadcast('refreshActiveRideModal');
                    $rootScope.$broadcast('refreshActiveRideTiles');
                    $scope.closePopup();
                  }
                });
              } else if (success) {
                action = rideData ? rideData.ride_status : action;
                $scope.rideDetails.displayStatus = action;
                $rootScope.$broadcast('refreshActiveRideTiles');
                if ([
                  relaylib.ride.status.RIDE_COMPLETED.status,
                  relaylib.ride.status.PROVIDER_NO_SHOW.status,
                  'Rider No Show'].includes(action)
                  && showMessage) {
                  const successMessage = `Ride status has been updated to ${action} and ride has been moved to previous trips page.`;
                  SweetAlert.swal(
                    {
                      title: '',
                      text: successMessage,
                      icon: 'success',
                      showCancelButton: false,
                      confirmButtonColor: '#DD6B55',
                      confirmButtonText: 'Ok',
                      closeOnConfirm: true,
                    },
                    $scope.closePopup,
                  );
                } else if (
                  action === relaylib.ride.status.VARIANCE_ISSUE.status
                ) {
                  $rootScope.$broadcast('refreshActiveRideModal');
                } else if (selectedAction === relaylib.ride.status.PENDING_DISPOSITION.status) {
                  $rootScope.$broadcast('refreshActiveRideModal');
                  $rootScope.$broadcast('refreshActiveRideTiles');
                  $scope.closePopup();
                }
              }
            } catch (error) {
              toaster.pop({
                type: 'error',
                title: (error && error.message) || 'Unable to update status of provider',
                timeout: 3000,
              });
            } finally {
              loadingScreenFactory.hideLoadingScreen();
            }
          };
          const confirmationPopup = (selectedAction, vendorCTAs, selectedReason) => {
            let text = `Are you sure you want to set status to ${selectedAction} ?`;
            if (selectedAction === relaylib.ride.status.PENDING_DISPOSITION.status) {
              text = `Current leg will be marked as ${selectedAction}
               Do you want to proceed?`;
            }
            SweetAlert.swal(
              {
                title: '',
                text,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes',
                closeOnConfirm: true,
              }, (isConfirm) => {
                if (isConfirm) {
                  swalHandler(selectedAction, vendorCTAs, selectedReason);
                }
              },
            );
          };
          const rideCancel = async (selectedReason, flag) => {
            try {
              loadingScreenFactory.showLoadingScreen();
              const {
                data: {
                  success, message,
                },
              } = await $http.post(`${API_BASE_URL}order/${orderId}/cancel`,
                {
                  system_code: selectedReason,
                  ride_id: $scope.rideDetails.id,
                  cancel_all: flag,
                });
              if (!success) {
                toaster.pop({
                  type: 'error',
                  title: message || 'Unable to update status of provider',
                  timeout: 3000,
                });
              } else {
                $rootScope.$broadcast('refreshActiveRideModal');
                $rootScope.$broadcast('refreshActiveRideTiles');
                $scope.closePopup();
              }
            } catch (error) {
              toaster.pop({
                type: 'error',
                title: (error && error.message) || 'Unable to update status of provider',
                timeout: 3000,
              });
            } finally {
              loadingScreenFactory.hideLoadingScreen();
            }
          };
          $scope.showConfirmation = (selectedAction, selectedReason) => {
            if (selectedReason !== null) {
              confirmationPopup(selectedAction, null, selectedReason);
            }
          };
          $scope.$watch('rideDetails.ride_status', () => {
            const indexOfRideCancel = $scope.rideStatusesList.indexOf(relaylib.ride.status.RIDE_CANCELED.status);
            if ((!($scope.rideDetails.ride_status === 'Patient Enroute' || $scope.rideDetails.displayStatus === 'Patient Enroute') && $scope.traditionalRideCancellation) && !$scope.rideStatusesList.includes(relaylib.ride.status.RIDE_CANCELED.status)) {
              rideStatuses[relaylib.ride.status.RIDE_CANCELED.status] = 2;
              $scope.rideStatusesList.push(relaylib.ride.status.RIDE_CANCELED.status);
            } else if (indexOfRideCancel > -1 && ($scope.rideDetails.ride_status === 'Patient Enroute' || $scope.rideDetails.displayStatus === 'Patient Enroute')) {
              $scope.rideStatusesList.splice(indexOfRideCancel, 1);
            }
          });
          $scope.openModal = function (selectedReason) {
            const modalInstance =   ngDialog.open({
              ribedBy: 'modal-body',
              templateUrl: 'ngDialogTemplate1',
              className: 'ngdialog-theme-default',
            });
            modalInstance.closePromise.then((res) => {
              if (typeof (res.value) === 'boolean') {
                rideCancel(selectedReason, res.value);
              }
            });
          };
          $scope.confirmationPopup = (selectedAction) => {
            if (!($scope.checkboxModel.member || $scope.checkboxModel.provider || $scope.checkboxModel.facility)) {
              toaster.pop({
                type: 'error',
                title: 'Please select atleast one contact type to complete this request.',
                timeout: 3000,
              });
              return;
            }
            confirmationPopup(selectedAction);
          };
          $scope.updateStatus = (selectedAction, vendorCTAs) => {
            if (selectedAction !== null) {
              if (selectedAction.match(/No Show$/gmi) !== null) {
                const realActionValue = NO_SHOW_MAPPING.getStatusMapValues[selectedAction];
                $scope.statusReasonList = [];
                $scope.selectedReason = { id: null };
                statusReasons.forEach((statusReason) => { if (statusReason.status === realActionValue) { $scope.statusReasonList.push(statusReason); } });
                if (previousStatusReason && previousStatusReason.reasonCategory === realActionValue) {
                  $scope.selectedReason.id = previousStatusReason.no_show_code_id;
                }
                $scope.rideCancelledStatusReasonList = [];
                $scope.showPendingDispostion = false;
              } else if (selectedAction.match(/Ride Canceled$/gmi) !== null) {
                $scope.rideCancelledStatusReasonList = cancelReasonCodes;
                $scope.statusReasonList = [];
                $scope.selectedReason = { id: null };
                $scope.showPendingDispostion = false;
              } else if (selectedAction === relaylib.ride.status.PENDING_DISPOSITION.status) {
                $scope.rideCancelledStatusReasonList = [];
                $scope.statusReasonList = [];
                $scope.showPendingDispostion = true;
              } else {
                $scope.showPendingDispostion = false;
                $scope.rideCancelledStatusReasonList = [];
                $scope.statusReasonList = [];
                $scope.selectedReason = { id: null };
                confirmationPopup(selectedAction, vendorCTAs);
              }
            }
          };
        }],
        template: `
          <div class="form-group">
              <select 
                style="margin-top:20px" 
                orderId=""
                class="form-control"
                ng-model="vendor.selectedAction"
                ng-options="st for st in rideStatusesList"
                ng-change="updateStatus(vendor.selectedAction)">
                  <option value="">Select Status</option>
              </select>
              <label style="margin-top:20px;float:left" ng-show="statusReasonList.length"for="rideStatusReason">
              <span>&nbsp;*</span>Ride Status Reason
                </label>
                <ui-select
                ng-model="selectedReason.id"
                theme="bootstrap"
                ng-show="statusReasonList.length"
                ng-change="showConfirmation(vendor.selectedAction, selectedReason.id)"
              >
              <ui-select-match title ="{{$select.selected.reason_description}}" placeholder="Select Ride Status Reason">{{$select.selected.reason_description|limitTo: 30}}{{$select.selected.reason_description.length > 30 ? '...' : ''}}</ui-select-match>
              <ui-select-choices repeat="st.id as st in statusReasonList">
                  <div ng-bind-html="st.reason_description  | highlight: $select.search" title = "{{st.reason_description }}"></div>
                </ui-select-choices>
              </ui-select>
              <label style="margin-top:20px;float:left" ng-show="rideCancelledStatusReasonList.length"for="rideStatusReason && traditionalRideCancellation">
              <span>&nbsp;*</span>Ride Status Reason
                </label>
                <ui-select
                ng-model="selectedCancelReason.system_code"
                theme="bootstrap"
                ng-show="rideCancelledStatusReasonList.length && traditionalRideCancellation"
                ng-change="openModal(selectedCancelReason.system_code)"
              >
              <ui-select-match title ="{{$select.selected.reason_description}}" placeholder="Select Ride Status Reason">{{$select.selected.reason_description|limitTo: 30}}{{$select.selected.reason_description.length > 30 ? '...' : ''}}</ui-select-match>
              <ui-select-choices repeat="st.system_code as st in rideCancelledStatusReasonList">
                  <div ng-bind-html="st.reason_description  | highlight: $select.search" title = "{{st.reason_description }}"></div>
                </ui-select-choices>
              </ui-select>
          </div>
          <div ng-if="showPendingDispostion">
          <div class="form-group" style="text-align: left;" >
          <label>
    <input type="checkbox" ng-model="checkboxModel.member">
    Member</label><br/>
    <input type="checkbox" ng-model="checkboxModel.provider">
    <label>Provider</label><br/>
    <input type="checkbox" ng-model="checkboxModel.facility">
  <label>Facility
  </label><br/>
</div>
<button style="border-color: white; text-align: center;" class="btn btn-info btn-sm ng-binding" ng-click="confirmationPopup(vendor.selectedAction)">Submit</button>
</div>
          <script id="ngDialogTemplate1" type="text/ng-template">
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4 text-center" style="font-size: 50px; padding:10px;">
      <i class="fa fa-exclamation-triangle"></i>
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-12">
      <div class="row" style="padding:10px;margin:10px">
        <p>To Cancel Current leg and all subsequent legs, please click - Cancel All</p>
        <p>To Cancel only current leg, Click - Cancel Current</p>
        <br />
      </div>
      <div class="row">
        <div class="col-md-12 text-center">
          <button
            class="btn btn-primary"
            ng-click="$ctrl.cancelAll() ; closeThisDialog(false)"
          >
          Cancel Current
          </button>
          <button
            class="btn btn-danger"
            ng-click="$ctrl.cancelAll() ; closeThisDialog(true)"
          >
          Cancel All
          </button>
        </div>
      </div>
    </div>
  </div>
</script>
        `,
      };
    },
  ]);
