function findChildOrg(
  organisations,
  orgId,
) {
  let orgData = null;
  organisations.some(
    (org) => {
      if (orgId === org.id) {
        orgData = org;
        return true;
      }
      if (org.children) {
        orgData = findChildOrg(org.children, orgId);
        if (orgData) {
          return true;
        }
      }
      return false;
    },
  );
  return orgData;
}

const spaceCharacterPerDepthLevel = (depth) => {
  let spaceCharacters = '';
  for (let i = 0; i < depth; i += 1) {
    spaceCharacters = `${spaceCharacters}-`;
  }
  if (depth) {
    spaceCharacters += `${spaceCharacters}> `;
  }
  return spaceCharacters;
};

angular.module('relayHealth').factory('orgHierarchyFactory', [
  '$http',
  'API_BASE_URL',
  'toaster',
  '$rootScope',
  '$window',
  function orgHrchyFactory($http, API_BASE_URL, toaster, $rootScope, $window) {
    const orgHierarchyFactory = {
      loading: true,
      loggedOut: false,
      flattenedChildren: [],
    };

    let userDetails = JSON.parse(localStorage.getItem('userDetails'));

    const flattenChildren = (children, depth) => {
      angular.forEach(children, (child) => {
        Object.assign(child, {
          depth,
          isCollapsed: true,
          depthInSpaces: spaceCharacterPerDepthLevel(depth),
        });
        orgHierarchyFactory.flattenedChildren.push(child);
        if (child.children && child.children.length) {
          flattenChildren(child.children, depth + 1);
        }
      });
    };

    orgHierarchyFactory.uncollapseChildren = (
      depth = 0,
      children = orgHierarchyFactory.organisationsCopyForFilter,
    ) => {
      angular.forEach(children, (child) => {
        Object.assign(child, {
          depth,
          isCollapsed: false,
        });
        if (child.children && child.children.length) {
          orgHierarchyFactory.uncollapseChildren(depth + 1, child.children);
        }
      });
    };

    orgHierarchyFactory.copyOrganisationsForParentOrgFilter = () => {
      const clone = _.cloneDeep(orgHierarchyFactory.organisationsChildren);
      const org = findChildOrg(clone, userDetails.organisation.id);
      orgHierarchyFactory.organisationsCopyForFilter = org ? [org] : null;
    };

    orgHierarchyFactory.getOrganistionChildren = () => {
      userDetails = JSON.parse(localStorage.getItem('userDetails'));
      const headers = {
        username: userDetails.username,
        usertoken: userDetails.user_token,
        'Content-Type': 'application/json',
      };
      orgHierarchyFactory.request = $http
        .get(`${API_BASE_URL}organisation/getAllChildrenAndAncesotorOrganisations/${userDetails.org_id}`, { headers })
        .then((results) => {
          flattenChildren(results.data.result, 0);
          orgHierarchyFactory.organisationsChildren = results.data.result;
          orgHierarchyFactory.copyOrganisationsForParentOrgFilter();
          orgHierarchyFactory.loading = false;
          return results.data.result;
        })
        .catch((err) => {
          toaster.pop({
            type: 'error',
            title: err && err.data ? err.data.message : 'An unknown error occurred.',
            showCloseButton: true,
            timeout: 10000,
          });
        });
    };

    orgHierarchyFactory.switchOrganisation = (organisation) => {
      $http
        .get(`${API_BASE_URL}organisation/imitate/${organisation.id}`)
        .then(() => {
          const user = JSON.parse(localStorage.getItem('userDetails'));
          user.organisation = organisation;
          user.hierarchySelected = organisation.id;
          user.hierarchySelectedName = organisation.depthInSpaces + organisation.name;
          if (!user.organisation) {
            toaster.pop({
              type: 'error',
              title: 'error setting view',
              showCloseButton: true,
              timeout: 10000,
            });
            return;
          }

          const orgId = user.organisation.id;
          const parentOrgId = user.organisation.parent_id;

          if (user.orgIds.indexOf(orgId) === -1 && user.orgIds.indexOf(parentOrgId) === -1) {
            [user.organisation.parent_id] = user.orgIds;
          }

          localStorage.setItem('userDetails', JSON.stringify(user));
          if (organisation.childrenCount) {
            Object.assign($window.location, {
              href: `/${process.env.PUBLIC_URL_PREFIX}current/main/dashboard/parent-org-dashboard`,
            });
            return;
          }
          Object.assign($window.location, {
            href: '/',
          });
        })
        .catch(error => toaster.pop({
          type: 'error',
          title: error.messaeg,
          showCloseButton: true,
          timeout: 10000,
        }));
    };

    orgHierarchyFactory.getOrgsWithInheritedSettings = async (orgIds, attributes) => $http.get(`${API_BASE_URL}organisation/${orgIds}/inherited-settings`, {
      params: { attributes: attributes.join() },
    });

    if (userDetails && userDetails.belongsToParentOrg) {
      orgHierarchyFactory.getOrganistionChildren();
    }

    $rootScope.$on('logout', () => {
      orgHierarchyFactory.loading = true;
      orgHierarchyFactory.loggedOut = true;
      orgHierarchyFactory.flattenedChildren = [];
      delete orgHierarchyFactory.organisationsCopyForFilter;
      delete orgHierarchyFactory.organisationsChildren;
    });

    $rootScope.$on('login', () => {
      userDetails = JSON.parse(localStorage.getItem('userDetails'));
      if (orgHierarchyFactory.loggedOut) {
        orgHierarchyFactory.loggedOut = false;
        orgHierarchyFactory.getOrganistionChildren();
      }
    });

    return orgHierarchyFactory;
  },
]);
