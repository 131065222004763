angular.module('relayHealth').controller('CreateReleaseNoteCtrl', [
  '$scope',
  '$uibModalInstance',
  'editInfo',
  '$http',
  'API_BASE_URL',
  function CreateReleaseNoteCtrl($scope, $uibModalInstance, editInfo, $http, API_BASE_URL) {
    function convertFromDM(inDM) {
      const data = {
        id: inDM.id,
        category: relaylib.broadcasts.broadcastCategories.RELEASE_NOTES,
        title: inDM.title,
        content: inDM.message,
        type: relaylib.broadcasts.broadcastTypes.OBTRUSIVE,
        roles: ['all'],
        status: relaylib.broadcasts.broadcastStatuses.ACTIVE,
        tags: {
          version: inDM.version,
        },
      };
      data.targets = [];
      if (inDM.isTargetWeb) {
        data.targets.push(relaylib.broadcasts.broadcastTargets.WEB);
      }
      if (inDM.isTargetAndroid) {
        data.targets.push(relaylib.broadcasts.broadcastTargets.ANDROID);
      }
      if (inDM.isTargetIos) {
        data.targets.push(relaylib.broadcasts.broadcastTargets.IOS);
      }
      if (inDM.isTargetDriverAppIos) {
        data.targets.push(relaylib.broadcasts.broadcastTargets.DRIVER_IOS);
      }
      if (inDM.isTargetDriverAppAndroid) {
        data.targets.push(relaylib.broadcasts.broadcastTargets.DRIVER_ANDROID);
      }
      return data;
    }

    function init() {
      $scope.editorConfig = {
        placeholder: 'Enter the detailed Notes',
      };
      if (editInfo) {
        $scope.dm = Object.assign({}, editInfo);
      } else {
        $scope.dm = {
          title: '',
          message: '',
          category: relaylib.broadcasts.broadcastCategories.RELEASE_NOTES,
          isTargetWeb: false,
          isTargetAndroid: false,
          isTargetIos: false,
          isTargetDriverAppIos: false,
          isTargetDriverAppAndroid: false,
          version: '',
        };
      }
      $scope.isSaving = false;
    }

    function reset() {
      $scope.dm = {
        title: '',
        message: '',
        category: relaylib.broadcasts.broadcastCategories.RELEASE_NOTES,
        isTargetWeb: false,
        isTargetAndroid: false,
        isTargetIos: false,
        isTargetDriverAppIos: false,
        isTargetDriverAppAndroid: false,
        version: '',
      };
      $scope.isSaving = false;
      if ($scope.addBroadcastFrm) {
        $scope.addBroadcastFrm.$setPristine();
      }
    }

    function isFormValid() {
      return (
        $scope.addBroadcastFrm.$valid
        && ($scope.dm.isTargetWeb || $scope.dm.isTargetAndroid || $scope.dm.isTargetIos || $scope.dm.isTargetDriverAppIos || $scope.dm.isTargetDriverAppAndroid)
      );
    }

    $scope.submitMessage = () => {
      if (isFormValid() && !editInfo) {
        // Create case
        $scope.isSaving = true;
        const data = convertFromDM($scope.dm);
        $http
          .post(`${API_BASE_URL}broadcasts`, data)
          .then((resp) => {
            $uibModalInstance.close(resp);
          })
          .finally(() => {
            $scope.isSaving = false;
          });
      } else if (isFormValid()) {
        // Edit case
        $scope.isSaving = true;
        const data = convertFromDM($scope.dm);
        $http
          .put(`${API_BASE_URL}broadcasts/${data.id}`, data)
          .then((resp) => {
            $uibModalInstance.close(resp);
          })
          .finally(() => {
            $scope.isSaving = false;
          });
      } else {
        // Form data invalid. Do nothing
      }
    };

    $scope.cancel = () => {
      reset();
      $uibModalInstance.close();
    };

    init();
  },
]);
