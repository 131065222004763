angular
  .module('relayHealth')
  .controller('rideOrderLogsCtrl', [
    '$scope',
    'API_BASE_URL',
    '$http',
    'loadingScreenFactory',
    '$uibModal',
    'toaster',
    function rideOrderLogsCtrl(
      $scope,
      API_BASE_URL,
      $http,
      loadingScreenFactory,
      $uibModal,
      toaster,
    ) {
      this.appointmentDetails = [];
      this.rideOrderLogs = [];
      this.orderBy = 'createdAt';
      this.sortOrder = 'DESC';
      this.moment = moment;
      this.orderStatus = [
        {
          index: 0,
          value: 'Select status',
        },
      ];
      const { appointment: { status: appointmentStatus }, ride: { status: rideStatus }, common: { constants: { rideStatusMapping } } } = relaylib;
      const {
        OFFERED,
        CONFIRMED,
        ORDER_UPDATED,
        APPOINTMENT_COMPLETED,
        APPOINTMENT_CANCELED,
        PATIENT_NO_SHOW,
        ORDER_QUEUED,
        PENDING_DISPOSITION,
      } = appointmentStatus;
      const {
        UATP,
        NDA,
        RETRO_QUEUED,
      } = rideStatus;
      const allStatuses = [
        OFFERED.status,
        CONFIRMED.status,
        ORDER_UPDATED.status,
        ORDER_QUEUED.status,
        APPOINTMENT_COMPLETED.status,
        APPOINTMENT_CANCELED.status,
        PATIENT_NO_SHOW.status,
        UATP.status,
        NDA.status,
        rideStatusMapping['Offer Created'],
        rideStatusMapping.NEPF,
        RETRO_QUEUED.status,
        PENDING_DISPOSITION.status,
      ];
      allStatuses.forEach((status, index) => {
        this.orderStatus.push({
          index: index + 1,
          value: status,
        });
      });
      const showToaster = (message) => {
        toaster.pop({
          type: 'error',
          title: message,
          showCloseButton: true,
          timeout: 10000,
        });
      };

      const adjustPagination = (totalCount) => {
        this.pagination.totalItems = totalCount;
        this.pagination.lastItem = (this.pagination.currentPage) * this.pagination.itemsPerPage;
        if (this.pagination.lastItem > this.pagination.totalItems) {
          this.pagination.lastItem = this.pagination.totalItems;
        }
      };

      const getAppointmentDetails = async (offset, limit) => {
        const params = {
          data_source: 'postgres',
          limit,
          offset,
          patient_name: this.rideOrderLogsQuery.patient_name || null,
          order_id: this.rideOrderLogsQuery.order_id || null,
          phone_number: this.rideOrderLogsQuery.phone_number || null,
          order_status: this.rideOrderLogsQuery.order_status.index ? this.rideOrderLogsQuery.order_status.value : null,
          order: this.orderBy,
          sortBy: this.sortOrder,
        };
        try {
          loadingScreenFactory.showLoadingScreen();
          const {
            data: {
              rideOrders,
              total_count: totalCount,
            },
          } = await $http.get(`${API_BASE_URL}audit_logs`, { params });
          this.appointmentDetails = rideOrders;
          adjustPagination(totalCount);
        } catch (e) {
          showToaster(e);
        } finally {
          loadingScreenFactory.hideLoadingScreen();
          $scope.$apply();
        }
      };

      this.getRideOrderLogsByOrderId = async (index, ad) => {
        if (ad.rideOrderLogs) {
          Object.assign(ad, { rideOrderLogs: undefined });
          return;
        }
        const externalOrderId = ad.external_order_id
          ? ad.external_order_id
          // eslint-disable-next-line no-underscore-dangle
          : ad._source.external_order_id;
        const params = {
          data_source: 'elasticsearch',
          externalOrderId,
        };
        try {
          loadingScreenFactory.showLoadingScreen();
          const { data: { audit_logs: auditLogs } } = await $http.get(`${API_BASE_URL}audit_logs`, { params });
          for (let i = 0; i < this.appointmentDetails.length; i += 1) {
            delete this.appointmentDetails[i].rideOrderLogs;
          }
          for (let i = 0; i < auditLogs.length; i += 1) {
            // eslint-disable-next-line no-underscore-dangle
            if (auditLogs[i]._source.topic_payload) {
              auditLogs[i].external_order_id = JSON.parse(
                // eslint-disable-next-line no-underscore-dangle
                auditLogs[i]._source.topic_payload.body,
              ).OrderId;
            }
          }
          this.appointmentDetails[index].rideOrderLogs = auditLogs;
        } catch (e) {
          showToaster(e.message);
        } finally {
          loadingScreenFactory.hideLoadingScreen();
          $scope.$apply();
        }
      };

      this.filterRideOrderLogs = () => {
        getAppointmentDetails((this.pagination.currentPage - 1) * 10, 10);
      };

      this.sort = (orderBy) => {
        this.sortOrder = this.sortOrder === 'ASC' ? 'DESC' : 'ASC';
        this.orderBy = orderBy;
        getAppointmentDetails((this.pagination.currentPage - 1) * 10, 10);
      };

      const initSearchForm = () => {
        this.rideOrderLogsQuery = {
          patient_name: null,
          phone_number: null,
          order_id: null,
          order_status: this.orderStatus[0],
        };
        this.pagination = {
          totalItems: 0,
          currentPage: 1,
          lastItem: 0,
          itemsPerPage: 10,
        };
      };

      const init = () => {
        initSearchForm();
        getAppointmentDetails((this.pagination.currentPage - 1) * 10, 10);
      };

      init();

      this.pageChanged = () => {
        getAppointmentDetails((this.pagination.currentPage - 1) * 10, 10);
      };

      this.showMeta = (rol) => {
        const modalInstance = $uibModal.open({
          animation: true,
          template: require('../log-details.html'),
          size: 'lg',
          backdrop: 'static',
          resolve: {
            meta() {
              return angular.copy(rol);
            },
          },
          controllerAs: 'logDetailsCtrl',
          controller: [
            'meta',
            '$uibModalInstance',
            function logDetailsCtrl(meta, $uibModalInstance) {
              Object.assign(this, {
                meta,
                closePopup() {
                  $uibModalInstance.close();
                },
              });
            },
          ],
        });
        $scope.$on('$destroy', () => {
          modalInstance.close();
        });
      };

      this.clearFilters = () => {
        initSearchForm();
        this.orderBy = 'createdAt';
        this.sortOrder = 'DESC';
        getAppointmentDetails(0, 10);
        this.pagination.currentPage = 1;
      };

      this.searchOnEnter = (event) => {
        if (
          event.keyCode === 13
          && !this.rideOrderLogsForm.$invalid) {
          this.orderBy = 'createdAt';
          this.sortOrder = 'DESC';
          this.pagination.currentPage = 1;
          getAppointmentDetails(0, 10);
        }
      };
    },
  ]);
