angular
  .module('relayHealth')
  .controller('rideOrderUATPCtrl', [
    '$scope',
    'API_BASE_URL',
    '$http',
    'toaster',
    '$state',
    'rideDetails',
    '$uibModalInstance',
    function rideOrderUATPCtrl($scope, API_BASE_URL, $http, toaster, $state, rideDetails, $uibModalInstance) {
      $scope.uatpDetails = {
        rideId: null,
        orderId: null,
        uatpNotes: null,
      };

      $scope.confirmDisable = false;

      function init() {
        $scope.uatpDetails.rideId = rideDetails.ride_id;
        $scope.uatpDetails.orderId = rideDetails.orderInfo.orderId;
      }

      init();

      $scope.closeModal = function closeModal() {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.confirmUatp = function confirmUatp() {
        if (!$scope.uatpDetails.uatpNotes) {
          toaster.pop({
            type: 'error',
            title: 'Notes are required to mark order as UATP.',
            timeout: 3000,
          });
          return;
        }
        const requestData = {
          ride_id: $scope.uatpDetails.rideId,
          uatp_notes: $scope.uatpDetails.uatpNotes,
        };

        $scope.confirmDisable = true;
        $http.put(`${API_BASE_URL}vendors/mark-as-uatp`, requestData).then((result) => {
          if (result.data.success) {
            toaster.pop({
              type: 'success',
              title: 'Ride order marked as UATP',
              timeout: 3000,
            });
            $state.reload();
          } else {
            toaster.pop({
              type: 'error',
              title: result.data.message || 'Not able to mark Ride Order as UATP.',
              timeout: 3000,
            });
            $scope.confirmDisable = false;
          }
        }).catch((error) => {
          toaster.pop({
            type: 'error',
            title: (error.data && error.data.message) || error.message || 'Not able to mark Ride Order as UATP.',
            timeout: 3000,
          });
          $scope.confirmDisable = false;
        });
      };
    }]);
