import GetItem from './commands/getItem';
import GetItemByKey from './commands/getItemByKey';
import SetItem from './commands/setItem';
import SetItemByKey from './commands/setItemByKey';
import RemoveItem from './commands/removeItem';
import RemoveItemByKey from './commands/removeItemByKey';
import StorageProvider from  './storageProvider';

export default class StorageFacade {
    constructor(storageType){
        const storageProviderInstance = StorageProvider.instance;
        storageProviderInstance.storageType = storageType;
    }
    setData(key,data){
        const obj = new SetItem();
        obj.execute(key,data);
    }

    setDataByKey(key, childProperty, data){
        const obj = new SetItemByKey();
        obj.execute(key, childProperty, data);
    }

    getData(key){
        const obj = new GetItem();
        return obj.execute(key);
    }

    getDataByKey(key, childProperty){
        const obj = new GetItemByKey();
        return obj.execute(key, childProperty);
    }

    removeData(key){
        const obj = new RemoveItem();
        obj.execute(key);
    }

    removeDataByKey(key, childProperty){
        const obj = new RemoveItemByKey();
        obj.execute(key, childProperty);
    }


}
