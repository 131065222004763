import './style.scss';

const { Blob, saveAs } = window;

angular.module('relayHealth').directive('export', [
  function exportDirective() {
    return {
      template: require('./template.html'),
      scope: {
        exportTitle: '@',
        dateRange: '=dateRangeObj', // changing name of scope property as directive named dateRange already exists
        minDate: '=minExportDate',
        maxDate: '=maxExportDate',
        dateRangeInfo: '@',
        exportBtnLabel: '@',
        exportBtnDisabled: '=',
        exportFunction: '&',
        exportList: '=',
        exportRideTypes: '=',
        selectedExportRideType: '=',
        exportContainerLable: '@',
        showExportType: '=',
      },
      controller: [
        '$scope',
        '$http',
        'API_BASE_URL',
        'toaster',
        ($scope, $http, API_BASE_URL, toaster) => {
          'ngInject';

          $scope.downloadXlsxFile = (filename) => {
            const downloadXlsxFileCall = $http({
              url: `${API_BASE_URL}export/downloadXlsxFile/${filename}`,
              method: 'GET',
              data: {
                filename,
              },
              headers: {
                'Content-type': 'application/json',
                Accept:
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              },
              responseType: 'arraybuffer',
            });

            downloadXlsxFileCall.then((result) => {
              const blob = new Blob([result.data], {
                type:
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              if (blob.size < 70) {
                toaster.pop({
                  type: 'error',
                  title: 'No record found! Please try changing date range!',
                  showCloseButton: true,
                  timeout: 10000,
                });
              } else {
                saveAs(blob, filename);
              }
            });
          };
        },
      ],
    };
  },
]);
