angular.module('relayHealth').controller('languageCompletedAppointmentsCtrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  'toaster',
  'SweetAlert',
  '$filter',
  'loadingScreenFactory',
  '$stateParams',
  '$location',
  'isOCCMRole',
  'storageManager',
  function languageCompletedAppointmentsCtrl(
    $scope,
    $http,
    API_BASE_URL,
    toaster,
    SweetAlert,
    $filter,
    loadingScreenFactory,
    $stateParams,
    $location,
    isOCCMRole,
    storageManager,
  ) {
    $scope.moment = moment;
    $scope.isClientOrg = true;
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const ifOCCMRole = isOCCMRole();
    const { storage } = storageManager;

    if ($filter('isMasterOrg')(userDetails.organisation.org_level)) {
      $scope.isClientOrg = false;
    }

    $scope.tabs = {
      video: {
        heading: 'Video',
        active: true,
        url: 'language/getLanguageApptList/VR/updatedAt DESC/',
        pagination: {
          totalItems: 0,
          currentPage: 1,
          lastItem: 0,
          itemsPerPage: 20,
          data: [],
        },
        show: true,
        firstTimeLoad: true,
        searchFormQuery: {
          dateRange: {},
        },
        completed: {
          dateRange: {},
        },
        showLoader: false,
        statusList: ['Appointment Completed', 'Appointment Canceled', 'Appointment Rejected', 'Patient No Show'],
      },
      phone: {
        heading: 'Audio Rollover',
        active: false,
        url: 'language/getLanguageApptList/OPI/updatedAt DESC/',
        pagination: {
          totalItems: 0,
          currentPage: 1,
          lastItem: 0,
          itemsPerPage: 20,
          data: [],
        },
        show: true,
        firstTimeLoad: true,
        searchFormQuery: {
          dateRange: {},
        },
        completed: {
          dateRange: {},
        },
        showLoader: false,
      },
    };

    $scope.dateRangeOptions = {
      parentEl: '#current-app #page-wrapper',
      eventHandlers: {
        'apply.daterangepicker': function applyDaterangepicker() {
          const activeTab = Object.keys($scope.tabs).find(tabKey => $scope.tabs[tabKey].active);
          const dateRangeFilter = document.getElementsByName('dateRange')[0].value;

          if (dateRangeFilter) {
            const dateRangeFilterArr = dateRangeFilter.split(' - ');
            $scope.tabs[activeTab].searchFormQuery.dateRange.startDate = `${dateRangeFilterArr[0]} 00:00:00`;
            $scope.tabs[activeTab].searchFormQuery.dateRange.endDate = `${dateRangeFilterArr[1]} 23:59:59`;
          }
        },
      },
    };

    $scope.dateRangeExport = { startDate: new Date().setHours(0, 0, 0), endDate: new Date().setHours(23, 59, 59) };
    $scope.button_disabled = false;
    $scope.export_button = 'Export';


    function setSearchFilters(tabKey) {
      const matchData = {};

      if ($scope.tabs[tabKey].searchFormQuery.organization) {
        matchData.clientOrgId = $scope.tabs[tabKey].searchFormQuery.organization.id;
      } else if ($scope.tabs[tabKey].searchFormQuery.clientOrgId) {
        matchData.clientOrgId = $scope.tabs[tabKey].searchFormQuery.clientOrgId;
      }

      if ($scope.tabs[tabKey].searchFormQuery.language) {
        matchData.language = $scope.tabs[tabKey].searchFormQuery.language;
      }

      if (
        $scope.tabs[tabKey].searchFormQuery.dateRange
        && $scope.tabs[tabKey].searchFormQuery.dateRange.startDate
        && $scope.tabs[tabKey].searchFormQuery.dateRange.endDate
      ) {
        const firstDate = new Date($scope.tabs[tabKey].searchFormQuery.dateRange.startDate);
        const dateTime = moment(firstDate).format('YYYY-MM-DD HH:mm:ss');
        const startDate = dateTime;

        const secondDate = new Date($scope.tabs[tabKey].searchFormQuery.dateRange.endDate);
        const endDateTime = moment(secondDate).format('YYYY-MM-DD HH:mm:ss');
        const endDate = endDateTime;

        matchData.dateRange = {
          startDate,
          endDate,
        };
      }

      if (tabKey === 'pr') {
        matchData.is_pr_ride = true;
      } else if (tabKey === 'rides') {
        matchData.is_pr_ride = false;
      } else if (tabKey === 'variance') {
        matchData.is_variance = true;
      }
      return angular.copy(matchData);
    }

    function setUrlFilters(tabKey, filters) {
      if ($scope.tabs[tabKey].active) {
        $location.search('activeTab', tabKey);

        Object.keys(filters).forEach((key) => {
          if (key === 'dateRange') {
            $location.search('startDate', filters.dateRange.startDate);
            $location.search('endDate', filters.dateRange.endDate);
          } else {
            $location.search(key, filters[key]);
          }
        });

        $location.search('currentPage', $scope.tabs[tabKey].pagination.currentPage);
      }
      return filters;
    }

    function adjustPagination(tabKey) {
      $scope.tabs[tabKey].pagination.lastItem = ($scope.tabs[tabKey].pagination.currentPage - 1) * $scope.tabs[tabKey].pagination.itemsPerPage + 20;
      if ($scope.tabs[tabKey].pagination.lastItem > $scope.tabs[tabKey].pagination.totalItems) {
        $scope.tabs[tabKey].pagination.lastItem = $scope.tabs[tabKey].pagination.totalItems;
      }
    }

    function getCompletedAppointments(tabKey, filters) {
      const url = `${API_BASE_URL + $scope.tabs[tabKey].url + ($scope.tabs[tabKey].pagination.currentPage - 1) * $scope.tabs[tabKey].pagination.itemsPerPage}/${
        $scope.tabs[tabKey].pagination.itemsPerPage
      }`;

      const params = Object.assign({}, filters);
      if ((userDetails.organisation.parent_id, !ifOCCMRole)) {
        params.clientOrgId = userDetails.organisation.id;
      }
      $scope.tabs[tabKey].showLoader = true;
      loadingScreenFactory.showLoadingScreen();
      const getCompletedAppointmentsCall = $http({
        url,
        method: 'GET',
        params,
      });

      getCompletedAppointmentsCall.then(
        (result) => {
          if (result.data.success) {
            loadingScreenFactory.hideLoadingScreen();
            $scope.tabs[tabKey].data = result.data.data;
            $scope.tabs[tabKey].pagination.totalItems = result.data.totalCount;
            if ($stateParams.currentPage && $scope.tabs[tabKey].firstTimeLoad && $scope.tabs[tabKey].active) {
              $scope.tabs[tabKey].pagination.currentPage = $stateParams.currentPage;
            }
            adjustPagination(tabKey);
            $scope.tabs[tabKey].firstTimeLoad = false;
            $scope.tabs[tabKey].showLoader = false;
            // showOrHideLoader();
          }
        },
        (err) => {
          loadingScreenFactory.hideLoadingScreen();
          $scope.tabs[tabKey].data = [];
          $scope.tabs[tabKey].pagination.totalItems = 0;
          adjustPagination(tabKey);
          if (!$scope.tabs[tabKey].firstTimeLoad && err.status) {
            toaster.pop({
              type: 'error',
              title: err.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          }
          $scope.tabs[tabKey].firstTimeLoad = false;
        },
      );
    }

    function filterCompletedAppts(tabKey, isSearch = false) {
      if ($scope.tabs[tabKey].active) {
        $location.url($location.path());
      }
      const matchData = setSearchFilters(tabKey);
      if (isSearch) {
        storage.setData('filterDataLangCompAppointments', { tab: tabKey, data: matchData });
      }
      setUrlFilters(tabKey, matchData);
      getCompletedAppointments(tabKey, matchData);
    }

    $scope.pageChanged = (tabKey) => {
      if ($scope.tabs[tabKey].firstTimeLoad === false) {
        filterCompletedAppts(tabKey);
      }
    };

    function writeSearchFilter() {
      const prevSearchValues = storage.getData('filterDataLangCompAppointments');
      if (!prevSearchValues) {
        return;
      }
      const prevForm = prevSearchValues.data;
      const prevTab = prevSearchValues.tab;
      ([$scope.tabs[prevTab].completed.organization] = _.filter($scope.orgList, { id: prevForm.clientOrgId }));
      $scope.tabs[prevTab].completed.language = prevForm.language;
      $scope.tabs[prevTab].completed.dateRange = prevForm.dateRange;
    }

    $scope.clearFilters = (tabKey) => {
      const prevSearchValues = storage.getData('filterDataLangCompAppointments');
      if (prevSearchValues && prevSearchValues.tab === tabKey) {
        storage.removeData('filterDataLangCompAppointments');
      }
      $scope.tabs[tabKey].searchFormQuery = {
        dateRange: { startDate: null, endDate: null },
      };
      $scope.tabs[tabKey].completed = { dateRange: { startDate: null, endDate: null, language: '' } };
      $scope.tabs[tabKey].pagination.currentPage = 1;
      $location.url($location.path());
      filterCompletedAppts(tabKey);
    };

    $scope.getAllOrganizations = (offset, limit) => $http.get(`${API_BASE_URL}organisation/getOrganizations/active/${offset}/${limit}`).then((resp) => {
      let response = resp.data;
      response = (response.org_details || []).sort((a, b) => a.name.localeCompare(b.name));
      return {
        data: response,
        count: resp.data.totalCount,
      };
    });

    $scope.onOrgFetchComplete = (list) => {
      $scope.orgList = list;
      writeSearchFilter();
    };

    function init() {
      Object.keys($scope.tabs).forEach((tabKey) => {
        filterCompletedAppts(tabKey);
      });
    }
    init();

    $scope.tabClicked = (tabKey) => {
      $location.url($location.path());
      const matchData = setSearchFilters(tabKey);
      setUrlFilters(tabKey, matchData);
      $location.search('activeTab', tabKey);
    };


    $scope.enterOnFormSearch = (event, tabKey) => {
      if (event.keyCode === 13) {
        if ($scope.tabs[tabKey].searchFormQuery.patientPhoneNumber && $scope.tabs[tabKey].searchFormQuery.patientPhoneNumber.charAt(0) === '_') {
          $scope.tabs[tabKey].searchFormQuery.patientPhoneNumber = undefined;
        }
        if ($scope.tabs[tabKey].completed.patientPhoneNumber && $scope.tabs[tabKey].completed.patientPhoneNumber.charAt(0) === '_') {
          $scope.tabs[tabKey].completed.patientPhoneNumber = undefined;
        }
        $scope.searchButtonClicked(tabKey);
      }
    };

    $scope.searchButtonClicked = (tabKey) => {
      $scope.tabs[tabKey].pagination.currentPage = 1;

      if ($scope.tabs[tabKey].completed.organization) {
        if (!$scope.tabs[tabKey].searchFormQuery.organization) $scope.tabs[tabKey].searchFormQuery.organization = {};
        $scope.tabs[tabKey].searchFormQuery.organization.name = $scope.tabs[tabKey].completed.organization.name;
        $scope.tabs[tabKey].searchFormQuery.organization.id = $scope.tabs[tabKey].completed.organization.id;
      } else if ($scope.tabs[tabKey].completed.clientOrgId) {
        $scope.tabs[tabKey].searchFormQuery.clientOrgId = $scope.tabs[tabKey].completed.clientOrgId;
      }

      if ($scope.tabs[tabKey].completed.language) {
        $scope.tabs[tabKey].searchFormQuery.language = $scope.tabs[tabKey].completed.language;
      } else {
        $scope.tabs[tabKey].searchFormQuery.language = null;
      }

      if ($scope.tabs[tabKey].completed.dateRange && $scope.tabs[tabKey].completed.dateRange.startDate && $scope.tabs[tabKey].completed.dateRange.endDate) {
        if (!$scope.tabs[tabKey].searchFormQuery.dateRange) $scope.tabs[tabKey].searchFormQuery.dateRange = {};
        $scope.tabs[tabKey].searchFormQuery.dateRange.startDate = $scope.tabs[tabKey].completed.dateRange.startDate;
        $scope.tabs[tabKey].searchFormQuery.dateRange.endDate = $scope.tabs[tabKey].completed.dateRange.endDate;
      } else {
        $scope.tabs[tabKey].searchFormQuery.dateRange = null;
      }
      filterCompletedAppts(tabKey, true);
    };

    $scope.refreshCompletedAppointments = () => {
      filterCompletedAppts();
    };

    let appointmentsToApprove = [];
    $scope.showApproveUI = false;

    $scope.showApproveButtonClicked = () => {
      $scope.showApproveUI = true;
    };

    $scope.addToApprove = (id) => {
      if (appointmentsToApprove.indexOf(id) > -1) {
        const index = appointmentsToApprove.indexOf(id);
        appointmentsToApprove.splice(index, 1);
      } else {
        appointmentsToApprove.push(id);
      }
    };
    $scope.checkAllAppointments = false;
    $scope.addAllToApprove = (apptData) => {
      if (!$scope.checkAllAppointments) {
        appointmentsToApprove = [];
        for (let i = 0; i < apptData.length; i += 1) {
          if (apptData[i].appointment_approved !== true) {
            appointmentsToApprove.push(apptData[i].id);
          }
        }
        $scope.checkAllAppointments = true;
      } else {
        appointmentsToApprove = [];
        $scope.checkAllAppointments = false;
      }
    };

    $scope.isSelectedAppt = (id) => {
      if (appointmentsToApprove.indexOf(id) > -1) {
        return true;
      }
      return false;
    };
    $scope.approveSelectedApoointments = () => {
      if (appointmentsToApprove.length < 1) {
        toaster.pop({
          type: 'error',
          title: 'No Trip Selected',
          showCloseButton: true,
          timeout: 10000,
        });
        return;
      }
      const urlToApproveAppointments = `${API_BASE_URL}appointment/approve`;
      const params = {
        appointmentsId: appointmentsToApprove,
      };
      const approveAppointmentsApiCall = $http.put(urlToApproveAppointments, params);

      approveAppointmentsApiCall.then(
        (result) => {
          if (result) {
            if (result.data.success) {
              SweetAlert.swal({
                title: 'Approved',
                text: 'Trips approved successfully',
                type: 'success',
              });
              appointmentsToApprove = [];
              $scope.showApproveUI = false;
              filterCompletedAppts('pr');
            } else {
              SweetAlert.swal({
                title: 'Failed',
                text: result.data.msg,
                type: 'error',
              });
            }
          }
        },
        (err) => {
          toaster.pop({
            type: 'error',
            title: err.data.msg,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    };
  },
]);
