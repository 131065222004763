const { permissionsConstants } = relaylib.permissions;
const { rideStatusMapping } = relaylib.ride;
const statuses = relaylib.ride.status;
const statusObj = {
  'Finding Driver': {
    type: 'info',
  },
  'Driver Confirmed': {
    type: 'info',
  },
  'Driver Arrived': {
    type: 'warn',
  },
  'Patient Enroute': {
    type: 'info',
  },
  'Driver Canceled': {
    type: 'danger',
  },
  'Patient No Show': {
    type: 'danger',
  },
  'Provider No Show': {
    type: 'danger',
  },
  'Ride Canceled': {
    type: 'danger',
  },
  'Late Arrival': {
    type: 'warn',
  },
  'Ride Completed': {
    type: 'success',
  },
  'Surge Approved': {
    type: 'warn',
  },
  'Variance Issue': {
    type: 'warn',
  },
  'Pending Ride Request': {
    type: 'info',
  },
  'Request Overdue': {
    type: 'danger',
  },
  'Failed CC Auth': {
    type: 'warn',
  },
  'Failed CC Payment': {
    type: 'danger',
  },
  'Failed-Other': {
    type: 'danger',
  },
  'No Driver Available': {
    type: 'danger',
  },
  'Arrival Time Exceeded': {
    type: 'warn',
  },
  'Confirm Member Pickup': {
    type: 'danger',
  },
  'Confirm Member Dropoff': {
    type: 'danger',
  },
  'Pending Disposition': {
    type: 'success',
  },
};

angular.module('relayHealth').controller('activeRidesCtrl', [
  '$scope',
  '$rootScope',
  '$http',
  'API_BASE_URL',
  'toaster',
  '$uibModal',
  'Pubnub',
  'pubnubNotificationFactory',
  '$timeout',
  'loadingScreenFactory',
  '$location',
  '$state',
  'checkAndClearETA',
  'getMarketSegments',
  'isOCCMRole',
  'oldDashboardCheck',
  'permissionFilter',
  '$q',
  'storageManager',
  'requestingOrganizationFactory',
  'orgHierarchyFactory',
  function activeRidesCtrl(
    $scope,
    $rootScope,
    $http,
    API_BASE_URL,
    toaster,
    $uibModal,
    Pubnub,
    pubnubNotificationFactory,
    $timeout,
    loadingScreenFactory,
    $location,
    $state,
    checkAndClearETA,
    getMarketSegments,
    isOCCMRole,
    oldDashboardCheck,
    permissionFilter,
    $q,
    storageManager,
    requestingOrganizationFactory,
    orgHierarchyFactory,
  ) {
    let firstLoad = true;
    const currentState = $state.current.name;
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const { storage } = storageManager;
    $scope.provider = { providerNameList: [] };
    $scope.searchFormQuery = {};
    $scope.searchFormQuery.status = 'Select Status';
    $scope.activeRide = {};
    $scope.activeRide.status = 'Select Status';
    $scope.activeRide.userList = [];
    $scope.disableOrgList = false;
    const requestingOrganizationsVisibleCount = 20;

    function toasterError(message) {
      toaster.pop({
        type: 'error',
        title: message || 'Could not fetch users List',
        showCloseButton: true,
        timeout: 10000,
      });
    }

    let matchData;
    let makeApiCall = true;
    let activeChanelGroup = null;

    function adjustPagination() {
      $scope.pagination.lastItem = ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage
        + $scope.displayItemsperPages;
      if ($scope.pagination.lastItem > $scope.pagination.totalItems) {
        $scope.pagination.lastItem = $scope.pagination.totalItems;
      }
    }


    function refreshSingleTile(index, rideData) {
      const rideIndex = index || $scope.activeRides.findIndex(
        item => item.id === rideData.id,
      );
      const existingFileNumber = $scope.activeRides[rideIndex].fileNumberLabel || null;
      $scope.activeRides[rideIndex] = angular.copy(rideData);
      $scope.activeRides[rideIndex].fileNumberLabel = existingFileNumber;
      if ($scope.activeRides[rideIndex].ride_status === 'Ride Completed') {
        $scope.activeRides[rideIndex].driver_eta = null;
        $scope.activeRides[rideIndex].redis_driver_eta = null;
        if (
          $scope.activeRides[rideIndex].leg_type === 'round'
          && $scope.activeRides[rideIndex].ride_category === 'active'
        ) {
          $scope.activeRides[rideIndex].displayStatus = 'Pending Ride Request';
        }
        if (
          $scope.activeRides[rideIndex].leg_type === 'multi'
          && $scope.activeRides[rideIndex].ride_category === 'active'
        ) {
          $scope.activeRides[rideIndex].displayStatus = 'Pending Ride Request';
        }
        if ($scope.activeRides[rideIndex].ride_category === 'completed') {
          $timeout(() => {
            // eslint-disable-next-line no-use-before-define
            filterActiveAppts();
          }, 1000 * 120);
        }
      }

      checkAndClearETA($scope.activeRides[rideIndex]);

      $scope.activeRides[rideIndex].statusType = statusObj[`${$scope.activeRides[rideIndex].ride_status}`].type;
      $scope.$apply();
    }

    function handlePubnubMessages(payload) {
      const { message } = payload;
      const statusArray = ['Finding Driver', 'Surge Approval Needed'];
      const catArray = ['issue', 'active', 'completed', 'special_ride'];
      if (makeApiCall) {
        const cond1 = message.type === 'refresh_issues'
          && $state.current.name === 'main.dashboard.active';
        const cond2 = message.ride_data
          && (statusArray.includes(message.ride_data.ride_status)
            || catArray.includes(message.ride_data.ride_category));
        if (cond1 || cond2) {
          const url = $location.absUrl().split('/');
          if (url[6].indexOf('activeRides') !== -1) {
            makeApiCall = false;
            // eslint-disable-next-line no-use-before-define
            filterActiveAppts();
          }
        } else if (message.ride_data && message.type === 'Update Ride Manager') {
          refreshSingleTile(undefined, message.ride_data);
        } else {
          // Nothing to do
        }
      }
    }

    function registerListener() {
      $rootScope.$on(
        Pubnub.getMessageEventNameFor(activeChanelGroup),
        (event, payload) => {
          const { message } = payload;
          if (
            message.ride_data.ride_status === 'Ride Completed'
            && message.ride_data.ride_category === 'completed'
          ) {
            // eslint-disable-next-line no-use-before-define
            filterActiveAppts();
          } else {
            $scope.activeRides.some((ride, rideIndex) => {
              if (
                ride.appointment
                && (ride.appointment.id
                  === message.appt_id
                  && message.ride_data.file_no_suffix
                    === ride.file_no_suffix)
                && message.type !== 'eta'
              ) {
                refreshSingleTile(rideIndex, message.ride_data);
                return true;
              }
              if (
                ride.appointment
                && ride.appointment.id
                  === message.appt_id
                && message.type === 'eta'
                && message.location_info
              ) {
                $scope.activeRide[rideIndex].driver_eta = message.location_info.eta;
                $scope.activeRide[rideIndex].redis_driver_eta = message.location_info.eta;
                $scope.$apply();
                return true;
              }

              return false;
            });
          }
        },
      );
    }

    function listenForPubnubMessages() {
      $rootScope.$on(
        Pubnub.getMessageEventNameFor(userDetails.user_id),
        (event, payload) => {
          handlePubnubMessages(payload);
        },
      );
      $rootScope.$on(
        Pubnub.getMessageEventNameFor('msa_presence_channel'),
        (event, payload) => {
          handlePubnubMessages(payload);
        },
      );
      $rootScope.$on(
        Pubnub.getMessageEventNameFor(
          `org_id_channel_${userDetails.organisation.id}`,
        ),
        (event, payload) => {
          handlePubnubMessages(payload);
        },
      );
      registerListener();
    }

    async function setAncestorOrgFileNumberLabels(orgIds) {
      if (!orgIds || !orgIds.length) {
        return;
      }
      const uniqueOrgIds = Array.from(new Set(orgIds));
      try {
        const { data: { orgsWithInheritedSettings = [] } } = await orgHierarchyFactory.getOrgsWithInheritedSettings(uniqueOrgIds.join(),
          [
            'file_number_label',
          ]);
        orgsWithInheritedSettings.forEach((orgWithInheritedSettings) => {
          const ridesToUpdate = $scope.activeRides.filter(ride => orgWithInheritedSettings.id === ride.org_id);

          if (ridesToUpdate.length) {
            ridesToUpdate.forEach((rideToUpdate) => {
              Object.assign(rideToUpdate, { fileNumberLabel: orgWithInheritedSettings.file_number_label });
            });
          }
        });
        $scope.$apply();
      } catch (error) {
        toaster.pop({
          type: 'error',
          title: (error.data || {}).message ? error.data.message : error.message,
          showCloseButton: true,
          timeout: 10000,
        });
      }
    }
    function ifGHRideExcludingMAS(ride) {
      const externalIdentifier = _.get(ride, 'appointment.additional_details.external_identifier', '');
      return (ride.external_leg_id && (!externalIdentifier || externalIdentifier.toLowerCase() !== 'mas'));
    }

    function getAllActiveRides(filters) {
      $scope.loading = true;
      if (activeChanelGroup) {
        pubnubNotificationFactory.unsubscribeChannelGroup(activeChanelGroup);
      }
      const params = Object.assign({}, filters);

      params.org_id = userDetails.organisation.id;
      if (!oldDashboardCheck.check()) {
        params.old_dashboard = false;
        params.no_pu = true; // no potential unavailable is required here
      } else {
        params.old_dashboard = true;
      }

      $http({
        url: `${API_BASE_URL}rides/getRidesListByRideCategory/active/ride."updatedAt" DESC/${
          ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage
        }/${
          $scope.pagination.itemsPerPage
        }`,
        method: 'GET',
        params,
      }).then(
        (result) => {
          $scope.loading = false;
          if (result.data.success) {
            $scope.activeRides.length = 1;
            $scope.activeRides = $scope.showChildData() ? result.data.data : $scope.activeRides.concat(result.data.data);
            $scope.pagination.totalItems = result.data.totalCount;
            adjustPagination();

            const orgsIdsForFetchingAncestors = [];
            $scope.activeRides.forEach((ride, rideIndex) => {
              const currentRide = $scope.activeRides[rideIndex];
              if (currentRide.ride_status) {
                currentRide.statusType = statusObj[`${currentRide.ride_status}`].type;
                if (currentRide.ride_status === 'Ride Completed') {
                  currentRide.redis_driver_eta = null;
                  currentRide.driver_eta = null;
                  if (
                    currentRide.leg_type === 'round'
                    && currentRide.ride_category === 'active'
                  ) {
                    currentRide.displayStatus = 'Pending Ride Request';
                  } else if (
                    currentRide.leg_type === 'multi'
                    && currentRide.ride_category === 'active'
                  ) {
                    currentRide.displayStatus = 'Pending Ride Request';
                  }
                  if (currentRide.ride_category === 'completed') {
                    $timeout(() => {
                      // eslint-disable-next-line no-use-before-define
                      filterActiveAppts();
                    }, 1000 * 120);
                  }
                } else if (
                  currentRide.ride_status === 'Driver Canceled'
                  || currentRide.ride_status === 'Patient No Show'
                  || currentRide.ride_status === 'Ride Canceled'
                ) {
                  currentRide.driver_eta = null;
                  currentRide.redis_driver_eta = null;
                  if (ifGHRideExcludingMAS(currentRide) && (currentRide.leg_type === 'round' || currentRide.leg_type === 'multi')) {
                    currentRide.displayStatus = rideStatusMapping(currentRide.ride_status, currentRide.ride_category);
                    /** To set the Display Status to Show the respsective colour by statusType */
                    if (currentRide.displayStatus === statuses.PENDING_RIDE_REQUEST.status)  currentRide.statusType = 'success';
                  }
                } else if (currentRide.ride_status === 'Provider No Show') {
                  if (ifGHRideExcludingMAS(currentRide) && (currentRide.leg_type === 'round' || currentRide.leg_type === 'multi')) {
                    currentRide.displayStatus = rideStatusMapping(currentRide.ride_status, currentRide.ride_category);
                    /** To set the Display Status to Show the respsective colour by statusType */
                    if (currentRide.displayStatus === statuses.PENDING_RIDE_REQUEST.status)  currentRide.statusType = 'success';
                  }
                }
                checkAndClearETA(currentRide); // Check and clear ETA if not applicable for current ride status
              }

              if (_.get(currentRide, 'appointment.organisation.customizable_file_number', false)) {
                currentRide.fileNumberLabel = currentRide.appointment.organisation.file_number_label;
              } else if (currentRide.org_id) {
                orgsIdsForFetchingAncestors.push(
                  currentRide.org_id,
                );
              }
            });

            if (orgsIdsForFetchingAncestors.length) {
              setAncestorOrgFileNumberLabels(orgsIdsForFetchingAncestors);
            }

            activeChanelGroup = result.data.channelGroup;
            if (activeChanelGroup) {
              pubnubNotificationFactory.subscribeChannelGroup(
                activeChanelGroup,
              );
              $rootScope.$broadcast('activeChannelGroup', activeChanelGroup);
            }

            // pubnubNotificationFactory.addChannelsToGroup(channels,"activeApptChannel")
            makeApiCall = true;

            if ((firstLoad)) {
              firstLoad = false;
              listenForPubnubMessages();
            }
          } else {
            $scope.activeRides.length = $scope.showChildData() ? 0 : 1;
            $scope.pagination.totalItems = 0;
            adjustPagination();
            if (result.data.message) {
              toaster.pop({
                type: 'info',
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
            } else {
              toaster.pop({
                type: 'info',
                title: 'No Rides Found',
                showCloseButton: true,
                timeout: 10000,
              });
            }
          }
        },
        (err) => {
          $scope.loading = false;
          $scope.activeRides.length = 1;
          $scope.pagination.totalItems = 0;
          adjustPagination();
          toaster.pop({
            type: 'info',
            title: err.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    }

    function filterActiveAppts(isSearch = false) {
      matchData = {
        organizationName: $scope.searchFormQuery.organization
          ? $scope.searchFormQuery.organization.name
          : undefined,
        patientName: $scope.searchFormQuery.patientName
          ? $scope.searchFormQuery.patientName.trim()
          : undefined,
        status:
          $scope.searchFormQuery.status !== 'Select Status'
            ? $scope.searchFormQuery.status
            : undefined,
        phone: $scope.searchFormQuery.phone
          ? $scope.searchFormQuery.phone
            .replace(/-/g, '')
            .replace(' ', '')
            .replace(/_/g, '')
            .replace('(', '')
            .replace(')', '')
          : undefined,
        fileNo: $scope.searchFormQuery.fileNo
          ? $scope.searchFormQuery.fileNo.trim()
          : undefined,
        market_segment_id: $scope.searchFormQuery.market_segment_id
          ? $scope.searchFormQuery.market_segment_id
          : undefined,
        userList: $scope.searchFormQuery.userList
          ? $scope.searchFormQuery.userList
          : undefined,
        orderId: $scope.searchFormQuery.orderId || undefined,
        tripIdentifierType: $scope.searchFormQuery.tripIdentifierType
          ? $scope.searchFormQuery.tripIdentifierType
          : undefined,
        masInvoiceNumber: $scope.searchFormQuery.masInvoiceNumber || undefined,
        providerName: $scope.searchFormQuery.providerName || undefined,
        requestingOrganizationId: $scope.searchFormQuery.requestingOrganization ? $scope.searchFormQuery.requestingOrganization.id : undefined,
        requestingOrganization: $scope.searchFormQuery.requestingOrganization ? $scope.searchFormQuery.requestingOrganization.name : undefined,
      };
      if (isSearch) {
        const matchDataCopy = angular.copy(matchData);
        matchDataCopy.userList = $scope.activeRide.userList;
        storage.setDataByKey('keepFilterData', currentState, matchDataCopy);
      }
      /* this will be deleted as the requesting organisation name
      will not be used in server side and stored in local storage
      for retaining filter  */
      delete matchData.requestingOrganization;
      getAllActiveRides(matchData);
    }

    const orgUsers = {};
    $scope.getUsersList = function getUsersList(offset, limit) {
      const getUsersUrl = 'getUsersByOrgIdForDropBox/';

      if (
        !(
          $scope.activeRide
          && $scope.activeRide.organization
          && $scope.activeRide.organization.id
        )
      ) {
        return $q.resolve({ data: [] });
      }
      if (Object.prototype.hasOwnProperty.call(orgUsers, $scope.activeRide.organization.id)) {
        return $q.resolve({
          data: orgUsers[$scope.activeRide.organization.id],
          count: orgUsers[$scope.activeRide.organization.id].length,
        });
      }
      // Do nothing if data not found in cache

      const selectedOrg = $scope.activeRide.organization.id;
      return $http
        .get(
          `${API_BASE_URL}${getUsersUrl}${selectedOrg}/${offset}/${limit}`,
        )
        .then(
          (resp) => {
            let userList = [];
            const { data } = resp;
            if (data.success) {
              userList = data.userList.map(user => ({
                name: user.full_name,
                userId: user.ride_requester_id,
              }));
            } else {
              toasterError(data.message);
            }
            return {
              data: userList,
              count: Number(data.totalCount),
            };
          },
          (data) => {
            toasterError(data.message);
          },
        );
    };

    $scope.cacheUserList = function cacheUserList(data) {
      if (
        data
        && data.length > 0
        && $scope.activeRide
        && _.get($scope.activeRide, 'organization.id')
      ) {
        orgUsers[$scope.activeRide.organization.id] = data;
      }
    };

    if (oldDashboardCheck.check()) {
      $scope.oldDashboard = true;
    } else {
      $scope.oldDashboard = false;
    }
    $scope.displayItemsperPages = 17;

    if ($scope.showChildData()) {
      $scope.displayItemsperPages = 18;
    }

    $scope.pagination = {
      totalItems: 0,
      currentPage: 1,
      lastItem: 0,
      itemsPerPage: $scope.displayItemsperPages,
    };

    $scope.isOCCMRole = isOCCMRole()
      || permissionFilter(permissionsConstants.MARKET_SEGMENT_READ);
    $scope.permissionsConstants = permissionsConstants;

    $scope.pageChanged = function pageChanged() {
      filterActiveAppts();
    };

    $scope.activeRides = [
      {
        text: 'REQUEST A  RIDE',
      },
    ];

    $scope.statusList = ['Select Status'].concat(Object.keys(statusObj));
    const index = $scope.statusList.indexOf('Ride Completed');
    if (index !== -1) {
      $scope.statusList.splice(index, 1);
    }

    $scope.marketSegmentList = [];

    getMarketSegments((err, data) => {
      if (!err) {
        $scope.marketSegmentList = data;
      } else {
        toaster.pop({
          type: 'error',
          title: err.msg || err,
          showCloseButton: true,
          timeout: 10000,
        });
      }
    });

    function writeSearchFilter() {
      const prevForm = storage.getDataByKey('keepFilterData', currentState);
      if (prevForm) {
        [$scope.activeRide.organization] = _.filter($scope.orgList.data, {
          name: prevForm.organizationName,
        });
        $scope.activeRide.patientName = prevForm.patientName;
        $scope.activeRide.status = prevForm.status;
        $scope.activeRide.phone = prevForm.phone;
        $scope.activeRide.fileNo = prevForm.fileNo;
        $scope.activeRide.market_segment_id = prevForm.market_segment_id;
        $scope.activeRide.userList = prevForm.userList;
        $scope.activeRide.orderId = prevForm.orderId;
        $scope.activeRide.tripIdentifierType = prevForm.tripIdentifierType;
        $scope.activeRide.masInvoiceNumber = prevForm.masInvoiceNumber;
        $scope.activeRide.providerName = prevForm.providerName;
        $scope.orgChanged(true);
        if (prevForm.requestingOrganization && prevForm.requestingOrganizationId) {
          $scope.activeRide.requestingOrganization = {
            id: prevForm.requestingOrganizationId,
            name: prevForm.requestingOrganization,
          };
        }
        $timeout(() => {
          $scope.searchButtonClicked();
        });
      } else {
        $scope.searchButtonClicked();
      }
    }

    const getAllOrganizations = async () => {
      loadingScreenFactory.showLoadingScreen();
      const params = {
        fields: ['orgId', 'orgName'],
        displayOrgChildren: true,
      };
      const ROLES = relaylib.common.constants.userRoles;
      if (
        userDetails.organisation.is_third_party
        && [ROLES.ORG_SUPERADMIN, ROLES.ORG_ADMIN].includes(userDetails.role)
      ) {
        params.getDelegatingOrgs = true;
      }
      if (userDetails.belongsToParentOrg) {
        params.orgId = userDetails.organisation.id;
      }
      try {
        const { data: { organisations: organizations } } = await $http.get(`${API_BASE_URL}organisations`, {
          params,
        });
        $scope.organizations = {
          data: organizations,
          visibleChoices: requestingOrganizationsVisibleCount,
        };
      } catch (error) {
        toaster.pop({
          type: 'error',
          title: error.message || 'Unable to fetch Organizations',
          showCloseButton: true,
          timeout: 10000,
        });
      } finally {
        // eslint-disable-next-line no-use-before-define
        onOrgFetchComplete($scope.organizations);
      }
    };
    getAllOrganizations();
    $scope.loadMoreChoices = () => {
      $scope.requestingOrganizations.visibleChoices += requestingOrganizationsVisibleCount;
    };
    const getRequestingOrganizations = async (organizationId) => {
      try {
        const { data: { organisations: requestingOrganizations } } = await requestingOrganizationFactory.getRequestingOrganizations({
          organizationId,
          fields: ['orgId', 'orgName'],
        });
        $scope.requestingOrganizations = {
          data: requestingOrganizations,
          visibleChoices: requestingOrganizationsVisibleCount,
        };
      } catch (error) {
        toaster.pop({
          type: 'error',
          title: error.message || 'Unable to fetch Requesting Organizations',
          showCloseButton: true,
          timeout: 10000,
        });
      } finally {
        $scope.$apply();
      }
    };
    getRequestingOrganizations();

    $scope.clearFilters = function clearFilters() {
      storage.removeDataByKey('keepFilterData', currentState);
      $scope.searchFormQuery = {
        status: 'Select Status',
      };
      $scope.activeRide = {
        status: 'Select Status',
        organization: $scope.activeRide.organization,
        userList: [],
        patientName: '',
        providerName: undefined,
        requestingOrganization: undefined,
      };
      $scope.pagination.currentPage = 1;
      if (!$scope.disableOrgList) {
        delete $scope.activeRide.organization;
      }
      getRequestingOrganizations();
      filterActiveAppts();
    };

    $scope.orgChanged = (organization) => {
      if (!organization) {
        $scope.activeRide.userList = [];
      }
      $scope.reloadRequesters = true;
      $scope.activeRide.requestingOrganization = undefined;
      if ($scope.activeRide.organization) {
        getRequestingOrganizations($scope.activeRide.organization.id);
      } else {
        getRequestingOrganizations();
      }
    };
    $scope.loadMoreOrgChoices = () => {
      $scope.organizations.visibleChoices += requestingOrganizationsVisibleCount;
    };
    const onOrgFetchComplete = (list) => {
      loadingScreenFactory.hideLoadingScreen();
      $scope.orgList = list;
      writeSearchFilter();
      if (list.length === 1) {
        $scope.activeRide.organization = {
          id: $scope.orgList[0].id,
          name: $scope.orgList[0].name,
        };
        $scope.orgChanged($scope.activeRide.organization.id);
        $scope.disableOrgList = true;
      }
    };

    const getUserDetailsById = function getUserDetailsById() {
      const getUserDetailsByIdCall = $http.get(
        `${API_BASE_URL}users/getUserDetailsById/${userDetails.user_id}`,
        {
          params: {
            org_id: userDetails.organisation.id,
          },
        },
      );
      getUserDetailsByIdCall.then(
        (result) => {
          if (result.data.success) {
            /*
              * fetch active rides in case user is logged in by riderAdmin role
              * this is required because active_rides.html page we are not calling getactiveride function
              * for this role.
              */
            if (result.data.userDetails.role === 'riderAdmin') {
              $scope.searchButtonClicked();
            }
          } else {
            toaster.pop({
              type: 'error',
              title: result.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        },
        (error) => {
          toaster.pop({
            type: 'error',
            title: error.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    };
    getUserDetailsById();

    $scope.openActivePopup = function openActivePopup(rideDetails) {
      loadingScreenFactory.showLoadingScreen();
      const modalInstance = $uibModal.open({
        animation: true,
        template: require('../views/inprogress_details.html'),
        controller: 'inProgressDetailsCtrl',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          rideDetails: [
            '$q',
            (q) => {
              const defer = q.defer();
              const getRideDetails = $http.get(
                `${API_BASE_URL}ride/getRideDetailsById/${rideDetails.id}`,
                {
                  params: {
                    org_id: userDetails.organisation.id,
                    checkIfZtripProviderRide: true,
                  },
                },
              );
              getRideDetails
                .then(({ data: { rideDetails: ride } }) => {
                  checkAndClearETA(ride);
                  defer.resolve(Object.assign(ride, {
                    activeChannelGroup: activeChanelGroup,
                    oldDashboard: $scope.oldDashboard,
                  }));
                })
                .catch((error) => {
                  loadingScreenFactory.hideLoadingScreen();
                  toaster.pop({
                    type: 'error',
                    title: error.data.message,
                    showCloseButton: true,
                    timeout: 10000,
                  });
                });
              return defer.promise;
            },
          ],
          caller() {
            return 'AR';
          },
        },
      });
      Object.assign($rootScope, { modal: modalInstance });
    };

    $scope.$on('refreshActiveRideTiles', () => {
      filterActiveAppts();
    });

    $scope.enterOnFormSearch = function enterOnFormSearch(event) {
      if (event.keyCode === 13) {
        if (
          $scope.activeRide.phone
          && $scope.activeRide.phone.charAt(0) === '_'
        ) {
          $scope.activeRide.phone = undefined;
        }
        $scope.searchButtonClicked();
      }
    };
    $scope.tripIdentifierChanged = function tripIdentifierChanged() {
      $scope.activeRide.orderId = null;
      $scope.activeRide.masInvoiceNumber = null;
      $scope.activeRide.fileNo = null;
    };
    $scope.searchButtonClicked = () => {
      $scope.pagination.currentPage = 1;
      if (
        $scope.activeRide.providerName
        && $scope.activeRide.providerName.trim()
      ) {
        $scope.searchFormQuery.providerName = $scope.activeRide.providerName.trim();
      } else {
        $scope.searchFormQuery.providerName = null;
      }
      if ($scope.activeRide.organization) {
        if (!$scope.searchFormQuery.organization) {
          $scope.searchFormQuery.organization = {};
        }
        $scope.searchFormQuery.organization.name = $scope.activeRide.organization.name;
      } else {
        $scope.searchFormQuery.organization = null;
      }
      if ($scope.activeRide.patientName) {
        $scope.searchFormQuery.patientName = $scope.activeRide.patientName;
      } else {
        $scope.searchFormQuery.patientName = null;
      }
      if ($scope.activeRide.status) {
        $scope.searchFormQuery.status = $scope.activeRide.status;
      } else {
        $scope.searchFormQuery.status = null;
      }
      if ($scope.activeRide.phone) {
        $scope.searchFormQuery.phone = $scope.activeRide.phone;
      } else {
        $scope.searchFormQuery.phone = null;
      }
      if ($scope.activeRide.fileNo) {
        $scope.searchFormQuery.fileNo = $scope.activeRide.fileNo;
      } else {
        $scope.searchFormQuery.fileNo = null;
      }
      if ($scope.activeRide.market_segment_id) {
        $scope.searchFormQuery.market_segment_id = $scope.activeRide.market_segment_id;
      } else {
        $scope.searchFormQuery.market_segment_id = null;
      }
      if ($scope.activeRide.orderId) {
        $scope.searchFormQuery.orderId = $scope.activeRide.orderId;
      } else {
        $scope.searchFormQuery.orderId = null;
      }
      $scope.searchFormQuery.requestingOrganization = $scope.activeRide.requestingOrganization || undefined;
      if ($scope.activeRide.userList) {
        let userListString = '';
        const { userList } = $scope.activeRide;
        const userListLength = userList.length;
        userList.forEach((user, userIndex) => {
          if (userIndex === userListLength - 1) {
            userListString += user.userId;
          } else {
            userListString += `${user.userId},`;
          }
        });
        $scope.searchFormQuery.userList = userListString;
      }

      $scope.searchFormQuery.tripIdentifierType = $scope.activeRide.tripIdentifierType || null;
      $scope.searchFormQuery.masInvoiceNumber = $scope.activeRide.masInvoiceNumber || null;

      filterActiveAppts(true);
    };

    $scope.refreshActiveAppointments = () => filterActiveAppts();


    $(window).on('beforeunload.activerides', () => {
      // Unsubscribe and remove active channel group
      if (activeChanelGroup) {
        pubnubNotificationFactory.unsubscribeChannelGroup(activeChanelGroup);
        pubnubNotificationFactory.deleteChannelGroup(activeChanelGroup);
        activeChanelGroup = null;
      }
    });

    $scope.$on('$destroy', () => {
      // Unsubscribe and remove active channel group
      if (activeChanelGroup) {
        pubnubNotificationFactory.unsubscribeChannelGroup(activeChanelGroup);
        pubnubNotificationFactory.deleteChannelGroup(activeChanelGroup);
        activeChanelGroup = null;
      }
      $(window).off('.activerides');
    });

    /** IIFE to fetch provider names */
    (async () => {
      try {
        const { data: { providerNames } } = await $http.get(`${API_BASE_URL}vendors/provider-names`);
        $scope.provider.providerNameList = providerNames;
      } catch (err) {
        toasterError('Failed to get provider name list');
      }
    })();
    $scope.startsWith = (state, viewValue) => {
      if (viewValue.length < 3) {
        return undefined;
      }
      return state.substr(0, viewValue.length).toLowerCase() === viewValue.toLowerCase();
    };
  },
]);
