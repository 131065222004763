const provider = {
  cts: {
    software_provider_name: "cts",
    software_provider_id: "cts",
    type: "cts",
    event_type: {
      book: "book",
      cancel: "cancel",
      revoke: "revoke",
      ride_scheduled: "RideScheduled",
      on_the_way: "OnTheWay",
      pickup_arrived: "PickupArrived",
      pickup_completed: "PickupCompleted",
      dropoff_arrived: "DropoffArrived",
      dropoff_completed: "DropoffCompleted",
      location_reported: "LocationReported"
    },
    event_action: {
      book: "book",
      cancel: "cancel",
      revoke: "revoke",
      ride_scheduled: "RideScheduled",
      on_the_way: "OnTheWay",
      pickup_arrived: "PickupArrived",
      pickup_completed: "PickupCompleted",
      dropoff_arrived: "DropoffArrived",
      dropoff_completed: "DropoffCompleted",
      location_reported: "LocationReported"
    },
    event_source: {
      webhook: "cts_webhook",
      cron: "relay_cron",
      relay_web_app: "relay_web_app"
    }
  },
  ztrip: {
    software_provider_name: ['ztrip'],
    software_provider_id: ['228'],
    type: "ztrip",
    event_type: {
      book: "book",
      cancel: "cancel",
      revoke: "revoke",
      update: "update",
    },
    event_action: {
      book: "book",
      cancel: "cancel",
      revoke: "cancel",
      update: "update",
    },
    event_source: {
      webhook: "ztrip_webhook",
      cron: "relay_cron",
      relay_web_app: "relay_web_app"
    }
  }
  
};
export default Object.freeze(provider);
