angular.module('relayHealth').factory('googleService', [
  '$q',
  'SOURCE_ICON',
  'DEST_ICON',
  ($q, SOURCE_ICON, DEST_ICON) => ({
    sourceIcon: SOURCE_ICON,
    destIcon: DEST_ICON,
    geocode(location) {
      const latlng = {
        lat: location.latitude,
        lng: location.longitude,
      };
      const geocoder = new google.maps.Geocoder();
      const deferred = $q.defer();
      geocoder.geocode(
        {
          location: latlng,
        },
        (results, status) => {
          if (status === 'OK' && angular.isDefined(results) && Array.isArray(results) && results[0]) {
            deferred.resolve(results);
          } else {
            deferred.reject('Unable to fetch location info');
          }
        },
      );
      return deferred.promise;
    },
  }),
]);
