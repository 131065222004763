function getState(stateName) {
  if (!stateName) return false;
  const state = localStorage.getItem(stateName);
  return JSON.parse(state);
}

function setState(stateName, state) {
  if (!stateName) return;
  localStorage.setItem(stateName, JSON.stringify(state));
}

function signupStateService() {
  this.getState = getState;

  // update state is used to update a part of a state
  this.updateState = function updateState(stateName, key, value) {
    if (!stateName) return false;
    const state = getState(stateName);
    state[key] = value;
    setState(stateName, state);
    return true;
  };

  // setState sets the whole state
  this.setState = setState;

  this.clearState = function clearState(stateName) {
    if (!stateName) return;
    localStorage.removeItem(stateName);
  };
}

angular.module('relayHealth').service('signupStateService', signupStateService);
