angular.module('relayHealth').controller('editReceiptPopupCtrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  'SweetAlert',
  '$uibModalInstance',
  'rideAndReceiptData',
  '$q',
  'googleService',
  'loadingScreenFactory',
  'capitalizeFilter',
  'toaster',
  'providerService',
  'GOOGLE_PLACES_ATTRIBUTES',
  'NO_SHOW_MAPPING',
  'ngDialog',
  function editReceiptPopupCtrl(
    $scope,
    $http,
    API_BASE_URL,
    SweetAlert,
    $uibModalInstance,
    rideAndReceiptData,
    $q,
    googleService,
    loadingScreenFactory,
    capitalizeFilter,
    toaster,
    providerService,
    GOOGLE_PLACES_ATTRIBUTES,
    NO_SHOW_MAPPING,
    ngDialog,
  ) {
    loadingScreenFactory.hideLoadingScreen();
    const pickupData = {
      type: 'source',
    };
    const destinationData = {
      type: 'destination',
    };
    const reasonCodesConstant = relaylib.receipt.reasonCodes;
    const statuses = relaylib.ride.status;
    const providerMapping = relaylib.common.constants.providerIdMapping;
    let statusReasons = [];
    $scope.traditionalRideCancellation = false;
    const getSystemSettings = async () => {
      try {
        loadingScreenFactory.showLoadingScreen();
        const data = await $http.get(`${API_BASE_URL}setting/default?setting_name=traditional_ride_cancellation`);
        $scope.traditionalRideCancellation = data.data.settings.traditional_ride_cancellation;
      } catch (error) {
        toaster.pop({
          type: 'error',
          title: (error && error.message) || 'Unable to get system settings',
          timeout: 3000,
        });
      } finally {
        loadingScreenFactory.hideLoadingScreen();
        $scope.$apply();
      }
    };
    const getstatusReasons = async () => {
      try {
        loadingScreenFactory.showLoadingScreen();
        const data = await $http.get(`${API_BASE_URL}noshowcodes`);
        statusReasons = data.data.data;
        const cancelReasonData = await $http.get(`${API_BASE_URL}cancellationcodes`);
        $scope.cancelStatusReasonList = cancelReasonData.data.data;
        if (rideAndReceiptData.receiptDetails.ride_additional_detail
           && rideAndReceiptData.receiptDetails.ride_additional_detail
             .status_change_reason) {
          const cancelReasonIndex = $scope.cancelStatusReasonList
            .map(cancelReason => cancelReason.system_code)
            .indexOf(
              rideAndReceiptData.receiptDetails.ride_additional_detail
                .status_change_reason.system_code,
            );
          if (cancelReasonIndex === -1) {
            $scope.ride.system_code = null;
          }
        }
        $scope.statusReasonInit();
      } catch (error) {
        toaster.pop({
          type: 'error',
          title: (error && error.message) || 'Unable to get no show codes',
          timeout: 3000,
        });
      } finally {
        loadingScreenFactory.hideLoadingScreen();
        $scope.$apply();
      }
    };
    getstatusReasons();
    const saveReceiptData = async (rideAndReceiptDetails) => {
      try {
        loadingScreenFactory.showLoadingScreen();
        const {
          data: {
            success,
            message,
            statusMismatchTrack: {
              isStatusHonored = false,
              isFinalDispositionStatus = false,
            },
          } = {},
        } = await $http.put(
          `${API_BASE_URL}ride/receipt`,
          rideAndReceiptDetails,
        );
        let successTitle = 'Receipt details updated successfully!';
        let type = 'success';
        let swalMessage = message;
        loadingScreenFactory.hideLoadingScreen();

        if (!isStatusHonored && isFinalDispositionStatus) {
          successTitle = 'Error';
          type = 'error';
          swalMessage = 'Vendor Invoiced, Status updates are not honored.';
        }

        if (success) {
          SweetAlert.swal({
            title: successTitle,
            text: swalMessage,
            type,
          });
        }
        $uibModalInstance.close({ success: true });
        return;
      } catch ({
        data: {
          message,
        } = {},
      }) {
        toaster.pop({
          type: 'error',
          title: message,
          showCloseButton: true,
          timeout: 10000,
        });
      } finally {
        loadingScreenFactory.hideLoadingScreen();
      }
    };
    const { ride_additional_detail: { status_change_reason: previousStatusReason } = {} } = rideAndReceiptData.receiptDetails;

    $scope.googlePlacesAttributes = GOOGLE_PLACES_ATTRIBUTES;
    $scope.permissionsConstants = relaylib.permissions.permissionsConstants;
    $scope.providerTypes = [
      {
        id: 0,
        name: 'Select one provider',
      }, {
        id: providerMapping.UBER,
        name: providerService.getProviderNameById(providerMapping.UBER),
      }, {
        id: providerMapping.LYFT,
        name: providerService.getProviderNameById(providerMapping.LYFT),
      }, {
        id: providerMapping.EXTERNAL,
        name: providerService.getProviderNameById(providerMapping.EXTERNAL),
      },
    ];
    $scope.rideStatusList = [
      {
        displayText: 'Select Status',
        status: 'Select Status',
      }, {
        displayText: statuses.RIDE_COMPLETED.status,
        status: statuses.RIDE_COMPLETED.status,
      }, {
        displayText: statuses.RIDE_CANCELED.status,
        status: statuses.RIDE_CANCELED.status,
      }, {
        displayText: statuses.NO_DRIVER_AVAILABLE.status,
        status: statuses.NO_DRIVER_AVAILABLE.status,
      }, {
        displayText: `${statuses.VARIANCE_ISSUE.status} - Billable`,
        status: `${statuses.VARIANCE_ISSUE.status} - Billable`,
      }, {
        displayText: `${statuses.VARIANCE_ISSUE.status} - Waived`,
        status: `${statuses.VARIANCE_ISSUE.status} - Waived`,
      }, {
        displayText: statuses.DRIVER_CANCELED.status,
        status: statuses.DRIVER_CANCELED.status,
      }, {
        displayText: statuses.PATIENT_NO_SHOW.status,
        status: statuses.PATIENT_NO_SHOW.status,
      }, {
        displayText: statuses.UATP_OTHER.status,
        status: statuses.UATP_OTHER.status,
      }, {
        displayText: statuses.RETRO_QUEUED.status,
        status: statuses.RETRO_QUEUED.status,
      },
      {
        displayText: statuses.PENDING_DISPOSITION.status,
        status: statuses.PENDING_DISPOSITION.status,
      },
    ];
    $scope.apptStatusList = [
      {
        status: 'Select Status',
      }, {
        status: statuses.APPOINTMENT_COMPLETED.status,
      }, {
        status: statuses.APPOINTMENT_CANCELED.status,
      }, {
        status: statuses.PATIENT_NO_SHOW.status,
      }, {
        status: statuses.RETRO_QUEUED.status,
      },
      { status: statuses.PENDING_DISPOSITION.status },
    ];
    $scope.invoiceBlockedReason = Object.values(relaylib.receipt.blockedReasons).map(blockedReason => blockedReason);
    /**
     * ghRate is used for disabling and showing also
     * ghRate true means show trip flat fee and disclaimer and disble rate fields
     */
    this.disableFields = {
      provider: true,
      fareBreakdown: true,
      commonFields: true,
      vendor: true,
      ghRate: true,
      rideStatus: true,
      actualPickUpAddress: true,
      actualDropOffAddress: true,
      actualPickUpTime: true,
      actualDropOffTime: true,
      reasonType: true,
      blockedReason: true,
      invoicingBlocked: true,
    };

    this.requiredFields = {
      reasonCode: true,
      rideStatus: false,
      reasonType: false,
      updateType: false,
      vendor: false,
      tripFlatFee: false,
      rateFields: false,
      blockedReason: false,
      invoicingBlocked: false,
      actualSourceAddress: false,
      actualPickupTime: false,
      actualDropoffAddress: false,
      actualDropoffTime: false,
    };

    this.showConfirmation = false;
    let map;
    this.reasonCodeList = angular.copy(rideAndReceiptData.reasonCodes);
    this.flatFeeLabel = 'Trip Flat Fee';
    this.flatFeeEditMessage = 'Please enter the amount for the entire Order and the amount will be applied equally to all the legs.';
    this.updateTypes = ['Trip Level', 'Leg Level'];
    this.tooltipIsOpen = false;
    this.tripFlatFee = 0;
    this.totalFareGH = 0;
    this.fareTypeGH = {
      flatFee: 0,
      vendorRatePerMile: 0,
      vendorLoadCharge: 0,
      vendorBaseRate: 0,
    };
    let tempGhRideStatus = null;
    this.flatFeetToolTipMsg = 'Please select one Flat Fee type:\n'
    + 'Trip Level: Flat rate to be added for all legs.\n Leg Level: Flat rate to be added for individual legs';
    let legCount;
    this.vendorDetail = {
      id: 0,
      vendorCode: '',
      name: '',
      fuseId: '',
      legUpdateType: null,
    };
    this.vendorList = [];
    $scope.ride = angular.copy(rideAndReceiptData.receiptDetails);
    if (rideAndReceiptData.receiptDetails.ride_additional_detail.status_change_reason && rideAndReceiptData.receiptDetails.ride_additional_detail.status_change_reason.system_code) {
      $scope.ride.system_code = rideAndReceiptData.receiptDetails.ride_additional_detail.status_change_reason.system_code;
    }
    $scope.ride.ride_end_date_time = (_.get($scope.ride, 'ride_additional_detail.actual_dropoff') && $scope.ride.ride_end_date_time) || null;
    if (_.get($scope.ride, 'receipt.update_reason') === reasonCodesConstant.ADJUDICATION) {
      $scope.ride.receipt.update_reason = null;
    }
    $scope.ride.receipt.update_note = null;
    this.isGhOrderRide = $scope.ride.isGhOrderRide;
    this.amountPattern = /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/;
    if ($scope.ride.organisation.market_segment.key === 'GHT') {
      $scope.rideStatusList.push({
        status: statuses.PROVIDER_NO_SHOW.status,
        displayText: statuses.PROVIDER_NO_SHOW.status,
      });
    }
    this.showAllProviders = false;
    this.visibleProviderChoices = 20;
    this.reasonType = relaylib.vendorPortal.reasonType;
    this.additionalReasons = [];
    let subReasons = [];
    const phoneNumber = $scope.ride.driver_phone;

    const calculateRateTotal = (event) => {
      if (!event) {
        this.tripFlatFee = +parseFloat(
          this.fareTypeGH.flatFee * legCount,
        ).toFixed(2);
      }
      if ($scope.ride.receipt.update_reason !== reasonCodesConstant.PAYMENT_ADJUSTMENT) {
        if ($scope.ride.receipt.update_reason === reasonCodesConstant.FLAT_RATE) {
          this.flatFeeUpdateTypeSelected();
          if (this.vendorDetail.legUpdateType === 'Leg Level') { return; }
        }
        this.totalFareGH = this.fareTypeGH.flatFee;
        $scope.ride.receipt.final_ride_cost = this.totalFareGH;
        return;
      }
      this.fareTypeGH.flatFee = 0;
      this.totalFareGH = Object.values(this.fareTypeGH).reduce((acc, curr) => {
        let sum = acc;
        sum += curr;
        return sum;
      }, 0);
      $scope.ride.receipt.final_ride_cost = this.totalFareGH;
    };

    this.fetchVendorListDetail = async () => {
      try {
        const url = `${API_BASE_URL}remote-vendor-list`;
        const params =  {
          sortBy: 'name.ASC',
        };
        if (!this.showAllProviders) {
          params.contractStatus = 'Contracted,Single Rate Agreement';
          if (rideAndReceiptData.healthPlan && rideAndReceiptData.planState) {
            params.healthPlan = rideAndReceiptData.healthPlan;
            params.planState = rideAndReceiptData.planState;
          }
        }
        const {
          data: {
            data: vendorList,
          } = {},
        } = await $http.get(url, {
          params,
        });
        if (
          this.vendorDetail
          && this.vendorDetail.vendorCode
          && vendorList.length
        ) {
          /**
            * on UI we are having lazy loading in list so if the vendor is not in
            * first list it is not displayed, to fix this issue moving selected vendor
            * on first postion of array
          */
          this.vendorList = [
            ...vendorList.filter(
              vendor => vendor.vendorCode === this.vendorDetail.vendorCode,
            ),
            ...vendorList.filter(
              vendor => vendor.vendorCode !== this.vendorDetail.vendorCode,
            ),
          ];
        } else {
          this.vendorList = vendorList;
        }
      } catch (err) {
        toaster.pop({
          type: 'error',
          title: (err.data && err.data.message) || err.message,
          showCloseButton: true,
          timeout: 10000,
        });

        throw err;
      } finally {
        $scope.$apply();
      }
    };
    $scope.openModal = function (rideAndReceiptDetails) {
      const modalInstance =   ngDialog.open({
        ribedBy: 'modal-body',
        templateUrl: 'ngDialogTemplate1',
        className: 'ngdialog-theme-default',
      });
      modalInstance.closePromise.then((res) => {
        if (typeof res.value === 'boolean') {
          const data = rideAndReceiptDetails;
          data.cancel_all = res.value;
          saveReceiptData(data);
        }
      });
    };
    this.ghReasonCodeInit = async () => {
      if (!this.isGhOrderRide) {
        return;
      }
      if (!tempGhRideStatus) { tempGhRideStatus = $scope.ride.ride_status; }
      $scope.rideStatusList = [
        {
          displayText: 'Select Status',
          status: 'Select Status',
        }, {
          displayText: 'Complete',
          status: statuses.RIDE_COMPLETED.status,
        }, {
          displayText: 'Member No Show',
          status: statuses.PATIENT_NO_SHOW.status,
        }, {
          displayText: 'Provider No Show',
          status: statuses.PROVIDER_NO_SHOW.status,
        },
      ];
      if (($scope.ride.receipt.update_reason === reasonCodesConstant.CASE_STATUS || !$scope.ride.receipt.update_reason) && $scope.traditionalRideCancellation) {
        $scope.rideStatusList.push({
          displayText: statuses.RIDE_CANCELED.status,
          status: statuses.RIDE_CANCELED.status,
        });
      }
      if (
        ![
          statuses.RIDE_COMPLETED.status,
          statuses.PATIENT_NO_SHOW.status,
          statuses.PROVIDER_NO_SHOW.status,
          statuses.RIDE_CANCELED.status,
        ].includes($scope.ride.ride_status)
      ) {
        $scope.ride.ride_status = 'Select Status';
      }
      if ($scope.ride.vendor_lists.length) {
        const [
          {
            show_all_providers: showAllProviders,
            vendor_list_details:
            [{
              id,
              vendor_code: vendorCode,
              fuse_id: fuseId,
              name,
              flat_fee: flatFee,
              vendor_base_rate: vendorBaseRate,
              vendor_load_charge: vendorLoadCharge,
              vendor_rate_per_mile: vendorRatePerMile,
              flat_fee_type: legUpdateType,
              reason_type: reasonType,
            }],
          }] = $scope.ride.vendor_lists;
        this.showAllProviders = showAllProviders;
        this.fareTypeGH = {
          flatFee: +parseFloat(flatFee).toFixed(2) || 0,
          vendorRatePerMile: +parseFloat(vendorRatePerMile).toFixed(2) || 0,
          vendorLoadCharge: +parseFloat(vendorLoadCharge).toFixed(2) || 0,
          vendorBaseRate: +parseFloat(vendorBaseRate).toFixed(2) || 0,
        };
        this.vendorDetail = {
          id,
          vendorCode,
          name,
          fuseId,
          legUpdateType,
          reasonType: (reasonType || {}).reason,
        };
        subReasons = (reasonType || {}).subReason || [];
        if (!$scope.ride.receipt.update_reason && legUpdateType) {
          $scope.ride.receipt.update_reason = reasonCodesConstant.FLAT_RATE;
        }
        this.reasonTypeSelected();
      } else {
        this.fareTypeGH = {
          flatFee: 0,
          vendorRatePerMile: 0,
          vendorLoadCharge: 0,
          vendorBaseRate: 0,
        };
        Object.assign(this.vendorDetail, {
          id: 0,
          vendorCode: '',
          name: '',
          fuseId: '',
          legUpdateType: null,
        });
      }
      ({ legCount } = $scope.ride);
      calculateRateTotal();
      if (this.vendorList.length) {
        return;
      }
      this.fetchVendorListDetail();
    };

    const init = async () => {
      await getSystemSettings();
      if (!$scope.ride.provider_id) {
        $scope.ride.provider_id = $scope.providerTypes[0].id;
      }
      if (!$scope.ride.ride_status) {
        $scope.ride.ride_status = $scope.rideStatusList[0].status;
      } else if ($scope.ride.is_variance) {
        $scope.ride.ride_status = `${statuses.VARIANCE_ISSUE.status} - Billable`;
        if ($scope.ride.variance_type === 2) {
          $scope.ride.ride_status = `${statuses.VARIANCE_ISSUE.status} - Waived`;
        }
      }

      if (!$scope.ride.appointment.appointment_status) {
        $scope.ride.appointment.appointment_status = $scope.apptStatusList[0].status;
      }

      if ($scope.ride && $scope.ride.patient) {
        let fullName = $scope.ride.patient.first_name;
        if ($scope.ride.patient.last_name) {
          fullName = `${fullName} ${$scope.ride.patient.last_name}`;
        }
        $scope.ride.patient.fullName = capitalizeFilter(fullName);
      }

      if ($scope.ride.receipt.duration) {
        const duration = $scope.ride.receipt.duration.split(':');

        $scope.ride.receipt.duration = {
          hours: parseInt(duration[0], 10),
          minutes: parseInt(duration[1], 10),
          seconds: parseInt(duration[2], 10),
        };
      } else {
        $scope.ride.receipt.duration = { hours: 0, minutes: 0, seconds: 0 };
      }
      if (this.isGhOrderRide) {
        this.ghReasonCodeInit();
      }
      $scope.reasonCodeSelected('init');
    };

    const createReceiptMap = () => {
      map = new google.maps.Map(document.getElementById('receiptMap'), {
        zoom: 2,
      });
      map.setCenter({
        lat: -34.397,
        lng: 150.644,
      });
    };

    const getAddressComponentsByAddress = (address) => {
      const geocoder = new google.maps.Geocoder();
      const defer = $q.defer();
      geocoder.geocode(
        {
          address,
        },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            defer.resolve(results);
          }
        },
      );
      return defer.promise;
    };

    const getAddressComponents = (place, setterPincodeProperty, ride) => {
      let pincode;
      place.address_components.forEach((obj) => {
        if (obj.types.indexOf('postal_code') !== -1) {
          pincode = obj.short_name;
        }
      });

      if (pincode) {
        Object.assign(ride, { [setterPincodeProperty]: pincode });
        return;
      }

      const latlng = {
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      };

      googleService.geocode(latlng).then((results) => {
        for (let i = 0; i < results.length; i += 1) {
          for (let j = 0; j < results[i].address_components.length; j += 1) {
            const obj = results[i].address_components[j];
            if (obj.types.indexOf('postal_code') !== -1) {
              pincode = obj.short_name;
              Object.assign(ride, { [setterPincodeProperty]: pincode });
              break;
            }
          }

          if (typeof pincode !== 'undefined') {
            break;
          }
        }
      });
    };

    const setBounds = () => {
      const bounds = new google.maps.LatLngBounds();
      const setPickupMarker = pickupData.marker && typeof pickupData.marker.setMap === 'function';
      const setDestinationMarker = destinationData.marker
        && typeof destinationData.marker.setMap === 'function';
      if (setPickupMarker && setDestinationMarker) {
        bounds.extend(pickupData.latLng);
        bounds.extend(destinationData.latLng);
        map.fitBounds(bounds);
        map.panToBounds(bounds);
        return;
      }
      if (setPickupMarker) {
        map.setCenter(pickupData.latLng);
        map.setZoom(16);
        return;
      }
      if (setDestinationMarker) {
        map.setCenter(destinationData.latLng);
        map.setZoom(16);
      }
    };

    const setMarker = (latitude, longitude, placeName, obj) => {
      Object.assign(obj, { latLng: new google.maps.LatLng(latitude, longitude) });
      if (obj.marker && typeof obj.marker.setMap === 'function') {
        obj.marker.setMap(null);
      }
      let contentString;
      let icon;
      if (obj.type === 'source') {
        contentString = `<div><h4>Pickup Location</h4><div>${placeName}</div></div>`;
        icon = googleService.sourceIcon;
      } else {
        contentString = `<div><h4>Destination Location</h4><div>${placeName}</div></div>`;
        icon = googleService.destIcon;
      }

      const infowindow = new google.maps.InfoWindow({
        content: contentString,
      });

      Object.assign(obj, {
        marker: new google.maps.Marker({
          position: obj.latLng,
          map,
          icon,
          animation: google.maps.Animation.DROP,
          draggable: true,
        }),
      });

      Object.assign(obj, {
        clickListener: obj.marker.addListener('click', () => {
          infowindow.open(map, obj.marker);
        }),
      });

      setBounds();
    };

    const setInitialMarkers = () => {
      if (
        $scope.ride.actual_source_lat_long
        && $scope.ride.actual_source_lat_long.length
      ) {
        setMarker(
          $scope.ride.actual_source_lat_long[0],
          $scope.ride.actual_source_lat_long[1],
          $scope.ride.actual_source,
          pickupData,
        );
      }
      if (
        $scope.ride.actual_dest_lat_long
        && $scope.ride.actual_dest_lat_long.length
      ) {
        setMarker(
          $scope.ride.actual_dest_lat_long[0],
          $scope.ride.actual_dest_lat_long[1],
          $scope.ride.actual_dest,
          destinationData,
        );
      }
    };

    const setAddressDetails = (place, type, showOnMap) => {
      if (type === 'source') {
        if (showOnMap) {
          $scope.ride.actual_source_lat_long = [
            place.geometry.location.lat(),
            place.geometry.location.lng(),
          ];
          setMarker(
            $scope.ride.actual_source_lat_long[0],
            $scope.ride.actual_source_lat_long[1],
            place.name,
            pickupData,
          );
        } else {
          $scope.ride.short_source = place.name;
          $scope.ride.source_lat_long = [
            place.geometry.location.lat(),
            place.geometry.location.lng(),
          ];
        }
      } else if (showOnMap) {
        $scope.ride.actual_dest_lat_long = [
          place.geometry.location.lat(),
          place.geometry.location.lng(),
        ];
        setMarker(
          $scope.ride.actual_dest_lat_long[0],
          $scope.ride.actual_dest_lat_long[1],
          place.name,
          destinationData,
        );
      } else {
        $scope.ride.short_dest = place.name;
        $scope.ride.dest_lat_long = [
          place.geometry.location.lat(),
          place.geometry.location.lng(),
        ];
      }
    };

    const getSetterPincodeProperty = (type, actualAddress) => {
      if (type === 'source') {
        if (actualAddress) {
          return 'actualSourceAddressPincode';
        }
        return 'sourceAddressPincode';
      }
      if (actualAddress) {
        return 'actualDestAddressPincode';
      }
      return 'destAddressPincode';
    };

    $scope.addressChanged = function addressChanged(error, type, showOnMap) {
      let selectedPlace = this.getPlace();
      const setterPincodeProperty = getSetterPincodeProperty(type, showOnMap);
      if (
        typeof selectedPlace.address_components === 'undefined'
        && typeof selectedPlace.geometry === 'undefined'
      ) {
        const promise = getAddressComponentsByAddress(selectedPlace.name);
        promise.then((result) => {
          [selectedPlace] = result;
          getAddressComponents(
            selectedPlace,
            setterPincodeProperty,
            $scope.ride,
          );
          setAddressDetails(selectedPlace, type, showOnMap);
        });
      } else {
        getAddressComponents(selectedPlace, setterPincodeProperty, $scope.ride);
        setAddressDetails(selectedPlace, type, showOnMap);
      }
    };

    $scope.closePopup = () => {
      $uibModalInstance.dismiss(false);
    };

    const calculateTotal = () => {
      let total = 0;
      for (
        let fareIndex = 0;
        fareIndex < $scope.ride.receipt.fare_breakdown.length;
        fareIndex += 1
      ) {
        if (
          $scope.ride.receipt.fare_breakdown[fareIndex].amount === '0'
          || parseFloat($scope.ride.receipt.fare_breakdown[fareIndex].amount)
        ) {
          total += parseFloat(
            $scope.ride.receipt.fare_breakdown[fareIndex].amount,
          );
        }
      }
      if (
        $scope.ride.receipt.occm_fee === '0'
        || parseFloat($scope.ride.receipt.occm_fee)
      ) {
        total += parseFloat($scope.ride.receipt.occm_fee);
      }
      $scope.ride.receipt.final_ride_cost = total;
    };

    $scope.checkDec = (fare) => {
      if (!this.isGhOrderRide) {
        if (fare.type === 'occm_fee') {
          $scope.ride.receipt.occm_fee = fare.amount;
        }
        calculateTotal();
      } else {
        if (this.disableFields.ghRate) {
          if ($scope.ride.receipt.update_reason === reasonCodesConstant.FLAT_RATE && this.vendorDetail.legUpdateType === 'Leg Level') {
            this.fareTypeGH.flatFee = +parseFloat((this.tripFlatFee));
          } else {
            this.fareTypeGH.flatFee = +parseFloat((this.tripFlatFee / legCount)).toFixed(2);
          }
        }
        calculateRateTotal('input');
      }
    };

    $scope.removeFareType = (fareIndex) => {
      if (!this.disableFields.fareBreakdown) {
        $scope.ride.receipt.fare_breakdown.splice(fareIndex, 1);
        calculateTotal();
      }
    };

    $scope.addFareType = () => {
      $scope.ride.receipt.fare_breakdown.push({});
    };

    const setDates = () => {
      const TimezoneMoment = moment.tz.setDefault($scope.ride.appt_timezone);
      if ($scope.ride.id) {
        if ($scope.ride.book_cab_time) {
          const bookCabDateTimeZ = new TimezoneMoment(
            $scope.ride.book_cab_time * 1000,
          );
          $scope.ride.book_cab_time = new Date(
            1970,
            0,
            1,
            bookCabDateTimeZ.hours(),
            bookCabDateTimeZ.minutes(),
          );
        }
        if ($scope.ride.ride_start_date_time) {
          const rideStartDateTimeZ = new TimezoneMoment(
            $scope.ride.ride_start_date_time * 1000,
          );
          $scope.ride.ride_start_date_time = new Date(
            1970,
            0,
            1,
            rideStartDateTimeZ.hours(),
            rideStartDateTimeZ.minutes(),
          );
        }
        if ($scope.ride.ride_end_date_time) {
          const rideEndDateTimeZ = new TimezoneMoment(
            $scope.ride.ride_end_date_time * 1000,
          );
          $scope.ride.ride_end_date_time = new Date(
            1970,
            0,
            1,
            rideEndDateTimeZ.hours(),
            rideEndDateTimeZ.minutes(),
          );
        }
      } else {
        const timezoneMomentObj = new TimezoneMoment();
        $scope.ride.book_cab_time = new Date(
          1970,
          0,
          1,
          timezoneMomentObj.hours(),
          timezoneMomentObj.minutes(),
        );
        $scope.ride.ride_start_date_time = new Date(
          1970,
          0,
          1,
          timezoneMomentObj.hours(),
          timezoneMomentObj.minutes(),
        );
        $scope.ride.ride_end_date_time = new Date(
          1970,
          0,
          1,
          timezoneMomentObj.hours(),
          timezoneMomentObj.minutes(),
        );
      }
    };

    const formatDateForDisplay = (timeStr) => {
      let [hours = '00', minutes = '00', seconds = '00'] = timeStr.split(':');
      if (hours.length < 2) {
        hours = `0${hours}`;
      }
      if (minutes.length < 2) {
        minutes = `0${minutes}`;
      }
      if (seconds.length < 2) {
        seconds = `0${seconds}`;
      }
      return `${hours}:${minutes}:${seconds}`;
    };

    const formatDatesForBackend = (rideAndReceiptDetails) => {
      const TimezoneMoment = moment.tz.setDefault($scope.ride.appt_timezone);
      const apptDate = $scope.ride.datetime_in_tz.split(' ')[0];
      if (rideAndReceiptDetails.book_cab_time) {
        const hours = rideAndReceiptDetails.book_cab_time.getHours();
        const minutes = rideAndReceiptDetails.book_cab_time.getMinutes();
        const bookCabDateTimeZ = new TimezoneMoment(
          `${apptDate} ${formatDateForDisplay(`${hours}:${minutes}`)}`,
        );
        Object.assign(rideAndReceiptDetails, { book_cab_time: bookCabDateTimeZ.unix() });
      }
      if (rideAndReceiptDetails.ride_start_date_time) {
        const hours = rideAndReceiptDetails.ride_start_date_time.getHours();
        const minutes = rideAndReceiptDetails.ride_start_date_time.getMinutes();
        const rideStartDateTimeZ = new TimezoneMoment(
          `${apptDate} ${formatDateForDisplay(`${hours}:${minutes}`)}`,
        );
        Object.assign(rideAndReceiptDetails, { ride_start_date_time: rideStartDateTimeZ.unix() });
      }
      if (rideAndReceiptDetails.ride_end_date_time) {
        const hours = rideAndReceiptDetails.ride_end_date_time.getHours();
        const minutes = rideAndReceiptDetails.ride_end_date_time.getMinutes();
        const rideEndDateTimeZ = new TimezoneMoment(
          `${apptDate} ${formatDateForDisplay(`${hours}:${minutes}`)}`,
        );
        Object.assign(rideAndReceiptDetails, { ride_end_date_time: rideEndDateTimeZ.unix() });
      }
      if ($scope.ride.id) {
        Object.assign(rideAndReceiptDetails, { datetime_in_tz: undefined, appt_timezone: undefined });
      }
    };

    const addPincodeToAddress = (
      rideAndReceiptDetails,
      addressProperty,
      pincodeProperty,
    ) => {
      let pincode = '';
      if (rideAndReceiptDetails[pincodeProperty]) {
        pincode = `, ${rideAndReceiptDetails[pincodeProperty]}`;
      }
      Object.assign(rideAndReceiptDetails, {
        [addressProperty]: rideAndReceiptDetails[addressProperty] + pincode,
      });
    };

    const formatAddressesforBackend = (rideAndReceiptDetails) => {
      if (!rideAndReceiptDetails.actual_source) {
        Object.assign(rideAndReceiptDetails, { actual_source_lat_long: null });
      } else {
        addPincodeToAddress(
          rideAndReceiptDetails,
          'actual_source',
          'actualSourceAddressPincode',
        );
      }
      if (!rideAndReceiptDetails.actual_dest) {
        Object.assign(rideAndReceiptDetails, { actual_dest_lat_long: null });
      } else {
        addPincodeToAddress(
          rideAndReceiptDetails,
          'actual_dest',
          'actualDestAddressPincode',
        );
      }
      if (!rideAndReceiptDetails.source_address) {
        throw new Error('Please enter Requested Pickup');
      } else {
        addPincodeToAddress(
          rideAndReceiptDetails,
          'source_address',
          'sourceAddressPincode',
        );
      }
      if (!rideAndReceiptDetails.dest_address) {
        Object.assign(rideAndReceiptDetails, { dest_lat_long: null, short_dest: null });
      } else {
        addPincodeToAddress(
          rideAndReceiptDetails,
          'dest_address',
          'destAddressPincode',
        );
      }
    };

    const formatDuration = ({ hours, minutes, seconds } = {}) => `${hours || '0'}:${minutes || '00'}:${seconds || '00'}`;

    const formatDataForBackend = (rideAndReceiptDetails) => {
      if (rideAndReceiptDetails.provider_id === 0) {
        Object.assign(rideAndReceiptDetails, { provider_id: undefined });
      }
      if (rideAndReceiptDetails.ride_status === 'Select Status') {
        Object.assign(rideAndReceiptDetails, { ride_status: undefined });
      }
      if (
        rideAndReceiptDetails.appointment.appointment_status === 'Select Status'
      ) {
        Object.assign(rideAndReceiptDetails.appointment, { appointment_status: undefined });
      }
      for (
        let fareIndex = 0;
        fareIndex < rideAndReceiptDetails.receipt.fare_breakdown.length;
        fareIndex += 1
      ) {
        if (
          rideAndReceiptDetails.receipt.fare_breakdown[fareIndex].amount
          === '0'
          || parseFloat(
            rideAndReceiptDetails.receipt.fare_breakdown[fareIndex].amount,
          )
        ) {
          Object.assign(rideAndReceiptDetails.receipt.fare_breakdown[
            fareIndex
          ], {
            amount: parseFloat(
              rideAndReceiptDetails.receipt.fare_breakdown[fareIndex].amount,
            ),
          });
        }
      }
      if (
        rideAndReceiptDetails.receipt.occm_fee === '0'
        || parseFloat(rideAndReceiptDetails.receipt.occm_fee)
      ) {
        Object.assign(rideAndReceiptDetails.receipt, {
          occm_fee: parseFloat(
            rideAndReceiptDetails.receipt.occm_fee,
          ),
        });
      }
      formatDatesForBackend(rideAndReceiptDetails);
      formatAddressesforBackend(rideAndReceiptDetails);
      Object.assign(rideAndReceiptDetails, {
        driver_phone: $('#driverPhone').intlTelInput(
          'getNumber',
          window.intlTelInputUtils.numberFormat.E164,
        ),
      });
      Object.assign(rideAndReceiptDetails.receipt, {
        currency_code: undefined,
      });
    };

    const formatGHDataForBackend = (rideAndReceiptDetails) => {
      const { reasonType: reason } = this.vendorDetail;
      if (subReasons.length) {
        Object.assign(this.vendorDetail, {
          reasonType: {
            reason,
            subReason: subReasons,
          },
        });
      } else {
        Object.assign(this.vendorDetail, {
          reasonType: {
            reason,
          },
        });
      }
      const vendor = Object.assign({}, this.fareTypeGH, this.vendorDetail, { showAllProviders: this.showAllProviders });
      formatDatesForBackend(rideAndReceiptDetails);
      Object.assign(rideAndReceiptDetails, { vendor });
      Object.assign(rideAndReceiptDetails, { ride_additional_detail: undefined });
    };
    $scope.saveReceipt = async () => {
      if (!$scope.editReceiptForm.$valid) {
        return;
      }
      try {
        loadingScreenFactory.showLoadingScreen();
        const rideAndReceiptDetails = angular.copy($scope.ride);
        if (rideAndReceiptDetails.receipt.duration) {
          rideAndReceiptDetails.receipt.duration = formatDuration(rideAndReceiptDetails.receipt.duration);
        }
        if (this.isGhOrderRide) {
          if ($scope.ride.ride_status === 'Select Status') {
            if ($scope.ride.receipt.update_reason === reasonCodesConstant.CASE_STATUS) {
              toaster.pop({
                type: 'error',
                title: 'Ride Status is mandatory for reason code case status',
                showCloseButton: true,
                timeout: 10000,
              });
              loadingScreenFactory.hideLoadingScreen();
              return;
            }
            $scope.ride.ride_status = tempGhRideStatus;
            Object.assign(rideAndReceiptDetails, { ride_status: tempGhRideStatus });
          }
          if (!this.vendorDetail.vendorCode && $scope.ride.receipt.update_reason !== reasonCodesConstant.CASE_STATUS && $scope.ride.receipt.update_reason !== reasonCodesConstant.ADJUDICATION) {
            toaster.pop({
              type: 'error',
              title: 'No vendor is associated',
              showCloseButton: true,
              timeout: 10000,
            });
            loadingScreenFactory.hideLoadingScreen();
            return;
          }
          formatGHDataForBackend(rideAndReceiptDetails);
        } else {
          formatDataForBackend(rideAndReceiptDetails);
        }
        if ($scope.ride.receipt.update_reason === reasonCodesConstant.CASE_STATUS && $scope.traditionalRideCancellation && $scope.ride.ride_status === statuses.RIDE_CANCELED.status) {
          $scope.openModal(rideAndReceiptDetails);
        } else {
          await saveReceiptData(rideAndReceiptDetails);
        }
        loadingScreenFactory.hideLoadingScreen();
      } finally {
        loadingScreenFactory.hideLoadingScreen();
      }
    };

    if ($scope.ride.driver_phone) {
      $scope.ride.driver_phone = phoneNumber.replace(/[- )(]/g, '').slice(-10);
    }

    const viewContentLoaded = $scope.$watch('$viewContentLoaded', () => {
      init();
      createReceiptMap();
      setTimeout(() => {
        setInitialMarkers();
      }, 1000);
      setDates();
      calculateTotal();
      viewContentLoaded();
    });

    this.flatFeeUpdateTypeSelected = (event) => {
      if ($scope.ride.receipt.update_reason !== reasonCodesConstant.FLAT_RATE || !this.vendorDetail.legUpdateType) {
        return;
      }
      this.tooltipIsOpen = false;
      if (this.vendorDetail.legUpdateType === 'Leg Level') {
        this.flatFeeLabel = 'Leg Flat Fee';
        this.flatFeeEditMessage = 'Please enter the Flat rate amount to be added for individual leg';
        this.tripFlatFee = +parseFloat(this.fareTypeGH.flatFee).toFixed(2);
        this.totalFareGH = this.tripFlatFee;
        this.fareTypeGH.flatFee = this.tripFlatFee;
        $scope.ride.receipt.final_ride_cost = this.totalFareGH;
      } else {
        this.flatFeeLabel = 'Trip Flat Fee';
        this.flatFeeEditMessage = 'Please enter the amount for the entire Order and the amount will be applied equally to all the legs.';

        if (event) { this.tripFlatFee = +parseFloat(this.fareTypeGH.flatFee * legCount).toFixed(2); }
      }
    };

    $scope.reasonCodeSelected = (event) => {
      switch ($scope.ride.receipt.update_reason) {
        case reasonCodesConstant.SRA:
        case reasonCodesConstant.CONTINUITY_OF_CARE:
          this.flatFeeLabel = 'Trip Flat Fee';
          this.flatFeeEditMessage = 'Please enter the amount for the entire Order and the amount will be applied equally to all the legs.';
          this.disableFields.ghRate = true;
          Object.assign(this.disableFields, {
            vendor: false,
            fareBreakdown: false,
            rideStatus: false,
            actualPickUpAddress: false,
            actualDropOffAddress: false,
            actualPickUpTime: false,
            actualDropOffTime: false,
            reasonType: false,
            blockedReason: true,
            invoicingBlocked: true,
          });
          Object.assign(this.requiredFields, {
            rideStatus: true,
            reasonType: true,
            updateType: false,
            tripFlatFee: true,
            vendor: !this.vendorDetail.vendorCode,
            rateFields: false,
            actualSourceAddress: false,
            actualPickupTime: false,
            actualDropoffAddress: false,
            actualDropoffTime: false,
          });
          break;
        case reasonCodesConstant.PAYMENT_ADJUSTMENT:
          this.flatFeeLabel = 'Trip Flat Fee';
          this.flatFeeEditMessage = 'Please enter the amount for the entire Order and the amount will be applied equally to all the legs.';
          Object.assign(this.disableFields, {
            vendor: true,
            fareBreakdown: false,
            ghRate: false,
            rideStatus: false,
            actualPickUpAddress: false,
            actualDropOffAddress: false,
            actualPickUpTime: false,
            actualDropOffTime: false,
            reasonType: false,
            blockedReason: true,
            invoicingBlocked: true,
          });
          Object.assign(this.requiredFields, {
            rideStatus: true,
            reasonType: false,
            updateType: false,
            tripFlatFee: false,
            vendor: false,
            rateFields: true,
            actualSourceAddress: false,
            actualPickupTime: false,
            actualDropoffAddress: false,
            actualDropoffTime: false,
          });

          break;
        case reasonCodesConstant.FLAT_RATE:
          Object.assign(this.disableFields, {
            provider: true,
            fareBreakdown: false,
            commonFields: true,
            vendor: true,
            ghRate: true,
            rideStatus: true,
            actualPickUpAddress: true,
            actualDropOffAddress: true,
            actualPickUpTime: true,
            actualDropOffTime: true,
            reasonType: false,
            blockedReason: true,
            invoicingBlocked: true,
          });
          Object.assign(this.requiredFields, {
            rideStatus: false,
            reasonType: false,
            updateType: true,
            tripFlatFee: true,
            vendor: false,
            rateFields: false,
            actualSourceAddress: false,
            actualPickupTime: false,
            actualDropoffAddress: false,
            actualDropoffTime: false,
          });
          /**
         * this function is invoked for dropdown action and while initializing
         * event is passed only when it is invoked via init()
         * logic should not execute when it is invoked via init() and no leg update type
         * is available ie choosing flat rate for first time
         */
          if (!event || !this.vendorDetail.legUpdateType) {
            this.tooltipIsOpen = true;
          }
          break;
        case reasonCodesConstant.UPDATE_ACTUALS:
          Object.assign(this.disableFields, {
            provider: true,
            fareBreakdown: true,
            vendor: true,
            ghRate: false,
            rideStatus: true,
            actualPickUpAddress: false,
            actualDropOffAddress: false,
            actualPickUpTime: false,
            actualDropOffTime: false,
            reasonType: false,
            blockedReason: true,
            invoicingBlocked: true,
          });
          Object.assign(this.requiredFields, {
            rideStatus: false,
            reasonType: false,
            updateType: false,
            actualSourceAddress: true,
            actualPickupTime: true,
            actualDropoffAddress: true,
            actualDropoffTime: true,
          });
          break;
        case reasonCodesConstant.CASE_STATUS:
          Object.assign(this.disableFields, {
            provider: true,
            fareBreakdown: true,
            commonFields: true,
            vendor: true,
            ghRate: false,
            rideStatus: false,
            actualPickUpAddress: true,
            actualDropOffAddress: true,
            actualPickUpTime: true,
            actualDropOffTime: true,
            reasonType: true,
            blockedReason: true,
            invoicingBlocked: true,
          });
          Object.assign(this.requiredFields, {
            rideStatus: true,
            reasonType: false,
            updateType: false,
            tripFlatFee: false,
            vendor: false,
            rateFields: false,
            blockedReason: false,
            invoicingBlocked: false,
            actualSourceAddress: false,
            actualPickupTime: false,
            actualDropoffAddress: false,
            actualDropoffTime: false,
          });
          if ($scope.ride.ride_status === 'Select Status') {
            $scope.editReceiptForm.rideStatus.$setValidity('valid', false);
          }
          break;
        case reasonCodesConstant.VARIANCE:
        case reasonCodesConstant.NON_RIDE:
        case reasonCodesConstant.CUSTOMER_COMPLAINT:
          this.disableFields.fareBreakdown = false;
          break;
        case reasonCodesConstant.ADJUDICATION:
          Object.assign(this.disableFields, {
            provider: true,
            fareBreakdown: true,
            vendor: true,
            ghRate: true,
            rideStatus: true,
            actualPickUpAddress: true,
            actualDropOffAddress: true,
            actualPickUpTime: true,
            actualDropOffTime: true,
            reasonType: true,
            blockedReason: false,
            invoicingBlocked: false,
          });
          Object.assign(this.requiredFields, {
            rideStatus: false,
            reasonType: false,
            updateType: false,
            blockedReason: false,
            invoicingBlocked: false,
            vendor: false,
            tripFlatFee: false,
            rateFields: false,
            actualSourceAddress: false,
            actualPickupTime: false,
            actualDropoffAddress: false,
            actualDropoffTime: false,
          });
          break;
        default:
          this.disableFields.fareBreakdown = true;
      }
      if (this.requiredFields.rideStatus && $scope.ride.ride_status === $scope.rideStatusList[0].status && this.isAnyFieldInvalid) {
        $scope.editReceiptForm.rideStatus.$setValidity('valid', false);
      } else {
        $scope.editReceiptForm.rideStatus.$setValidity('valid', true);
      }
      if (!event && !this.requiredFields.rideStatus) {
        const rideStatusToBeSet = ((rideAndReceiptData || {}).receiptDetails || {}).ride_status;
        $scope.ride.ride_status = rideStatusToBeSet || $scope.rideStatusList[0].status;
      }
    };

    this.vendorSelected = async () => {
      if (
        [
          reasonCodesConstant.FLAT_RATE,
          reasonCodesConstant.PAYMENT_ADJUSTMENT,
          reasonCodesConstant.UPDATE_ACTUALS,
          reasonCodesConstant.CASE_STATUS,
        ].includes($scope.ride.receipt.update_reason)
      ) {
        toaster.pop({
          type: 'error',
          title: 'Vendor can not be changed',
          showCloseButton: true,
          timeout: 10000,
        });
        return;
      }
      const { ride_additional_detail: { mode } = {} } = $scope.ride;
      const url = `${API_BASE_URL}remote-vendor-details`;
      const params = {
        vendorCodes: this.vendorDetail.vendorCode,
        mode,
        segment: 'GHT',
      };
      if (rideAndReceiptData.healthPlan && rideAndReceiptData.planState) {
        params.healthPlan = rideAndReceiptData.healthPlan;
        params.planState = rideAndReceiptData.planState;
      }
      try {
        const {
          data: {
            data: [data],
          } = {},
        } = await $http.get(url, {
          params,
        });
        this.fareTypeGH = {
          flatFee: +parseFloat(data.vendorFlatFee).toFixed(2) || 0,
          vendorRatePerMile: +parseFloat(data.vendorRatePerMile).toFixed(2) || 0,
          vendorLoadCharge: +parseFloat(data.vendorLoadCharge).toFixed(2) || 0,
          vendorBaseRate: +parseFloat(data.vendorBaseRate).toFixed(2) || 0,
        };
        Object.assign(this.vendorDetail, data);
        Object.assign(this.vendorDetail, {
          vendorNoShowRate: parseFloat(data.vendorNoShowRate) || 0,
        });
        delete this.vendorDetail.vendorFlatFee;
        delete this.vendorDetail.vendorRatePerMile;
        delete this.vendorDetail.vendorLoadCharge;
        delete this.vendorDetail.vendorBaseRate;
        this.vendorDetail.id = null;
        calculateRateTotal();
      } catch (err) {
        toaster.pop({
          type: 'error',
          title: (err.data && err.data.message) || err.message,
          showCloseButton: true,
          timeout: 10000,
        });

        throw err;
      } finally {
        $scope.$apply();
      }
    };

    this.reasonTypeSelected = () => {
      this.additionalReasons = (this.reasonType.find(reason => reason.value === this.vendorDetail.reasonType) || {}).subReason || [];
      if (this.additionalReasons.length) {
        if (subReasons.length) {
          this.additionalReasons = this.additionalReasons.map(reason => ({ ...reason, checked: subReasons.includes(reason.value) }));
        } else {
          this.additionalReasons = this.additionalReasons.map(reason => ({ ...reason, checked: false }));
        }
      }
    };
    this.showConfirmationBox = () => {
      subReasons = this.additionalReasons.filter(additionalReason => additionalReason.checked).map(additionalReason => additionalReason.value);
      this.showConfirmation = true;
      setTimeout(() => {
        $('#confirmation-box')[0].scrollIntoView();
      });
    };

    this.isAnyFieldInvalid = false;
    this.saveButtonClicked = () => {
      const invalidFields = [];
      if (!$scope.ride.ride_end_date_time) {
        delete $scope.ride.ride_end_date_time;
      }
      Object.keys(this.requiredFields).forEach((field) => {
        if (this.requiredFields[field] === true) {
          if (field === 'rideStatus') {
            if ($scope.ride.ride_status === $scope.rideStatusList[0].status) {
              invalidFields.push(field);
              $scope.editReceiptForm.rideStatus.$setValidity('valid', false);
            } else {
              $scope.editReceiptForm.rideStatus.$setValidity('valid', true);
            }
          } else if (field === 'rateFields') {
            let fieldsToBeChecked;
            if (this.isGhOrderRide) {
              fieldsToBeChecked = Object.keys(this.fareTypeGH).map((fareType, index) => `gh${fareType}value${index}`);
            } else {
              fieldsToBeChecked = (Object.keys($scope.ride.receipt.fare_breakdown || [])).map((fareType, index) => `fareValue${index}`);
            }
            fieldsToBeChecked.forEach((rateFieldToBeChecked) => {
              if ($scope.editReceiptForm[rateFieldToBeChecked] && $scope.editReceiptForm[rateFieldToBeChecked].$invalid) {
                invalidFields.push(rateFieldToBeChecked);
              }
            });
          } else if ($scope.editReceiptForm[field] && $scope.editReceiptForm[field].$invalid) {
            invalidFields.push(field);
          }
        }
      });
      if (invalidFields.length) {
        this.isAnyFieldInvalid = true;
        return;
      }
      this.isAnyFieldInvalid = false;
      if (!$scope.editReceiptForm.$valid) {
        return;
      }
      this.reasonTypeSelected();
      this.showConfirmationBox();
    };

    this.rideStatusSelected = () => {
      if ($scope.ride.receipt.update_reason === reasonCodesConstant.CASE_STATUS) {
        if ([
          statuses.RIDE_COMPLETED.status,
          statuses.PATIENT_NO_SHOW.status,
          statuses.PROVIDER_NO_SHOW.status,
        ].includes($scope.ride.ride_status)) {
          $scope.editReceiptForm.rideStatus.$setValidity('valid', true);
        }
      } else {
        $scope.editReceiptForm.rideStatus.$setValidity('valid', true);
      }
      $scope.statusReasonInit();
    };

    this.loadMoreChoices = () => {
      this.visibleProviderChoices += 20;
    };

    $scope.statusReasonList = [];
    $scope.cancelStatusReasonList = [];
    $scope.statusReasonInit = () => {
      const selectedAction = $scope.ride.ride_status;
      if ($scope.ride.ride_status.match(/No Show$/gmi) !== null) {
        const realActionValue = NO_SHOW_MAPPING.getStatusMapValues[selectedAction];
        $scope.statusReasonList = [];
        $scope.ride.no_show_code_id = null;
        statusReasons.forEach((statusReason) => { if (statusReason.status === realActionValue) { $scope.statusReasonList.push(statusReason); } });
        if (previousStatusReason && previousStatusReason.system_code) {
          $scope.statusReasonList.forEach((statusReason) => { if (statusReason.system_code === previousStatusReason.system_code) { $scope.ride.no_show_code_id = statusReason.id; }  });
        }
      } else {
        $scope.statusReasonList = [];
        $scope.ride.no_show_code_id = null;
      }
    };
    $scope.statusReasonDisable = () => ($scope.ride.ride_status.match(/No Show$/gmi) === null);
  },
]);
