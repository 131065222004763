const { alert } = window;

function accessLogCtrl(
  $scope,
  API_BASE_URL,
  $http,
  loadingScreenFactory,
  $uibModal,
  $rootScope,
  $state,
  toaster,
  Pubnub,
  storageManager,
) {
  let storageKey;
  if ($state.current.url.includes('/accessLog')) {
    $scope.logType = 'admin';
    storageKey = 'accessLogFilter';
  } else if ($state.current.url.includes('/accountLog')) {
    $scope.logType = 'account';
    storageKey = 'accountLogFilter';
  }
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const { storage } = storageManager;

  function initSearchForm() {
    $scope.accessLogQuery = {
      username: '',
      level: 'Select Level',
      dateRange: { startDate: null, endDate: null },
      phone: '',
    };
  }

  $scope.dateRangeExport = { startDate: new Date().setHours(0, 0, 0), endDate: new Date().setHours(23, 59, 59) };
  $scope.button_disabled = false;
  $scope.export_button = 'Export';

  function getLatestProcessedExportRecords() {
    const getLatestProcessedExportRecordsCall = $http.get(`${API_BASE_URL}export/getLatestProcessedExportRecords/Access Log ${$scope.logType}`);

    getLatestProcessedExportRecordsCall.then((result) => {
      if (result.data.success) {
        $scope.exportList = result.data.data;
      }
    }, (err) => {
      toaster.pop({
        type: 'error',
        title: err,
        showCloseButton: true,
        timeout: 10000,
      });
    });
  }

  $scope.exportInDateRange = () => {
    if ($scope.dateRangeExport) {
      const firstDate = new Date($scope.dateRangeExport.startDate).getTime();
      const secondDate = new Date($scope.dateRangeExport.endDate).getTime();
      const endDateArr = $scope.moment(secondDate).format('MM-DD-YYYY HH:mm:SS');
      const endDate = `${endDateArr.split(' ')[0]} 23:59:59`;
      const startDateArr = moment(firstDate).format('MM-DD-YYYY HH:mm:SS');
      const startDate = `${startDateArr.split(' ')[0]} 00:00:00`;

      const url = `${API_BASE_URL}saveExportFilters`;
      $scope.button_disabled = true;
      $scope.export_button = 'Processing';
      const exportInDateRangeCall = $http({
        url,
        method: 'POST',
        data: {
          start_date: startDate, end_date: endDate, page: `Access Log ${$scope.logType}`, type: $scope.logType,
        },
        headers: {
          'Content-type': 'application/json',
        },
      });
      exportInDateRangeCall.then((result) => {
        $scope.button_disabled = false;
        $scope.export_button = 'Export';
        if (result.success === false) {
          alert('failed');
        } else {
          toaster.pop({
            type: 'info',
            title: 'Please wait for your file.',
            showCloseButton: true,
            timeout: 10000,
          });
          getLatestProcessedExportRecords();
        }
      }, (err) => {
        $scope.button_disabled = false;
        $scope.export_button = 'Export';
        toaster.pop({
          type: 'error',
          title: err.data.message,
          showCloseButton: true,
          timeout: 10000,
        });
      });
    } else {
      toaster.pop({
        type: 'error',
        title: 'Choose valid date range',
        showCloseButton: true,
        timeout: 10000,
      });
    }
  };
  $scope.moment = moment;
  function adjustPagination() {
    $scope.pagination.lastItem = ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage + $scope.pagination.itemsPerPage;
    if ($scope.pagination.lastItem > $scope.pagination.totalItems) {
      $scope.pagination.lastItem = $scope.pagination.totalItems;
    }
  }

  // for getting accessLogs logs from api
  function getAccessLogs(offset, limit) {
    const params = {
      limit,
      offset,
      username: $scope.accessLogQuery.username || null,
      phone: $scope.accessLogQuery.phone ? $scope.accessLogQuery.phone.replace(/[()-\s]/g, '') : null,
      full_name: $scope.accessLogQuery.full_name || null,
      level: $scope.accessLogQuery.level !== 'Select Level' ? $scope.accessLogQuery.level : null,
    };
    if ($scope.accessLogQuery.dateRange.startDate && $scope.accessLogQuery.dateRange.endDate) {
      params.startDate = $scope.accessLogQuery.dateRange.startDate.unix();
      params.endDate = $scope.accessLogQuery.dateRange.endDate.unix();
    }
    const request = $http.get(`${API_BASE_URL}logs/getLogs/${$scope.logType}`, { params });
    loadingScreenFactory.showLoadingScreen();
    request.then(({ data }) => {
      loadingScreenFactory.hideLoadingScreen();
      if (data.success) {
        $scope.access_logs = data.logDetails;
        $scope.pagination.totalItems = data.totalCount;
        adjustPagination();
      }
    }, () => {
      loadingScreenFactory.hideLoadingScreen();
    });
  }

  $scope.sortBy = 'createdAt';
  $scope.sortOrder = 1;
  $scope.sort = (sortBy) => {
    if ($scope.sortBy === sortBy) {
      $scope.sortOrder = !$scope.sortOrder;
    }
    $scope.sortBy = sortBy;
    getAccessLogs($scope.pagination.currentPage - 1, 10);
  };

  getLatestProcessedExportRecords();

  function listenForPubnubMessages() {
    $rootScope.$on(Pubnub.getMessageEventNameFor(userDetails.user_id), (event, payload) => {
      if (payload.message.type === 'fileReady'
          && (payload.message.page === 'Access Log admin' && $state.current.name === 'main.dashboard.logs.accessLog')
      ) {
        getLatestProcessedExportRecords();
      }
    });
  }
  listenForPubnubMessages();

  function initPagination() {
    $scope.pagination = {
      totalItems: 0,
      currentPage: 1,
      lastItem: 0,
      itemsPerPage: 10,
    };
  }

  $scope.rideStatus = ['Select Level', 'info', 'error'];

  function writeSearchFilter() {
    const prevSearchValues = storage.getData(storageKey);
    if (!prevSearchValues) {
      return;
    }
    $scope.accessLogQuery.username = prevSearchValues.username;
    $scope.accessLogQuery.full_name = prevSearchValues.full_name;
    $scope.accessLogQuery.phone = prevSearchValues.phone;
    $scope.accessLogQuery.level = prevSearchValues.level;
    $scope.accessLogQuery.dateRange = {
      startDate: prevSearchValues.startDate ? moment(prevSearchValues.startDate) : null,
      endDate: prevSearchValues.endDate ? moment(prevSearchValues.endDate) : null,
    };
  }


  function init() {
    initPagination();
    initSearchForm();
    writeSearchFilter();
    getAccessLogs($scope.pagination.currentPage - 1, 10);
  }
  init();

  $scope.pageChanged = () => {
    getAccessLogs($scope.pagination.currentPage - 1, 10);
  };

  $scope.showUserData = ({ _source: source }) => {
    const { meta: sourceMeta } = source;
    sourceMeta.user_agent = source.user_agent;
    const modalInstance = $uibModal.open({
      animation: true,
      template: require('../../sub-modules/logs/log-details.html'),
      size: 'lg',
      backdrop: 'static',
      resolve: {
        meta() {
          return angular.copy(sourceMeta);
        },
      },
      controllerAs: 'logDetailsCtrl',
      controller: [
        'meta',
        '$uibModalInstance',
        function logDetailsCtrl(meta, $uibModalInstance) {
          Object.assign(this, {
            meta,
            closePopup() {
              $uibModalInstance.close();
            },
          });
        },
      ],
    });
    $scope.$on('$destroy', () => {
      modalInstance.close();
    });
  };

  const getStorageKeyParams = () => {
    const storageKeyParams = angular.copy($scope.accessLogQuery);
    delete storageKeyParams.dateRange;
    if (storageKeyParams.level === 'Select Level') {
      delete storageKeyParams.level;
    }
    if ($scope.accessLogQuery.dateRange.startDate && $scope.accessLogQuery.dateRange.endDate) {
      storageKeyParams.startDate = $scope.accessLogQuery.dateRange.startDate;
      storageKeyParams.endDate = $scope.accessLogQuery.dateRange.endDate;
    }
    return storageKeyParams;
  };

  $scope.filterAccessLogs = () => {
    storage.setData(storageKey, getStorageKeyParams());
    getAccessLogs(0, 10);
  };

  $scope.clearFilters = () => {
    storage.removeData(storageKey);
    initSearchForm();
    getAccessLogs(0, 10);
  };

  $scope.enterOnFormSearch = (event) => {
    if (event.keyCode === 13) {
      $scope.pagination.currentPage = 1;
      storage.setData(storageKey, getStorageKeyParams());
      getAccessLogs(0, 10);
    }
  };
}

angular.module('relayHealth')
  .controller('accessLogCtrl', [
    '$scope',
    'API_BASE_URL',
    '$http',
    'loadingScreenFactory',
    '$uibModal',
    '$rootScope',
    '$state',
    'toaster',
    'Pubnub',
    'storageManager',
    accessLogCtrl,
  ]);
