angular
  .module('relayHealthConfig', [])
  .constant('API_BASE_URL', process.env.API_URL_BASE)
  .constant('DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID', process.env.MANAGEMENT_CARE_ID)
  .constant('PUBNUB_PUBLISH_KEY', process.env.PUBNUB_PUBLISH_KEY)
  .constant('PUBNUB_SUBSCRIBE_KEY', process.env.PUBNUB_SUBSCRIBE_KEY)
  .constant('PASSWORD_PUBLIC_KEY', process.env.PASSWORD_PUBLIC_KEY)
  .constant('PUBNUB_CIPHER_KEY', process.env.PUBNUB_CIPHER_KEY)
  .constant('KIBANA_URL_REPORTING', process.env.KIBANA_REPORTING)
  .constant('KIBANA_URL_CLIENT_REPORT', process.env.KIBANA_CLIENT_REPORT)
  .constant('USER_GUIDE_URL', 'https://s3-us-west-2.amazonaws.com/relayhealthcare/static_assets/Relay+Transportation+User+Guide+v2.1.pdf')
  .constant('STRIPE_KEY_PUBLISHABLE', process.env.STRIPE_KEY_PUBLISHABLE)
  .constant('WORKERS_COMPENSATION_ID', 1)
  .constant('MASS_MARKET_ID', 2)
  .constant('HEALTHCARE_MASS_MARKET_KEY', 'HMM')
  .constant('WORKERS_COMPENSATION_KEY', 'WC')
  .constant('MASS_MARKET_KEY', 'MM')
  .constant('GROUP_HEALTH_KEY', 'GHT')
  .constant('GOVERNMENT_SOLUTIONS_KEY', 'GS')
  .constant('SITE_NAME', 'RelaySOLUTIONS')
  .constant('SESSION_EXPIRATION_NOTIFICATION_TIME', process.env.REDIS_SESSION_EXPIRY - 3)
  .constant('SESSION_EXPIRATION_TIME', process.env.REDIS_SESSION_EXPIRY)
  .constant('PAYMENT_METHODS', {
    CREDIT_CARD: 'credit card',
    BANK_ACCOUNT: 'bank account',
  })
  .constant('OCCM_LAT_LONG', {
    latitude: 30.316365,
    longitude: -81.66353,
  })
  .constant('MASTER_ORG_LEVEL', [1])
  .constant('RECURRENCE_WEEKLY_MAX_INTERVALS', 3)
  .constant('RECURRENCE_MONTHLY_MAX_INTERVALS', 12)
  .constant('RECURRENCE_LIMIT', 365)
  .constant('SOURCE_ICON', 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png')
  .constant('DEST_ICON', 'https://maps.google.com/mapfiles/ms/icons/red-dot.png')
  .constant('SHOW_RECURRENCE', true)
  .constant('MAX_SERVER_ERROR_RETRY', 5);
window.environment = 'ENV_VAR';

window.zendesk_subdomain = 'ZENDESK_SUBDOMAIN';

window.GTM_KEY = 'KEY_GTM';

export default 'relayHealthConfig';
