import moment from 'moment';
import app from '../../app';

app.controller('manageRiderCntrl', [
  '$scope',
  '$timeout',
  '$http',
  'loadingScreenFactory',
  'API_BASE_URL',
  '$stateParams',
  'toaster',
  'GOOGLE_PLACES_ATTRIBUTES',
  function manageRiderCntrl(
    $scope,
    $timeout,
    $http,
    loadingScreenFactory,
    API_BASE_URL,
    $stateParams,
    toaster,
    GOOGLE_PLACES_ATTRIBUTES,
  ) {
    $scope.googlePlacesAttributes = GOOGLE_PLACES_ATTRIBUTES;
    const ref = this;
    const settingsConstants = {
      accountLookup: 'account_lookup',
      claimLookup: 'claim_lookup',
      futureLookup: 'future_lookup',
      payer: 'payer',
    };
    $scope.billingOptions = {
      [settingsConstants.accountLookup]: {
        label: 'My Account',
        showPayerName: false,
        showClaimNumber: false,
        showHomeAddress: false,
      },
      [settingsConstants.claimLookup]: {
        label: 'Claim',
        showPayerName: true,
        showClaimNumber: true,
        showHomeAddress: true,
      },
      [settingsConstants.futureLookup]: {
        label: 'Future Claim',
        showPayerName: true,
        showClaimNumber: false,
        showHomeAddress: true,
      },
    };
    $scope.fieldsToBeValidatedOnForm = [
      'startDate',
      'endDate',
      'numberOfLegs',
      'dollarAmount',
      'milesPerLeg',
      'totalMilesAllowed',
      'WeekDay',
      'timeFrom',
      'timeTo',
      'address',
    ];
    const allDateFields = ['startDate', 'endDate'];
    this.disableForm = !$stateParams.edit;
    this.address = '';
    this.pageTitle = 'Manage Riders';
    this.showAddRiderForm = false;
    this.isValidAmount = true;
    this.now = moment();
    this.daysList = [
      { day: 'Monday' },
      { day: 'Tuesday' },
      { day: 'Wednesday' },
      { day: 'Thursday' },
      { day: 'Friday' },
      { day: 'Saturday' },
      { day: 'Sunday' },
    ];
    this.moment = moment;
    $scope.addressesAllowed = [];
    this.permissionsConstants = relaylib.permissions.permissionsConstants;
    this.tripTypeConstants = relaylib.common.constants.tripTypeConstants;
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const datePickerToggleState = {
      '#riderDob': false,
      '#riderDoi': false,
    };

    this.dateOptions = {
      singleDatePicker: true,
      parentEl: '#current-app #page-wrapper',
      locale: {
        format: 'YYYY-MM-DD',
      },
      showDropdowns: true,
      eventHandlers: {
        'showCalendar.daterangepicker': ({
          picker: {
            element: [{ id }],
          },
        }) => {
          datePickerToggleState[`#${id}`] = true;
        },
        'hideCalendar.daterangepicker': ({
          picker: {
            element: [{ id }],
          },
        }) => {
          datePickerToggleState[`#${id}`] = false;
        },
      },
    };

    this.toggleDatePicker = (elementName) => {
      if (datePickerToggleState[elementName]) {
        datePickerToggleState[elementName] = false;
        $timeout(() => {
          $(elementName)
            .data('daterangepicker')
            .hide();
        });
      } else {
        datePickerToggleState[elementName] = true;
        $timeout(() => {
          $(elementName)
            .data('daterangepicker')
            .show();
        });
      }
    };

    const viewContentLoaded = $scope.$watch('$viewContentLoaded', () => {
      const timeFields = ['timeFrom', 'timeTo'];
      setTimeout(() => {
        timeFields.forEach((timefield) => {
          const tdSelector = `#${timefield} td.uib-time`;
          const selectedTdsDom = $(tdSelector);
          const timeInputDomFields = $(`${tdSelector} input`);
          timeInputDomFields.blur(() => {
            $timeout(() => {
              const [hoursFieldDom, minutesFieldDom] = Array.from(timeInputDomFields);
              const hoursFieldValue = hoursFieldDom.value;
              const  minutesFieldValue = minutesFieldDom.value;
              if (!hoursFieldValue && !minutesFieldValue && !this.editRide[timefield]) {
                this.editRiderForm[timefield].$invalid = false;
                selectedTdsDom.removeClass('has-error');
              } else if (hoursFieldValue && minutesFieldValue && this.editRide[timefield]) {
                this.editRiderForm[timefield].$invalid = false;
                this.editRiderForm.$invalid = false;
              } else {
                this.editRiderForm[timefield].$invalid = true;
                this.editRiderForm.$invalid = true;
              }
            }, 1000);
          });
        });
      }, 1000);
      viewContentLoaded();
    });
    /**
     * For phone number field in Add Rider section.
     */
    $('#phoneNumber').intlTelInput(
      'getNumber',
      window.intlTelInputUtils.numberFormat.E164,
    );
    const getSelectedOrgDetails = async (orgID) => {
      const getSelectedOrgDetailsCall = $http({
        url: `${API_BASE_URL}organisation/getOrgDetailsById/${orgID}`,
        method: 'GET',
      });
      getSelectedOrgDetailsCall.then(
        (result) => {
          if (result.data && result.data.success) {
            $scope.orgDetails = result.data.orgDetails;
            const customizedIDNumber = _.find($scope.orgDetails.settings, {
              setting_name: 'customized_id_number',
            });
            $scope.customizedIDNumber = (customizedIDNumber && customizedIDNumber.setting_value.toLowerCase() === 'true');
            if ($scope.customizedIDNumber) {
              const customizedIdNumberLabel = _.find($scope.orgDetails.settings, {
                setting_name: 'customized_id_number_label',
              });
              if (customizedIdNumberLabel && customizedIdNumberLabel.setting_value) {
                $scope.customizedIdNumberLabel = customizedIdNumberLabel.setting_value;
              }
            }
          } else {
            toaster.pop({
              type: 'error',
              title: result.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        },
        (error) => {
          toaster.pop({
            type: 'error',
            title: error.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    };

    const getInvitationDetails = async () => {
      loadingScreenFactory.showLoadingScreen();
      try {
        const headers = {
          username: userDetails.username,
          usertoken: userDetails.user_token,
          'Content-Type': 'application/json',
        };
        const invitationId = $stateParams.id;
        const requestUrl = `${API_BASE_URL}invite/${invitationId}`;
        const {
          data: { success, data },
        } = await $http.get(requestUrl, { headers });
        if (success) {
          await getSelectedOrgDetails(data.org_id);
          data.dateOfBirth = data.dateOfBirth
            ? `${moment(new Date(data.dateOfBirth)).format('YYYY-MM-DD')}`
            : null;
          data.dateOfInjury = data.dateOfInjury
            ? `${moment(new Date(data.dateOfInjury)).format('YYYY-MM-DD')}`
            : null;
          ref.invitationDetails = data;
          ref.editRide = angular.copy(data.accessAllowed);
          if (!ref.editRide.addressesAllowed) {
            ref.editRide.addressesAllowed = [];
          }
          if (!ref.editRide.daysAllowed) {
            ref.daysAllowed = [];
          } else {
            ref.daysAllowed = ref.editRide.daysAllowed.map(selectedDay => ({ day: _.capitalize(selectedDay) }));
          }
          if (ref.editRide.timeFrom) {
            ref.editRide.timeFrom = moment(ref.editRide.timeFrom, ['HH:mm']).format('YYYY-MM-DDTHH:mm:ssZ');
          }
          if (ref.editRide.timeTo) {
            ref.editRide.timeTo = moment(ref.editRide.timeTo, ['HH:mm']).format('YYYY-MM-DDTHH:mm:ssZ');
          }
        }
      } catch (err) {
        const message = (err.data && err.data.message) ? err.data.message : err.message;
        toaster.pop({
          type: 'error',
          title: message,
          showCloseButton: true,
        });
      } finally {
        loadingScreenFactory.hideLoadingScreen();
        $scope.$apply();
      }
    };
    const convertStringToFloat = variable => parseFloat(variable.toString().replace(/,/g, ''));

    this.checkForFieldsValidity = () => {
      const invalidRiderTabFields = $scope.fieldsToBeValidatedOnForm
        .filter(fieldToBeValidated => (this.editRiderForm[fieldToBeValidated] || {}).$invalid === true);
      if (invalidRiderTabFields.length) {
        const firstInvalidRiderTabField = $(`[name = ${invalidRiderTabFields[0]}]`)[0];
        if (firstInvalidRiderTabField) {
          if (allDateFields.includes(invalidRiderTabFields[0])) {
            $timeout(() => {
              firstInvalidRiderTabField.focus();
            }, 1000);
          } else {
            firstInvalidRiderTabField.focus();
          }
        }
        $scope.isAnyRiderFieldInvalid = true;
      } else {
        $scope.isAnyRiderFieldInvalid = false;
      }
    };

    this.saveChangesClicked = async () => {
      this.checkForFieldsValidity();
      if ($scope.isAnyRiderFieldInvalid) { return; }
      loadingScreenFactory.showLoadingScreen();
      try {
        const allowedDayList = this.daysAllowed.map(dayObj => (dayObj.day));
        this.editRide.daysAllowed = allowedDayList;

        if (this.editRide.addressesAllowed.length === 1) {
          throw new Error('Minimum 2 addresses are required');
        }
        if (this.editRide.totalMilesAllowed) {
          this.editRide.totalMilesAllowed = convertStringToFloat(this.editRide.totalMilesAllowed);
        }
        if (this.editRide.milesPerLeg) {
          this.editRide.milesPerLeg = convertStringToFloat(this.editRide.milesPerLeg);
        }
        const headers = {
          username: userDetails.username,
          usertoken: userDetails.user_token,
          'Content-Type': 'application/json',
        };

        const putData = {};
        const addRideModelDateKeys = ['startDate', 'endDate'];
        Object.keys(this.editRide).forEach((key) => {
          if (addRideModelDateKeys.indexOf(key) > -1 && this.editRide[key]) {
            putData[key] = moment(this.editRide[key]).format('MM/DD/YYYY');
          } else if (key === 'timeTo' || key === 'timeFrom') {
            putData[key] = moment(this.editRide[key]).format('HH:mm');
          } else if (this.editRide[key]) {
            putData[key] = this.editRide[key];
          }
        });
        const invitationId = $stateParams.id;
        const requestUrl = `${API_BASE_URL}invite/${invitationId}`;
        const response = await $http.put(
          requestUrl,
          { accessAllowed: putData },
          headers,
        );
        const {
          data: { success },
        } = response;
        if (success) {
          toaster.pop({
            type: 'success',
            title: 'Invitation updated successfully',
            showCloseButton: true,
          });
        }
      } catch (err) {
        const message = err.data && err.data.message ? err.data.message : err.message;
        toaster.pop({
          type: 'error',
          title: message,
          showCloseButton: true,
        });
      } finally {
        loadingScreenFactory.hideLoadingScreen();
        $scope.$apply();
      }
    };
    let isAddressSelectedFromSuggestions = false;
    $scope.addAddress = function address() {
      isAddressSelectedFromSuggestions = true;
      const addressInfo = this.getPlace();
      const fullAddress = {
        lat: addressInfo.geometry.location.lat(),
        long: addressInfo.geometry.location.lng(),
        zipCode: addressInfo.address_components[addressInfo.address_components.length - 1].short_name,
        shortAddress: addressInfo.name,
        address: ref.address,
        placeId: addressInfo.place_id,
      };
      ref.editRide.addressesAllowed.push(fullAddress);
      ref.address = '';
    };
    $scope.deleteAddress = (position) => {
      if (this.disableForm) {
        return;
      }
      this.editRide.addressesAllowed.splice(position, 1);
      this.setFieldValidity();
      if (this.editRide.addressesAllowed.length === 1) {
        $('[name = \'address\']')[0].focus();
      }
    };

    this.editButtonClicked = () => {
      this.disableForm = false;
    };

    this.setForm = function setForm(form) {
      this.editRiderForm = form;
    };

    this.setFieldValidity = () => {
      $timeout(() => {
        if (this.editRide.addressesAllowed.length === 1) {
          this.editRiderForm.address.$setValidity('valid', false);
          return;
        }
        if (!this.address && !this.editRide.addressesAllowed.length) {
          this.editRiderForm.address.$setValidity('valid', true);
          return;
        }
        let ngChangeTriggered = true;
        if (isAddressSelectedFromSuggestions) {
          ngChangeTriggered = false;
          isAddressSelectedFromSuggestions = false;
        }
        if (ngChangeTriggered) {
          this.editRiderForm.address.$setValidity('valid', false);
        } else {
          this.editRiderForm.address.$setValidity('valid', true);
        }
      }, 1000);
    };

    this.init = () => {
      this.invitationDetails = {};
      getInvitationDetails();
    };
    this.init();
  },
]);
