angular.module('relayHealth').factory('setMapPoints', ['$timeout', $timeout => (map, markers, mapPoints) => {
  $timeout(() => {
    if (mapPoints) {
      const { source, destination } = mapPoints;
      const sourcelatLong = new google.maps.LatLng(
        source.latitude,
        source.longitude,
      );
      markers.push(
        new google.maps.Marker({
          position: sourcelatLong,
          map,
          label: 'S',
          icon: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
        }),
      );
      const destLatLong = new google.maps.LatLng(
        destination.latitude,
        destination.longitude,
      );
      markers.push(
        new google.maps.Marker({
          position: destLatLong,
          map,
          label: 'D',
        }),
      );
      google.maps.event.trigger(map, 'resize');
      map.setCenter(sourcelatLong);
      map.setZoom(13);
    }
  });
}]);
