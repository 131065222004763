angular.module('relayHealth').controller('performanceReportCtrl', [
  '$scope',
  '$state',
  'API_BASE_URL',
  '$http',
  function performanceReportCtrl($scope, $state, API_BASE_URL, $http) {
    $scope.pageTitle = 'Operations Performance Reports';


    function adjustPagination() {
      $scope.pagination.lastItem = ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage + $scope.pagination.itemsPerPage;
      if ($scope.pagination.lastItem > $scope.pagination.totalItems) {
        $scope.pagination.lastItem = $scope.pagination.totalItems;
      }
    }

    function getPerformanceReports(offset, limit, searchStr) {
      const request = $http.get(`${API_BASE_URL}fuseReports/performanceReports?offset=${offset}&limit=${limit}${searchStr}`);

      request.then(
        (response) => {
          if (response.data.success) {
            const result = response.data.data.results;
            const userDetails = response.data.data.user_details;
            $scope.reports = result;
            $scope.userDetails = userDetails;
            $scope.pagination.totalItems = response.data.totalCount;
            adjustPagination();
          }
        },
      );
    }

    function getSearchString() {
      const searchFormQuery = angular.copy($scope.performanceFilters);

      if (searchFormQuery.dateRange.startDate && searchFormQuery.dateRange.endDate) {
        if (typeof $scope.performanceFilters.dateRange.startDate === 'object') {
          searchFormQuery.startDate = `${$scope.performanceFilters.dateRange.startDate.format('YYYY-MM-DD')} 00:00:00`;
          searchFormQuery.endDate = `${$scope.performanceFilters.dateRange.endDate.format('YYYY-MM-DD')} 23:59:59`;

          searchFormQuery.startTimestamp = $scope.performanceFilters.dateRange.startDate.unix();
          searchFormQuery.endTimestamp = $scope.performanceFilters.dateRange.endDate.unix();
        } else {
          searchFormQuery.startDate = $scope.performanceFilters.dateRange.startDate;
          searchFormQuery.endDate = $scope.performanceFilters.dateRange.endDate;

          searchFormQuery.startTimestamp = $scope.performanceFilters.dateRange.startDate.unix();
          searchFormQuery.endTimestamp = $scope.performanceFilters.dateRange.endDate.unix();
        }
      }
      delete searchFormQuery.dateRange;
      if (searchFormQuery.status === 'Select Status') {
        // remove if from
        delete searchFormQuery.status;
      }
      const searchStr = [];
      Object.keys(searchFormQuery).forEach((key) => {
        if (searchFormQuery[key] && key !== 'currentPage') {
          searchStr.push(`${key}=${searchFormQuery[key]}`);
        }
      });

      const order = $scope.sortOrder ? 'DESC' : 'ASC';
      const orderArray = [[$scope.sortBy, order]];
      const orderString = encodeURI(JSON.stringify(orderArray));
      searchStr.push(`order=${orderString}`);

      let temp = searchStr.join('&');
      if (searchStr.length > 0) temp = `&${temp}`;

      return temp;
    }

    $scope.sortBy = 'createdAt';
    $scope.sortOrder = 1;
    $scope.sort = (sortBy) => {
      if ($scope.sortBy === sortBy) {
        $scope.sortOrder = !$scope.sortOrder;
      }
      $scope.sortBy = sortBy;
      getPerformanceReports($scope.pagination.currentPage - 1, 10, getSearchString());
    };

    function initSearchForm() {
      $scope.performanceFilters = {
        dateRange: {
          startDate: moment(),
          endDate: moment(),
        },
        currentPage: '',
      };
    }

    function initPagination() {
      $scope.pagination = {
        totalItems: 0,
        currentPage: 1,
        lastItem: 0,
        itemsPerPage: 10,
      };
    }

    $scope.dateRangeOptions = {
      parentEl: '#current-app #page-wrapper',
      eventHandlers: {
        'apply.daterangepicker': () => {
          const dateRangeFilter = document.getElementsByName('dateRange')[0].value;

          if (dateRangeFilter) {
            const dateRangeFilterArr = dateRangeFilter.split(' - ');
            $scope.dateRange.startDate = `${dateRangeFilterArr[0]} 00:00:00`;
            $scope.dateRange.endDate = `${dateRangeFilterArr[1]} 23:59:59`;
          }
        },
      },
    };

    function getStateParams() {
      const stateParams = angular.copy($scope.performanceFilters);

      if ($scope.performanceFilters.dateRange) {
        if ($scope.performanceFilters.dateRange.startDate && typeof $scope.performanceFilters.dateRange.startDate === 'object') {
          stateParams.startDate = `${$scope.performanceFilters.dateRange.startDate.format('YYYY-MM-DD')} 00:00:00`;
        }
        if ($scope.performanceFilters.dateRange.endDate && typeof $scope.performanceFilters.dateRange.endDate === 'object') {
          stateParams.endDate = `${$scope.performanceFilters.dateRange.endDate.format('YYYY-MM-DD')} 23:59:59`;
        }
      }

      stateParams.endDate = $scope.performanceFilters.dateRange === '' ? null : $scope.performanceFilters.dateRange.endDate;
      return stateParams;
    }

    // Fetch data based on filters when enter key pushed.
    $scope.enterOnFormSearch = (event) => {
      if (event.keyCode === 13) {
        $scope.pagination.currentPage = 1;
        $scope.performanceFilters = angular.copy($scope.performanceFilters);
        const stateParams = getStateParams();
        stateParams.currentPage = 0;
        $state.go($state.current, stateParams);
        getPerformanceReports(0, 10, getSearchString());
      }
    };

    $scope.performanceReportsSearch = () => {
      $scope.searchFormQuery = angular.copy($scope.performanceFilters);
      const stateParams = getStateParams();
      stateParams.currentPage = 0;
      $state.go($state.current, stateParams);
      getPerformanceReports(0, 10, getSearchString());
    };

    $scope.clearFilters = () => {
      const stateParams = {};
      initSearchForm();
      getPerformanceReports(0, 10, getSearchString());

      angular.extend(stateParams, $scope.performanceFilters);
      stateParams.level = '';
      stateParams.currentPage = 0;
      $scope.pagination.currentPage = 1;
      $state.go($state.current, stateParams);
    };

    initSearchForm();
    initPagination();
    $scope.performanceReportsSearch();
  },
]);
