import { registerApplication, getAppNames } from 'single-spa';
import singleSpaAngular from 'single-spa-angularjs';
import './app';
import './constants';
import './filters';
import './directives';
import './services';
import './factories';
import './controllers';
import './sub-modules';
import './routes';
import './css';

const currentApp = singleSpaAngular({
  angular,
  domElementGetter: () => document.getElementById('current-app'),
  mainAngularModule: 'relayHealth',
  uiRouter: true,
  preserveGlobal: false,
  template: '<div ui-view></div>',
});

if (!getAppNames().includes('currentApp')) {
  registerApplication('currentApp', currentApp, location => location.pathname.indexOf(`/${process.env.PUBLIC_URL_PREFIX}current/`) === 0);
}
