export default class LocalStorage {
    getItem(key) {
        return JSON.parse(LocalStorage.localstorage.getItem(key));
    }

    getItemByKey(key, childProperty) {
        const storageData = JSON.parse(LocalStorage.localstorage.getItem(key));
        let getKeyData = null;
        if (storageData && storageData[childProperty]) {
            getKeyData = storageData[childProperty];
        }
        return getKeyData;
    }

    setItem(key, data) {
        LocalStorage.localstorage.setItem(key, JSON.stringify(data));
    }

    setItemByKey(key, childProperty, data) {
        let storageData = JSON.parse(LocalStorage.localstorage.getItem(key));
        if (!storageData) { storageData = {}; }
        storageData[childProperty] = data;
        LocalStorage.localstorage.setItem(key, JSON.stringify(storageData));
    }

    removeItem(key) {
        LocalStorage.localstorage.removeItem(key);
    }

    removeItemByKey(key, childProperty) {
        const storageData = JSON.parse(LocalStorage.localstorage.getItem(key));
        if (storageData && storageData[childProperty]) {
            delete(storageData[childProperty]);
        }
        LocalStorage.localstorage.setItem(key, JSON.stringify(storageData));
    }

    static set localstorage(localStorage) {
        this._localStorage = localStorage;
    }

    static get localstorage() {
        return this._localStorage;
    }

}
