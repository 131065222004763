angular.module('relayHealth').service('signupInitialStateService', [
  'signupStateService',
  function signupInitialStateService(signupStateService) {
    this.initialState = function initialState() {
      const userDetails = signupStateService.getState('userDetails');
      const { organisation } = userDetails;
      const state = signupStateService.getState('signupState');
      if (state) {
        return state;
      }
      let cardStoredIn = '';
      if (
        userDetails.role === 'orgadmin'
          || userDetails.role === 'riderUser'
      ) {
        cardStoredIn = 'user';
      } else {
        cardStoredIn = 'org';
      }
      const stateStructure = {
        signupType: userDetails.organisation.org_class,
        userType: userDetails.role,
        email: userDetails.username,
        firstName: userDetails.first_name,
        lastName: userDetails.last_name,
        phone: userDetails.phone ? userDetails.phone.slice(-10) : '',
        userId: userDetails.user_id,
        addUsersAlertShown: false,
        usertoken: userDetails.user_token,
        accountInfo: {
          orgId: organisation.id,
          state: userDetails.state,
          city: userDetails.city,
          address1: userDetails.address1,
          zip: userDetails.zip,
        },
        latestStage: userDetails.step,
        acceptTc: userDetails.t_and_c_check || false,
        businessInfo: {
          orgId: organisation.id,
          interest: '',
          orgName: organisation.name,
          address1: organisation.addresses
            ? organisation.addresses.address1
            : '',
          city: organisation.addresses ? organisation.addresses.city : '',
          state_id: organisation.addresses
            ? organisation.addresses.state_id
            : '',
          zip: organisation.addresses ? organisation.addresses.zip : '',
          completeAddress: _.get(organisation, 'addresses.completeAddress', ''),
          industry: organisation.industry,
          TIN: organisation.tin,
          market_segment_id: organisation.market_segment_id,
          market_segment_key: (organisation.market_segment || {}).key,
        },

        users: [],
        monthlyInvoice: false,
        stripeCardAdded:
            cardStoredIn === 'user'
              ? userDetails.stripe_customer_id
              : userDetails.organisation.stripe_customer_id,
        maskedStripeCardNumber: '',
        cardHolderName: '',
        expMonth: '',
        expYear: '',
        billingAddressSameAsAddress: false,
        billingAddress: {
          address1: '',
          city: '',
          state: '',
          zip: '',
        },
        previousPage: '',
        currentPage: userDetails.step,
        created_by_user: organisation.created_by_user,
      };
      if (organisation.parent_id) {
        stateStructure.businessInfo.parent_id = organisation.parent_id;
        stateStructure.businessInfo.type = 'childOrg';
      }
      signupStateService.setState('signupState', stateStructure);
      return stateStructure;
    };
  },
]);
