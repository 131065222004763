const permissionConstants = {
    SHOW_VARIANCE_TAB_DASHBOARD: 'show_variance_tab_dashboard',
    MARKET_SEGMENT_CREATE: 'market_segment_create',
    MARKET_SEGMENT_READ: 'market_segment_read',
    MARKET_SEGMENT_UPDATE: 'market_segment_update',
    MARKET_SEGMENT_DELETE: 'market_segment_delete',
    MARKET_SEGMENT_VIEW_ORGS: 'market_segment_view_orgs',
    IMPORT_ORG: 'import_org',
    IMPORT_FUSE_INCLUSION: 'import_fuse_inclusion',
    GET_ONLINE_ADMINS: 'get_online_admins',
    ORG_CREATE: 'org_create',
    ORG_READ: 'org_read',
    ORG_UPDATE: 'org_update',
    ORG_DELETE: 'org_delete',
    USER_CREATE: 'usercreate',
    USER_READ: 'user_read',
    USER_UPDATE: 'user_update',
    USER_DELETE: 'user_delete',
    GET_API_KEYS: 'get_api_keys',
    CREATE_API_TOKEN: 'create_api_token',
    ACTIVATE_API_TOKEN: 'activate_api_token',
    DEACTIVATE_API_TOKEN: 'deactivate_api_token',
    USER_UPDATE_ANY: 'update_any_user',
    GET_ONLINE_ADMIN_COUNT: 'get_online_admin_count',
    SUBSCRIBE_PRESENCE: 'subscribe_presence',
    QUESTIONNAIRE_ACCESS: 'questionnaire_access',
    SHOW_ALL_TABS: 'show_all_tabs',
    GET_TILES_COUNT: 'get_tiles_count',
    READ_NOTIFICATION: 'read_notification',
    SHOW_SIDE_NAV_HOME: 'show_side_nav_home',
    SHOW_SIDE_NAV_REQUEST_RIDE: 'show_side_nav_request_ride',
    SHOW_SIDE_NAV_ACCOUNT_MGMT: 'show_side_nav_account_mgmt',
    SHOW_SIDE_NAV_PROFILE_MGMT: 'show_side_nav_profile_mgmt',
    SHOW_SIDE_NAV_ORG_MGMT: 'show_side_nav_org_mgmt',
    SHOW_SIDE_NAV_USER_MGMT: 'show_side_nav_user_mgmt',
    SHOW_SIDE_NAV_MARKET_SEGMENT: 'show_side_nav_market_segment',
    SHOW_SIDE_NAV_REPORTING: 'show_side_nav_reporting',
    SHOW_SIDE_NAV_LANG_DASHBOARD: 'show_side_nav_lang_dashboard',
    SHOW_SIDE_NAV_TRANSPORTATION: 'show_side_nav_transportation',
    SHOW_SIDE_NAV_DASHBOARD: 'show_side_nav_dashboard',
    SHOW_SIDE_NAV_LOGS: 'show_side_nav_logs',
    SHOW_SIDE_NAV_FUSE_REPORTS: 'show_side_nav_fuse_reports',
    SHOW_SIDE_NAV_MISMATCH_REPORT: 'show_side_nav_mismatch_report',
    SHOW_SIDE_NAV_BWB_REPORT: 'show_side_nav_bwb_report',
    SHOW_SIDE_NAV_OPERATION_REPORT: 'show_side_nav_operation_report',
    SHOW_SIDE_NAV_INVOICES: 'show_side_nav_invoices',
    SHOW_SIDE_NAV_HELP: 'show_side_nav_help',
    SHOW_SIDE_NAV_VIDEO_LIB: 'show_side_nav_video_lib',
    SHOW_NOTIFICATIONS: 'show_notifications',
    CHOOSE_ORG: 'choose_org',
    SHOW_REQUESTER_NAME: 'show_requester_name',
    SHOW_AUTO_VERIFY_CHECKBOX: 'show_auto_verify_checkbox',
    SHOW_CHOOSE_CHILD_ORG_DROPDOWN: 'show_choose_child_org_dropdown',
    SHOW_ORACLE_CUSTOMER_ACC_NUMBER: 'show_oracle_customer_acc_number',
    EDIT_ORACLE_CUSTOMER_ACC_NUMBER: 'edit_oracle_customer_acc_number',
    SHOW_ORACLE_PARTY_ID: 'show_oracle_party_id',
    EDIT_ORACLE_PARTY_ID: 'edit_oracle_party_id',
    SHOW_UNELIGIBLE_PARENT_MSG: 'show_uneligible_parent_msg',
    SHOW_TIN_NUMBER: 'show_tin_number',
    SHOW_ACCOUNT_SETTINGS: 'show_account_settings',
    SHOW_BREADCRUMB_ORG_NAME: 'show_breadcrumb_org_name',
    SHOW_RIDE_STATUS_COLUMN: 'show_ride_status_column',
    SHOW_ORG_COLUMN: 'show_org_column',
    SHOW_MARKET_SEGMENT_COLUMN: 'show_market_segment_column',
    SHOW_MANAGE_BY_DROPDOWN: 'show_manage_by_dropdown',
    SHOW_MARKET_SEGMENT_DROPDOWN: 'show_market_segment_dropdown',
    SHOW_MANAGE_BY_DROPDOWN_ISSUE_TAB: 'show_manage_by_dropdown_issue_tab',
    SHOW_NOTIFICATIONS_SETTING: 'show_notifications_setting',
    SHOW_ISSUE_SETTING: 'show_issue_setting',
    SHOW_MILEAGE_TRACKER_SETTING: 'show_mileage_tracker_setting',
    SHOW_ACTION_COLUMN: 'show_action_column',
    SHOW_ACTION_CTA: 'show_action_cta',
    UPDATE_BILLING_METHOD: 'update_billing_method',
    RELEASE_NOTES_READ: 'release_notes_read',
    RELEASE_NOTES_CREATE: 'release_notes_create',
    CLIENT_DASHBOARD_ACCESS: 'client_dashboard_access',
    CAN_ACCESS_ALL_ORGS: 'can_access_all_orgs',
    CAN_ACCESS_ALL_MARKET_SEGMENTS: 'can_access_all_market_segments',
    BROADCAST_CREATE: 'broadcast_create',
    BROADCAST_READ: 'broadcast_read',
    BROADCAST_UPDATE: 'broadcast_update',
    BROADCAST_DELETE: 'broadcast_delete',
    TEMPLATE_BROADCAST_CREATE: 'template_broadcast_create',
    TEMPLATE_BROADCAST_READ: 'template_broadcast_read',
    TEMPLATE_BROADCAST_UPDATE: 'template_broadcast_update',
    TEMPLATE_BROADCAST_DELETE: 'template_broadcast_delete',
    BROADCAST_CREATE_FROM_TEMPLATE: 'broadcast_create_from_template',
    SHOW_ACCESS_TYPES: 'show_access_types',
    EDIT_INVOICE: 'edit_invoice',
    EDIT_GH_INVOICE:'edit_gh_invoice',
    SHOW_DELETE_HELP_VIDEO: 'show_delete_help_video',
    SHOW_UPLOAD_HELP_VIDEO: 'show_upload_help_video',
    EDIT_RECEIPT_FIELDS: 'edit_receipt_fields',
    EDIT_APPT_STATUS_RECEIPT_FIELD: 'edit_appt_status_receipt_field',
    EDIT_RIDE_STATUS_RECEIPT_FIELD: 'edit_ride_status_receipt_field',
    EDIT_INVOICE_ADD_RECEIPT: 'edit_invoice_add_receipt',
    ACCESS_ISSUES_TAB: 'show_issues_tab',
    ACCESS_PAYMENT_ISSUES_TAB: 'show_payment_issues_tab',
    DASHBOARD_TAB_PAGINATION: 'dashboard_tab_pagination',
    OPERATIONS_TEAM_ACCESS: 'operations_team_access',
    SHOW_VARIANCE_TAB_COMPLETED_APPTS: 'show_variance_tab_dashboard_appts',
    SHOW_VENDOR_PREFERENCE: 'show_vendor_preference',
    SHOW_EXCLUDED_VENDOR_LIST: 'show_excluded_vendor_list',
    SHOW_SCHEDULING_ALERTS_TAB: 'show_scheduling_alerts_tab',
    SHOW_MANAGE_BY_DROPDOWN_SCHEDULING_ALERTS_TAB: 'show_manage_by_dropdown_scheduling_alerts_tab',
    SHOW_ALERT_TYPE_DROPDOWN_SCHEDULING_ALERTS_TAB: 'show_alert_type_dropdown_scheduling_alerts_tab',
    SHOW_ALL_SCHEDULING_ALERTS: 'show_all_scheduling_alerts',
    VIEW_PROVIDERS: 'view_providers',
    SUBSCRIBE_MASTER_PRESENCE: 'subscribe_master_presence',
    SHOW_ENHANCED_BILLING_TOS: 'show_enhanced_billing_tos',
    SHOW_BILLING_OPTIONS: 'show_billing_options',
    SHOW_MANAGE_RIDER: 'show_manage_rider',
    SHOW_RECURRENCE_TRIPS_TAB: 'show_recurrence_trips_tab',
    SHOW_AVAILABILITY_MATRIX: 'show_availability_matrix',
    SHOW_PAYER: 'show_payer',
    SHOW_RETRO_QUEUE_TAB: 'show_retro_queue_tab',
    SHOW_RECEIPT_DETAIL_LOGS: 'show_receipt_detail_logs',
    SHOW_INVITE_ONLY_LOGS: 'show_invite_only_logs',
    SHOW_MAS_LOGS: 'show_mas_logs',
    SHOW_MAS_INVOICE_ADMIN_PAGE: 'show_mas_invoice_admin_page',
    ALLOW_ORG_KEY_EDITING: 'allow_org_key_editing',
    SHOW_DELEGATION: 'show_delegation',
    SHOW_DASHBOARD_FILTERS: 'show_dashboard_filters',
    SHOW_REQUESTING_ORGANIZATION_FILTER: 'show_requesting_organization_filter',
    EDIT_PATIENT_DETAILS_SCHEDULED_RIDE: 'edit_patient_details_scheduled_ride',
    SHOW_MILEAGE_LIMIT_SETTINGS: 'show_mileage_limit_settings',
    SHOW_GLOBAL_SETTINGS: 'show_global_settings',
    EDIT_GLOBAL_SETTINGS: 'edit_global_settings',
    SHOW_SIDE_NAV_GLOBAL_SETTINGS: 'show_side_nav_global_settings',
    SHOW_DYNAMIC_EXPORT_INACTIVE_ORGS: 'show_dynamic_export_inactive_orgs',
    SHOW_SIDE_NAV_INVITE_RIDER: 'show_side_nav_invite_rider',
    SHOW_SIDE_NAV_ADDRESS_AUDIT_REPORT: 'show_side_nav_address_audit_report',
    DISABLE_CTAS: 'disable_ctas'
};

Object.freeze(permissionConstants);
export default permissionConstants;
