angular.module('relayHealth').constant('TRIP_IDENTIFIERS', [
  {
    type: 'file_number',
    name: 'File Number',
  }, {
    type: 'mas',
    name: 'MAS Invoice Number',
  }, {
    type: 'ride_order_id',
    name: 'Ride Order ID',
  },
]);
