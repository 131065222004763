import _ from 'lodash';

export default class ProductCodes {
    static getProductCodes() {
        return [
            {
                "product_code": 5001,
                "sub_group": 'Sedan',
                "system_code": 'SedanLoadFee',
                "EBS_item_number": 43048,
                "description": 'LOAD FEE',
                "code": 'A0170',
                "on_medicaid": false,
                "ui_visible": true
            },
            {
                "product_code": 5001,
                "sub_group": 'Sedan',
                "system_code": 'SedanRatePerMile',
                "EBS_item_number": 42934,
                "description": "RATE PER MILE",
                "code": "A0100",
                "on_medicaid": true,
                "ui_visible": false
            },
            {
                "product_code": 5002,
                "sub_group": 'Wheelchair Van',
                "system_code": 'WheelchairLoadFee',
                "EBS_item_number": 42964,
                "description": 'LOAD FEE',
                "code": 'A0130',
                "on_medicaid": true,
                "ui_visible": true
            },
            {
                "product_code": 5002,
                "sub_group": 'Wheelchair Van',
                "system_code": 'WheelchairRatePerMile',
                "EBS_item_number": 42963,
                "description": 'RATE PER MILE',
                "code": 'A0425',
                "on_medicaid": true,
                "ui_visible": false
            },
            {
                "product_code": 5003,
                "sub_group": 'Stretcher',
                "system_code": 'StretcherRatePerMile',
                "EBS_item_number": 42977,
                "description": 'RATE PER MILE',
                "code": 'A0434',
                "on_medicaid": false,
                "ui_visible": true
            },
            {
                "product_code": 5003,
                "sub_group": 'Stretcher',
                "system_code": 'StretcherLoadFee',
                "EBS_item_number": 42978,
                "description": 'LOAD FEE',
                "code": 'T2005',
                "on_medicaid": false,
                "ui_visible": false
            },
            {
                "product_code": 5004,
                "sub_group": 'BLS',
                "system_code": 'BlsLoadFee',
                "EBS_item_number": 42991,
                "description": 'LOAD FEE',
                "code": 'A0428',
                "on_medicaid": true,
                "ui_visible": true
            },
            {
                "product_code": 5004,
                "sub_group": 'BLS',
                "system_code": 'BlsRatePerMile',
                "EBS_item_number": 42990,
                "description": 'RATE PER MILE',
                "code": 'A0380',
                "on_medicaid": true,
                "ui_visible": false
            },
            {
                "product_code": 5005,
                "sub_group": 'ALS',
                "system_code": 'AlsLevel2',
                "EBS_item_number": 91708,
                "description": 'ALS LEVEL 2',
                "code": 'A0433',
                "on_medicaid": true,
                "ui_visible": true
            },
            {
                "product_code": 5005,
                "sub_group": 'ALS',
                "system_code": 'AlsLoadFee',
                "EBS_item_number": 43004,
                "description": 'LOAD FEE',
                "code": 'A0426',
                "on_medicaid": true,
                "ui_visible": false
            },
            {
                "product_code": 5005,
                "sub_group": 'ALS',
                "system_code": 'AlsRatePerMile',
                "EBS_item_number": 43003,
                "description": 'RATE PER MILE',
                "code": 'A0390',
                "on_medicaid": true,
                "ui_visible": false
            },
            {
                "product_code": 2501,
                "sub_group": 'IPS',
                "system_code": '',
                "EBS_item_number": 0,
                "description": '',
                "code": '',
                "on_medicaid": true,
                "ui_visible": true
            },
            {
                "product_code": 2502,
                "sub_group": 'IPCC',
                "system_code": '',
                "EBS_item_number": 0,
                "description": '',
                "code": '',
                "on_medicaid": true,
                "ui_visible": true
            },
            {
                "product_code": 2503,
                "sub_group": 'IPA',
                "system_code": '',
                "EBS_item_number": 0,
                "description": '',
                "code": '',
                "on_medicaid": true,
                "ui_visible": true
            },
            {
                "product_code": 2504,
                "sub_group": 'IPM',
                "system_code": '',
                "EBS_item_number": 0,
                "description": '',
                "code": '',
                "on_medicaid": true,
                "ui_visible": true
            },
            {
                "product_code": 3201,
                "sub_group": 'HA',
                "system_code": '',
                "EBS_item_number": 0,
                "description": '',
                "code": '',
                "on_medicaid": true,
                "ui_visible": true
            },
            {
                "product_code": 3202,
                "sub_group": 'ATA',
                "system_code": '',
                "EBS_item_number": 0,
                "description": '',
                "code": '',
                "on_medicaid": true,
                "ui_visible": true
            },
            {
                "product_code": 3203,
                "sub_group": 'Air Ambulance',
                "system_code": '',
                "EBS_item_number": 0,
                "description": '',
                "code": '',
                "on_medicaid": true,
                "ui_visible": true
            },
            {
                "product_code": 3301,
                "sub_group": 'DT',
                "system_code": '',
                "EBS_item_number": 0,
                "description": '',
                "code": '',
                "on_medicaid": true,
                "ui_visible": false
            },
            {
                "product_code": 3401,
                "sub_group": 'TT',
                "system_code": '',
                "EBS_item_number": 0,
                "description": '',
                "code": '',
                "on_medicaid": true,
                "ui_visible": false
            }
        ];
    }

    static findProductCode(findCondition) {
        if (_.isEmpty(findCondition)) {
            return {};
        }
        const productCodes = this.getProductCodes();
        return _.find(productCodes, findCondition);
    }

    static filterProductCodes(filterCondition) {
        if (_.isEmpty(filterCondition)) {
            return [];
        }
        const productCodes = this.getProductCodes();
        return _.filter(productCodes, filterCondition);
    }

    static filterProductCodesBySubGroupLike(find_sub_group_text){
        if (_.isEmpty(find_sub_group_text)) {
            return [];
        }
        const productCodes = this.getProductCodes();
        const searchPattern = new RegExp('^' + find_sub_group_text, 'i');
        return _.filter(productCodes, code => {
            return searchPattern.test(code.sub_group);
        });
    }
    static filterUniqueProductCodes() {
        const productCodes = this.getProductCodes();
        const resMap = new Map();
        productCodes.map(function(item){
            if(!resMap[item.product_code]) {
                resMap.set(item.product_code, item);
            }
        });
        return [...resMap.values()];
    }

}
