angular.module('relayHealth').factory('pubnubNotificationFactory', [
  'Pubnub',
  '$rootScope',
  '$state',
  'PUBNUB_CIPHER_KEY',
  'ShowBroadcastService',
  function pubnubNotificationFactory(Pubnub,
    $rootScope,
    $state,
    PUBNUB_CIPHER_KEY,
    ShowBroadcastService) {
    let userDetails;
    let channelSubscribed;
    const {
      common: {
        constants: { userRoles },
      },
    } = relaylib;

    if (localStorage.getItem('userDetails')) {
      userDetails = JSON.parse(localStorage.getItem('userDetails'));
    }

    let pubnubUuid = '';
    if (userDetails !== undefined) {
      pubnubUuid = `${userDetails.user_token}:${userDetails.user_id}`;
    } else {
      channelSubscribed = false;
    }

    function subscribeUserIdChannel() {
      const ud = JSON.parse(localStorage.getItem('userDetails'));
      // Creating OrgId Channel for sending Org specific push
      const channelsList = [`${ud.user_id}`];
      if (ud.organisation && ud.organisation.org_level !== 1) {
        channelsList.push(`org_id_channel_${ud.organisation.id}`);
      }
      if (ud) {
        Pubnub.subscribe({
          channels: [channelsList],
          triggerEvents: ['message'],
          uuid: ud.id,
        });
      }
    }

    function countChannel(event) {
      const ud = JSON.parse(localStorage.getItem('userDetails'));
      if (ud) {
        // Subscribing channel to receive counts.
        let operationTeams = [];
        if (ud.operations_teams.length > 0) {
          operationTeams = ud.operations_teams.map(val => `op_team_${val.operations_team_id}`);
        }
        let channels = [];
        const masterRoles = [userRoles.MASTER_SUPER_ADMIN, userRoles.PRODUCTION_OPERATION];
        const otherRoles = [userRoles.SCHEDULING_ADMIN, userRoles.RIDE_MANAGER, userRoles.SUPER_ADMIN, userRoles.NETWORK_OPERATION];
        const isMasterRole = masterRoles.includes(ud.role);
        const isOtherRole = otherRoles.includes(ud.role);
        const operationsTeamCheck = operationTeams && operationTeams.length > 0;
        if (isMasterRole) {
          channels.push('all_count');
        } else if (operationsTeamCheck && isOtherRole) {
          channels = channels.concat(operationTeams);
        } else {
          channels.push(`org_id_${ud.org_id}`);
        }

        if (event === 'subscribe') {
          Pubnub.subscribe({
            channels: [channels],
            triggerEvents: ['message'],
          });
          // Subscribe channel to receive online admin count
          if ([userRoles.MASTER_SUPER_ADMIN, userRoles.SUPER_ADMIN, userRoles.PRODUCTION_OPERATION].includes(ud.role)) {
            Pubnub.subscribe({
              channels: ['online_admins_channel'],
              triggerEvents: ['message'],
              withPresence: true,
              heartbeatInterval: 30,
            });
          }
        } else {
          channels.push('online_admins_channel');
          Pubnub.unsubscribe({
            channels: [channels],
          });
        }
        // End
      }
    }

    function setUUID() {
      const ud = JSON.parse(localStorage.getItem('userDetails'));
      if (ud) {
        const uuid = `${ud.user_token}:${ud.user_id}`;
        Pubnub.setUUID(uuid);
      }
    }

    function subscribeChannel(channel) {
      Pubnub.subscribe({
        channels: [channel],
        triggerEvents: ['message'],
      });
    }

    function subscribeChannelGroup(channelGroup) {
      Pubnub.subscribe({
        channelGroups: [channelGroup],
        triggerEvents: ['message'],
      });
    }

    function unsubscribeUserIdChannel(userId) {
      const ud = JSON.parse(localStorage.getItem('userDetails'));
      channelSubscribed = false;
      const udId = userId || (ud && ud.userId);
      const channelsList = [udId];
      if (ud.organisation && ud.organisation.org_level !== 1) {
        channelsList.push(`org_id_channel_${ud.organisation.id}`);
      }
      if (udId) {
        Pubnub.unsubscribe({
          channels: [channelsList],
        });
      }
    }

    function unsubscribeChannel(channel) {
      Pubnub.unsubscribe({
        channels: [channel],
      });
    }

    function unsubscribeChannelGroup(channelGroup) {
      Pubnub.unsubscribe({
        channelGroups: [channelGroup],
      });
    }

    function unSubscribePresenceChannel() {
      Pubnub.unsubscribe({
        channels: ['presence_channel'],
      });
      channelSubscribed = false;
    }

    function unsubscribeAllChannel() {
      Pubnub.unsubscribeAll();
      unSubscribePresenceChannel();
    }

    function addChannelsToGroup(channels, channelGroup) {
      Pubnub.channelGroups.addChannels(
        {
          channels,
          channelGroup,
        },
        () => {
          //
        },
      );
    }

    function deleteChannelGroup(channelGroup) {
      Pubnub.channelGroups.deleteGroup(
        {
          channelGroup,
        },
        () => {
          //
        },
      );
    }

    function getChannelNameForRole(role) {
      let channelName;
      if ([userRoles.MASTER_SUPER_ADMIN, userRoles.SUPER_ADMIN, userRoles.SCHEDULING_ADMIN, userRoles.PRODUCTION_OPERATION].includes(role)) {
        channelName = 'msa_presence_channel';
      } else if (['org_superadmin', 'orgadmin'].includes(role)) {
        channelName = 'oa_presence_channel';
      } else if (role === userRoles.RIDE_MANAGER) {
        channelName = 'presence_channel';
      } else if (role === userRoles.NETWORK_OPERATION) {
        channelName = 'no_presence_channel';
      } else {
        channelName = '';
      }
      return channelName;
    }

    function setChannelState(channel, state, callback) {
      Pubnub.setState(
        {
          state,
          channels: [channel],
        },
        callback,
      );
    }

    // Function to get details of here now of some channel
    function getHereNowOfChannel(channel, callback) {
      Pubnub.hereNow(
        {
          channels: [channel],
          includeUUIDs: true,
          includeState: true,
        },
        callback,
      );
    }

    function subscribePresenceChannel(custom, state) {
      const ud = JSON.parse(localStorage.getItem('userDetails'));
      if (!custom) {
        const channelName = getChannelNameForRole(ud.role);
        if (channelName) {
          Pubnub.subscribe({
            channels: [channelName],
            withPresence: true,
            heartbeatInterval: 30,
            uuid: ud.id,
            triggerEvents: ['message'],
          });
        }
      } else if (custom) {
        // Added case for subscibing to custom presence channels (with option of setting state)
        Pubnub.subscribe({
          channels: [custom],
          withPresence: true,
          heartbeatInterval: 30,
          uuid: ud.id,
        });
        if (state) {
          setChannelState(custom, state, () => {
            getHereNowOfChannel(custom);
          });
        }
      }
    }

    function getHereNowOfChannels(channels, callback) {
      if (Array.isArray(channels)) {
        Pubnub.hereNow(
          {
            channels,
            includeUUIDs: true,
            includeState: true,
          },
          callback,
        );
      } else {
        throw new Error('Parameter (channels) should be array of channels');
      }
    }

    function subscribeOnlineChannel() {
      const ud = JSON.parse(localStorage.getItem('userDetails'));
      const checkRoles = [userRoles.SCHEDULING_ADMIN, userRoles.RIDE_MANAGER, userRoles.SUPER_ADMIN, userRoles.NETWORK_OPERATION, userRoles.ORG_ADMIN];
      if (checkRoles.includes(ud.role)) {
        Pubnub.subscribe({
          channels: ['online_channel'],
          withPresence: true,
          heartbeatInterval: 30,
        });
      }
    }

    function showDesktopNotification(obj) {
      if (!window.Notification) {
        // eslint-disable-next-line no-alert
        window.alert('Desktop notifications not available in your browser. Try Chromium.');
        return;
      }
      if (window.Notification.permission !== 'granted') {
        window.Notification.requestPermission();
      } else {
        if (obj.type === 'refresh_issues' || !obj.message) {
          return;
        }

        const notification = new window.Notification('Relay Transport', {
          icon: 'img/favicon-32x32.png',
          body: obj.message
            .replace(/Patient/g, 'Rider')
            .replace(/patient/g, 'rider')
            .replace(/Appointment/g, 'Trip')
            .replace(/appointment/g, 'trip'),
          data: {
            ride_id: obj.ride_id,
            type: obj.type,
            user_id: obj.user_id,
            appt_id: obj.appt_id,
          },
          tag: 'relayNotification',
        });

        const audio = new window.Audio('../sounds/Ping.mp3');
        audio.play();

        notification.onclick = function onclick() {
          const user = JSON.parse(localStorage.getItem('userDetails'));
          if (!user || !obj.ride_id) {
            return;
          }

          let state = 'main.dashboard';
          if ([userRoles.MASTER_SUPER_ADMIN, userRoles.SUPER_ADMIN].includes(user.role)) {
            state = 'main.dashboard.superAdminDashboard';
          }

          const rideModalAllowed = [
            'late arrival',
            'driver canceled',
            'no driver available',
            'failed cc payment',
            'failed cc auth',
            'failed-other',
            'request overdue',
            'patient no show',
          ];

          if (rideModalAllowed.indexOf(obj.type.toLowerCase()) > -1) {
            notification.data.displayRideModal = true;
          } else if (obj.type.toLowerCase() === 'potential unavailability') {
            notification.data.displayRideModal = false;
            // redirect to edit page
          } else {
            notification.data.displayRideModal = false;
          }
          const url = $state.href(state, { opentab: 'pendingApproval' });
          localStorage.setItem('notification', JSON.stringify(notification.data));
          window.location.href = url;
          window.focus();
          $rootScope.$broadcast('decrementUnreadNotificationCount');
          $rootScope.$apply();
          notification.close();
        };
      }
    }

    const service = {
      subscribeChannel,
      showDesktopNotification,
      subscribeUserIdChannel,
      unsubscribeUserIdChannel,
      unsubscribeChannel,
      unsubscribeAllChannel,
      addChannelsToGroup,
      deleteChannelGroup,
      subscribeChannelGroup,
      unsubscribeChannelGroup,
      subscribePresenceChannel,
      getHereNowOfChannel,
      subscribeOnlineChannel,
      unSubscribePresenceChannel,
      setUUID,
      countChannel,
      getHereNowOfChannels,
    };
    function pubNubInit(pubNubDetail) {
      const options = {
        uuid: pubnubUuid,
        ssl: document.location.protocol === 'https:',
        // presenceTimeout: 120
      };
      if (pubNubDetail) {
        if (pubNubDetail.auth_key) {
          options.authKey = pubNubDetail.auth_key;
        }
        if (pubNubDetail.pubnub) {
          options.publishKey = pubNubDetail.pubnub.publishKey;
          options.subscribeKey = pubNubDetail.pubnub.subscribeKey;
        }
      }
      if (PUBNUB_CIPHER_KEY) { options.cipherKey = PUBNUB_CIPHER_KEY; }
      Pubnub.init(options);
    }
    if (userDetails) {
      pubNubInit(userDetails);
    } else {
      pubNubInit();
    }

    const subscribeBroadCastChannelAndRegisterForNotifications = () => {
      subscribeChannel(relaylib.broadcasts.broadcastChannelConstants.PUBLIC_CHANNEL); // this is required when user logins
      $rootScope.$on(Pubnub.getMessageEventNameFor(relaylib.broadcasts.broadcastChannelConstants.PUBLIC_CHANNEL), (rootScopeEvent, payload) => {
        ShowBroadcastService.setBroadcast(payload.message);
      });
    };

    $rootScope.$on('userLogin', (event, data) => {
      pubNubInit(data);
      /* when user is logged out and logs in, the channel needs to be subscribed and
        then register the rootscope event for PubNub Notification
      This is because the subscribe channels has'nt been executed when user is logged out
      and reverse process (register event and then subscribe will not work)
      */
      if (!channelSubscribed) {
        subscribeBroadCastChannelAndRegisterForNotifications();
      }
      ShowBroadcastService.init();
    });
    $rootScope.$on('trackingPage', (event, pubnubDetail) => {
      if (!userDetails) {
        pubNubInit(pubnubDetail);
      }
    });
    if (userDetails && !channelSubscribed) {
      setUUID();
      // subscribe channel will not be executed if user is logged out
      subscribeBroadCastChannelAndRegisterForNotifications();
      subscribePresenceChannel();
      subscribeOnlineChannel();
      subscribeUserIdChannel();
      channelSubscribed = true;
    }

    return service;
  },
]);
