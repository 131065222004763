angular.module('relayHealth').controller('manageMarketSegmentCntrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  '$state',
  '$stateParams',
  'toaster',
  'SweetAlert',
  'loadingScreenFactory',
  '$window',
  '$timeout',
  'DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID',
  'getMarketSegementSetting',
  'permissionFilter',
  'AUTO_ASSIGN_SETTINGS',
  'dosOverlap',
  function manageMarketSegmentCntrl(
    $scope,
    $http,
    API_BASE_URL,
    $state,
    $stateParams,
    toaster,
    SweetAlert,
    loadingScreenFactory,
    $window,
    $timeout,
    DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID,
    getMarketSegementSetting,
    permissionFilter,
    AUTO_ASSIGN_SETTINGS,
    dosOverlap,
  ) {
    $scope.globalSettings = {};
    $scope.validateDos = false;
    $scope.emptyDosValues = false;
    $scope.dosPattern = /^[0-9]\d*$/;
    $scope.addDosExpiration = {};
    let prevDosValue;
    const { marketSegmentId } = $stateParams;
    const editToggle = $stateParams.edit;
    $scope.permissionsConstants = relaylib.permissions.permissionsConstants;
    $scope.dropdownOpen = false;
    $scope.occmId = DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID;

    let marketSegmentDetails;
    $scope.forms = {};
    $scope.disableForm = true;

    $scope.getMarketSegementSetting = getMarketSegementSetting;

    $scope.helpEmailOptions = [];
    $scope.helpPhoneOptions = [];
    $scope.operationsTeam = [];
    $scope.billingMethodList = [
      {
        name: 'Oracle',
        value: 'oracle',
      },
    ];

    function getAllHelpOptions() {
      const getAllHelpOptionsCall = $http({
        url: `${API_BASE_URL}helpcontacts`,
        method: 'GET',
      });

      getAllHelpOptionsCall.then(
        (result) => {
          if (result.data.success && result.data.data && result.data.data.length > 0) {
            $scope.helpEmailOptions = [];
            $scope.helpPhoneOptions = [];
            let i;
            for (i = 0; i < result.data.data.length; i += 1) {
              if (result.data.data[i].type === 'phone') {
                $scope.helpPhoneOptions.push(result.data.data[i]);
              } else if (result.data.data[i].type === 'email') {
                $scope.helpEmailOptions.push(result.data.data[i]);
              }
            }
          } else {
            toaster.pop({
              type: 'error',
              title: 'Unable to fetch help email or help phone number list',
              showCloseButton: true,
              timeout: 10000,
            });
          }
        },
        (error) => {
          toaster.pop({
            type: 'error',
            title: error.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    }
    getAllHelpOptions();

    function getOperationsTeam() {
      const getOperationsTeamCall = $http({
        url: `${API_BASE_URL}operationsteams`,
        method: 'GET',
      });

      getOperationsTeamCall.then(
        (result) => {
          if (result.data.success && result.data.data && result.data.data.length > 0) {
            $scope.operationsTeam = result.data.data;
          } else {
            $scope.operationsTeam = [];
            toaster.pop({
              type: 'error',
              title: 'Unable to fetch operations team',
              showCloseButton: true,
              timeout: 10000,
            });
          }
        },
        (error) => {
          $scope.operationsTeam = [];
          toaster.pop({
            type: 'error',
            title: error.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    }
    getOperationsTeam();
    const getAutoAssignSettings = async (requiredSettings = []) => {
      loadingScreenFactory.showLoadingScreen();
      const params = { setting_name: requiredSettings.join(',') };
      let autoAssignSettings = {};
      try {
        const getAutoAssignSettingsCall = await $http({
          url: `${API_BASE_URL}setting/default`,
          method: 'GET',
          params,
        });
        if (getAutoAssignSettingsCall.data && getAutoAssignSettingsCall.data.success) {
          $scope.globalSettings = getAutoAssignSettingsCall.data.settings;
          if (Object.keys($scope.globalSettings).length) {
            autoAssignSettings = $scope.globalSettings;
          }
        }
      } catch (err) {
        const message = err.data && err.data.message ? err.data.message : err.message;
        toaster.pop({
          type: 'error',
          title: message,
          showCloseButton: true,
          timeout: 10000,
        });
      } finally {
        loadingScreenFactory.hideLoadingScreen();
      }
      return autoAssignSettings;
    };
    $scope.onAutoAssignFieldChange = (settingName) => {
      if ($scope.globalSettings[settingName]) { delete $scope.globalSettings[settingName]; }
    };
    $scope.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    $scope.editDos = (index) => {
      if ($scope.disableForm) {
        return;
      }
      if ($scope.addDosExpiration.$$hashKey && prevDosValue) {
        $scope.marketSegmentDetails.setting.dos_offer_expiry.push({ ...prevDosValue });
      }
      $scope.addDosExpiration = { ...$scope.marketSegmentDetails.setting.dos_offer_expiry[index] };
      prevDosValue = { ...$scope.marketSegmentDetails.setting.dos_offer_expiry[index] };
      $scope.onDosConditionChange();
      $scope.marketSegmentDetails.setting.dos_offer_expiry.splice(index, 1);
    };
    $scope.clearDos = () => {
      if ($scope.disableForm) {
        return;
      }
      if ($scope.addDosExpiration.$$hashKey) {
        $scope.marketSegmentDetails.setting.dos_offer_expiry.push({ ...prevDosValue });
      }
      $scope.addDosExpiration = {};
      $scope.addDosExpiration.expiry = '';
      $scope.addDosExpiration.dos = '';
      $scope.addDosExpiration.topProvidersValue = '';
      $scope.forms.marketSegmentEdit.$setUntouched();
      $scope.forms.marketSegmentEdit.$setPristine();
      $scope.onDosConditionChange();
    };

    $scope.deleteDos = (index) => {
      if ($scope.disableForm) {
        return;
      }
      $scope.marketSegmentDetails.setting.dos_offer_expiry.splice(index, 1);
      $scope.onDosConditionChange();
      $scope.onAutoAssignFieldChange('dos_offer_expiry');
    };
    $scope.onDosConditionChange = () => {
      $scope.validateDos = false;
      $scope.emptyDosValues = false;
      $scope.dosPlaceholder = 'e.g. 5';
      $scope.dosPattern = /^[0-9]\d*$/;
      if ($scope.addDosExpiration && $scope.addDosExpiration.check === 'between') {
        $scope.dosPlaceholder = 'e.g. 5-10';
        $scope.dosPattern = /^[0-9]+-[0-9]+$/;
      }
    };
    $scope.addDosAndExpiry = () => {
      $scope.validateDos = dosOverlap.checkValidity($scope.marketSegmentDetails.setting.dos_offer_expiry, $scope.addDosExpiration);
      if ($scope.validateDos || $scope.disableForm) {
        return;
      }
      if (
        !($scope.addDosExpiration.check
        && $scope.addDosExpiration.dos
        && $scope.addDosExpiration.dosUnit
        && $scope.addDosExpiration.expiry
        && $scope.addDosExpiration.expiryUnit
        && $scope.addDosExpiration.topProvidersValue
        )
      ) {
        $scope.emptyDosValues = true;
        return;
      }
      if ($scope.addDosExpiration.check === 'between') {
        const values = $scope.addDosExpiration.dos.split('-');
        if (Number(values[0]) > Number(values[1])) {
          $scope.wrongValues = true;
          return;
        }
      }
      if ($scope.addDosExpiration.$$hashKey) {
        let found;
        $scope.marketSegmentDetails.setting.dos_offer_expiry.forEach((dosSetting, index) => {
          if (dosSetting.$$hashKey === $scope.addDosExpiration.$$hashKey) {
            found = true;
            $scope.marketSegmentDetails.setting.dos_offer_expiry[index] = { ...$scope.addDosExpiration };
            $scope.addDosExpiration = {};
          }
        });
        if (!found) {
          $scope.marketSegmentDetails.setting.dos_offer_expiry.push({ ...$scope.addDosExpiration });
          $scope.addDosExpiration = {};
        }
      } else {
        $scope.marketSegmentDetails.setting.dos_offer_expiry.push({ ...$scope.addDosExpiration });
        $scope.addDosExpiration = {};
      }
      $scope.onDosConditionChange();
      $scope.onAutoAssignFieldChange('dos_offer_expiry');
    };
    // (if edit button clicked and role is correct do following)
    if (
      editToggle
      && $scope.userDetails
      && ($scope.userDetails.role === 'mastersuperadmin' || permissionFilter($scope.permissionsConstants.MARKET_SEGMENT_UPDATE))
    ) {
      $scope.disableForm = false;
    }
    // do end

    function getMarketSegmentDetailsById() {
      if (!marketSegmentId) {
        $state.go('main.marketSegment.marketSegmentList');
        return;
      }
      $http
        .get(`${API_BASE_URL}marketSegments/${marketSegmentId}`)
        .then(async (result) => {
          if (result.data.success) {
            $scope.marketSegmentDetails = result.data.data;
            $scope.marketSegmentDetails.setting = {};
            $scope.marketSegmentDetails.setting.pay_invoice = getMarketSegementSetting($scope.marketSegmentDetails, 'pay_invoice', 'bool');
            $scope.marketSegmentDetails.setting.pickup_time_allowed = getMarketSegementSetting($scope.marketSegmentDetails, 'pickup_time_allowed', 'bool');
            $scope.marketSegmentDetails.setting.variance = getMarketSegementSetting($scope.marketSegmentDetails, 'variance', 'bool');
            $scope.marketSegmentDetails.setting.variance_under = getMarketSegementSetting($scope.marketSegmentDetails, 'variance_under', 'bool');
            $scope.marketSegmentDetails.setting.variance_over = getMarketSegementSetting($scope.marketSegmentDetails, 'variance_over', 'bool');
            $scope.marketSegmentDetails.setting.variance_config = getMarketSegementSetting($scope.marketSegmentDetails, 'variance_config');
            $scope.marketSegmentDetails.setting.variance_under_config = getMarketSegementSetting($scope.marketSegmentDetails, 'variance_under_config');
            $scope.marketSegmentDetails.setting.variance_over_config = getMarketSegementSetting($scope.marketSegmentDetails, 'variance_over_config');
            $scope.marketSegmentDetails.setting.steerage = getMarketSegementSetting($scope.marketSegmentDetails, 'steerage', 'bool');
            $scope.marketSegmentDetails.setting.edit_receipt = getMarketSegementSetting($scope.marketSegmentDetails, 'edit_receipt', 'bool');
            if ($scope.marketSegmentDetails.key === 'GHT') {
              const autoAssignSettings = {};
              const notFoundAutoAssignSettings = [];
              const autoAssignSettingsName = AUTO_ASSIGN_SETTINGS.ALL_AUTO_ASSIGN_SETTINGS;
              autoAssignSettingsName.forEach((setting) => {
                const settingValue = getMarketSegementSetting($scope.marketSegmentDetails, setting.settingName, setting.type);
                if (settingValue === undefined
                  || settingValue === null || settingValue === '') {
                  notFoundAutoAssignSettings.push(setting.settingName);
                }
                autoAssignSettings[setting.settingName] = settingValue;
              });

              Object.assign($scope.marketSegmentDetails.setting, autoAssignSettings);
              if (notFoundAutoAssignSettings.length) {
                const settings = await getAutoAssignSettings(notFoundAutoAssignSettings);
                Object.assign($scope.marketSegmentDetails.setting, settings);
              }
              marketSegmentDetails = angular.copy($scope.marketSegmentDetails);
              if (!$scope.marketSegmentDetails.setting.dos_offer_expiry) {
                $scope.marketSegmentDetails.setting.dos_offer_expiry = [];
              } else if (typeof $scope.marketSegmentDetails.setting.dos_offer_expiry === 'string') {
                $scope.marketSegmentDetails.setting.dos_offer_expiry = JSON.parse($scope.marketSegmentDetails.setting.dos_offer_expiry);
              }
            }
          } else {
            toaster.pop({
              type: 'error',
              title: result.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        })
        .catch((error) => {
          toaster.pop({
            type: 'error',
            title: error.data ? error.data.message : error.message,
            showCloseButton: true,
            timeout: 10000,
          });
        });
    }

    getMarketSegmentDetailsById();

    $scope.editDetailsClicked = () => {
      $scope.disableForm = false;
    };

    $scope.cancelClicked = () => {
      // (if cancel button clicked and role is correct do following)
      if (
        editToggle
        && $scope.userDetails
        && ($scope.userDetails.role === 'mastersuperadmin' || permissionFilter($scope.permissionsConstants.MARKET_SEGMENT_READ))
      ) {
        $window.history.back();
      } else {
        $scope.marketSegmentDetails = angular.copy(marketSegmentDetails);
        $scope.disableForm = true;
        $scope.forms.marketSegmentEdit.submitted = false;
      }
      // do end
    };

    $scope.saveChangesClicked = () => {
      if ($scope.forms.marketSegmentEdit && $scope.forms.marketSegmentEdit.$valid) {
        const serverData = {
          id: $scope.marketSegmentDetails.id,
          name: $scope.marketSegmentDetails.name,
          help_phone_id: $scope.marketSegmentDetails.help_phone_id,
          help_email_id: $scope.marketSegmentDetails.help_email_id,
          billing_method: $scope.marketSegmentDetails.billing_method,
          operations_team_id: $scope.marketSegmentDetails.operations_team_id,
          special_ride: $scope.marketSegmentDetails.special_ride,
          mileage_tracker: $scope.marketSegmentDetails.mileage_tracker,
          language_access: $scope.marketSegmentDetails.language_access,
          questionnaries: $scope.marketSegmentDetails.questionnaries,
          cutomizable_file_number: $scope.marketSegmentDetails.cutomizable_file_number,
          ride_invoice_history: $scope.marketSegmentDetails.ride_invoice_history,
          setting: $scope.marketSegmentDetails.setting,
          billing_template_id: $scope.marketSegmentDetails.billing_template_id,
        };
        if ($scope.marketSegmentDetails.key === 'GHT') {
          AUTO_ASSIGN_SETTINGS.ALL_AUTO_ASSIGN_SETTINGS
            .forEach((setting) => {
              const { settingName, editable, dependent } = setting;
              if (serverData.setting[settingName] === '' || (serverData.setting[settingName] && typeof serverData.setting[settingName] !== 'number' && !serverData.setting[settingName].length)) {
                serverData.setting[settingName] = null;
              }
              if (dependent && (serverData.setting[dependent] === '' || serverData.setting[dependent] === null)) {
                serverData.setting[settingName] = null;
              }
              if (!editable || $scope.globalSettings[settingName]) {
                delete serverData.setting[settingName];
              }
            });
        }
        loadingScreenFactory.showLoadingScreen();
        $http
          .put(`${API_BASE_URL}marketSegments`, serverData)
          .then((result) => {
            $scope.forms.marketSegmentEdit.submitted = false;
            if (result.data.success) {
              SweetAlert.swal(
                {
                  title: 'Changes Saved',
                  text: 'Market Segment edited successfully',
                  type: 'success',
                  confirmButtonColor: '#DD6B55',
                  confirmButtonText: 'OK',
                  closeOnConfirm: true,
                },
                (isConfirm) => {
                  if (isConfirm) getMarketSegmentDetailsById();
                  $scope.disableForm = true;
                },
              );
            } else {
              loadingScreenFactory.hideLoadingScreen();
              const errorMessage = result.data.message;
              if (typeof errorMessage === 'object') {
                Object.keys(errorMessage).forEach(key => toaster.pop({
                  type: 'error',
                  title: errorMessage[key],
                  showCloseButton: true,
                  timeout: 10000,
                }));
              } else if (typeof errorMessage === 'string') {
                toaster.pop({
                  type: 'error',
                  title: errorMessage,
                  showCloseButton: true,
                  timeout: 10000,
                });
              }
            }
          })
          .catch((error) => {
            toaster.pop({
              type: 'error',
              title: error.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          })
          .finally(() => {
            loadingScreenFactory.hideLoadingScreen();
          });
      } else {
        $scope.forms.marketSegmentEdit.submitted = true;
      }
    };
  },
]);
