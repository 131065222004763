angular.module('relayHealth').controller('userListCntrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  '$stateParams',
  'toaster',
  'SweetAlert',
  'loadingScreenFactory',
  '$state',
  '$timeout',
  '$location',
  'org_constants',
  'isMasterOrgFilter',
  'permissionFilter',
  'displayRoleFilter',
  'storageManager',
  '$filter',
  'WORKERS_COMPENSATION_KEY',
  'HEALTHCARE_MASS_MARKET_KEY',
  function userListCntrl(
    $scope,
    $http,
    API_BASE_URL,
    $stateParams,
    toaster,
    SweetAlert,
    loadingScreenFactory,
    $state,
    $timeout,
    $location,
    orgConstants,
    isMasterOrgFilter,
    permissionFilter,
    displayRoleFilter,
    storageManager,
    $filter,
    WORKERS_COMPENSATION_KEY,
    HEALTHCARE_MASS_MARKET_KEY,
  ) {
    const { storage } = storageManager;
    const currentState = $state.current.name;
    $scope.permissionsConstants = relaylib.permissions.permissionsConstants;
    $scope.org_constants = orgConstants;
    const { userRoles } = relaylib.common.constants;
    const { orgId } = $stateParams;

    $scope.breadcrumbs = [
      {
        label: 'User Management',
      },
    ];

    $scope.addUser = {};
    $scope.signedInUser = JSON.parse(localStorage.getItem('userDetails'));
    const ROLES = relaylib.common.constants.userRoles;
    $scope.visibleOrgAlertChoices = 20;
    $scope.addUser.linkedOrgs = [
      {
        organisation: null,
        org_id: null,
        role: null,
        access_type: null,
        operations_team: null,
        default: true,
        accessTypeList: $scope.accessTypeList,
        assignedOrgAlertsList: [],
        assignedOrgAlerts: [],
      },
      /**
       * {
       *      organisation: {}
       *      org_id: 1,
       *      role: '',
       *      access_type: '',
       *      operation_team: '',
       *      default: false
       * }
       */
    ];
    $scope.addUser.selectedOrgs = [0];

    $scope.setDefaultOrg = (selectedIndex) => {
      $scope.addUser.linkedOrgs.forEach((item, index) => {
        if (selectedIndex === index) {
          Object.assign(item, { default: true });
        } else {
          Object.assign(item, { default: false });
        }
      });
    };

    $scope.showAddUserForm = false;
    $scope.showInvitedUsers = true;
    $scope.searchFormQuery = {};
    $scope.tempSearchFormQuery = {};
    $scope.userListQuery = {};
    $scope.tempUserListQuery = {};
    $scope.accessTypeList = [
      { type: 'admin' },
      { type: 'superadmin' },
      { type: 'mastersuperadmin' },
      { type: userRoles.PRODUCTION_OPERATION },
      { type: userRoles.NETWORK_OPERATION },
      { type: userRoles.OC_REPORTING },
    ];
    $scope.orgDisabled = !!orgId;
    $scope.mainContact = {};
    if ($location.search().table_page === true || $location.search().table_page === undefined || Number.isNaN($location.search().table_page)) {
      $location.search('table_page', 1);
    }
    $scope.statusList = [
      {
        id: 0,
        name: 'active',
      },
      {
        id: 1,
        name: 'inactive',
      },
    ];
    $scope.pagination = {
      totalItems: 0,
      currentPage: 1,
      lastItem: 0,
      itemsPerPage: 20,
    };
    $scope.tempPagination = {
      totalItems: 0,
      currentPage: 1,
      lastItem: 0,
      itemsPerPage: 20,
    };
    $scope.createAddUserForm = true;
    $scope.orgName = '';
    $scope.operationsTeam = [];

    const getOperationsTeam = () => {
      const getOperationsTeamCall = $http({
        url: `${API_BASE_URL}operationsteams`,
        method: 'GET',
      });

      getOperationsTeamCall.then(
        (result) => {
          if (result.data.success && result.data.data && result.data.data.length > 0) {
            $scope.operationsTeam = result.data.data;
          } else {
            $scope.operationsTeam = [];
            toaster.pop({
              type: 'error',
              title: 'Unable to fetch operations team',
              showCloseButton: true,
              timeout: 10000,
            });
          }
        },
        (error) => {
          $scope.operationsTeam = [];
          toaster.pop({
            type: 'error',
            title: error.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    };
    getOperationsTeam();
    $scope.pageChanged = (newPage) => {
      $location.search('table_page', newPage);
    };
    $scope.userDetails = JSON.parse(localStorage.getItem('userDetails'));

    $scope.validatePhoneNumber = () => {
      const phoneNo = $('#phoneNo').intlTelInput('getNumber', window.intlTelInputUtils.numberFormat.E164);
      return window.intlTelInputUtils.isValidNumber(phoneNo);
    };

    function writeSearchFilter() {
      const prevSearchValues = storage.getDataByKey('keepFilterData', currentState);
      if (!prevSearchValues) {
        return;
      }
      const prevForm = prevSearchValues.data;
      if (prevSearchValues.key === 'users') {
        $scope.userListQuery.userName = prevForm.name;
        $scope.userListQuery.email = prevForm.username;
        $scope.userListQuery.phone = prevForm.phone;
        $scope.userListQuery.organisation = prevSearchValues.org;
        [$scope.userListQuery.is_del] = $scope.statusList.filter(obj => obj.id === prevForm.is_del);

        // fetch list of access type for selected org when user come back from detail view
        $scope.orgFilterChange($scope.userListQuery.organisation);

        $scope.userListQuery.operations_team_id = prevForm.operations_team_id;
        if (prevForm.role) {
          [$scope.userListQuery.accessType] = $scope.accessTypeFilterList.filter(obj => obj.type.toLowerCase() === prevForm.role.toLowerCase());
        }
      } else {
        $scope.tempUserListQuery.userName = prevForm.name;
        $scope.tempUserListQuery.email = prevForm.email;
        $scope.tempUserListQuery.phone = prevForm.phone;
        $scope.tempUserListQuery.organisation = prevSearchValues.org;
        $scope.tempUserListQuery.accessType = prevForm.role;
      }
    }

    $scope.canceClicked = (form) => {
      if (form) {
        form.$setUntouched();
        form.$setPristine();
      }
      const addUserCopy = angular.copy($scope.addUser);
      $scope.addUser.id = '';
      $scope.addUser.userName = '';
      $scope.addUser.userEmail = '';
      $scope.addUser.userPhone = '';
      $scope.addUser.firstName = '';
      $scope.addUser.lastName = '';
      $scope.addUser.accessType = '';
      $scope.addUser.organisation = '';
      $scope.showAddUserForm = false;
      $scope.addUser.autoVerify = false;
      if (orgId) {
        $scope.addUser.organisation = addUserCopy.organisation;
        $scope.orgChange({
          id: orgId,
          org_class: addUserCopy.organisation && addUserCopy.organisation.org_class,
          org_level: addUserCopy.organisation && addUserCopy.organisation.org_level,
          market_segment: addUserCopy.organisation && addUserCopy.organisation.market_segment,
        });
      } else {
        $scope.accessTypeList = [];
      }
    };

    const showToaster = (toasterType, message, customMessage) => {
      toaster.clear();
      const type = ['info', 'error', 'success'].indexOf(toasterType) > -1 ? toasterType : 'error';
      toaster.pop({
        type,
        title: message || customMessage,
        showCloseButton: true,
        timeout: 10000,
      });
    };

    $scope.getAllOrganizations = function getAllOrganizations() {
      const params = {};
      if ($scope.userDetails.role === userRoles.ORG_SUPERADMIN && typeof orgId === 'undefined') {
        params.displayOrgChildren = true;
      }
      return $http.get(`${API_BASE_URL}organisation/getOrganizations/active/-1000/-1000`, { params }).then((resp) => {
        let response = resp.data;
        response = (response.org_details || []).sort((a, b) => a.name.localeCompare(b.name));
        return {
          data: response,
          count: resp.data.totalCount,
        };
      });
    };

    const clearUser = () => {
      Object.assign($scope.addUser, {
        id: '',
        firstName: '',
        lastName: '',
        userPhone: '',
        userEmail: '',
        accessType: {},
        operations_team: '',
      });
      $scope.formReadOnly = '';
    };

    let inProcess = false;
    $scope.getUserDetails = async (email) => {
      let userorgId = orgId;
      if (!userorgId && $scope.userDetails.role === userRoles.ORG_SUPERADMIN) {
        ({ org_id: userorgId } = $scope.userDetails);
      }
      if (!(email && userorgId)) { return; }
      try {
        inProcess = true;
        const result = await $http.get(`${API_BASE_URL}user/detailsByEmailAndOrg/${email}/${userorgId}`);
        const {
          data: {
            success,
            userDetails: {
              id: userId,
              first_name: firstName,
              last_name: lastName,
              phone_verified: phoneVerified,
              phone = '',
            } = {},
          },
        } = result;
        if (!success) {
          return;
        }

        if (!phoneVerified) {
          // User belongs to current or some other Organisation
          showToaster('error', 'User belongs to current or some other organisation', '');
          clearUser();
          $scope.$apply();
          return;
        }
        SweetAlert.swal({
          title: 'This User already exists.',
          text: 'Do you wish to add this user to current organization?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Ok',
        },
        (isConfirm) => {
          if (isConfirm) {
            Object.assign($scope.addUser, {
              id: userId,
              firstName,
              lastName,
              userPhone: phone ? phone.replace(/[- )(]/g, '').slice(-10) : '',
            });
            $scope.formReadOnly = 'readonly';
          } else {
            clearUser();
          }
        });
      } catch (error) {
        showToaster('error', error.data ? error.data.message : 'Could not fetch user details', '');
      } finally {
        inProcess = false;
      }
    };

    $scope.onOrgFetchComplete = (list) => {
      $scope.orgList = list;
      writeSearchFilter();
    };

    const getSelectedOrgDetails = () => {
      const getSelectedOrgDetailsCall = $http({
        url: `${API_BASE_URL}organisation/getOrgDetailsById/${orgId}`,
        method: 'GET',
      });
      getSelectedOrgDetailsCall.then(
        (result) => {
          if (result.data.success) {
            $scope.addUser.linkedOrgs[0].organisation = result.data.orgDetails;
            $scope.addUser.organisation = result.data.orgDetails;
            $scope.userListQuery.organisation = result.data.orgDetails;
            $scope.tempUserListQuery.organisation = result.data.orgDetails;
            if (result.data.orgDetails.is_del) {
              $scope.createAddUserForm = false;
            }

            $scope.orgName = result.data.orgDetails.org_class === orgConstants.RIDER_ORG_CLASS
              ? (result.data.orgDetails.user && result.data.orgDetails.user.first_name) || ''
              : result.data.orgDetails.name;
            $scope.orgFilterChange({
              id: orgId,
              org_class: result.data.orgDetails.org_class,
              org_level: result.data.orgDetails.org_level,
              market_segment: result.data.orgDetails.market_segment,
            });
            $scope.accessTypeList = $scope.getAccessType(result.data.orgDetails);
            $scope.orgChange(result.data.orgDetails, false, 0);
          } else {
            showToaster('error', result.data.message, '');
          }
        },
        (error) => {
          showToaster('error', error.data.message, '');
        },
      );
    };

    $scope.enterOnFormSearch = (event) => {
      if (event.keyCode === 13) {
        if ($scope.userListQuery.phone && $scope.userListQuery.phone.charAt(0) === '_') {
          $scope.userListQuery.phone = undefined;
        }
        $scope.searchButtonClicked();
      }
    };

    $scope.enterOnTempFormSearch = (event) => {
      if (event.keyCode === 13) {
        if ($scope.tempUserListQuery.phone && $scope.tempUserListQuery.phone.charAt(0) === '_') {
          $scope.tempUserListQuery.phone = undefined;
        }
        $scope.tempSearchButtonClicked();
      }
    };

    $scope.setAddUserForm = (addUserform) => {
      $scope.addUserForm = addUserform;
    };

    $scope.addAnotherOrganisation = () => {
      if (!$scope.orgDisabled) {
        $scope.addUser.linkedOrgs.push({
          organisation: null,
          org_id: null,
          role: null,
          access_type: null,
          operations_team: null,
          default: false,
          accessTypeList: [],
          assignedOrgAlertsList: [],
          assignedOrgAlerts: [],
        });
        $scope.addUser.selectedOrgs.push(0);
      }
    };

    $scope.removeOrganisation = (index) => {
      $scope.addUser.linkedOrgs.splice(index, 1);
      $scope.addUser.selectedOrgs.splice(index, 1);
    };

    $scope.addOnEnter = (event) => {
      if ($scope.addUserForm.$valid && event.keyCode === 13) {
        $scope.addUserClicked();
      }
    };
    const getRole = (name, accessTypeList) => {
      for (let i = 0; i < accessTypeList.length; i += 1) {
        if (accessTypeList[i].name.toLowerCase() === name.name.toLowerCase()) {
          return accessTypeList[i].type;
        }
      }
      return false;
    };

    $scope.getStatus = (isDeleted) => {
      if (isDeleted) {
        return 'Inactive';
      }
      return 'Active';
    };

    $scope.orgChange = (org, firstTimeLoad, index, uiAction) => {
      if (firstTimeLoad) {
        $scope.accessTypeList = [];
      } else if (index > -1) {
        $scope.addUser.linkedOrgs[index].accessTypeList = $scope.getAccessType(org);
        $scope.addUser.selectedOrgs[index] = org.id;
        const filteredAccessTypes = $filter('orderBy')($scope.addUser.linkedOrgs[index].accessTypeList, 'name');
        [$scope.addUser.linkedOrgs[index].access_type] = filteredAccessTypes;
      } else {
        $scope.accessTypeList = $scope.getAccessType(org);
      }
      /**
       * when org changed than removing operations_team and assignedOrgAlerts
       */
      if (
        uiAction
        && $scope.addUser.linkedOrgs[index].org_id
        && $scope.addUser.linkedOrgs[index].org_id !== org.id
      ) {
        Object.assign($scope.addUser.linkedOrgs[index], {
          operations_team: null,
          assignedOrgAlertsList: [],
          assignedOrgAlerts: [],
        });
      }
    };

    $scope.orgFilterChange = function orgFilterChange(org) {
      $scope.accessTypeFilterList = '';
      const loggedInUserDetails = JSON.parse(localStorage.getItem('userDetails'));
      if (!org) {
        if (isMasterOrgFilter(loggedInUserDetails.organisation.org_level)) {
          if ((loggedInUserDetails.role && loggedInUserDetails.role === 'mastersuperadmin') || permissionFilter($scope.permissionsConstants.USER_UPDATE_ANY)) {
            $scope.accessTypeFilterList = [
              { type: 'admin', name: 'Ride Manager Admin' },
              { type: 'mastersuperadmin', name: 'Master Superadmin' },
              { type: userRoles.PRODUCTION_OPERATION, name: displayRoleFilter(userRoles.PRODUCTION_OPERATION) },
              { type: 'superadmin', name: 'Superadmin' }, { type: 'Scheduling admin', name: 'Scheduling Admin' },
              { type: 'orgadmin', name: 'User' },
              { type: userRoles.OC_REPORTING, name: displayRoleFilter(userRoles.OC_REPORTING) },
              { type: userRoles.ORG_REPORTING, name: displayRoleFilter(userRoles.ORG_REPORTING) },
              { type: 'org_superadmin', name: 'Admin' },
              { type: userRoles.NETWORK_OPERATION, name: displayRoleFilter(userRoles.NETWORK_OPERATION) },
              { type: userRoles.PROVIDER_MANAGER, name: displayRoleFilter(userRoles.PROVIDER_MANAGER) },
            ];
          } else {
            $scope.accessTypeFilterList = [
              { type: 'admin', name: 'Ride Manager Admin' },
              { type: 'superadmin', name: 'Superadmin' },
              { type: 'Scheduling admin', name: 'Scheduling Admin' },
              { type: 'orgadmin', name: 'User' },
              { type: userRoles.OC_REPORTING, name: displayRoleFilter(userRoles.OC_REPORTING) },
              { type: userRoles.ORG_REPORTING, name: displayRoleFilter(userRoles.ORG_REPORTING) },
              { type: 'org_superadmin', name: 'Admin' }];
          }
        } else if (loggedInUserDetails.role && loggedInUserDetails.role === 'riderAdmin') {
          $scope.accessTypeFilterList = [{ type: 'riderUser', name: 'User' }, { type: 'riderAdmin', name: 'Admin' }];// not implemented reporting here since as per Disha RiderAdmin is an old role not used anymore
        } else if (loggedInUserDetails.role === 'org_superadmin') {
          $scope.accessTypeFilterList = [{ type: 'orgadmin', name: 'User' }, { type: 'org_superadmin', name: 'Admin' },
            { type: userRoles.ORG_REPORTING, name: displayRoleFilter(userRoles.ORG_REPORTING) }];
        } else {
          $scope.accessTypeFilterList = [];
        }
      } else {
        $scope.userListQuery.accessType = '';
        $scope.accessTypeFilterList = $scope.getAccessType(org);
      }
    };

    if (orgId) {
      getSelectedOrgDetails();
    } else {
      $scope.orgFilterChange();
    }

    $scope.orgChange(
      {
        id: $scope.userDetails.org_id,
        org_class: $scope.userDetails.organisation && $scope.userDetails.organisation.org_class,
        org_level: $scope.userDetails.organisation && $scope.userDetails.organisation.org_level,
        market_segment: $scope.userDetails.organisation && $scope.userDetails.organisation.market_segment,
      },
      true,
    );

    const adjustPagination = () => {
      $scope.pagination.lastItem = ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage + 20;
      if ($scope.pagination.lastItem > $scope.pagination.totalItems) {
        $scope.pagination.lastItem = $scope.pagination.totalItems;
      }
    };

    const getOrgUsers = function getOrgUsers(matchData, orgIdInParams, isFilter) {
      const getOrgUsersCall = $http({
        url: `${API_BASE_URL}getUsersByOrgId/${orgIdInParams || orgId || 0}/${($location.search().table_page - 1) * $scope.pagination.itemsPerPage}/${
          $scope.pagination.itemsPerPage
        }/${isFilter}`,
        method: 'GET',
        params: matchData,
      });
      loadingScreenFactory.showLoadingScreen();
      getOrgUsersCall.then(
        (result) => {
          loadingScreenFactory.hideLoadingScreen();
          if (result.data.success) {
            $scope.userList = result.data.userList;
            $scope.pagination.totalItems = result.data.totalCount;
            $scope.pagination.currentPage = $location.search().table_page;
            let failRetries = 10;
            const processOrgList = () => {
              if ($scope.addUser.organisation) {
                $scope.userList.forEach((element) => {
                  if (element.id === $scope.addUser.organisation.main_contact_id) {
                    Object.assign(element, { isMainContact: true });
                    $scope.mainContact = element;
                  }
                });
              } else if (failRetries > 0) {
                failRetries -= 1;
                $timeout(processOrgList, 500);
              }
            };
            processOrgList();

            $scope.userList.forEach((element) => {
              if (element.user_orgs.length) {
                element.user_orgs.forEach((org) => {
                  Object.assign(org, {
                    operations_team_view: [],
                    default: (org.org_id === element.default_org_id),
                    org_id: element.default_org_id,
                  });
                  Object.assign(element, { operations_team_view: [] });
                  if (element.user_operations_teams) {
                    element.user_operations_teams.forEach((opTeam) => {
                      if (opTeam.org_id.toString() === org.org_id.toString()) {
                        org.operations_team_view.push(opTeam.operations_team.name);
                      }
                      if (opTeam.org_id.toString() === element.default_org_id.toString()) {
                        element.operations_team_view.push(opTeam.operations_team.name);
                      }
                    });
                  }
                });
              }
            });

            adjustPagination();
          } else {
            $scope.pagination.totalItems = 0;
            adjustPagination();
            showToaster('error', result.data.message, '');
          }
        },
        (error) => {
          loadingScreenFactory.hideLoadingScreen();
          $scope.pagination.totalItems = 0;
          adjustPagination();
          showToaster('error', error.data.message, '');
        },
      );
    };

    const adjustTempPagination = () => {
      $scope.tempPagination.lastItem = ($scope.tempPagination.currentPage - 1) * $scope.tempPagination.itemsPerPage + 20;
      if ($scope.tempPagination.lastItem > $scope.tempPagination.totalItems) {
        $scope.tempPagination.lastItem = $scope.tempPagination.totalItems;
      }
    };

    const getOrgTempUsers = (matchData, orgIdInParams) => {
      const getOrgTempUsersCall = $http({
        url: `${API_BASE_URL}getTempUsersByOrgId/${orgIdInParams || orgId || 0}/${($scope.tempPagination.currentPage - 1)
          * $scope.tempPagination.itemsPerPage}/${$scope.tempPagination.itemsPerPage}`,
        method: 'GET',
        params: matchData,
      });
      loadingScreenFactory.showLoadingScreen();
      getOrgTempUsersCall.then(
        (result) => {
          loadingScreenFactory.hideLoadingScreen();
          const { data } = result;
          if (data.success) {
            $scope.tempUserList = data.userList;
            $scope.tempPagination.totalItems = data.totalCount;
            $scope.tempUserList.forEach((element) => {
              Object.assign(element, { user_orgs: [] });
              element.organisations.forEach((org, index) => {
                element.user_orgs.push({
                  organisation: {
                    name: org,
                  },
                  role: element.role[index],
                });
              });
            });
            adjustTempPagination();
          } else {
            $scope.tempPagination.totalItems = 0;
            adjustTempPagination();
            showToaster('error', result.data.message, '');
          }
        },
        (error) => {
          loadingScreenFactory.hideLoadingScreen();
          $scope.tempPagination.totalItems = 0;
          adjustTempPagination();
          showToaster('error', error.data.message, '');
        },
      );
    };

    const getFilterRole = (name) => {
      for (let i = 0; i < $scope.accessTypeFilterList.length; i += 1) {
        if ($scope.accessTypeFilterList[i].name.toLowerCase() === name.name.toLowerCase()) {
          return $scope.accessTypeFilterList[i].type;
        }
      }
      return true;
    };

    const filterUsers = (isSearch = false) => {
      let role = '';
      let isFilter = false;
      if ($scope.searchFormQuery.accessType) {
        role = getFilterRole($scope.searchFormQuery.accessType);
      } else {
        role = undefined;
      }
      Object.values($scope.searchFormQuery).forEach((value) => {
        if (value) {
          isFilter = true;
        }
      });
      const matchData = {
        name: $scope.searchFormQuery.userName ? $scope.searchFormQuery.userName : undefined,
        username: $scope.searchFormQuery.email ? $scope.searchFormQuery.email.trim() : undefined,
        phone: $scope.searchFormQuery.phone
          ? $scope.searchFormQuery.phone
            .replace(/-/g, '')
            .replace(' ', '')
            .replace(/_/g, '')
            .replace('(', '')
            .replace(')', '')
          : undefined,
        role,
        is_del: $scope.searchFormQuery.is_del ? $scope.searchFormQuery.is_del.id : undefined,
        operations_team_id: $scope.searchFormQuery.operations_team_id ? $scope.searchFormQuery.operations_team_id : undefined,
      };
      let selectedOrg = $scope.searchFormQuery.organisation;
      const storedData = storage.getDataByKey('keepFilterData', currentState);
      if (isSearch) {
        storage.setDataByKey('keepFilterData', currentState, { key: 'users', data: matchData, org: selectedOrg });
      } else if (storedData && storedData.key === 'users') {
        matchData.name = storedData.data.name ? storedData.data.name : undefined;
        matchData.username = storedData.data.username ? storedData.data.username : undefined;
        matchData.phone = storedData.data.phone ? storedData.data.phone : undefined;
        matchData.role = storedData.data.role ? storedData.data.role : undefined;
        matchData.is_del = [0, 1].includes(storedData.data.is_del) ? storedData.data.is_del : undefined;
        matchData.operations_team_id = storedData.data.operations_team_id ? storedData.data.operations_team_id : undefined;
        selectedOrg = storedData.org;
        isFilter = true;
      } else {
        // this should be skipped.
      }
      getOrgUsers(matchData, selectedOrg && selectedOrg.id, isFilter);
    };
    filterUsers();

    $scope.searchButtonClicked = () => {
      $scope.pagination.currentPage = 1;
      $scope.searchFormQuery = angular.copy($scope.userListQuery);
      filterUsers(true);
    };

    $scope.clearFilters = () => {
      storage.removeDataByKey('keepFilterData', currentState);

      const userListQueryCopy = angular.copy($scope.userListQuery);
      $scope.searchFormQuery = {};
      $scope.userListQuery = { userName: '' };
      if (orgId) {
        $scope.userListQuery.organisation = userListQueryCopy.organisation;
      }
      $scope.pagination.currentPage = 1;
      $scope.orgFilterChange(
        orgId
          ? {
            id: orgId,
            org_class: userListQueryCopy.organisation && userListQueryCopy.organisation.org_class,
            org_level: userListQueryCopy.organisation && userListQueryCopy.organisation.org_level,
            market_segment: userListQueryCopy.organisation && userListQueryCopy.organisation.market_segment,
          }
          : null,
      );
      filterUsers();
    };

    const filterTempUsers = (isSearch = false) => {
      let role;
      if ($scope.tempSearchFormQuery.accessType) {
        role = getFilterRole($scope.tempSearchFormQuery.accessType);
      }
      const matchData = {
        name: $scope.tempSearchFormQuery.userName || undefined,
        email: $scope.tempSearchFormQuery.email ? $scope.tempSearchFormQuery.email.trim() : undefined,
        phone: $scope.tempSearchFormQuery.phone
          ? $scope.tempSearchFormQuery.phone
            .replace(/-/g, '')
            .replace(' ', '')
            .replace(/_/g, '')
            .replace('(', '')
            .replace(')', '')
          : undefined,
        role,
      };
      let selectedOrg = $scope.tempSearchFormQuery.organisation;
      const storedData = storage.getDataByKey('keepFilterData', currentState);
      if (isSearch) {
        storage.setDataByKey('keepFilterData', currentState, { key: 'tempusers', data: matchData, org: selectedOrg });
      } else if (storedData && storedData.key === 'tempusers') {
        matchData.name = storedData.data.name || undefined;
        matchData.email = storedData.data.email || undefined;
        matchData.phone = storedData.data.phone || undefined;
        matchData.role = storedData.data.role || undefined;
        selectedOrg = storedData.org;
      } else {
        // this should be skipped
      }
      getOrgTempUsers(matchData, selectedOrg && selectedOrg.id);
    };
    filterTempUsers();

    $scope.addUserClicked = async (form) => {
      if (inProcess) {
        return;
      }

      const orgRoles = [];

      for (let i = 0; i < $scope.addUser.linkedOrgs.length; i += 1) {
        if (
          !$scope.addUser.linkedOrgs[i].organisation
          || !$scope.addUser.linkedOrgs[i].organisation.id
          || !$scope.addUser.linkedOrgs[i].access_type
          || !$scope.addUser.linkedOrgs[i].access_type.type
        ) {
          if ($scope.addUser.linkedOrgs[i].default) {
            showToaster('error', 'Default Organization details cannot be empty', '');
            return;
          }
        } else if ($scope.addUser.linkedOrgs[i].default) {
          orgRoles.unshift({
            id: $scope.addUser.linkedOrgs[i].organisation ? $scope.addUser.linkedOrgs[i].organisation.id : undefined,
            role: getRole($scope.addUser.linkedOrgs[i].access_type, $scope.addUser.linkedOrgs[i].accessTypeList) || undefined,
            operations_team:
              $scope.addUser.linkedOrgs[i].operations_team && $scope.addUser.linkedOrgs[i].operations_team.length > 0
                ? $scope.addUser.linkedOrgs[i].operations_team
                : undefined,
            assigned_org_alerts:
              $scope.addUser.linkedOrgs[i].assignedOrgAlerts && $scope.addUser.linkedOrgs[i].assignedOrgAlerts.length
                ? $scope.addUser.linkedOrgs[i].assignedOrgAlerts
                : undefined,
          });
        } else {
          orgRoles.push({
            id: $scope.addUser.linkedOrgs[i].organisation ? $scope.addUser.linkedOrgs[i].organisation.id : undefined,
            role: getRole($scope.addUser.linkedOrgs[i].access_type, $scope.addUser.linkedOrgs[i].accessTypeList) || undefined,
            operations_team:
              $scope.addUser.linkedOrgs[i].operations_team && $scope.addUser.linkedOrgs[i].operations_team.length > 0
                ? $scope.addUser.linkedOrgs[i].operations_team
                : undefined,
            assigned_org_alerts:
              $scope.addUser.linkedOrgs[i].assignedOrgAlerts && $scope.addUser.linkedOrgs[i].assignedOrgAlerts.length
                ? $scope.addUser.linkedOrgs[i].assignedOrgAlerts
                : undefined,
          });
        }
      }

      const userPhone = angular.element('#phoneNo').intlTelInput('getNumber', window.intlTelInputUtils.numberFormat.E164);
      const serverData = {
        username: $scope.addUser.userEmail,
        phone: userPhone,
        org_roles: orgRoles,
      };

      if (!$scope.addUser.id) {
        Object.assign(serverData, {
          firstName: $scope.addUser.firstName,
          lastName: $scope.addUser.lastName,
          org_class: $scope.userDetails.organisation.org_class,
          autoVerify: $scope.addUser.autoVerify,
        });

        try {
          loadingScreenFactory.showLoadingScreen();
          const result = await $http.post(`${API_BASE_URL}users/add`, serverData);

          if (result.data.success) {
            filterUsers();
            filterTempUsers();
            showToaster('success', 'User added successfully', '');
            clearUser();
            $scope.addUser.linkedOrgs = [
              {
                organisation: null,
                org_id: null,
                role: null,
                access_type: null,
                operations_team: null,
                default: true,
                accessTypeList: $scope.accessTypeList,
                assignedOrgAlertsList: [],
                assignedOrgAlerts: [],
              },
            ];
            if (form) {
              form.$setUntouched();
              form.$setPristine();
            }
            if ($scope.addUser.autoVerify) {
              $state.transitionTo('main.userMgmt.manageUser', { userId: result.data.user_id });
            }
          } else {
            Object.keys(result.data.message).forEach((key) => {
              showToaster('error', result.data.message[key], '');
            });
          }
        } catch (error) {
          showToaster('error', error.data.message, '');
        } finally {
          loadingScreenFactory.hideLoadingScreen();
        }
      } else {
        Object.assign(serverData, {
          first_name: $scope.addUser.firstName,
          last_name: $scope.addUser.lastName,
          org_id: orgId,
          assignUserCall: true,
        });

        try {
          loadingScreenFactory.showLoadingScreen();
          const result = await $http.put(`${API_BASE_URL}users/edit/${$scope.addUser.id}`, serverData);

          if (result.data.success) {
            filterUsers();
            showToaster('success', 'User added successfully', '');
            clearUser();
          } else {
            Object.keys(result.data.message).forEach((key) => {
              showToaster('error', result.data.message[key], '');
            });
          }
        } catch (error) {
          showToaster('error', error.data.message, '');
        } finally {
          loadingScreenFactory.hideLoadingScreen();
        }
      }
      $scope.$apply();
    };

    $scope.tempSearchButtonClicked = () => {
      $scope.tempPagination.currentPage = 1;
      $scope.tempSearchFormQuery = angular.copy($scope.tempUserListQuery);
      filterTempUsers(true);
    };

    $scope.clearTempFilters = () => {
      storage.removeDataByKey('keepFilterData', currentState);

      const tempUserListQueryCopy = angular.copy($scope.tempUserListQuery);
      $scope.tempSearchFormQuery = {};
      $scope.tempUserListQuery = {
        userName: '',
      };
      if (orgId) {
        $scope.tempUserListQuery.organisation = tempUserListQueryCopy.organisation;
      }
      $scope.tempPagination.currentPage = 1;
      $scope.orgFilterChange(
        orgId
          ? {
            id: orgId,
            org_class: tempUserListQueryCopy.organisation && tempUserListQueryCopy.organisation.org_class,
            org_level: tempUserListQueryCopy.organisation && tempUserListQueryCopy.organisation.org_level,
            market_segment: tempUserListQueryCopy.organisation && tempUserListQueryCopy.organisation.market_segment,
          }
          : null,
      );
      filterTempUsers();
    };

    $scope.tempPageChanged = () => {
      filterTempUsers();
    };

    $scope.getAccessType = function getAccessType(org) {
      if (!org) { return []; }

      let loggedInUserDetails = localStorage.getItem('userDetails');
      loggedInUserDetails = JSON.parse(loggedInUserDetails);
      let accessType = [];
      if (isMasterOrgFilter(org.org_level)) {
        if ((loggedInUserDetails.role && loggedInUserDetails.role === 'mastersuperadmin') || permissionFilter($scope.permissionsConstants.USER_UPDATE_ANY)) {
          accessType = [
            { type: 'admin', name: 'Ride Manager Admin' },
            { type: 'mastersuperadmin', name: 'Master Superadmin' },
            { type: userRoles.PRODUCTION_OPERATION, name: displayRoleFilter(userRoles.PRODUCTION_OPERATION) },
            { type: 'superadmin', name: 'Superadmin' },
            { type: userRoles.OC_REPORTING, name: displayRoleFilter(userRoles.OC_REPORTING) },
            { type: 'Scheduling admin', name: 'Scheduling Admin' },
            { type: userRoles.NETWORK_OPERATION, name: displayRoleFilter(userRoles.NETWORK_OPERATION) },
            { type: userRoles.PROVIDER_MANAGER, name: displayRoleFilter(userRoles.PROVIDER_MANAGER) },
          ];
        } else {
          accessType = [
            { type: 'admin', name: 'Ride Manager Admin' },
            { type: 'superadmin', name: 'Superadmin' },
            { type: 'Scheduling admin', name: 'Scheduling Admin' },
            { type: userRoles.OC_REPORTING, name: displayRoleFilter(userRoles.OC_REPORTING) }];
        }
      } else if (loggedInUserDetails.role && loggedInUserDetails.role === 'riderAdmin') {
        accessType = [{ type: 'riderUser', name: 'User' }, { type: 'riderAdmin', name: 'Admin' }];// Not added Reporting here since as per Disha this role is not in use anymore
      } else if (loggedInUserDetails.role === 'org_superadmin') {
        accessType = [{ type: 'orgadmin', name: 'User' }, { type: 'org_superadmin', name: 'Admin' }];
        if ([WORKERS_COMPENSATION_KEY, HEALTHCARE_MASS_MARKET_KEY].includes(org.market_segment.key)) {
          accessType.push({ type: userRoles.ORG_REPORTING, name: displayRoleFilter(userRoles.ORG_REPORTING) });
        }
      } else if (['mastersuperadmin', 'superadmin'].includes(loggedInUserDetails.role) || permissionFilter($scope.permissionsConstants.USER_UPDATE_ANY)) {
        if (org.org_class === 'business') {
          accessType = [{ type: 'orgadmin', name: 'User' }, { type: 'org_superadmin', name: 'Admin' }];
          if ([WORKERS_COMPENSATION_KEY, HEALTHCARE_MASS_MARKET_KEY].includes(org.market_segment.key)) {
            accessType.push({ type: userRoles.ORG_REPORTING, name: displayRoleFilter(userRoles.ORG_REPORTING) });
          }
        } else if (org.org_class === 'rider') {
          accessType = [{ type: 'riderUser', name: 'User' }, { type: 'riderAdmin', name: 'Admin' }];
        } else {
          accessType = [];
        }
      } else {
        accessType = [];
      }
      return accessType;
    };

    $scope.sendPendingSignupMail = (userId) => {
      if (!userId) return false;
      $scope.sendMail('pendingSignup', 'emails/pending-signup/', userId);
      return true;
    };

    $scope.sendMemberInviteMail = (userId) => {
      if (!userId) return false;
      $scope.sendMail('inviteMember', 'emails/invite-members/', userId);
      return true;
    };

    $scope.sendMail = (type, url, userId) => {
      loadingScreenFactory.showLoadingScreen();
      $http
        .post(API_BASE_URL + url + userId)
        .then((result) => {
          const { data } = result;
          if (data.success) {
            showToaster('info', data.message, 'Mail Sent Successfully');
          } else {
            showToaster('error', data.message, 'Could not send Mail');
          }
        })
        .catch((error) => {
          showToaster('error', error.data ? error.data.message : false, 'Could not send Mail');
        })
        .finally(() => {
          loadingScreenFactory.hideLoadingScreen();
        });
    };

    $scope.$on('$locationChangeSuccess', () => {
      if ($location.$$path === '/main/userMgmt/userList/') {
        if ($location.search().table_page === true || $location.search().table_page === undefined || Number.isNaN($location.search().table_page)) {
          $location.search('table_page', 1);
        } else {
          filterUsers();
        }
      } else {
        $location.search('table_page', null);
      }
    });

    $scope.mask = '(999) 999-9999';
    (function registerListenerForCountryChange() {
      $timeout(() => {
        $('.iti__country-list .iti__country').click(() => {
          $timeout(() => {
            if ($('#phoneNo').intlTelInput('getSelectedCountryData').iso2 === 'us') {
              $scope.mask = '(999) 999-9999';
            } else {
              $scope.mask = '(999) 999-99999?9?9?9?9?';
            }
          });
        });
      });
    }());
    const getAssignedOrgAlertsList = async (operationTeams) => {
      let alertOrganisations = [];
      try {
        const {
          data:
           { success, message, organisations }
           = {},
        } = await $http.get(`${API_BASE_URL}operationsteams/${operationTeams}/organisations`);
        if (!success) {
          throw new Error(message);
        }
        alertOrganisations = organisations;
      } catch (error) {
        showToaster('error', error.data ? error.data.message : 'Could not fetch user details', '');
      }
      return alertOrganisations;
    };
    $scope.operationTeamSelected = async (index, operationTeams) => {
      if (
        ![ROLES.MASTER_SUPER_ADMIN, ROLES.PRODUCTION_OPERATION].includes(
          $scope.signedInUser.role,
        )
        || ![ROLES.RIDE_MANAGER, ROLES.NETWORK_OPERATION].includes(
          $scope.addUser.linkedOrgs[index].access_type.type,
        )
      ) {
        return;
      }
      const organisations = await getAssignedOrgAlertsList(operationTeams);
      Object.assign($scope.addUser.linkedOrgs[index], { assignedOrgAlertsList: organisations });
    };
    $scope.operationTeamRemoved = async (index, item) => {
      const removeOrgIds = [];
      const remianingOrgAlertList = [];
      $scope.addUser.linkedOrgs[index].assignedOrgAlertsList.forEach(
        (assignedOrgAlertList) => {
          if (assignedOrgAlertList.operationTeamId === item.id) {
            removeOrgIds.push(assignedOrgAlertList.orgId);
          } else {
            remianingOrgAlertList.push(assignedOrgAlertList);
          }
        },
      );
      const remianingOrgIds = $scope.addUser.linkedOrgs[index].assignedOrgAlerts.filter(organisationId => !removeOrgIds.includes(organisationId));
      Object.assign($scope.addUser.linkedOrgs[index], {
        assignedOrgAlertsList: remianingOrgAlertList,
        assignedOrgAlerts: remianingOrgIds,
      });
    };
    $scope.loadMoreChoices = () => {
      $scope.visibleOrgAlertChoices += 20;
    };
  },
]);
