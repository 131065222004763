import moment from 'moment';
import app from '../../app';

app.controller('masInvoiceAdminCtrl', [
  '$scope',
  'API_BASE_URL',
  '$http',
  'loadingScreenFactory',
  '$state',
  'toaster',
  function auditLogCtrl(
    $scope,
    API_BASE_URL,
    $http,
    loadingScreenFactory,
    $state,
    toaster,
  ) {
    let firstTimeLoad = true;
    $scope.invoiceStatusCodes = {
      SELECT: {
        text: 'Select Status',
        canExport: false,
      },
      IA_ATTEST: {
        text: 'Invoice Attest Pending',
        canExport: false,
      },
      IA_FAILED: {
        text: 'Invoice Attest Failure',
        canExport: true,
      },
      IA_SUCCESS: {
        text: 'Invoice Attested',
        canExport: true,
      },
      EBS_PENDING_SUBMISSION: {
        text: 'EBS Upload Pending',
        canExport: false,
      },
      EBS_SUBMITTED: {
        text: 'EBS Submitted',
        canExport: true,
      },
      EBS_FAILED: {
        text: 'EBS Failure',
        canExport: true,
      },
      EBS_SUCCESS: {
        text: 'EBS Succeeded',
        canExport: false,
      },
    };

    $scope.invoiceStatusList = [
      'SELECT',
      'IA_ATTEST',
      'IA_FAILED',
      'IA_SUCCESS',
      'EBS_PENDING_SUBMISSION',
      'EBS_SUBMITTED',
      'EBS_FAILED',
      'EBS_SUCCESS',
    ];
    $scope.allInvoicesSelected = false;
    $scope.itemsPerPageList = [20, 30, 50, 100];
    const initSearchForm = () => {
      $scope.searchFormQuery = {
        invoiceStatus: 'SELECT',
        dateRange: {},
        invoiceNumber: '',
      };
      $scope.invoiceFilterQuery = {
        invoiceStatus: 'SELECT',
        dateRange: {},
        invoiceNumber: '',
      };
    };

    const initSearchFormNew = () => {
      $scope.searchFormQuery = {
        invoiceStatus: 'SELECT',
        dateRange: { startDate: null, endDate: null },
        invoiceNumber: '',
      };
      $scope.invoiceFilterQuery = {
        invoiceStatus: 'SELECT',
        dateRange: { startDate: null, endDate: null },
        invoiceNumber: '',
      };
      $scope.allInvoicesSelected = false;
    };

    $scope.moment = moment;

    $scope.dateRangeOptions = {
      parentEl: '#current-app #page-wrapper',
      eventHandlers: {
        'apply.daterangepicker': () => {
          const element = document.getElementById('dateRange');
          const dateRangeFilter = element.value;

          if (dateRangeFilter) {
            const dateRangeFilterArr = dateRangeFilter.split(' - ');
            $scope.invoiceFilterQuery.dateRange.startDate = moment(dateRangeFilterArr[0]).startOf('day');
            $scope.invoiceFilterQuery.dateRange.endDate = moment(dateRangeFilterArr[1]).endOf('day');
          }
        },
      },
    };
    $scope.sortBy = 'dateOfService';
    $scope.sortOrder = 1;
    const adjustPagination = () => {
      $scope.pagination.lastItem = ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage
        + $scope.pagination.itemsPerPage;
      if ($scope.pagination.lastItem > $scope.pagination.totalItems) {
        $scope.pagination.lastItem = $scope.pagination.totalItems;
      }
    };

    const getMasInvoices = (offset, limit, searchStr) => {
      const request = $http.get(
        `${API_BASE_URL}mas-service/invoices?offset=${offset}&limit=${limit}${searchStr}`,
      );
      loadingScreenFactory.showLoadingScreen();
      request.then(
        ({
          data: { success, data, message },
        }) => {
          loadingScreenFactory.hideLoadingScreen();
          if (!success) {
            toaster.pop({
              type: 'error',
              title: message || 'Error in fetching MAS Invoices',
              timeout: 3000,
            });
            return;
          }
          $scope.invoices = data.rows;
          $scope.pagination.totalItems = data.count; // todo : this needs to equals totalCount
          firstTimeLoad = false;
          adjustPagination();
        },
        () => {
          toaster.pop({
            type: 'error',
            title: 'Error in fetching MAS Invoices',
            timeout: 3000,
          });
          loadingScreenFactory.hideLoadingScreen();
        },
      );
    };

    const getSearchString = () => {
      const searchFormQuery = angular.copy($scope.searchFormQuery);

      if (
        searchFormQuery.dateRange.startDate
        && searchFormQuery.dateRange.endDate
      ) {
        if (typeof $scope.searchFormQuery.dateRange.startDate === 'object') {
          searchFormQuery.startDate = `${$scope.searchFormQuery.dateRange.startDate.format(
            'MM-DD-YYYY',
          )} 00:00:00`;
          searchFormQuery.endDate = `${$scope.searchFormQuery.dateRange.endDate.format(
            'MM-DD-YYYY',
          )} 23:59:59`;
        } else {
          searchFormQuery.startDate = $scope.searchFormQuery.dateRange.startDate;
          searchFormQuery.endDate = $scope.searchFormQuery.dateRange.endDate;
        }
      }
      delete searchFormQuery.dateRange;
      if (searchFormQuery.invoiceStatus === 'SELECT') {
        // remove if from
        delete searchFormQuery.invoiceStatus;
      }
      const searchStr = [];
      const searchFormQueryKeys = Object.keys(searchFormQuery);
      for (
        let keyIndex = 0;
        keyIndex < searchFormQueryKeys.length;
        keyIndex += 1
      ) {
        const key = searchFormQueryKeys[keyIndex];
        if (searchFormQuery[key] && key !== 'currentPage') {
          searchStr.push(`${key}=${searchFormQuery[key]}`);
        }
      }
      const order = $scope.sortOrder ? 'DESC' : 'ASC';
      searchStr.push(`sortBy=${$scope.sortBy}&sortDir=${order}`);
      let temp = searchStr.join('&');
      if (searchStr.length > 0) temp = `&${temp}`;

      return temp;
    };

    $scope.itemsPerPageChanged = () => {
      getMasInvoices($scope.pagination.currentPage - 1, $scope.pagination.itemsPerPage, getSearchString());
    };

    $scope.sort = (sortBy) => {
      $scope.allInvoicesSelected = false;
      if ($scope.sortBy === sortBy) {
        $scope.sortOrder = ($scope.sortOrder === 1) ? 0 : 1;
      } else {
        $scope.sortBy = sortBy;
        $scope.sortOrder = 0;
      }
      getMasInvoices($scope.pagination.currentPage - 1, $scope.pagination.itemsPerPage, getSearchString());
    };

    const requestExport = async (invoiceIds) => {
      try {
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        const data = {
          page: 'MAS',
          invoice_numbers: invoiceIds,
        };
        loadingScreenFactory.showLoadingScreen();
        const {
          data: {
            success,
          },
        } = await ($http.post(`${API_BASE_URL}saveExportFilters`, data));
        if (success) {
          toaster.pop({
            type: 'info',
            title: 'Please wait for your file.',
            showCloseButton: true,
            timeout: 10000,
          });

          $state.go('main.dashboard.masExportImportInvoice', { activeTab: 'export' });
        } else {
          toaster.pop({
            type: 'error',
            title: 'Error in requesting export',
            timeout: 3000,
          });
        }
      } catch (e) {
        toaster.pop({
          type: 'error',
          title: e.message,
          timeout: 3000,
        });
      } finally {
        loadingScreenFactory.hideLoadingScreen();
        $scope.$apply();
      }
    };
    $scope.exportInvoices = () => {
      if (!$scope.invoices.length) {
        toaster.pop({
          type: 'error',
          title: 'Please select invoices to download',
          showCloseButton: true,
          timeout: 10000,
        });
        return;
      }
      const selectedInvoices = [];
      for (let i = 0; i < $scope.invoices.length; i += 1) {
        if ($scope.invoices[i].selected) {
          selectedInvoices.push($scope.invoices[i].invoice_number);
        }
      }
      if (!selectedInvoices.length) {
        toaster.pop({
          type: 'error',
          title: 'Please select invoices to download',
          showCloseButton: true,
          timeout: 10000,
        });
        return;
      }
      requestExport(selectedInvoices);
    };

    const initPagination = () => {
      $scope.pagination = {
        totalItems: 0,
        currentPage: 1,
        lastItem: 0,
        itemsPerPage: $scope.itemsPerPageList[3],
      };
    };

    const init = () => {
      initPagination();
      initSearchForm();
      getMasInvoices(
        $scope.pagination.currentPage - 1,
        $scope.pagination.itemsPerPage,
        getSearchString(),
      );
    };
    init();

    $scope.pageChanged = () => {
      if (!firstTimeLoad) {
        $scope.allInvoicesSelected = false;
        $state.go($state.current, {
          currentPage: $scope.pagination.currentPage - 1,
        });
        getMasInvoices($scope.pagination.currentPage - 1, $scope.pagination.itemsPerPage, getSearchString());
      }
    };

    $scope.filterInvoices = () => {
      $scope.searchFormQuery = angular.copy($scope.invoiceFilterQuery);
      getMasInvoices(0, $scope.pagination.itemsPerPage, getSearchString());
    };

    $scope.clearFilters = () => {
      initSearchFormNew();
      getMasInvoices(0, $scope.pagination.itemsPerPage, getSearchString());
    };

    $scope.enterOnFormSearch = (event) => {
      if (event.keyCode === 13) {
        $scope.searchFormQuery = angular.copy($scope.invoiceFilterQuery);
        getMasInvoices(0, $scope.pagination.itemsPerPage, getSearchString());
      }
    };

    $scope.selectAllInvoices = () => {
      for (let i = 0; i < $scope.invoices.length; i += 1) {
        if ($scope.invoices[i] && $scope.invoiceStatusCodes[$scope.invoices[i].invoice_status].canExport) {
          $scope.invoices[i].selected = $scope.allInvoicesSelected;
        }
      }
    };
  },
]);
