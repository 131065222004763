angular.module('relayHealth').controller('summaryCtrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  'toaster',
  '$state',
  '$interval',
  '$timeout',
  'signupStateService',
  'signupAuthCheckService',
  'signupInitialStateService',
  '$stateParams',
  'signupNavigationValidationService',
  'updateSignupStep',
  '$rootScope',
  function summaryCtrl(
    $scope,
    $http,
    API_BASE_URL,
    toaster,
    $state,
    $interval,
    $timeout,
    signupStateService,
    signupAuthCheckService,
    signupInitialStateService,
    $stateParams,
    signupNavigationValidationService,
    updateSignupStep,
    $rootScope,
  ) {
    $scope.states = '';
    $scope.state = {};
    $scope.stageNumber = 7;

    $scope.selectedState = {};

    $scope.pagination = {
      totalItems: 0,
      currentPage: 1,
      lastItem: 0,
      itemsPerPage: 20,
    };

    $scope.summaryResponseError = '';

    function adjustPagination() {
      $scope.pagination.lastItem = $scope.pagination.lastItem > $scope.pagination.totalItems
        ? $scope.pagination.totalItems
        : (($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage + 20);
    }

    function init() {
      $scope.state = signupInitialStateService.initialState();
      let hasErrors = false;
      const currentPage = parseInt($scope.state.currentPage, 10);
      if (currentPage < 7) {
        if (currentPage < 4) {
          signupNavigationValidationService.termsOfServiceValidation((errors) => {
            if (Object.keys(errors).length > 0) {
              hasErrors = true;
              $state.go($scope.state.signupType === 'business' ? 'main.business_signup_terms_of_service' : 'main.rider_signup_terms_of_service', {
                errors,
              });
            }
          });
        }

        if (currentPage < 5) {
          if ($scope.state.signupType === 'business') {
            signupNavigationValidationService.organizationValidation((errors) => {
              if (Object.keys(errors).length > 0) {
                hasErrors = true;
                $state.go('business_signup_add_organization', { errors });
              }
            });
          } else {
            signupNavigationValidationService.accountInfoValidation((errors) => {
              if (Object.keys(errors).length > 0) {
                hasErrors = true;
                $state.go('main.rider_signup_account_information', { errors });
              }
            });
          }
        }

        if (currentPage < 6) {
          signupNavigationValidationService.addUsers((errors) => {
            if (Object.keys(errors).length > 0) {
              hasErrors = true;
              $state.go($scope.state.signupType === 'business' ? 'main.business_signup_add_users' : 'main.rider_signup_add_users', { errors });
            }
          });
        }

        if (currentPage < 7 && !$scope.state.created_by_user) {
          signupNavigationValidationService.paymentValidation((errors) => {
            if (Object.keys(errors).length > 0) {
              hasErrors = true;
              $state.go($scope.state.signupType === 'business' ? 'main.business_signup_payment' : 'main.rider_signup_payment', { errors });
            }
          });
        }
      }

      if (!hasErrors) {
        signupAuthCheckService.summaryAllowed();
        if (typeof $scope.state.businessInfo.industry === 'object') {
          if ($scope.state.signupType === 'business') {
            $scope.state.businessInfo.industry = $scope.state.businessInfo.industry.name;
          }
        }
        if ($scope.stageNumber > $scope.state.latestStage) {
          $scope.state.latestStage = 7;
          signupStateService.setState('signupState', $scope.state);
        }

        $scope.state.currentPage = 7;
        signupStateService.setState('signupState', $scope.state);
        $timeout(() => {
          $scope.pagination.totalItems = $scope.state.users.length;
          adjustPagination();
        }, 1000);
        $http
          .get(`${API_BASE_URL}states`)
          .then(({ data }) => {
            if (data.success) {
              $scope.states = data.states;
              let stateName = '';
              if ($scope.state.signupType === 'rider') {
                stateName = $scope.state.accountInfo.state ? parseInt($scope.state.accountInfo.state, 10) : '';
              } else if ($scope.state.signupType === 'business') {
                stateName = $scope.state.businessInfo.state ? parseInt($scope.state.businessInfo.state, 10) : '';
              }

              if (typeof stateName === 'number') {
                for (let i = 0; i < data.states.length; i += 1) {
                  if (data.states[i].id === stateName) {
                    $scope.selectedState = data.states[i];
                    $scope.state.accountInfo.state = data.states[i].id;
                    signupStateService.setState('signupState', $scope.state);
                    break;
                  }
                }
              } else if (typeof stateName === 'string') {
                for (let i = 0; i < data.states.length; i += 1) {
                  if (data.states[i].state_name.toLowerCase() === stateName.toLowerCase()) {
                    $scope.selectedState = data.states[i];
                    $scope.state.accountInfo.state = data.states[i].id;
                    signupStateService.setState('signupState', $scope.state);
                    break;
                  }
                }
              }
            }
          });
        const headers = {
          username: $scope.state.email,
          usertoken: $scope.state.usertoken,
          'Content-Type': 'application/json',
        };
        $http
          .get(`${API_BASE_URL}rider/members`, headers)
          .then(({ data }) => {
            if (data.success) {
              $scope.state.users = data.data || [];
              $scope.pagination.totalItems = data.data.length;
              adjustPagination();
              signupStateService.setState('signupState', $scope.state);
            } else {
              $scope.pagination.totalItems = 0;
            }
          })
          .catch(() => {
            $scope.pagination.totalItems = 0;
            $scope.state.users = [];
            signupStateService.setState('signupState', $scope.state);
          });
        $http
          .get(`${API_BASE_URL}userPaymentProfile`)
          .then(({ data }) => {
            if (data.success) {
              if (data.data) {
                const stripeData = data.data.stripe_profile;
                $scope.state.maskedStripeCardNumber = `************${stripeData.last4}`;
                $scope.state.expMonth = stripeData.exp_month;
                $scope.state.expYear = stripeData.exp_year;
              }
            }
          });
        updateSignupStep(6);
      }
    }
    init();

    $scope.submitForm = function submitForm() {
      const headers = {
        username: $scope.state.email,
        usertoken: $scope.state.usertoken,
        'Content-Type': 'application/json',
      };

      const data = { step: 7 };
      $http
        .put(`${API_BASE_URL}rider/update`, [data], headers)
        .then(({ data: { success, permissions } }) => {
          if (!success) {
            $scope.summaryResponseError = 'Some Error Occured';
            return;
          }
          const userDetails = signupStateService.getState('userDetails');
          if ($scope.state.signupType === 'rider') {
            userDetails.role = 'riderAdmin';
          } else if ($scope.state.signupType === 'business') {
            userDetails.role = 'org_superadmin';
          }
          if (permissions) {
            userDetails.permissions = permissions;
          }
          signupStateService.setState('userDetails', userDetails);
          $rootScope.$emit('userLogin', userDetails);
          $state.go('main.thank_you');
        })
        .catch(() => {
          $scope.summaryResponseError = 'Some Error Occured';
        });
    };
  },
]);
