const { Blob, saveAs, FormData } = window;
const { permissionsConstants } = relaylib.permissions;

function inclusionCtrl(
  $scope,
  $rootScope,
  $http,
  API_BASE_URL,
  toaster,
  Pubnub,
  $location,
  permissionFilter,
) {
  $scope.userDetails = JSON.parse(localStorage.getItem('userDetails'));
  if ($scope.userDetails.role !== 'mastersuperadmin' && !permissionFilter(permissionsConstants.IMPORT_FUSE_INCLUSION)) {
    $location.path('/main/dashboard');
  }
  $scope.showAddOrgForm = false;
  $scope.searchFormQuery = {};
  $scope.orgListQuery = {};
  $scope.addOrg = {};
  $scope.statusList = [{ status: 'Active', value: 0 }, { status: 'Inactive', value: 1 }];
  $scope.pagination = {
    totalItems: 0,
    currentPage: 1,
    lastItem: 0,
    itemsPerPage: 20,
  };

  function getLatestProcessedImportRecords() {
    const getLatestProcessedImportRecordsCall = $http.get(`${API_BASE_URL}import/getLatestProcessedImportRecords/fuseInclusion`);

    getLatestProcessedImportRecordsCall.then(
      (result) => {
        if (result.data.success) {
          $scope.importList = result.data.data;
        }
      },
      (err) => {
        toaster.pop({
          type: 'error',
          title: err,
          showCloseButton: true,
          timeout: 10000,
        });
      },
    );
  }

  $scope.importCSV = () => {
    const fm = new FormData();

    if (document.getElementById('import_file').files.length > 0) {
      fm.append('file', document.getElementById('import_file').files[0]);
      const importFuseInclusionCSV = $http({
        url: `${API_BASE_URL}uploadCsvFile/fuseInclusion`,
        method: 'POST',
        data: fm,
        headers: {
          'Content-Type': undefined,
        },
      });
      importFuseInclusionCSV
        .then(
          (result) => {
            if (result.data.success) {
              toaster.pop({
                type: 'info',
                title: 'File queued to upload',
                showCloseButton: true,
                timeout: 10000,
              });
              getLatestProcessedImportRecords();
            } else {
              toaster.pop({
                type: 'error',
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
            }
          },
          (error) => {
            toaster.pop({
              type: 'error',
              title: error.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          },
        )
        .catch((error) => {
          toaster.pop({
            type: 'error',
            title: error.message,
            showCloseButton: true,
            timeout: 10000,
          });
        });
    } else {
      toaster.pop({
        type: 'error',
        title: 'Please select file to upload',
        showCloseButton: true,
        timeout: 10000,
      });
    }
  };
  $scope.downloadXlsxFile = (filename) => {
    const downloadXlsxFileCall = $http({
      url: `${API_BASE_URL}downloadFile/csv/import/fuse_inclusion/${filename}`,
      method: 'GET',
      data: { filename },
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    });

    downloadXlsxFileCall.then((result) => {
      const blob = new Blob([result.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      if (blob.size < 70) {
        toaster.pop({
          type: 'error',
          title: 'No record found! Please try changing date range!',
          showCloseButton: true,
          timeout: 10000,
        });
      } else {
        saveAs(blob, filename);
      }
    });
  };

  getLatestProcessedImportRecords();

  function listenForPubnubMessages() {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    $rootScope.$on(Pubnub.getMessageEventNameFor(userDetails.user_id), (event, payload) => {
      if (payload.message.type === 'dispatchImportRequestProcessed' && payload.message.module === 'fuseInclusion') {
        getLatestProcessedImportRecords();
      }
    });
  }
  listenForPubnubMessages();
}

angular.module('relayHealth').controller('inclusionCtrl', [
  '$scope',
  '$rootScope',
  '$http',
  'API_BASE_URL',
  'toaster',
  'Pubnub',
  '$location',
  'permissionFilter',
  inclusionCtrl,
]);
