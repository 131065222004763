import StorageProvider from '../storageProvider';
import Command from '../../common/command';

export default class GetItemByKey extends Command {
  constructor() {
    super();
  }
  execute(key, childProperty){
    const strorageType = StorageProvider.instance.storageType;
    const storageObj = new strorageType();
    return storageObj.getItemByKey(key, childProperty);
  }
}
