import storageTypes from './storageTypes';
const singleton = Symbol();
const singletonEnforcer = Symbol();

export default class StorageProvider {
    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) {
          throw new Error('Cannot construct singleton storageProvider');
        }
        this._storageType = null;
    }
    /**
     * Holds the singleton instance of this class
     */
    static get instance() {
        if (!this[singleton]) {
          this[singleton] = new StorageProvider(singletonEnforcer);
        }
        return this[singleton];
    }
    static set instance(storageType) {
        throw new Error('Cannot set singleton');
    }
    get storageType(){
        return this._storageType;
    }
    set storageType(storageType){
        if(storageType){
            this._storageType = storageType;
        }else{
            this._storageType = storageTypes.default;
        }
    }
}