import StorageProvider from '../storageProvider';
import Command from '../../common/command';

export default class RemoveItemByKey extends Command {
  constructor() {
    super();
  }
  execute(key, childProperty){
    const storageType = StorageProvider.instance.storageType;
    const storageObj = new storageType();
    storageObj.removeItemByKey(key, childProperty);
  }
}
