export default class eligibilityParameterConverter {
    constructor() {
        // Do nothing
    }

    static config() {
        return {
            "AdditionalChildPassengerCount":{
                "sms":false,
                "ride_order_page":false,
                "send_vendor_portal":false,
                "value":"extra child passenger(s)"
            },
            "AdditionalAdultPassengerCount":{
                "sms":false,
                "ride_order_page":false,
                "send_vendor_portal":false,
                "value":"extra adult passenger(s)"
            },
            "IsCarSeatRequired":{
                "sms":false,
                "ride_order_page":true,
                "send_vendor_portal":true,
                "value":"Car seat required"
            },
            "RequiresCarSeatAssistance":{
                "sms":false,
                "ride_order_page":true,
                "send_vendor_portal":true,
                "value":"Requires car seat assistance"
            },
            "RequiresAssistanceWithVehicleDoor":{
                "sms":false,
                "ride_order_page":true,
                "send_vendor_portal":true,
                "value":"Requires assistance with vehicle door"
            },
            "RequiresAssistanceWithSeatBelt":{
                "sms":false,
                "ride_order_page":true,
                "send_vendor_portal":true,
                "value":"Requires assistance with a seat belt"
            },
            "RequiresAssistanceWithMobilityDevice":{
                "sms":false,
                "ride_order_page":true,
                "send_vendor_portal":true,
                "value":"Requires assistance with a mobility device"
            },
            "HasManualWheelchair":{
                "sms":false,
                "ride_order_page":true,
                "send_vendor_portal":true,
                "value":"Member has manual wheelchair"
            },
            "HasElectricWheelchair":{
                "sms":false,
                "ride_order_page":true,
                "send_vendor_portal":true,
                "value":"Member has electric wheelchair"
            },
            "RequiresDoorThroughDoorAssistance":{
                "sms":false,
                "ride_order_page":true,
                "send_vendor_portal":true,
                "value":"Requires door through door assistance"
            },
            "RequiresDoorToDoorAssistance":{
                "sms":false,
                "ride_order_page":true,
                "send_vendor_portal":true,
                "value":"Requires door to door assistance"
            },
            "HasServiceAnimal":{
                "sms":false,
                "ride_order_page":true,
                "send_vendor_portal":true,
                "value":"Member has a service animal"
            }
        };
    }

    static convertEligibilityObjToString(eligParamObj, enumType) {
        const contain =[];
        const getConfigObject = this.config();
        for (const key in eligParamObj) {
        // check if the property is from prototype
            if (eligParamObj.hasOwnProperty(key) && eligParamObj[key] && getConfigObject[key][enumType]  ) {
                if(typeof eligParamObj[key]==="number"){
                    contain.push(`${eligParamObj[key]} ${getConfigObject[key].value}`);
                }else{
                    contain.push(`${getConfigObject[key].value} Yes`);
                }
            }
        }
        return contain.join(',');
    }
}
