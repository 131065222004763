const statuses = relaylib.ride.status;
const getStatusMapValues = {
  [statuses.PATIENT_NO_SHOW.status]: 'MemberNoShow',
  [statuses.PROVIDER_NO_SHOW.status]: 'ProviderNoShow',
  'Rider No Show': 'MemberNoShow',
  'Member No Show': 'MemberNoShow',
};
angular.module('relayHealth')
  .constant('NO_SHOW_MAPPING', {
    getStatusMapValues,
  });
