import Moment from 'moment';

angular.module('relayHealth').controller('superAdminDashboardCtrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  'Pubnub',
  '$uibModal',
  '$rootScope',
  'SweetAlert',
  'pubnubNotificationFactory',
  '$state',
  '$timeout',
  'loadingScreenFactory',
  '$location',
  'toaster',
  'redirectUserHomeIfLoggedInService',
  'isOCCMRole',
  'ngDialog',
  'getMarketSegments',
  'RECURRENCE_LIMIT',
  'isMasterOrgFilter',
  'permissionFilter',
  'displayRoleFilter',
  'superAdminDashboardService',
  'oldDashboardCheck',
  '$q',
  'SHOW_RECURRENCE',
  'storageManager',
  '$stateParams',
  'requestingOrganizationFactory',
  function superAdminDashboardCtrl(
    $scope,
    $http,
    API_BASE_URL,
    Pubnub,
    $uibModal,
    $rootScope,
    SweetAlert,
    pubnubNotificationFactory,
    $state,
    $timeout,
    loadingScreenFactory,
    $location,
    toaster,
    redirectUserHomeIfLoggedInService,
    isOCCMRole,
    ngDialog,
    getMarketSegments,
    RECURRENCE_LIMIT,
    isMasterOrgFilter,
    permissionFilter,
    displayRoleFilter,
    superAdminDashboardService,
    oldDashboardCheck,
    $q,
    SHOW_RECURRENCE,
    storageManager,
    $stateParams,
    requestingOrganizationFactory,
  ) {
    pubnubNotificationFactory.countChannel('subscribe');
    $scope.moment = Moment;
    $scope.columnKey = null;

    // all const assignments
    const {
      common: { constants: { userRoles } },
      ride: { status: rideStatuses },
    } = relaylib;
    const superAdminDashboardConfig = angular.copy(superAdminDashboardService);
    const {
      issueStatuses, lateArrivalStatuses, driverAlertStatuses, statusColor,
    } = superAdminDashboardConfig;
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const { current: { name: currentState } } = $state;
    const { storage } = storageManager;
    const requestingOrganizationsVisibleCount = 20;

    const makeApiCall = {
      pending_approval: false,
      pr: true,
      issues: true,
      variance: true,
      paymentIssues: true,
      schedulingAlerts: true,
      retroQueueRides: true,
    };
    const { data: prevForm, tab: tabToSearchOn, currentPage } = storage.getDataByKey('keepFilterData', currentState) || {};

    this.dateOptions = {
      singleDatePicker: true,
      parentEl: '#current-app #page-wrapper',
      locale: {
        format: 'MM-DD-YYYY',
      },
      showDropdowns: true,
    };

    // all let assignments
    let inProcessRefresh = false;
    let fallbackRefreshTimerId;
    let lastPushMsgTime;

    //  all $scope assignments

    $scope.permissionsConstants = relaylib.permissions.permissionsConstants;
    $scope.alertTypes = superAdminDashboardConfig.alertTypes;
    $scope.isOCCMRole = isOCCMRole() || permissionFilter($scope.permissionsConstants.MARKET_SEGMENT_READ);
    $scope.userDetails = userDetails;
    $scope.marketSegmentList = [];
    $scope.extendRequestPending = false;
    $scope.tabs = {
      issues: superAdminDashboardConfig.issuesTab,
      pr: superAdminDashboardConfig.mileageTrackerTab,
      variance: superAdminDashboardConfig.varianceTab,
    };
    $scope.recurrenceTripsTab = superAdminDashboardConfig.recurrenceTripsTab;
    $scope.extendRecurrenceTripsData = {};
    $scope.modes = relaylib.productCodes.ProductCodes.filterUniqueProductCodes();
    $scope.visibleOrgChoicesCount = {};
    if (oldDashboardCheck.check()) {
      $scope.oldDashboard = true;
    } else {
      $scope.oldDashboard = false;
    }
    if (permissionFilter($scope.permissionsConstants.SHOW_SCHEDULING_ALERTS_TAB)) {
      $scope.tabs.schedulingAlerts = superAdminDashboardConfig.schedulingAlertsTab;
      if (!permissionFilter($scope.permissionsConstants.SHOW_MANAGE_BY_DROPDOWN_SCHEDULING_ALERTS_TAB)) {
        delete $scope.tabs.schedulingAlerts.tableColumns.find(column => column.columnKey === 'managedBy').isFilterable;
      }
      if (!permissionFilter($scope.permissionsConstants.SHOW_ALERT_TYPE_DROPDOWN_SCHEDULING_ALERTS_TAB)) {
        delete $scope.tabs.schedulingAlerts.tableColumns.find(column => column.columnKey === 'alertType').isFilterable;
      }
    }

    if ($scope.isOCCMRole) {
      $scope.tabs.issues.tableColumns.splice(3, 0, {
        displayText: 'Market Segment',
        columnKey: 'marketSegment',
        isSortable: true,
        sortType: null,
        sortKey: 'market_segment.name',
      });
      $scope.tabs.paymentIssues = superAdminDashboardConfig.paymentIssuesTab;
    }

    if (permissionFilter($scope.permissionsConstants.SHOW_ORG_COLUMN)) {
      $scope.tabs.pr.tableColumns.splice(2, 0, {
        displayText: 'Organization',
        key: 'organization',
      });
    }

    if (!permissionFilter($scope.permissionsConstants.SHOW_MANAGE_BY_DROPDOWN_ISSUE_TAB)) {
      delete $scope.tabs.issues.tableColumns.find(column => column.columnKey === 'managedBy').isFilterable;
    }

    if (!permissionFilter($scope.permissionsConstants.SHOW_MARKET_SEGMENT_DROPDOWN)) {
      if ($scope.tabs.variance.tableColumns.find(column => column.columnKey === 'marketSegment')) {
        delete $scope.tabs.variance.tableColumns.find(column => column.columnKey === 'marketSegment').isFilterable;
      }
      if ($scope.tabs.issues.tableColumns.find(column => column.columnKey === 'marketSegment')) {
        delete $scope.tabs.issues.tableColumns.find(column => column.columnKey === 'marketSegment').isFilterable;
      }
    }
    if (tabToSearchOn === 'issues' || !tabToSearchOn) {
      $scope.statusTypes = superAdminDashboardConfig.rideAlertStatuses;
    } else if (tabToSearchOn === 'schedulingAlerts') {
      $scope.statusTypes = superAdminDashboardConfig.alertTypes;
    }

    function setUrlFilters(tabKey) {
      if ($scope.tabs[tabKey] && $scope.tabs[tabKey].active) {
        $location.search('opentab', tabKey);
      } else if (tabKey === 'recurrenceTripsTab' && $scope.recurrenceTripsTab.active) {
        $location.search('opentab', 'recurrenceTripsTab');
      }
    }

    function activateTab(key) {
      if ($scope.tabs[key]) {
        $scope.tabs[key].active = true;
      } else if (key === 'recurrenceTripsTab') {
        $scope.recurrenceTripsTab.active = true;
      } else {
        $scope.tabs.issues.active = true;
      }
      setUrlFilters(key);
    }
    const { opentab } = $stateParams;
    if (permissionFilter($scope.permissionsConstants.SHOW_RETRO_QUEUE_TAB)) {
      $scope.tabs.retroQueueRides = superAdminDashboardConfig.retroQueueTab;
      if (opentab === 'retroQueueRides') {
        activateTab('retroQueueRides');
      }
      $scope.retroQueueDateRangeOptions = {
        parentEl: '#current-app #page-wrapper',
        format: 'MM/DD/YYYY',
        locale: {
          format: 'MM/DD/YYYY',
        },
        opens: 'left',
        eventHandlers: {
          'apply.daterangepicker': function daterangepickerEvent() {
            const elementId = 'dateRange-retroQueueRides';
            const element = document.getElementById(elementId);
            if (element) {
              const dateRangeFilter = element.value;
              if (dateRangeFilter) {
                const dateRangeFilterArr = dateRangeFilter.split(' - ');
                $scope.tabs.retroQueueRides.filterForm.data.dateRange.startDate = `${dateRangeFilterArr[0]} 00:00:00`;
                $scope.tabs.retroQueueRides.filterForm.data.dateRange.endDate = `${dateRangeFilterArr[1]} 23:59:59`;
              }
            }
          },
        },
      };
    }

    // all const functions

    function adjustPagination(tabKey) {
      $scope.tabs[tabKey].pagination.lastItem = ($scope.tabs[tabKey].pagination.currentPage - 1) * $scope.tabs[tabKey].pagination.itemsPerPage + 10;
      if ($scope.tabs[tabKey].pagination.lastItem > $scope.tabs[tabKey].pagination.totalItems) {
        $scope.tabs[tabKey].pagination.lastItem = $scope.tabs[tabKey].pagination.totalItems;
      }
    }

    function adjustRecurrencePagination() {
      const { pagination } = $scope.recurrenceTripsTab;
      pagination.lastItem = (pagination.currentPage - 1) * pagination.itemsPerPage + 10;
      if (pagination.lastItem > pagination.totalItems) {
        pagination.lastItem = pagination.totalItems;
      }
    }

    const checkAndSetStatusTypeToDanger = (tabKey, record) => {
      if (tabKey === 'schedulingAlerts' && record.ride_sorting === 2) {
        Object.assign(record, {
          statusType: 'danger',
        });
      } else if (tabKey === 'retroQueueRides' && record.ride_sorting === 2) {
        Object.assign(record, {
          statusType: 'danger',
        });
      } else {
        // write your type
      }
    };

    function setStatusTypeForData(key, statusList) {
      $scope.tabs[key].data.forEach(((ride) => {
        statusList.some((status, alertIndex) => {
          const rideStatus = ride.ride_status;
          const statusExistsInArray = _.get(statusList, `[${alertIndex}].status.constructor`) === Array && status.status.includes(rideStatus);
          if (statusExistsInArray || rideStatus === status.status) {
            Object.assign(ride, {
              statusType: status.type,
            });
            checkAndSetStatusTypeToDanger(key, ride);
            return true;
          }
          return false;
        });
      }));
    }
    function resetVisibleOrgChoiceCount() {
      Object.keys($scope.tabs).forEach((keyName) => {
        $scope.visibleOrgChoicesCount[keyName] = 20;
      });
      $scope.visibleOrgChoicesCount.recurrenceTripsTab = 20;
    }
    function saveFilterState(key) {
      if (key === 'recurrenceTripsTab') {
        const { filterForm: { data: formData } = {} } = $scope.recurrenceTripsTab;
        const matchDataCopy = angular.copy(formData);
        storage.setDataByKey('keepFilterData',
          currentState,
          { tab: key, data: matchDataCopy, currentPage: $scope.recurrenceTripsTab.pagination.currentPage || 1 });
      } else {
        const { filterForm: { data: formData } = {} } = $scope.tabs[key];
        const matchDataCopy = angular.copy(formData);
        storage.setDataByKey('keepFilterData', currentState, { tab: key, data: matchDataCopy, currentPage: $scope.tabs[key].pagination.currentPage || 1 });
      }
    }

    $scope.tabClicked = function tabClicked(tabKey) {
      if (tabKey === 'issues') {
        $scope.statusTypes = superAdminDashboardConfig.rideAlertStatuses;
      } else if (tabKey === 'schedulingAlerts') {
        $scope.statusTypes = superAdminDashboardConfig.alertTypes;
      }
      saveFilterState(tabKey);
      activateTab(tabKey);
      resetVisibleOrgChoiceCount();
      $scope.requestingOrganizations.visibleChoices = requestingOrganizationsVisibleCount;
    };

    function getTabData(key) {
      if (!makeApiCall[key]) {
        return;
      }
      makeApiCall[key] = false;
      loadingScreenFactory.showLoadingScreen();
      let url = API_BASE_URL + $scope.tabs[key].url;
      if ($scope.userDetails.role === 'admin' && !permissionFilter($scope.permissionsConstants.DASHBOARD_TAB_PAGINATION)) {
        // no pagination for Ride Managers
      } else {
        url += `${($scope.tabs[key].pagination.currentPage - 1) * $scope.tabs[key].pagination.itemsPerPage}/${$scope.tabs[key].pagination.itemsPerPage}`;
      }

      let queryParams = '';

      for (let columnIndex = 0; columnIndex < $scope.tabs[key].tableColumns.length; columnIndex += 1) {
        const tableColumn = $scope.tabs[key].tableColumns[columnIndex];
        if (tableColumn.filterValue) {
          queryParams = `${queryParams}${tableColumn.filterKey}=${tableColumn.filterValue}&`;
        }
      }

      if (key === 'paymentIssues') {
        queryParams += 'subcat=payment&';
      } else if (key === 'issues') {
        queryParams += 'subcat=general&';
      } else {
        //
      }

      if ($scope.tabs[key].showFilterForm) {
        const { filterForm: { data: formData } } = $scope.tabs[key];
        if (formData.requestingOrganization) {
          Object.assign(formData, {
            requestingOrganizationId: formData.requestingOrganization.id,
          });
        }
        Object.keys(formData).forEach((formKey) => {
          const filterData = formData[formKey];
          if (Array.isArray(filterData) && filterData.length) {
            const arrayItems = filterData.map(itemValue => itemValue.id || itemValue);
            queryParams += `${formKey}=${arrayItems}&`;
          } else if (filterData) {
            queryParams += `${formKey}=${filterData.id ? filterData.id : filterData}&`;
          }
        });
      }

      if (queryParams) {
        url = `${url}?${queryParams}`;
      }

      const tabDataCall = $http.get(url, { params: { org_id: userDetails.organisation.id } });
      tabDataCall.then(
        (result) => {
          loadingScreenFactory.hideLoadingScreen();
          if (!result.data.success) {
            // handling case where
            $scope.tabs[key].data = [];
            $scope.tabs[key].pagination.totalItems = 0;
            makeApiCall[key] = true;
            return;
          }
          $scope.tabs[key].data = result.data.data;
          $scope.tabs[key].pagination.totalItems = result.data.totalCount;
          makeApiCall[key] = true;
          adjustPagination(key);
          if (key === 'issues' || key === 'paymentIssues') {
            $scope.tabs[key].heading = $scope.tabs[key].heading.indexOf('(') >= 0
              ? $scope.tabs[key].heading.replace(/\((.*)\)/, `(${result.data.totalCount})`)
              : `${$scope.tabs[key].heading} (${result.data.totalCount})`;

            const currentTime = new Date().getTime();
            setStatusTypeForData(key, issueStatuses);
            for (let dataIndex = 0; dataIndex < $scope.tabs[key].data.length; dataIndex += 1) {
              $scope.tabs[key].data[dataIndex].last_updated_at = currentTime;
              if ($scope.tabs[key].data[dataIndex].late_arrival_flag && lateArrivalStatuses.indexOf($scope.tabs[key].data[dataIndex].ride_status) > -1) {
                $scope.tabs[key].data[dataIndex].statusType = 'warn';
                $scope.tabs[key].data[dataIndex].ride_status = 'Arrival Time Exceeded';
              } else if ($scope.tabs[key].data[dataIndex].auto_rebook_tries === 2) {
                // 3rd Driver alert case
                driverAlertStatuses.some((driverAlertStatus) => {
                  if ($scope.tabs[key].data[dataIndex].ride_status === driverAlertStatus.status) {
                    $scope.tabs[key].data[dataIndex].statusType = driverAlertStatus.type;
                    $scope.tabs[key].data[dataIndex].driverAlert = '3rd Driver Alert';
                    return true;
                  }
                  return false;
                });
              }
            }
            if ($scope.userDetails.role === 'admin' || $scope.userDetails.role === userRoles.NETWORK_OPERATION) {
              lastPushMsgTime = currentTime;
              // eslint-disable-next-line no-use-before-define
              initFallbackRefreshTimer();
            }
          } else if (key === 'pending_approval') {
            $scope.tabs[key].heading = `Pending Approval (${result.data.totalCount})`;
          } else if (key === 'pr') {
            $scope.tabs[key].heading = `Mileage Tracker (${result.data.totalCount})`;
            setStatusTypeForData(key, issueStatuses);
          } else if (key === 'variance') {
            $scope.tabs[key].heading = `RelayRIDE Variances (${result.data.totalCount})`;
          } else if (key === 'schedulingAlerts') {
            $scope.tabs[key].heading = `Scheduling Alerts (${result.data.totalCount})`;
            setStatusTypeForData(key, $scope.alertTypes);
            const rideOrderColumn = $scope.tabs[key].tableColumns.find(column => column.columnKey === 'rideOrderId');
            const currentTripIdentifier = $scope.tabs.schedulingAlerts.filterForm.data.tripIdentifierType;
            if (rideOrderColumn) {
              rideOrderColumn.displayText = (currentTripIdentifier === 'mas') ? 'MAS Invoice Number' : 'Ride Order ID';
            }
          } else if (key === 'retroQueueRides') {
            $scope.tabs[key].heading = `Retro Queue (${result.data.totalCount})`;
            setStatusTypeForData(key, superAdminDashboardConfig.retroTypes);
          } else {
            //
          }
        },
        () => {
          loadingScreenFactory.hideLoadingScreen();
          $scope.tabs[key].data = [];
          $scope.tabs[key].pagination.totalItems = 0;
          makeApiCall[key] = true;
          if (key === 'pending_approval') {
            $scope.tabs.pending_approval.heading = 'Pending Approval (0)';
          }
          adjustPagination(key);
        },
      );
    }

    const getRecurrenceTrips = function getRecurrenceTrips(tableColumn) {
      const url = API_BASE_URL + $scope.recurrenceTripsTab.url;
      const data = {
        offset: ($scope.recurrenceTripsTab.pagination.currentPage - 1) * $scope.recurrenceTripsTab.pagination.itemsPerPage,
        limit: $scope.recurrenceTripsTab.pagination.itemsPerPage,
        org_id: userDetails.organisation.id,
        order: 'createdAt,DESC',
      };

      if (tableColumn && tableColumn.isSortable) {
        const columnIndex = $scope.recurrenceTripsTab.tableColumns.findIndex(column => column.columnKey === tableColumn.columnKey);
        if (columnIndex >= 0) {
          const column = $scope.recurrenceTripsTab.tableColumns[columnIndex];
          Object.assign(column, {
            sortType:
              !column.sortType || column.sortType === 'DESC'
                ? 'ASC'
                : 'DESC',
          });
          Object.assign(data, {
            order: `${column.sortKey},${column.sortType}`,
          });
        }
      }
      if (
        userDetails.role !== 'mastersuperadmin'
        && !permissionFilter($scope.permissionsConstants.MARKET_SEGMENT_READ)
        && isMasterOrgFilter(userDetails.organisation.org_level)
      ) {
        const getMarketSegmentsCall = $http({
          url: `${API_BASE_URL}marketSegments`,
          method: 'GET',
          params: {
            operations_team: true,
          },
        });
        getMarketSegmentsCall.then(
          (result) => {
            if (result.data.success) {
              $scope.marketSegmentList = result.data.data;
              data.market_segment_list = '';
              for (let i = 0; i < $scope.marketSegmentList.length - 1; i += 1) {
                data.market_segment_list += `${$scope.marketSegmentList[i].id},`;
              }
              if ($scope.marketSegmentList.length > 0) {
                data.market_segment_list += $scope.marketSegmentList[$scope.marketSegmentList.length - 1].id;
              }

              const tabDataCall = $http.get(url, { params: data });

              tabDataCall.then(
                ({ data: { recurrenceRides, totalCount } }) => {
                  $scope.recurrenceTripsTab.data = recurrenceRides;
                  $scope.recurrenceTripsTab.pagination.totalItems = totalCount;
                  adjustRecurrencePagination();
                },
                () => {
                  $scope.recurrenceTripsTab.data = [];
                  $scope.recurrenceTripsTab.pagination.totalItems = 0;
                  adjustRecurrencePagination();
                },
              );
            } else {
              toaster.pop({
                type: 'error',
                title: 'Could not fetch recurrence alerts',
                showCloseButton: true,
                timeout: 10000,
              });
            }
          },
          (error) => {
            toaster.pop({
              type: 'error',
              title: error.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          },
        );
      } else {
        if ($scope.recurrenceTripsTab.showFilterForm) {
          const { filterForm: { data: formData } } = $scope.recurrenceTripsTab;
          Object.keys(formData).forEach((formKey) => {
            const filterData = formData[formKey];
            if (Array.isArray(filterData) && filterData.length) {
              const arrayItems = filterData.map(itemValue => itemValue.id);
              data[formKey] = arrayItems.toString();
            } else if (filterData) {
              data[formKey] = filterData.id ? filterData.id : filterData;
            }
          });
        }
        const tabDataCall = $http.get(url, { params: data });

        tabDataCall.then(
          (result) => {
            $scope.recurrenceTripsTab.data = result.data.recurrenceRides;
            $scope.recurrenceTripsTab.pagination.totalItems = result.data.totalCount;
            adjustRecurrencePagination();
          },
          () => {
            $scope.recurrenceTripsTab.data = [];
            $scope.recurrenceTripsTab.pagination.totalItems = 0;
            adjustRecurrencePagination();
          },
        );
      }
    };

    function writeSearchFilter() {
      if (!prevForm) {
        return;
      }
      if ($scope.tabs[tabToSearchOn] && prevForm) {
        $scope.tabs[tabToSearchOn].filterForm.data = prevForm;
        $scope.tabs[tabToSearchOn].pagination.currentPage = currentPage || 1;
      } else if (tabToSearchOn === 'recurrenceTripsTab' && prevForm) {
        $scope.recurrenceTripsTab.filterForm.data = prevForm;
        $scope.recurrenceTripsTab.pagination.currentPage = currentPage;
      }
    }

    $scope.searchButtonClicked = function searchButtonClicked(key) {
      saveFilterState(key);
      if ($scope.tabs[key] && $scope.tabs[key].filterForm.angularForm.$valid) {
        if (key === 'retroQueueRides') {
          const { startDate, endDate } = $scope.tabs.retroQueueRides.filterForm.data.dateRange || {};
          if (startDate && endDate) {
            $scope.tabs.retroQueueRides.filterForm.data.pickUpDateRange = JSON.stringify({ startDate, endDate });
          } else {
            $scope.tabs.retroQueueRides.filterForm.data.pickUpDateRange = null;
          }
        }
        getTabData(key);
      } else if (key === 'recurrenceTripsTab' && $scope.recurrenceTripsTab.filterForm.angularForm.$valid) {
        getRecurrenceTrips();
      }
    };

    $scope.openInvoicePage = function openInvoicePage(ride) {
      $state.go('main.dashboard.invoice', {
        appt_id: ride.appointment.id,
        retroQueueTab: true,
      });
    };

    $scope.enterOnFormSearch = function enterOnFormSearch(event, tabKey) {
      if (event.keyCode === 13) {
        $scope.searchButtonClicked(tabKey);
      }
    };

    let orderCompleteModal;
    $scope.openOrderCompleteModal = function openOrderCompleteModal(ride) {
      orderCompleteModal = $uibModal.open({
        animation: true,
        template: require('../views/retro-queue-order-complete-modal.html'),
        controller: 'retroQueueOrderCompleteModal as $ctrl',
        size: 'md',
        resolve: {
          orderDetails() {
            return ({
              apptId: ride.appointment.id,
              orderId: ride.appointment.external_order_id,
            });
          },
        },
      });
      orderCompleteModal.result.then(() => {
        getTabData('retroQueueRides');
      }, () => {
        getTabData('retroQueueRides');
      });
    };

    // Check if we received any push in last 2 minutes... if not... hit API and refresh issues list
    function checkLastPushTimeAndUpdateIssues() {
      if ($state.current.name === 'main.dashboard.superAdminDashboard') {
        if (lastPushMsgTime < new Date().getTime() - 2 * 60 * 1000) {
          // no push msg in last 2 minutes... refresh issue list
          getTabData('issues');
          getTabData('paymentIssues');
        } else {
          // we received push msg but there might be a case where some issue is still stuck in the list from more than 5 minutes
          if ($scope.tabs.issues.data && $scope.tabs.issues.data.length) {
            for (let k = 0; k < $scope.tabs.issues.data.length; k += 1) {
              if (!$scope.tabs.issues.data[k].is_read && $scope.tabs.issues.data[k].last_updated_at <= new Date().getTime() - 5 * 60 * 1000) {
                getTabData('issues');
                break;
              }
            }
          }

          if ($scope.tabs.paymentIssues.data && $scope.tabs.paymentIssues.data.length) {
            for (let k = 0; k < $scope.tabs.paymentIssues.data.length; k += 1) {
              if (!$scope.tabs.paymentIssues.data[k].is_read && $scope.tabs.paymentIssues.data[k].last_updated_at <= new Date().getTime() - 5 * 60 * 1000) {
                getTabData('paymentIssues');
                break;
              }
            }
          }
        }
      }
    }

    const initFallbackRefreshTimer = function initFallbackRefreshTimer() {
      if (fallbackRefreshTimerId) {
        clearInterval(fallbackRefreshTimerId);
      }
      fallbackRefreshTimerId = setInterval(() => {
        checkLastPushTimeAndUpdateIssues();
      }, 60000);
    };

    const getRideDetails = (rideId) => {
      loadingScreenFactory.showLoadingScreen();
      const defer = $q.defer();
      const getRideDetailsCall = $http.get(`${API_BASE_URL}ride/getRideDetailsById/${rideId}`, {
        params: {
          org_id: userDetails.organisation.id,
        },
      });
      getRideDetailsCall
        .then((result) => {
          loadingScreenFactory.hideLoadingScreen();
          defer.resolve(
            // result.data.rideDetails
            Object.assign(result.data.rideDetails, {
              oldDashboard: $scope.oldDashboard,
            }),
          );
        })
        .catch(() => {
          loadingScreenFactory.hideLoadingScreen();
          //
        });
      return defer.promise;
    };

    $scope.showClearAlertPopup = function showClearAlertPopup(rideDetails) {
      $uibModal.open({
        animation: true,
        template: require('../views/clear-alert-popup.html'),
        controller: 'clearAlertPopupCtrl',
        size: 'md',
        backdrop: 'static',
        resolve: {
          rideDetails: getRideDetails(rideDetails.id),
        },
      });
    };

    function showInProgressDetails(rideDetails) {
      loadingScreenFactory.showLoadingScreen();
      const modalInstance = $uibModal.open({
        animation: true,
        template: require('../views/inprogress_details.html'),
        controller: 'inProgressDetailsCtrl',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          rideDetails: getRideDetails(rideDetails.id),
          caller() {
            return 'dashboard';
          },
        },
      });
      Object.assign($rootScope, {
        modal: modalInstance,
      });
    }

    function displayRideModal() {
      let notification = localStorage.getItem('notification');
      notification = JSON.parse(notification);
      if (notification && notification.displayRideModal) {
        notification.id = notification.ride_id || '';
        showInProgressDetails(notification);
      }
      if (notification && (notification.type.toLowerCase() === 'potential unavailability' || notification.type.toLowerCase() === 'failed fuse')) {
        const url = $state.href('main.dashboard.createAppointment', { rideId: notification.ride_id });
        window.location.href = url;
      }
      localStorage.removeItem('notification');
    }

    // Getting count of online admins
    function getOnlineAdminsCount() {
      const url = `${API_BASE_URL}list_online_admins`;
      $http.get(url).then((response) => {
        $scope.online_admins = response.data.message;
      });
    }

    function showSpecialRideDetails(rideDetails) {
      loadingScreenFactory.showLoadingScreen();
      const modalInstance = $uibModal.open({
        animation: true,
        template: require('../views/special_ride_details.html'),
        controller: 'specialRideCtrl',
        size: 'md',
        backdrop: 'static',
        resolve: {
          rideDetails() {
            return rideDetails;
          },
          caller() {
            return 'dashboard';
          },
        },
      });
      Object.assign($rootScope, {
        modal: modalInstance,
      });
    }

    function getTilesData() {
      if (userDetails && $location.path() === '/main/dashboard/super-admin-dashboard') {
        $scope.tilesData = {};
        const endDateArr = $scope.moment().format('YYYY-MM-DD HH:mm:SS');
        const endDate = `${endDateArr.split(' ')[0]} 23:59:59`;
        const startDateToday = `${endDateArr.split(' ')[0]} 00:00:00`;
        const startDateTimeStamp = (Moment(startDateToday).unix() - 86400 * 6) * 1000;
        const startDate = Moment(startDateTimeStamp).format('YYYY-MM-DD HH:mm:SS');
        const getTilesDataCall = $http.get(`${API_BASE_URL}SchdeduledRidesCount`, {
          params: {
            startDate,
            endDate,
            startDateToday,
            org_id: userDetails.organisation.id,
          },
        });
        getTilesDataCall.then((data) => {
          if (data.data.success) {
            $scope.tilesData.scheduledApptCount = data.data.data.scheduledApptCount;
            $scope.tilesData.activeRideCount = data.data.data.activeRideCount;
            $scope.tilesData.completedApptCount = data.data.data.completedApptCount;
          }
        });
      }
    }

    async function getFeatureFlags() {
      const settingNames = 'allow_recurrence';
      const featureFlags = await $http.get(`${API_BASE_URL}setting/feature-settings/${$scope.userDetails.org_id}/${settingNames}`);
      $scope.recurrenceTripsTab.show = SHOW_RECURRENCE && !!_.get(featureFlags, 'data.setting.allow_recurrence', false);
    }

    async function getUserSettings() {
      const user = localStorage.getItem('userDetails');
      Object.assign(userDetails, JSON.parse(user));
      const headers = {
        username: userDetails.username,
        usertoken: userDetails.user_token,
        'Content-Type': 'application/json',
      };
      const objSuccess = await $http.get(`${API_BASE_URL}user/settings/`, { headers });
      const userSettings = _.get(objSuccess, 'data.data', {});

      if (permissionFilter($scope.permissionsConstants.SHOW_RECURRENCE_TRIPS_TAB)) {
        $scope.recurrenceTripsTab.show = SHOW_RECURRENCE && true;
      }
      if (
        $scope.userDetails.role === 'superadmin'
        || $scope.userDetails.role === 'mastersuperadmin'
        || permissionFilter($scope.permissionsConstants.SHOW_ALL_TABS)
      ) {
        if (userSettings.settings.admin_issues || userSettings.settings.admin_issues === undefined) {
          $scope.tabs.issues.show = true;
          $scope.tabs.paymentIssues.show = true;
        } else {
          $scope.tabs.issues.show = false;
          $scope.tabs.paymentIssues.show = false;
        }

        if (userSettings.organisation.pr_status) {
          if (userSettings.settings.admin_pr) {
            $scope.tabs.pr.show = true;
          } else {
            $scope.tabs.pr.show = false;
          }
        } else {
          $scope.tabs.pr.show = false;
        }
      }
      if (
        $scope.userDetails.role === 'admin'
        || (permissionFilter($scope.permissionsConstants.ACCESS_ISSUES_TAB) && permissionFilter($scope.permissionsConstants.ACCESS_PAYMENT_ISSUES_TAB))
      ) {
        if (userSettings.settings.admin_issues || userSettings.settings.admin_issues === undefined) {
          $scope.tabs.issues.show = true;
          $scope.tabs.paymentIssues.show = true;
        } else {
          $scope.tabs.paymentIssues.show = false;
        }

        if (userSettings.organisation.pr_status || userSettings.organisation.pr_status === undefined) {
          if (userSettings.settings.admin_pr || userSettings.settings.admin_pr === undefined) {
            $scope.tabs.pr.show = true;
          } else {
            $scope.tabs.pr.show = false;
          }
        } else {
          $scope.tabs.pr.show = false;
        }
      }
      if ($scope.userDetails.role === 'Scheduling admin') {
        if (userSettings.settings.admin_issues) {
          $scope.tabs.issues.show = true;
          $scope.tabs.paymentIssues.show = true;
        } else {
          $scope.tabs.paymentIssues.show = false;
        }

        if (userSettings.organisation.pr_status || userSettings.organisation.pr_status === undefined) {
          if (userSettings.settings.admin_pr || userSettings.settings.admin_pr === undefined) {
            $scope.tabs.pr.show = true;
          } else {
            $scope.tabs.pr.show = false;
          }
        } else {
          $scope.tabs.pr.show = false;
        }
      }
      if ($scope.userDetails.role === 'orgadmin' || $scope.userDetails.role === 'org_superadmin' || $scope.userDetails.role === 'org_reporting') {
        if (userSettings.settings.admin_issues) {
          $scope.tabs.issues.show = true;
        }

        if (userSettings.organisation.pr_status) {
          if (userSettings.settings.admin_pr) {
            $scope.tabs.pr.show = true;
          } else {
            $scope.tabs.pr.show = false;
          }
        } else {
          $scope.tabs.pr.show = false;
        }
      }
    }

    // const assignManagerToIssuesAndAlertsOnUI = ({ issueType: tabKey = 'issues', assignedIssuesData = [] }) => {
    //   $scope.tabs[tabKey].data.forEach((issue) => {
    //     const matchedIssue = assignedIssuesData.find(assignedIssue => assignedIssue.rideId === issue.id);
    //     if (matchedIssue) {
    //       Object.assign(issue.user, {
    //         id: matchedIssue.managerId,
    //       });
    //     }
    //   });
    //   $scope.$apply();
    // };

    const updateStatusOfIssueOnUI = (notificationData) => {
      let tabKey = 'issues';
      if (notificationData.issueType === 'alert') {
        tabKey = 'schedulingAlerts';
      }
      for (let issueIndex = 0; issueIndex < $scope.tabs[tabKey].data.length; issueIndex += 1) {
        if (notificationData.rideId === $scope.tabs[tabKey].data[issueIndex].id) {
          $scope.tabs[tabKey].data[issueIndex].ride_status = notificationData.status;
          $scope.tabs[tabKey].data[issueIndex].ride_sorting = notificationData.ride_sorting;
          checkAndSetStatusTypeToDanger(tabKey, $scope.tabs[tabKey].data[issueIndex]);
          break;
        }
      }
      $scope.$apply();
    };

    const executeMasterNotificationHandlers = () => {
      $scope.$on(Pubnub.getMessageEventNameFor('msa_presence_channel'), (event, payload) => {
        const { message } = payload;
        if (
          message.type === 'issuesAssigned'
          && $state.current.name === 'main.dashboard.superAdminDashboard'
          && message.refreshDashboard
        ) {
          // assignManagerToIssuesAndAlertsOnUI(message);
          if (message.issueType === 'issues') {
            getTabData('issues');
          } else if (
            message.issueType === 'schedulingAlerts'
            || message.issueType === 'failed'
          ) {
            getTabData('schedulingAlerts');
          }
        }
      });
    };

    const refreshAllTabs = () => {
      if (permissionFilter($scope.permissionsConstants.SHOW_VARIANCE_TAB_DASHBOARD)) {
        getTabData('variance');
      }

      getTabData('issues');
      getTabData('paymentIssues');
      if (permissionFilter($scope.permissionsConstants.SHOW_SCHEDULING_ALERTS_TAB)) {
        getTabData('schedulingAlerts');
      }
      if (permissionFilter($scope.permissionsConstants.SHOW_RETRO_QUEUE_TAB)) {
        getTabData('retroQueueRides');
      }
    };

    const executeUserIdNotificationHandlers = () => {
      $scope.$on(Pubnub.getMessageEventNameFor(userDetails.user_id), (event, payload) => {
        const { message } = payload;
        if (message.type === 'recurrence-rides') {
          $scope.refreshRecurrenceTabs();
        }
        if (message.type === 'Ride Switch' && $scope.userDetails.role !== 'admin') {
          getTabData('pending_approval');
          getTabData('pr');
          $scope.$apply();
        } else if (message.appt_status === 'Pending Approval' && $scope.userDetails.role !== 'admin') {
          getTabData('pending_approval');
          $scope.$apply();
        } else if (message.type === 'Update Ride Manager' && ![userRoles.RIDE_MANAGER, userRoles.NETWORK_OPERATION].includes($scope.userDetails.role)) {
          for (let index = 0; index < $scope.tabs.issues.data.length; index += 1) {
            if ($scope.tabs.issues.data[index].id === message.ride_id) {
              $scope.tabs.issues.data[index].user.id = message.user_id;
            }
          }
        } else if (message.type === 'statusChangedForIssue' && $state.current.name === 'main.dashboard.superAdminDashboard') {
          updateStatusOfIssueOnUI(message);
        } else if (message.type === 'issuesAssigned' && $state.current.name === 'main.dashboard.superAdminDashboard') {
          refreshAllTabs();
        } else if (
          message.ride_data
          && ['completed', 'issue', 'active', 'alert'].includes(message.ride_data.ride_category)
          && $state.current.name === 'main.dashboard.superAdminDashboard'
        ) {
          if (
            ['completed', 'issue', 'alert'].includes(message.ride_data.ride_category)
            || ['refresh_issues', 'refresh_variance_issues'].includes(message.type)
          ) {
            refreshAllTabs();
          }
          $scope.$apply();
        }
      });
    };

    const listenForPubnubMessages = function listenForPubnubMessages() {
      if (permissionFilter($scope.permissionsConstants.SUBSCRIBE_MASTER_PRESENCE)) {
        executeMasterNotificationHandlers();
      }
      executeUserIdNotificationHandlers();
    };

    function listenOnlineAdmins() {
      $scope.$on(Pubnub.getMessageEventNameFor('online_admins_channel'), (event, payload) => {
        const { message: { admins, networkOperations, count } } = payload;
        $scope.admins = admins;
        $scope.networkOperationsList = networkOperations;
        $scope.online_admins = count;
        $scope.$apply();
      });
    }

    function listenCounts() {
      const operationTeams = (userDetails.operations_teams.length > 0)
        ? userDetails.operations_teams.map(val => `op_team_${val.operations_team_id}`) : null;
      let channels = [];
      const masterRoles = [userRoles.MASTER_SUPER_ADMIN, userRoles.PRODUCTION_OPERATION];
      const otherRoles = [userRoles.SCHEDULING_ADMIN, userRoles.RIDE_MANAGER, userRoles.SUPER_ADMIN, userRoles.NETWORK_OPERATION];
      const isMasterRole = masterRoles.includes(userDetails.role);
      const ifHasPermission = permissionFilter($scope.permissionsConstants.GET_TILES_COUNT);
      const isOtherRole = otherRoles.includes(userDetails.role);
      const operationsTeamCheck = operationTeams && operationTeams.length > 0;
      if (isMasterRole || ifHasPermission) {
        channels.push('all_count');
      } else if (operationsTeamCheck && isOtherRole) {
        channels = channels.concat(operationTeams);
      } else {
        channels.push(`org_id_${userDetails.org_id}`);
      }

      channels.forEach((channel) => {
        $scope.$on(Pubnub.getMessageEventNameFor(channel), () => {
          $scope.$broadcast('refreshTilesCount');
        });
      });
    }


    // all $scope functions

    $scope.goToCreateApptPage = ({
      id: rideId,
      ride_status: rideStatus,
      appointment: {
        external_order_id: externalOrderId,
        organisation: {
          org_type: orgType,
        },
      },
    }) => {
      let stateToNavigate = 'main.dashboard.createAppointment';
      if (orgType === 'mas'
        && [
          rideStatuses.POTENTIAL_UNAVAILABILITY.status,
          rideStatuses.VALIDATION_FAILED.status,
        ].includes(rideStatus)) {
        stateToNavigate = 'main.dashboard.masOrderDetails';
      } else if (externalOrderId) {
        stateToNavigate = 'main.dashboard.rideOrderDetails';
      }
      $state.go(stateToNavigate, { rideId, caller: 'dashboard' });
    };

    $scope.varianceNameFromType = (type) => {
      switch (type) {
        case 2:
          return 'Variance';
        case 3:
          return 'Variance Under';
        case 4:
          return 'Variance Over';
        default:
          return 'Variance';
      }
    };

    $scope.toggleSort = (tableColumn, tabKey) => {
      if (tabKey === 'recurrenceTripsTab') {
        getRecurrenceTrips(tableColumn);
        return;
      }
      if (!tableColumn.isSortable) {
        return;
      }
      let categoryType = tabKey;
      Object.assign(tableColumn, {
        sortType: !tableColumn.sortType || tableColumn.sortType === 'DESC' ? 'ASC' : 'DESC',
      });
      if (tabKey === 'paymentIssues') {
        categoryType = 'issues';
      }
      $scope.tabs[tabKey].url = `rides/getRidesListByRideCategory/${categoryType}/${tableColumn.sortKey} ${tableColumn.sortType} NULLS LAST/`;
      $scope.columnKey = tableColumn.sortKey;
      getTabData(tabKey);
    };

    $scope.pageChanged = (tabKey) => {
      getTabData(tabKey);
      saveFilterState(tabKey);
    };

    $scope.recurrencePageChanged = () => {
      getRecurrenceTrips();
      saveFilterState('recurrenceTripsTab');
    };

    $scope.extendRecurrenceCloseClicked = () => {
      $scope.closeExtendCalender();
      $scope.extendRecurrenceModal.close();
    };

    $scope.extendRecurrenceOpenClicked = function extendRecurrenceOpenClicked(id, pattern, startBy) {
      $scope.closeExtendCalender();
      $scope.extendRequestPending = false;
      $scope.extendRecurrenceTripsData = {
        date: '',
        error: null,
        recurrence_id: id,
        actualStartDate: startBy,
        pattern,
      };

      $scope.extendRecurrenceModal = ngDialog.open({
        template: 'ngExtendRecurrence',
        className: 'ngdialog-theme-default',
        scope: $scope,
        name: 'ngExtendRecurrence',
        closeByNavigation: true,
      });
    };

    $scope.extendButtonClicked = function extendButtonClicked() {
      $scope.extendRequestPending = true;
      const extendRequest = $http.put(`${API_BASE_URL}recurring-rides/${$scope.extendRecurrenceTripsData.recurrence_id}/extend`, {
        endBy: new Moment($scope.extendRecurrenceTripsData.date).format('YYYY-MM-DD'),
      });
      extendRequest.then(
        (result) => {
          $scope.extendRequestPending = false;
          if (result.data && result.data.success) {
            toaster.pop({
              type: 'success',
              title: 'Recurrence trip end date is extended successfully',
              showCloseButton: true,
              timeout: 10000,
            });
            $scope.extendRecurrenceCloseClicked();
            $scope.refreshRecurrenceTabs();
          } else if (result.data && result.data.message) {
            toaster.pop({
              type: 'error',
              title: result.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          } else {
            toaster.pop({
              type: 'error',
              title: 'Could not extend this trip. Unkown error occurred.',
              showCloseButton: true,
              timeout: 10000,
            });
          }
        },
        (error) => {
          $scope.extendRequestPending = false;
          if (error.data && error.data.message) {
            toaster.pop({
              type: 'error',
              title: error.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          } else {
            toaster.pop({
              type: 'error',
              title: 'Could not extend this trip. Unkown error occurred.',
              showCloseButton: true,
              timeout: 10000,
            });
          }
        },
      );
    };

    $scope.clearAlertButtonClicked = function clearAlertButtonClicked() {
      $scope.extendRequestPending = true;
      const clearRequest = $http.put(`${API_BASE_URL}recurring-rides/${$scope.extendRecurrenceTripsData.recurrence_id}/clear`);
      clearRequest.then(
        (result) => {
          $scope.extendRequestPending = false;
          if (result.data && result.data.success) {
            toaster.pop({
              type: 'success',
              title: 'Alert cleared for this recurrence series',
              showCloseButton: true,
              timeout: 10000,
            });
            $scope.extendRecurrenceCloseClicked();
            $scope.refreshRecurrenceTabs();
          } else if (result.data && result.data.message) {
            toaster.pop({
              type: 'error',
              title: result.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          } else {
            toaster.pop({
              type: 'error',
              title: 'Could not clear alert for this recurrence series. Unknown error occurred.',
              showCloseButton: true,
              timeout: 10000,
            });
          }
        },
        (error) => {
          $scope.extendRequestPending = false;
          if (error.data && error.data.message) {
            toaster.pop({
              type: 'error',
              title: error.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          } else {
            toaster.pop({
              type: 'error',
              title: 'Could not clear alert for this recurrence series. Unknown error occurred.',
              showCloseButton: true,
              timeout: 10000,
            });
          }
        },
      );
    };

    $scope.rejectAppointment = function rejectAppointment(ride) {
      SweetAlert.swal(
        {
          title: 'Reject Trip?',
          text: 'Are you sure you want to reject this trip?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Ok',
        },
        (isConfirm) => {
          if (isConfirm) {
            const params = {
              ride_category: 'completed',
              ride_status: 'Ride Canceled',
              dontupdatedate: true,
            };
            $http.put(`${API_BASE_URL}ride/update/${ride.id}`, params).then(
              ({ data: { success } }) => {
                if (success) {
                  SweetAlert.swal('Rejected', 'Trip rejected successfully', 'success');
                  getTabData('pending_approval');
                }
              },
              () => {
              },
            );
          }
        },
      );
    };

    $scope.takeCorrespondingAction = (rideDetails, issueIndex, key, redirectAllow = true) => {
      if (!rideDetails.is_read) {
        const issueReadUrl = `${API_BASE_URL}ride/update/${rideDetails.id}`;
        const params = {
          is_read: true,
        };

        const issueReadCall = $http.put(issueReadUrl, params);
        issueReadCall.then(
          () => {
            $scope.tabs[key].data[issueIndex].is_read = true;
          },
          () => {
            //
          },
        );
      }
      if (redirectAllow) {
        if ([rideStatuses.POTENTIAL_UNAVAILABILITY.status, rideStatuses.FAILED_FUSE.status].includes(rideDetails.ride_status)
          || key === 'schedulingAlerts') {
          $scope.goToCreateApptPage(rideDetails);
        } else if (rideDetails.ride_status === 'Special Ride') {
          showSpecialRideDetails(rideDetails);
        } else if (key !== 'schedulingAlerts') {
          showInProgressDetails(rideDetails);
        }
      }
    };

    // immediately invoked functions
    if (userDetails.role === 'tempBusiness' || userDetails.role === 'tempRider') {
      redirectUserHomeIfLoggedInService();
      return;
    }
    if (userDetails.organisation.childrenCount > 0) {
      $state.go('main.dashboard.parentOrgDashboard');
    }

    let isOpTeam = true;
    if (userDetails.role === 'mastersuperadmin' || userDetails.role === 'productionOperation') {
      isOpTeam = false;
    }
    getMarketSegments((err, data) => {
      if (!err) {
        $scope.marketSegmentList = data;
      } else {
        toaster.pop({
          type: 'error',
          title: err.msg || err,
          showCloseButton: true,
          timeout: 10000,
        });
      }
    }, isOpTeam ? { operations_team: isOpTeam } : {});

    $scope.openExtendCalender = (event) => {
      event.stopPropagation();
      $timeout(() => {
        angular.element('#extend-date').triggerHandler('focus');
      }, 100);
    };

    $scope.closeExtendCalender = () => {
      $timeout(() => {
        angular.element('#extend-date').triggerHandler('blur');
      }, 100);
    };

    $scope.extendDateChange = () => {
      if (!$scope.extendRecurrenceTripsData.date) {
        $scope.extendRecurrenceTripsData.error = 'Please provide extend date value';
        return;
      }
      const { pattern, date, actualStartDate } = $scope.extendRecurrenceTripsData;
      if (date.isBefore(new Moment(new Date().getTime()), 'days')) {
        $scope.extendRecurrenceTripsData.error = 'Extend date cannot be before today';
        return;
      }

      if (pattern === 'weekly') {
        const dateInTimeZone = moment(actualStartDate).format('YYYY-MM-DD');
        if (date.isAfter(new Moment(dateInTimeZone).add(RECURRENCE_LIMIT - 1, 'days'), 'days')) {
          $scope.extendRecurrenceTripsData.error = 'Rides cannot be scheduled more than 1 year from current date.';
          return;
        }
      }
      if (date.isAfter(new Moment(new Date().getTime()).add(RECURRENCE_LIMIT - 1, 'days'), 'days')) {
        $scope.extendRecurrenceTripsData.error = 'Rides cannot be scheduled more than 1 year from current date.';
        return;
      }
      if ($scope.extendRecurrenceTripsData.error) {
        $scope.extendRecurrenceTripsData.error = null;
      }
    };

    $scope.toggleFilterDisplay = (event, tableColumn) => {
      event.stopPropagation();
      Object.assign(tableColumn, {
        showFilter: !tableColumn.showFilter,
      });
    };

    $scope.getStyle = (statusType) => {
      let color = 'white';
      if (statusType === 'plain') {
        color = 'black';
      }
      return { color, 'font-weight': 'bold', 'background-color': statusColor[statusType] };
    };

    $scope.showTripDateTime = ({ appt_date_time: apptDateTime, pickup_date_time: pickDateTime, appt_timezone: apptTimeZone }) => {
      if (apptDateTime === '0' && !pickDateTime) {
        return 'N/A';
      }
      const dateField = apptDateTime !== '0' ? apptDateTime : pickDateTime;
      const dateFormat = 'MM/DD/YYYY h: mm a ';

      return `${moment(dateField * 1000).tz(apptTimeZone).format(dateFormat)} (${moment(dateField * 1000).tz(apptTimeZone).zoneAbbr()})`;
    };

    $scope.goToCompletedRequests = () => {
      $state.go('main.dashboard.completed', { caller: 'sevenDays' });
    };

    $scope.goToActiveRides = () => {
      $state.go('main.dashboard.active');
    };

    $scope.goToScheduledAppts = () => {
      $state.go('main.dashboard.scheduled');
    };

    $scope.refreshTabs = (args) => {
      let key = 'issues';
      if (args.toLowerCase().indexOf('issues') === -1) {
        key = 'pending_approval';
      }
      getTabData(key);
    };

    $scope.refreshRecurrenceTabs = () => {
      getRecurrenceTrips();
    };

    $scope.filterAdminsEligibleForIssue = organisation => (value, index, array) => (
      array[index].id === 0
      || array[index].alert_org_ids.includes(organisation.id)
      || (array[index].market_segment_ids || []).includes(organisation.market_segment_id)
    );

    $scope.updateManager = (rideDetails, tabKey) => {
      const adminId = rideDetails.user.id;
      if (!adminId) {
        return;
      }
      const urlToUpdateMangerOfRide = `${API_BASE_URL}update_ride_manager`;
      const params = {
        adminId,
        rideId: rideDetails.id,
        orgId: rideDetails.org_id,
        issueType: tabKey,
      };
      const updateManagerCall = $http.put(urlToUpdateMangerOfRide, params);
      updateManagerCall.then(
        (result) => {
          if (result) {
            let role = userRoles.RIDE_MANAGER;
            if (tabKey === 'schedulingAlerts') {
              role = userRoles.NETWORK_OPERATION;
            }
            SweetAlert.swal({
              title: `${displayRoleFilter(role)} updated`,
              text: `${displayRoleFilter(role)} updated successfully`,
              type: 'success',
            });
          }
        },
        (err) => {
          toaster.pop({
            type: 'error',
            title: err.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    };

    $scope.getOnlineAdminsLabel = (role) => {
      if (role === userRoles.RIDE_MANAGER) {
        return 'Ride Managers';
      }
      if ([userRoles.NETWORK_OPERATION, userRoles.PRODUCTION_OPERATION].includes(role)) {
        return displayRoleFilter(role);
      }
      return `${displayRoleFilter(role)}s`;
    };

    $scope.filterTabData = (event, tabKey) => {
      event.stopPropagation();
      getTabData(tabKey);
    };

    $scope.showDisplayStatus = (rideStatus, key) => {
      let statusTypes;
      if (key === 'retroQueueRides') {
        statusTypes = superAdminDashboardConfig.retroTypes;
      } else {
        statusTypes = $scope.alertTypes;
      }
      const alertObject = _.find(statusTypes, (alertType) => {
        const statusExistsInArray = _.get(alertType, 'status.constructor') === Array && alertType.status.includes(rideStatus);
        return statusExistsInArray || alertType.status === rideStatus;
      });
      return _.get(alertObject, 'displayMessage');
    };

    // in case of approving of appointments,there is no pubnub notification
    // so manually update of counters are there
    $scope.$on('refreshTilesCount', () => {
      if (userDetails && $location.path() === '/main/dashboard/super-admin-dashboard') {
        if (!inProcessRefresh) {
          inProcessRefresh = true;
          const endDateArr = $scope.moment().format('YYYY-MM-DD HH:mm:SS');
          const endDate = `${endDateArr.split(' ')[0]} 23:59:59`;
          const startDateToday = `${endDateArr.split(' ')[0]} 00:00:00`;
          const startDateTimeStamp = (Moment(startDateToday).unix() - 86400 * 6) * 1000;
          const startDate = Moment(startDateTimeStamp).format('YYYY-MM-DD HH:mm:SS');
          const getTilesDataCall = $http.get(`${API_BASE_URL}SchdeduledRidesCount?startDate=${startDate}&endDate=${endDate}&startDateToday=${startDateToday}`);
          getTilesDataCall.then((data) => {
            if (data.data.success) {
              $scope.tilesData.scheduledApptCount = data.data.data.scheduledApptCount;
              $scope.tilesData.activeRideCount = data.data.data.activeRideCount;
              $scope.tilesData.completedApptCount = data.data.data.completedApptCount;
              inProcessRefresh = false;
            }
          });
        } else {
          //
        }
      }
    });

    $scope.$on('$destroy', () => {
      //
      if (orderCompleteModal) {
        orderCompleteModal.dismiss('cancel');
      }
    });

    $rootScope.$on('refreshIssuesTab', () => {
      getTabData('issues');
      getTabData('paymentIssues');
    });

    $scope.$on('$destroy', () => {
      pubnubNotificationFactory.countChannel('unsubscribe');
      if ($scope.extendRecurrenceModal) {
        $scope.extendRecurrenceModal.close();
      }
    });

    $scope.$on('refreshSchedulingAlertsTab', () => {
      getTabData('schedulingAlerts');
    });

    if ($location.path() === '/main/dashboard/super-admin-dashboard' && permissionFilter($scope.permissionsConstants.GET_ONLINE_ADMINS)) {
      getOnlineAdminsCount();
    }

    if (permissionFilter($scope.permissionsConstants.GET_ONLINE_ADMINS) && $location.path() === '/main/dashboard/super-admin-dashboard') {
      const getAllOnlineAdmins = () => {
        const getAllAdminsCall = $http.get(`${API_BASE_URL}get_all_admins`);
        getAllAdminsCall.then(
          (result) => {
            if (result.data) {
              $scope.admins = result.data.data;
            }
          },
          () => {
            $scope.admins = [];
          },
        );
      };
      const getAllNetworkOperations = () => {
        const getAllNetworkOperationsCall = $http.get(`${API_BASE_URL}admin/networkOperations`);
        getAllNetworkOperationsCall.then(
          (result) => {
            if (result.data) {
              $scope.networkOperationsList = result.data.data;
            }
          },
          () => {
            $scope.networkOperationsList = [];
          },
        );
      };
      getAllOnlineAdmins();
      getAllNetworkOperations();
    }

    $scope.loadMoreChoices = (key) => {
      $scope.visibleOrgChoicesCount[key] += 20;
    };

    $scope.tripIdentifierChanged = function tripIdentifierChanged(key) {
      $scope.tabs[key].filterForm.data.orderId = null;
      $scope.tabs[key].filterForm.data.masInvoiceNumber = null;
      $scope.tabs[key].filterForm.data.fileNo = null;
    };

    const getAllOrgsForSelectBox = async () => {
      loadingScreenFactory.showLoadingScreen();
      try {
        const { data: { organisations } } = await $http.get(`${API_BASE_URL}organisations`, {
          params: {
            marketSegment: true,
            fields: ['orgId', 'orgName'],
          },
        });
        $scope.organizations = (organisations || []);
      } catch (error) {
        toaster.pop({
          type: 'error',
          title: error.data && error.data.message ? error.data.message : 'Could not fetch organizations.',
          showCloseButton: true,
          timeout: 10000,
        });
      } finally {
        loadingScreenFactory.hideLoadingScreen();
      }
    };

    $scope.loadMoreChoicesForRequestingOrganizations = () => {
      $scope.requestingOrganizations.visibleChoices += requestingOrganizationsVisibleCount;
    };

    const getRequestingOrganizations = async (organizationId) => {
      try {
        const { data: { organisations: requestingOrganizations } } = await requestingOrganizationFactory.getRequestingOrganizations({
          organizationId,
          fields: ['orgId', 'orgName'],
        });
        $scope.requestingOrganizations = {
          data: requestingOrganizations,
          visibleChoices: requestingOrganizationsVisibleCount,
        };
      } catch (error) {
        toaster.pop({
          type: 'error',
          title: error.message || 'Unable to fetch Requesting Organizations',
          showCloseButton: true,
          timeout: 10000,
        });
      } finally {
        $scope.$apply();
      }
    };

    $scope.clearFilters = function clearFilters(key) {
      const prevSearchValues = storage.getDataByKey('keepFilterData', currentState);
      if (prevSearchValues && prevSearchValues.tab === key) {
        storage.removeDataByKey('keepFilterData', currentState);
      }

      if ($scope.tabs[key]) {
        Object.keys($scope.tabs[key].filterForm.data).forEach((keyName) => {
          $scope.tabs[key].filterForm.data[keyName] = null;
        });
        getTabData(key);
      } else {
        Object.keys($scope.recurrenceTripsTab.filterForm.data).forEach((keyName) => {
          $scope.recurrenceTripsTab.filterForm.data[keyName] = null;
        });
        getRecurrenceTrips();
      }
      getRequestingOrganizations();
    };

    $scope.orgChanged = (organizations, key) => {
      let organizationIds = organizations.map(organization => organization.id);
      organizationIds = organizationIds.length ? organizationIds : undefined;
      getRequestingOrganizations(organizationIds);
      $scope.tabs[key].filterForm.data.requestingOrganization = undefined;
    };

    (async () => {
      await getFeatureFlags();
      await getUserSettings();
      writeSearchFilter();
      getRecurrenceTrips();
      Object.keys($scope.tabs).forEach(key => getTabData(key));
      activateTab(tabToSearchOn);
      $scope.$watch('extendRecurrenceTripsData.date', $scope.extendDateChange);
      resetVisibleOrgChoiceCount();
      listenForPubnubMessages();
      listenOnlineAdmins();
      listenCounts();
      getTilesData();
      displayRideModal();
      getRequestingOrganizations();
      if (permissionFilter($scope.permissionsConstants.SHOW_DASHBOARD_FILTERS)) {
        getAllOrgsForSelectBox();
      }
      $scope.$apply();
    })();
  },
]);
