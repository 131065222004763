import moment from 'moment';
import app from '../../app';
import claimLoaderTemplate from '../appointment/claim-loader.html';

app
  .controller('riderListCntrl', [
    '$scope',
    '$timeout',
    'API_BASE_URL',
    '$http',
    'toaster',
    '$stateParams',
    'SweetAlert',
    'loadingScreenFactory',
    'GOOGLE_PLACES_ATTRIBUTES',
    'ngDialog',
    '$transitions',
    function riderListCntrl(
      $scope,
      $timeout,
      API_BASE_URL,
      $http,
      toaster,
      $stateParams,
      SweetAlert,
      loadingScreenFactory,
      GOOGLE_PLACES_ATTRIBUTES,
      ngDialog,
      $transitions,
    ) {
      $scope.invitationList = [];
      $scope.pageTitle = 'Manage Riders';
      $scope.googlePlacesAttributes = GOOGLE_PLACES_ATTRIBUTES;
      $scope.now = moment();
      $scope.addRide = {
        claimInformation: {},
      };
      $scope.addRide.idType = 'Other';
      $scope.addRide.addressMain = '';
      $scope.organisation = {
        name: $stateParams.org_name,
        orgId: $stateParams.org_id,
      };
      $scope.sendInviteType = [];
      $scope.addressesAllowed = [];
      $scope.daysList = [{ day: 'Monday' },
        { day: 'Tuesday' },
        { day: 'Wednesday' },
        { day: 'Thursday' },
        { day: 'Friday' },
        { day: 'Saturday' },
        { day: 'Sunday' },
      ];
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      const datePickerToggleState = {
        '#riderDob': false,
        '#riderDoi': false,
      };
      const settingsConstants = {
        accountLookup: 'account_lookup',
        claimLookup: 'claim_lookup',
        futureLookup: 'future_lookup',
        payer: 'payer',
      };
      const billingOptions = {
        [settingsConstants.accountLookup]: {
          label: 'My Account',
          showPayerName: false,
          showClaimNumber: false,
        },
        [settingsConstants.claimLookup]: {
          label: 'Claim',
          showPayerName: true,
          showClaimNumber: true,
          showHomeAddress: true,
          isInjuryDateMandatory: true,
        },
        [settingsConstants.futureLookup]: {
          label: 'Future Claim',
          showPayerName: true,
          showClaimNumber: false,
          showHomeAddress: true,
          isInjuryDateMandatory: true,
          isDobMandatory: true,
        },
      };
      let isClaimNumberDetailFetched = false;


      $scope.billingOptionsDefault = {
        account_lookup: {
          display_name: 'My Account',
          value: 'account_lookup',
          fieldsToBeValidatedOnForm: [
            'firstName',
            'lastName',
            'email',
            'phone',
            'idValue',
            'idType',
            'startDate',
            'endDate',
            'legs',
            'amount',
            'milesPerLeg',
            'totalMilesAllowed',
            'daysAllowed',
            'timeFrom',
            'timeTo',
            'address',
          ],
        },
        claim_lookup: {
          display_name: 'Claim',
          value: 'claim_lookup',
          fieldsToBeValidatedOnForm: [
            'firstName',
            'lastName',
            'email',
            'phoneNo',
            'claimNumber',
            'payerName',
            'idType',
            'dateOfInjury',
            'adjusterHomeAddress',
            'startDate',
            'endDate',
            'legs',
            'amount',
            'milesPerLeg',
            'totalMilesAllowed',
            'daysAllowed',
            'timeFrom',
            'timeTo',
            'address',
          ],
        },
        future_lookup: {
          display_name: 'Future Claim',
          value: 'future_lookup',
          fieldsToBeValidatedOnForm: [
            'payerName',
            'phoneNo',
            'firstName',
            'lastName',
            'email',
            'idType',
            'dateOfBirth',
            'dateOfInjury',
            'adjusterHomeAddress',
            'startDate',
            'endDate',
            'legs',
            'amount',
            'milesPerLeg',
            'totalMilesAllowed',
            'daysAllowed',
            'timeFrom',
            'timeTo',
            'address',
          ],
        },
      };
      const allDateFields = ['startDate', 'endDate', 'dateOfBirth', 'dateOfInjury'];
      $scope.dateOptions = {
        singleDatePicker: true,
        parentEl: '#current-app #page-wrapper',
        locale: {
          format: 'YYYY-MM-DD',
        },
        showDropdowns: true,
        eventHandlers: {
          'showCalendar.daterangepicker': ({ picker: { element: [{ id }] } }) => {
            datePickerToggleState[`#${id}`] = true;
          },
          'hideCalendar.daterangepicker': ({ picker: { element: [{ id }] } }) => {
            datePickerToggleState[`#${id}`] = false;
          },
        },
      };

      $scope.toggleDatePicker = function toogleDatePicker(elementName) {
        if (datePickerToggleState[elementName]) {
          datePickerToggleState[elementName] = false;
          $timeout(() => {
            $(elementName).data('daterangepicker').hide();
          });
        } else {
          datePickerToggleState[elementName] = true;
          $timeout(() => {
            $(elementName).data('daterangepicker').show();
          });
        }
      };

      $scope.pagination = {
        totalItems: 0,
        currentPage: 1,
        lastItem: 0,
        itemsPerPage: 20,
      };

      $scope.billingOptions = {};

      const getPayersList = function getPayersList() {
        $scope.payersList = [$scope.orgDetails.org_payer] || [];
      };

      function setPayerSetting(settings = []) {
        $scope.isPayerSettingTrue = !!settings.find(setting => setting.setting_name === settingsConstants.payer
          && setting.setting_value.toLowerCase() === 'true' && ($scope.orgDetails.org_payer || {}).id);
      }

      function makeViewChanges() {
        $scope.billingOptions.showPayerName = billingOptions[$scope.addRide.billingType].showPayerName;
        getPayersList();
        $scope.billingOptions.showClaimNumber = billingOptions[$scope.addRide.billingType].showClaimNumber && ($scope.addRide.claimInformation || {}).payerId && $scope.billingOptions.showPayerName;
        $scope.billingOptions.showHomeAddress = billingOptions[$scope.addRide.billingType].showHomeAddress;
        $scope.billingOptions.isDobMandatory = billingOptions[$scope.addRide.billingType].isDobMandatory;
        $scope.billingOptions.isInjuryDateMandatory = billingOptions[$scope.addRide.billingType].isInjuryDateMandatory;

        if ($scope.addRide.billingType !== settingsConstants.accountLookup) {
          const checkOrgClaimSearchAvailability = $http.get(`${API_BASE_URL}organisation/checkClaimSearch/${$stateParams.org_id}/${$scope.addRide.billingType}`);
          checkOrgClaimSearchAvailability.then(
            (result) => {
              if (!result.data.success) {
                toaster.pop({
                  type: 'error',
                  title: result.data.message,
                  showCloseButton: true,
                  timeout: 10000,
                });
                $scope.resetAddRiderForm();
                $scope.addRide.billingType = settingsConstants.accountLookup
              }
            },
            (error) => {
              toaster.pop({
                type: 'error',
                title: error.data ? error.data.message : '',
                showCloseButton: true,
                timeout: 10000,
              });
            },
          );
        }
      }

      function makeClaimInfoModelChanges() {
        $scope.addRide.claimInformation = {
          payerId: ($scope.orgDetails.org_payer || {}).id,
        };
      }

      $scope.billingOptionChanged = () => {
        isClaimNumberDetailFetched = false;
        $scope.isAnyRiderFieldInvalid = false;
        makeClaimInfoModelChanges();
        makeViewChanges();
        
      };

      function setBillingOptions(settings = []) {
        $scope.billingOptions.activeBillingOptions = [];
        const billingOptionListKeys = Object.keys(billingOptions);
        settings.forEach((setting) => {
          if (billingOptionListKeys.includes(setting.setting_name) && (setting.setting_value).toLowerCase() === 'true') {
            $scope.billingOptions.activeBillingOptions.push({ key: setting.setting_name, label: billingOptions[setting.setting_name].label });
          }
        });
      }

      const getSelectedOrgDetails = async orgID => new Promise((resolve, reject) => {
        const getSelectedOrgDetailsCall = $http({
          url: `${API_BASE_URL}organisation/getOrgDetailsById/${orgID}`,
          method: 'GET',
        });
        getSelectedOrgDetailsCall.then(
          (result) => {
            if (result.data && result.data.success) {
              $scope.orgDetails = result.data.orgDetails;
              const customizedIDNumber = _.find($scope.orgDetails.settings, {
                setting_name: 'customized_id_number',
              });
              $scope.customizedIDNumber = (customizedIDNumber && customizedIDNumber.setting_value.toLowerCase() === 'true');
              if ($scope.customizedIDNumber) {
                $scope.addRide.idType = 'Other';
                const customizedIdNumberLabel = _.find($scope.orgDetails.settings, {
                  setting_name: 'customized_id_number_label',
                });
                if (customizedIdNumberLabel && customizedIdNumberLabel.setting_value) {
                  $scope.customizedIdNumberLabel = customizedIdNumberLabel.setting_value;
                }
              }
              setPayerSetting($scope.orgDetails.settings);
              setBillingOptions($scope.orgDetails.settings);
              resolve();
            } else {
              toaster.pop({
                type: 'error',
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
              resolve();
            }
          },
          (error) => {
            toaster.pop({
              type: 'error',
              title: error.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
            reject();
          },
        );
      });
      const adjustPagination = function adjustPagination() {
        $scope.pagination.lastItem = ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage + 20;
        if ($scope.pagination.lastItem > $scope.pagination.totalItems) {
          $scope.pagination.lastItem = $scope.pagination.totalItems;
        }
      };

      const getSearchString = () => {
        const searchStr = [];
        const filterVal = $scope.filterRider;
        Object.keys(filterVal).forEach((key) => {
          if (filterVal[key]) {
            if (key === 'phone') {
              const phone = filterVal[key]
                .replace(/-/g, '')
                .replace(' ', '')
                .replace(/_/g, '')
                .replace('(', '')
                .replace(')', '')
                .trim();
              searchStr.push(`${key}=${phone}`);
            } else if (key === 'riderName') {
              const riderName = filterVal[key]
                .replace(/[ ]/g, '%20')
                .trim();
              searchStr.push(`${key}=${riderName}`);
            } else {
              searchStr.push(`${key}=${filterVal[key].trim()}`);
            }
          }
        });
        let temp = searchStr.join('&');
        if (searchStr.length > 0) {
          temp = `&${temp}`;
        }
        return temp;
      };

      const getInvitedRiders = async (offset = 0, limit = $scope.pagination.itemsPerPage) => {
        loadingScreenFactory.showLoadingScreen();
        try {
          const headers = {
            username: userDetails.username,
            usertoken: userDetails.user_token,
            'Content-Type': 'application/json',
          };
          const orgId = $stateParams.org_id;
          let requestUrl = `${API_BASE_URL}invite?org_id=${orgId}`;
          if (offset >= 0 && limit > 0) {
            requestUrl += `&offset=${offset}&limit=${limit}`;
          }
          if ($scope.filterRider) {
            requestUrl += getSearchString();
          }
          const { data: { success, data, count } } = await $http.get(requestUrl, { headers });
          if (success) {
            $scope.invitationList = data;
            $scope.pagination.totalItems = count;
            adjustPagination();
          }
        } catch (err) {
          const message = (err.data && err.data.message) ? err.data.message : err.message;
          toaster.pop({
            type: 'error',
            title: message,
            showCloseButton: true,
          });
        } finally {
          loadingScreenFactory.hideLoadingScreen();
          $scope.$apply();
        }
      };

      $scope.searchButtonClicked = () => {
        $scope.pagination.currentPage = 1;
        getInvitedRiders();
      };

      $scope.clearFilters = () => {
        $scope.filterRider = {};
        getInvitedRiders();
      };
      let isAddressSelectedFromSuggestions = false;
      $scope.addAddress = function address() {
        isAddressSelectedFromSuggestions = true;
        const addressInfo = this.getPlace();
        const fullAddress = {
          lat: addressInfo.geometry.location.lat(),
          long: addressInfo.geometry.location.lng(),
          zipCode: addressInfo.address_components[addressInfo.address_components.length - 1].short_name,
          shortAddress: addressInfo.name,
          address: $scope.addRide.addressMain,
          placeId: addressInfo.place_id,
        };
        $scope.addressesAllowed.push(fullAddress);
        $scope.addRide.addressMain = '';
      };
      $scope.deleteAddress = (position) => {
        $scope.addressesAllowed.splice(position, 1);
        $scope.setFieldValidity();
        if ($scope.addressesAllowed.length === 1) {
          $('[name = \'address\']')[0].focus();
        }
      };
      $scope.resetAddRiderForm = () => {
        $scope.addRide = {
          email: '',
          firstName: '',
          lastName: '',
          phone: '',
          idValue: '',
          idType: 'Other',
          dateOfBirth: '',
          dateOfInjury: '',
          bodyPart: '',
          accessAllowed: {
            startDate: '',
            endDate: '',
            legs: '',
            amount: '',
            milesPerLeg: '',
            totalMilesAllowed: '',

            timeFrom: '',
            timeTo: '',
            address: '',
            daysAllowed: [],
            addressMain: '',
          },
          claimInformation: {},
        };

        $scope.billingOptions = {
          activeBillingOptions: $scope.billingOptions.activeBillingOptions,
        };
      };

      $scope.claimNumberChanged = () => {
        isClaimNumberDetailFetched = false;
      };

      $scope.claimLookupClicked = async function claimLookupClicked() {
        const paramsObj = {};
        let processingWaitDialog;
        const ngDialogueObj = {
          template: claimLoaderTemplate,
          plain: true,
          className: 'ngdialog-theme-default',
          scope: $scope,
          showClose: false,
          closeByEscape: false,
          closeByNavigation: false,
          closeByDocument: false,
          name: 'processingWaitDialog',
        };

        if ($scope.addRide.claimInformation.claimNumber) {
          paramsObj.claim_number = $scope.addRide.claimInformation.claimNumber;
          if($stateParams.org_id){
            paramsObj.org_id = $stateParams.org_id;
          }

          if ($scope.addRide.claimInformation.vendorCode) {
            paramsObj.vendor_code = $scope.addRide.claimInformation.vendorCode;
          }
          processingWaitDialog = ngDialog.open(ngDialogueObj);
        } else {
          toaster.pop({
            type: 'error',
            title: 'Enter Claim Number',
            showCloseButton: true,
            timeout: 10000,
          });
          return;
        }

        try {
          const {
            data: {
              success,
              claimInformation,
            },
          } = await $http
            .get(`${API_BASE_URL}claims`, {
              params: paramsObj,
            });

          if (
            success
            && claimInformation
            && claimInformation.count) {
            if (claimInformation.count.toString() === '1') {
              const dateOfInjury = moment.utc(claimInformation.date_of_injury);

              isClaimNumberDetailFetched = true;

              Object.assign($scope.addRide.claimInformation, {
                firstName: claimInformation.adjuster_first_name,
                lastName: claimInformation.adjuster_last_name,
                email: claimInformation.email,
                claimState: claimInformation.claim_state,
                employer: claimInformation.employer,
                injuryState: claimInformation.injury_state,
                claimantHomeAddress: claimInformation.claimant_home_address,
                claimNumber: claimInformation.claim_number,
                vendorCode: claimInformation.vendor_code,
              });

              Object.assign($scope.addRide, {
                firstName: claimInformation.claimant_first_name,
                lastName: claimInformation.claimant_last_name,
                dateOfInjury,
              });

              $scope.$apply();
            } else if (
              $scope.addRide.claimInformation.vendorCode
                || claimInformation.count.toString() === '0'
            ) {
              isClaimNumberDetailFetched = false;
              // cannot find claim fill data manually
              SweetAlert.swal(
                'Claim not found. Please select a different billing method option or try a different claim number.',
                '',
                'warning',
              );
              $scope.resetAddRiderForm();
            } else {
              // please enter vendor code, as we got multiple data
              SweetAlert.swal(
                'More than one claim found for this claim number, please enter vendor code and try again',
                '',
                'warning',
              );
              $scope.resetAddRiderForm();
            }
          }
        } catch (error) {
          isClaimNumberDetailFetched = false;
          SweetAlert.swal(
            'Unable to retrieve claim details at this time. Please try again in a few minutes.',
            '',
            'error',
          );
          $scope.resetAddRiderForm();
        } finally {
          if (processingWaitDialog) {
            processingWaitDialog.close();
          }
        }
      };

      $scope.payerNameSelected = () => {
        if ($scope.addRide.billingType === settingsConstants.claimLookup) {
          $scope.billingOptions.showClaimNumber = true;
        }
      };

      function mapModelToInviteRiderData(inviteRiderData) {
        Object.keys(inviteRiderData).forEach((inviteRiderDataEntity) => {
          if (inviteRiderDataEntity === 'patient') {
            const { first_name: firstName, last_name: lastName, phone } = inviteRiderData[inviteRiderDataEntity];
            Object.assign($scope.addRide, { firstName, lastName, phone });
            if (phone) {
              $timeout(() => {
                $('#phone').trigger('phone-number-changed', phone);
              });
            }
          } else if (inviteRiderDataEntity === 'claimInformation') {
            const {
              adjuster_first_name: firstName,
              adjuster_last_name: lastName,
              adjuster_email_address: email,
              claim_number: claimNumber,
              vendor_code: vendorCode,
              date_of_injury: dateOfInjury,
              date_of_birth: dateOfBirth,
              adjuster_home_address: claimantHomeAddress,
              adjuster_employer_name: employer,
              claim_state: claimState,
              injury_state: injuryState,
              dynamite_claims_id: dynamiteClaimsId,
            } = inviteRiderData[inviteRiderDataEntity];

            Object.assign($scope.addRide, {
              dateOfInjury: dateOfInjury ? moment(dateOfInjury) : undefined,
              dateOfBirth: dateOfBirth ? moment(dateOfBirth) : undefined,
            });
            Object.assign($scope.addRide.claimInformation, {
              firstName,
              lastName,
              email,
              claimNumber,
              vendorCode,
              claimState,
              injuryState,
              claimantHomeAddress,
              employer,
              dynamiteClaimsId,
            });
            if ($scope.addRide.claimInformation.claimNumber) {
              isClaimNumberDetailFetched = true;
            }
          } else if (inviteRiderDataEntity === 'billingDetails') {
            const { selectedPayerName, selectedBillingOption } = inviteRiderData[inviteRiderDataEntity];
            Object.assign($scope.addRide, { billingType: selectedBillingOption });
            Object.assign($scope.addRide.claimInformation, { payerId: selectedPayerName });
          }
        });
      }

      const init = async () => {
        try {
          getInvitedRiders();
          await getSelectedOrgDetails($stateParams.org_id);
          const inviteRiderDataFromSessionStr = sessionStorage.getItem('inviteRiderData');
          if (inviteRiderDataFromSessionStr) {
            $scope.showAddRiderForm = true;
            const inviteRiderDataFromSession = JSON.parse(inviteRiderDataFromSessionStr);
            mapModelToInviteRiderData(inviteRiderDataFromSession);
            makeViewChanges();
            $scope.disableBillingFields = true;
          } else {
            $scope.showAddRiderForm = false;
          }
        } catch (err) {
          const message = (err.data && err.data.message) ? err.data.message : err.message;
          toaster.pop({
            type: 'error',
            title: message,
            body: err.message,
            showCloseButton: true,
          });
        } finally {
          $scope.$apply();
        }
      };

      const checkForFieldsValidity = function checkForFieldsValidity() {
        const invalidRiderTabFields = $scope.billingOptionsDefault[$scope.addRide.billingType].fieldsToBeValidatedOnForm
          .filter(fieldToBeValidated => ($scope.addRiderForm[fieldToBeValidated] || {}).$invalid === true);
        if (invalidRiderTabFields.length) {
          const firstInvalidRiderTabField = $(`[name = ${invalidRiderTabFields[0]}]`)[0];
          if (firstInvalidRiderTabField) {
            if (allDateFields.includes(invalidRiderTabFields[0])) {
              $timeout(() => {
                firstInvalidRiderTabField.focus();
              }, 1000);
            } else {
              firstInvalidRiderTabField.focus();
            }
          }
          $scope.isAnyRiderFieldInvalid = true;
        } else {
          $scope.isAnyRiderFieldInvalid = false;
        }
      };
      $scope.addRiderClicked = () => {
        if ($scope.addRide.billingType === settingsConstants.claimLookup && !isClaimNumberDetailFetched) {
          toaster.pop({
            type: 'error',
            title: 'Please enter a valid claim number and click claim lookup button to continue',
            timeout: 10000,
            showCloseButton: true,
          });
          return;
        }
        if (!$scope.addRide.billingType) {
          const uiSelect = angular.element('#billingOption').controller('uiSelect');
          uiSelect.focusser[0].focus();
          uiSelect.open = true;// to open the drop down.
          uiSelect.activate();
          return;
        }
        checkForFieldsValidity();
        if ($scope.isAnyRiderFieldInvalid) { return; }
        SweetAlert.swal(
          {
            title: 'Terms and Conditions',
            text: 'By inviting these riders, you agree to incur the costs of rides scheduled within the authorization criteria specified. In the event there is a dispute, it will be between you and the invited riders for reimbursement.',
            type: 'info',
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'I Accept',
            showCancelButton: false,
          },
          (isConfirm) => {
            $scope.pagination.currentPage = 1;
            if (isConfirm) $scope.addRider();
          },
        );
      };

      $scope.$watch('showAddRiderForm', () => {
        if ($scope.showAddRiderForm) {
          const timeFields = ['timeFrom', 'timeTo'];
          $timeout(() => {
            timeFields.forEach((timefield) => {
              const tdSelector = `#${timefield} td.uib-time`;
              const selectedTdsDom = $(tdSelector);
              const timeInputDomFields = $(`${tdSelector} input`);
              timeInputDomFields.blur(() => {
                $timeout(() => {
                  const [hoursFieldDom, minutesFieldDom] = Array.from(timeInputDomFields);
                  const hoursFieldValue = hoursFieldDom.value;
                  const  minutesFieldValue = minutesFieldDom.value;
                  if (!hoursFieldValue && !minutesFieldValue) {
                    if (!$scope.addRide.accessAllowed || !$scope.addRide.accessAllowed[timefield]) {
                      $scope.addRiderForm[timefield].$invalid = false;
                      selectedTdsDom.removeClass('has-error');
                    } else {
                      $scope.addRiderForm[timefield].$invalid = true;
                    }
                  } else if (hoursFieldValue && minutesFieldValue && $scope.addRide.accessAllowed[timefield]) {
                    $scope.addRiderForm[timefield].$invalid = false;
                  } else {
                    $scope.addRiderForm[timefield].$invalid = true;
                  }
                }, 1000);
              });
            });
          }, 1000);
        }
      });
      $scope.removeDeviceId = async (invitation) => {
        const inviteId = invitation.id;
        loadingScreenFactory.showLoadingScreen();
        try {
          const headers = {
            username: userDetails.username,
            usertoken: userDetails.user_token,
            'Content-Type': 'application/json',
          };
          const requestUrl = `${API_BASE_URL}invite/${inviteId}/detach-device`;
          const { data: { success } } = await $http.patch(requestUrl, headers);
          if (success) {
            toaster.pop({
              type: 'info',
              title: 'Device Id Removed Successfully.',
              showCloseButton: true,
            });
            Object.assign(invitation, {
              deviceId: null,
            });
          }
        } catch (err) {
          const message = (err.data && err.data.message) ? err.data.message : err.message;
          toaster.pop({
            type: 'error',
            title: message,
            showCloseButton: true,
          });
        } finally {
          loadingScreenFactory.hideLoadingScreen();
          $scope.$apply();
        }
      };

      $scope.sendInvite = async (invitation, index) => {
        const inviteId = invitation.id;
        loadingScreenFactory.showLoadingScreen();
        try {
          delete $scope.addRide.addressMain;
          const body = { sendInviteType: $scope.sendInviteType[index] };
          const headers = {
            username: userDetails.username,
            usertoken: userDetails.user_token,
            'Content-Type': 'application/json',
          };
          const requestUrl = `${API_BASE_URL}invite/${inviteId}/send`;
          const { data: { success } } = await $http.patch(requestUrl, body, headers);
          if (success) {
            toaster.pop({
              type: 'info',
              title: 'Invite Sent Successfully.',
              showCloseButton: true,
            });
            Object.assign(invitation, {
              isInvited: true,
            });
          }
        } catch (err) {
          const message = (err.data && err.data.message) ? err.data.message : err.message;
          toaster.pop({
            type: 'error',
            title: message,
            showCloseButton: true,
          });
        } finally {
          loadingScreenFactory.hideLoadingScreen();
          $scope.$apply();
        }
      };
      const convertStringToFloat = variable => parseFloat(variable.toString().replace(/,/g, ''));
      $scope.addRider = async () => {
        loadingScreenFactory.showLoadingScreen();
        try {
          const postData = { accessAllowed: {}, orgId: $stateParams.org_id };

          if ($scope.addressesAllowed.length === 1) {
            throw new Error('Minimum 2 addresses are required');
          }

          const addRideModelDateKeys = ['dateOfBirth', 'dateOfInjury', 'startDate', 'endDate'];
          Object.keys($scope.addRide).forEach((key) => {
            if (addRideModelDateKeys.indexOf(key) > -1 && $scope.addRide[key]) {
              postData[key] = moment($scope.addRide[key]).format('MM/DD/YYYY');
            } else if ($scope.addRide[key] && key !== 'accessAllowed') {
              postData[key] = $scope.addRide[key];
            }
          });

          /**
         * For phone number field in Add Rider section.
         */
          postData.phone = $('#phone').intlTelInput(
            'getNumber',
            window.intlTelInputUtils.numberFormat.E164,
          );
          if ($scope.addRide.accessAllowed) {
            if ($scope.addRide.accessAllowed.daysAllowed) {
              $scope.addRide.accessAllowed.daysAllowed = $scope.addRide.accessAllowed.daysAllowed.map(dayObj => (dayObj.day));
            }
            if ($scope.addRide.accessAllowed.totalMilesAllowed) {
              $scope.addRide.accessAllowed.totalMilesAllowed = convertStringToFloat($scope.addRide.accessAllowed.totalMilesAllowed);
            }
            if ($scope.addRide.accessAllowed.milesPerLeg) {
              $scope.addRide.accessAllowed.milesPerLeg = convertStringToFloat($scope.addRide.accessAllowed.milesPerLeg);
            }
            Object.keys($scope.addRide.accessAllowed).forEach((key) => {
              if (addRideModelDateKeys.indexOf(key) > -1 && $scope.addRide.accessAllowed[key]) {
                postData.accessAllowed[key] = moment($scope.addRide.accessAllowed[key]).format('MM/DD/YYYY');
              } else if ($scope.addRide.accessAllowed[key] && (key === 'timeTo' || key === 'timeFrom')) {
                postData.accessAllowed[key] = moment($scope.addRide.accessAllowed[key]).format('HH:mm');
              } else if ($scope.addRide.accessAllowed[key]) {
                postData.accessAllowed[key] = $scope.addRide.accessAllowed[key];
              }
            });
          }

          if ($scope.addressesAllowed.length) {
            postData.accessAllowed.addressesAllowed = $scope.addressesAllowed;
          }

          if ($scope.addRide.billingType === settingsConstants.accountLookup) {
            delete postData.claimInformation;
          }

          const requestUrl = `${API_BASE_URL}invite`;
          const { data: { success } } = await $http.post(requestUrl, postData);
          if (success) {
            await getInvitedRiders();
            toaster.pop({
              type: 'info',
              title: 'Rider Added Successfully.',
              showCloseButton: true,
            });
          }
          $scope.showAddRiderForm = false;
          sessionStorage.removeItem('inviteRiderData');
          $scope.disableBillingFields = false;

          $scope.resetAddRiderForm();
        } catch (err) {
          const message = (err.data && err.data.message) ? err.data.message : err.message;
          toaster.pop({
            type: 'error',
            title: message,
            showCloseButton: true,
          });
        } finally {
          loadingScreenFactory.hideLoadingScreen();
          $scope.$apply();
        }
      };
      init();


      $scope.pageChanged = function pageChanged(currentPage) {
        const offset = (currentPage - 1) * $scope.pagination.itemsPerPage;

        const limit = $scope.pagination.itemsPerPage;
        getInvitedRiders(offset, limit);
      };

      $scope.setForm = function setForm(form) {
        $scope.addRiderForm = form;
      };

      $scope.setFieldValidity = () => {
        $timeout(() => {
          if ($scope.addressesAllowed.length === 1) {
            $scope.addRiderForm.address.$setValidity('valid', false);
            return;
          }
          if (!$scope.addRide.addressMain && !$scope.addressesAllowed.length) {
            $scope.addRiderForm.address.$setValidity('valid', true);
            return;
          }
          let ngChangeTriggered = true;
          if (isAddressSelectedFromSuggestions) {
            ngChangeTriggered = false;
            isAddressSelectedFromSuggestions = false;
          }
          if (ngChangeTriggered) {
            $scope.addRiderForm.address.$setValidity('valid', false);
          } else {
            $scope.addRiderForm.address.$setValidity('valid', true);
          }
        }, 1000);
      };

      $transitions.onStart({}, (transition) => {
        const fromState = transition.from();
        if (fromState.name === 'main.invitations') {
          sessionStorage.removeItem('inviteRiderData');
        }
      });
    },
  ]);
