const reasonCodes = {
    VARIANCE: 'Variance',
    NON_RIDE: 'Non Ride',
    CUSTOMER_COMPLAINT: 'Customer Complaint',
    SRA: 'SRA',
    FLAT_RATE: 'Flat Rate',
    PAYMENT_ADJUSTMENT: 'Payment Adjustment',
    UPDATE_ACTUALS: 'Update Actuals',
    CONTINUITY_OF_CARE: 'Continuity of Care',
    CASE_STATUS: 'Case Status',
    ADJUDICATION: 'Adjudication',
};
export default Object.freeze(reasonCodes);
