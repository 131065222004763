angular.module('relayHealth').controller('contactUsCtrl', [
  '$scope',
  '$http',
  'API_BASE_URL',
  '$state',
  'toaster',
  'loadingScreenFactory',
  function contactUsCtrl(
    $scope,
    $http,
    API_BASE_URL,
    $state,
    toaster,
    loadingScreenFactory,
  ) {
    const signedInUser = JSON.parse(localStorage.getItem('userDetails'));
    $scope.signedInUser = signedInUser;
    $scope.contactUs = {
      type: 'web',
    };
    $scope.submitDisable = false;
    $scope.contact_preferences = ['Phone',
      'Email'];
    $scope.help_types = [
      "I'd like to request a demo",
      'I need help setting up my account',
      'I need help with the website',
      'I have a question about my account',
      'I have a general inquiry',
    ];

    $scope.marketSegmentDetails = {};
    function getMarketSegmentDetailsById() {
      if (!$scope.signedInUser.organisation.market_segment_id) {
        $state.go('');
        return;
      }
      loadingScreenFactory.showLoadingScreen();
      $http
        .get(`${API_BASE_URL}marketSegments/${$scope.signedInUser.organisation.market_segment_id}`)
        .then((result) => {
          if (result.data.success) {
            const { data: { data: { help_phone: { value } = {} } = {} } = {} } = result;
            $scope.helpPhoneNumber = value  || '1-866-835-8889';
          } else {
            toaster.pop({
              type: 'error',
              title: result.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        })
        .catch((error) => {
          toaster.pop({
            type: 'error',
            title: error.data ? error.data.message : error.message,
            showCloseButton: true,
            timeout: 10000,
          });
        }).finally(() => {
          loadingScreenFactory.hideLoadingScreen();
        });
    }

    getMarketSegmentDetailsById();

    $scope.submitContact = () => {
      if (!$scope.contactUsform.$valid) {
        toaster.pop({
          type: 'error',
          title: 'Please complete the form!',
          showCloseButton: true,
          timeout: 10000,
        });
        return;
      }

      $scope.submitDisable = true;

      const contactFormSubmit = $http.post(`${API_BASE_URL}contactUs`, $scope.contactUs);
      loadingScreenFactory.showLoadingScreen();
      contactFormSubmit.then(
        (result) => {
          $scope.submitDisable = false;
          // this.contactUsform.reset();
          [$scope.contactUs.help_type] = $scope.help_types;
          [$scope.contactUs.contact_preference] = $scope.contact_preferences;
          $scope.contactUs.message = '';
          if (result.data.success) {
            toaster.pop({
              type: 'success',
              title: 'Thank you for your question. A One Call Representative will respond to you shortly',
              showCloseButton: true,
              timeout: 10000,
            });
          } else {
            toaster.pop({
              type: 'error',
              title: 'Error while submitting the form , Please refresh the page and try again.',
              showCloseButton: true,
              timeout: 10000,
            });
          }
        },
      ).catch((error) => {
        $scope.submitDisable = false;
        toaster.pop({
          type: 'error',
          title: error.message,
          showCloseButton: true,
          timeout: 10000,
        });
      }).finally(() => loadingScreenFactory.hideLoadingScreen());
    };
    $scope.resetContact = () => {
      [$scope.contactUs.help_type] = $scope.help_types;
      [$scope.contactUs.contact_preference] = $scope.contact_preferences;
      $scope.contactUs.message = '';
    };
  },
]);
