const { alert } = window;
const { permissionsConstants } = relaylib.permissions;

angular.module('relayHealth').controller('questionnaireListCtrl', [
  '$scope',
  '$rootScope',
  '$http',
  'API_BASE_URL',
  '$stateParams',
  'toaster',
  'SweetAlert',
  'loadingScreenFactory',
  'DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID',
  '$state',
  '$location',
  'Pubnub',
  'permissionFilter',
  function questionnaireListCtrl(
    $scope,
    $rootScope,
    $http,
    API_BASE_URL,
    $stateParams,
    toaster,
    SweetAlert,
    loadingScreenFactory,
    DEFAULT_ONE_CALL_CARE_MANAGEMENT_ID,
    $state,
    $location,
    Pubnub,
    permissionFilter,
  ) {
    $scope.moment = moment;
    if (
      $scope.userDetails.role !== 'mastersuperadmin'
      && $scope.userDetails.role !== 'org_superadmin'
      && !permissionFilter(permissionsConstants.QUESTIONNAIRE_ACCESS)
    ) {
      $location.path('/main/dashboard');
    }

    $scope.pagination = {
      totalItems: 0,
      currentPage: 1,
      lastItem: 0,
      itemsPerPage: 10,
    };

    const getAllOrgs = () => {
      const res = $http.get(`${API_BASE_URL}organisation/getOrganizations`);
      loadingScreenFactory.showLoadingScreen();
      res.then(({ data }) => {
        loadingScreenFactory.hideLoadingScreen();
        if (data.success) {
          $scope.orgList = data.org_details;
        } else {
          toaster.pop({
            type: 'error',
            title: data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        }
      });
      res.catch(() => {
        loadingScreenFactory.hideLoadingScreen();
        toaster.pop({
          type: 'error',
          title: 'Not able to get organization list',
          showCloseButton: true,
          timeout: 10000,
        });
      });
    };


    function getActiveQuestionnaireForOrg() {
      $http
        .get(`${API_BASE_URL}organisation/getActiveQuestionnaireForOrg/${$scope.searchQuery.organization.id}`)
        .then((result) => {
          if (result.data.success) {
            $scope.activeQuestionnaire = result.data.activeQuestionnaire;
          } else {
            $scope.activeQuestionnaire = null;
            toaster.pop({
              type: 'error',
              title: result.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        });
    }

    function adjustPagination() {
      $scope.pagination.lastItem = ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage + 10;
      if ($scope.pagination.lastItem > $scope.pagination.totalItems) {
        $scope.pagination.lastItem = $scope.pagination.totalItems;
      }
    }

    function getQuestionnariesByOrgId() {
      const offset = ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage;
      const limit = $scope.pagination.itemsPerPage;
      const url = `${API_BASE_URL}organisation/getQuestionnariesByOrgId/${$scope.searchQuery.organization.id}`;
      const getQuestionnariesByOrgIdCall = $http({
        url,
        method: 'GET',
        params: {
          offset,
          limit,
        },
      });
      loadingScreenFactory.showLoadingScreen();
      getQuestionnariesByOrgIdCall.then(({ data }) => {
        loadingScreenFactory.hideLoadingScreen();
        if (data.success) {
          $scope.questionnaireList = data.questionnaireList;
          $scope.pagination.totalItems = data.totalCount;
          getActiveQuestionnaireForOrg();
          adjustPagination();
        } else {
          toaster.pop({
            type: 'error',
            title: data.message,
            showCloseButton: true,
            timeout: 10000,
          });
          $scope.questionnaireList = [];
          $scope.activeQuestionnaire = null;
          $scope.pagination.totalItems = 0;
          adjustPagination();
        }
      });
      getQuestionnariesByOrgIdCall.catch(() => {
        loadingScreenFactory.hideLoadingScreen();
        toaster.pop({
          type: 'error',
          title: 'Not able to get questionnaires list',
          showCloseButton: true,
          timeout: 10000,
        });
        $scope.activeQuestionnaire = null;
        $scope.pagination.totalItems = 0;
        adjustPagination();
      });
    }

    let userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (userDetails.role === 'mastersuperadmin' || permissionFilter(permissionsConstants.QUESTIONNAIRE_ACCESS)) {
      getAllOrgs();
      if ($stateParams.orgId) {
        const getOrgDetailsByIdCall = $http.get(`${API_BASE_URL}organisation/getOrgDetailsById/${$stateParams.orgId}`);
        getOrgDetailsByIdCall.then(
          (result) => {
            if (result.data.success) {
              $scope.searchQuery = {
                organization: result.data.orgDetails,
              };
              $scope.orgName = $scope.searchQuery.organization.name;
              $scope.orgId = $scope.searchQuery.organization.id;
              getQuestionnariesByOrgId();
            } else {
              toaster.pop({
                type: 'error',
                title: result.data.message,
                showCloseButton: true,
                timeout: 10000,
              });
            }
          },
          (error) => {
            toaster.pop({
              type: 'error',
              title: error.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          },
        );
      } else {
        $scope.searchQuery = {
          organization: userDetails.organisation,
        };
        $scope.orgName = $scope.searchQuery.organization.name;
        getQuestionnariesByOrgId();
      }
    } else {
      $scope.searchQuery = {
        organization: userDetails.organisation,
      };
      $scope.orgName = $scope.searchQuery.organization.name;
      getQuestionnariesByOrgId();
    }

    $scope.dateRangeExport = {
      startDate: new Date().setHours(0, 0, 0),
      endDate: new Date().setHours(23, 59, 59),
    };
    $scope.button_disabled = false;
    $scope.export_button = 'Export';

    function getLatestProcessedExportRecords() {
      const getLatestProcessedExportRecordsCall = $http.get(`${API_BASE_URL}export/getLatestProcessedExportRecords/Questionnaires`);

      getLatestProcessedExportRecordsCall.then(
        (result) => {
          if (result.data.success) {
            $scope.exportList = result.data.data;
          }
        },
        (err) => {
          toaster.pop({
            type: 'error',
            title: err,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    }
    getLatestProcessedExportRecords();

    $scope.exportInDateRange = function exportInDateRange() {
      if ($scope.dateRangeExport) {
        const firstDate = new Date($scope.dateRangeExport.startDate).getTime();
        const secondDate = new Date($scope.dateRangeExport.endDate).getTime();
        const endDateArr = $scope.moment(secondDate).format('YYYY-MM-DD HH:mm:SS');
        const endDate = `${endDateArr.split(' ')[0]} 23:59:59`;
        const startDateArr = moment(firstDate).format('YYYY-MM-DD HH:mm:SS');
        const startDate = `${startDateArr.split(' ')[0]} 00:00:00`;
        const url = `${API_BASE_URL}saveExportFilters`;
        $scope.button_disabled = true;
        $scope.export_button = 'Processing';
        const loggedinUserDetails = JSON.parse(localStorage.getItem('userDetails'));
        const exportInDateRangeCall = $http({
          url,
          method: 'POST',
          data: {
            start_date: startDate,
            end_date: endDate,
            questionnaire_org_id: $scope.searchQuery.organization.id,
            page: 'Questionnaires',
          },
          headers: {
            'Content-type': 'application/json',
          },
        });
        exportInDateRangeCall.then(
          (result) => {
            $scope.button_disabled = false;
            $scope.export_button = 'Export';
            if (result.success === false) {
              // eslint-disable-next-line no-alert
              alert('failed');
            } else {
              toaster.pop({
                type: 'info',
                title: 'Please wait for your file.',
                showCloseButton: true,
                timeout: 10000,
              });
              getLatestProcessedExportRecords();
            }
          },
          (err) => {
            $scope.button_disabled = false;
            $scope.export_button = 'Export';
            toaster.pop({
              type: 'error',
              title: err.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          },
        );
      } else {
        toaster.pop({
          type: 'error',
          title: 'Choose valid date range',
          showCloseButton: true,
          timeout: 10000,
        });
      }
    };

    $scope.pageChanged = () => {
      getQuestionnariesByOrgId();
    };

    $scope.toggleQuestionnaireFeatureForOrg = (isQuestionnaireActive) => {
      if (isQuestionnaireActive && !$scope.questionnaireList.length) {
        toaster.pop({
          type: 'error',
          title: 'Create a questionnaire first to enable this feature',
          showCloseButton: true,
          timeout: 10000,
        });
        return;
      }
      const toggleQuestionnaireFeatureForOrgCall = $http.put(
        `${API_BASE_URL}organisation/toggleQuestionnaireFeatureForOrg/${$scope.searchQuery.organization.id}`,
        {
          isActive: isQuestionnaireActive,
        },
      );
      toggleQuestionnaireFeatureForOrgCall.then(
        (result) => {
          loadingScreenFactory.hideLoadingScreen();
          if (result.data.success) {
            $scope.searchQuery.organization.questionnaire_active = isQuestionnaireActive;
            if (userDetails.role === 'mastersuperadmin' || permissionFilter(permissionsConstants.QUESTIONNAIRE_ACCESS)) {
              Object.keys($scope.orgList).forEach((index) => {
                if ($scope.orgList[index].id === $scope.searchQuery.organization.id) {
                  $scope.orgList[index].questionnaire_active = isQuestionnaireActive;
                }
              });
            }
            if ($scope.searchQuery.organization.id === userDetails.organisation.id) {
              const loggedinUserDetails = JSON.parse(localStorage.getItem('userDetails'));
              loggedinUserDetails.organisation.questionnaire_active = isQuestionnaireActive;
              localStorage.setItem('userDetails', JSON.stringify(loggedinUserDetails));
              userDetails = loggedinUserDetails;
            }
          }
        },
        (error) => {
          loadingScreenFactory.hideLoadingScreen();
          toaster.pop({
            type: 'error',
            title: error.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    };


    function setQuestionnaireFeatureOnSetQuestionnaire() {
      let activeQuestionnaire = false;

      Object.keys($scope.questionnaireList).some((index) => {
        if ($scope.questionnaireList[index].org_id === $scope.searchQuery.organization.id && $scope.questionnaireList[index].is_active) {
          activeQuestionnaire = true;
          return true;
        }
        return false;
      });
      if (!activeQuestionnaire) {
        $scope.searchQuery.organization.questionnaire_active = false;
        if ($scope.searchQuery.organization.id === userDetails.organisation.id) {
          const loggedinUserDetails = JSON.parse(localStorage.getItem('userDetails'));
          loggedinUserDetails.organisation.questionnaire_active = false;
          localStorage.setItem('userDetails', JSON.stringify(loggedinUserDetails));
          userDetails = loggedinUserDetails;
        }
      }
    }

    $scope.setQuestionnaireForOrg = (questionnaire) => {
      const setQuestionnaireForOrgCall = $http.put(
        `${API_BASE_URL}organisation/setQuestionnaireForOrg/${questionnaire.id}/${$scope.searchQuery.organization.id}`,
        {
          setActive: questionnaire.is_active,
        },
      );
      setQuestionnaireForOrgCall.then(
        (result) => {
          if (result.data.success) {
            getQuestionnariesByOrgId();
            setQuestionnaireFeatureOnSetQuestionnaire();
          }
        },
        (error) => {
          loadingScreenFactory.hideLoadingScreen();
          toaster.pop({
            type: 'error',
            title: error.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    };

    $scope.clearFilters = () => {
      $scope.searchQuery = {
        organization: userDetails.organisation,
      };
      $scope.pagination.currentPage = 1;
      getQuestionnariesByOrgId();
    };

    function getUserDetailsById() {
      const getUserDetailsByIdCall = $http.get(`${API_BASE_URL}users/getUserDetailsById/${userDetails.user_id}`);
      getUserDetailsByIdCall.then(
        (result) => {
          if (result.data.success) {
            userDetails.organisation.questionnaire_active = result.data.userDetails.organisation.questionnaire_active;
          } else {
            toaster.pop({
              type: 'error',
              title: result.data.message,
              showCloseButton: true,
              timeout: 10000,
            });
          }
        },
        (error) => {
          toaster.pop({
            type: 'error',
            title: error.data.message,
            showCloseButton: true,
            timeout: 10000,
          });
        },
      );
    }
    getUserDetailsById();

    $scope.searchButtonClicked = () => getQuestionnariesByOrgId();

    $scope.openFormBuilder = (questionnaire) => {
      const params = {
        orgId: $scope.searchQuery.organization.id,
      };
      if (questionnaire) {
        params.questionnaireId = questionnaire.id;
      }
      $state.go('main.userMgmt.manageQuestionnaire', params);
    };

    function listenForPubnubMessages() {
      $rootScope.$on(Pubnub.getMessageEventNameFor(userDetails.user_id), (event, payload) => {
        if (payload.message.type === 'fileReady' && payload.message.page === 'Questionnaires' && $state.current.name === 'main.userMgmt.questionnaireList') {
          getLatestProcessedExportRecords();
        }
      });
    }
    listenForPubnubMessages();
  },
]);
